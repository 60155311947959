import React from "react";
import illustrationHealth from "../../../assets/images/optimus/illustrationHealth.png";
import fitnessVector from "../../../assets/images/optimus/fitnessVector.png";
import NutritionVector from "../../../assets/images/optimus/nutritionVector.png";
import SleepVector from "../../../assets/images/optimus/sleepVector.png";
import HealthVector from "../../../assets/images/optimus/healthVector.png";
import MindfulnessVector from "../../../assets/images/optimus/mindfulnessVector.png";
import MotivationVector from "../../../assets/images/optimus/motivationVector.png";

import problemStatementIcon from "../../../assets/images/optimus/problemStatementIcon.png";
import solutionIcon from "../../../assets/images/optimus/solutionIcon.png";
import businessOpportunityIcon from "../../../assets/images/optimus/businessOpportunityIcon.png";
import problemStatementImg from "../../../assets/images/optimus/problemStatementImg.png";
import problemStatementImg2 from "../../../assets/images/optimus/problmimg2.png";
import SolutionImg from "../../../assets/images/optimus/Solution.png";
import businessOpportunityImg from "../../../assets/images/optimus/businessOpportunityImg.png";
import herotext from "../../../assets/images/optimus/optimustext.svg";

import surveyAnalysis from "../../../assets/images/optimus/surveyAnalysis.svg";
import survayNinetyone from "../../../assets/images/optimus/survayNinetyone.svg";
import survaySixtyfive from "../../../assets/images/optimus/survaySixtyfive.svg";
const OptimusSection1 = () => {
  let url = "https://infini8ai-images.s3.amazonaws.com/optimus";
  return (
    <div id="hero-part">
      <div className="bg-Case-4">
        <div className="bg-case-img">
          <div className="container">
            <div className="optimusHeader">
              <div className="row" style={{ alignItems: "center" }}>
                <div className="col-md-4 m-0 p-0">
                  <div className="hero-images">
                    <img
                      src={`${url}/optimushealth.svg`}
                      class="rounded optimusLogo float-left"
                      alt="logo"
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className=" optimus_heroContentText d-flex justify-content-center">
                    <h3>UX/UI Case Study</h3>
                  </div>
                </div>
                <div className="col-md-4 m-0 p-0">
                  <div className="hero-images">
                    <img
                      src={illustrationHealth}
                      class="rounded illustrationHealth"
                      alt="logo"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="optimusHeroContent">
              <div className="row">
                <div className="col-md-5">
                  <div className="optimus_heroContentText text-center">
                    <h3>
                      Personalised & Tailored Data Driven Wellbeing
                      Optimisation.
                    </h3>
                  </div>
                  <div className="row pt-5">
                    <div className="col-md-4 col-sm-4">
                      <div id="personalisedCard">
                        <div className="card">
                          <img
                            src={fitnessVector}
                            className="Nutritioncard"
                            alt="nutrition"
                          />
                          <div className="cardbody">
                            <h5>Fitness</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-4">
                      <div id="personalisedCard">
                        <div className="card">
                          <img
                            src={NutritionVector}
                            className="NutritionVector"
                            alt="nutrition"
                          />
                          <div className="cardbody">
                            <h5>Nutrition</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-4">
                      <div id="personalisedCard">
                        <div className="card">
                          <img
                            src={SleepVector}
                            className="SleepVector"
                            alt="sleep"
                          />
                          <div className="cardbody">
                            <h5>Sleep</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-4">
                      <div id="personalisedCard">
                        <div className="card">
                          <img
                            src={HealthVector}
                            className="HealthVector"
                            alt="health"
                          />
                          <div className="cardbody">
                            <h5>Health</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-4">
                      <div id="personalisedCard">
                        <div className="card">
                          <img
                            src={MindfulnessVector}
                            className="MindfulnessVector"
                            alt="mindfulness"
                          />
                          <div className="cardbody">
                            <h5>Mindfulness</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-4">
                      <div id="personalisedCard">
                        <div className="card">
                          <img
                            src={MotivationVector}
                            className="MotivationVector"
                            alt="motivation"
                          />
                          <div className="cardbody">
                            <h5>Motivation</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="optimus_heroContentText ">
                    <p>
                      Easily aggregate & streamline all your wellbeing data,
                      apps & expert coaching, on a single “Smartphone App”,
                      towards your own personalised optimul unique lifestyle.
                    </p>
                  </div>
                </div>
                <div className="col-md-7 m-0 p-0">
                  <div className="hero-images">
                    <img
                      src={`${url}/3d-render-medical.png`}
                      class="rounded renderMedical"
                      alt="logo"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="w-100 ">
                    <img src={herotext} className="img-fluid" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="SolutionMainDiv">
        <div className="bg-Case-4_Hero">
          <div className="container pt-5 " style={{ maxWidth:"95%" }}>
            <div className="bg-Case-4_backImg1">
              <div className="row" style={{ alignItems: "center" }}>
                <div className="col-md-4" >
                  <div className="problemStatementMain">
                    <img
                      src={problemStatementIcon}
                      className="problemStatementIcon"
                      alt="nutrition"
                    />
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="problemStatementMain">
                    <img
                      src={problemStatementImg}
                      className="problemStatementImg"
                      alt="nutrition"
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <img
                    className="img-fluid "
                    src={problemStatementImg2}
                    style={{ mixBlendMode: "color-dodge" }}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="covidFearMain">
                  <div className="covidFear">
                    <p>
                      With Covid fear and busy routines, people didn’t have
                      access to a gym and a healthy lifestyle. They found it
                      difficult to train themselves at home with a proper
                      nutrition mentor and plan.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-Case-4_Hero">
          <div className="container pb-1" style={{maxWidth:"100%"}}>
            <div className="bg-Case-4_backImg2">
              <div className="row no-gutters" style={{ alignItems: "center" }}>
                <div className="col-md-3 ">
                  <img
                    className="img-fluid "
                    style={{marginLeft: "0px" }}
                    src={problemStatementImg2}
                    style={{ mixBlendMode: "color-dodge" }}
                  />
                </div>
                <div className="col-md-6">
                  <div className="problemStatementMain">
                    <img
                      src={SolutionImg}
                      className="SolutionImg"
                      alt="nutrition"
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="problemStatementMain">
                    <img
                      src={solutionIcon}
                      style={{marginLeft:"0px"}}
                      className="solutionIcon"
                      alt="nutrition"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-md-11">
                <div className="covidFearMain2">
                  <div className="covidFear2">
                    <p>
                      Optimus Health is a product made in collaboration with a
                      panel of NHS doctors in the UK. Personalized and tailored,
                      data-driven wellbeing optimization in the palm of your
                      hands.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-Case-4_Hero">
          <div className="container ">
            <div className="bg-Case-4_backImg3">
              <div className="row" style={{ alignItems: "center" }}>
                <div className="col-md-3">
                  <div className="problemStatementMains">
                    <img
                      src={businessOpportunityIcon}
                      className="businessOpportunityIcon"
                      alt="nutrition"
                    />
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="problemStatementMains">
                    <img
                      src={businessOpportunityImg}
                      className="businessOpportunityImg"
                      alt="nutrition"
                    />
                  </div>
                </div>
                <div className="col-md-4 ">
                  <img
                    className="img-fluid  "
                    src={problemStatementImg2}
                    style={{ mixBlendMode: "color-dodge" }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="covidFearMain3">
                    <div className="covidFear3">
                      <p>
                        The business opportunity is huge. Health remains the
                        continuous focus of every individual. Our purpose is to
                        give everyone an effective, scientific, objective, and
                        robust tool that optimizes their well-being based on
                        their ideal lifestyle. There is hardly any application
                        that acts as a one-stop shop for all your health needs.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="OptimusSurveyAnalysis">
        <div className="container">
          <div className="row">
            <div className="col-md-6 offset-md-3">
              <div className="OptimusSurveyTitle">
                <img src={surveyAnalysis} className="" alt="" />
                <p>
                  They served <span>10</span> Health trainees, <span>10</span>{" "}
                  Doctors, and <span>15</span> Individuals through their{" "}
                  <span>MVP</span>.
                </p>
              </div>
            </div>
          </div>
          <div className="analysisCountingMain">
            <div className="row">
              <div className="col-md-6">
                <div className="analysisCountingImg">
                  <img
                    src={survayNinetyone}
                    className="survayNinetyone"
                    alt="Ninetyone"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="analysisCountingImg">
                  <img
                    src={survaySixtyfive}
                    className="survaySixtyfive"
                    alt="Sixtyfive"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OptimusSection1;
