import React, { useLayoutEffect } from "react";
import { useState, useEffect } from "react";
import "./task.css";
import axios from "axios";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Box, styled } from "@mui/system";
import { CircularProgress, Grid, Stack, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import AddCompany from "../../assets/images/project/AddCompany.svg";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import TaskImg from "../../assets/images/project/task.png";
import TaskDetailPopup from "./TaskDetailPopup";
import DashboardLayout from "../../layouts/Dashboard";
import DashboardNavbar from "../../layouts/Navbars/DashboardNavbar";
import { getTasksByMember } from "../../apiservices/task";
import { getUsersByCompany } from "../../apiservices/general";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import uploadimage from "../../assets/images/project/add_a_photo.svg";
import { BackButton } from "../team/Team";
import { Dialog, DialogContent } from "@mui/material";
import "@fullcalendar/react/dist/vdom";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import Slide from "@mui/material/Slide";
import { TextStyle, Title } from "../dashboard/style";
const TaskHeader = styled(Box)({
  height: "40px",
  // width: "336px",
  borderRadius: "6px",
  background: "#04B2AE",
  boxShadow: " 0px 4px 20px rgba(0, 0, 0, 0.16)",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  color: "#FFFFFF",
});
const Cards = styled(Box)({
  // height: "139px",
  // width: "312px",
  borderRadius: "6px",
  // background: "#FFFFFF",
  boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.08)",
});
const DragIcon = styled(Box)({
  margin: "5px",
  borderRadius: "2px",
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  color: "grey",
  justifyContent: "center",
  "&:hover": {
    background: "#67748E",
    color: "#ffffff",
  },
});
const Body = styled(Stack)({
  background: "#FFFFFF",
  padding: "10px",
  // margin: "20px",
  borderRadius: "10px",
});
window.Buffer = window.Buffer || require("buffer").Buffer;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const Task = () => {
  const [popup, setPopup] = useState(false);
  const [selectedRow, setselectedRow] = useState("");
  const [selectedCol, setSelectedCol] = useState("");
  const [allUsers, setAllUsers] = useState([]);
  const [columns, setColumns] = useState({});
  const [taskCompleted, setTaskCompleted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [calender, setCalender] = useState(false);
  const [events, setEvents] = useState(false);

  useEffect(() => {
    getallTask();
    getAllUser();
  }, []);

  useLayoutEffect(() => {
    if (selectedCol) {
      updateTask();
    }
  }, [selectedCol]);

  const getAllUser = async () => {
    let data = {
      company_code: localStorage.getItem("companyCode"),
    };
    let res = await getUsersByCompany(data);
    setAllUsers(res.usersArr);
  };
  const updateTask = async () => {
    let copyCols = JSON.parse(JSON.stringify(columns));
    let found = copyCols[selectedCol].items.find(
      (item) => item.journey !== selectedCol
    );
    if (found) {
      found.journey = selectedCol;
      await axios.post(`https://infini8ai.herokuapp.com/tasks`, {
        request: {
          method: "updateTask",
          data: {
            ...found,
          },
        },
      });

      setSelectedCol("");
      getallTask();
    } else {
      setSelectedCol("");
    }
    setTaskCompleted(false);
  };

  const complete = () => {
    setTaskCompleted(!taskCompleted);
  };
  const onDragEnd = async (result, columns, setColumns) => {
    if (result.destination.droppableId === "completed") {
      complete();
    }
    if (!result.destination) return;
    const { source, destination } = result;
    setSelectedCol(destination.droppableId);

    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];
      const sourceItems = [...sourceColumn.items];
      const destItems = [...destColumn.items];
      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems,
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems,
        },
      });
    } else {
      setSelectedCol("");
      const column = columns[source.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems,
        },
      });
    }
  };
  function randomColor() {
    let hex = Math.floor(Math.random() * 0xffffff);
    let color = "#" + hex.toString(16);
    return color;
  }
  const getallTask = async () => {
    setLoading(true);
    let columnsFromBackend = {
      assigned: {
        name: "Assigned",
        items: [],
        icons: AddCompany,
      },
      inprogress: {
        name: "In Progress",
        items: [],
      },
      testing: {
        name: "Testing",
        items: [],
      },
      completed: {
        name: "Done",
        items: [],
      },
    };
    let data = {
      member: localStorage.getItem("userEmail"),
    };
    let dataArr = [];
    let res = await getTasksByMember(data);
    let inprogress = [];
    let completed = [];
    let assigned = [];
    let testing = [];

    let response = res.tasksArr;
    response.forEach((el) => {
      if (el.journey === "assigned") {
        assigned.push(el);
      } else if (el.journey === "inprogress") {
        inprogress.push(el);
      } else if (el.journey === "testing") {
        testing.push(el);
      } else if (el.journey === "completed") {
        completed.push(el);
      }
    });
    columnsFromBackend.inprogress.items = inprogress;
    columnsFromBackend.completed.items = completed;
    columnsFromBackend.assigned.items = assigned;
    columnsFromBackend.testing.items = testing;

    setColumns(columnsFromBackend);
    setLoading(false);
    res?.tasksArr.map((item) => {
      return dataArr.push({
        title: item.task,
        start: item.startDate,
        end: item.endDate,
        backgroundColor: randomColor(),
      });
    });
    console.log(res.tasksArr);
    setEvents(dataArr);
  };

  const assignTaskDetails = (items, columname) => {
    setselectedRow(items);
    setPopup(true);
  };
  const closeTaskPopup = () => {
    setPopup(false);
  };
  const handleCalender = () => {
    setCalender((prev) => !prev);
  };
  return (
    <DashboardLayout>
      <DashboardNavbar>
        <Stack
          sx={{ height: "100px", mx: 1 }}
          alignItems={"center"}
          direction={"row"}
          justifyContent={"space-between"}>
          <Stack>
            <img src={TaskImg} alt="img" />
          </Stack>
          <Stack>
            <Title variant="h6" sx={{ fontWeight: "bold" }}>
              My Tasks
            </Title>
          </Stack>
          <Stack>
            <BackButton
              size="small"
              onClick={handleCalender}
              sx={{ textTransform: "capitalize" }}>
              View Calender
            </BackButton>
          </Stack>
        </Stack>
      </DashboardNavbar>
      <Grid container sx={{ px: 3, mt: 5 }}>
        {loading && (
          <Box sx={{ m: "0 auto" }}>
            <CircularProgress color="success" />
          </Box>
        )}
        {!loading && (
          <DragDropContext
            onDragEnd={(result) => onDragEnd(result, columns, setColumns)}>
            {Object.entries(columns).map(([columnId, column], index) => {
              return (
                <Grid item md={3} sm={6} xs={12} key={columnId}>
                  <TaskHeader sx={{ mx: 1 }}>
                    <TextStyle>{column.name}</TextStyle>
                  </TaskHeader>
                  <Droppable droppableId={columnId} key={columnId}>
                    {(provided, snapshot) => {
                      return (
                        <div
                          className="task_card_body taskbody_scroll mx-2"
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          style={{
                            background: snapshot.isDraggingOver
                              ? "lightblue"
                              : "#E7F3F3",
                          }}>
                          {provided.placeholder}

                          {column.items &&
                            column.items?.map((item, index) => {
                              let user = item.members[0] || "";
                              return (
                                <>
                                  <Draggable
                                    key={item._id}
                                    draggableId={item._id}
                                    index={index}>
                                    {(provided) => {
                                      return (
                                        <>
                                          <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={{
                                              paddingInline: "5px",
                                              userSelect: "none",
                                              margin: "0 0 8px 0",
                                              minHeight: "50px",

                                              color: "white",
                                              ...provided.draggableProps.style,
                                            }}>
                                            <Cards
                                              className={`${
                                                columnId === "completed"
                                                  ? "task_comp_tab"
                                                  : "task_show_tab  mb-3"
                                              } fw-bold fs-6`}
                                              onClick={(e) =>
                                                assignTaskDetails(
                                                  item,
                                                  columnId
                                                )
                                              }>
                                              <Stack
                                                width="100%"
                                                direction="row"
                                                justifyContent="space-between">
                                                <div className="col-8">
                                                  <span className="px-2 ">
                                                    {item.imageUrl && (
                                                      <img
                                                        src={
                                                          item.imageUrl[0]
                                                            ? item.imageUrl[0]
                                                            : uploadimage
                                                        }
                                                        alt="img"
                                                        className="img-fluid pt-2 "
                                                        style={{
                                                          height: "32px",
                                                        }}
                                                      />
                                                    )}
                                                  </span>
                                                </div>

                                                <Box>
                                                  <DragIcon>
                                                    <DragIndicatorIcon />
                                                  </DragIcon>
                                                </Box>
                                              </Stack>
                                              <Box
                                                sx={{
                                                  ml: 1,
                                                  color: "#67748E",
                                                }}>
                                                <CheckCircleOutlineIcon
                                                  style={{
                                                    cursor: "pointer",
                                                  }}
                                                />

                                                <span
                                                  className="my-1  w-100 fw-bold p-1"
                                                  style={{
                                                    overflowWrap: "break-word",
                                                    overflow: "hidden",
                                                  }}>
                                                  {item.task}
                                                </span>
                                              </Box>
                                              <div className="row  d-flex flex-end ">
                                                <div
                                                  className="col-6 d-flex flex-column "
                                                  style={{ color: "#67748E" }}>
                                                  <p
                                                    className="   task_asign_name  "
                                                    style={{
                                                      marginLeft: "42px",
                                                    }}>
                                                    Assigned To:
                                                  </p>
                                                  <div className="d-flex   gap-1">
                                                    <p
                                                      style={{
                                                        marginTop: "-18px",
                                                      }}>
                                                      <img
                                                        src={user?.imageUrl}
                                                        alt=""
                                                        style={{
                                                          borderRadius: "50%",
                                                          width: "40px",
                                                          height: "40px",
                                                          padding: "5px",
                                                        }}
                                                      />
                                                    </p>

                                                    <p className="task_asigned_name pt-1">
                                                      {user?.name}
                                                    </p>
                                                  </div>
                                                </div>
                                                <div
                                                  className="col-6"
                                                  style={{ color: "#67748E" }}>
                                                  <span className=" float-end  p-2 task_asign_name ">
                                                    Due:{" "}
                                                    {item?.endDate
                                                      ? item?.endDate
                                                      : item.deadline}
                                                  </span>
                                                  <p
                                                    className=""
                                                    style={{
                                                      fontSize: "12px",
                                                      fontWeight: "600",
                                                    }}>
                                                    {item.projects &&
                                                      item.projects[0]}
                                                  </p>
                                                </div>
                                              </div>
                                            </Cards>
                                          </div>
                                        </>
                                      );
                                    }}
                                  </Draggable>
                                </>
                              );
                            })}
                        </div>
                      );
                    }}
                  </Droppable>
                </Grid>
              );
            })}
          </DragDropContext>
        )}
        {taskCompleted ? (
          <div className="bird-container bird-container--one">
            <div className="bird bird--one"></div>
          </div>
        ) : null}
      </Grid>

      {popup && (
        <TaskDetailPopup
          open={popup}
          onClose={closeTaskPopup}
          row={selectedRow}
          Tasks={getallTask}
          users={allUsers}
        />
      )}
      <Dialog
        open={calender}
        onClose={handleCalender}
        fullWidth
        fullScreen
        TransitionComponent={Transition}>
        <DialogContent>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleCalender}
            aria-label="close">
            <CloseIcon />
          </IconButton>
          <Stack>
            <Body>
              <FullCalendar
                firstDay={1}
                locale="en"
                height={700}
                plugins={[dayGridPlugin]}
                events={events}
                // eventClick={(info) => applyModel("leaveModal", info)}
              />
            </Body>
          </Stack>
        </DialogContent>
      </Dialog>
    </DashboardLayout>
  );
};

export default Task;
