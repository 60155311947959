import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  InputLabel,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import S3FileUpload from "react-s3/lib/ReactS3";
import { getCurrentDate } from "../../user portal/reuseable components";
import { saveProject, updateProject } from "../../apiservices/project";
import { getTeamsByCompany } from "../../apiservices/team";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
const AddNewProject = ({ closeTeamPopup, editRow, isEdit, getAllProjects }) => {
  const schema = yup
    .object({
      projectName: yup.string().required("*Project Name is required"),
    })
    .required();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [state, setState] = useState({
    checked: false,
    members: [],
    selected: editRow ? editRow.team : "",
    imageUrl: "",
    projectName: editRow ? editRow.name : "",
  });
  const [displayImg, setdisplayImg] = useState("");
  const config = {
    bucketName: "infini8ai-images",
    dirName: "photos",
    error: "",
    region: "us-east-1",
    accessKeyId: "AKIA44SH2DQ6PSTL43O7",
    secretAccessKey: "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",
  };

  const CancelBtn = styled(Button)({
    height: "34px",
    width: "136px",
    borderRadius: "4px",
    padding: "8px 50px 8px 50px",
    color: "#A7A7A7",
    background: "#FFFFFF",
    "&:hover": {
      boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.16)",
      color: "#A7A7A7",
    },
    "&:active": {
      background: " #A7A7A7",
      color: "#FFFFFF",
    },
  });
  const SaveBtn = styled(Button)({
    height: "34px",
    width: "136px",
    borderRadius: "4px",
    padding: "8px 50px 8px 50px",
    color: "#F5F5F5",
    background: "#21A49E",
    "&:hover": {
      boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.16)",
      background: "#1B8C87",
      color: "#F5F5F5",
    },
    "&:active": {
      background: " #146E6A",
      color: "#F5F5F5",
    },
  });
  const onSubmit = async (data) => {
    let date = getCurrentDate();
    let url = editRow?.imageUrl;
    if (displayImg) {
      let { location } = await S3FileUpload.uploadFile(state.imageUrl, config);
      url = location;
    }
    let projectData = {
      ...(isEdit ? { _id: editRow._id, _rev: editRow._rev } : {}),
      name: data.projectName,
      team: state.selected,
      imageUrl: url,
      company_code: localStorage.getItem("companyCode"),
      date: date,
    };
    isEdit ? await updateProject(projectData) : await saveProject(projectData);
    isEdit
      ? toast.success("Project edit successfully")
      : toast.success("Project aadded successfully");

    getAllProjects();
    closeTeamPopup();
  };
  const getUser = async () => {
    let data = {
      company_code: localStorage.getItem("companyCode"),
    };
    let res = await getTeamsByCompany(data);
    setState((prev) => ({ ...prev, members: res.teamsArr }));
  };
  const handleImg = (e) => {
    let displayImage = URL.createObjectURL(e.target.files[0]);
    setdisplayImg(displayImage);
    setState((prev) => ({ ...prev, imageUrl: e.target.files[0] }));
  };

  useEffect(() => {
    getUser();
    setValue("projectName", editRow?.name);
  }, []);
  const handleChange = (e, name) => {
    let { value } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };
  return (
    <>
      <Typography
        variant="h5"
        sx={{ textAlign: "center", color: "#21A49E", mb: 4 }}>
        Create new project
      </Typography>
      <Box>
        <TextField
          fullWidth
          label="Add Name"
          type="text"
          {...register("projectName")}
        />
        <Typography sx={{ mb: 3, color: "red", fontSize: "12px" }}>
          {errors.projectName?.message}
        </Typography>
      </Box>

      <Box>
        <TextField fullWidth type="file" sx={{ mb: 3 }} onChange={handleImg} />
      </Box>
      <Box>
        <InputLabel>Select team</InputLabel>

        <Select
          sx={{ height: "40px" }}
          value={state.selected}
          fullWidth
          onChange={(e) => handleChange(e, "selected")}>
          {state.members.map((item) => (
            <MenuItem value={item.teamName}>{item.teamName}</MenuItem>
          ))}
        </Select>
      </Box>
      <Stack direction="row" sx={{ mt: 3 }} justifyContent="end">
        <CancelBtn onClick={() => closeTeamPopup()} sx={{ mx: 3 }}>
          Cancel
        </CancelBtn>
        <SaveBtn onClick={handleSubmit(onSubmit)}>Done</SaveBtn>
      </Stack>
    </>
  );
};

export default AddNewProject;
