import React from "react";
import Modal from "@mui/material/Modal";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { styled } from "@mui/system";
import Member from "../../assets/images/team/profile.png";
window.Buffer = window.Buffer || require("buffer").Buffer;

const ViewMembers = ({ openViewMembers, closeViewMember, teamMembers }) => {
  const style = {
    position: "absolute",
    height: "600px",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: "#FFFFFF",
    boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.2)",
    borderRadius: "12px",
    minWidth: "400px",
    p: 4,
  };
  const MemberCard = styled(Grid)({
    borderRadius: "10px",
    minWidth: "150px",
    height: "220px",
    borderBottom: "2px solid #67748E",
    background: "#FFFFFF",
    color: "#737373",
    "&:hover": {
      borderWidth: "1px 1px 4px 1px",
      borderStyle: "solid",
      borderColor: "#04B2AE",
      borderRadius: "10px",
      color: "#04B2AE",
    },
  });
  const Border = styled(Stack)({
    border: " 1px solid ",
    // color: "#67748E",
    boxShadow: "0px 2.57px rgba(0, 0, 0, 0.3)",
    height: "100px",
    width: "100px",
    borderRadius: "10px",
    justifyContent: "center",
  });
  return (
    <div>
      <Modal
        open={openViewMembers}
        onClose={closeViewMember}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Stack alignItems="center">
            <Typography
              variant="h4"
              sx={{ color: "#21A49E", fontWeight: "bold" }}
            >
              Project Members:[
              {teamMembers ? teamMembers.length : "No Member Exist"}]
            </Typography>
          </Stack>
          <Grid
            container
            sx={{
              m: 3,
              px: 3,
            }}
          >
            {teamMembers?.map((item) => (
              <MemberCard
                item
                xs={3}
                sx={{
                  mx: 1,
                  mb: 3,
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <Stack alignItems="center">
                  <Border>
                    <img
                      src={item.imageUrl ? item.imageUrl : Member}
                      alt="img"
                      height="100px"
                      width="100px"
                      style={{ borderRadius: "10px" }}
                    ></img>
                  </Border>
                </Stack>
                <Box
                  sx={{
                    borderTop: 1,
                    width: "50px",
                    marginTop: "10px",
                    marginLeft: "40px",
                    marginBottom: "15px",
                  }}
                />
                <Stack direction="column" alignItems="center">
                  <Typography
                    sx={{ fontSize: "10px", px: 1, lineBreak: "anywhere" }}
                  >
                    {item.email}
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      color: "#67748E",
                      fontSize: "12px",
                    }}
                  >
                    {item.name}
                  </Typography>
                </Stack>
              </MemberCard>
            ))}
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};

export default ViewMembers;
