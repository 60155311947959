import React from "react";
import StockChain1CWhiteLogomark from "../../../assets/images/stock/StockChain1CWhiteLogomark.svg";
import illustrationLogistics from "../../../assets/images/stock/illustrationLogistics.svg";
import Problemillustration from "../../../assets/images/stock/Problemillustration.png";
import stockMarket1 from "../../../assets/images/stock/stockMarket1.png";
import StockChain_Illustration1 from "../../../assets/images/stock/StockChain_Illustration1.png";
import Group48098343 from "../../../assets/images/businesspal/Group48098343.png";
import Group48098310 from "../../../assets/images/businesspal/Group48098310.png";
import questionimg from "../../../assets/images/businesspal/questionimg.png";
import monitorimg from "../../../assets/images/businesspal/monitorimg.png";
import leftu from "../../../assets/images/businesspal/leftu.png";
import rightu from "../../../assets/images/businesspal/rightu.png";
import Union from "../../../assets/images/businesspal/Union.png";
import businesstopimgfullnew from "../../../assets/images/businesspal/businesstopimgfullnew.png";
import uiuxfull from "../../../assets/images/businesspal/uiuxfull.png";
import { right } from "@popperjs/core";


const BusinessSection1 = () => {
  let url = "https://infini8ai-images.s3.amazonaws.com/stock";
  return (
    <div id="stockhero-part" style={{backgroundColor:"white"}}>
      <div style={{backgroundColor:"#E1FCFE", minHeight:"600px"}}>
        <div className="container" style={{maxWidth:"100%"}}>
          <div style={{display:"flex", flexDirection:"column", gap:"2rem"}}>
          <div className="StockChainHeader" >
            <div className="row businesspal-top-img" style={{display:"flex", justifyContent:"flex-end", position:"absolute", }}>
              <div className="col-md-3">
              <img
                    src={Union}
                    className="businesspal-img"
                    // style={{maxWidth:"185%"}}
                    alt="logo"
                  />
              </div>
            </div>
            <div
              className="row"
              style={{ alignItems: "center", justifyContent: "space-between" }}
            >
              <div className="col-md-4 mt-5 p-0" >
                <div className="hero-images" style={{display:"flex",justifyContent:"start"}}>
                  <img
                    src={businesstopimgfullnew}
                    style={{maxWidth:"70%"}}
                    class="rounded StockLogo1 float-left"
                    alt="logo"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="mt-3 mx-5 "><img src={uiuxfull} style={{maxWidth:"20%"}}/></div>
          
          <div className="StockChainHero">
            <div
              className="row"
              style={{
                alignItems: "center",
                justifyContent: "space-around",
                marginTop: "-55px"
              }}
            >
              <div className="col-md-5"style={{display:"flex", flexDirection:"column", gap:"1rem"}} >
                <div className="StockChainHeroText1">
                  <p  style={{
                fontSize: "40px",
                font: "poppins",
                fontWeight: 700,
                color: "#16CAD5",
              }}>BusinessPal</p>
                  <p>
                  Manage your business professionally with BusinessPal through the best Web and Mobile Apps for your billing, inventory, accounting etc.
                  </p>
                </div>
                <div className="StockChainHeroText2">
                  <p>
                  Join the digital revolution and bring your business online.
                  </p>
                </div>
              </div>
              <div className="col-md-5">
                <div
                  className="StockChainHeroImg "
               
                >
                  <img
                    src={monitorimg}
                    className="illustrationLogistics "
                    alt="illustrationLogistics"
                    style={{position:"relative", width:"100%"}}
                  />
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>

        <div className="row u-img" style={{display:"flex", justifyContent:"space-between", position:"relative"}}>
          <div className="col-md-2">
            <img src={leftu}  style={{maxWidth:"40%"}}/>
          </div>
          <div className="col-md-2" style={{ display:"flex", justifyContent:"flex-end"}}>
            <img src={rightu}   style={{ maxWidth:"40%", }}/>
          </div>
        </div>
      </div>
      <div id="StockProblemStatement" style={{paddingTop:"4rem",paddingBottom:"4rem"}}>
        <div className="container">
          <div
            className="row "
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div className="col-md-9">
              <div className="ProblemStatementCard" style={{padding:0}}>
                <div style={{ display: "flex", justifyContent: "center",alignItems:"flex-end" , gap:"1rem"}}>
                  <img src={questionimg} width="10%" />
                  <div
                    class="card text-center"
                    style={{ backgroundColor: "#ECFEFE",height:"50px" }}
                  >
                    <h5
                      style={{
                        fontWeight: 600,
                        fontSize: "41px",
                        color: "#16CAD5",
                      }}
                    >
                      <span style={{ color: "#000000" }}>Problem </span>
                      Statement
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="ProblemStatementImg">
                <div>
                  <div className="container  ">
                    <p className="fs-3 my-4 lh-base text-center ">
                      Small and medium-sized businesses often face challenges in
                      efficiently managing their operations, including sales,
                      inventory, billing, invoicing, and financial
                      record-keeping. Traditional methods are time-consuming,
                      prone to errors, and lack integration, leading to
                      inefficiencies and increased operational costs.
                    </p>
                    <p  className="fs-3 my-4 lh-base text-center ">
                      Businesses need a comprehensive and integrated solution
                      that streamlines these operations, enhances accuracy, and
                      provides real-time insights. The lack of such a solution
                      can lead to missed sales opportunities, stockouts or
                      overstock, delayed payments, and financial discrepancies.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="row "
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop:"3rem"
            }}
          >
            <div className="col-md-9">
              <div className="ProblemStatementCard" style={{padding:0}}>
                <div style={{ display: "flex", justifyContent: "center",alignItems:"flex-end", gap:"1rem" }}>
                  <div
                    class="card text-center"
                    style={{ backgroundColor: "#ECFEFE",height:"50px"  }}
                  >
                    <h5
                      style={{
                        fontWeight: 600,
                        fontSize: "41px",
                        color: "#16CAD5",
                      }}
                    >
                      <span style={{ color: "#000000" }}>Possible </span>
                      Solution
                    </h5>
                  </div>
                  <img src={Group48098310} width="10%" />
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="ProblemStatementImg">
                <div>
                  <div className="container py-2 ">
                    <p className="fs-3 my-4 lh-base text-center ">
                      To address the challenges faced by small and medium-sized
                      businesses in managing their operations, BusinessPal
                      offers an integrated platform that consolidates essential
                      business functions into one seamless solution. By
                      providing real-time sales and inventory tracking,
                      automated billing and invoicing, and efficient tools for
                      creating quotations, BusinessPal simplifies and
                      streamlines business processes. Additionally, it offers
                      daily bookkeeping capabilities, customizable bill
                      printing, and detailed ledger management. This
                      comprehensive approach not only reduces errors and
                      enhances operational efficiency but also provides valuable
                      insights into business performance, empowering businesses
                      to focus on growth and customer satisfaction.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="row "
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop:"2rem"
            }}
          >
            <div className="col-md-9">
              <div className="ProblemStatementCard" style={{padding:0}}>
                <div style={{ display: "flex", justifyContent: "center",alignItems:"flex-end", gap:"1rem" }}>
                  <img src={stockMarket1} width="10%" />
                  <div
                    class="card text-center"
                    style={{ backgroundColor: "#ECFEFE",height:"50px"  }}
                  >
                    <h5
                      style={{
                        fontWeight: 600,
                        fontSize: "41px",
                        color: "#000000",
                      }}
                    >
                      <span style={{}}>Business </span>Opportunity
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="ProblemStatementImg">
                <div>
                  <div className="container py-4 ">
                    <p className="fs-3 my-4 lh-base text-center ">
                      As businesses increasingly seek efficient and integrated
                      solutions to manage their operations, BusinessPal is
                      uniquely positioned to capitalize on this growing market
                      demand. By offering a comprehensive platform that
                      streamlines sales, inventory, billing, invoicing, and
                      financial record-keeping, BusinessPal addresses critical
                      pain points for small and medium-sized enterprises. This
                      creates a significant market opportunity to attract
                      businesses looking to improve operational efficiency,
                      reduce errors, and gain valuable insights into their
                      operations. Leveraging these features, BusinessPal can
                      drive substantial adoption, positioning itself as a vital
                      tool for business growth and customer satisfaction.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessSection1;
