import React, { useEffect, useState } from "react";
import DashboardLayout from "../../layouts/Dashboard";
import DashboardNavbar from "../../layouts/Navbars/DashboardNavbar";
import { Box, CircularProgress } from "@mui/material";
import { Typography, styled, Grid, Stack, TextField } from "@mui/material";
import { Title } from "../dashboard/style";
import ClockImg from "../../assets/images/clock img.png";
import moment from "moment";
import { saveTimeTrack } from "./api";
import Repo from "../../Repository/Repo";
import Toaster from "../../components/Toaster";
import Timer from "./Timer";

const GridBox = styled(Grid)({
  display: "flex",
  alignItems: "center",
  minHeight: "60px",
});
const ItemBox = styled(Box)(({ theme }) => ({
  minHeight: "60px",
  display: "flex",
  alignItems: "center",
  padding: "0 43px",
  borderRadius: "8px",
  background: "#FFFFFF",
  margin: "28px 0",
  borderLeft: "2px solid #04B2AE",
  borderRight: "2px solid #04B2AE",
  [theme.breakpoints.down("md")]: {
    padding: "10px",
  },
}));
const Text = styled(Typography)({
  fontFamily: "Dosis",
  fontWeight: 400,
  fontSize: "16px",
  color: "#ffffff",
});

function TimeTracking() {
  const [state, setState] = useState({
    startDate: "",
    endDate: "",
  });
  const [loading, setLoading] = useState(false);
  const [selectedTime, setSelectedTime] = useState(null);
  const [user, setUser] = useState([]);

  useEffect(() => {
    const { startDate, endDate } = state;
    if ((startDate && endDate) || (!startDate && !endDate)) {
      getTimeTrack();
    }
  }, [state.startDate, state.endDate]);

  const getTimeTrack = async () => {
    try {
      setLoading(true);
      const { startDate, endDate } = state;
      let start =
        startDate && endDate ? moment(startDate).format("DD-MM-YYYY") : "";
      let end =
        startDate && endDate ? moment(endDate).format("DD-MM-YYYY") : "";
      let { data } = await Repo.time_track({
        request: {
          method: "getEmployeeTimeTracks",
          data: {
            employeeId: localStorage.getItem("userEmail"),
            startDate: start,
            endDate: end,
          },
        },
      });
      setLoading(false);
      if (data.response.data.timeTracksArr) {
        let today = moment().format("DD-MM-YYYY");
        let arr = data.response.data.timeTracksArr;
        setUser(arr);
        if (arr[0].date == today) {
          if (arr[0]?.clockedIn) {
            let length = arr[0]?.clockHitsory.length - 1;
            let t = arr[0].clockHitsory[length].time;
            setSelectedTime(t);
          }
        }
      } else {
        // Toast("error", "Error");
      }
    } catch (err) {
      setLoading(false);
      console.log("err:", err);
      // Toast("error", "Error");
    }
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };

  const handleCheckIn = async () => {
    try {
      setLoading(true);
      let time = Math.floor(Date.now() / 1000);
      setSelectedTime(time);
      let res = await saveTimeTrack({ clock: "clockIn", time });
      if (res.response.data.timeTractId) {
        Toaster("success", "Clocked-In Successfully");
        setLoading(false);
        getTimeTrack();
      } else {
        Toaster("error", "Error");
        setLoading(false);
      }
    } catch (err) {
      console.log("err:", err);
      Toaster("error", "Error");
      setLoading(false);
    }
  };

  const handleCheckOut = async (arg = "") => {
    try {
      setLoading(true);
      setSelectedTime(null);
      let time = Math.floor(Date.now() / 1000);
      let res = await saveTimeTrack({ clock: "clockOut", time, id: arg });
      if (res.response.status.statusCode == 200) {
        Toaster("success", "Clocked-Out Successfully");
        setLoading(false);
        getTimeTrack();
      } else {
        Toaster("error", "Error");
        setLoading(false);
      }
    } catch (err) {
      console.log("err:", err);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar>
        <Grid container>
          <Grid item xs={1} sx={{ display: "flex", alignItems: "center" }}>
            <img
              style={{ marginLeft: "10px" }}
              height="50px"
              src={ClockImg}
              alt=""
            />
          </Grid>
          <Grid
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            item
            xs={7.5}>
            <Title>My Clock</Title>
          </Grid>
          <Grid item xs={2.5} sx={{ display: "flex", alignItems: "center" }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              spacing={1}>
              <Stack>
                <Typography sx={{ fontSize: "12px" }}>From</Typography>
                <TextField
                  size="small"
                  type="date"
                  variant="outlined"
                  name="startDate"
                  onChange={handleInput}
                />
              </Stack>
              <Stack>
                <Typography sx={{ fontSize: "12px" }}>To</Typography>
                <TextField
                  size="small"
                  type="date"
                  variant="outlined"
                  name="endDate"
                  onChange={handleInput}
                />
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </DashboardNavbar>
      <Timer
        user={user}
        clockIn={handleCheckIn}
        clockOut={(e) => handleCheckOut(e)}
        time={selectedTime}
        load={loading}
      />
      {loading && (
        <Stack alignItems="center" justifyContent="center">
          <CircularProgress size={50} />
        </Stack>
      )}
      {!loading &&
        user?.map((item, i) => {
          return (
            <ItemBox key={i}>
              <Grid container spacing={2}>
                <GridBox item md={3} sm={6} xs={12}>
                  <Stack direction="row" alignItems="center" spacing={0.5}>
                    <Text sx={{ color: "#67748E", fontWeight: 600, mr: 0.5 }}>
                      Date :
                    </Text>
                    <Text sx={{ color: "#67748E" }}>{item?.date}</Text>
                  </Stack>
                </GridBox>

                <GridBox item md={3} sm={6} xs={12}>
                  <Stack direction="row" alignItems="center" spacing={0.5}>
                    <Text sx={{ color: "#67748E", fontWeight: 600, mr: 0.5 }}>
                      Hours Consumed :
                    </Text>

                    <Text sx={{ color: "#67748E" }}>{item?.consumed_time}</Text>
                  </Stack>
                </GridBox>
                <GridBox item md={3} sm={6} xs={12}>
                  <Stack direction="row" alignItems="center" spacing={0.5}>
                    <Text sx={{ color: "#67748E", fontWeight: 600, mr: 0.5 }}>
                      Pending Hours :
                    </Text>
                    <Text sx={{ color: "#67748E" }}>{item?.pending_time}</Text>
                  </Stack>
                </GridBox>
                <GridBox item md={3} sm={6} xs={12}>
                  <Stack direction="row" alignItems="center" spacing={0.5}>
                    <Text sx={{ color: "#67748E", fontWeight: 600, mr: 0.5 }}>
                      Total Hours :
                    </Text>
                    <Text sx={{ color: "#67748E" }}>{item.total_time}</Text>
                  </Stack>
                </GridBox>
              </Grid>
            </ItemBox>
          );
        })}
    </DashboardLayout>
  );
}

export default TimeTracking;
