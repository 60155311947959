import React from "react";

const NaseemSection3 = () => {
  let url = "https://infini8ai-images.s3.amazonaws.com/naseem-casestudy";
  return (
    <div>
      <div>
        <div id="bg-case-about1" className="padding-10 0 ">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="naseemAbout">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="naseemAboutContent">
                        <h3>About</h3>
                        <p>
                          Mr. Ijaaz Ahmed is a thirty-two-year-old married man
                          with two children. He works as a school executive in
                          Rawalpindi. He manages and oversees six school
                          branches across Rawalpindi.
                        </p>
                        <p>
                          He wishes to do his work without moving around and he
                          wants an easier way to accomplish his tasks without
                          visiting each school branch individually.
                        </p>
                        <p>
                          He appreciates technology as it is more centralized
                          management system where, he could do most of his work
                          from a single platform without moving around.
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="row" style={{ alignItems: "center" }}>
                        <div className="col-md-5">
                          <div className="AboutVector">
                            <img className="" src={`${url}/Group1492.svg`} />
                          </div>
                        </div>
                        <div className="col-md-7">
                          <div className="AboutVectorText">
                            <div className="card">
                              <h5 className="card-title">Mr. Ijaaz Ahmed</h5>
                              <div className="card-body">
                                <p className="card-text">
                                  <strong>Age:</strong> 32
                                </p>
                                <p className="card-text">
                                  <strong>Work:</strong> School Executive
                                </p>
                                <p className="card-text">
                                  <strong>Family:</strong> Married +2
                                </p>
                                <p className="card-text">
                                  <strong>Location:</strong> Rawalpindi, PK
                                </p>
                              </div>
                            </div>
                            <p className="card-text">
                              “ Personal Life satisfaction is when your job’s
                              much more relaxing. ”
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row pt-5">
                    <div className="col-md-12">
                      <div className="naseemAboutContent">
                        <h3>Goals</h3>
                        <p>
                          Manage school branches under my supervision from a
                          single platform.
                        </p>
                        <p>Don’t have to travel for day to day routine work.</p>
                        <p>Make life more relaxing by reducing work stress.</p>
                      </div>
                    </div>
                    <div className="col-md-12 pt-5">
                      <div className="naseemAboutContent">
                        <h3>Pain Points</h3>
                        <p>
                          Visiting every branch of school physically even for
                          smaller routine work.
                        </p>
                        <p>
                          More physical paper being used for records every year.
                        </p>
                        <p>
                          Mentally keeping track of everything happening around.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="about2linearBg">
        <div id="bg-case-about2" className="padding-10 0 ">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="naseemAbout">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="naseemAboutContent">
                        <h3>About</h3>
                        <p>
                          Mr. Kamran Rauf is a forty year old married man with
                          two boys and two girls. He has taken the
                          responsibility of a school principal for several years
                          and maintained his positive reputation among
                          community.{" "}
                        </p>
                        <p>
                          He considers his school reputation and education
                          progress a very important task and in order to do so
                          he has to physically engage himself with staff and
                          students in order to review their progress and
                          failures all the time.
                        </p>
                        <p>
                          Mr. Kamran is looking for a solution which could help
                          him oversee school’s day to day progress and act
                          accordingly based on the stats and engage the staff,
                          students and their parents when necessary.
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="row" style={{ alignItems: "center" }}>
                        <div className="col-md-5">
                          <div className="AboutVector">
                            <img className="" src={`${url}/Group1496.svg`} />
                          </div>
                        </div>
                        <div className="col-md-7">
                          <div className="AboutVectorText">
                            <div className="card">
                              <h5 className="card-title">Mr. Kamran Rauf</h5>
                              <div className="card-body">
                                <p className="card-text">
                                  <strong>Age:</strong> 40
                                </p>
                                <p className="card-text">
                                  <strong>Work:</strong> School Principal
                                </p>
                                <p className="card-text">
                                  <strong>Family:</strong> Married +4
                                </p>
                                <p className="card-text">
                                  <strong>Location:</strong> Islamabad, PK
                                </p>
                              </div>
                            </div>
                            <p className="card-text">
                              “ Sometimes, we all need a little inspiration to
                              remind us why we do what we do.”
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row pt-5">
                    <div className="col-md-12">
                      <div className="naseemAboutContent">
                        <h3>Goals</h3>
                        <p>
                          Oversee day to day school progress from a single
                          platform.
                        </p>
                        <p>
                          Minimize the chances of daily physical interaction.
                        </p>
                        <p>
                          Get to review to the point stats and act accordingly
                          based on the data.
                        </p>
                      </div>
                    </div>
                    <div className="col-md-12 pt-5">
                      <div id="painPoints" className="naseemAboutContent">
                        <h3>Pain Points</h3>
                        <p>
                          Time it takes to interact with the staff in order to
                          get progress reports.
                        </p>
                        <p>
                          Not being sure about the stats and reports being 100
                          percent accurate.
                        </p>
                        <p>
                          Not being able to get the data right on the spot when
                          needed.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div id="bg-case-about3" className="padding-10 0 ">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="naseemAbout">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="naseemAboutContent">
                        <h3>About</h3>
                        <p>
                          Miss. Nadia Samin is a twenty seven year old
                          passionate school teacher. She took this
                          responsibility because of her love for education and
                          passing knowledge to the next generation.{" "}
                        </p>
                        <p>
                          She teaches a bunch of classes on a daily basis and
                          sometimes finds it difficult, to maintain paperwork
                          and reports of daily routine work along with students
                          progress reports.{" "}
                        </p>
                        <p>
                          Ms. Nadia and other from the same school are looking
                          for a solution which could help them manage their
                          classes from a single platform in a simple yet
                          efficient manner.
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="row" style={{ alignItems: "center" }}>
                        <div className="col-md-5">
                          <div className="AboutVector">
                            <img className="" src={`${url}/Group1483.svg`} />
                          </div>
                        </div>
                        <div className="col-md-7">
                          <div className="AboutVectorText">
                            <div className="card">
                              <h5 className="card-title">Ms. Nadia Samin</h5>
                              <div className="card-body">
                                <p className="card-text">
                                  <strong>Age:</strong> 21
                                </p>
                                <p className="card-text">
                                  <strong>Work:</strong> School Teacher
                                </p>
                                <p className="card-text">
                                  <strong>Family:</strong> Single
                                </p>
                                <p className="card-text">
                                  <strong>Location:</strong> Lahore, PK
                                </p>
                              </div>
                            </div>
                            <p className="card-text">
                              “ The duties of a teacher are neither few or
                              small, but they elevate the mind and enhance
                              character.”
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row pt-5">
                    <div className="col-md-12">
                      <div className="naseemAboutContent">
                        <h3>Goals</h3>
                        <p>
                          Managing all of the classes in a better way rather
                          than using traditional method.
                        </p>
                        <p>
                          Being able to convey her message and able to teach
                          those students who aren’t around.
                        </p>
                        <p>
                          Be more efficient and modern in her ways of teaching
                          and overseeing classes altogether.
                        </p>
                      </div>
                    </div>
                    <div className="col-md-12 pt-5">
                      <div className="naseemAboutContent">
                        <h3>Pain Points</h3>
                        <p>
                          Student progress taking a step backwards when they
                          aren’t present in class.
                        </p>
                        <p>
                          Being overwhelmed with a bunch of tasks and keeping
                          records manually.
                        </p>
                        <p>
                          Not being able to show progress to parents, whenever
                          needed because of their schedule.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="about4linearBg">
        <div id="bg-case-about4" className="padding-10 0 ">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="naseemAbout">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="naseemAboutContent">
                        <h3>About</h3>
                        <p>
                          Mrs. Noreen Iqbal is a thirty nine year old married
                          women with two girls and one boy. She is protective of
                          her children and wants them to succeed in life and to
                          get better education.
                        </p>
                        <p>
                          She is mostly busy in her daily routine being a
                          housewife, taking care of the entire family which
                          makes it difficult for her to visit her children`s
                          school every other day in order to get progress
                          reports and feedback from their teachers.
                        </p>
                        <p>
                          She is curious and very interested in the idea of
                          having an application, which could help tracking her
                          children’s daily progress and being aware of any
                          announcements made by teachers or staff in general.
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="row" style={{ alignItems: "center" }}>
                        <div className="col-md-5">
                          <div className="AboutVector">
                            <img className="" src={`${url}/Group1497.svg`} />
                          </div>
                        </div>
                        <div className="col-md-7">
                          <div className="AboutVectorText">
                            <div className="card">
                              <h5 className="card-title">Mrs. Noreen Iqbal</h5>
                              <div className="card-body">
                                <p className="card-text">
                                  <strong>Age:</strong> 39
                                </p>
                                <p className="card-text">
                                  <strong>Work:</strong> House Wife
                                </p>
                                <p className="card-text">
                                  <strong>Family:</strong> Married +3
                                </p>
                                <p className="card-text">
                                  <strong>Location:</strong> jhelum, PK
                                </p>
                              </div>
                            </div>
                            <p className="card-text">
                              “ The memories we make with our family is
                              everything.”
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row pt-5">
                    <div className="col-md-12">
                      <div className="naseemAboutContent">
                        <h3>Goals</h3>
                        <p>
                          Making sure her kids get better education and succeed
                          in life.
                        </p>
                        <p>
                          Being able to track her kids progress without worrying
                          about having to visit herself.
                        </p>
                        <p>
                          Getting feedback and stats directly from the source.{" "}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-12 pt-5">
                      <div className="naseemAboutContent">
                        <h3>Pain Points</h3>
                        <p>
                          Student progress taking a step backwards when they
                          aren’t present in class.
                        </p>
                        <p>
                          Being overwhelmed with a bunch of tasks and keeping
                          records manually.
                        </p>
                        <p>
                          Not being able to show progress to parents, whenever
                          needed because of their schedule.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="empathyMaplinearBg">
        <div id="bg-case-empathyMap" className="padding-10 0 ">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="empathyMapContent">
                  <div className="row">
                    <div className="col-md-10 offset-md-1">
                      <div className="empathyMapLogo">
                        <div className="row">
                          <div className="col-md-4">
                            <img
                              src={`${url}/charGroup1.png`}
                              className="charGroup1"
                              alt="charGroup1"
                            />
                          </div>
                          <div className="col-md-4">
                            <div className="row">
                              <div className="col-md-12">
                                <img
                                  src={`${url}/handsUnite.png`}
                                  className="handsUnite"
                                  alt="handsUnite"
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-12">
                                <img
                                  src={`${url}/empathyMap.png`}
                                  className="empathyMap"
                                  alt="empathyMap"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <img
                              src={`${url}/charGroup2.png`}
                              className="charGroup2"
                              alt="charGroup2"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row text-center">
                    <div className="col-md-12">
                      <div className="empathyMapLogo">
                        <div className="row">
                          <div className="col-lg-4 col-md-12">
                            <img
                              src={`${url}/leftVector.svg`}
                              className="leftVector"
                              alt="leftVector"
                            />
                          </div>
                          <div className="col-lg-4 col-md-12">
                            <div className="row">
                              <div className="col-md-12">
                                <img
                                  src={`${url}/doCard.png`}
                                  className="doCard"
                                  alt="doCard"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-12">
                            <img
                              src={`${url}/rightVector.svg`}
                              className="rightVector"
                              alt="rightVector"
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-4 col-md-12">
                            <div className="row">
                              <div className="col-md-12">
                                <img
                                  src={`${url}/seeCard.png`}
                                  className="seeCard"
                                  alt="seeCard"
                                />
                              </div>
                              <div className="col-md-12">
                                <img
                                  src={`${url}/verticaldownArrow.svg`}
                                  className="verticaldownArrow"
                                  alt="verticaldownArrow"
                                />
                              </div>
                              <div className="col-md-12">
                                <img
                                  src={`${url}/painCard.png`}
                                  className="painCard"
                                  alt="painCard"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-12">
                            <img
                              src={`${url}/kidseight.png`}
                              className="kidseight"
                              alt="kidseight"
                            />
                          </div>
                          <div className="col-lg-4 col-md-12">
                            <div className="row">
                              <div className="col-md-12">
                                <img
                                  src={`${url}/hearCard.png`}
                                  className="hearCard"
                                  alt="hearCard"
                                />
                              </div>
                              <div className="col-md-12">
                                <img
                                  src={`${url}/verticaldownArrow.svg`}
                                  className="verticaldownArrow"
                                  alt="verticaldownArrow"
                                />
                              </div>
                              <div className="col-md-12">
                                <img
                                  src={`${url}/gainCard.png`}
                                  className="gainCard"
                                  alt="gainCard"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NaseemSection3;
