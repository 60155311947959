import React from "react";
import Union from "../../../assets/images/optimus/Union.svg";
import Group334 from "../../../assets/images//stock/Group334.png";
import Group336 from "../../../assets/images/stock/Group336.png";
import Group332 from "../../../assets/images/stock/Group332.png";
import Group344 from "../../../assets/images/stock/Group344.png";

const StockSection3 = () => {
  let url = "https://infini8ai-images.s3.amazonaws.com/stock";
  return (
    <div>
      <div id="StockAbouts">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="StockLearningContentMain">
                <p>User Personas</p>
              </div>
            </div>
          </div>
          <div className="firstAboutContent">
            <div className="row" style={{ alignItems: "center" }}>
              <div className="col-md-5"  >
                <div style={{display:"flex", flexDirection:"column",}}>
                  <div className="aboutImg">
                    <img src={Group334} className="Group334" alt="Group334" />
                  </div>
                  <div className="aboutText">
                    <p>
                      Mr. Hassan Mir is a thirty seven year old logistic company
                      owner in islamabad. He started working in this field about
                      a decade ago and persues more success in coming years.
                    </p>
                    
                  </div>
                  <div className="aboutText">
                  <p>
                      He’s well aware of technological advancements and how
                      everything is movings towards digital platforms. He
                      believes that his company can benefit from it as well.
                    </p>

                    </div>
                </div>
              </div>
              <div className="col-md-7">
                <div className="aboutgroupImg">
                  <img
                    src={`${url}/hassanMir.svg`}
                    className="hassanMir"
                    alt="hassanMir"
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div id="questionAnswering">
                  <div className="aboutImg">
                    <img src={Group336} className="Group336" alt="Group336" />
                  </div>
                  <div className="aboutText">
                    <div className="generallyAboutContent">
                      <ul className="d-flex ml-3 ">
                        <li className="list-unstyled mr-1">
                          <img src={Union} className="Union" alt="union" />
                        </li>
                        <li className="list-unstyled ml-1">
                          <p>
                            {" "}
                            Earn more success and respect for his business in
                            coming years.
                          </p>
                        </li>
                      </ul>
                      <ul className="d-flex ml-3 ">
                        <li className="list-unstyled mr-1">
                          <img src={Union} className="Union" alt="union" />
                        </li>
                        <li className="list-unstyled ml-1">
                          <p>
                            Incorporate technology in his business to stay
                            up-to-date with current times.
                          </p>
                        </li>
                      </ul>
                      <ul className="d-flex ml-3 ">
                        <li className="list-unstyled mr-1">
                          <img src={Union} className="Union" alt="union" />
                        </li>
                        <li className="list-unstyled ml-1">
                          <p>
                            Provide better experience to his client and
                            employees.
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="aboutImg">
                    <img src={Group332} className="Group332" alt="Group332" />
                  </div>
                  <div className="aboutText">
                    <div className="generallyAboutContent">
                      <ul className="d-flex ml-3 ">
                        <li className="list-unstyled mr-1">
                          <img src={Union} className="Union" alt="union" />
                        </li>
                        <li className="list-unstyled ml-1">
                          <p>
                            Mishandling and Misplacing shipments happens very
                            often.
                          </p>
                        </li>
                      </ul>
                      <ul className="d-flex ml-3 ">
                        <li className="list-unstyled mr-1">
                          <img src={Union} className="Union" alt="union" />
                        </li>
                        <li className="list-unstyled ml-1">
                          <p> Improper management system to avoid theft.</p>
                        </li>
                      </ul>
                      <ul className="d-flex ml-3 ">
                        <li className="list-unstyled mr-1">
                          <img src={Union} className="Union" alt="union" />
                        </li>
                        <li className="list-unstyled ml-1">
                          <p>
                            Lack of proper shipment tracking system to ensure
                            safe and on-time delivery.
                          </p>
                        </li>
                      </ul>
                      <ul className="d-flex ml-3 ">
                        <li className="list-unstyled mr-1">
                          <img src={Union} className="Union" alt="union" />
                        </li>
                        <li className="list-unstyled ml-1">
                          <p>
                            Points mentioned above resulting in financial loss.
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="firstAboutContent">
            <div className="row" style={{ alignItems: "center" }}>
              <div className="col-md-5">
                <div style={{display:"flex", flexDirection:"column",}}>
                  <div className="aboutImg">
                    <img src={Group334} className="Group334" alt="Group334" />
                  </div>
                  <div className="aboutText">
                    <p>
                    Mr. Muneeb Nawaz is a twenty nine year old
                    man, who’s been working as a courier
                    in a local cargo company in Rawalpindi
                    for the past couple of years.

                    </p>
                   
                  </div>
                  <div className="aboutText">
                  <p>
                      
                      He believes incorporating technology
                      in his daily routine work will help him in
                      becoming more efficient in his work.
                      </p>
                      </div>
                </div>
              </div>
              <div className="col-md-7">
                <div className="aboutgroupImg">
                  <img
                    src={`${url}/muneebNawaz.svg`}
                    className="muneebNawaz"
                    alt="muneebNawaz"
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div id="questionAnswering">
                  <div className="aboutImg">
                    <img src={Group336} className="Group336" alt="Group336" />
                  </div>
                  <div className="aboutText">
                    <div className="generallyAboutContent">
                      <ul className="d-flex ml-3 ">
                        <li className="list-unstyled mr-1">
                          <img src={Union} className="Union" alt="union" />
                        </li>
                        <li className="list-unstyled ml-1">
                          <p> Being more efficient in his work.</p>
                        </li>
                      </ul>
                      <ul className="d-flex ml-3 ">
                        <li className="list-unstyled mr-1">
                          <img src={Union} className="Union" alt="union" />
                        </li>
                        <li className="list-unstyled ml-1">
                          <p>Finding better ways to reach deadlines. </p>
                        </li>
                      </ul>
                      <ul className="d-flex ml-3 ">
                        <li className="list-unstyled mr-1">
                          <img src={Union} className="Union" alt="union" />
                        </li>
                        <li className="list-unstyled ml-1">
                          <p>
                            Getting rid of manual paperwork habit and
                            transfering his work over digital platform.
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="aboutImg">
                    <img src={Group332} className="Group332" alt="Group332" />
                  </div>
                  <div className="aboutText">
                    <div className="generallyAboutContent">
                      <ul className="d-flex ml-3 ">
                        <li className="list-unstyled mr-1">
                          <img src={Union} className="Union" alt="union" />
                        </li>
                        <li className="list-unstyled ml-1">
                          <p>
                            Lots of physical paper being used for manual entries
                            on a daily basis.
                          </p>
                        </li>
                      </ul>
                      <ul className="d-flex ml-3 ">
                        <li className="list-unstyled mr-1">
                          <img src={Union} className="Union" alt="union" />
                        </li>
                        <li className="list-unstyled ml-1">
                          <p>
                            {" "}
                            Time wastage because of daily on-site meetings to
                            recieve tasks.
                          </p>
                        </li>
                      </ul>
                      <ul className="d-flex ml-3 ">
                        <li className="list-unstyled mr-1">
                          <img src={Union} className="Union" alt="union" />
                        </li>
                        <li className="list-unstyled ml-1">
                          <p>
                            Lack of proper shipment tracking system to ensure
                            safe and on-time delivery.
                          </p>
                        </li>
                      </ul>
                      <ul className="d-flex ml-3 ">
                        <li className="list-unstyled mr-1">
                          <img src={Union} className="Union" alt="union" />
                        </li>
                        <li className="list-unstyled ml-1">
                          <p>
                            Points mentioned above resulting in financial loss
                            and credibility as a courier.
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="StockEmpathyMap">
        <div className="container">
          <div className="row">
            <div className="col-md-10 offset-md-1">
              <div className="StockEmpathyImg">
                <img
                  src={`${url}/Group346.png`}
                  className="GroupImg346"
                  alt="Group346"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="StockEmpathyImg">
                <img
                  src={`${url}/Group347.png`}
                  className="GroupImg347"
                  alt="Group347"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="StockEmpathyImg">
                <img
                  src={`${url}/Group348.png`}
                  className="GroupImg348"
                  alt="Group348"
                />
              </div>
            </div>
            <div className="col-md-10 offset-md-1">
              <div className="StockEmpathyImg">
                <img src={Group344} className="GroupImg344" alt="Group344" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="StockEmpathyImg">
                <img
                  src={`${url}/Group349.png`}
                  className="GroupImg349"
                  alt="Group349"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="StockEmpathyImg">
                <img
                  src={`${url}/Group350.png`}
                  className="GroupImg350"
                  alt="Group350"
                />
              </div>
            </div>
            <div className="col-md-10 offset-md-1">
              <div className="StockEmpathyImg">
                <img
                  src={`${url}/iconInfinite.png`}
                  className="iconInfinite"
                  alt="iconInfinite"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StockSection3;
