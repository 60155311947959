import React from "react";
import { useState, useEffect } from "react";
import { CircularProgress, Grid, Stack } from "@mui/material";
import { Box, styled } from "@mui/system";
import VideoLogo from "../../assets/images/meeting/video.png";
import Alarm from "../../assets/images/meeting/alarm.png";
import NoMeeting from "../../assets/images/nomeetings.svg";
import DashboardLayout from "../../layouts/Dashboard";
import DashboardNavbar from "../../layouts/Navbars/DashboardNavbar";
import { getMeetingsByCompany } from "../../apiservices/meeting";

import moment from "moment";
import { TextStyle, Title } from "../dashboard/style";
export const TableHeader = styled(Grid)({
  minHeight: "60px",
  alignItems: "center",
  paddingLeft: "10px",
  borderRadius: "8px",
  marginBottom: "15px",
});
const Meeting = () => {
  const [state, setState] = useState({
    meetingData: [],
    loader: false,
  });

  const Typo = styled(Grid)({
    fontSize: "17px",
  });
  const getMeetings = async () => {
    setState((prev) => ({ ...prev, loader: !prev.loader }));
    let data = {
      company_code: localStorage.getItem("companyCode"),
    };
    let res = await getMeetingsByCompany(data);
    setState((prev) => ({ ...prev, meetingData: res.meetingsArr }));
    setState((prev) => ({ ...prev, loader: !prev.loader }));
  };
  useEffect(() => {
    getMeetings();
  }, []);
  const getRemainingHours = (endTime) => {
    let currentTime = moment().hours();
    let time = moment(endTime, "hh:mm A").format("HH:mm");

    let endHours = moment(time, "HH:mm").hours();
    let diff = endHours - currentTime;
    return diff.toString().includes("-") ? 24 + diff : diff;
  };
  return (
    <DashboardLayout>
      <DashboardNavbar>
        <Stack
          sx={{ height: "100px", mx: 1 }}
          alignItems={"center"}
          direction={"row"}
          justifyContent={"space-between"}>
          <Stack>
            <img src={VideoLogo} alt="img" />
          </Stack>
          <Stack>
            <Title variant="h6" sx={{ fontWeight: "bold" }}>
              Meetings
            </Title>
          </Stack>
          <Stack></Stack>
        </Stack>
      </DashboardNavbar>

      <TableHeader
        container
        sx={{
          background: "#04B2AE",
          color: "#FFFFFF",
          mt: 5,
        }}>
        <Grid xs={0.4} item>
          <TextStyle>#</TextStyle>
        </Grid>
        <Grid xs={3} item>
          <TextStyle>Meeting Name</TextStyle>
        </Grid>
        <Grid xs={3} item>
          <TextStyle>Link/ID</TextStyle>
        </Grid>
        <Grid xs={2} item>
          <TextStyle>Date</TextStyle>
        </Grid>
        <Grid xs={1.5} item>
          <TextStyle>Passcode</TextStyle>
        </Grid>
        <Grid xs={2} item>
          <TextStyle>Starts in</TextStyle>
        </Grid>
      </TableHeader>
      {state.meetingData && state.meetingData.length !== 0 ? (
        state.meetingData &&
        state.meetingData.map((item, index) => (
          <TableHeader
            container
            sx={{
              background: "#FFFFFF",
              color: "#67748E",
              borderLeft: 2,
              borderRight: 2,

              borderColor: "#04B2AE",
            }}>
            <Grid item xs={0.4}>
              <Typo>{index + 1}</Typo>
            </Grid>
            <Grid item xs={3}>
              <Typo>{item.name}</Typo>
            </Grid>
            <Grid item xs={3}>
              <a href={item.meetingUrl} target="_blank" rel="noreferrer">
                <Typo>{Date.now() * (index + 1)}</Typo>
              </a>
            </Grid>
            <Grid item xs={2}>
              <Typo>{item.date}</Typo>
            </Grid>
            <Grid item xs={1.5}>
              <Typo>{item.password}</Typo>
            </Grid>
            <Grid item xs={2}>
              <Box>
                <Typo>
                  <img src={Alarm} alt="alarm" style={{ marginRight: "5px" }} />
                  {item.time}
                </Typo>
                <Typo>
                  <img src={Alarm} alt="alarm" style={{ marginRight: "5px" }} />
                  {item.time && getRemainingHours(item.time)} hours left,
                </Typo>
              </Box>
            </Grid>
          </TableHeader>
        ))
      ) : (
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
          {state.loader ? (
            <Box sx={{ display: "flex" }}>
              <CircularProgress color="success" />
            </Box>
          ) : (
            <img src={NoMeeting} alt="" height="350px" width="400px" />
          )}
        </Grid>
      )}
    </DashboardLayout>
  );
};

export default Meeting;
