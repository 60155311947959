import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { styled } from "@mui/system";

import "../../App.css";
// import { makeStyles } from "@material-ui/styles";
import { Button } from "@mui/material";

const Dialogs = styled(Dialog)({
  minWidth: "300px",
  //   textAlign: "center",
});

const DialogAction = styled(DialogActions)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

function DialogComponent({
  title,
  open,
  onClose,
  onClickCancel,
  onClickYes,
  dialogTitle,
  dialogContent,
  buttonDisAgreeTitle,
  buttonAgreeTitle,
  width = true,
}) {
  return (
    <div>
      <Dialogs
        title={title}
        fullWidth={width}
        open={open}
        onClose={onClose}
        disableEnforceFocus>
        <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
        <DialogContent>{dialogContent}</DialogContent>
        <DialogAction>
          {buttonDisAgreeTitle ? (
            <Button onClick={onClickCancel}>{buttonDisAgreeTitle}</Button>
          ) : null}
          {buttonAgreeTitle ? (
            <Button onClick={onClickYes}>{buttonAgreeTitle}</Button>
          ) : null}
        </DialogAction>
      </Dialogs>
    </div>
  );
}

export default DialogComponent;
