import { CircularProgress } from '@mui/material'
import React from 'react'

const Loader = () => {
  return (
   
     <CircularProgress color="success" />
  
  )
}

export default Loader