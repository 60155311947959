import React from "react";
import Facebook from "../../assets/images/social media/facebook.png";
import Twitter from "../../assets/images/social media/twitter.png";
import Linkedin from "../../assets/images/social media/linkedin.png";
import Email from "../../assets/images/social media/email.png";
import leftChevron from "../../assets/images/social media/left-chevron.png";
import PageLayout from "../../layouts/Page";

function CareerDetails() {
  return (
    <PageLayout>
      <div id="CareerDetail">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col">
                  <div className="BackIcon">
                    <ul>
                      <li>
                        <a className="careerBtn" href="/career">
                          <img
                            src={leftChevron}
                            className="img-responsive"
                            alt="leftChevron"
                          />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col">
                  <div className="SocialMediaIcon">
                    <ul>
                      <li>
                        <a className="Facebook" href="/" target="_blank">
                          <img
                            src={Facebook}
                            className="img-responsive"
                            alt="Facebook"
                          />
                        </a>
                      </li>
                      <li>
                        <a className="Twitter" href="/">
                          <img
                            src={Twitter}
                            className="img-responsive"
                            alt="Twitter"
                            target="_blank"
                          />
                        </a>
                      </li>
                      <li>
                        <a
                          className="Linkedin"
                          target="_blank"
                          href="https://www.linkedin.com/company/infini8ai"
                        >
                          <img
                            src={Linkedin}
                            className="img-responsive"
                            alt="Linkedin"
                          />
                        </a>
                      </li>
                      <li>
                        <a className="Email" href="/" target="_blank">
                          <img
                            src={Email}
                            className="img-responsive"
                            alt="Email"
                          />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="mainJobTitle">
            <div className="row" style={{ alignItems: "center" }}>
              <div className="col-md-8">
                <div className="jobTitle">
                  <h2>Sales Engineer</h2>
                  <ul className="mt-3">
                    <li>
                      <i class="fa fa-map-marker" aria-hidden="true"></i>{" "}
                      Rawalpindi, Punjab, Pakistan
                    </li>
                    <li>
                      <i class="fa fa-tasks" aria-hidden="true"></i> Full-time
                    </li>
                    <li>
                      <i class="fa fa-sitemap" aria-hidden="true"></i> Software
                      Quality Assurance
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-4 mt-4">
                <div className="jobApplyBtn">
                  <a
                    id="outlineapplynow"
                    class="btn btn-outline-primary btn-lg"
                    href="/apply-now"
                  >
                    Apply Now
                  </a>
                </div>
              </div>
            </div>
          </div>
          <hr
            style={{
              marginTop: "18px",
              marginBottom: "18px",
              border: "0",
              borderTop: "1px solid #eeeeee",
            }}
          />
          <div id="jobDescription">
            <div className="row">
              <div className="col-md-8">
                <div className="jobDescriptionContent lh-base">
                  <p>
                    <b>Who we are</b>
                  </p>
                  <p>
                    We are a team of passionate and dedicated individuals
                    building easy-to-use software delivering amazing user
                    experiences, best-in-class functionality, and enterprise
                    scalability. Our software brings assets under control and
                    helps organizations across education, event management, AV
                    media production, engineering, construction, healthcare,
                    manufacturing, rental, and transportation industries improve
                    their profitability and simplify their operations.
                  </p>
                  <p>
                    Some of our amazing customers include Amazon, BBC, Buzzfeed,
                    CNN, Disney, Fox Sports, Intel, NASA, Netflix, Polycom,
                    Petronas, SNAP, Styker, 3M, and Harvard Medical School.
                  </p>
                  <p>
                    <b>Position Overview</b>
                  </p>
                  <p>
                    Part technical ninja, part consultant, sales engineers help
                    customers and partners realize the full potential of our
                    solution during the pre-sales cycle. This requires a strong
                    understanding of what is possible with our suite of asset
                    tracking applications, and building key relationships with
                    both technical and business decision-makers to drive
                    adoption, and create solutions that translate that into
                    improved profitability, simplified operations, and resolved
                    pain points for our customers and partners.
                  </p>
                  <p>
                    <b>Responsibilities</b>
                  </p>
                  <ul>
                    <li>Conduct a thorough discovery process</li>
                    <li>
                      Provide timely and responsive technical advice, support,
                      and product education for sales opportunities
                    </li>
                    <li>
                      Build and deliver technical presentations, demos, and
                      proof of concept trials
                    </li>
                    <li>
                      Engage with the technical contacts within the customer and
                      partner organizations to build relationships and ensure
                      product adoption from pre-sales and beyond
                    </li>
                    <li>
                      Offer best practice recommendations on how to configure
                      and use the software based on customer workflow needs and
                      pain points
                    </li>
                    <li>
                      Own and respond to RFP inquiries with assistance from
                      Sales and Customer Success
                    </li>
                    <li>Assist with implementation and customer acceptance</li>
                    <li>
                      Capture and convey feature requests, enhancements, and
                      bugs to the Product and Development teams
                    </li>
                    <li>
                      Provide onboarding and technical training to partners and
                      the sales organization to ensure proper levels of product
                      and technical knowledge are maintained
                    </li>
                  </ul>
                  <p>
                    <b>Minimum Requirements</b>
                  </p>
                  <ul>
                    <li>
                      Ability to overcome objections and provide appropriate
                      sales positioning
                    </li>
                    <li>
                      3+ years of sales engineering experience with a SaaS
                      product
                    </li>
                    <li>
                      Working knowledge of IT infrastructure, networking, and
                      service management
                    </li>
                    <li>Experience with JamF, In Tune, SCCM is a plus</li>
                    <li>
                      Plus if you have inventory or asset management experience
                    </li>
                    <li>
                      Able to establish rapport, trust, and credibility with
                      technical and non-technical audiences
                    </li>
                    <li>
                      Passionate about helping customers succeed with our
                      software solutions
                    </li>
                    <li>
                      Ability to problem-solve, troubleshoot, and debug issues
                    </li>
                    <li>
                      Excellent verbal and written communication skills. You
                      know what to say and how to say it
                    </li>
                    <li>Keen attention to detail</li>
                  </ul>
                </div>
                <div className="col-md-4"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
}

export default CareerDetails;
