import React from "react";

import Union from "../../../assets/images/optimus/Union.svg";
import Group302 from "../../../assets/images/optimus/Group302.svg";
import Group318 from "../../../assets/images/optimus/Group318.png";
import Group319 from "../../../assets/images/optimus/Group319.png";
import Group320 from "../../../assets/images/optimus/Group320.png";
import Group321 from "../../../assets/images/optimus/Group321.png";
import Group315 from "../../../assets/images/optimus/Group315.svg";
import Group323 from "../../../assets/images/optimus/Group323.png";
import Group324 from "../../../assets/images/optimus/Group324.png";
import Group325 from "../../../assets/images/optimus/Group325.png";
import Group381 from "../../../assets/images/optimus/Group381.svg";
import familyphoto from "../../../assets/images/optimus/familygroup.png";
import interviewquestions from "../../../assets/images/optimus/interviewquestion.png";
import mainLearning from "../../../assets/images/optimus/mainlearning.png";




const OptimusSection2 = () => {
  let url = "https://infini8ai-images.s3.amazonaws.com/optimus";
  return (
    <div>
      <div id="interviewQSection">
        <div style={{ background: "#f2f2f2" }}>
          <div className="container">
            <div className="row">
              <div className="col-md-10 offset-md-1">
                <div className="interviewQSectionHead">
                  {/* <h5>Interview Questions</h5> */}
                  <img
                  src={interviewquestions}
                  className="img-fluid Group381"
                  alt="Group381"
                />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="NHS_Doctors">
          <div className="container">
            <div className="row" style={{ alignItems: "center" }}>
              <div className="col-md-7">
                <div className="NHS_DoctorsContent">
                  <h5>
                    A Group of 50 individuals was questioned by our researchers
                    in coordination with the NHS Doctors.
                  </h5>
                  <p>Group was comprised of :</p>
                  <ul className="d-flex ml-3 ">
                    <li className="list-unstyled mr-1">
                      <img src={Union} className="Union" alt="union" />
                    </li>
                    <li className="list-unstyled ml-1">
                      <span>Male, Female, and LGBT members</span>
                    </li>
                  </ul>
                  <ul className="d-flex ml-3 ">
                    <li className="list-unstyled mr-1">
                      <img src={Union} className="Union" alt="union" />
                    </li>
                    <li className="list-unstyled ml-1">
                      <span>Young adults</span>
                    </li>
                  </ul>
                  <ul className="d-flex ml-3 ">
                    <li className="list-unstyled mr-1">
                      <img src={Union} className="Union" alt="union" />
                    </li>
                    <li className="list-unstyled ml-1">
                      <span>Older generation</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-5">
                <div className="NHS_DoctorsImg">
                  <img
                    src={familyphoto}
                    className="GroupImgs"
                    alt="GroupImgs"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="questionedMain">
          <div className="container">
            <div className="row" style={{ alignItems: "center" }}>
              <div className="col-md-6">
                <div id="questioned">
                  <p>The questioned asked were generally about :</p>
                </div>
              </div>
              <div className="col-md-6">
                <div id="questionedImg">
                  <img src={Group302} className="Group302" alt="Group302" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="generallyAbout">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="generallyAboutContent">
                  <ul className="d-flex ml-3 ">
                    <li className="list-unstyled mr-1">
                      <img src={Union} className="Union" alt="union" />
                    </li>
                    <li className="list-unstyled ml-1">
                      <p>
                        Each of the individual’s daily routine before Covid and
                        how did it change afterward?
                      </p>
                    </li>
                  </ul>
                  <ul className="d-flex ml-3 ">
                    <li className="list-unstyled mr-1">
                      <img src={Union} className="Union" alt="union" />
                    </li>
                    <li className="list-unstyled ml-1">
                      <p>
                        How does each individual manage their nutrition and
                        health?
                      </p>
                    </li>
                  </ul>
                  <ul className="d-flex ml-3 ">
                    <li className="list-unstyled mr-1">
                      <img src={Union} className="Union" alt="union" />
                    </li>
                    <li className="list-unstyled ml-1">
                      <p>
                        Do any of these individuals engage in physical exercise,
                        either at home or in a gym?
                      </p>
                    </li>
                  </ul>
                  <ul className="d-flex ml-3 ">
                    <li className="list-unstyled mr-1">
                      <img src={Union} className="Union" alt="union" />
                    </li>
                    <li className="list-unstyled ml-1">
                      <p>How did Covid affect their daily gym routine? </p>
                    </li>
                  </ul>
                  <ul className="d-flex ml-3 ">
                    <li className="list-unstyled mr-1">
                      <img src={Union} className="Union" alt="union" />
                    </li>
                    <li className="list-unstyled ml-1">
                      <p>
                        Are they following any nutrition plan given by their
                        doctor or a nutritionist?
                      </p>
                    </li>
                  </ul>
                  <ul className="d-flex ml-3 ">
                    <li className="list-unstyled mr-1">
                      <img src={Union} className="Union" alt="union" />
                    </li>
                    <li className="list-unstyled ml-1">
                      <p>What are their daily eating and sleeping habits? </p>
                    </li>
                  </ul>
                  <ul className="d-flex ml-3 ">
                    <li className="list-unstyled mr-1">
                      <img src={Union} className="Union" alt="union" />
                    </li>
                    <li className="list-unstyled ml-1">
                      <p>
                        Do they use or follow any other medium for their health
                        benefits.{" "}
                      </p>
                    </li>
                  </ul>
                  <ul className="d-flex ml-3 ">
                    <li className="list-unstyled mr-1">
                      <img src={Union} className="Union" alt="union" />
                    </li>
                    <li className="list-unstyled ml-1">
                      <p>
                        How did Covid affect their mental and physical health?{" "}
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ background: "#f2f2f2" }}>
          <div className="container">
            <div className="row">
              <div className="col-md-10 offset-md-1">
                <div className="interviewQSectionHead">
                 <img src={mainLearning}   className="img-fluid Group381" alt="Group381"/>
                 </div>
              </div>
            </div>
          </div>
        </div>
        <div id="mainLearning">
          <div className="container text-center">
            <div className="row  ">
              <div className="col-md-6 pb-5 pt-5 ">
                <div className="mainLearningsContent">
                  <img src={Group318} className="groupimg318" alt="" />
                </div>
              </div>
              <div className="col-md-6 pb-5 pt-5">
                <div className="mainLearningsContent">
                  <img src={Group319} className="Groupimg319" alt="" />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 pb-5 pt-5">
                <div className="mainLearningsContent">
                  <img src={Group320} className="Groupimg320" alt="" />
                </div>
              </div>
              <div className="col-md-6 pb-5 pt-5">
                <div className="mainLearningsContent">
                  <img src={Group321} className="Groupimg321" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="OptimusUserPersonas">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="OptimusPersonasTitle">
                  <img src={Group315} className="GroupImg315" alt="Group315" />
                </div>
              </div>
            </div>
            <div className="UserPersonasMain">
              <div className="row">
                <div className="col-md-3">
                  <div className="analysisCountingImg">
                    <img
                      src={`${url}/Group322.png`}
                      className="GroupImg322"
                      alt="Group322"
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="analysisCountingImg">
                    <img
                      src={Group323}
                      className="GroupImg323"
                      alt="Group323"
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="analysisCountingImg">
                    <img
                      src={Group324}
                      className="GroupImg324"
                      alt="Group324"
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="analysisCountingImg">
                    <img
                      src={Group325}
                      className="GroupImg325"
                      alt="Group325"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OptimusSection2;
