import React from "react";
import { Button, Paper } from "@mui/material";
import { Table, TableBody, TableCell, TableContainer } from "@mui/material";
import { TableHead, TableRow, styled, tableCellClasses } from "@mui/material";
import { Dialog, DialogContent, Stack, Typography } from "@mui/material";
import Logo from "../../assets/images/logo.svg";
import moment from "moment";

const Title = styled(Typography)({
  fontFamily: "Dosis",
  fontWeight: 600,
  fontSize: "18px",
  color: "#353535",
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#04B2AE",
    fontSize: "16px",
    fontFamily: "Dosis",
    fontWeight: 600,
    color: "#ffffff",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "16px",
    fontFamily: "Dosis",
    fontWeight: 500,
    color: "#67748E",
    backgroundColor: "#F5F5F5",
  },
}));

function Report({ onClose, user }) {
  let name = user?.timeTracksArr[0].employeeName ?? "";
  let startDate = user?.timeTracksArr[0].date ?? "dd-mm-yyyy";
  const length = user?.timeTracksArr?.length - 1;
  let endDate = user?.timeTracksArr[length].date ?? "dd-mm-yyyy";
  let index = 0;

  return (
    <Dialog open={true} onClose={onClose} fullWidth maxWidth="md">
      <DialogContent>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          flexWrap="wrap"
        >
          <Stack direction="row" spacing={1}>
            <Title sx={{ color: "#04B2AE" }}>Employee Name:</Title>
            <Title>{name}</Title>
          </Stack>
          <img height="23px" src={Logo} alt="" />
          <Stack direction="row" spacing={1}>
            <Title sx={{ color: "#04B2AE" }}>Date:</Title>
            <Title>
              {endDate} / {startDate}
            </Title>
          </Stack>
        </Stack>

        <TableContainer
          sx={{ my: 2, borderRadius: "8px 8px 0 0" }}
          component={Paper}
        >
          <Table sx={{ minWidth: 500 }} aria-label="customized table">
            <TableHead sx={{ p: "0", display: "table-header-group" }}>
              <TableRow>
                <StyledTableCell>#</StyledTableCell>
                <StyledTableCell>Date</StyledTableCell>
                <StyledTableCell>Type</StyledTableCell>
                <StyledTableCell>Time</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {user?.timeTracksArr?.map((row) => {
                return row?.clockHitsory.map((item, idx) => {
                  index += 1;
                  return (
                    <TableRow key={idx}>
                      <StyledTableCell component="th" scope="row">
                        {index}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {row?.date}
                      </StyledTableCell>
                      <StyledTableCell>{item.action}</StyledTableCell>
                      <StyledTableCell>
                        {moment.unix(item.time).format("hh:mm:ss A")}
                      </StyledTableCell>
                    </TableRow>
                  );
                });
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Stack mb={2} px={5} alignItems="end" justifyContent="flex-end">
          <Stack>
            <Stack
              width="100%"
              direction="row"
              spacing={3}
              justifyContent="space-between"
            >
              <Title>Consumed Hours :</Title>
              <Title>{user?.totalTimeSpent}</Title>
            </Stack>
            <Stack
              width="100%"
              direction="row"
              spacing={3}
              justifyContent="space-between"
            >
              <Title>Pending Hours :</Title>
              <Title>{user?.totalTimePending}</Title>
            </Stack>
          </Stack>
        </Stack>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          spacing={8}
        >
          <Button
            size="small"
            variant="contained"
            sx={{
              background: "#ffffff !important",
              textTransform: "none",
              border: "solid 1px #A7A7A7",
              px: "50px",
            }}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            size="small"
            variant="contained"
            sx={{
              background: "#21A49E !important",
              textTransform: "none",
              color: "#ffffff",
              px: "50px",
            }}
          >
            Save
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}

export default Report;
