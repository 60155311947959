import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  styled,
  TextField,
} from "@mui/material";
import { Stack } from "@mui/system";
import Clock from "../../assets/images/calender/clock.svg";
import File from "../../assets/images/Employees/file.png";
import Notes from "../../assets/images/calender/notes.svg";
import S3FileUpload from "react-s3/lib/ReactS3";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { saveLeave } from "../../apiservices/calendar";
import moment from "moment";
import { TextStyle, Title } from "../dashboard/style";
window.Buffer = window.Buffer || require("buffer").Buffer;

const schema = yup
  .object({
    title: yup.string().required("*Title is required"),
    startDate: yup.string().required("*start date is required"),
    description: yup.string().required("*description is required"),
  })
  .required();
const AttendanceModel = ({ closePop, getLeaves, getLeavesCount }) => {
  const [state, setState] = useState({
    checked: false,
    file: "",
  });

  const CancelBtn = styled(Button)({
    height: "34px",
    width: "136px",
    borderRadius: "4px",
    padding: "8px 50px 8px 50px",
    color: "#A7A7A7",
    background: "#FFFFFF",
    "&:hover": {
      boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.16)",
      color: "#A7A7A7",
    },
    "&:active": {
      background: " #A7A7A7",
      color: "#FFFFFF",
    },
  });
  const SaveBtn = styled(Button)({
    height: "34px",
    width: "136px",
    borderRadius: "4px",
    padding: "8px 50px 8px 50px",
    color: "#F5F5F5",
    background: "#21A49E",
    "&:hover": {
      boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.16)",
      background: "#1B8C87",
      color: "#F5F5F5",
    },
    "&:active": {
      background: " #146E6A",
      color: "#F5F5F5",
    },
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const config = {
    bucketName: "infini8ai-images",
    dirName: "photos",
    error: "",
    region: "us-east-1",
    accessKeyId: "AKIA44SH2DQ6PSTL43O7",
    secretAccessKey: "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",
  };
  useEffect(() => {
    reset({
      startDate: moment().format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
      startTime: moment().format("HH:mm"),
      endTime: moment().format("HH:mm"),
    });
  }, [state.checked]);

  const handleChange = (event) => {
    setState((prev) => ({
      ...prev,
      [event.target.name]: event.target.checked,
    }));
  };
  const handleImgChange = (e) => {
    setState((prev) => ({ ...prev, file: e.target.files[0] }));
  };
  const onSubmit = async (data) => {
    console.log(data.startTime);
    let formateDate = moment(data.startDate).format("DD-MM-YYYY");
    let formateEndDate = moment(data.endDate).format("DD-MM-YYYY");
    let url = state.file;
    if (state.file) {
      let { location } = await S3FileUpload.uploadFile(state.file, config);
      url = location;
    }
    let leaveData = {
      title: data.title,
      date: formateDate, //dd-MM-yyyy
      startDate: formateDate, //whatever format is easy to apply
      endDate: formateEndDate,
      description: data.description,
      startTime: data?.startTime,
      endTime: data?.endTime,
      attachment: url,
      type: state.checked === true ? "full" : "half", // "full"  or  "half"
      applicantName: localStorage.getItem("userName"),
      applicantId: localStorage.getItem("userEmail"),
      company_code: localStorage.getItem("companyCode"),
    };
    await saveLeave(leaveData);
    closePop("applyLeaves");
    getLeaves();
    getLeavesCount();
  };
  return (
    <>
      <Stack sx={{ p: 4 }}>
        <Title variant="h5" sx={{ textAlign: "center", color: "#21A49E" }}>
          Leave Application
        </Title>
        <Box>
          <TextField
            fullWidth
            label="Add Title"
            type="text"
            id="title"
            {...register("title")}
            sx={{ mb: 3 }}
          />
          <TextStyle
            variant="p"
            sx={{
              justifyContent: "center",
              color: "red",
              fontSize: "15px",
            }}>
            {errors.title?.message}
          </TextStyle>
        </Box>

        <Stack direction="row" justifyContent="space-between" sx={{ mb: 3 }}>
          <Stack>
            <Stack direction="row">
              <img
                src={Clock}
                alt=""
                // height="15px"
                style={{ marginRight: "10px" }}
              />
              <Stack direction="column">
                <TextField
                  type="date"
                  variant="standard"
                  {...register("startDate")}
                />
                <TextStyle
                  variant="p"
                  sx={{
                    justifyContent: "center",
                    color: "red",
                    fontSize: "15px",
                  }}>
                  {errors.startDate?.message}
                </TextStyle>
              </Stack>
            </Stack>
            <FormControlLabel
              sx={{ mx: 3 }}
              control={
                <Checkbox
                  name="checked"
                  checked={state.checked}
                  onChange={handleChange}
                />
              }
              label="All day"
            />
          </Stack>
          <Stack direction="row">
            {!state.checked ? (
              <>
                <TextField
                  fullWidth
                  type="time"
                  variant="standard"
                  name="startTime"
                  {...register("startTime")}
                />
                <TextStyle sx={{ mx: 1 }}>_</TextStyle>
                <TextField
                  fullWidth
                  type="time"
                  variant="standard"
                  {...register("endTime")}
                />
              </>
            ) : (
              <TextField
                fullWidth
                type="date"
                variant="standard"
                name="endDate"
                {...register("endDate")}
              />
            )}
          </Stack>
        </Stack>
        <Stack direction="row">
          <img
            src={Notes}
            alt=""
            // height="15px"
            style={{ marginRight: "10px" }}
          />
          <Stack direction="column">
            <TextField
              fullWidth
              id="filled-multiline-static"
              label="Write description here"
              multiline
              sx={{ width: "450px", fontSize: "normal" }}
              rows={4}
              variant="filled"
              name="description"
              {...register("description")}
            />
            <TextStyle
              variant="p"
              sx={{
                justifyContent: "center",
                color: "red",
                fontSize: "15px",
              }}>
              {errors.description?.message}
            </TextStyle>
          </Stack>
        </Stack>
        <Stack direction="row" sx={{ mt: 2 }}>
          <img src={File} alt="" style={{ marginRight: "10px" }} width="50px" />
          <Box>
            <input
              type="file"
              // variant="filled"
              onChange={handleImgChange}
            />
          </Box>
        </Stack>
        <Stack direction="row" justifyContent="end">
          <CancelBtn onClick={() => closePop("applyLeaves")} sx={{ mx: 3 }}>
            Cancel
          </CancelBtn>
          <SaveBtn onClick={handleSubmit(onSubmit)}>Done</SaveBtn>
        </Stack>
      </Stack>
    </>
  );
};

export default AttendanceModel;
