import React from "react";
import NaseemSection1 from "./NaseemSection1";
import "../caseStudy.css";
import NavbarMenu from "../../common/NavbarMenu";
import NaseemMobile from "./NaseemMobile";
import useWindowSize from "../../utils/useWindowSize";
import NaseemMobile1 from "./NaseemMobile1";
import NaseemSection2 from "./NaseemSection2";
import NaseemSection3 from "./NaseemSection3";
import NaseemSection4 from "./NaseemSection4";
import PageLayout from "../../../layouts/Page";

const Naseem = () => {
  const size = useWindowSize();

  return (
    <PageLayout>
      <NavbarMenu />
      {size.width < 768 ? (
        <>
          <NaseemMobile />
          <NaseemMobile1 />
        </>
      ) : (
        <>
          <NaseemSection1 />
          <NaseemSection2 />
          <NaseemSection3 />
          <NaseemSection4 />
        </>
      )}
    </PageLayout>
  );
};

export default Naseem;
