import React from "react";
import Union from "../../../assets/images/optimus/Union.svg";
import Group334 from "../../../assets/images//stock/Group334.png";
import Group336 from "../../../assets/images/stock/Group336.png";
import Group332 from "../../../assets/images/stock/Group332.png";
import Group344 from "../../../assets/images/stock/Group344.png";
import doimage from "../../../assets/images/businesspal/doimage.png";
import empathyimage from "../../../assets/images/businesspal/empathymap.png";
import seeimage from "../../../assets/images/businesspal/seeimage.png";
import painimage from "../../../assets/images/businesspal/painimage.png";
import gainimage from "../../../assets/images/businesspal/gainimage.png";
import hearimage from "../../../assets/images/businesspal/Hearimage.png";
import saraimg from "../../../assets/images/businesspal/saraimg.png";
import rajimg from "../../../assets/images/businesspal/rajimg.png";

const BusinessSection3 = () => {
  let url = "https://infini8ai-images.s3.amazonaws.com/stock";
  return (
    <div>
      <div id="StockAbouts" style={{paddingTop:"5rem", paddingBottom:"5rem",backgroundColor:"white"}}>
        <div className="container" style={{display:"flex", flexDirection:"column", gap:"80px"}}>
          <div className="row">
            <div className="col-md-12">
              <div className="StockLearningContentMain">
                <p>User Personas</p>
              </div>
            </div>
          </div>
          <div style={{display:"flex", flexDirection:"column", gap:"25px"}}>
          <div className="row no-gutters"  style={{justifyContent:"space-between"}}>
            <div className="col-md-6 ">
              <div className="StockAnalysisContentCardImg">
                <img
                  src={saraimg}
                  className="GroupImg276"
                  alt="Group276"
                  style={{ width:"100%",maxWidth: "100%", height:"408px" }}
                />
              </div>
            </div>
            <div className="col-md-5 ">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  // width: '300px',
                  height: "408px",
                  backgroundColor: "#E1FCFE",
                  borderRadius: "20px",
                  // boxShadow: "-20px -20px 20px #37757B",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "15px",
                  }}
                >
                  <p style={{ color: "#116065", textAlign: "center" ,fontWeight:600,font:"poppins", fontSize:"24px"}}>About</p>
                  <p style={{ color: "black", textAlign: "center",fontWeight:400,font:"poppins", fontSize:"20px" , marginLeft:"2rem",marginRight:"2rem"}}>
                    Sarah runs a small retail store and struggles with managing
                    inventory and finances using traditional methods.
                  </p>
                  <p style={{ color: "black", textAlign: "center",fontWeight:400,font:"poppins", fontSize:"20px" , marginLeft:"2rem",marginRight:"2rem"}}>
                    “keeping track of everything manually is exhausting and
                    prone to mistake”
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                  // width: '300px',
                  height: "200px",
                  backgroundColor: "#E1FCFE",
                  borderRadius: "20px",
                  // boxShadow: "-20px -20px 20px #37757B",
                }}
              >
                <div>
                <p style={{ margin: "0 0 20px 0",color: "#116065", fontWeight:600,font:"poppins", fontSize:"24px" }}>Goals</p>
                <ul style={{ margin: "0", padding: "0 0 0 20px" , fontWeight:400,font:"poppins", fontSize:"20px"}}>
                  <li>Streamline inventory management</li>
                  <li>Reduce manual errors</li>
                  <li>Improve financial accuracy</li>
                </ul>
                </div>

                <div>
                <p style={{ margin: "0 0 20px 0",color: "#116065", fontWeight:600,font:"poppins", fontSize:"24px" }}>Pain Points</p>
                <ul style={{ margin: "0", padding: "0 0 0 20px" , fontWeight:400,font:"poppins", fontSize:"20px"}}>
                  <li>Time-consuming manual processes</li>
                  <li>Frequent stockouts and overstock</li>
                  <li>Financial discrepancies</li>
                </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="row" style={{justifyContent:"space-between"}}>
            <div className="col-md-6">
              <div className="StockAnalysisContentCardImg">
                <img
                  src={rajimg}
                  className="GroupImg276"
                  alt="Group276"
                  style={{width:"100%", maxWidth: "100%" ,height:"408px"}}
                />
              </div>
            </div>
            <div className="col-md-5">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  // width: '300px',
                  height: "408px",
                  backgroundColor: "#E1FCFE",
                  borderRadius: "20px",
                  // boxShadow: "-20px -20px 20px #37757B",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "15px",
                  }}
                >
                  <p style={{ color: "#116065", textAlign: "center" ,fontWeight:600,font:"poppins", fontSize:"24px"}}>About</p>
                  <p style={{ color: "black", textAlign: "center",fontWeight:400,font:"poppins", fontSize:"20px" , marginLeft:"2rem",marginRight:"2rem"}}>
                    Raj manages a medium-sized manufacturing business and needs
                    an efficient solution to handle complex operations.{" "}
                  </p>
                  <p style={{ color: "black", textAlign: "center",fontWeight:400,font:"poppins", fontSize:"20px" , marginLeft:"2rem",marginRight:"2rem"}}>
                    "An integrated system could save us so much time and money."
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                  // width: '300px',
                  height: "200px",
                  backgroundColor: "#E1FCFE",
                  borderRadius: "20px",
                  // boxShadow: "-20px -20px 20px #37757B",
                }}
              >
                <div>
                <p style={{ margin: "0 0 20px 0",color: "#116065", fontWeight:600,font:"poppins", fontSize:"24px" }}>Goals</p>
                <ul style={{ margin: "0", padding: "0 0 0 20px" , fontWeight:400,font:"poppins", fontSize:"20px"}}>
                  <li>Integrate various business operations</li>
                  <li>Gain real-time insights into sales & stock</li>
                  <li>Improve cash flow management</li>
                </ul>
                </div>

                <div>
                <p style={{ margin: "0 0 20px 0",color: "#116065", fontWeight:600,font:"poppins", fontSize:"24px" }}>Pain Points</p>
                <ul style={{ margin: "0", padding: "0 0 0 20px" , fontWeight:400,font:"poppins", fontSize:"20px"}}>
                  <li>Inefficiencies in current operations</li>
                  <li>Payment issues due to manual invoices</li>
                  <li>Lack of real-time business inisights</li>
                </ul>
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>
      <div id="StockEmpathyMap" style={{ background: "#E1FCFE" }}>
        <div className="container">
          <div className="row">
            <div className="col-md-10 offset-md-1">
              <div className="StockEmpathyImg">
                <img
                  src={empathyimage}
                  className="GroupImg346"
                  alt="Group346"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="StockEmpathyImg">
                <img src={seeimage} className="GroupImg347" alt="Group347" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="StockEmpathyImg">
                <img src={hearimage} className="GroupImg348" alt="Group348" />
              </div>
            </div>
            <div className="col-md-10 offset-md-1">
              <div className="StockEmpathyImg">
                <img src={doimage} className="GroupImg344" alt="Group344" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="StockEmpathyImg">
                <img src={painimage} className="GroupImg349" alt="Group349" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="StockEmpathyImg">
                <img src={gainimage} className="GroupImg350" alt="Group350" />
              </div>
            </div>
            <div className="col-md-10 offset-md-1">
              {/* <div className="StockEmpathyImg">
                <img
                  src={`${url}/iconInfinite.png`}
                  className="iconInfinite"
                  alt="iconInfinite"
                />
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessSection3;
