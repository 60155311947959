import axios from "axios";
import React, { useCallback, useLayoutEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import S3FileUpload from "react-s3/lib/ReactS3";
import { Stack, styled } from "@mui/system";
import {
  Grid,
  InputLabel,
  FormControl,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  InputAdornment,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import ReactSelect from "react-select";
import { BreakWord } from "../../styles/style";
import { formatDateYYYYMMDD } from "../../user portal/reuseable components";
import moment from "moment/moment";
// import Select from "react-select";
import emp from "../../assets/images/project/emp.svg";
import ImageViewer from "react-simple-image-viewer";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
// import Loader from "../task/Loader";
import uploadimg from "../../assets/images/project/add_a_photo.svg";
import { Dialog, DialogContent } from "@mui/material";
import { ViewMemberButton } from "./Project";
import AutorenewIcon from "@mui/icons-material/Autorenew";

window.Buffer = window.Buffer || require("buffer").Buffer;

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      height: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const TaskDetailPopup = ({
  popup,
  closeTaskPopup,
  selectedRow,
  selectedColName,
  projName,
  getallTask,
  teamMembers,
  allUsers,
}) => {
  const config = {
    bucketName: "infini8ai-images",
    dirName: "photos",
    error: "",
    region: "us-east-1",
    accessKeyId: "AKIA44SH2DQ6PSTL43O7",
    secretAccessKey: "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",
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    height: "600px",
    overflow: "scroll",
    transform: "translate(-50%, -50%)",
    background: "#FFFFFF",
    boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.2)",
    borderRadius: "12px",
    // height: "500px",
    p: 4,
  };
  const RemoveBtn = styled(Button)({
    // height: "36px",
    width: "98px",
    borderRadius: "4px",
    border: "1px solid #C02727",
    color: "#A42121",
    textTransform: "capitalize",
    "&:hover": {
      color: "#FFFFFF",
      background: "#C02727",
      boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.16)",
      border: "1px solid #C02727",
    },
    "&:active": {
      background: "#931D1D",
      border: "1px solid #A22020",
    },
  });
  const username = localStorage.getItem("userName");
  const userImage = localStorage.getItem("imageUrl");
  const [state, setState] = useState(selectedRow);
  const [displayImg, setDisplayImg] = useState("");
  const [commentSec, setCommentSec] = useState(
    selectedRow.commentSection ?? []
  );
  const [comments, setComments] = useState("");
  const [displayImages, setDisplayImages] = useState([]);
  const [currentImage, setCurrentImage] = useState(0);
  const [newImages, setNewImages] = useState([]);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  useLayoutEffect(() => {
    let date = formatDateYYYYMMDD(state.deadline);
    let assignedDate = moment.unix(selectedRow.created_at).format("YYYY-MM-DD");

    setState((prevState) => ({
      ...prevState,
      deadline: date,
      assigned: assignedDate,
    }));
    let updatedState =
      state.members &&
      state.members?.map((item) => ({
        ...item,
        label: item.name,
        value: item.name,
      }));

    setState((prevState) => ({
      ...prevState,
      members: updatedState,
    }));
    const keyDownHandler = (event) => {
      if (event.keyCode === 13) {
        event.preventDefault();
        document.getElementById("btn").click();
      }
    };
    document.addEventListener("keydown", keyDownHandler);
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, []);
  const handleChange = (e) => {
    let { name, value } = e.target;
    console.log(name);
    if (name === "members") {
      let arr = JSON.parse(value);
      setState((prev) => ({ ...prev, members: [arr] }));
    } else if (name === "comments") {
      setComments(value);
    } else {
      setState((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };
  const uploadImage = async (item) => {
    try {
      let { location } = await S3FileUpload.uploadFile(item, config);
      return location;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
  const updateTasks = async () => {
    // Set isLoading to true to show the loader
    setIsLoading(true);

    const apiPromises = newImages.map(uploadImage);
    const results = await Promise.all(apiPromises);

    await axios.post(`https://infini8ai.herokuapp.com/tasks`, {
      request: {
        method: "updateTask",
        data: {
          ...state,
          imageUrl: [...results, ...state.imageUrl],
          commentSection: commentSec,
        },
      },
    });

    toast.success("Task Edit Successfully");
    closeTaskPopup();
    getallTask();
    setIsLoading(false);
  };
  const deleteTask = async () => {
    await axios.post(`https://infini8ai.herokuapp.com/tasks`, {
      request: {
        method: "deleteTask",
        data: {
          _id: state._id,
        },
      },
    });
    toast.warning("Task Deleted Successfully");
    getallTask();
    closeTaskPopup();
  };
  const handleImg = (e) => {
    const files = Array.from(e.target.files);
    let img = [];
    files.forEach((item) => {
      img.push(URL.createObjectURL(item));
    });
    setDisplayImages((prev) => [...img, ...prev]);
    setNewImages((prev) => [...files, ...prev]);
  };
  const onChange = (selectedOptions) => {
    setState((prev) => ({ ...prev, members: selectedOptions }));
  };
  const saveComments = (e) => {
    let date = moment().format("MMM. D, YYYY");
    if (e.code === "Enter" && comments !== "") {
      setCommentSec([
        { username, comment: comments, imageUrl: userImage, date: date },
        ...commentSec,
      ]);
      setComments("");
    }
  };

  const allImages = () => {
    let arr = Array.isArray(state.imageUrl);

    if (arr) {
      return [...displayImages, ...state.imageUrl];
    } else if (typeof state.imageUrl === "string") {
      return [...displayImages, state.imageUrl];
    } else {
      return [...displayImages];
    }
  };
  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const handleRemoveImg = (arg, idx) => {
    if (arg.toString().includes("blob")) {
      let filtered = displayImages.filter((item) => item != arg);
      setDisplayImages(filtered);
      let copy = [...newImages];
      copy.splice(idx, 1);
      setNewImages(copy);
    } else {
      let arr = Array.isArray(state.imageUrl);
      if (arr) {
        let filtered = state.imageUrl.filter((item) => item != arg);
        setState((prev) => ({ ...prev, imageUrl: filtered }));
      } else {
        setState((prev) => ({ ...prev, imageUrl: [] }));
      }
    }
  };
  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    console.log(name);
    if (name === "startDate" || name === "endDate") {
      const formattedDate = value
        ? new Date(value).toISOString().split("T")[0]
        : "";

      if (name === "startDate") {
        setState((prev) => ({ ...prev, startDate: formattedDate }));
      } else {
        setState((prev) => ({ ...prev, endDate: formattedDate }));
      }
    } else if (name === "files") {
      const { files } = e.target;
      if (files[0]) {
        setState((prev) => ({ ...prev, files: [...prev.files, files[0]] }));
      }
    } else {
      setState((prev) => ({ ...prev, [name]: value }));
    }
  };
  const onColabChange = (selectedOptions) => {
    setState((prev) => ({ ...prev, collaborators: selectedOptions }));
  };
  return (
    <>
      {isLoading && (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: "9999",
          }}>
          {/* <Loader /> */}
        </div>
      )}
      <Dialog open={popup} onClose={closeTaskPopup} maxWidth="xl" fullWidth>
        <DialogContent>
          <Stack direction="row" p="16px" gap={"48px"}>
            <Box flex={"1"} justifyContent="center">
              <Stack direction="row" justifyContent="space-between">
                <Box></Box>
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  sx={{ marginLeft: 8 }}>
                  Task Details
                </Typography>
                <RemoveBtn size="small" onClick={deleteTask}>
                  Remove
                </RemoveBtn>
              </Stack>
              <Stack justifyContent="center" alignItems="center" sx={{ p: 1 }}>
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  direction="row"
                  spacing={1}>
                  {allImages().map((src, index) => (
                    <Box className="profile-pic">
                      <img
                        src={src ? src : uploadimg}
                        onClick={() => openImageViewer(index)}
                        width="150"
                        height="150"
                        key={index}
                        style={{ margin: "2px" }}
                        alt=""
                      />
                      <Box className="edit">
                        <DeleteForeverIcon
                          sx={{ color: "red", cursor: "pointer" }}
                          onClick={() => handleRemoveImg(src, index)}
                        />
                      </Box>
                    </Box>
                  ))}
                  {allImages().length == 0 && (
                    <img
                      src={uploadimg}
                      width="150"
                      height="150"
                      style={{ margin: "2px" }}
                      onClick={() => openImageViewer()}
                      alt=""
                    />
                  )}

                  {isViewerOpen && (
                    <ImageViewer
                      src={allImages()}
                      currentIndex={currentImage}
                      onClose={closeImageViewer}
                      disableScroll={false}
                      backgroundStyle={{
                        backgroundColor: "rgba(0,0,0,0.9)",
                        zIndex: 9999,
                      }}
                      closeOnClickOutside={true}
                    />
                  )}
                </Stack>
                <label htmlFor="fileInput" className="custom-file-upload">
                  Upload Images
                </label>
                <input
                  type="file"
                  id="fileInput"
                  multiple
                  accept="image/*"
                  onChange={handleImg}
                  style={{ display: "none" }}
                />
              </Stack>
              <Grid container spacing={1}>
                <Grid item md={12} xs={12}>
                  <InputLabel>Title</InputLabel>
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Task Name"
                    name="task"
                    value={state.task}
                    onChange={handleInput}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Status</InputLabel>
                    <Select
                      name="status"
                      value={state.status}
                      onChange={handleInput}
                      input={<OutlinedInput label="Status" />}
                      sx={{ height: "2.8rem" }}>
                      <MenuItem value="Pending">Pending</MenuItem>
                      <MenuItem value="InProgress">InProgress</MenuItem>
                      <MenuItem value="Testing">Testing</MenuItem>
                      <MenuItem value="DOne">Done</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={6} xs={12}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Priority</InputLabel>
                    <Select
                      name="priority"
                      value={state.priority}
                      onChange={handleInput}
                      input={<OutlinedInput label="Priority" />}
                      sx={{ height: "2.8rem" }}>
                      <MenuItem value="First">High</MenuItem>
                      <MenuItem value="Second">Medium</MenuItem>
                      <MenuItem value="third">Low</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    id="outlined-required"
                    variant="outlined"
                    name="startDate"
                    value={state.startDate}
                    label="Start Date"
                    type="date"
                    onChange={handleInput}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    id="outlined-required"
                    variant="outlined"
                    name="endDate"
                    value={state.endDate}
                    label="End Date"
                    type="date"
                    onChange={handleInput}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <InputLabel>Description</InputLabel>
                  <textarea
                    style={{
                      width: "100%",
                      borderRadius: "8px",
                      borderColor: "#BDBDBD",
                      padding: 5,
                    }}
                    fullWidth
                    variant="outlined"
                    name="details"
                    value={state.details}
                    placeholder="Description"
                    onChange={handleInput}
                  />
                </Grid>

                <Stack
                  direction="row"
                  justifyContent="space-between"
                  sx={{ mt: 1 }}>
                  <Typography variant="p" sx={{ fontSize: "16px" }}>
                    {state.assignedBy} Created This Task
                  </Typography>
                </Stack>
                <Grid container sx={{ mt: 2 }}>
                  <Grid
                    item
                    xs={1}
                    sx={{
                      marginRight: "10px",
                    }}>
                    <img
                      src={
                        state.members?.length !== 0 &&
                        state.members[0]?.imageUrl
                          ? state.members[0]?.imageUrl
                          : emp
                      }
                      alt="card"
                      width="50px"
                      height="50px"
                      style={{ borderRadius: "100px" }}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <InputLabel></InputLabel>

                    <FormControl sx={{ width: "100%", mt: 1.5 }}>
                      <ReactSelect
                        value={state.members}
                        isMulti
                        onChange={onChange}
                        options={allUsers}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            border: 0,
                            // margin: "12px",
                            background: "#F7F7F7",
                            fontSize: "14px",
                            minHeight: "20px",
                            width: "250px",
                          }),
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <InputLabel>Collaborators</InputLabel>
                    <ReactSelect
                      value={state.collaborators}
                      isMulti
                      onChange={onColabChange}
                      options={allUsers}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          border: 0,
                          // margin: "12px",
                          background: "#F7F7F7",
                          fontSize: "14px",
                          minHeight: "20px",
                          width: "250px",
                        }),
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid item md={4} xs={12}>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <ViewMemberButton
                      size="small"
                      onClick={() => closeTaskPopup()}>
                      Cancel
                    </ViewMemberButton>
                    <ViewMemberButton
                      size="small"
                      sx={{
                        borderColor: "#04B2AE",
                        "&:hover": {
                          background: "#04B2AE",
                        },
                      }}
                      disabled={isLoading}
                      onClick={updateTasks}>
                      {isLoading && <AutorenewIcon />} Update
                    </ViewMemberButton>
                  </Stack>
                </Grid>
              </Grid>
            </Box>
            <Box
              flex="1"
              sx={{
                bgcolor: "#F7F7F7",
                p: "8px",
                borderRadius: "4px",
              }}>
              <Typography fontSize="14px" mb="8px">
                Comments
              </Typography>
              <textarea
                style={{
                  width: "100%",
                  borderRadius: "8px",
                  borderColor: "#BDBDBD",
                  padding: 5,
                }}
                fullWidth
                variant="outlined"
                onChange={handleChange}
                value={comments}
                name="comments"
                onKeyDown={saveComments}
                placeholder="Project Task - 101 etc"
              />

              <Box
                sx={{
                  overflowY: "auto",
                  overflowX: "hidden",
                  height: "420px",
                }}>
                {commentSec &&
                  commentSec.map((comm) => {
                    return (
                      <Box
                        sx={{
                          width: "98%",
                          height: "50px",
                          bgcolor: "#fff",
                          display: "flex",
                          m: 1,
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "4px",
                          borderLeft: "2px solid #67748E",
                        }}>
                        <BreakWord>{comm.comment}</BreakWord>
                      </Box>
                    );
                  })}
              </Box>
            </Box>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default TaskDetailPopup;
