import React, { useState } from "react";
import { styled, Typography, Button, CircularProgress } from "@mui/material";
import { InputLabel, FormControl, OutlinedInput } from "@mui/material";
import { Dialog, Stack, DialogContent, InputAdornment } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import moment from "moment";
import Repo from "../../Repository/Repo";
import Toaster from "../../components/Toaster";

const Title = styled(Typography)({
  fontFamily: "Dosis",
  fontWeight: 500,
  fontSize: "16px",
  color: "#21A49E",
  textAlign: "center",
});

function EditDetails({ onClose, record, onSuccess }) {
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    clockIn: record.clockIn,
    clockInChange: false,
    clockOutChange: false,
    clockOut: record.clockOut,
    consumed: record.consumed_time,
    pending: record.pending_time,
  });

  const toggle = () => {
    if (!loading) {
      onClose();
    }
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    if (name == "clockIn") {
      setState((prev) => ({ ...prev, [name]: value, clockInChange: true }));
    } else if (name == "clockOut") {
      setState((prev) => ({ ...prev, [name]: value, clockOutChange: true }));
    } else {
      setState((prev) => ({ ...prev, [name]: value }));
    }
  };

  const updateRecord = async () => {
    try {
      const { clockIn, clockOut, pending, consumed, clockInChange } = state;
      if (!clockIn || !clockOut || !pending || !consumed) {
        Toaster("info", "Fill all fields");
        return;
      }

      let regex = /^(0[1-9]|1[0-2]):[0-5][0-9] (AM|PM)$/;

      if (clockInChange && !regex.test(clockIn)) {
        Toaster("info", "Provide proper Clock-In time like 09:00 AM");
        return;
      }
      if (state.clockOutChange && !regex.test(clockOut)) {
        Toaster("info", "Provide proper Clock-Out time like 09:00 AM");
        return;
      }

      setLoading(true);
      let consumedH = consumed.slice(0, 2);
      let consumedM = consumed.slice(3, 5);
      let finalConsumed = parseInt(consumedH * 60) + parseInt(consumedM);
      let pendingH = pending.slice(0, 2);
      let pendingM = pending.slice(3, 5);
      let finalPending = parseInt(pendingH * 60) + parseInt(pendingM);

      let { data } = await Repo.time_track({
        request: {
          method: "updateEmployeeTimeTrack",
          data: {
            _id: record._id,
            clockIn,
            clockOut,
            pending_time: finalPending,
            consumed_time: finalConsumed,
          },
        },
      });
      if (data.response.data.timeTractId) {
        Toaster("success", "Updated Successfully");
        onSuccess();
      } else {
        Toaster("error", "Error");
      }
      setLoading(false);
    } catch (err) {
      console.log("err:", err);
      setLoading(false);
    }
  };

  return (
    <Dialog open={true} onClose={toggle} fullWidth maxWidth="xs">
      <DialogContent>
        <Stack spacing={1.6}>
          <Title sx={{ fontSize: "20px" }}>{record?.employeeName}</Title>
          <Title>Edit details - {record?.date}</Title>

          <FormControl variant="outlined">
            <InputLabel htmlFor="clock-in" sx={{ color: "#21A49E" }}>
              Clock-In Time
            </InputLabel>
            <OutlinedInput
              id="clock-in"
              name="clockIn"
              label="Clock-In Time"
              placeholder="09:00 AM"
              value={
                state.clockInChange
                  ? state.clockIn
                  : moment.unix(state.clockIn).format("hh:mm A")
              }
              onChange={handleInput}
              endAdornment={
                <InputAdornment position="end">
                  <AccessTimeIcon />
                </InputAdornment>
              }
            />
          </FormControl>
          <FormControl variant="outlined">
            <InputLabel htmlFor="clock-out" sx={{ color: "#21A49E" }}>
              Clock-Out Time
            </InputLabel>
            <OutlinedInput
              id="clock-out"
              name="clockOut"
              label="Clock-Out Time"
              value={
                state.clockOutChange
                  ? state.clockOut
                  : moment.unix(state.clockOut).format("hh:mm A")
              }
              onChange={handleInput}
              endAdornment={
                <InputAdornment position="end">
                  <AccessTimeIcon />
                </InputAdornment>
              }
            />
          </FormControl>
          <FormControl variant="outlined">
            <InputLabel sx={{ color: "#21A49E" }}>Hours Consumed</InputLabel>
            <OutlinedInput
              name="consumed"
              label="Hours Consumed"
              value={state.consumed}
              onChange={handleInput}
              endAdornment={
                <InputAdornment position="end">
                  <AccessTimeIcon />
                </InputAdornment>
              }
            />
          </FormControl>
          <FormControl variant="outlined">
            <InputLabel sx={{ color: "#21A49E" }}>Pending Hours</InputLabel>
            <OutlinedInput
              name="pending"
              label="Pending Hours"
              value={state.pending}
              onChange={handleInput}
              endAdornment={
                <InputAdornment position="end">
                  <AccessTimeIcon />
                </InputAdornment>
              }
            />
          </FormControl>
          {loading && (
            <Stack alignItems="center" justifyContent="center">
              <CircularProgress sx={{ color: "#21A49E" }} size={40} />
            </Stack>
          )}
          {!loading && (
            <Stack direction="row" width="100%" justifyContent="space-between">
              <Button
                variant="contained"
                sx={{
                  background: "#ffffff !important",
                  border: "1px solid #A7A7A7",
                  textTransform: "none",
                  color: "#A7A7A7",
                  px: 5,
                }}
                onClick={toggle}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                sx={{
                  background: "#21A49E !important",
                  textTransform: "none",
                  color: "#ffffff",
                  px: 5,
                }}
                onClick={updateRecord}
              >
                Save
              </Button>
            </Stack>
          )}
        </Stack>
      </DialogContent>
    </Dialog>
  );
}

export default EditDetails;
