import React, { useState, useLayoutEffect, useCallback } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import S3FileUpload from "react-s3/lib/ReactS3";
import { Stack, styled } from "@mui/system";
import CardImg from "../../assets/images/project/cardImg.png";
import { Grid, InputLabel } from "@mui/material";
import { BreakWord } from "../../styles/style";
import { formatDateYYYYMMDD } from "../../user portal/reuseable components";
import moment from "moment/moment";
import ImageViewer from "react-simple-image-viewer";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import uploadimg from "../../assets/images/project/add_a_photo.svg";
import ReactSelect from "react-select";
import {
  FormControl,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  InputAdornment,
  Typography,
  Dialog,
  DialogContent,
} from "@mui/material";
import { TextStyle, Title } from "../dashboard/style";
import { ViewMemberButton } from "../project/Project";
window.Buffer = window.Buffer || require("buffer").Buffer;
const config = {
  bucketName: "infini8ai-images",
  dirName: "photos",
  error: "",
  region: "us-east-1",
  accessKeyId: "AKIA44SH2DQ6PSTL43O7",
  secretAccessKey: "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",
};
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  height: "600px",
  overflow: "scroll",
  transform: "translate(-50%, -50%)",
  background: "#FFFFFF",
  boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.2)",
  borderRadius: "12px",
  // height: "500px",
  p: 4,
};
const RemoveBtn = styled(Button)({
  // height: "36px",
  width: "98px",
  borderRadius: "4px",
  border: "1px solid #C02727",
  color: "#A42121",
  textTransform: "capitalize",
  "&:hover": {
    color: "#FFFFFF",
    background: "#C02727",
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.16)",
    border: "1px solid #C02727",
  },
  "&:active": {
    background: "#931D1D",
    border: "1px solid #A22020",
  },
});

const TaskDetailPopup = ({ open, onClose, row, Tasks, users }) => {
  const username = localStorage.getItem("userName");
  const userImage = localStorage.getItem("imageUrl");
  const [state, setState] = useState(row);
  const [newImages, setNewImages] = useState([]);
  const [displayImages, setDisplayImages] = useState([]);
  const [commentSec, setCommentSec] = useState(row.commentSection ?? []);
  const [comments, setComments] = useState("");
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  useLayoutEffect(() => {
    let date = formatDateYYYYMMDD(state.deadline);
    let assignedDate = moment.unix(row.created_at).format("YYYY-MM-DD");

    setState((prevState) => ({
      ...prevState,
      deadline: date,
      assigned: assignedDate,
    }));
    let updatedState =
      state.members &&
      state.members.map((item) => ({
        ...item,
        label: item.name,
        value: item.name,
      }));

    setState((prevState) => ({
      ...prevState,
      members: updatedState,
    }));
  }, []);

  const allImages = () => {
    let arr = Array.isArray(state.imageUrl);
    if (arr) {
      return [...displayImages, ...state.imageUrl];
    } else if (typeof state.imageUrl === "string") {
      return [...displayImages, state.imageUrl];
    } else {
      return [...displayImages];
    }
  };

  const handleChange = (e) => {
    let { name, value } = e.target;
    if (name === "members") {
      let arr = JSON.parse(value);
      setState((prev) => ({ ...prev, members: [arr] }));
    } else if (name === "comments") {
      setComments(value);
    } else {
      setState((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const uploadImage = async (item) => {
    try {
      let { location } = await S3FileUpload.uploadFile(item, config);
      return location;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const updateTasks = async () => {
    const apiPromises = newImages.map(uploadImage);
    const results = await Promise.all(apiPromises);

    await axios.post(`https://infini8ai.herokuapp.com/tasks`, {
      request: {
        method: "updateTask",
        data: {
          ...state,
          imageUrl: [...results, ...state.imageUrl],
          commentSection: commentSec,
        },
      },
    });
    toast.success("Task Edit Successfully");
    onClose();
    Tasks();
  };
  const deleteTask = async () => {
    await axios.post(`https://infini8ai.herokuapp.com/tasks`, {
      request: {
        method: "deleteTask",
        data: {
          _id: state._id,
        },
      },
    });
    toast.warning("Task Deleted Successfully");
    Tasks();
    onClose();
  };
  const handleImg = (e) => {
    const files = Array.from(e.target.files);
    let img = [];
    files.forEach((item) => {
      img.push(URL.createObjectURL(item));
    });
    setDisplayImages((prev) => [...img, ...prev]);
    setNewImages((prev) => [...files, ...prev]);
  };
  const onChange = (selectedOptions) => {
    setState((prev) => ({ ...prev, members: selectedOptions }));
  };
  const saveComments = (e) => {
    let date = moment().format("MMM. D, YYYY");
    if (e.code === "Enter" && comments !== "") {
      setCommentSec([
        { username, comment: comments, imageUrl: userImage, date: date },
        ...commentSec,
      ]);
      setComments("");
    }
  };

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const handleRemoveImg = (arg, idx) => {
    if (arg.toString().includes("blob")) {
      let filtered = displayImages.filter((item) => item != arg);
      setDisplayImages(filtered);
      let copy = [...newImages];
      copy.splice(idx, 1);
      setNewImages(copy);
    } else {
      let arr = Array.isArray(state.imageUrl);
      if (arr) {
        let filtered = state.imageUrl.filter((item) => item != arg);
        setState((prev) => ({ ...prev, imageUrl: filtered }));
      } else {
        setState((prev) => ({ ...prev, imageUrl: [] }));
      }
    }
  };
  const handleSelectChange = (event) => {
    const {
      target: { value, name },
    } = event;
    setState((prev) => ({
      ...prev,
      [name]:
        // On autofill we get a stringified value.
        typeof value === "string" ? value.split(",") : value,
    }));
  };
  const onColabChange = (selectedOptions) => {
    setState((prev) => ({ ...prev, collaborators: selectedOptions }));
  };
  return (
    <Dialog open={open} onClose={onClose} maxWidth="xl" fullWidth>
      <DialogContent>
        <Stack direction="row" p="16px" gap={"48px"}>
          <Box flex={"1"} justifyContent="center">
            <Stack direction="row" justifyContent="space-between">
              <Box></Box>
              <Title
                id="modal-modal-title"
                variant="h6"
                component="h2"
                sx={{ marginLeft: 8 }}>
                Task Details
              </Title>
              <RemoveBtn size="small" onClick={deleteTask}>
                Remove
              </RemoveBtn>
            </Stack>
            <Stack justifyContent="center" alignItems="center" sx={{ p: 1 }}>
              <Stack
                alignItems="center"
                justifyContent="center"
                direction="row"
                spacing={1}>
                {allImages().map((src, index) => (
                  <Box className="profile-pic">
                    <img
                      src={src}
                      onClick={() => openImageViewer(index)}
                      width="150"
                      height="150"
                      key={index}
                      style={{ margin: "2px" }}
                      alt=""
                    />
                    <Box className="edit">
                      <DeleteForeverIcon
                        sx={{ color: "red", cursor: "pointer" }}
                        onClick={() => handleRemoveImg(src, index)}
                      />
                    </Box>
                  </Box>
                ))}
                {allImages().length == 0 && (
                  <img
                    src={uploadimg}
                    width="150"
                    height="150"
                    style={{ margin: "2px" }}
                    onClick={() => openImageViewer()}
                    alt=""
                  />
                )}

                {isViewerOpen && (
                  <ImageViewer
                    src={allImages()}
                    currentIndex={currentImage}
                    onClose={closeImageViewer}
                    disableScroll={false}
                    backgroundStyle={{
                      backgroundColor: "rgba(0,0,0,0.9)",
                      zIndex: 9999,
                    }}
                    closeOnClickOutside={true}
                  />
                )}
              </Stack>
              <input
                type="file"
                multiple
                accept="image/*"
                onChange={handleImg}
                style={{ border: 0, width: "250px", marginTop: 3 }}
              />
            </Stack>
            <Grid container spacing={1}>
              <Grid item md={12} xs={12}>
                <InputLabel>Title</InputLabel>
                <textarea
                  style={{
                    width: "100%",
                    borderRadius: "8px",
                    borderColor: "#BDBDBD",
                    padding: 5,
                  }}
                  variant="outlined"
                  placeholder="Task Name"
                  rows={1}
                  name="task"
                  value={state.task}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel>Status</InputLabel>
                  <Select
                    name="status"
                    value={state.status}
                    onChange={handleChange}
                    input={<OutlinedInput label="Status" />}
                    sx={{ height: "2.8rem" }}>
                    <MenuItem value="Pending">Pending</MenuItem>
                    <MenuItem value="InProgress">InProgress</MenuItem>
                    <MenuItem value="Testing">Testing</MenuItem>
                    <MenuItem value="DOne">Done</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={6} xs={12}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel>Priority</InputLabel>
                  <Select
                    name="priority"
                    value={state.priority}
                    onChange={handleChange}
                    input={<OutlinedInput label="Priority" />}
                    sx={{ height: "2.8rem" }}>
                    <MenuItem value="First">High</MenuItem>
                    <MenuItem value="Second">Medium</MenuItem>
                    <MenuItem value="third">Low</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  id="outlined-required"
                  variant="outlined"
                  name="startDate"
                  value={state.startDate}
                  label="Start Date"
                  type="date"
                  onChange={handleChange}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  id="outlined-required"
                  variant="outlined"
                  name="endDate"
                  value={state.endDate}
                  label="End Date"
                  type="date"
                  onChange={handleChange}
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <InputLabel>Description</InputLabel>
                <textarea
                  style={{
                    width: "100%",
                    borderRadius: "8px",
                    borderColor: "#BDBDBD",
                    padding: 5,
                  }}
                  fullWidth
                  variant="outlined"
                  name="details"
                  value={state.details}
                  placeholder="Description"
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <Stack
              direction="row"
              justifyContent="space-between"
              sx={{ mt: 1 }}>
              <TextStyle variant="p" sx={{ fontSize: "16px" }}>
                {state.assignedBy} Created This Task
              </TextStyle>
            </Stack>
            <Grid container sx={{ mt: 2 }}>
              <Grid
                item
                xs={1}
                sx={{
                  marginRight: "10px",
                }}>
                <img
                  src={
                    state.members[0].imageUrl
                      ? state.members[0].imageUrl
                      : CardImg
                  }
                  alt="card"
                  width="50px"
                  height="50px"
                  style={{ borderRadius: "100px" }}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <FormControl sx={{ width: "100%", mt: 1.5 }}>
                  <ReactSelect
                    value={state.members}
                    isMulti
                    onChange={onChange}
                    options={users}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        border: 0,
                        // margin: "12px",
                        background: "#F7F7F7",
                        fontSize: "14px",
                        minHeight: "20px",
                        width: "250px",
                      }),
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item md={4} xs={12}>
                <InputLabel>Collaborators</InputLabel>
                <ReactSelect
                  value={state.collaborators}
                  isMulti
                  onChange={onColabChange}
                  options={users}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      border: 0,
                      // margin: "12px",
                      background: "#F7F7F7",
                      fontSize: "14px",
                      minHeight: "20px",
                      width: "250px",
                    }),
                  }}
                />
              </Grid>
            </Grid>
            <Grid item md={4} xs={12}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <ViewMemberButton size="small" onClick={onClose}>
                  Cancel
                </ViewMemberButton>
                <ViewMemberButton
                  size="small"
                  sx={{
                    borderColor: "#04B2AE",
                    "&:hover": {
                      background: "#04B2AE",
                    },
                  }}
                  onClick={updateTasks}>
                  Update
                </ViewMemberButton>
              </Stack>
            </Grid>
          </Box>
          <Box
            flex="1"
            sx={{ bgcolor: "#F7F7F7", p: "8px", borderRadius: "4px" }}>
            <Typography fontSize="14px" mb="8px">
              Comments
            </Typography>
            <textarea
              style={{
                width: "100%",
                borderRadius: "8px",
                borderColor: "#BDBDBD",
                padding: 5,
              }}
              fullWidth
              variant="outlined"
              onChange={handleChange}
              value={comments}
              name="comments"
              onKeyDown={saveComments}
              placeholder="Project Task - 101 etc"
            />

            <Box
              sx={{
                overflowY: "auto",
                overflowX: "hidden",
                height: "420px",
              }}>
              {commentSec &&
                commentSec.map((comm) => {
                  return (
                    <Box
                      sx={{
                        width: "100%",
                        height: "50px",
                        bgcolor: "#fff",
                        display: "flex",
                        m: 1,
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "4px",
                        borderLeft: "2px solid #67748E",
                      }}>
                      <BreakWord>{comm.comment}</BreakWord>
                    </Box>
                  );
                })}
            </Box>
          </Box>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default TaskDetailPopup;
