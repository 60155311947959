import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  TextareaAutosize,
} from "@mui/material";
import { Dialog, DialogContent, Stack, Typography } from "@mui/material";
import uploadimage from "../../assets/images/project/add_a_photo.svg";
import emp from "../../assets/images/project/emp.svg";
import axios from "axios";
import Loader from "../../admin portal/task/Loader";
import S3FileUpload from "react-s3/lib/ReactS3";
import { useHref } from "react-router-dom";
import Toaster from "../../components/Toaster";

const config = {
  bucketName: "infini8ai-images",
  dirName: "photos",
  error: "",
  region: "us-east-1",
  accessKeyId: "AKIA44SH2DQ6PSTL43O7",
  secretAccessKey: "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",
};

const TrakingDetail = ({
  onSuccess,
  onClose,
  task,
  projectImg,
  group,
  single,
  all,
  delTask,
  myGroup,
  myTask,
}) => {
  console.log("salman", myTask);
  const [displayImages, setDisplayImages] = useState([]);

  const [newImage, setNewImages] = useState([]);

  const [comment, setComment] = useState("");
  const [commentList, setCommentList] = useState(task?.commentSection ?? []); //remind
  const [error, setError] = useState(null);
  const [selectSrNumber, setSelectNumber] = useState(task?.srNum);
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    userTask: single?.task,
    desc: single?.details,
    user: single?.members[0]?.name,
    estimatedTimeline: single?.date,
  });

  const handleInput = (e) => {
    const { name, value } = e.target;
    if (name === "comment") {
      setComment(value);
    } else {
      setState((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleImg = (e) => {
    const files = Array.from(e.target.files);
    let img = [];
    let images = [];
    files.forEach((item) => {
      img.push(URL.createObjectURL(item));
      images.push(item);
    });
    setDisplayImages((prev) => [...img, ...prev]);
    setNewImages((prev) => [...images, ...prev]);
  };
  const handleAddComment = (e) => {
    if (e.key === "Enter" && comment !== "") {
      setCommentList([{ comment: comment }, ...commentList]);
      setComment("");
    }
  };

  const uploadImage = async (item) => {
    try {
      let { location } = await S3FileUpload.uploadFile(item, config);
      return location;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
  const handleUpdate = async () => {
    try {
      setLoading(true);
      console.log("Updating task...");
      const apiPromises = newImage.map(uploadImage);
      const results = await Promise.all(apiPromises);

      const response = await fetch("https://infini8ai.herokuapp.com/roadmap", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          request: {
            method: "updateRoadMapGroup",
            data: {
              _id: single._id,
              _rev: single._rev,
              srNum: single.srNum,
              task: {
                taskName: state.userTask,
                assignee: single.assignee,
                status: single.status,
                priority: single.priority,
                estimatedTimeline: state.estimatedTimeline,
                dependentOn: single.dependentOn,
                anyText: state.desc,
                files: [...results, ...task.files],
                srNum: single.srNum,
                commentSection: commentList,
              },
            },
          },
        }),
      });
      console.log("REQUEST", response.body);

      setLoading(false);
      Toaster("success", "Task Update Successfully");
      onSuccess();
    } catch (err) {
      setError(err.message);
      onClose();
    }
  };

  const deleteTask = async () => {
    setLoading(true);
    try {
      await axios.post(`https://infini8ai.herokuapp.com/roadmap`, {
        request: {
          method: "deleteRoadMapTasks",
          data: {
            [myGroup._id]: [myTask.srNum],
          },
        },
      });

      console.log("Task deleted successfully.");
    } catch (error) {
      console.error("Failed to delete the task.", error);
    } finally {
      setLoading(false);

      onClose();

      Toaster("success", "Task Delete Successfully");
    }
  };

  return (
    <>
      {loading && (
        <div
          style={{
            fontSize: "50px",
            position: "absolute",
            top: "50%",
            bottom: "50%",
            left: "50%",
            right: "50%",
            zIndex: "9999",
          }}>
          <Loader />
        </div>
      )}
      <Dialog open={true} onClose={onClose} maxWidth="xl" fullWidth>
        <DialogContent>
          <Stack direction="row" p="16px" gap="48px">
            <Box flex="1">
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                mb="32px">
                <Typography
                  sx={{
                    color: "#67748E",
                    fontSize: "14px",
                    fontWeight: "500",
                  }}>
                  Assigned : {state.estimatedTimeline}
                </Typography>
                <Typography
                  sx={{
                    color: "#21A49E",
                    fontSize: "14px",
                    fontWeight: "500",
                  }}>
                  Task Details
                </Typography>
                <Button
                  sx={{ color: "#A42121", border: "1px solid #A42121" }}
                  onClick={deleteTask}>
                  {loading ? <Loader /> : "Remove"}
                </Button>
              </Stack>
              <Box display="flex" justifyContent="center">
                <Box
                  sx={{
                    bgcolor: "#F4F4F4",
                    borderRadius: "8px",
                    width: "236px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "135px",
                    mb: "16px",
                  }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}>
                    {task &&
                      task?.imageUrl &&
                      task?.imageUrl?.map((imageSrc, index) => (
                        <img
                          key={index}
                          src={imageSrc}
                          alt={`Img ${index}`}
                          style={{
                            width: "100px", // Adjust the width as needed
                            height: "auto",
                            marginRight: "10px", // Add some spacing between images
                          }}
                        />
                      ))}
                    {task?.imageUrl?.length === 0 && (
                      <img
                        src={uploadimage}
                        alt={`Img`}
                        style={{
                          width: "100px", // Adjust the width as needed
                          height: "auto",
                          marginRight: "10px", // Add some spacing between images
                        }}
                      />
                    )}

                    {displayImages.map((imageSrc, index) => (
                      <img
                        key={index}
                        src={imageSrc}
                        alt={`Img ${index}`}
                        style={{
                          width: "100px", // Adjust the width as needed
                          height: "auto",
                          marginRight: "10px", // Add some spacing between images
                        }}
                      />
                    ))}
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",

                  justifyContent: "center",
                  mb: "20px",
                }}>
                <input
                  type="file"
                  multiple
                  style={{ marginLeft: "116px" }}
                  onChange={handleImg}
                />
              </Box>

              <Box mb="16px">
                <InputLabel sx={{ mb: "5px" }}>Title</InputLabel>
                <TextField
                  placeholder="Project Task - 101 etc"
                  sx={{
                    width: "100%",
                    bgcolor: "#F7F7F7",
                    borderRadius: "4px",
                  }}
                  name="userTask"
                  value={state.userTask}
                  onChange={handleInput}
                />
              </Box>
              <Box mb="16px">
                <InputLabel sx={{ mb: "5px" }}>Description</InputLabel>
                <textarea
                  className="textareapop"
                  name="desc"
                  value={state.desc}
                  onChange={handleInput}
                  style={{ padding: "0 8px" }}></textarea>
              </Box>

              <Stack direction="row" justifyContent="space-between" gap="95px">
                <Box>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: "",

                      mb: "10px",
                    }}>
                    {task?.assignee} created this task
                  </Typography>
                  <Box sx={{ display: "flex", gap: "12px" }}>
                    <img src={emp} width="50px" height="50px" alt="" />
                    {/* <textarea
                    sx={{ width: "257px", height: "71px", bgcolor: "#F4F4F4" }}
                  ></textarea> */}
                    <Stack>
                      <Typography variant="p">Assigned to: </Typography>

                      <select
                        style={{
                          width: "150px",
                          height: "30px",
                          border: 0,
                          fontSize: "16px",
                          background: "#F7F7F7",
                        }}>
                        <option value={state.user}>{state.user}</option>
                      </select>
                    </Stack>
                  </Box>
                </Box>
                <Box>
                  <Typography sx={{ fontSize: "14px", mb: "10px" }}>
                    From : {state.estimatedTimeline}
                  </Typography>
                  <Typography sx={{ fontSize: "14px" }}>
                    To : {state.estimatedTimeline}
                  </Typography>
                </Box>
              </Stack>
            </Box>

            <Box flex="1" sx={{ bgcolor: "#F7F7F7", p: "8px" }}>
              <Typography fontSize="14px" mb="8px">
                Comments
              </Typography>
              <TextField
                placeholder="Project Task - 101 etc"
                name="comment"
                onChange={handleInput}
                value={comment}
                onKeyDown={handleAddComment}
                sx={{
                  width: "100%",
                  bgcolor: "#fff",
                  borderRadius: "4px",
                  borderLeft: "2px solid #67748E",
                }}
              />
              <Box>
                {commentList.map((comm) => {
                  return (
                    <Box
                      sx={{
                        width: "100%",
                        height: "56px",
                        bgcolor: "#fff",
                        my: "24px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "4px",
                        borderLeft: "2px solid #67748E",
                      }}>
                      {comm.comment}
                    </Box>
                  );
                })}
              </Box>
            </Box>
          </Stack>

          <Stack direction="row" spacing={2} justifyContent="center" mr="200px">
            <Button
              sx={{ background: "#F5F5F5", color: "white" }}
              onClick={onClose}>
              Cancel
            </Button>
            <Button
              sx={{ background: "#21A49E", color: "#fff" }}
              onClick={handleUpdate}>
              Update
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default TrakingDetail;
