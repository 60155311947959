import React, { useLayoutEffect, useState } from "react";
import "@fullcalendar/react/dist/vdom";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import { Stack, styled } from "@mui/system";
import { Grid, Typography } from "@mui/material";
import Logo from "../../assets/images/calender/calender.png";
import LeaveModel from "./LeaveModel";
import DashboardLayout from "../../layouts/Dashboard";
import DashboardNavbar from "../../layouts/Navbars/DashboardNavbar";
import { getLeavesByCompany } from "../../apiservices/calendar";
import moment from "moment";
export default function AttendanceReport() {
  const [state, setState] = useState({
    applyLeaves: false,
    leaveModal: false,
    events: [],
    rowEvent: [],
    eventStartDate: new Date(),
    eventEndDate: "",
    leavesCount: 0,
  });
  const Body = styled(Stack)({
    background: "#FFFFFF",
    padding: "10px",
    // margin: "20px",
    borderRadius: "10px",
  });
  useLayoutEffect(() => {
    getLeaves();
  }, []);
  const getLeaves = async () => {
    let data = {
      company_code: localStorage.getItem("companyCode"),
    };
    let res = await getLeavesByCompany(data);
    let dataArr = [];
    res.leavesArr.map((item) => {
      let date = moment(item.startDate, "DD-MM-YYYY").format("YYYY-MM-DD");
      let dateEnd = moment(item?.endDate, "DD-MM-YYYY").format("YYYY-MM-DD");
      let createdAt = moment.unix(item.created_at).format("DD-MM-YYYY");
      return dataArr.push({
        title: item.title,
        start: date,
        end: dateEnd,
        startT: item?.startTime,
        endT: item?.endTime,
        attachment: item.attachment,
        backgroundColor: "rgba(202, 41, 41, 0.12)",
        textColor: "#CA2929",
        borderColor: "#FFFFFF",
        description: item.description,
        applicantName: item.applicantName,
        status: item.status,
        leaveDate: date,
        leaveEndDate: dateEnd,
        aId: item._id,
        rev: item._rev,
        created: createdAt,
      });
    });
    setState((prev) => ({ ...prev, events: dataArr }));
  };
  const firstDaty = 1;
  const applyModel = (name, info = "") => {
    setState((prev) => ({
      ...prev,
      [name]: !prev[name],
      rowEvent: info.event?._def,
    }));
  };
  return (
    <DashboardLayout>
      <DashboardNavbar>
        <Grid
          container
          spacing={2}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}>
          <Grid item xs={1.8}>
            <img height="50px" widt="50px" src={Logo} alt="img" />
          </Grid>
          <Grid item xs={8}>
            <Typography
              variant="h6"
              sx={{
                fontWeight: "bold",
                display: "flex",
                justifyContent: "center",
              }}>
              Calender
            </Typography>
          </Grid>
          <Grid xs={2}> </Grid>
        </Grid>
      </DashboardNavbar>
      <Body sx={{ mt: 5 }}>
        <FullCalendar
          firstDay={firstDaty}
          locale="en"
          height={650}
          plugins={[dayGridPlugin]}
          events={state.events}
          eventClick={(info) => applyModel("leaveModal", info)}
        />
      </Body>
      {state.leaveModal && (
        <LeaveModel
          popup={state.leaveModal}
          closeTaskPopup={(leave) => applyModel(leave)}
          events={state.rowEvent}
          getLeaves={getLeaves}
          eventStartDate={state.eventStartDate}
          eventEndDate={state.eventEndDate}
        />
      )}
    </DashboardLayout>
  );
}
