import React, { useEffect } from "react";
import { Box, Button, styled, TextField, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import {
  formatDateDDMMYYYY,
  formatDateYYYYMMDD,
} from "../../user portal/reuseable components";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { saveMeeting, updateMeeting } from "../../apiservices/meeting";
import moment from "moment";
const schema = yup
  .object({
    title: yup.string().required("*title is required"),
    link: yup.string().required("*link is required"),
    date: yup.string().required("*date is required"),
    time: yup.string().required("*time is required"),
  })
  .required();
const AddMeeting = ({ closePop, getMeetings, isEdit, editRow }) => {
  const CancelBtn = styled(Button)({
    height: "34px",
    width: "136px",
    borderRadius: "4px",
    padding: "8px 50px 8px 50px",
    color: "#A7A7A7",
    background: "#FFFFFF",
    "&:hover": {
      boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.16)",
      color: "#A7A7A7",
    },
    "&:active": {
      background: " #A7A7A7",
      color: "#FFFFFF",
    },
  });
  const SaveBtn = styled(Button)({
    height: "34px",
    width: "136px",
    borderRadius: "4px",
    padding: "8px 50px 8px 50px",
    color: "#F5F5F5",
    background: "#21A49E",
    "&:hover": {
      boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.16)",
      background: "#1B8C87",
      color: "#F5F5F5",
    },
    "&:active": {
      background: " #146E6A",
      color: "#F5F5F5",
    },
  });
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmit = async (data) => {
    let date = formatDateDDMMYYYY(data.date);
    let meetingData = {
      ...(isEdit ? { _id: editRow._id, _rev: editRow._rev } : {}),
      name: data.title,
      meetingUrl: data.link,
      password: data.passcode,
      time: data.time,
      date: date,
      company_code: localStorage.getItem("companyCode"),
    };
    isEdit ? await updateMeeting(meetingData) : await saveMeeting(meetingData);

    isEdit
      ? toast.success("Meeting edit successfully")
      : toast.success("Meeting added successfully");
    getMeetings();
    closePop();
  };

  useEffect(() => {
    let setDate = moment().format("YYYY-MM-DD");
    reset({ date: setDate });
    if (isEdit) {
      let date = formatDateYYYYMMDD(editRow?.date);
      setValue("title", editRow?.name);
      setValue("link", editRow?.meetingUrl);
      setValue("date", date);
      setValue("time", editRow?.time);
      setValue("passcode", editRow?.password);
    }
  }, []);
  return (
    <>
      <Typography
        variant="h5"
        sx={{ textAlign: "center", color: "#21A49E", mb: 4 }}>
        Create new meeting
      </Typography>
      <Box>
        <TextField
          fullWidth
          label="Meeting Title"
          type="text"
          {...register("title")}
        />
        <Typography
          sx={{
            justifyContent: "center",
            color: "red",
            fontSize: "13px",
            mb: 3,
          }}>
          {errors.title?.message}
        </Typography>
      </Box>
      <Box>
        <TextField
          fullWidth
          label="Meeting Link"
          type="text"
          {...register("link")}
        />
        <Typography
          sx={{
            justifyContent: "center",
            color: "red",
            fontSize: "13px",
            mb: 3,
          }}>
          {errors.link?.message}
        </Typography>
      </Box>
      <Box>
        <TextField fullWidth type="date" {...register("date")} />
        <Typography
          sx={{
            justifyContent: "center",
            color: "red",
            fontSize: "13px",
            mb: 3,
          }}>
          {errors.date?.message}
        </Typography>
      </Box>
      <Stack direction="row" justifyContent="space-between">
        <Box>
          <TextField label="Set time" type="time" {...register("time")} />
          <Typography
            sx={{
              justifyContent: "center",
              color: "red",
              fontSize: "13px",
              mb: 3,
            }}>
            {errors.time?.message}
          </Typography>
        </Box>
        <TextField
          label="Passcode"
          type="text"
          sx={{ mb: 3 }}
          {...register("passcode")}
        />
      </Stack>
      <Stack direction="row" sx={{ mt: 3 }} justifyContent="end">
        <CancelBtn onClick={() => closePop()} sx={{ mx: 3 }}>
          Cancel
        </CancelBtn>
        <SaveBtn onClick={handleSubmit(onSubmit)}>Done</SaveBtn>
      </Stack>
    </>
  );
};

export default AddMeeting;
