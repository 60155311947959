import React from "react";
const NaseemMobile = () => {
  let url = "https://infini8ai-images.s3.amazonaws.com/naseem-casestudy";
  return (
    <div>
      <div style={{ background: "#5912F0" }}>
        <div className="text-center ">
          <img
            src={`${url}/splash logo.png`}
            className=" w-50 img-fluid   "
            style={{ marginTop: "-20px" }}
          />
        </div>
        <div className="text-center text-white">
          <p className="fs-2 fw-bold ">Naseem Education</p>
          <p className="fs-2 mt-3 ">
            A learning management system powered by AI, Neuroscience and
            Learning Sciences
          </p>
          <img src={`${url}/hero-illus.png`} className="  img-fluid   " />
        </div>
      </div>
      <div id="Problemsection">
        <div>
          <div className="text-center mt-4">
            <img src={`${url}/problem.svg`} className="  img-fluid   " />
          </div>
          <div className="text-center mt-4">
            <img
              src={`${url}/solution 1.svg`}
              className=" w-2ss5 img-fluid   "
            />
            <p className="p-2  fs-6 lh-sm">
              At Naseem education we feel that learning and management works
              side by side, for any organisation. Many educational institutes
              find it hard, to integrate their operations with quality learning
              based on latest technological developments.
            </p>
          </div>
        </div>
        <div>
          <div className="text-center mt-4">
            <img src={`${url}/solution.svg`} className="  img-fluid   " />
          </div>
          <div className="text-center mt-4">
            <img src={`${url}/brain.jpg`} className=" w-25 img-fluid   " />
            <p className="p-2  fs-6 lh-sm">
              Naseem Education is a powerful Management Information System, that
              is supported by state of the art learning management system.
              Powered by Artificial Intelligence, Neuroscience and Learning
              Sciences.
            </p>
          </div>
        </div>
        <div>
          <div className="text-center mt-4">
            <img src={`${url}/business.svg`} className="  img-fluid   " />
          </div>
          <div className="text-center mt-4">
            <img
              src={`${url}/agreement 1.svg`}
              className=" w-2ss5 img-fluid   "
            />
            <p className="p-2  fs-6 lh-sm">
              The target market for this product is huge, with around five
              hundred thousand schools in Pakistan. The company has the
              potential to capture a significant proportion of this market, with
              its expertise in learning sciences, software development and
              growth.
            </p>
          </div>
        </div>
      </div>
      <div id="SurveyAnalysis">
        <div style={{ background: "#F0A512" }} className="mt-5">
          <div className="text-center ">
            <img src={`${url}/survey.png`} className="  img-fluid   " />
          </div>
        </div>
      </div>
      <div id="Interviewpart">
        <div style={{ background: "#CB812B" }} className="pt-3">
          <div className="text-center ">
            <img src={`${url}/interview.svg`} className="  img-fluid   " />
          </div>
        </div>
      </div>
      <div id="MainLearnpart">
        <div style={{ background: "#F0A512" }} className="pt-3">
          <div className="text-center ">
            <img src={`${url}/learn1.svg`} className="  img-fluid   " />
          </div>
          <div className="text-center ">
            <img src={`${url}/learn2.svg`} className="  img-fluid   " />
          </div>
          <div className="text-center ">
            <img src={`${url}/learn3.svg`} className="  img-fluid   " />
          </div>
          <div className="text-center ">
            <img src={`${url}/learn4.svg`} className="  img-fluid   " />
          </div>
          <div className="text-center ">
            <img src={`${url}/learn5.svg`} className="  img-fluid   " />
          </div>
        </div>
      </div>
      <div id="UserPerson">
        <div style={{ background: "#E1F3FF" }} className="pt-3">
          <div className="text-center ">
            <img src={`${url}/userheading.svg`} className="  img-fluid   " />
          </div>
          <div className="text-center ">
            <img src={`${url}/userimg.svg`} className="  img-fluid   " />
          </div>
        </div>
      </div>
      <div id="Userdetail1">
        <div style={{ background: "#FFF4F4" }} className="pt-3">
          <div className="text-center ">
            <img src={`${url}/user1.svg`} className="  img-fluid   " />
          </div>

          <div className="firstAboutContent p-3">
            <div className="row" style={{ alignItems: "center" }}>
              <div className="col-md-5">
                <div>
                  <div className="aboutImg">
                    <p className="fw-bold fs-3 text-primary">About</p>
                  </div>
                  <div className="text-primary mt-4">
                    <p>
                      Mr. Ijaaz Ahmed is a thirty two year old married man with
                      two children. He works as a school executive in
                      Rawalpindi.
                    </p>
                    <p className="mt-4">
                      He wishes to do his work without moving around and he
                      wants easier way to accomplish his tasks without visiting
                      each school branch individuallly.
                    </p>
                    <p className="mt-4">
                      He appreciates technology as it is more centralized
                      management system where, he could do most of his work from
                      a single platform without moving around.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div id="questionAnswering">
                  <div className="aboutImg">
                    <p className="fs-3 fw-bold text-primary mt-4">Goals</p>
                  </div>
                  <div className="text-primary mt-4">
                    <div>
                      <ul className="d-flex ml-3mt-4 ">
                        <li
                          className="list-unstyled ml-1"
                          style={{ fontFamily: "Poppins" }}
                        >
                          <p>
                            {" "}
                            Manage school branches under my supervision from a
                            single platform.
                          </p>
                        </li>
                      </ul>
                      <ul className="d-flex ml-3 mt-4">
                        <li
                          className="list-unstyled ml-1"
                          style={{ fontFamily: "Poppins" }}
                        >
                          <p>
                            Don’t have to travel for day to day routine work.
                          </p>
                        </li>
                      </ul>
                      <ul className="d-flex ml-3 mt-4">
                        <li
                          className="list-unstyled ml-1"
                          style={{ fontFamily: "Poppins" }}
                        >
                          <p>
                            Make life more relaxing by reducing work stress.
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="aboutImg">
                    <p className="fs-3 fw-bold text-primary mt-4">
                      {" "}
                      Pain Points
                    </p>
                  </div>
                  <div className="aboutText">
                    <div className="text-primary">
                      <ul className="d-flex ml-3 mt-4">
                        <li className="list-unstyled ml-1">
                          <p>
                            Visiting every branch of school physically even for
                            smaller routine work.
                          </p>
                        </li>
                      </ul>
                      <ul className="d-flex ml-3 mt-4">
                        <li className="list-unstyled ml-1">
                          <p>
                            {" "}
                            More physical paper being used for records every
                            year.
                          </p>
                        </li>
                      </ul>
                      <ul className="d-flex ml-3 mt-4 ">
                        <li className="list-unstyled ml-1">
                          <p>
                            Mentally keeping track of everything happening
                            around.
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="Userdetail2">
        <div style={{ background: "#E1F3FF" }} className="pt-3">
          <div className="text-center ">
            <img src={`${url}/user2.svg`} className="  img-fluid   " />
          </div>

          <div className="firstAboutContent p-3">
            <div className="row" style={{ alignItems: "center" }}>
              <div className="col-md-5">
                <div>
                  <div className="aboutImg">
                    <p className="fw-bold fs-3 text-primary">About</p>
                  </div>
                  <div className="text-primary mt-4">
                    <p>
                      Mr. Kamran Rauf is a fourty year old married man with two
                      boys and two girls. He has taken the responsibility of a
                      school principal for several years and maintained his
                      positive reputation among community.
                    </p>
                    <p className="mt-4">
                      He considers his school reputataion and education progress
                      a very important task and in order to do so he has to
                      physically engage himself with staff and students in order
                      to review their progress and failures all the time.
                    </p>
                    <p className="mt-4">
                      Mr. Kamran is looking for a solution which could help him
                      oversee school’s day to day progress and act accordingly
                      based on the stats and engage the staff, students and
                      their parents when necessary.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div id="questionAnswering">
                  <div className="aboutImg">
                    <p className="fs-3 fw-bold text-primary mt-4">Goals</p>
                  </div>
                  <div className="text-primary mt-4">
                    <div>
                      <ul className="d-flex ml-3mt-4 ">
                        <li
                          className="list-unstyled ml-1"
                          style={{ fontFamily: "Poppins" }}
                        >
                          <p>
                            Oversee day to day school progress from a single
                            platform.
                          </p>
                        </li>
                      </ul>
                      <ul className="d-flex ml-3 mt-4">
                        <li
                          className="list-unstyled ml-1"
                          style={{ fontFamily: "Poppins" }}
                        >
                          <p>
                            Minimize the chances of daily physical interaction.
                          </p>
                        </li>
                      </ul>
                      <ul className="d-flex ml-3 mt-4">
                        <li
                          className="list-unstyled ml-1"
                          style={{ fontFamily: "Poppins" }}
                        >
                          <p>
                            Get to review to the point stats and act accordingly
                            based on the data.
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="aboutImg">
                    <p className="fs-3 fw-bold text-primary mt-4">
                      {" "}
                      Pain Points
                    </p>
                  </div>
                  <div className="aboutText">
                    <div className="text-primary">
                      <ul className="d-flex ml-3 mt-4">
                        <li className="list-unstyled ml-1">
                          <p>
                            Time it takes to interact with the staff in order to
                            get progress reports.
                          </p>
                        </li>
                      </ul>
                      <ul className="d-flex ml-3 mt-4">
                        <li className="list-unstyled ml-1">
                          <p>
                            {" "}
                            Not being sure about the stats and reports being 100
                            percent accurate.
                          </p>
                        </li>
                      </ul>
                      <ul className="d-flex ml-3 mt-4 ">
                        <li className="list-unstyled ml-1">
                          <p>
                            Not being able to get the data right on the spot
                            when needed.
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="Userdetail3">
        <div style={{ background: "#FFFBF4" }} className="pt-3">
          <div className="text-center ">
            <img src={`${url}/user3.svg`} className="  img-fluid   " />
          </div>

          <div className="firstAboutContent p-3">
            <div className="row" style={{ alignItems: "center" }}>
              <div className="col-md-5">
                <div>
                  <div className="aboutImg">
                    <p className="fw-bold fs-3 text-primary">About</p>
                  </div>
                  <div className="text-primary mt-4">
                    <p>
                      Miss. Nadia Samin is a twenty seven year old passionate
                      school teacher. She took this responsibility because of
                      her love for education and passing knowledge to the next
                      generation.
                    </p>
                    <p className="mt-4">
                      She teaches a bunch of classes on a daily basis and
                      sometimes finds it difficult, to maintain paperwork and
                      reports of daily routine work along with students progress
                      reports.
                    </p>
                    <p className="mt-4">
                      Ms. Nadia and other from the same school are looking for a
                      solution which could help them manage their classes from a
                      single platform in a simple yet efficient manner.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div id="questionAnswering">
                  <div className="aboutImg">
                    <p className="fs-3 fw-bold text-primary mt-4">Goals</p>
                  </div>
                  <div className="text-primary mt-4">
                    <div>
                      <ul className="d-flex ml-3mt-4 ">
                        <li
                          className="list-unstyled ml-1"
                          style={{ fontFamily: "Poppins" }}
                        >
                          <p>
                            Managing all of the classes in a better way rather
                            than using traditional method.
                          </p>
                        </li>
                      </ul>
                      <ul className="d-flex ml-3 mt-4">
                        <li
                          className="list-unstyled ml-1"
                          style={{ fontFamily: "Poppins" }}
                        >
                          <p>
                            Being able to convey her message and able to teach
                            those students who aren’t around.
                          </p>
                        </li>
                      </ul>
                      <ul className="d-flex ml-3 mt-4">
                        <li
                          className="list-unstyled ml-1"
                          style={{ fontFamily: "Poppins" }}
                        >
                          <p>
                            Be more efficient and modern in her ways of teaching
                            and overseeing classes altogether.
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="aboutImg">
                    <p className="fs-3 fw-bold text-primary mt-4">
                      {" "}
                      Pain Points
                    </p>
                  </div>
                  <div className="aboutText">
                    <div className="text-primary">
                      <ul className="d-flex ml-3 mt-4">
                        <li className="list-unstyled ml-1">
                          <p>
                            Student progress taking a step backwards when they
                            aren’t present in class.
                          </p>
                        </li>
                      </ul>
                      <ul className="d-flex ml-3 mt-4">
                        <li className="list-unstyled ml-1">
                          <p>
                            {" "}
                            Being overwhelmed with a bunch of tasks and keeping
                            records manually.
                          </p>
                        </li>
                      </ul>
                      <ul className="d-flex ml-3 mt-4 ">
                        <li className="list-unstyled ml-1">
                          <p>
                            Not being able to show progress to parents, whenever
                            needed because of their schedule.
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="Userdetail4">
        <div style={{ background: "#F4FFFC" }} className="pt-3">
          <div className="text-center ">
            <img src={`${url}/user4.svg`} className="  img-fluid   " />
          </div>

          <div className="firstAboutContent p-3">
            <div className="row" style={{ alignItems: "center" }}>
              <div className="col-md-5">
                <div>
                  <div className="aboutImg">
                    <p className="fw-bold fs-3 text-primary">About</p>
                  </div>
                  <div className="text-primary mt-4">
                    <p>
                      Mrs. Noreen Iqbal is a thirty nine year old married women
                      with two girls and one boy. She is protective of her
                      children and wants them to succeed in life and to get
                      better education.
                    </p>
                    <p className="mt-4">
                      She is mostly busy in her daily routine being a house-
                      wife, taking care of the entire family which makes it
                      difficult for her to visit her children`s school every
                      other day in order to get progress reports and feedback
                      from their teachers.
                    </p>
                    <p className="mt-4">
                      She is curious and very interested in the idea of having
                      an application, which could help tracking her children’s
                      daily progress and being aware of any announcements made
                      by teachers or staff in general.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div id="questionAnswering">
                  <div className="aboutImg">
                    <p className="fs-3 fw-bold text-primary mt-4">Goals</p>
                  </div>
                  <div className="text-primary mt-4">
                    <div>
                      <ul className="d-flex ml-3mt-4 ">
                        <li
                          className="list-unstyled ml-1"
                          style={{ fontFamily: "Poppins" }}
                        >
                          <p>
                            Making sure her kids get better education and
                            succeed in life.
                          </p>
                        </li>
                      </ul>
                      <ul className="d-flex ml-3 mt-4">
                        <li
                          className="list-unstyled ml-1"
                          style={{ fontFamily: "Poppins" }}
                        >
                          <p>
                            Being able to track her kids progress without
                            worrying about having to visit herself.
                          </p>
                        </li>
                      </ul>
                      <ul className="d-flex ml-3 mt-4">
                        <li
                          className="list-unstyled ml-1"
                          style={{ fontFamily: "Poppins" }}
                        >
                          <p>
                            Getting feedback and stats directly from the source.
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="aboutImg">
                    <p className="fs-3 fw-bold text-primary mt-4">
                      {" "}
                      Pain Points
                    </p>
                  </div>
                  <div className="aboutText">
                    <div className="text-primary">
                      <ul className="d-flex ml-3 mt-4">
                        <li className="list-unstyled ml-1">
                          <p>
                            Student progress taking a step backwards when they
                            aren’t present in class.
                          </p>
                        </li>
                      </ul>
                      <ul className="d-flex ml-3 mt-4">
                        <li className="list-unstyled ml-1">
                          <p>
                            {" "}
                            Being overwhelmed with a bunch of tasks and keeping
                            records manually.
                          </p>
                        </li>
                      </ul>
                      <ul className="d-flex ml-3 mt-4 ">
                        <li className="list-unstyled ml-1">
                          <p>
                            Not being able to show progress to parents, whenever
                            needed because of their schedule.
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NaseemMobile;
