import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import axios from "axios";
import React from "react";
import { useState } from "react";
import OutlinedInput from "@mui/material/OutlinedInput";

const UpdateRow = ({ updated, allGroups, rowUpdate, setRowUpdate }) => {
  const [formValues, setFormValues] = useState({
    taskName: updated.taskName,
    assignee: updated.assignee,
    status: updated.status,
    priority: updated.priority,
    startDate: updated.startDate,
    endDate: updated.endDate,
    dependentOn: updated.dependentOn,
    anyText: updated.anyText,
    files: updated.files, // Use null for the file input
  });

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: files ? files[0] : value, // Use files[0] for file input
    }));
  };

  const sendUpdateRequest = () => {
    // Define the API URL
    const apiUrl = "https://infini8ai.herokuapp.com/roadmap"; // Replace with your actual API URL

    // Prepare the request data
    const requestData = {
      request: {
        method: "updateRoadMapGroup",
        data: {
          _id: allGroups[0]._id, // Use the correct field from your data
          _rev: allGroups[0]._rev, // Use the correct field from your data
          // Use the correct field from your data
          task: {
            taskName: formValues.taskName,
            assignee: formValues.assignee,
            status: formValues.status,
            priority: formValues.priority,
            startDate: formValues.startDate,
            endDate: formValues.endDate,
            dependentOn: formValues.dependentOn,
            anyText: formValues.anyText,
            files: formValues.files,
            srNum: updated.srNum, // Use the correct field from your data
          },
        },
      },
    };
    console.log("Request Data:", requestData);

    // Send the HTTP POST request
    axios
      .post(apiUrl, requestData)
      .then((response) => {
        // Handle the response, e.g., show a success message
        console.log("Update successful:", response.data);
        setRowUpdate(false);
      })
      .catch((error) => {
        // Handle errors, e.g., show an error message
        console.error("Update failed:", error);
      });
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item md={12} xs={12}>
          <textarea
            style={{
              width: "100%",
              borderRadius: "8px",
              borderColor: "#BDBDBD",
              padding: 5,
            }}
            variant="outlined"
            placeholder="Task Name"
            rows={1}
            name="taskName"
            value={formValues.taskName}
            onChange={handleChange}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>Assignee</InputLabel>
            <Select
              name="assignee"
              sx={{ height: "2.8rem" }}
              input={<OutlinedInput label="Assignee" />}
              value={formValues.assignee}
              onChange={handleChange}>
              <MenuItem value="Salman Ghani">Salman Ghani</MenuItem>
              <MenuItem value="Mushtaq ahmad">Mushtaq ahmad</MenuItem>
              <MenuItem value="Abdullah">Abdullah</MenuItem>
              <MenuItem value="yasir">Yasir</MenuItem>
              <MenuItem value="Syed Sharukh">Syed Sharukh</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item md={6} xs={12}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>Status</InputLabel>
            <Select
              name="status"
              sx={{ height: "2.8rem" }}
              value={formValues.status}
              input={<OutlinedInput label="Status" />}
              onChange={handleChange}>
              <MenuItem value="Pending">Pending</MenuItem>
              <MenuItem value="InProgress">InProgress</MenuItem>
              <MenuItem value="Testing">Testing</MenuItem>
              <MenuItem value="DOne">Done</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item md={6} xs={12}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>Priority</InputLabel>
            <Select
              name="priority"
              sx={{ height: "2.8rem" }}
              value={formValues.priority}
              input={<OutlinedInput label="Priority" />}
              onChange={handleChange}>
              <MenuItem value="First">First</MenuItem>
              <MenuItem value="Second">Second</MenuItem>
              <MenuItem value="third">third</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item md={6} xs={12}>
          <TextField
            fullWidth
            id="outlined-required"
            variant="outlined"
            name="startDate"
            label="Start Date"
            type="date"
            value={formValues.startDate}
            onChange={handleChange}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            fullWidth
            id="outlined-required"
            variant="outlined"
            name="endDate"
            label="End Date"
            type="date"
            value={formValues.endDate}
            onChange={handleChange}
          />
        </Grid>

        <Grid item md={6} xs={12}>
          <TextField
            fullWidth
            id="outlined-required"
            variant="outlined"
            name="dependentOn"
            label="Collaborators"
            value={formValues.dependentOn}
            onChange={handleChange}
          />
        </Grid>
        <Grid item md={12} xs={12}>
          <textarea
            style={{
              width: "100%",
              borderRadius: "8px",
              borderColor: "#BDBDBD",
              padding: 5,
            }}
            variant="outlined"
            name="anyText"
            placeholder="Description"
            value={formValues.anyText}
            onChange={handleChange}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            fullWidth
            variant="outlined"
            name="files"
            // value={task.files}
            type="file"
            placeholder="choose file"
            onChange={handleChange}
          />
        </Grid>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          my={2}>
          <Button onClick={() => setRowUpdate(false)}>Cancel</Button>
          <Button onClick={sendUpdateRequest}>Save</Button>
        </Stack>
      </Grid>
    </>
  );
};

export default UpdateRow;
