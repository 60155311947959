import React, { useEffect } from "react";
import { ViewMode, Gantt } from "gantt-task-react";
import { ViewSwitcher } from "./view-switcher";
import "gantt-task-react/dist/index.css";
import { Box } from "@mui/material";

//Init
const GanttChart = ({ tasks, onClick }) => {
  const [view, setView] = React.useState(ViewMode.Day);
  const [isChecked, setIsChecked] = React.useState(true);
  let columnWidth = 100;
  if (view === ViewMode.Month) {
    columnWidth = 300;
  } else if (view === ViewMode.Week) {
    columnWidth = 250;
  }
  const handleProgressChange = async (task) => {
    // setTasks(tasks.map((t) => (t.id === task.id ? task : t)));
    console.log("On progress change Id:" + task.id);
  };
  const handleDblClick = (task) => {
    alert("On Double Click event Id:" + task.id);
  };
  const handleSelect = (task, isSelected) => {
    console.log(task.name + " has " + (isSelected ? "selected" : "unselected"));
  };
  const handleExpanderClick = (task) => {
    // setTasks(tasks.map((t) => (t.id === task.id ? task : t)));
    console.log("On expander click Id:" + task.id);
  };
  return (
    <div>
      <Box sx={{ mb: 4 }}>
        <ViewSwitcher
          onViewModeChange={(viewMode) => setView(viewMode)}
          onViewListChange={setIsChecked}
          isChecked={isChecked}
        />
      </Box>
      <Gantt
        tasks={tasks}
        viewMode={view}
        onClick={onClick}
        onProgressChange={handleProgressChange}
        onDoubleClick={handleDblClick}
        onSelect={handleSelect}
        onExpanderClick={handleExpanderClick}
        handleWidth={50}
        // listCellWidth={isChecked ? "155px" : ""}
        ganttHeight={220}
        columnWidth={columnWidth}
        headerHeight={95}
        // barBackgroundColor="#EF5350"
        projectBackgroundColor={"#fac465"}
        barBackgroundColor={"#43A047"}
        todayColor={"#E0E1E4"}
      />
    </div>
  );
};
export default GanttChart;
