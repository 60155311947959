import axios from "axios";

export const getLeavesById = async (userData) => {
  let res = await axios.post(`https://infini8ai.herokuapp.com/leave`, {
    request: {
      method: "getLeavesById",
      data: userData,
    },
  });
  let json = res.data.response.data;
  return json;
};
export const getLeavesCountById = async (userData) => {
  let res = await axios.post(`https://infini8ai.herokuapp.com/leave`, {
    request: {
      method: "getLeavesCountById",
      data: userData,
    },
  });
  let json = res.data.response.data;
  return json;
};
export const saveLeave = async (userData) => {
  let res = await axios.post(`https://infini8ai.herokuapp.com/leave`, {
    request: {
      method: "saveLeave",
      data: userData,
    },
  });
  let json = res.data.response.data;
  return json;
};

export const getLeavesByMonth = async (userData) => {
  let res = await axios.post(`https://infini8ai.herokuapp.com/leave`, {
    request: {
      method: "getLeavesByMonth",
      data: userData,
    },
  });
  let json = res.data.response.data;
  return json;
};
export const getLeavesByCompany = async (userData) => {
  let res = await axios.post(`https://infini8ai.herokuapp.com/leave`, {
    request: {
      method: "getLeavesByCompany",
      data: userData,
    },
  });
  let json = res.data.response.data;
  return json;
};
export const updateLeave = async (userData) => {
  let res = await axios.post(`https://infini8ai.herokuapp.com/leave`, {
    request: {
      method: "updateLeave",
      data: userData,
    },
  });
  let json = res.data.response.data;
  return json;
};
