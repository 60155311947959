import React from "react";
import stockmobile from "../../../assets/images/stock/stockimg.svg";
import illustration from "../../../assets/images/stock/illustration.png";
import probillustration from "../../../assets/images/stock/Problem illustration.svg";
import probstatement from "../../../assets/images/stock/probstatement.svg";
import solution from "../../../assets/images/stock/solution.svg";
import stockmarket from "../../../assets/images/stock/stock-market 1.svg";
import business from "../../../assets/images/stock/business.svg";
import interviewheading from "../../../assets/images/stock/interviewquestn.svg";
import learnheading from "../../../assets/images/stock/learnheading.svg";
import learn1 from "../../../assets/images/stock/learn1.svg";
import learn2 from "../../../assets/images/stock/learn2.svg";
import learn3 from "../../../assets/images/stock/learn3.svg";
import learn4 from "../../../assets/images/stock/learn4.svg";
import userperson from "../../../assets/images/stock/userperson.svg";
import empathymap from "../../../assets/images/stock/empathymap.svg";
import empathydo from "../../../assets/images/stock/empathydo.svg";
import empathysee from "../../../assets/images/stock/empathysee.svg";
import empathyhear from "../../../assets/images/stock/empathyhear.svg";
import empathypain from "../../../assets/images/stock/empathypain.svg";
import empathygain from "../../../assets/images/stock/empathygain.svg";
import empathygroup from "../../../assets/images/stock/empathygroup.svg";
import mobiledesign from "../../../assets/images/stock/mobiledesign.svg";
import stockchainlogo from "../../../assets/images/stock/stockchainlogo.svg";
import Group334 from "../../../assets/images//stock/Group334.png";
import Group336 from "../../../assets/images/stock/Group336.png";
import Union from "../../../assets/images/optimus/Union.svg";
import Group332 from "../../../assets/images/stock/Group332.png";
import StockChainIllustration from "../../../assets/images/stock/StockChainIllustration.png";
import footerbottem from "../../../assets/images/footer/footerbottem.png";
import { Link ,useNavigate , } from "react-router-dom";
import { Link as Linkscroll,scroller } from "react-scroll";
const StockChainmobile = () => {
  let url = "https://infini8ai-images.s3.amazonaws.com/stock";
  const navigate = useNavigate();

  const handleScroll = (section) => {


    navigate('/');
    setTimeout(() => {
      scroller.scrollTo(section, {
        duration: 500,
        smooth: true,
      });
    }, 100);
  };
  return (
    <div>
      <div style={{ height: "100%" }}>
        <div className="stockmobile_background1  ">
          <div className="stockChain_Text text-white d-flex justify-content-center  pt-4">
            <h3> StockChain</h3>
            <img src={stockmobile} className="img-fluid  " />
          </div>
          <div className="stockChain_Text p-3 text-center text-white mt-3">
            <p>
              To eliminate the invisible layers in Logistics through our
              technology platforms
            </p>
          </div>
          <div className="text-center ">
            <img
              src={illustration}
              className="img-fluid   "
              style={{ width: "50%", position: "relative", top: "10px" }}
            />
          </div>
        </div>
        <div className="stockmobile_background2">
          <div
            className="stockChain_Text text-center "
            style={{ paddingTop: "100px" }}
          >
            <p className="text-black fw-bold ">
              Connecting people and simplify logistics in Pakistan, delivering
              goods with responsibility.
            </p>
            <p className="text-black fw-bold">
              StockChain is a third party logistics reaching 50 destinations
              across Pakistan.
            </p>
          </div>
        </div>
      </div>
      <div className="text-center">
        <img src={probillustration} className="img-fluid   " />
      </div>
      <div className="text-center">
        <img src={probstatement} className="img-fluid   " />
      </div>
      <div className="text-center">
        <img
          src={StockChainIllustration}
          className="img-fluid   "
        />
      </div>
      <div className="text-center pt-2">
        <img src={solution} className="img-fluid   " />
      </div>
      <div className="text-center pt-2">
        <img src={stockmarket} className="img-fluid   " />
      </div>
      <div className="text-center pt-2">
        <img src={business} className="img-fluid   " />
      </div>
      <div id="StockSurveyAnalysis">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="StockAnalysisContentMain">
                <p>Survey Analysis</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="stockChain_Text text-white">
                <p>
                  They served <span>15</span> Warehouses, <span>5</span>{" "}
                  Logistic company owners, and <span>10</span> Cargo companies.
                </p>
              </div>
            </div>
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-6">
                  <div className="StockAnalysisContentCardImg">
                    <img
                      src={`${url}/Group273.png`}
                      className="GroupImg273"
                      alt="Group273"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="StockAnalysisContentCardImg">
                    <img
                      src={`${url}/Group274.png`}
                      className="GroupImg274"
                      alt="Group274"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="StockAnalysisContentCardImg">
                    <img
                      src={`${url}/Group275.png`}
                      className="GroupImg275"
                      alt="Group275"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="StockAnalysisContentCardImg">
                    <img
                      src={`${url}/Group276.png`}
                      className="GroupImg276"
                      alt="Group276"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="StockInterview">
        <div style={{ background: "#C4D6ED" }}>
          <div className="text-center pt-5">
            <img src={interviewheading} className="img-fluid   " />
          </div>
          <div className="text-center ">
            <img src={`${url}/interviewdetail.svg`} className="img-fluid   " />
          </div>
        </div>
      </div>
      <div id="StockLearn">
        <div style={{ background: "#C4EDEB" }}>
          <div className="text-center pt-5">
            <img src={learnheading} className="img-fluid   " />
          </div>
          <div className="text-center ">
            <img src={learn1} className="img-fluid   " />
          </div>
          <div className="text-center ">
            <img src={learn2} className="img-fluid   " />
          </div>
          <div className="text-center ">
            <img src={learn3} className="img-fluid   " />
          </div>
          <div className="text-center ">
            <img src={learn4} className="img-fluid   " />
          </div>
        </div>
      </div>
      <div id="UserPerson">
        <div style={{ background: "#C4D6ED" }}>
          <div className="text-center pt-5">
            <img src={userperson} className="img-fluid   " />
          </div>
          <div className="text-center ">
            <img src={`${url}/userpic.svg`} className="img-fluid   " />
          </div>
          <div className="firstAboutContent p-3 pb-5">
            <div className="row" style={{ alignItems: "center" }}>
              <div className="col-md-5">
                <div>
                  <div className="aboutImg">
                    <img
                      src={Group334}
                      className="img-fluid w-50"
                      alt="Group334"
                    />
                  </div>
                  <div className="stockChain_Text ">
                    <p>
                      Mr. Hassan Mir is a thirty seven year old logistic company
                      owner in islamabad. He started working in this field about
                      a decade ago and persues more success in coming years.
                    </p>
                    <p className="mt-4">
                      He’s well aware of technological advancements and how
                      everything is movings towards digital platforms. He
                      believes that his company can benefit from it as well.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div id="questionAnswering">
                  <div className="aboutImg">
                    <img
                      src={Group336}
                      className="w-50 img-fluid"
                      alt="Group336"
                    />
                  </div>
                  <div className="stockChain_Text">
                    <div>
                      <ul className="d-flex ml-3 mt-4">
                        <li className="list-unstyled mr-1  pt-1">
                          <img src={Union} className="Union" alt="union" />
                        </li>
                        <li
                          className="list-unstyled ml-1"
                          style={{ fontFamily: "Poppins" }}
                        >
                          <p>
                            {" "}
                            Earn more success and respect for his business in
                            coming years.
                          </p>
                        </li>
                      </ul>
                      <ul className="d-flex ml-3 mt-4">
                        <li className="list-unstyled mr-1 pt-1">
                          <img src={Union} className="Union" alt="union" />
                        </li>
                        <li
                          className="list-unstyled ml-1"
                          style={{ fontFamily: "Poppins" }}
                        >
                          <p>
                            Incorporate technology in his business to stay
                            up-to-date with current times.{" "}
                          </p>
                        </li>
                      </ul>
                      <ul className="d-flex ml-3 mt-4">
                        <li className="list-unstyled mr-1 pt-1">
                          <img src={Union} className="Union" alt="union" />
                        </li>
                        <li
                          className="list-unstyled ml-1"
                          style={{ fontFamily: "Poppins" }}
                        >
                          <p>
                            Provide better experience to his client and
                            employees.
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="aboutImg">
                    <img
                      src={Group332}
                      className="w-75 img-fluid"
                      alt="Group332"
                    />
                  </div>
                  <div className="aboutText">
                    <div className="stockChain_Text">
                      <ul className="d-flex ml-3 mt-4 ">
                        <li className="list-unstyled mr-1 pt-1">
                          <img src={Union} className="Union" alt="union" />
                        </li>
                        <li className="list-unstyled ml-1">
                          <p>
                            Mishandling and Misplacing shipments happens very
                            often.
                          </p>
                        </li>
                      </ul>
                      <ul className="d-flex ml-3 mt-4">
                        <li className="list-unstyled mr-1 pt-1">
                          <img src={Union} className="Union" alt="union" />
                        </li>
                        <li className="list-unstyled ml-1">
                          <p> Improper management system to avoid theft.</p>
                        </li>
                      </ul>
                      <ul className="d-flex ml-3 mt-4">
                        <li className="list-unstyled mr-1 pt-1">
                          <img src={Union} className="Union" alt="union" />
                        </li>
                        <li className="list-unstyled ml-1">
                          <p>
                            Lack of proper shipment tracking system to ensure
                            safe and on-time delivery.
                          </p>
                        </li>
                      </ul>
                      <ul className="d-flex ml-3 mt-4">
                        <li className="list-unstyled mr-1 pt-1">
                          <img src={Union} className="Union" alt="union" />
                        </li>
                        <li className="list-unstyled ml-1">
                          <p>
                            Points mentioned above resulting in financial loss.
                          </p>
                        </li>
                      </ul>
                      <div className="text-center mt-3 ">
                        <img
                          src={`${url}/muneeb.svg`}
                          className="img-fluid   "
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row" style={{ alignItems: "center" }}>
              <div className="col-md-5">
                <div>
                  <div className="aboutImg">
                    <img
                      src={Group334}
                      className="img-fluid w-50"
                      alt="Group334"
                    />
                  </div>
                  <div className="stockChain_Text ">
                    <p>
                      Mr. Muneeb Nawaz is a twenty nine year old man, who’s been
                      working as a courier in a local cargo company in
                      Rawalpindi for the past couple of years.
                    </p>
                    <p className="mt-4">
                      He believes incorporating technology in his daily routine
                      work will help him in becoming more efficient in his work.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div id="questionAnswering">
                  <div className="aboutImg">
                    <img
                      src={Group336}
                      className="w-50 img-fluid"
                      alt="Group336"
                    />
                  </div>
                  <div className="stockChain_Text">
                    <div>
                      <ul className="d-flex ml-3mt-4 ">
                        <li className="list-unstyled mr-1  pt-1">
                          <img src={Union} className="Union" alt="union" />
                        </li>
                        <li
                          className="list-unstyled ml-1"
                          style={{ fontFamily: "Poppins" }}
                        >
                          <p> Being more efficient in his work.</p>
                        </li>
                      </ul>
                      <ul className="d-flex ml-3 mt-4">
                        <li className="list-unstyled mr-1 pt-1">
                          <img src={Union} className="Union" alt="union" />
                        </li>
                        <li
                          className="list-unstyled ml-1"
                          style={{ fontFamily: "Poppins" }}
                        >
                          <p>Finding better ways to reach deadlines.</p>
                        </li>
                      </ul>
                      <ul className="d-flex ml-3 mt-4">
                        <li className="list-unstyled mr-1 pt-1">
                          <img src={Union} className="Union" alt="union" />
                        </li>
                        <li
                          className="list-unstyled ml-1"
                          style={{ fontFamily: "Poppins" }}
                        >
                          <p>
                            Getting rid of manual paperwork habit and
                            transfering his work over digital platform.
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="aboutImg">
                    <img
                      src={Group332}
                      className="w-75 img-fluid"
                      alt="Group332"
                    />
                  </div>
                  <div className="aboutText">
                    <div className="stockChain_Text">
                      <ul className="d-flex ml-3 mt-4">
                        <li className="list-unstyled mr-1 pt-1">
                          <img src={Union} className="Union" alt="union" />
                        </li>
                        <li className="list-unstyled ml-1">
                          <p>
                            Lots of physical paper being used for manual entries
                            on a daily basis.
                          </p>
                        </li>
                      </ul>
                      <ul className="d-flex ml-3 mt-4">
                        <li className="list-unstyled mr-1 pt-1">
                          <img src={Union} className="Union" alt="union" />
                        </li>
                        <li className="list-unstyled ml-1">
                          <p>
                            {" "}
                            Time wastage because of daily on-site meetings to
                            recieve tasks.
                          </p>
                        </li>
                      </ul>
                      <ul className="d-flex ml-3 mt-4 ">
                        <li className="list-unstyled mr-1 pt-1">
                          <img src={Union} className="Union" alt="union" />
                        </li>
                        <li className="list-unstyled ml-1">
                          <p>
                            Lack of proper shipment tracking system to ensure
                            safe and on-time delivery.
                          </p>
                        </li>
                      </ul>
                      <ul className="d-flex ml-3 mt-4">
                        <li className="list-unstyled mr-1 pt-1">
                          <img src={Union} className="Union" alt="union" />
                        </li>
                        <li className="list-unstyled ml-1">
                          <p>
                            Points mentioned above resulting in financial loss
                            and credibility as a courier.
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="Empathypart">
        <div style={{ background: "#005BFF" }}>
          <div className="text-center pt-2">
            <img src={empathymap} className="img-fluid   " />
          </div>
          <div className="text-center pt-2">
            <img src={empathydo} className="img-fluid   " />
          </div>
          <div className="text-center pt-2">
            <img src={empathysee} className="img-fluid   " />
          </div>
          <div className="text-center pt-2">
            <img src={empathyhear} className="img-fluid   " />
          </div>
          <div className="text-center pt-2">
            <img src={empathypain} className="img-fluid   " />
          </div>
          <div className="text-center pt-2">
            <img src={empathygain} className="img-fluid   " />
          </div>{" "}
          <div className="text-center pt-2">
            <img src={empathygroup} className="img-fluid   " />
          </div>
        </div>
      </div>
      <div id="Mobiledesign " style={{paddingTop:"3rem", paddingBottom:"3rem"}}>
        <div className="text-center pt-2">
          <img src={mobiledesign} className="img-fluid   " />
        </div>
        <div className="text-center pt-2">
          <img src={`${url}/mobiledesign1.svg`} className="img-fluid   " />
        </div>
        <div className="text-center pt-2">
          <img src={`${url}/mobiledesign2.svg`} className="img-fluid   " />
        </div>
      </div>
      <div style={{ background: " #C6F7FF" }}>
        <div className="text-center pt-2">
          <img src={stockchainlogo} className="img-fluid   " />
        </div>
      </div>
      <div id="footerpart">
      <footer
      style={{
        backgroundColor: "#345BFF",
        backgroundImage: "none",
      }}
      className="page-footer font-small   pb-3 "
    >
      <div className="container">
        <div className="row footer-row" >
          <div className="col-12 col-lg-3 col-md-4 footer-sitemap">
            <p
              className="fs-6 mt-4"
              style={{ fontFamily: "Dosis",color:"#dde73c", }}
            >
              SITE MAP
            </p>
           
            <div>
                {/* <p style={{ margin: "0 0 20px 0",color: "#116065", fontWeight:600,font:"poppins", fontSize:"24px" }}>Case Studies</p> */}
                <ul  className="text-white mt-4 lh-sm justify-content-md-start justify-content-center"
              style={{ fontFamily: "Dosis" , listStyleType: "none", display:"flex", gap:"4rem"}}>
                <div style={{display:"flex", flexDirection:"column", gap:"3rem"}}>
                   <Link to="/" style={{  }}>
                   <li style={{color:"white",fontSize:"16px"}}>Home</li>
                  </Link>
                  <div
          className="cursor-pointer"
          style={{ cursor: "pointer",  }}
          onClick={() => handleScroll("ourProducts")}
        >
            <li style={{ fontFamily: "Dosis",fontSize:"16px"}}>Products</li>
        </div>
                  {/* <li>About Us</li> */}
                  <Link to="/our-process" >
                  <li style={{color:"white",fontSize:"16px"}}>Our Process</li>
                  </Link>
                  </div>
                  <div  style={{display:"flex", flexDirection:"column", gap:"3rem"}}>
                  <Link to="/team" >
                  <li style={{ color:"white",fontSize:"16px" }}>Team</li>
                  </Link>
                  <Link to="/portfolio" >
                  <li style={{ color:"white",fontSize:"16px"}}>Portfolio</li>
                  </Link>
                  <Link to="/careers" >
                  <li style={{ color:"white",fontSize:"16px"}}>Careers</li>
                  </Link>
                  </div>
                </ul>
                </div>
          </div>
          <div className="col-12 col-lg-3 col-md-4 footer-contactus">
            <p
              className="fs-6 mt-4 "
              style={{ fontFamily: "Dosis",color:"#dde73c" }}
            >
              CONTACT US
            </p>
            <p  
              className="text-white mt-4 lh-sm"
              style={{ fontFamily: "Dosis" ,fontSize:"16px"}}
            >
              IDEA London Ground Floor 69 Wilson Street, London EC2A 2BB
              <p className="mt-4 mb-4 text-white lh-sm"   style={{ fontFamily: "Dosis",fontSize:"16px" }}>For hiring inquiries please contact: <span style={{fontFamily: "Dosis",fontSize:"16px"}}>hr@infini8ai.com</span></p> 
              
            </p>
            <p
              className="text-white mt-4 lh-sm"
              style={{ fontFamily: "Dosis" }}
            >
          
              <p className="mt-4 mb-4 text-white lh-sm"   style={{ fontFamily: "Dosis",fontSize:"16px" }}>For all other queries please contact: <span style={{fontFamily: "Dosis",fontSize:"16px"}}>info@infini8ai.com </span> </p> 
              
            </p>
            <div className=" mt-2">
          <p style={{ color: "white",fontSize:"16px" }}>2024 Copyright: Infini8AI</p>
        </div>

          </div>
          <div className="col-12 col-lg-3 col-md-4 footer-casestudies" >
            <p
              className="fs-6  mt-4  "
              style={{ fontFamily: "Dosis",color:"#dde73c" }}
            >
              CASE   STUDIES
            </p>
            {/* <p
              className="text-white mt-4 lh-sm"
              style={{ fontFamily: "Bitter" }}
            >
              To receive updates about our new product offerings and latest
              developments in the world of AI, join our bi-weekly newsletter:{" "}
              <a href="https://veracious.ai/news-letter" target="_blank">
                Veracious AI
              </a>
            </p> */}
              <div>
                {/* <p style={{ margin: "0 0 20px 0",color: "#116065", fontWeight:600,font:"poppins", fontSize:"24px" }}>Case Studies</p> */}
                <ul  className="text-white mt-4 lh-sm"
              style={{ fontFamily: "Dosis" , listStyleType: "none", display:"flex",  gap:"4rem"}}>
                    <div  style={{display:"flex", flexDirection:"column", gap:"3rem"}}>

                    <Link to="/optimus-case-study">
                    <li style={{color:"white",fontSize:"16px"}}>Healthcare</li>
                    </Link>
                    <Link to="/naseem-case-study">
                    <li style={{color:"white",fontSize:"16px"}}>Education</li>
                    </Link>
                    <Link to="/qurbani-case-study">
                    <li style={{color:"white",fontSize:"16px"}}>Livestock</li>
                    </Link>
                    </div>
                    <div  style={{display:"flex", flexDirection:"column", gap:"3rem"}}>

                    <Link to="/stock-chain-case-study">
                    <li style={{color:"white",fontSize:"16px"}}>Logistics</li>
                    </Link>
                    <Link to="/businesspal-case-study">
                    <li style={{color:"white",fontSize:"16px"}}>Business</li>
                    </Link>
                    </div>
                </ul>
                </div>

          </div>
        </div>
        
      </div>
    </footer>
      </div>
    </div>
  );
};

export default StockChainmobile;
