import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Stack } from "@mui/system";
import CardImg from "../../../assets/images/project/cardImg.png";
import { Grid, InputLabel } from "@mui/material";
import { CancelButton } from "../../../styles/style";
import { formatDateYYYYMMDD } from "../../../user portal/reuseable components";

window.Buffer = window.Buffer || require("buffer").Buffer;
const TaskDetailPopup = ({ popup, closeTaskPopup, selectedRow }) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: "#FFFFFF",
    boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.2)",
    borderRadius: "12px",
    // height: "500px",
    p: 4,
  };
  console.log(selectedRow);
  return (
    <div>
      <Modal
        open={popup}
        onClose={() => closeTaskPopup("viewTaskDetail")}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style} justifyContent="center">
          <Stack direction="row" justifyContent="center">
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Task Details
            </Typography>
          </Stack>
          <Stack justifyContent="center" alignItems="center" sx={{ p: 2 }}>
            <img
              src={selectedRow.imageUrl ? selectedRow.imageUrl : CardImg}
              alt=""
              width="200px"
              height="100px"
            />
          </Stack>
          <InputLabel>Title</InputLabel>
          <input
            type="text"
            name="task"
            disabled
            style={{
              borderLeft: "3px solid #21A49E",
              borderTop: "0px",
              borderBottom: "0px",
              borderRight: "0px",
              marginBottom: "8px",
              background: "#F7F7F7",
              width: "556px",
              height: 40,
              paddingLeft: "10px",
              borderRadius: "4px",
            }}
            value={selectedRow.task}
          />
          <InputLabel>Description</InputLabel>

          <textarea
            type="text"
            name="details"
            disabled
            style={{
              borderLeft: "3px solid #21A49E",
              borderTop: "0px",
              borderBottom: "0px",
              borderRight: "0px",
              overflowWrap: "break-word",
              background: "#F7F7F7",
              width: "556px",
              // height: 40,
              paddingLeft: "10px",

              borderRadius: "4px",
            }}
            value={selectedRow.details}
          />
          <Stack direction="row" justifyContent="space-between" sx={{ mt: 3 }}>
            <Typography variant="p">
              {selectedRow.assignedBy} created this task
            </Typography>
            <Box display="flex">
              <Typography variant="p" sx={{ mx: 2 }}>
                due date:
              </Typography>

              <input
                type="date"
                name="deadline"
                disabled
                style={{
                  border: 0,
                  background: "#F7F7F7",
                }}
                value={formatDateYYYYMMDD(selectedRow.deadline)}
              />
            </Box>
          </Stack>
          <Grid container sx={{ mt: 2 }}>
            <Grid item xs={1}>
              <img
                src={CardImg}
                alt="card"
                width="50px"
                height="50px"
                style={{ borderRadius: "100px" }}
              />
            </Grid>
            <Grid item xs={5} sx={{ mx: 2 }}>
              <Stack>
                <Typography variant="p">Assigned to: </Typography>
                <Typography sx={{ fontSize: "12px" }}>
                  {selectedRow.members && selectedRow.members[0].name}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={4} sx={{ display: "flex" }}>
              <CancelButton
                size="small"
                onClick={() => closeTaskPopup("viewTaskDetail")}
                sx={{ mx: 1 }}>
                Cancel
              </CancelButton>
              {/* <SaveButton size="small">Save</SaveButton> */}
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};

export default TaskDetailPopup;
