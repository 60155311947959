import React from "react";
import Qurbanimobile from "./Qurbanimobile";
import useWindowSize from "../../utils/useWindowSize";
import Qurbanimobile1 from "./Qurbanimobile1";
import QurbaniSection1 from "./QurbaniSection1";
import QurbaniSection2 from "./QurbaniSection2";
import QurbaniSection3 from "./QurbaniSection3";
import QurbaniSection4 from "./QurbaniSection4";
import NavbarMenu from "../../common/NavbarMenu";
import PageLayout from "../../../layouts/Page";

const Qurbani = () => {
  const size = useWindowSize();

  return (
    <PageLayout>
      <div>
        <NavbarMenu />

        {size.width < 768 ? (
          <>
            <Qurbanimobile />
          </>
        ) : (
          <>
            <QurbaniSection1 />
            <QurbaniSection2 />
            <QurbaniSection3 />
            <QurbaniSection4 />
          </>
        )}
      </div>
    </PageLayout>
  );
};

export default Qurbani;
