import React from "react";
import Interviewone from "../../../assets/images/stock/Group277.png";

const StockSection2 = () => {
  let url = "https://infini8ai-images.s3.amazonaws.com/stock";
  return (
    <div>
      <div id="StockSurveyAnalysis">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="StockAnalysisContentMain">
                <p>Survey Analysis</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="StockAnalysisContent">
                <p>
                  They served <span>15</span> Warehouses, <span>5</span>{" "}
                  Logistic company owners, <br />
                  and <span>10</span> Cargo companies.
                </p>
              </div>
            </div>
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-6">
                  <div className="StockAnalysisContentCardImg">
                    <img
                      src={`${url}/Group273.png`}
                      className="GroupImg273"
                      alt="Group273"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="StockAnalysisContentCardImg">
                    <img
                      src={`${url}/Group274.png`}
                      className="GroupImg274"
                      alt="Group274"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="StockAnalysisContentCardImg">
                    <img
                      src={`${url}/Group275.png`}
                      className="GroupImg275"
                      alt="Group275"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="StockAnalysisContentCardImg">
                    <img
                      src={`${url}/Group276.png`}
                      className="GroupImg276"
                      alt="Group276"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="StockInterviewQuestions">
        <div className="container">
          <div className="row">
            <div className="col-md-8 offset-md-2 text-center">
              <div className="StockInterviewImage1">
                <img
                  src={Interviewone}
                  className="Groupimg277"
                  alt="Group277"
                />
              </div>
            </div>
          </div>
          <div className="row pt-5">
            <div className="col-md-12 text-center">
              <div className="StockInterviewImage2">
                <img
                  src={`${url}/Group278.png`}
                  className="Groupimg278"
                  alt="Group278"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="StockMainLearning">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="StockLearningContentMain">
                <p>Main Learnings</p>
              </div>
            </div>
            <div className="col-md-12 ">
              <div className="row pt-5">
                <div className="col-md-6">
                  <div className="StockAnalysisContentCardImg">
                    <img
                      src={`${url}/Group280.png`}
                      className="GroupImg280"
                      alt="Group280"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="StockAnalysisContentCardImg">
                    <img
                      src={`${url}/Group281.png`}
                      className="GroupImg281"
                      alt="Group281"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="StockAnalysisContentCardImg">
                    <img
                      src={`${url}/Group282.png`}
                      className="GroupImg282"
                      alt="Group282"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="StockAnalysisContentCardImg">
                    <img
                      src={`${url}/Group283.png`}
                      className="GroupImg283"
                      alt="Group283"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StockSection2;
