import React from "react";
import StockChain1CWhiteLogomark from "../../../assets/images/stock/StockChain1CWhiteLogomark.svg";
import illustrationLogistics from "../../../assets/images/stock/illustrationLogistics.svg";
import Problemillustration from "../../../assets/images/stock/Problemillustration.png";
import stockMarket1 from "../../../assets/images/stock/stockMarket1.png";
import StockChain_Illustration1 from "../../../assets/images/stock/StockChain_Illustration1.png";
const StockSection1 = () => {
  let url = "https://infini8ai-images.s3.amazonaws.com/stock";
  return (
    <div id="stockhero-part">
      <div id="bg-case-1">
        <div className="container">
          <div className="StockChainHeader">
            <div className="row" style={{ alignItems: "center", justifyContent:"space-between",  }}>
              <div className="col-md-4 m-0 p-0">
                <div className="hero-images">,
                  <img
                    src={StockChain1CWhiteLogomark}
                    class="rounded StockLogo1 float-left"
                    alt="logo"
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="hero-Content text-center">
                  <h3>UX/UI Case Study</h3>
                </div>
              </div>
              <div className="col-md-4 m-0 p-0">
                <div className="hero-images">
                  <img
                    src={`${url}/StockChainIllustration.svg`}
                    class="rounded illustrationHealth"
                    alt="logo"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="StockChainHero">
            <div className="row" style={{ alignItems: "center", justifyContent:"center", marginLeft:"20px" }}>
              <div className="col-md-5">
                <div className="StockChainHeroText1">
                  <h3>StockChain</h3>
                  <p>
                    To eliminate the invisible layers in Logistics through our
                    technology platforms
                  </p>
                </div>
                <div className="StockChainHeroText2">
                  <p>
                    Connecting people and simplify logistics in Pakistan,
                    delivering goods with responsibility.
                  </p>
                  <p>
                    StockChain is a third party logistics reaching 50
                    destinations across Pakistan.
                  </p>
                </div>
              </div>
              <div className="col-md-5">
                <div
                  className="StockChainHeroImg "
                  style={{ marginTop: "-95px" }}
                >
                  <img
                    src={illustrationLogistics}
                    className="illustrationLogistics "
                    alt="illustrationLogistics"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="StockProblemStatement">
        <div className="container">
          <div className="row " style={{ alignItems: "center" }}>
            <div className="col-md-6">
              <div className="ProblemStatementCard">
                <div class="card">
                  <h5 className="card-title">Problem Statement</h5>

                  <div className="card-body">
                    <p className="card-text">
                      The whole supply chain and logistics process has many
                      loopholes which include theft, mishandling and misplacing
                      of shipments.
                    </p>
                    <p className="card-text">
                      There is no tracking system in logistics sector, which
                      results in severe financial loss.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="ProblemStatementImg">
                <img
                  src={Problemillustration}
                  className="Problemillustration"
                  alt="Problemillustration"
                />
              </div>
            </div>
          </div>
          <div className="row pt-5" style={{ alignItems: "center" }}>
            <div className="col-md-6">
              <div className="ProblemStatementImg">
                <img
                  src={StockChain_Illustration1}
                  className="StockChain_Illustration1"
                  alt="StockChain_Illustration1"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="ProblemStatementCard">
                <div class="card">
                  <h5 className="card-title">Solution</h5>

                  <div className="card-body">
                    <p className="card-text">
                      “StockChain” is an advance logistic solution that gives
                      you complete transparency in supply chain and cargo
                      mobility.
                    </p>
                    <p className="card-text">
                      From booking to delivery, you can track every segment of
                      the whole process.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row pt-5" style={{ alignItems: "center" }}>
            <div className="col-md-6">
              <div className="ProblemStatementCard">
                <div class="card">
                  <h5 className="card-title">Business Opportunity</h5>

                  <div className="card-body">
                    <p className="card-text">
                      Pakistan’s logistics market has reached $34.2 billion with
                      annual growth of 18 percent.
                    </p>
                    <p className="card-text">
                      This is a huge market which is untapped and we feel that
                      we have a first mover advantage to capture this amazing
                      market size
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="ProblemStatementImg">
                <img
                  src={stockMarket1}
                  className="stockMarket1"
                  alt="stockMarket1"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StockSection1;
