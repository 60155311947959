import Dashboard from "./user portal/dashboard";
import Icon from "@mui/material/Icon";
import WidgetsIcon from "@mui/icons-material/Widgets";
import BadgeIcon from "@mui/icons-material/Badge";
import Diversity2Icon from "@mui/icons-material/Diversity2";
import AccessTimeFilledRoundedIcon from "@mui/icons-material/AccessTimeFilledRounded";
import VideoCallIcon from "@mui/icons-material/VideoCall";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import SpeakerNotesIcon from "@mui/icons-material/SpeakerNotes";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import SignIn from "./user portal/authentication/sign-in";
import SignUp from "./user portal/authentication/sign-up";
import OurProcess from "./components/our process/OurProcess";
import LandingPage from "./components/home page/LandingPage";
import Portfolio from "./components/portfolio/Portfolio";
import Careers from "./components/careers";
import CareerDetails from "./components/careers/careerDetails";
import ApplyNow from "./components/careers/applyNow";
import Naseem from "./components/case studies/naseem/Naseem";
import Optimus from "./components/case studies/optimus/Optimus";
import StockChain from "./components/case studies/stock chain/StockChain";
import BusinessPal from "./components/case studies/businesspal/BusinessPal";
import Teams from "./user portal/team/Team";
import Qurbani from "./components/case studies/qurbani/Qurbani";
import Team from "./components/Team/Team";
import Profile from "./user portal/profile/Profile";
import Attendance from "./user portal/attendance/Attendance";
import Projects from "./user portal/project/Project";
import Task from "./user portal/task/Task";
import AdminTask from "./admin portal/task/Task";
import Meeting from "./user portal/meeting/Meeting";
import Chat from "./user portal/chat/Chat";
import Calendar from "./user portal/calender/AttendanceReport";
import UserVerification from "./admin portal/user verification/UserVerification";
import Employees from "./admin portal/employees/Employees";
import UserProfile from "./admin portal/employees/Profile/Profile";
import UserTask from "./admin portal/employees/taskreport/TaskReport";
import AnnualLeaves from "./admin portal/employees/annaul leaves/AnnualLeaves";
import AttendanceReview from "./admin portal/employees/calender/AttendanceReport";
import AdminAttendance from "./admin portal/attendance";
import AdminTeam from "./admin portal/team/Team";
import AdminProject from "./admin portal/project/Project";
import AdminMeeting from "./admin portal/meeting/Meeting";
import AdminCalendar from "./admin portal/calender/AttendanceReport";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import Roadmap from "./user portal/productmap";
import QueryBuilderSharpIcon from "@mui/icons-material/QueryBuilderSharp";
import TimeTracking from "./user portal/time tracking";
import Tracking from "./admin portal/employees/Tracking";
import AddTracking from "./admin portal/time tracking";
import Home from "./user portal/Home/Home";
import HomeIcon from "@mui/icons-material/Home";
const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <WidgetsIcon fontSize="small">dashboard</WidgetsIcon>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Home",
    key: "Home",
    icon: <HomeIcon fontSize="small">dashboard</HomeIcon>,
    route: "/home",
    component: <Home />,
  },
  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    icon: <BadgeIcon fontSize="small">dashboard</BadgeIcon>,
    route: "/profile",
    component: <Profile />,
  },
  {
    type: "collapse",
    name: "Teams",
    key: "teams",
    icon: <Diversity2Icon fontSize="small">dashboard</Diversity2Icon>,
    route: "/teams",
    component: <Teams />,
  },
  {
    type: "collapse",
    name: "Projects",
    key: "project",
    icon: <ReceiptLongIcon fontSize="small">dashboard</ReceiptLongIcon>,
    route: "/project",
    component: <Projects />,
  },
  {
    type: "collapse",
    name: "My Tasks",
    key: "task",
    icon: (
      <AccessTimeFilledRoundedIcon fontSize="small">
        dashboard
      </AccessTimeFilledRoundedIcon>
    ),
    route: "/task",
    component: <Task />,
  },
  {
    type: "collapse",
    name: "Meetings",
    key: "meeting",
    icon: <VideoCallIcon fontSize="small">dashboard</VideoCallIcon>,
    route: "/meeting",
    component: <Meeting />,
  },
  {
    type: "collapse",
    name: "Attendance",
    key: "attendance",
    icon: <VerifiedUserIcon fontSize="small">dashboard</VerifiedUserIcon>,
    route: "/attendance",
    component: <Attendance />,
  },
  // {
  //   type: "collapse",
  //   name: "Chat",
  //   key: "chat",
  //   icon: <SpeakerNotesIcon fontSize="small">dashboard</SpeakerNotesIcon>,
  //   route: "/chat",
  //   component: <Chat />,
  // },
  {
    type: "collapse",
    name: "Calender",
    key: "calender",
    icon: <CalendarMonthIcon fontSize="small">dashboard</CalendarMonthIcon>,
    route: "/calender",
    component: <Calendar />,
  },
  // {
  //   type: "collapse",
  //   name: "Product Roadmap",
  //   key: "product-roadmap",
  //   icon: <CalendarMonthIcon fontSize="small">dashboard</CalendarMonthIcon>,
  //   route: "/product-roadmap",
  //   component: <Roadmap />,
  // },
  {
    type: "collapse",
    name: "Time Tracking",
    key: "time-tracking",
    icon: (
      <QueryBuilderSharpIcon fontSize="small">dashboard</QueryBuilderSharpIcon>
    ),
    route: "/time-tracking",
    component: <TimeTracking />,
  },
  {
    name: "Login",
    key: "login",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/login",
    component: <SignIn />,
  },
  {
    name: "Sign Up",
    key: "sign-up",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/authentication/sign-up",
    component: <SignUp />,
  },
  {
    name: "Our Process",
    key: "our-process",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/our-process",
    component: <OurProcess />,
  },
  {
    name: "Home",
    key: "home",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/",
    component: <LandingPage />,
  },
  {
    name: "Team",
    key: "team",
    route: "/team",
    component: <Team />,
  },
  {
    name: "Portfolio",
    key: "portfolio",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/portfolio",
    component: <Portfolio />,
  },
  {
    name: "Careers",
    key: "careers",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/careers",
    component: <Careers />,
  },
  {
    name: "Career Details",
    key: "careersdetails",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/careers-details",
    component: <CareerDetails />,
  },
  {
    name: "Apply Now",
    key: "apply now",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/apply-now",
    component: <ApplyNow />,
  },
  {
    name: "Naseem",
    key: "naseem",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/naseem-case-study",
    component: <Naseem />,
  },
  {
    name: "Optimus",
    key: "optimus",
    route: "/optimus-case-study",
    component: <Optimus />,
  },
  {
    name: "Stock Chain",
    key: "stockChain",
    route: "/stock-chain-case-study",
    component: <StockChain />,
  },
  {
    name: "Business Pal",
    key: "BusinessPal",
    route: "/businesspal-case-study",
    component: <BusinessPal />,
  },
  {
    name: "Qurbani",
    key: "qurbani",
    route: "/qurbani-case-study",
    component: <Qurbani />,
  },
];

export const adminRoutes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <WidgetsIcon fontSize="small">dashboard</WidgetsIcon>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    icon: <BadgeIcon fontSize="small">dashboard</BadgeIcon>,
    route: "/profile",
    component: <Profile />,
  },
  {
    type: "collapse",
    name: "Teams",
    key: "teams",
    icon: <Diversity2Icon fontSize="small">Teams</Diversity2Icon>,
    route: "/teams",
    component: <AdminTeam />,
  },
  {
    type: "collapse",
    name: "Projects",
    key: "project",
    icon: <ReceiptLongIcon fontSize="small">dashboard</ReceiptLongIcon>,
    route: "/project",
    component: <AdminProject />,
  },
  {
    type: "collapse",
    name: "Tasks",
    key: "task",
    icon: (
      <AccessTimeFilledRoundedIcon fontSize="small">
        dashboard
      </AccessTimeFilledRoundedIcon>
    ),
    route: "/task",
    component: <AdminTask />,
  },
  {
    type: "collapse",
    name: "Meetings",
    key: "meeting",
    icon: <VideoCallIcon fontSize="small">dashboard</VideoCallIcon>,
    route: "/meeting",
    component: <AdminMeeting />,
  },
  {
    type: "collapse",
    name: "Attendance",
    key: "emp-attendance",
    icon: (
      <VerifiedUserIcon fontSize="small">Admin Attendance</VerifiedUserIcon>
    ),
    route: "/emp-attendance",
    component: <AdminAttendance />,
  },
  // {
  //   type: "collapse",
  //   name: "Chat",
  //   key: "chat",
  //   icon: <SpeakerNotesIcon fontSize="small">dashboard</SpeakerNotesIcon>,
  //   route: "/chat",
  //   component: <Chat />,
  // },
  {
    type: "collapse",
    name: "Calender",
    key: "calender",
    icon: <CalendarMonthIcon fontSize="small">dashboard</CalendarMonthIcon>,
    route: "/calender",
    component: <AdminCalendar />,
  },
  {
    type: "collapse",
    name: "User Verification",
    key: "user-verification",
    icon: (
      <AdminPanelSettingsIcon fontSize="small">
        dashboard
      </AdminPanelSettingsIcon>
    ),
    route: "/user-verification",
    component: <UserVerification />,
  },
  {
    type: "collapse",
    name: "Team Members",
    key: "employees",
    icon: <AssignmentIndIcon fontSize="small">dashboard</AssignmentIndIcon>,
    route: "/employees",
    component: <Employees />,
  },
  {
    type: "collapse",
    name: "Time Tracking",
    key: "add-tracking",
    icon: (
      <QueryBuilderSharpIcon fontSize="small">dashboard</QueryBuilderSharpIcon>
    ),
    route: "/add-tracking",
    component: <AddTracking />,
  },
  {
    name: "View Profile",
    key: "user-profile",
    icon: <Icon fontSize="small">View Profile</Icon>,
    route: "/user-profile",
    component: <UserProfile />,
  },

  {
    name: "Task Report",
    key: "user-task",
    icon: <Icon fontSize="small">Task Report</Icon>,
    route: "/user-task",
    component: <UserTask />,
  },
  {
    name: "Annual Leaves",
    key: "admin-leaves",
    icon: <Icon fontSize="small">Annual Leaves</Icon>,
    route: "/admin-leaves",
    component: <AnnualLeaves />,
  },
  {
    name: "Attendance Review",
    key: "admin-attendance",
    icon: <Icon fontSize="small">Attendance Review</Icon>,
    route: "/admin-attendance",
    component: <AttendanceReview />,
  },
  {
    name: "Login",
    key: "login",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/login",
    component: <SignIn />,
  },
  {
    name: "Sign Up",
    key: "sign-up",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/authentication/sign-up",
    component: <SignUp />,
  },
  {
    name: "Our Process",
    key: "our-process",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/our-process",
    component: <OurProcess />,
  },
  {
    name: "Home",
    key: "home",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/",
    component: <LandingPage />,
  },
  {
    name: "Team",
    key: "team",
    route: "/team",
    component: <Team />,
  },
  {
    name: "Portfolio",
    key: "portfolio",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/portfolio",
    component: <Portfolio />,
  },
  {
    name: "Careers",
    key: "careers",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/careers",
    component: <Careers />,
  },
  {
    name: "Career Details",
    key: "careersdetails",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/careers-details",
    component: <CareerDetails />,
  },
  {
    name: "Apply Now",
    key: "apply now",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/apply-now",
    component: <ApplyNow />,
  },
  {
    name: "Naseem",
    key: "naseem",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/naseem-case-study",
    component: <Naseem />,
  },
  {
    name: "Optimus",
    key: "optimus",
    route: "/optimus-case-study",
    component: <Optimus />,
  },
  {
    name: "Stock Chain",
    key: "stockChain",
    route: "/stock-chain-case-study",
    component: <StockChain />,
  },
  {
    name: "Qurbani",
    key: "qurbani",
    route: "/qurbani-case-study",
    component: <Qurbani />,
  },
  {
    type: "collapse",
    name: "Product Roadmap",
    key: "product-roadmap",
    icon: <CalendarMonthIcon fontSize="small">dashboard</CalendarMonthIcon>,
    route: "/product-roadmap",
    component: <Roadmap />,
  },
  {
    name: "Time Tracking",
    key: "time-tracking",
    route: "/time-tracking",
    component: <Tracking />,
  },
];
export default routes;
