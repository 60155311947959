import React, { useLayoutEffect, useState } from "react";
import "@fullcalendar/react/dist/vdom";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import { Stack, styled } from "@mui/system";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import { Button, Grid, Menu, MenuItem } from "@mui/material";
import Logo from "../../assets/images/calender/calender.png";
import AttendanceModel from "./AttendancePopup";
import LeaveModel from "./LeaveModel";
import DashboardLayout from "../../layouts/Dashboard";
import DashboardNavbar from "../../layouts/Navbars/DashboardNavbar";
import { getLeavesById, getLeavesCountById } from "../../apiservices/calendar";
import moment from "moment";
import DialogComponent from "../reuseable components/Dialog";
import { Add, Info } from "../../assets/Svg/svg";
import { TextStyle, Title } from "../dashboard/style";
export default function AttendanceReport() {
  const [state, setState] = useState({
    applyLeaves: false,
    leaveModal: false,
    events: [],
    rowEvent: [],
    eventStartDate: new Date(),
    eventEndDate: "",
    leavesCount: 0,
  });
  const ViewMemberButton = styled(Button)({
    fontFamily: "Dosis",
    fontStyle: "normal",
    width: "150px",
    borderRadius: "4px",

    padding: " 4px 8px 4px 8px",
    border: "1px solid #67748E",
    color: "#67748E !important",
    fontSize: "14px",
    textTransform: "capitalize",
    "&:hover": {
      background: "#67748E",
      color: "#FFFFFF !important",
    },
    "&:active": {
      // background: "#586278",
      color: "#FFFFFF !important",
    },
  });
  const CheckLeaves = styled(Menu)({
    borderRadius: "4px",
    // height: "200px",
  });
  const Body = styled(Stack)({
    background: "#FFFFFF",
    padding: "10px",
    // margin: "20px",
    borderRadius: "10px",
  });
  useLayoutEffect(() => {
    getLeaves();
    getLeavesCount();
  }, []);
  const getLeavesCount = async () => {
    let data = {
      applicantId: localStorage.getItem("userEmail"),
    };
    let res = await getLeavesCountById(data);
    setState((prev) => ({ ...prev, leavesCount: res.leaveCounts }));
  };
  const getLeaves = async () => {
    let data = {
      applicantId: localStorage.getItem("userId"),
    };
    let res = await getLeavesById(data);
    let dataArr = [];
    res.leavesArr.map((item) => {
      let date = moment(item.startDate, "DD-MM-YYYY").format("YYYY-MM-DD");
      let dateEnd = moment(item?.endDate, "DD-MM-YYYY").format("YYYY-MM-DD");
      let createdAt = moment.unix(item.created_at).format("DD-MM-YYYY");
      return dataArr.push({
        title: item.title,
        start: date,
        end: dateEnd,
        startT: item?.startTime,
        endT: item?.endTime,
        attachment: item.attachment,
        backgroundColor: "rgba(202, 41, 41, 0.12)",
        textColor: "#CA2929",
        borderColor: "#FFFFFF",
        description: item.description,
        applicantName: item.applicantName,
        status: item.status,
        leaveDate: date,
        leaveEndDate: dateEnd,
        id: item._id,
        rev: item._rev,
        created: createdAt,
      });
    });
    setState((prev) => ({ ...prev, events: dataArr }));
  };
  const firstDaty = 1;
  const applyModel = (name, info = "") => {
    setState((prev) => ({
      ...prev,
      [name]: !prev[name],
      rowEvent: info.event?._def,
    }));
  };
  return (
    <DashboardLayout>
      <DashboardNavbar>
        <Stack
          sx={{ height: "100px", mx: 1 }}
          alignItems={"center"}
          direction={"row"}
          justifyContent={"space-between"}>
          <Stack>
            <img height="50px" widt="50px" src={Logo} alt="img" />
          </Stack>
          <Stack>
            <Title variant="h6" sx={{ fontWeight: "bold" }}>
              Calender
            </Title>
          </Stack>

          <Stack direction={"row"}>
            <PopupState variant="popover" popupId="demo-popup-menu">
              {(popupState) => (
                <>
                  <ViewMemberButton {...bindTrigger(popupState)} size="small">
                    <Info />
                    &nbsp; Check Leaves
                  </ViewMemberButton>
                  <CheckLeaves {...bindMenu(popupState)}>
                    <TextStyle sx={{ m: 2, fontSize: 18, fontWeight: "bold" }}>
                      Annual Leaves
                    </TextStyle>
                    <Stack direction="row">
                      <Stack
                        alignItems="center"
                        sx={{ mx: 2, color: "#B28C04" }}>
                        <TextStyle>Availed</TextStyle>
                        <TextStyle sx={{ fontWeight: "bold" }}>
                          {state.leavesCount}
                        </TextStyle>
                      </Stack>
                      <Stack>
                        <TextStyle
                          type="title"
                          color="inherit"
                          sx={{
                            borderRight: "3px solid #04B2AE",
                            height: "2em",
                            color: "#04B2AE",
                          }}></TextStyle>
                      </Stack>
                      <Stack
                        alignItems="center"
                        sx={{ mx: 2, color: "#04B2AE" }}>
                        <TextStyle>Total</TextStyle>
                        <TextStyle sx={{ fontWeight: "bold" }}>20</TextStyle>
                      </Stack>
                    </Stack>
                  </CheckLeaves>
                </>
              )}
            </PopupState>

            <PopupState variant="popover" popupId="demo-popup-men">
              {(popupState) => (
                <>
                  <ViewMemberButton
                    {...bindTrigger(popupState)}
                    sx={{ mx: 1 }}
                    size="small">
                    <Add />
                    &nbsp; Create Leave
                  </ViewMemberButton>
                  <CheckLeaves
                    {...bindMenu(popupState)}
                    onClick={() => popupState.close()}>
                    <MenuItem onClick={() => applyModel("applyLeaves")}>
                      Leave application
                    </MenuItem>
                  </CheckLeaves>
                </>
              )}
            </PopupState>
          </Stack>
        </Stack>
      </DashboardNavbar>
      <Body sx={{ mt: 5 }}>
        <FullCalendar
          firstDay={firstDaty}
          locale="en"
          height={650}
          plugins={[dayGridPlugin]}
          events={state.events}
          eventClick={(info) => applyModel("leaveModal", info)}
        />
      </Body>
      <DialogComponent
        title="Item History"
        open={state.applyLeaves}
        onClose={(apply) => applyModel(apply)}
        onClickCancel={(apply) => applyModel(apply)}
        // dialogTitle={"Summary"}
        dialogContent={
          <>
            <AttendanceModel
              getMeetings={(apply) => applyModel(apply)}
              closePop={(apply) => applyModel(apply)}
              getLeaves={getLeaves}
              getLeavesCount={getLeavesCount}
            />
          </>
        }
      />

      {state.leaveModal && (
        <LeaveModel
          popup={state.leaveModal}
          closeTaskPopup={(leave) => applyModel(leave)}
          events={state.rowEvent}
          eventStartDate={state.eventStartDate}
          eventEndDate={state.eventEndDate}
        />
      )}
    </DashboardLayout>
  );
}
