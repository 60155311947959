import React from "react";
import StockSection1 from "./StockSection1";
import StockSection2 from "./StockSection2";
import StockSection3 from "./StockSection3";
import StockSection4 from "./StockSection4";
import NavbarMenu from "../../common/NavbarMenu";
import useWindowSize from "../../utils/useWindowSize";
import StockChainmobile from "./StockChainmobile";
import PageLayout from "../../../layouts/Page";

const StockChain = () => {
  const size = useWindowSize();

  return (
    <PageLayout>
      <NavbarMenu />
      {size.width < 768 ? (
        <StockChainmobile />
      ) : (
        <>
          <StockSection1 />
          <StockSection2 />
          <StockSection3 />
          <StockSection4 />
        </>
      )}
    </PageLayout>
  );
};

export default StockChain;
