import Repo from "../../../Repository/Repo";
import Toaster from "../../../components/Toaster";

export const saveTimeTrack = async ({ clock, time, id }) => {
  try {
    let info = {
      company_code: localStorage.getItem("companyCode"),
      employeeId: localStorage.getItem("userEmail"),
      employeeName: localStorage.getItem("userName"),
      [clock]: time,
    };

    if (clock == "clockOut" && id) {
      info._id = id;
    }

    let { data } = await Repo.time_track({
      request: {
        method: "saveEmployeeTimeTrack",
        data: info,
      },
    });
    return data;
  } catch (err) {
    console.log("err:", err);
    Toaster("error", "Error");
  }
};
