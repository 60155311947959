import React from "react";
import "./attendance.css";
import File from "../../assets/images/attendance/file.png";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { Stack, styled } from "@mui/system";
import { Box, Button, CircularProgress, Grid, Typo } from "@mui/material";
import AttendanceSumary from "./AttendanceSumary";
import DashboardLayout from "../../layouts/Dashboard";
import DashboardNavbar from "../../layouts/Navbars/DashboardNavbar";
import NoAttendance from "../../assets/images/noattendance.svg";
import moment from "moment";
import { Info } from "../../assets/Svg/svg";
import { Title } from "../dashboard/style";

const Attendance = () => {
  const SummaryButton = styled(Button)({
    width: "120px",
    height: "35px !important",
    borderRadius: "4px",
    color: "#67748E",
    textTransform: "capitalize",
    border: "1px solid #67748E",
    fontFamily: "Dosis",
    fontStyle: "normal",
    padding: "10px",
    "&:hover": {
      background: "#67748E",
      color: "#FFFFFF",
    },
    "&:active": {
      background: "#586278",
      color: "#FFFFFF",
    },
  });
  const RowStyle = styled(Stack)({
    height: "60px",
    alignItems: "center",
    paddingLeft: "10px",
    paddingRight: "10px",
    borderRadius: "8px",
    marginBottom: "15px",
    background: "#FFFFFF",
    color: "#67748E",
    borderLeft: "2px solid",
    borderRight: "2px solid",
    borderColor: "#04B2AE",
  });
  const Typo = styled(Grid)({
    fontSize: "17px",
  });
  const currentMonthYear = moment().format("MM-YYYY");
  const updatedDate = moment().format("YYYY-MM");
  const [openPopup, setOpenPopup] = useState(false);
  const [attendanceData, setAttendanceData] = useState([]);
  const [filterMonth, setfilterMonth] = useState(updatedDate);
  const [loader, setLoader] = useState(false);

  const attendSumaryPopup = () => {
    setOpenPopup(true);
  };
  const closeSumaryPopup = () => {
    setOpenPopup(false);
  };
  const handleChange = async (e) => {
    setfilterMonth(e.target.value);
    const keyword = e.target.value;
    var updatedDate = keyword;
    updatedDate = updatedDate.split("-").reverse().join("-");
    getAllAttendance(updatedDate);
  };
  useEffect(() => {
    getAllAttendance(currentMonthYear);
  }, []);
  const getAllAttendance = async (date) => {
    setLoader(true);
    let response = await axios.post(
      `https://infini8ai.herokuapp.com/attendance`,
      {
        request: {
          method: "getEmployeeAttendance",
          data: {
            employeeId: localStorage.getItem("userId"),
            month: date,
          },
        },
      }
    );
    setLoader(false);
    setAttendanceData(response.data.response.data.attendanceArr);
  };
  return (
    <DashboardLayout>
      <DashboardNavbar>
        <Stack
          sx={{ height: "100px", mx: 1 }}
          alignItems={"center"}
          direction={"row"}
          justifyContent={"space-between"}>
          <Stack>
            <img src={File} alt="img" />
          </Stack>
          <Stack>
            <Title variant="h6" sx={{ fontWeight: "bold" }}>
              Attendance
            </Title>
          </Stack>
          <Stack direction={"row"} spacing={1}>
            <input
              type="month"
              id="att_date_input"
              value={filterMonth}
              style={{ border: "1px solid", width: "172px" }}
              onChange={(e) => handleChange(e)}
            />
            <SummaryButton size="small" onClick={attendSumaryPopup}>
              <Info />
              &nbsp; Summary
            </SummaryButton>
          </Stack>{" "}
        </Stack>
      </DashboardNavbar>
      {attendanceData && attendanceData.length !== 0 ? (
        attendanceData &&
        attendanceData.map((item, index) => (
          <RowStyle
            direction="row"
            justifyContent="space-between"
            sx={{ mt: 5 }}>
            <Typo>{index + 1}</Typo>
            <Typo sx={{ width: "80px" }}>{item.day}</Typo>
            <Typo>
              Marked | {item.date} | {item.time}
            </Typo>
            <Stack alignItems="center">
              <Typo>Present</Typo>
              <p
                className={`${
                  item.status === 1 ? "present_check" : "present_check bg-white"
                } fw-bold fs-6`}></p>
            </Stack>
            <Stack alignItems="center">
              <Typo>Absent</Typo>
              <p
                className={`${
                  item.status === 2 ? "absent_check" : "absent_check bg-white"
                } fw-bold fs-6`}></p>
            </Stack>
            <Stack alignItems="center">
              <Typo>Leave</Typo>
              <p
                className={`${
                  item.status === 3 ? "leave_check" : "leave_check bg-white"
                } fw-bold fs-6`}></p>
            </Stack>
            <Stack alignItems="center">
              <Typo>Half Leave</Typo>
              <p
                className={`${
                  item.status === 4
                    ? "half_leave_check"
                    : "leave_check bg-white"
                } fw-bold fs-6`}></p>
            </Stack>
            <Stack alignItems="center">
              <Typo>WFH</Typo>
              <p
                className={`${
                  item.status === 5 ? "wfh_check" : "leave_check bg-white"
                } fw-bold fs-6`}></p>
            </Stack>
          </RowStyle>
        ))
      ) : (
        <Grid
          item
          xs={12}
          sx={{ display: "flex", justifyContent: "center", mt: 5 }}>
          {loader ? (
            <Box sx={{ display: "flex" }}>
              <CircularProgress color="success" />
            </Box>
          ) : (
            <img src={NoAttendance} alt="" height="350px" width="400px" />
          )}
        </Grid>
      )}
      {openPopup && (
        <AttendanceSumary
          openPopup={openPopup}
          closeSumaryPopup={closeSumaryPopup}
        />
      )}
    </DashboardLayout>
  );
};

export default Attendance;
