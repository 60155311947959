import { Box, Modal, styled, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import InventoryOutlinedIcon from "@mui/icons-material/InventoryOutlined";
import HowToRegOutlinedIcon from "@mui/icons-material/HowToRegOutlined";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import { Stack } from "@mui/system";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

const CardModel = ({ onClose, user }) => {
  console.log("user:", user);
  let navigate = useNavigate();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: "#FFFFFF",
    boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.2)",
    borderRadius: "12px",
    p: 4,
  };
  const StyledButton = styled(Box)({
    height: "36px",
    // width: "280px",
    borderRadius: "4px",
    padding: "6px 51px 6px 10px",
    textTransform: "capitalize",
    border: "1px solid #67748E",
    color: "#67748E",
    margin: "10px",
    "&:hover": {
      border: "1px solid #018786",
      color: "#018786",
    },
    "&:active": {
      background: "#018786",
      color: "#FFFFFF",
    },
  });
  const arr = [
    {
      name: "View Profile",
      url: "/user-profile",
      icon: AccountCircleOutlinedIcon,
    },
    {
      name: "Task report",
      url: "/user-task",
      icon: InventoryOutlinedIcon,
    },
    {
      name: "Attendance review",
      url: "/admin-attendance",
      icon: ArticleOutlinedIcon,
    },
    { name: "Annual leaves", url: "/admin-leaves", icon: HowToRegOutlinedIcon },
    {
      name: "Time Tracking",
      url: "/time-tracking",
      icon: AccessTimeIcon,
    },
  ];
  const direction = (url, name) => {
    navigate(`${url}`, {
      state: { empName: name, empObj: user },
    });
  };
  return (
    <Modal open={true} onClose={onClose}>
      <Box sx={style}>
        <Stack alignItems="center">
          <Typography variant="h4" sx={{ color: "#018786" }}>
            {user?.name} Details
          </Typography>
          <Stack direction="column">
            {arr.map((item, index) => (
              <StyledButton
                key={index}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  px: 5,
                }}
                onClick={() => direction(item.url, user?.name)}
              >
                <item.icon sx={{ mx: 1 }} />
                <Typography>{item.name}</Typography>
              </StyledButton>
            ))}
          </Stack>
        </Stack>
      </Box>
    </Modal>
  );
};

export default CardModel;
