import React from "react";
import { Button, Paper } from "@mui/material";
import { Table, TableBody, TableCell, TableContainer } from "@mui/material";
import { TableHead, TableRow, styled, tableCellClasses } from "@mui/material";
import { Dialog, DialogContent, Stack, Typography } from "@mui/material";
import moment from "moment";

const Title = styled(Typography)({
  fontFamily: "Dosis",
  fontWeight: 600,
  fontSize: "18px",
  color: "#353535",
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#04B2AE",
    fontSize: "16px",
    fontFamily: "Dosis",
    fontWeight: 600,
    color: "#ffffff",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "16px",
    fontFamily: "Dosis",
    fontWeight: 500,
    color: "#67748E",
    backgroundColor: "#F5F5F5",
  },
}));

function DailyDetails({ onClose, user, selected }) {
  let name = user?.timeTracksArr[0].employeeName ?? "";
  let date = user?.timeTracksArr[selected].date;

  return (
    <Dialog open={true} onClose={onClose} fullWidth maxWidth="md">
      <DialogContent>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          flexWrap="wrap"
        >
          <Stack direction="row" spacing={1}>
            <Title sx={{ color: "#04B2AE" }}>Employee Name:</Title>
            <Title>{name}</Title>
          </Stack>

          <Stack direction="row" spacing={1}>
            <Title sx={{ color: "#04B2AE" }}>Date:</Title>
            <Title>{date}</Title>
          </Stack>
        </Stack>

        <TableContainer
          sx={{ my: 1, borderRadius: "8px 8px 0 0" }}
          component={Paper}
        >
          <Table sx={{ minWidth: 500 }} aria-label="customized table">
            <TableHead sx={{ p: "0", display: "table-header-group" }}>
              <TableRow>
                <StyledTableCell>#</StyledTableCell>
                <StyledTableCell>Type</StyledTableCell>
                <StyledTableCell>Time</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {user?.timeTracksArr[selected].clockHitsory.map((row, i) => (
                <TableRow key={row.name}>
                  <StyledTableCell component="th" scope="row">
                    {i + 1}
                  </StyledTableCell>
                  <StyledTableCell>{row.action}</StyledTableCell>
                  <StyledTableCell>
                    {moment.unix(row.time).format("hh:mm:ss A")}
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Stack direction="row" alignItems="center" justifyContent="flex-end">
          <Button
            size="small"
            variant="contained"
            sx={{
              background: "#ffffff !important",
              textTransform: "none",
            }}
            onClick={onClose}
          >
            Cancel
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}

export default DailyDetails;
