import { useState } from "react";
import { useEffect } from "react";
import { Box, styled } from "@mui/system";
import {
  Button,
  CircularProgress,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import TeamLogo from "../../assets/images/team/teams.png";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import ReplyAllOutlinedIcon from "@mui/icons-material/ReplyAllOutlined";
import TeamMember from "./TeamMember";
import DashboardLayout from "../../layouts/Dashboard";
import DashboardNavbar from "../../layouts/Navbars/DashboardNavbar";
import { deleteTeam, getTeamsByCompany } from "../../apiservices/team";
import NoTeam from "../../assets/images/noteam.svg";
import { Members } from "../../assets/Svg/svg";
import AddTeam from "./AddTeam";
import Popover from "@mui/material/Popover";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import DialogComponent from "../../user portal/reuseable components/Dialog";
import { toast } from "react-toastify";

export const BackButton = styled(Button)({
  borderRadius: "4px",
  padding: "4px 8px 4px 8px",
  color: "#67748E",
  border: "1px solid #67748E",
  "&:hover": {
    background: "#67748E",
    color: "#FFFFFF",
  },
  "&:active": {
    background: "#586278",
    color: "#FFFFFF",
  },
});
const Team = () => {
  const [state, setState] = useState({
    teamData: [],
    selected: false,
    members: [],
    anchorEl: null,
    editTeam: {},
    teamName: "",
    isEdit: false,
  });
  const selectedTeam = (data) => {
    setState((prev) => ({
      ...prev,
      selected: !prev.selected,
      members: data.members,
      loader: false,
      addModal: false,
    }));
  };
  const TeamCard = styled(Box)({
    height: "100px",
    maxWidth: "450px",
    borderRadius: "8px",
    borderLeft: "2px solid transparent",
    background: "#F5F5F5",
    margin: "20px",
    transition: "0.5s",
    "&:hover": {
      background: "#FFFFFF",
      borderLeft: "3px solid #04B2AE",
      boxShadow: "0px 4px 10px 0px #EBEBEB",
    },
    "&:active": {
      background: "#F5F5F5",
      borderLeft: "0px solid #04B2AE",
      boxShadow: "0px 0px 0px 0px #EBEBEB ",
    },
  });

  const StylePop = styled(Stack)({
    background: "#FFFFFF",
    display: "flex",
    flexDirection: "column",
  });
  const DragIcon = styled(Box)({
    margin: "5px",
    borderRadius: "2px",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    color: "grey",
    justifyContent: "center",
    "&:hover": {
      background: "#67748E",
      color: "#ffffff",
    },
    "&:active": {
      background: "#67748E",
      color: "#ffffff",
    },
  });
  const Btn = styled(Button)({
    color: "#67748E",
    textTrasform: "capitalize",
    "&:hover": {
      color: "#67748E",
    },
  });
  const getAllTeam = async () => {
    setState((prev) => ({ ...prev, loader: !prev.loader }));

    let data = {
      company_code: localStorage.getItem("companyCode"),
    };
    let res = await getTeamsByCompany(data);
    if (res) {
      let response = res.teamsArr;
      setState({ teamData: response });
      setState((prev) => ({ ...prev, loader: !prev.loader }));
    }
    setState((prev) => ({ ...prev, loader: !prev.loader }));
  };
  useEffect(() => {
    getAllTeam();
  }, []);
  const handleClick = (event) => {
    setState((prev) => ({ ...prev, anchorEl: event.currentTarget }));
  };
  const open = state.anchorEl;
  const id = open ? "simple-popover" : undefined;
  const addTeam = (item = "") => {
    setState((prev) => ({
      ...prev,
      addModal: !prev.addModal,
      editTeam: item,
      isEdit: false,
    }));
  };
  const editTeam = (item = "") => {
    setState((prev) => ({
      ...prev,
      isEdit: !prev.isEdit,
      addModal: !prev.addModal,
      editTeam: item,
    }));
  };
  const removeTeam = async (item) => {
    let data = {
      _id: item._id,
    };
    await deleteTeam(data);
    toast.error("Team deleted successfully");

    getAllTeam();
  };
  return (
    <>
      <DialogComponent
        title="Item History"
        open={state.addModal}
        onClose={addTeam}
        onClickCancel={addTeam}
        // dialogTitle={"Summary"}
        dialogContent={
          <>
            <AddTeam
              editRow={state.editTeam}
              isEdit={state.isEdit}
              getAllTeam={getAllTeam}
              closeTeamPopup={addTeam}
            />
          </>
        }
      />
      <DashboardLayout>
        <DashboardNavbar>
          <Grid container spacing={2}>
            <Grid item xs={1} sx={{ mx: 1 }}>
              <img src={TeamLogo} alt="img" />
            </Grid>
            <Grid
              item
              xs={9.3}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}>
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                Teams
              </Typography>
            </Grid>
            <Grid
              item
              xs={1.5}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}>
              {state.selected ? (
                <BackButton size="small" onClick={selectedTeam}>
                  <ReplyAllOutlinedIcon />
                </BackButton>
              ) : (
                <BackButton
                  size="small"
                  onClick={addTeam}
                  sx={{ textTransform: "capitalize" }}>
                  <Members />
                  Add team
                </BackButton>
              )}
            </Grid>
          </Grid>
        </DashboardNavbar>
        {!state.selected ? (
          <Grid container sx={{ m: 3 }}>
            {state.teamData && state.teamData.length !== 0 ? (
              state.teamData.map((item) => (
                <Grid item xs={12} md={6}>
                  <TeamCard>
                    <Stack direction="row" justifyContent="space-between">
                      <Stack
                        direction="row"
                        alignItems="center"
                        onClick={() => selectedTeam(item)}>
                        <Stack justifyContent="center" sx={{ m: 1 }}>
                          <img
                            src={item.imageUrl ? item.imageUrl : TeamLogo}
                            alt="one"
                            width="80px"
                            height="80px"
                            style={{ borderRadius: 2 }}
                          />
                        </Stack>
                        <Stack sx={{ mx: 3 }}>
                          <Typography sx={{ color: "#67748E" }}>
                            {item.teamName}
                          </Typography>
                        </Stack>
                      </Stack>
                      <Stack direction="column" sx={{ alignItems: "end" }}>
                        <PopupState variant="popover" popupId="demo-popup-menu">
                          {(popupState) => (
                            <>
                              <Button
                                onClick={handleClick}
                                aria-describedby={id}
                                {...bindTrigger(popupState)}>
                                <DragIcon>
                                  <DragIndicatorIcon />
                                </DragIcon>
                              </Button>
                              <Popover {...bindMenu(popupState)}>
                                <StylePop>
                                  <Btn
                                    sx={{ p: 2 }}
                                    onClick={() => editTeam(item)}>
                                    Edit team
                                  </Btn>
                                  <Btn
                                    sx={{ p: 2 }}
                                    onClick={() => removeTeam(item)}>
                                    Remove
                                  </Btn>
                                </StylePop>
                              </Popover>
                            </>
                          )}
                        </PopupState>
                      </Stack>
                    </Stack>
                  </TeamCard>
                </Grid>
              ))
            ) : (
              <Grid
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "center" }}>
                {state.loader ? (
                  <Box sx={{ display: "flex" }}>
                    <CircularProgress color="success" />
                  </Box>
                ) : (
                  <img src={NoTeam} alt="" height="350px" width="400px" />
                )}
              </Grid>
            )}
          </Grid>
        ) : (
          <TeamMember selectedRow={state.members} />
        )}
      </DashboardLayout>
    </>
  );
};

export default Team;
