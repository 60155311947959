import React from "react";
import PageLayout from "../../layouts/Page";

function Careers() {
  return (
    <PageLayout>
      <div className="container">
        <div className="row">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div id="jobs-listings">
              <h2 class="heading heading-jobboard">Engineering/ Development</h2>
              <div class="list-group list-jobs">
                <div class="list-group-item" id="list_group_link">
                  <div class="row">
                    <div class="col-3 col-md-6 col-sm-6 col-xs-6">
                      <div class="list-group-item-title job-title">
                        <b>Position</b>
                      </div>
                    </div>

                    <div class="col-3 col-md-2 col-sm-2 col-xs-2 text-center">
                      <div className="jobApplyBtn">
                        <b>Status</b>
                      </div>
                    </div>
                    <div class="col-3 col-md-2 col-sm-2 col-xs-2 text-center">
                      <div className="jobApplyBtn">
                        <b>Openings</b>
                      </div>
                    </div>
                    <div class="col-3 col-md-2 col-sm-2 col-xs-2 text-center">
                      <div className="jobApplyBtn">
                        <b> Apply Now</b>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="list-group-item" id="list_group_link">
                  <div class="row">
                    <div class="col-3 col-md-6 col-sm-3 col-xs-3 ">
                      <div class="list-group-item-title job-title">
                        Manager IT - Cloud Systems
                      </div>
                    </div>
                    <div class="col-3 col-md-2 col-sm-3 col-xs-3 text-center">
                      <div className="jobApplyBtn">
                        <span className="text-success">Active </span>
                      </div>
                    </div>
                    <div class="col-3 col-md-2 col-sm-3 col-xs-3 text-center">
                      <div className="jobApplyBtn ">
                        <span>1</span>
                      </div>
                    </div>
                    <div class="col-3 col-md-2 col-sm-3 col-xs-3 text-center">
                      <div className="jobApplyBtn">
                        <a
                          href="/careers-details"
                          className="btn btn-outline-secondary"
                        >
                          Apply Now
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="list-group-item" id="list_group_link">
                  <div class="row">
                    <div class="col-3 col-md-6 col-sm-6 col-xs-6">
                      <div class="list-group-item-title job-title">
                        Software Architect
                      </div>
                    </div>
                    <div class="col-3 col-md-2 col-sm-2 col-xs-2 text-center">
                      <div className="jobApplyBtn">
                        <span className="text-danger">Close </span>
                      </div>
                    </div>
                    <div class="col-3 col-md-2 col-sm-2 col-xs-2 text-center">
                      <div className="jobApplyBtn ">
                        <span>2</span>
                      </div>
                    </div>
                    <div class="col-3 col-md-2 col-sm-2 col-xs-2 text-center">
                      <div className="jobApplyBtn">
                        <a
                          href="/careerdetail"
                          className="btn btn-outline-secondary"
                        >
                          Apply Now
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="list-group-item" id="list_group_link">
                  <div class="row">
                    <div class="col-3 col-md-6 col-sm-6 col-xs-6">
                      <div class="list-group-item-title job-title">
                        Software Development Manager
                      </div>
                    </div>
                    <div class="col-3 col-md-2 col-sm-2 col-xs-2 text-center">
                      <div className="jobApplyBtn">
                        <span className="text-success">Active </span>
                      </div>
                    </div>
                    <div class="col-3 col-md-2 col-sm-2 col-xs-2 text-center">
                      <div className="jobApplyBtn ">
                        <span>1</span>
                      </div>
                    </div>
                    <div class="col-3 col-md-2 col-sm-2 col-xs-2 text-center">
                      <div className="jobApplyBtn">
                        <a
                          href="/careerdetail"
                          className="btn btn-outline-secondary"
                        >
                          Apply Now
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="list-group-item" id="list_group_link">
                  <div class="row">
                    <div class="col-3 col-md-6 col-sm-6 col-xs-6">
                      <div class="list-group-item-title job-title">
                        Software Engineer - C Sharp
                      </div>
                    </div>
                    <div class="col-3 col-md-2 col-sm-2 col-xs-2 text-center">
                      <div className="jobApplyBtn">
                        <span className="text-danger">Close </span>
                      </div>
                    </div>
                    <div class="col-3 col-md-2 col-sm-2 col-xs-2 text-center">
                      <div className="jobApplyBtn ">
                        <span>1</span>
                      </div>
                    </div>
                    <div class="col-3 col-md-2 col-sm-2 col-xs-2 text-center">
                      <div className="jobApplyBtn">
                        <a
                          href="/careerdetail"
                          className="btn btn-outline-secondary"
                        >
                          Apply Now
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="list-group-item" id="list_group_link">
                  <div class="row">
                    <div class="col-3 col-md-6 col-sm-6 col-xs-6">
                      <div class="list-group-item-title job-title">
                        Sr. Software Engineer
                      </div>
                    </div>

                    <div class="col-3 col-md-2 col-sm-2 col-xs-2 text-center">
                      <div className="jobApplyBtn">
                        <span className="text-success">Active </span>
                      </div>
                    </div>
                    <div class="col-3 col-md-2 col-sm-2 col-xs-2 text-center">
                      <div className="jobApplyBtn ">
                        <span>3</span>
                      </div>
                    </div>
                    <div class="col-3 col-md-2 col-sm-2 col-xs-2 text-center">
                      <div className="jobApplyBtn">
                        <a
                          href="/careerdetail"
                          className="btn btn-outline-secondary"
                        >
                          Apply Now
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="list-group-item" id="list_group_link">
                  <div class="row">
                    <div class="col-3 col-md-6 col-sm-6 col-xs-6">
                      <div class="list-group-item-title job-title">
                        WordPress Developer
                      </div>
                    </div>
                    <div class="col-3 col-md-2 col-sm-2 col-xs-2 text-center">
                      <div className="jobApplyBtn">
                        <span className="text-danger">Close </span>
                      </div>
                    </div>
                    <div class="col-3 col-md-2 col-sm-2 col-xs-2 text-center">
                      <div className="jobApplyBtn ">
                        <span>1</span>
                      </div>
                    </div>
                    <div class="col-3 col-md-2 col-sm-2 col-xs-2 text-center">
                      <div className="jobApplyBtn">
                        <a
                          href="/careerdetail"
                          className="btn btn-outline-secondary"
                        >
                          Apply Now
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <h2 class="heading heading-jobboard">
                HR &amp; Operations/ General
              </h2>
              <div class="list-group list-jobs">
                <div class="list-group-item" id="list_group_link">
                  <div class="row">
                    <div class="col-3 col-md-6 col-sm-6 col-xs-6">
                      <div class="list-group-item-title job-title">
                        Finance Internship
                      </div>
                    </div>
                    <div class="col-3 col-md-2 col-sm-2 col-xs-2 text-center">
                      <div className="jobApplyBtn">
                        <span className="text-success">Active </span>
                      </div>
                    </div>
                    <div class="col-3 col-md-2 col-sm-2 col-xs-2 text-center">
                      <div className="jobApplyBtn ">
                        <span>1</span>
                      </div>
                    </div>
                    <div class="col-3 col-md-2 col-sm-2 col-xs-2 text-center">
                      <div className="jobApplyBtn">
                        <a
                          href="/careerdetail"
                          className="btn btn-outline-secondary"
                        >
                          Apply Now
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <h2 class="heading heading-jobboard">Marketing &amp; Content</h2>
              <div class="list-group list-jobs">
                <div class="list-group-item" id="list_group_link">
                  <div class="row">
                    <div class="col-3 col-md-6 col-sm-6 col-xs-6">
                      <div class="list-group-item-title job-title">
                        Content Editor
                      </div>
                    </div>
                    <div class="col-3 col-md-2 col-sm-2 col-xs-2 text-center">
                      <div className="jobApplyBtn">
                        <span className="text-danger">Close </span>
                      </div>
                    </div>
                    <div class="col-3 col-md-2 col-sm-2 col-xs-2 text-center">
                      <div className="jobApplyBtn ">
                        <span>2</span>
                      </div>
                    </div>
                    <div class="col-3 col-md-2 col-sm-2 col-xs-2 text-center">
                      <div className="jobApplyBtn">
                        <a
                          href="/careerdetail"
                          className="btn btn-outline-secondary"
                        >
                          Apply Now
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="list-group-item" id="list_group_link">
                  <div class="row">
                    <div class="col-3 col-md-6 col-sm-6 col-xs-6">
                      <div class="list-group-item-title job-title">
                        Graphic Designer
                      </div>
                    </div>
                    <div class="col-3 col-md-2 col-sm-2 col-xs-2 text-center">
                      <div className="jobApplyBtn">
                        <span className="text-success">Active </span>
                      </div>
                    </div>
                    <div class="col-3 col-md-2 col-sm-2 col-xs-2 text-center">
                      <div className="jobApplyBtn ">
                        <span>4</span>
                      </div>
                    </div>
                    <div class="col-3 col-md-2 col-sm-2 col-xs-2 text-center">
                      <div className="jobApplyBtn">
                        <a
                          href="/careerdetail"
                          className="btn btn-outline-secondary"
                        >
                          Apply Now
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="list-group-item" id="list_group_link">
                  <div class="row">
                    <div class="col-3 col-md-6 col-sm-6 col-xs-6">
                      <div class="list-group-item-title job-title">
                        Digital Marketing Associate/Manager
                      </div>
                    </div>
                    <div class="col-3 col-md-2 col-sm-2 col-xs-2 text-center">
                      <div className="jobApplyBtn">
                        <span className="text-success">Active </span>
                      </div>
                    </div>
                    <div class="col-3 col-md-2 col-sm-2 col-xs-2 text-center">
                      <div className="jobApplyBtn ">
                        <span>1</span>
                      </div>
                    </div>
                    <div class="col-3 col-md-2 col-sm-2 col-xs-2 text-center">
                      <div className="jobApplyBtn">
                        <a
                          href="/careerdetail"
                          className="btn btn-outline-secondary"
                        >
                          Apply Now
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="list-group-item" id="list_group_link">
                  <div class="row">
                    <div class="col-3 col-md-6 col-sm-6 col-xs-6">
                      <div class="list-group-item-title job-title">
                        Partner Solutions Consultant
                      </div>
                    </div>
                    <div class="col-3 col-md-2 col-sm-2 col-xs-2 text-center">
                      <div className="jobApplyBtn">
                        <span className="text-danger">Close </span>
                      </div>
                    </div>
                    <div class="col-3 col-md-2 col-sm-2 col-xs-2 text-center">
                      <div className="jobApplyBtn ">
                        <span>7</span>
                      </div>
                    </div>
                    <div class="col-3 col-md-2 col-sm-2 col-xs-2 text-center">
                      <div className="jobApplyBtn">
                        <a
                          href="/careerdetail"
                          className="btn btn-outline-secondary"
                        >
                          Apply Now
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="list-group-item" id="list_group_link">
                  <div class="row">
                    <div class="col-3 col-md-6 col-sm-6 col-xs-6">
                      <div class="list-group-item-title job-title">
                        Performance Marketing Associate/ Manager
                      </div>
                    </div>
                    <div class="col-3 col-md-2 col-sm-2 col-xs-2 text-center">
                      <div className="jobApplyBtn">
                        <span className="text-danger">Close </span>
                      </div>
                    </div>
                    <div class="col-3 col-md-2 col-sm-2 col-xs-2 text-center">
                      <div className="jobApplyBtn ">
                        <span>5</span>
                      </div>
                    </div>
                    <div class="col-3 col-md-2 col-sm-2 col-xs-2 text-center">
                      <div className="jobApplyBtn">
                        <a
                          href="/careerdetail"
                          className="btn btn-outline-secondary"
                        >
                          Apply Now
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="list-group-item" id="list_group_link">
                  <div class="row">
                    <div class="col-3 col-md-6 col-sm-6 col-xs-6">
                      <div class="list-group-item-title job-title">
                        Product Marketing Associate/ Manager
                      </div>
                    </div>
                    <div class="col-3 col-md-2 col-sm-2 col-xs-2 text-center">
                      <div className="jobApplyBtn">
                        <span className="text-success">Active </span>
                      </div>
                    </div>
                    <div class="col-3 col-md-2 col-sm-2 col-xs-2 text-center">
                      <div className="jobApplyBtn ">
                        <span>1</span>
                      </div>
                    </div>
                    <div class="col-3 col-md-2 col-sm-2 col-xs-2 text-center">
                      <div className="jobApplyBtn">
                        <a
                          href="/careerdetail"
                          className="btn btn-outline-secondary"
                        >
                          Apply Now
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="list-group-item" id="list_group_link">
                  <div class="row">
                    <div class="col-3 col-md-6 col-sm-6 col-xs-6">
                      <div class="list-group-item-title job-title">
                        Sales Operations Manager
                      </div>
                    </div>
                    <div class="col-3 col-md-2 col-sm-2 col-xs-2 text-center">
                      <div className="jobApplyBtn">
                        <span className="text-danger">Close </span>
                      </div>
                    </div>
                    <div class="col-3 col-md-2 col-sm-2 col-xs-2 text-center">
                      <div className="jobApplyBtn ">
                        <span>2</span>
                      </div>
                    </div>
                    <div class="col-3 col-md-2 col-sm-2 col-xs-2 text-center">
                      <div className="jobApplyBtn">
                        <a
                          href="/careerdetail"
                          className="btn btn-outline-secondary"
                        >
                          Apply Now
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="list-group-item" id="list_group_link">
                  <div class="row">
                    <div class="col-3 col-md-6 col-sm-6 col-xs-6">
                      <div class="list-group-item-title job-title">
                        Sr. Content Manager
                      </div>
                    </div>
                    <div class="col-3 col-md-2 col-sm-2 col-xs-2 text-center">
                      <div className="jobApplyBtn">
                        <span className="text-success">Active </span>
                      </div>
                    </div>
                    <div class="col-3 col-md-2 col-sm-2 col-xs-2 text-center">
                      <div className="jobApplyBtn ">
                        <span>1</span>
                      </div>
                    </div>
                    <div class="col-3 col-md-2 col-sm-2 col-xs-2 text-center">
                      <div className="jobApplyBtn">
                        <a
                          href="/careerdetail"
                          className="btn btn-outline-secondary"
                        >
                          Apply Now
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="list-group-item" id="list_group_link">
                  <div class="row">
                    <div class="col-3 col-md-6 col-sm-6 col-xs-6">
                      <div class="list-group-item-title job-title">
                        Technical Content Manager/Tech Writer
                      </div>
                    </div>
                    <div class="col-3 col-md-2 col-sm-2 col-xs-2 text-center">
                      <div className="jobApplyBtn">
                        <span className="text-danger">Close </span>
                      </div>
                    </div>
                    <div class="col-3 col-md-2 col-sm-2 col-xs-2 text-center">
                      <div className="jobApplyBtn ">
                        <span>4</span>
                      </div>
                    </div>
                    <div class="col-3 col-md-2 col-sm-2 col-xs-2 text-center">
                      <div className="jobApplyBtn">
                        <a
                          href="/careerdetail"
                          className="btn btn-outline-secondary"
                        >
                          Apply Now
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <h2 class="heading heading-jobboard">Product Management</h2>
              <div class="list-group list-jobs">
                <div class="list-group-item" id="list_group_link">
                  <div class="row">
                    <div class="col-3 col-md-6 col-sm-6 col-xs-6">
                      <div class="list-group-item-title job-title">
                        Associate UX Designer
                      </div>
                    </div>
                    <div class="col-3 col-md-2 col-sm-2 col-xs-2 text-center">
                      <div className="jobApplyBtn">
                        <span className="text-danger">Close </span>
                      </div>
                    </div>
                    <div class="col-3 col-md-2 col-sm-2 col-xs-2 text-center">
                      <div className="jobApplyBtn ">
                        <span>3</span>
                      </div>
                    </div>
                    <div class="col-3 col-md-2 col-sm-2 col-xs-2 text-center">
                      <div className="jobApplyBtn">
                        <a
                          href="/careerdetail"
                          className="btn btn-outline-secondary"
                        >
                          Apply Now
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="list-group-item" id="list_group_link">
                  <div class="row">
                    <div class="col-3 col-md-6 col-sm-6 col-xs-6">
                      <div class="list-group-item-title job-title">
                        Product Associate/ Manager
                      </div>
                    </div>
                    <div class="col-3 col-md-2 col-sm-2 col-xs-2 text-center">
                      <div className="jobApplyBtn">
                        <span className="text-danger">Close </span>
                      </div>
                    </div>
                    <div class="col-3 col-md-2 col-sm-2 col-xs-2 text-center">
                      <div className="jobApplyBtn ">
                        <span>1</span>
                      </div>
                    </div>
                    <div class="col-3 col-md-2 col-sm-2 col-xs-2 text-center">
                      <div className="jobApplyBtn">
                        <a
                          href="/careerdetail"
                          className="btn btn-outline-secondary"
                        >
                          Apply Now
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="list-group-item" id="list_group_link">
                  <div class="row">
                    <div class="col-3 col-md-6 col-sm-6 col-xs-6">
                      <div class="list-group-item-title job-title">
                        UX Designer
                      </div>
                    </div>
                    <div class="col-3 col-md-2 col-sm-2 col-xs-2 text-center">
                      <div className="jobApplyBtn">
                        <span className="text-success">Active </span>
                      </div>
                    </div>
                    <div class="col-3 col-md-2 col-sm-2 col-xs-2 text-center">
                      <div className="jobApplyBtn ">
                        <span>1</span>
                      </div>
                    </div>
                    <div class="col-3 col-md-2 col-sm-2 col-xs-2 text-center">
                      <div className="jobApplyBtn">
                        <a
                          href="/careerdetail"
                          className="btn btn-outline-secondary"
                        >
                          Apply Now
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <h2 class="heading heading-jobboard">Sales</h2>
              <div class="list-group list-jobs">
                <div class="list-group-item" id="list_group_link">
                  <div class="row">
                    <div class="col-3 col-md-6 col-sm-6 col-xs-6">
                      <div class="list-group-item-title job-title">
                        Sales Engineer
                      </div>
                    </div>
                    <div class="col-3 col-md-2 col-sm-2 col-xs-2 text-center">
                      <div className="jobApplyBtn">
                        <span className="text-danger">Close </span>
                      </div>
                    </div>
                    <div class="col-3 col-md-2 col-sm-2 col-xs-2 text-center">
                      <div className="jobApplyBtn ">
                        <span>1</span>
                      </div>
                    </div>
                    <div class="col-3 col-md-2 col-sm-2 col-xs-2 text-center">
                      <div className="jobApplyBtn">
                        <a
                          href="/careerdetail"
                          className="btn btn-outline-secondary"
                        >
                          Apply Now
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="list-group-item" id="list_group_link">
                  <div class="row">
                    <div class="col-3 col-md-6 col-sm-6 col-xs-6">
                      <div class="list-group-item-title job-title">
                        Sales Specialist
                      </div>
                    </div>
                    <div class="col-3 col-md-2 col-sm-2 col-xs-2 text-center">
                      <div className="jobApplyBtn">
                        <span className="text-success">Active </span>
                      </div>
                    </div>
                    <div class="col-3 col-md-2 col-sm-2 col-xs-2 text-center">
                      <div className="jobApplyBtn ">
                        <span>2</span>
                      </div>
                    </div>
                    <div class="col-3 col-md-2 col-sm-2 col-xs-2 text-center">
                      <div className="jobApplyBtn">
                        <a
                          href="/careerdetail"
                          className="btn btn-outline-secondary"
                        >
                          Apply Now
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <h2 class="heading heading-jobboard">
                Software Quality Assurance
              </h2>
              <div class="list-group list-jobs">
                <div class="list-group-item" id="list_group_link">
                  <div class="row">
                    <div class="col-3 col-md-6 col-sm-6 col-xs-6">
                      <div class="list-group-item-title job-title">
                        Software Quality Assurance Engineer
                      </div>
                    </div>
                    <div class="col-3 col-md-2 col-sm-2 col-xs-2 text-center">
                      <div className="jobApplyBtn">
                        <span className="text-danger">Close </span>
                      </div>
                    </div>
                    <div class="col-3 col-md-2 col-sm-2 col-xs-2 text-center">
                      <div className="jobApplyBtn ">
                        <span>2</span>
                      </div>
                    </div>
                    <div class="col-3 col-md-2 col-sm-2 col-xs-2 text-center">
                      <div className="jobApplyBtn">
                        <a
                          href="/careerdetail"
                          className="btn btn-outline-secondary"
                        >
                          Apply Now
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="list-group-item" id="list_group_link">
                  <div class="row">
                    <div class="col-3 col-md-6 col-sm-6 col-xs-6">
                      <div class="list-group-item-title job-title">
                        Sr. Software Quality Engineer
                      </div>
                    </div>
                    <div class="col-3 col-md-2 col-sm-2 col-xs-2 text-center">
                      <div className="jobApplyBtn">
                        <span className="text-success">Active </span>
                      </div>
                    </div>
                    <div class="col-3 col-md-2 col-sm-2 col-xs-2 text-center">
                      <div className="jobApplyBtn ">
                        <span>1</span>
                      </div>
                    </div>
                    <div class="col-3 col-md-2 col-sm-2 col-xs-2 text-center">
                      <div className="jobApplyBtn">
                        <a
                          href="/careerdetail"
                          className="btn btn-outline-secondary"
                        >
                          Apply Now
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="pt-5 row d-flex">
                <div class="col-lg-9 col-md-9 col-sm-9 col-xs-9">
                  <ul class="list-inline">
                    <li>
                      <a
                        class="btn btn-inverse-alt btn-secondary"
                        id="sign_up_for_email"
                        href="/subscribenow"
                      >
                        Subscribe to Job Alerts
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
}

export default Careers;
