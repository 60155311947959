import React, { useState } from "react";
import "gantt-task-react/dist/index.css";
import { ViewMode } from "gantt-task-react";
import { Button, styled } from "@mui/material";

const Clear = styled(Button)({
  textTransform: "capitalize",
  height: "22px",
  //   width: "67px",
  borderRadius: "4px",
  padding: "3px 8px 3px 8px",
  background: "#E0E1E4",
  "&:hover": {
    background: "#F7F7F7",
  },
  "&:active": {
    background: "#C9CBD0",
  },
});
export const ViewSwitcher = ({
  onViewModeChange,
  onViewListChange,
  isChecked,
}) => {
  return (
    <div className="ViewContainer">
      <Clear
        size="small"
        sx={{
          mx: 1,
          color: "#67748E",
          "&:hover": {
            color: "#67748E",
          },
          "&:active": {
            color: "#67748E",
          },
        }}
        onClick={() => onViewModeChange(ViewMode.QuarterDay)}>
        Quarter of Day
      </Clear>
      <Clear
        size="small"
        sx={{
          mx: 1,
          color: "#67748E",
          "&:hover": {
            color: "#67748E",
          },
          "&:active": {
            color: "#67748E",
          },
        }}
        onClick={() => onViewModeChange(ViewMode.HalfDay)}>
        Half of Day
      </Clear>
      <Clear
        size="small"
        sx={{
          mx: 1,
          color: "#67748E",
          "&:hover": {
            color: "#67748E",
          },
          "&:active": {
            color: "#67748E",
          },
        }}
        onClick={() => onViewModeChange(ViewMode.Day)}>
        Day
      </Clear>
      <Clear
        size="small"
        sx={{
          mx: 1,
          color: "#67748E",
          "&:hover": {
            color: "#67748E",
          },
          "&:active": {
            color: "#67748E",
          },
        }}
        onClick={() => onViewModeChange(ViewMode.Week)}>
        Week
      </Clear>
      <Clear
        size="small"
        sx={{
          mx: 1,
          color: "#67748E",
          "&:hover": {
            color: "#67748E",
          },
          "&:active": {
            color: "#67748E",
          },
        }}
        onClick={() => onViewModeChange(ViewMode.Month)}>
        Month
      </Clear>
    </div>
  );
};
