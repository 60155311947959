import React, { useEffect, useState } from "react";
import File from "../../assets/images/attendance/file.png";
import BookmarkAddedOutlinedIcon from "@mui/icons-material/BookmarkAddedOutlined";
import { FormControlLabel, Grid, styled } from "@mui/material";
import { Radio, RadioGroup, Typography } from "@mui/material";
import { Info } from "../../assets/Svg/svg";
import DashboardLayout from "../../layouts/Dashboard";
import DashboardNavbar from "../../layouts/Navbars/DashboardNavbar";
import Img from "../../assets/images/attendance/img1.png";
import ReplyAllOutlinedIcon from "@mui/icons-material/ReplyAllOutlined";
import {
  getAllEmployeesAttendance,
  getAllEmployeesAttendanceSummary,
  saveAttendance,
  updateAttendance,
} from "../../apiservices/attendance";
import { BackButton, Mark, Marked, RowStyle, SummaryButton } from "./styles";
import moment from "moment/moment";
import Summary from "./Summary";
import { toast } from "react-toastify";

const Typo = styled(Grid)({
  fontSize: "17px",
});
const Attendance = () => {
  const [records, setRecords] = useState([]);
  console.log(records);
  const [summary, setSummary] = useState([]);
  const [state, setState] = useState({
    loading: false,
    showSummary: false,
    filterMonth: moment().format("YYYY-MM"),
    monthDate: moment().format("YYYY-MM-DD"),
  });
  useEffect(() => {
    getAllAttendance(state.monthDate);
    getAllAttendanceSummary(state.filterMonth);
  }, []);

  const toggle = () => {
    setState((prev) => ({ ...prev, showSummary: !prev.showSummary }));
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
    if (name === "monthDate") {
      getAllAttendance(value);
    } else if (name === "filterMonth") {
      getAllAttendanceSummary(value);
    }
  };

  const handleRadioChange = (e, index) => {
    const { value } = e.target;
    let copy = JSON.parse(JSON.stringify(records));
    copy[index].status = parseInt(value);
    setRecords(copy);
  };

  const getAllAttendance = async (date) => {
    setState((prev) => ({ ...prev, loading: true }));
    let res = await getAllEmployeesAttendance(date);
    setState((prev) => ({ ...prev, loading: false }));
    setRecords(res.attendanceArr);
  };
  const getAllAttendanceSummary = async (date) => {
    setState((prev) => ({ ...prev, loading: true }));
    let res = await getAllEmployeesAttendanceSummary(date);
    setState((prev) => ({ ...prev, loading: false }));
    setSummary(res.attendanceArr);
  };
  const markAttendance = async (item) => {
    if (item._id) {
      let data = {
        _id: item._id,
        _rev: item._rev,
        employeeName: item.employeeName,
        time: moment().format("hh:mm A"),
        status: item.status,
        imageUrl: item.imageUrl,
        date: moment(state.monthDate).format("DD-MM-YYYY"),
        company_code: localStorage.getItem("companyCode"),
      };
      await updateAttendance(data);
      getAllAttendance(state.monthDate);
      getAllAttendanceSummary(state.filterMonth);

      toast.success("Attendance updated");
    } else {
      let data = {
        employeeId: item.email,
        employeeName: item.employeeName,
        time: moment().format("hh:mm A"),
        status: item.status,
        imageUrl: item.imageUrl,

        date: moment(state.monthDate).format("DD-MM-YYYY"),
        company_code: localStorage.getItem("companyCode"),
      };
      await saveAttendance(data);
      getAllAttendance(state.monthDate);
      getAllAttendanceSummary(state.filterMonth);

      toast.success("Attendance marked");
    }
  };
  return (
    <DashboardLayout>
      <DashboardNavbar>
        <Grid container alignItems="center" justifyContent="center">
          <Grid item xs={1.8} sx={{ display: "flex", justifyContent: "start" }}>
            <img src={File} alt="img" />
          </Grid>
          <Grid item xs={6} sx={{ display: "flex", justifyContent: "center" }}>
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              Attendance
            </Typography>
          </Grid>
          <Grid xs={0.3}></Grid>
          <Grid item xs={2.2}>
            {state.showSummary ? (
              <input
                type="month"
                id="att_date_input"
                name="filterMonth"
                value={state.filterMonth}
                onChange={handleInput}
                style={{
                  background: "#FFFFFF",
                  border: "1px solid #67748E",
                }}
              />
            ) : (
              <input
                type="date"
                id="att_date_input"
                name="monthDate"
                value={state.monthDate}
                onChange={handleInput}
                style={{
                  background: "#FFFFFF",
                  border: "1px solid #67748E",
                }}
              />
            )}
          </Grid>
          {state.showSummary && <Grid xs={0.3}></Grid>}
          <Grid item xs={1.4}>
            {state.showSummary ? (
              <BackButton size="small" onClick={toggle}>
                <ReplyAllOutlinedIcon />
              </BackButton>
            ) : (
              <SummaryButton size="small" onClick={toggle}>
                <Info />
                &nbsp;&nbsp; Summary
              </SummaryButton>
            )}
          </Grid>
        </Grid>
      </DashboardNavbar>

      {!state.showSummary &&
        records.map((item, index) => (
          <RowStyle direction="row" justifyContent="space-between">
            <img
              src={item.imageUrl ? item.imageUrl : Img}
              alt="empImg"
              width="50px"
              height="50px"
            />
            <Typo sx={{ width: "150px", mx: 1 }}>{item.employeeName}</Typo>
            <Typo>{item.time ? `Marked | ${item.time}` : "Not Marked"}</Typo>
            <RadioGroup
              row
              name="position"
              value={item.status}
              sx={{
                ".MuiFormControlLabel-label": {
                  fontSize: "13px !important",
                  fontWeight: "normal",
                },
              }}
              onChange={(e) => handleRadioChange(e, index)}
            >
              <FormControlLabel
                value={1}
                control={<Radio />}
                label="Present"
                labelPlacement="top"
              />
              <FormControlLabel
                value={2}
                control={<Radio />}
                label="Absent"
                labelPlacement="top"
              />
              <FormControlLabel
                value={3}
                control={<Radio />}
                label="Leave"
                labelPlacement="top"
              />
              <FormControlLabel
                value={4}
                control={<Radio />}
                label="Half Leave"
                labelPlacement="top"
              />
              <FormControlLabel
                value={5}
                control={<Radio />}
                label="WFH"
                labelPlacement="top"
              />
            </RadioGroup>
            {item._id ? (
              <Marked
                size="small"
                // sx={{ height: "10px !important" }}
                onClick={() => markAttendance(item)}
              >
                <BookmarkAddedOutlinedIcon sx={{ mx: 1 }} />
                Mark
              </Marked>
            ) : (
              <Mark
                size="small"
                // sx={{ height: "10px !important" }}
                onClick={() => markAttendance(item)}
              >
                <BookmarkAddedOutlinedIcon sx={{ mx: 1 }} />
                Mark
              </Mark>
            )}
          </RowStyle>
        ))}
      {state.showSummary && <Summary summary={summary} />}
    </DashboardLayout>
  );
};

export default Attendance;
