import { Button, Typography } from "@mui/material";
import { Box, styled } from "@mui/system";
import Frame from "../assets/images/Frame.png";

export const Header = styled(Box)({
  backgroundImage: `url(${Frame})`,
  // padding: 5,
  height: "25vh",
  backgroundRepeat: "no-repeat",
});
export const CancelButton = styled(Button)({
  height: "36px",
  width: "165px",
  borderRadius: "4px",
  padding: " 8px 62px 8px 62px",
  background: " #FFFFFF",
  color: "#A7A7A7",
  textTransform: "capitalize",

  "&:hover": {
    color: "#A7A7A7",
  },
  "&:active": {
    border: "1px solid #A7A7A7",
    background: "#A7A7A7",
    color: "#FFFFFF",
  },
});
export const SaveButton = styled(Button)({
  height: "36px",
  width: "165px",
  borderRadius: "4px",
  padding: " 8px 62px 8px 62px",
  background: " #21A49E",
  color: "#FFFFFF !important",
  textTransform: "capitalize",
  "&:hover": {
    boxShadow: "0px 2px 8px 0px",
    background: "#1B8C87",
    color: "#FFFFFF",
  },
  "&:active": {
    border: "1px solid #146E6A",
    background: "#146E6A",
    color: "#FFFFFF",
  },
});
export const ImgButton = styled(Button)({
  height: "27px",
  width: "120px",
  borderRadius: "4px",
  background: "#FFFFFF",
  border: "1px solid #A7A7A7",
  color: "#A7A7A7",
  textTransform: "capitalize",
  fontWeight: "normal",
  fontSize: "14px",
  padding: "5px",
  "&:hover": {
    background: "#A7A7A7",
    color: "#FFFFFF",
  },
});

export const CardHeader = styled(Box)({
  borderRadius: "8px",
  background: " #FFFFFF",
  height: "75px",
  margin: "0 auto",
  boxShadow: "0px 4px 30px 0px #0000001F",
  width: "97%",
  display: "flex",
  alignItems: "center",
});
export const BreakWord = styled(Typography)({
  width: "500px",
  overflowWrap: "break-word",
  wordWrap: "break-word",
  wordBreak: "break-word",
  minHeight: "15px",
  fontSize: "14px",
  color: "#7b809a",
  paddingRight: 10,
});
