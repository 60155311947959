import axios from "axios";

export const getProjectsByMember = async (userData) => {
  let res = await axios.post(`https://infini8ai.herokuapp.com/projects`, {
    request: {
      method: "getProjectsByMember",
      data: userData,
    },
  });
  let json = res.data.response.data;
  return json;
};
export const getProjectsByCompany = async (userData) => {
  let res = await axios.post(`https://infini8ai.herokuapp.com/projects`, {
    request: {
      method: "getProjectsByCompany",
      data: userData,
    },
  });
  let json = res.data.response.data;
  return json;
};
export const getTeamMembers = async (userData) => {
  let res = await axios.post(`https://infini8ai.herokuapp.com/teams`, {
    request: {
      method: "getTeamMembers",
      data: userData,
    },
  });
  let json = res.data.response.data;
  return json;
};
export const saveProject = async (userData) => {
  let res = await axios.post(`https://infini8ai.herokuapp.com/projects`, {
    request: {
      method: "saveProject",
      data: userData,
    },
  });
  let json = res.data.response.data;
  return json;
};
export const deleteProject = async (userData) => {
  let res = await axios.post(`https://infini8ai.herokuapp.com/projects`, {
    request: {
      method: "deleteProject",
      data: userData,
    },
  });
  let json = res.data.response.data;
  return json;
};
export const updateProject = async (userData) => {
  let res = await axios.post(`https://infini8ai.herokuapp.com/projects`, {
    request: {
      method: "updateProject",
      data: userData,
    },
  });
  let json = res.data.response.data;
  return json;
};
