import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  InputLabel,
  Stack,
  TextField,
} from "@mui/material";
import { v4 as uuid } from "uuid";
import { RoadBtn } from ".";
import { TableHeader } from "../meeting/Meeting";
import { useTheme } from "@mui/material/styles";
import AddRoadIcon from "@mui/icons-material/AddRoad";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ClearIcon from "@mui/icons-material/Clear";
import OtherHousesOutlinedIcon from "@mui/icons-material/OtherHousesOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import Repo from "../../Repository/Repo";
import OutlinedInput from "@mui/material/OutlinedInput";
import {
  FormControl,
  Menu,
  MenuItem,
  styled,
  Typography,
  Select,
  Grid,
} from "@mui/material";
import { Box } from "@mui/system";
import Traking from "./Traking";
import axios from "axios";
import Loader from "../../admin portal/task/Loader";
import Toaster from "../../components/Toaster";
import DataTable from "react-data-table-component";
import GanttView from "./GanttView";
import fille from "./fille.png";
import editIcon from "./editIcon.png";
import UpdateRow from "./UpdateRow";
import moment from "moment";

const customStyles = {
  rows: { style: { minHeight: "60px" } },
  headCells: {
    style: {
      backgroundColor: "#2155A4",
      color: "white",
      fontSize: "12px",
      paddingLeft: "5px",
    },
  },
  cells: { style: { paddingLeft: "5px", fontSize: "12px" } },
};
function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      height: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const Btn = styled(Button)({
  textTransform: "capitalize",
  color: "#67748E !important",
  marginLeft: "10px",
  "&:hover": {
    background: "#E0E1E4",
  },
  "&:active": {
    background: "#C9CBD0",
  },
});

const StyledMenu = styled(Box)({
  height: "162px",
  width: "520px",
  borderRadius: "6px",
  borderLeft: "3px solid #21A49E",
  padding: 5,
});
export const Clear = styled(Button)({
  textTransform: "capitalize",
  height: "22px",
  //   width: "67px",
  borderRadius: "4px",
  padding: "3px 8px 3px 8px",
  background: "#F7F7F7",
  "&:hover": {
    background: "#E0E1E4",
  },
  "&:active": {
    background: "#C9CBD0",
  },
});
export default function MyDataTable({ roadMap, getAllRoadMaps, closeView }) {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [loading, setLoading] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [groupTitle, setGroupTitle] = useState("");
  const [allGroups, setAllGroups] = useState([]);
  const [entryModal, setEntryModal] = useState(false);
  const [selected, setSelected] = useState("");
  const [display, setDisplay] = useState("");
  const [state, setState] = React.useState({
    columnName: "",
    value: "",
    color: "#000000",
  });
  const [gropId, setGropId] = useState("");
  const [users, setUsers] = useState([]);

  const [task, setTask] = useState({
    taskName: "",
    assignee: [],
    status: "",
    priority: "",
    estimatedTimeline: "",
    dependentOn: "",
    anyText: "",
    files: [],
  });
  const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [item, setItems] = useState([]);
  const [rowUpdate, setRowUpdate] = useState(false);
  const [updated, setUpdated] = useState([]);

  const handleRowUpdate = (row) => {
    setRowUpdate(true);
    setUpdated(row);
  };
  const handleRowUpdateClose = () => {
    setRowUpdate((prev) => !prev);
  };

  const open = Boolean(anchorEl);

  const columns = [
    {
      name: "#",
      selector: (row, index) => index + 1,
    },
    {
      name: "Task",
      selector: (row) => row.taskName,
    },
    {
      name: "Assignee",
      selector: (row) => row.assignee,
    },
    {
      name: "Status",
      selector: (row) => row.status,
    },
    {
      name: "Priority",
      selector: (row) => row.priority,
    },
    {
      name: "Estimated Timeline",
      selector: (row) => row.estimatedTimeline,
    },
    {
      name: "Dependent On",
      selector: (row) => row.dependentOn,
    },
    {
      name: "Any Text",
      selector: (row) => row.anyText,
    },
    {
      name: "File",
      selector: (row) =>
        row.files.length > 0 ? (
          <img src={fille} width="16px" alt="" />
        ) : (
          "no file"
        ),
    },
    {
      name: "",
      selector: (row) => (
        <Clear
          onClick={() => handleRowUpdate(row)}
          size="small"
          sx={{
            mx: 1,
            color: "#D05959",
            "&:hover": { color: "#D05959" },
            "&:active": { color: "#D05959" },
          }}>
          {" "}
          <img src={editIcon} width="14px" alt="" />{" "}
        </Clear>
      ),
    },
    {
      name: "",
      selector: (row) => (
        <Clear
          onClick={() => deleteRowTask(row.srNum)}
          size="small"
          sx={{
            mx: 1,
            color: "#D05959",
            "&:hover": {
              color: "#D05959",
            },
            "&:active": {
              color: "#D05959",
            },
          }}>
          <DeleteIcon sx={{ mx: 1 }} />
        </Clear>
      ),
    },
  ];
  useEffect(() => {
    getGroupsRecord();

    const groupId = allGroups.map((grp) => grp._id);
    setGropId(groupId);

    const srNum = allGroups?.map((grp) => grp?.tasks?.map((sr) => sr.srNum));

    setItems(srNum);

    const apiUrl = "https://infini8ai.herokuapp.com/users";
    const requestData = {
      request: {
        method: "getAllUsers",
      },
    };

    axios
      .post(apiUrl, requestData)
      .then((response) => {
        const userData = response.data.response.data.usersArr;
        let arr = [];
        userData.map((item) => arr.push(item.name));
        setUsers(arr);
      })
      .catch((error) => {
        console.error("API Request Error: ", error);
      });
  }, []);

  const handleView = (name) => {
    setDisplay(name);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleModal = () => {
    setAddModal((prev) => !prev);
    setGroupTitle(" ");
  };
  const handleChange = (e) => {
    let { name } = e.target;
    let { value } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };
  const clearState = () => {
    setState((prev) => ({ ...prev, columnName: "", value: "" }));
  };
  const saveRowTable = async () => {
    try {
      setLoading(true);
      const { data } = await Repo.roadMap({
        request: {
          method: "saveRoadMapTask",
          data: {
            groupId: selected._id,
            task: {
              ...task,
              estimatedTimeline: `${startDate} - ${endDate}`, // Ensure it's in the correct format
              isActive: false,
            },
          },
        },
      });

      setLoading(false);
      handleEntryModal();
      setTask({
        taskName: "",
        assignee: [],
        status: "",
        priority: "",
        estimatedTimeline: "",
        dependentOn: "",
        anyText: "",
        files: [],
      });
      setStartDate("");
      setEndDate("");
      if (data.response.data.groupId) {
        Toaster("success", "Added Successfully");
      } else {
        Toaster("error", "Error");
      }
      getGroupsRecord();
    } catch (error) {
      Toaster("error", "Error");
      console.error("Error making API request:", error);
      setLoading(false);
    }
  };

  const handleSaveGroup = async () => {
    try {
      setLoading(true);
      const { data } = await Repo.roadMap({
        request: {
          method: "saveRoadMapGroup",
          data: {
            roadmapId: roadMap._id,
            groupName: groupTitle,
          },
        },
      });

      setLoading(false);
      handleModal();
      if (data.response.data.groupId) {
        Toaster("success", "Group added successfully");
      } else {
        Toaster("error", "Error");
      }
      getGroupsRecord();
    } catch (error) {
      console.error("Error making API request:", error);
      Toaster("error", "Error");
      setLoading(false);
    }
  };

  const getGroupsRecord = async () => {
    try {
      setLoading(true);
      const { data } = await Repo.roadMap({
        request: {
          method: "getGroupsByRoadMap",
          data: {
            roadmapId: roadMap._id,
          },
        },
      });

      console.log("data", data);
      setLoading(false);

      if (data.response.data.groupsArr) {
        setAllGroups(data.response.data.groupsArr);
      }
    } catch (error) {
      console.error("Error making API request:", error);
      Toaster("error", "Error");
      setLoading(false);
    }
  };
  const handleEntryModal = (arg = "") => {
    if (!loading) {
      setEntryModal((prev) => !prev);
      setSelected(arg);
    }
  };

  const getGroupsRow = async () => {
    try {
      setLoading(true);
      const { data } = await Repo.roadMap({
        request: {
          method: "getGroupsByRoadMap",
          data: {
            roadmapId: roadMap._id,
          },
        },
      });

      console.log("data", data);
      setLoading(false);

      if (data.response.data.groupsArr) {
        setAllGroups(data.response.data.groupsArr);
      }
    } catch (error) {
      console.error("Error making API request:", error);
      Toaster("error", "Error");
      setLoading(false);
    }
  };
  const handleInput = (e) => {
    const { name, value } = e.target;
    if (name === "startDate" || name === "endDate") {
      const formattedDate = value
        ? new Date(value).toISOString().split("T")[0]
        : "";

      if (name === "startDate") {
        setStartDate(formattedDate);
      } else {
        setEndDate(formattedDate);
      }
    } else if (name === "files") {
      const { files } = e.target;
      if (files[0]) {
        setTask((prev) => ({ ...prev, files: [...prev.files, files[0]] }));
      }
    } else {
      setTask((prev) => ({ ...prev, [name]: value }));
    }
  };

  const deleteTask = async (id, item) => {
    setLoading(true);
    try {
      await axios.post(`https://infini8ai.herokuapp.com/roadmap`, {
        request: {
          method: "deleteRoadMapTasks",
          data: {
            [id]: [],
          },
        },
      });

      console.log("Task deleted successfully.");
    } catch (error) {
      console.error("Failed to delete the task.", error);
    } finally {
      setLoading(false);
      Toaster("success", "Delete Group Successfully");
      getGroupsRow();
    }
  };

  const deleteRoadmap = async (id) => {
    setLoading(true);

    try {
      await axios.post(`https://infini8ai.herokuapp.com/roadmap`, {
        request: {
          method: "deleteRoadMap",
          data: {
            _id: roadMap._id,
          },
        },
      });

      console.log("Task deleted successfully.");
      closeView();
    } catch (error) {
      console.error("Failed to delete the task.", error);
    } finally {
      setLoading(false);
      Toaster("success", "Delete Roadmap Successfully");
      getGroupsRow();
      getAllRoadMaps();
    }
  };

  const deleteRowTask = async (srNum) => {
    setLoading(true);
    try {
      await axios.post(`https://infini8ai.herokuapp.com/roadmap`, {
        request: {
          method: "deleteRoadMapTasks",
          data: {
            [allGroups[0]._id]: [srNum],
          },
        },
      });

      console.log("Task deleted successfully.");
    } catch (error) {
      console.error("Failed to delete the task.", error);
    } finally {
      setLoading(false);

      Toaster("success", "Task Delete Successfully");
      getGroupsRow();
      getAllRoadMaps();
    }
  };

  const handleSelectChange = (event) => {
    const {
      target: { value },
    } = event;
    setTask((prev) => ({
      ...prev,
      assignee:
        // On autofill we get a stringified value.
        typeof value === "string" ? value.split(",") : value,
    }));
  };

  return (
    <>
      <TableHeader
        container
        sx={{
          background: "#FFFFFF",
          mt: 2,
        }}>
        <RoadBtn size="medium" sx={{ mx: 3 }} onClick={handleModal}>
          <AddRoadIcon sx={{ mx: 1 }} />
          New group
        </RoadBtn>
        <Btn
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}>
          <AccountCircleIcon sx={{ mx: 1 }} /> Assignee
        </Btn>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}>
          <MenuItem onClick={handleClose}>Profile</MenuItem>
          <MenuItem onClick={handleClose}>My account</MenuItem>
          <MenuItem onClick={handleClose}>Logout</MenuItem>
        </Menu>
        <Btn
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}>
          Filter
        </Btn>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                left: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}>
          <StyledMenu>
            <Typography sx={{ m: 2 }}>Advanced filter</Typography>
            <Grid container>
              <Grid item xs={2} sx={{ mx: 1 }}>
                <Typography>Where</Typography>
              </Grid>
              <Grid item xs={3}>
                <FormControl fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="columnName"
                    sx={{ height: "40px" }}
                    onChange={handleChange}>
                    <MenuItem value={1}>Column name</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={2} sx={{ mx: 1 }}>
                <Typography>Is</Typography>
              </Grid>
              <Grid item xs={3}>
                <FormControl fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="value"
                    sx={{ height: "40px" }}
                    onChange={handleChange}>
                    <MenuItem value={2}>Value</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Clear
              size="small"
              onClick={clearState}
              sx={{
                color: "#67748E",
                "&:hover": {
                  color: "#67748E",
                },
                "&:active": {
                  color: "#67748E",
                },
              }}>
              <ClearIcon sx={{ mx: 1 }} />
              Clear
            </Clear>
          </StyledMenu>
        </Menu>
        <Clear
          onClick={() => handleView("")}
          size="small"
          sx={{
            mx: 1,
            color: "#67748E",
            "&:hover": {
              color: "#67748E",
            },
            "&:active": {
              color: "#67748E",
            },
          }}>
          <OtherHousesOutlinedIcon sx={{ mx: 1 }} />
          Default View
        </Clear>
        |
        <Clear
          onClick={() => handleView("gantt")}
          size="small"
          sx={{
            mx: 1,
            color: "#67748E",
            "&:hover": {
              color: "#67748E",
            },
            "&:active": {
              color: "#67748E",
            },
          }}>
          <OtherHousesOutlinedIcon sx={{ mx: 1 }} />
          Gantt View
        </Clear>
        <Clear
          onClick={() => handleView("trackingBoard")}
          size="small"
          sx={{
            mx: 1,
            color: "#67748E",
            "&:hover": {
              color: "#67748E",
            },
            "&:active": {
              color: "#67748E",
            },
          }}>
          <OtherHousesOutlinedIcon sx={{ mx: 1 }} />
          tracking board
        </Clear>
        <Clear
          onClick={deleteRoadmap}
          size="small"
          sx={{
            mx: 1,
            color: "#D05959",
            "&:hover": {
              color: "#D05959",
            },
            "&:active": {
              color: "#D05959",
            },
          }}>
          <DeleteIcon sx={{ mx: 1 }} />
          Delete roadmap
        </Clear>
      </TableHeader>
      {loading && (
        <Stack
          mt={5}
          direction="row"
          justifyContent="center"
          alignItems="center">
          <Loader />
        </Stack>
      )}
      {!loading && display == "" && (
        <Box sx={{ background: "#FFFFFF", p: 2, minHeight: "0px" }}>
          {allGroups.map((item, i) => (
            <Box key={i} sx={{ mb: 2 }}>
              <div style={{ width: "100%", marginBottom: "30px" }}>
                <Stack direction="row">
                  <Box
                    sx={{
                      mx: 3,
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      display: "flex",
                      width: "100%",
                    }}>
                    <Typography
                      sx={{
                        my: "8px",
                        color: "#2155A4",
                        fontSize: "16px",
                        fontWeight: "600",
                      }}>
                      {item.groupName}
                    </Typography>
                    <Clear
                      onClick={() => deleteTask(item._id, item)}
                      size="small"
                      sx={{
                        mx: 1,
                        color: "#D05959",
                        "&:hover": {
                          color: "#D05959",
                        },
                        "&:active": {
                          color: "#D05959",
                        },
                      }}>
                      <DeleteIcon sx={{ mx: 1 }} />
                      Delete group
                    </Clear>
                  </Box>
                </Stack>
                <DataTable
                  columns={columns}
                  data={item.tasks ?? []}
                  customStyles={customStyles}
                />
              </div>
              {loading ? (
                <Stack direction="row" justifyContent="center" width="100%">
                  <Loader />
                </Stack>
              ) : (
                <Stack
                  direction={"row"}
                  justifyContent="space-between"
                  width="100%">
                  {/* <Button onClick={() => addRowTable(item)}>Add item</Button> */}
                  <Button onClick={() => handleEntryModal(item)}>
                    Add Task
                  </Button>
                </Stack>
              )}
            </Box>
          ))}
        </Box>
      )}
      {display == "trackingBoard" && (
        <Traking
          allGroups={allGroups}
          task={task}
          success={getGroupsRecord}
          roadMap={roadMap}
          del={deleteRowTask}
        />
      )}
      {display == "gantt" && <GanttView allGroups={allGroups} />}

      <Dialog open={addModal} onClose={handleModal} fullWidth maxWidth="xs">
        <DialogContent>
          <Typography>Enter Group Name</Typography>
          <TextField
            fullWidth
            variant="outlined"
            value={groupTitle}
            onChange={(e) => setGroupTitle(e.target.value)}
          />
          {loading ? (
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              my={2}>
              <Loader />
            </Stack>
          ) : (
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              my={2}>
              <Button onClick={handleModal}>Cancel</Button>
              <Button onClick={handleSaveGroup}>Save</Button>
            </Stack>
          )}
        </DialogContent>
      </Dialog>

      <Dialog
        open={entryModal}
        onClose={handleEntryModal}
        fullWidth
        maxWidth="sm">
        <DialogContent>
          <Typography>Task Details</Typography>
          <Grid container spacing={3}>
            <Grid item md={12} xs={12}>
              <textarea
                style={{
                  width: "100%",
                  borderRadius: "8px",
                  borderColor: "#BDBDBD",
                  padding: 5,
                }}
                variant="outlined"
                placeholder="Task Name"
                rows={1}
                name="taskName"
                value={task.taskName}
                onChange={handleInput}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="demo-multiple-name-label">Name</InputLabel>

                <Select
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  multiple
                  value={task.assignee}
                  onChange={handleSelectChange}
                  sx={{ height: "2.7rem", width: "100%" }}
                  input={<OutlinedInput label="Person Name" />}
                  MenuProps={MenuProps}>
                  {users.map((name) => (
                    <MenuItem
                      key={name}
                      value={name}
                      style={getStyles(name, task.assignee, theme)}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item md={6} xs={12}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Status</InputLabel>
                <Select
                  name="status"
                  value={task.status}
                  onChange={handleInput}
                  input={<OutlinedInput label="Status" />}
                  c
                  sx={{ height: "2.8rem" }}>
                  <MenuItem value="Pending">Pending</MenuItem>
                  <MenuItem value="InProgress">InProgress</MenuItem>
                  <MenuItem value="Testing">Testing</MenuItem>
                  <MenuItem value="DOne">Done</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Priority</InputLabel>
                <Select
                  name="priority"
                  value={task.priority}
                  onChange={handleInput}
                  input={<OutlinedInput label="Priority" />}
                  sx={{ height: "2.8rem" }}>
                  <MenuItem value="First">High</MenuItem>
                  <MenuItem value="Second">Medium</MenuItem>
                  <MenuItem value="third">Low</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                id="outlined-required"
                variant="outlined"
                name="startDate"
                value={startDate}
                label="Start Date"
                type="date"
                onChange={handleInput}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                id="outlined-required"
                variant="outlined"
                name="endDate"
                value={endDate}
                label="End Date"
                type="date"
                onChange={handleInput}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                id="outlined-required"
                fullWidth
                variant="outlined"
                name="dependentOn"
                value={task.dependentOn}
                label="Collaborators"
                onChange={handleInput}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <textarea
                style={{
                  width: "100%",
                  borderRadius: "8px",
                  borderColor: "#BDBDBD",
                  padding: 5,
                }}
                fullWidth
                variant="outlined"
                name="anyText"
                value={task.anyText}
                placeholder="Description"
                onChange={handleInput}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                name="files"
                // value={task.files}
                type="file"
                placeholder="choose file"
                onChange={handleInput}
              />
            </Grid>
          </Grid>

          {loading ? (
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              my={1}>
              <Loader />
            </Stack>
          ) : (
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              my={2}>
              <Button onClick={handleEntryModal}>Cancel</Button>
              <Button onClick={saveRowTable}>Save</Button>
            </Stack>
          )}
        </DialogContent>
      </Dialog>

      <Dialog open={rowUpdate} onClose={handleRowUpdateClose}>
        <DialogContent>
          <UpdateRow
            updated={updated}
            allGroups={allGroups}
            rowUpdate={rowUpdate}
            setRowUpdate={setRowUpdate}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}
