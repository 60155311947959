import axios from "axios";

export const getTasksByProject = async (userData) => {
  let res = await axios.post(`https://infini8ai.herokuapp.com/tasks`, {
    request: {
      method: "getTasksByProject",
      data: userData,
    },
  });
  let json = res.data.response.data;
  return json;
};
export const getTasksByMember = async (userData) => {
  let res = await axios.post(`https://infini8ai.herokuapp.com/tasks`, {
    request: {
      method: "getTasksByMember",
      data: userData,
    },
  });
  let json = res.data.response.data;
  return json;
};
export const getTasksByCompany = async (userData) => {
  let res = await axios.post(`https://infini8ai.herokuapp.com/tasks`, {
    request: {
      method: "getTasksByCompany",
      data: userData,
    },
  });
  let json = res.data.response.data;
  return json;
};
export const saveTask = async (userData) => {
  let res = await axios.post(`https://infini8ai.herokuapp.com/tasks`, {
    request: {
      method: "saveTask",
      data: userData,
    },
  });
  let json = res.data.response.data;
  return json;
};
