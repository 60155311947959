import React from "react";

import fitnessVector from "../../../assets/images/optimus/fitness.svg";
import NutritionVector from "../../../assets/images/optimus/nutrition.svg";
import SleepVector from "../../../assets/images/optimus/sleep.svg";
import HealthVector from "../../../assets/images/optimus/health.svg";
import MindfulnessVector from "../../../assets/images/optimus/mindfullness.svg";
import MotivationVector from "../../../assets/images/optimus/motivation.svg";
import herotext from "../../../assets/images/optimus/bottom-text.svg";
import businessOpportunityIcon from "../../../assets/images/optimus/business1.png";
import SolutionImg from "../../../assets/images/optimus/sol1.png";
import mbleproblm1 from "../../../assets/images/optimus/mbleprob1.png";

import surveyAnalysis from "../../../assets/images/optimus/surveyAnalysis.svg";
import survayNinetyone from "../../../assets/images/optimus/survayNinetyone.svg";
import survaySixtyfive from "../../../assets/images/optimus/survaySixtyfive.svg";
import Union from "../../../assets/images/optimus/Union.svg";
import questionimg from "../../../assets/images/optimus/questionimg.png";

import userimg from "../../../assets/images/optimus/userimg.png";
import userimg1 from "../../../assets/images/optimus/user1.png";
import userimg2 from "../../../assets/images/optimus/user2.png";
import userimg3 from "../../../assets/images/optimus/user3.png";
import userimg4 from "../../../assets/images/optimus/user4.png";
import OptimusMobile2 from "./OptimusMobile2";
import familyphoto from "../../../assets/images/optimus/familygroup.png";
import BodyImg from "../../../assets/images/optimus/BodyImg.png";
import BothBack from "../../../assets/images/optimus/both-back.png";
import mainlearningmobile from "../../../assets/images/optimus/mainlearningmobile.png";


const OptimusMobile = () => {
  let url = "https://infini8ai-images.s3.amazonaws.com/optimus";
  return (
    <div>
      <div className="bg-dark">
        <div className="">
          <div className="container">
            <div className="optimus_heroContentText  pt-5">
              <h3 className="text-center  ">
                Personalised & Tailored Data Driven Wellbeing Optimisation.
              </h3>
            </div>
            <div className="text-center  w-100">
              <img
                src={BodyImg}
                class=" moblie_rendermedical img-fluid"
                alt="logo"
              />
            </div>
            <div className="d-flex justify-content-around">
              <div>
                <img
                  src={fitnessVector}
                  className=" img-fluid "
                  alt="nutrition"
                />
              </div>
              <div>
                <img
                  src={NutritionVector}
                  className=" img-fluid"
                  alt="nutrition"
                />
              </div>

              <div className="">
                <img src={SleepVector} className=" img-fluid" alt="sleep" />
              </div>
            </div>

            <div className="d-flex justify-content-around ">
              <div className="">
                <img src={HealthVector} className=" img-fluid " alt="health" />
              </div>
              <div>
                <img
                  src={MindfulnessVector}
                  className=" img-fluid"
                  alt="mindfulness"
                />
              </div>
              <div>
                <img
                  src={MotivationVector}
                  className=" img-fluid"
                  alt="motivation"
                />
              </div>
            </div>
            <div className=" text-white  ">
              <p className="lh-sm aggregate_mobile text-center mt-3">
                Easily aggregate & streamline all your wellbeing data, apps &
                expert coaching,on a single “Smartphone App”, towards your own
                personalised optimul unique lifestyle.
              </p>
            </div>
            <div className="w-100 text-center">
              <img src={herotext} className="img-fluid" />
            </div>

          </div>
          <div className="w-100 text-center">
          {/* <img className="img-fluid" src={`${url}/bottom-hero.png`} /> */}

              <img className="img-fluid" src={BothBack} />
            </div>
        </div>
      </div>
      <div className="SolutionMainDiv">
        <div className="bg-Case-4_Hero">
          <div className="container pt-5 ">
            <div className="bg-Case-4_backImg1">
              <div className="row" style={{ alignItems: "center" }}>
                <div className="col-md-3">
                  <div className="problemStatementMain">
                    <img
                      src={mbleproblm1}
                      className="problemStatementIcon img-fluid"
                      alt="nutrition"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="problemStatementMain">
                    <img
                      src={`${url}/probimg1.png`}
                      className="img-fluid problemStatementImg"
                      alt="nutrition"
                    />
                  </div>
                </div>
                <div className="col-md-3"></div>
              </div>
            </div>
            <div className="row" style={{marginLeft:"0px"}}>
              <div className="col-md-12">
                <div className="covidFearMain">
                  <div>
                    <p className="covidFear_mobile">
                      With Covid fear and busy routines, people didn’t have
                      access to a gym and a healthy lifestyle. They found it
                      difficult to train themselves at home with a proper
                      nutrition mentor and plan.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-Case-4_Hero">
          <div className="container pb-1">
            <div className="">
              <div className="row no-gutters" style={{ alignItems: "center" }}>
                <div className="col-md-3"></div>
                <div className="col-md-5">
                  <div className="problemStatementMain">
                    <img
                      src={SolutionImg}
                      className="img-fluid  "
                      alt="nutrition"
                      style={{ width: "65%" }}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="problemStatementMain">
                    <img
                      src={`${url}/sol2.png`}
                      className="img-fluid solutionIcon"
                      alt="nutrition"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row" style={{marginLeft:"0px"}}>
              <div className="col-md-12">
                <div className="covidFearMain2">
                  <div>
                    <p className="covidFear_mobile">
                      Optimus Health is a product made in collaboration with a
                      panel of NHS doctors in the UK. Personalized and tailored,
                      data-driven wellbeing optimization in the palm of your
                      hands.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-Case-4_Hero">
          <div className="container ">
            <div className="bg-Case-4_backImg3">
              <div className="row" style={{ alignItems: "center" }}>
                <div className="col-md-4">
                  <div className="problemStatementMains">
                    <img
                      src={businessOpportunityIcon}
                      className="img-fluid businessOpportunityIcon"
                      alt="nutrition"
                    />
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="problemStatementMains">
                    <img
                      src={`${url}/businessimg2.png`}
                      className="img-fluid businessOpportunityImg"
                      alt="nutrition"
                    />
                  </div>
                </div>
                <div className="col-md-3"></div>
              </div>
              <div className="row pb-5" style={{marginLeft:"0px"}}>
                <div className="col-md-12">
                  <div className="covidFearMain3">
                    <div>
                      <p className="covidFear_mobile">
                        The business opportunity is huge. Health remains the
                        continuous focus of every individual. Our purpose is to
                        give everyone an effective, scientific, objective, and
                        robust tool that optimizes their well-being based on
                        their ideal lifestyle. There is hardly any application
                        that acts as a one-stop shop for all your health needs.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="OptimusSurveyAnalysis">
        <div className="container">
          <div className="row">
            <div className="col-md-6 offset-md-3">
              <div className="OptimusSurveyTitle">
                <img src={surveyAnalysis} className="img-fluid" alt="" />
                <p>
                  They served <span>10</span> Health trainees, <span>10</span>{" "}
                  Doctors, and <span>15</span> Individuals through their{" "}
                  <span>MVP</span>.
                </p>
              </div>
            </div>
          </div>
          <div className="analysisCountingMain">
            <div className="row">
              <div className="col-md-6">
                <div className="analysisCountingImg">
                  <img
                    src={survayNinetyone}
                    className="img-fluid survayNinetyone"
                    alt="Ninetyone"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="analysisCountingImg">
                  <img
                    src={survaySixtyfive}
                    className="img-fluid survaySixtyfive"
                    alt="Sixtyfive"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="interviewQSection ">
        <div className="pt-5" style={{ background: "#f2f2f2" }}>
          <div className="container">
            <div className="row ">
              <div className="col-md-10 offset-md-1">
                <div className="">
                  <img src={`${url}/interheading.png`} className="img-fluid"  alt=""/>
                </div>
              </div>
              <div className="col-md-10 offset-md-1 pt-5">
                <div className="">
                  <img src={familyphoto} className="img-fluid" alt=""/>
                </div>
              </div>
            </div>
            <div className="row " style={{ alignItems: "center",marginLeft:"0px" }} >
              <div className="col-md-7" style={{marginLeft:"0px"}}>
                <div className="NHS_DoctorsContent mx-3 ">
                  <h5 className="iterview_mobile mt-4">
                    A Group of 50 individuals was questioned by our researchers
                    in coordination with the NHS Doctors.
                  </h5>
                  <p className="iterview_mobile mt-4">
                    Group was comprised of :
                  </p>
                  <ul className="d-flex ml-3 mt-4">
                    <li className="list-unstyled mr-1">
                      <img
                        src={Union}
                        className=" img-fluid Union "
                        alt="union"
                      />
                    </li>
                    <li className="list-unstyled ml-1">
                      <span className="iterview_mobile mt-4">
                        Male, Female, and LGBT members
                      </span>
                    </li>
                  </ul>
                  <ul className="d-flex ml-3 mt-4">
                    <li className="list-unstyled mr-1">
                      <img
                        src={Union}
                        className=" img-fluid Union"
                        alt="union"
                      />
                    </li>
                    <li className="list-unstyled ml-1">
                      <span className="iterview_mobile mt-4">Young adults</span>
                    </li>
                  </ul>
                  <ul className="d-flex ml-3 mt-4">
                    <li className="list-unstyled mr-1">
                      <img
                        src={Union}
                        className=" img-fluid Union"
                        alt="union"
                      />
                    </li>
                    <li className="list-unstyled ml-1 ">
                      <span className="iterview_mobile ">Older generation</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div id="questionedMain">
              <div className="container">
                <div className="row" style={{ alignItems: "center" , marginLeft:"0px"}} >
                  <div className="col-md-6">
                    <div className="text-center mt-5">
                      <img src={questionimg} className="img-fluid" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div id="questioned">
                      <p className="iterview_mobile mt-3">
                        The questioned asked were generally about :
                      </p>
                    </div>
                    <ul className="d-flex ml-3 mt-4">
                      <li className="list-unstyled mr-1">
                        <img src={Union} className="Union" alt="union" />
                      </li>
                      <li className="list-unstyled ml-1">
                        <span className="iterview_mobile mt-4">
                          Each of the individual’s daily routine before Covid
                          and how did it change afterward?
                        </span>
                      </li>
                    </ul>
                    <ul className="d-flex ml-3 mt-4">
                      <li className="list-unstyled mr-1">
                        <img src={Union} className="Union" alt="union" />
                      </li>
                      <li className="list-unstyled ml-1">
                        <span className="iterview_mobile mt-4">
                          How does each individual manage their nutrition and
                          health?
                        </span>
                      </li>
                    </ul>
                    <ul className="d-flex ml-3 mt-4">
                      <li className="list-unstyled mr-1">
                        <img src={Union} className="Union" alt="union" />
                      </li>
                      <li className="list-unstyled ml-1">
                        <span className="iterview_mobile mt-4">
                          Do any of these individuals engage in physical
                          exercise, either at home or in a gym?
                        </span>
                      </li>
                    </ul>
                    <ul className="d-flex ml-3 mt-4">
                      <li className="list-unstyled mr-1">
                        <img src={Union} className="Union" alt="union" />
                      </li>
                      <li className="list-unstyled ml-1">
                        <span className="iterview_mobile mt-4">
                          How did Covid affect their daily gym routine?
                        </span>
                      </li>
                    </ul>
                    <ul className="d-flex ml-3 mt-4">
                      <li className="list-unstyled mr-1">
                        <img src={Union} className="Union" alt="union" />
                      </li>
                      <li className="list-unstyled ml-1">
                        <span className="iterview_mobile mt-4">
                          Are they following any nutrition plan given by their
                          doctor or a nutritionist?
                        </span>
                      </li>
                    </ul>
                    <ul className="d-flex ml-3 mt-4">
                      <li className="list-unstyled mr-1">
                        <img src={Union} className="Union" alt="union" />
                      </li>
                      <li className="list-unstyled ml-1">
                        <span className="iterview_mobile mt-4">
                          What are their daily eating and sleeping habits?
                        </span>
                      </li>
                    </ul>
                    <ul className="d-flex ml-3 mt-4">
                      <li className="list-unstyled mr-1">
                        <img src={Union} className="Union" alt="union" />
                      </li>
                      <li className="list-unstyled ml-1">
                        <span className="iterview_mobile mt-4">
                          Do they use or follow any other medium for their
                          health benefits.
                        </span>
                      </li>
                    </ul>
                    <ul className="d-flex ml-3 mt-4">
                      <li className="list-unstyled mr-1">
                        <img src={Union} className="Union" alt="union" />
                      </li>
                      <li className="list-unstyled ml-1">
                        <span className="iterview_mobile mt-4">
                          How did Covid affect their mental and physical health?
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center mt-5">
              <img src={mainlearningmobile} className="img-fluid" />
            </div>
            <div className="d-flex justify-content-center mt-4">
              <img src={`${url}/mainlearnimg1.svg`} className="img-fluid" />
            </div>
            <div className="d-flex justify-content-center mt-4">
              <img src={`${url}/mainlearnimg2.svg`} className="img-fluid" />
            </div>
            <div className="d-flex justify-content-center mt-4">
              <img src={`${url}/mainlearnimg3.svg`} className="img-fluid" />
            </div>
            <div className="d-flex justify-content-center mt-4">
              <img src={`${url}/mainlearnimg4.svg`} className="img-fluid" />
            </div>
            <div className="row bg-dark">
              <div className="container">
                <div className="row">
                  <img src={userimg} className="img-fluid " />

                  <div className="col-6" style={{ marginTop: "-80px" }}>
                    <img src={userimg1} className="img-fluid" />
                  </div>
                  <div className="col-6" style={{ marginTop: "-80px" }}>
                    <img src={userimg2} className="img-fluid" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-6" style={{ marginTop: "-20px" }}>
                    <img src={userimg3} className="img-fluid" />
                  </div>
                  <div className="col-6" style={{ marginTop: "-20px" }}>
                    <img src={userimg4} className="img-fluid" />
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center mt-5">
              <img src={`${url}/johnmickny.png`} className="img-fluid" />
            </div>
          </div>
        </div>
      </div>
      <OptimusMobile2 />
    </div>
  );
};

export default OptimusMobile;
