import React from "react";
import Group4839 from "../../../assets/images/optimus/Group4839.jpg";
import appimage from "../../../assets/images/appimage.png";

import Group381 from "../../../assets/images/optimus/Group381.svg";
import Group388 from "../../../assets/images/optimus/Group388.svg";
import Group4837 from "../../../assets/images/optimus/Group4837.svg";
import Group291 from "../../../assets/images/optimus/Group291.svg";
import footerbottem from "../../../assets/images/footer/footerbottem.png";
import opthealth from "../../../assets/images/footer/opthealth.png";
import { Link ,useNavigate , } from "react-router-dom";
import { Link as Linkscroll,scroller } from "react-scroll";
const OptimusSection4 = () => {
  let url = "https://infini8ai-images.s3.amazonaws.com/optimus";

  const navigate = useNavigate();

  const handleScroll = (section) => {


    navigate('/');
    setTimeout(() => {
      scroller.scrollTo(section, {
        duration: 500,
        smooth: true,
      });
    }, 100);
  };
  return (
    <div>
      <div id="mobileSketches">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="mobileSketchesHead">
                <img
                  src={Group381}
                  className="img-fluid Group381"
                  alt="Group381"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="mobileSketchesImg">
                <img
                  src={appimage}
                  className="img-fluid Group4839"
                  alt="Group4839"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="mobileWireframe">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="mobileWireframeHead">
                <img
                  src={Group388}
                  className="img-fluid Group388"
                  alt="Group388"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="mobileWireframeImg">
                <img
                  src={`${url}/Group4838.svg`}
                  className="img-fluid Group4838"
                  alt="Group4838"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="OptimusMobileAppDesign">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="OptimusMobileAppDesignTitle text-center">
                <img
                  src={Group291}
                  className="img-fluid Group291"
                  alt="Group291"
                />
              </div>
            </div>
          </div>
          <div className="AppDesignMain">
            <div className="container-fluid">
              <div className="row text-center">
                <div className="col-md-12 col-sm-12">
                  <div className="OptimusMobileAppDesignContent">
                    <img
                      src={`${url}/Group4835.png`}
                      className="img-fluid Group4835"
                      alt="Group4835"
                    />
                  </div>
                </div>
              </div>
              <div className="row text-center">
                <div className="col-md-12 col-sm-12">
                  <div className="OptimusMobileAppDesignContent">
                    <img
                      src={`${url}/Group4836.png`}
                      className="img-fluid Group4836"
                      alt="Group4836"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="OptimusContact">
        <div className="container">
          <div className="row">
            <div className="col-md-6 offset-md-3">
              <div className="OptimusContactImg">
                <img
                  src={Group4837}
                  className="img-fluid Group4837"
                  alt="Group4837"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <footer
        id="NaseemCaseStudyFooter"
        style={{ backgroundColor: "#1A1A1A", backgroundImage: "none" }}
        className="page-footer font-small   pt-4 "
      >
        <div className="container">
          <div
            className="NaseemCaseStudyFooterContent"
            style={{ padding: "5rem 0" }}
          >
            <div
              id="NaseemFooterTop"
              className="footer-copyright  text-center "
            >
              <p className="base-text text-white"> www.MyOptimusHealth.com</p>
            </div>
            <div className="container-fluid text-center text-md-left">
              <div className="row">
                <div className="col-md-4 mt-md-0 mt-3">
                  <h5 className="text-uppercase text-white bold font">
                    ABOUT US
                  </h5>
                </div>

                <div className="col-md-4 mb-md-0 mb-3">
                  <h5 className="text-uppercase bold text-white font">
                    CONTACT US
                  </h5>
                </div>

                <div className="col-md-4 mb-md-0 mb-3">
                  <h5 className="text-uppercase bold text-white font">
                    NEWSLETTER
                  </h5>
                </div>
              </div>
              <div className="row pt-5">
                <div className="col-md-4 mt-md-0 mt-3">
                  <p className="base-text text-white">
                    Infinit8AI provides cutting edge solutions to all of your
                    problems. We have highly skilled, professional and
                    enthusiast individual that put all their efforts to make a
                    product better than the expectation of our clients.
                  </p>
                </div>

                <div className="col-md-4 mb-md-0 mb-3">
                  <ul className="list-unstyled">
                    <li>
                      <p className="base-text text-white">
                        7 Dickinson Court, West Yorkshire, WF1 3PU, Office 1206,
                        National Software Technology Park H-12, NUST Islamabad
                      </p>
                    </li>
                    <li>
                      <p className="base-text text-white">+44 7403 228448</p>
                    </li>
                    <li>
                      <p className="base-text text-white">
                        ali@naseem.education
                      </p>
                    </li>
                  </ul>
                </div>

                <div className="col-md-4 mb-md-0 mb-3">
                  <ul className="list-unstyled">
                    <li>
                      <p className="base-text text-white">
                        To get updates about our latest products and growing
                        market trends, send us a mail and get yourself updated.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer> */}

<footer
      style={{
        backgroundColor: "#1A1A1A",
        backgroundImage: "none",
      }}
      className="page-footer font-small   pb-3 "
    >
      <div className="container">
        <div className="row footer-row" >
          <div className="col-12 col-lg-3 col-md-4 footer-sitemap">
            <p
              className="fs-6 mt-4"
              style={{ fontFamily: "Dosis",color:"#dde73c", }}
            >
              SITE MAP
            </p>
           
            <div>
                {/* <p style={{ margin: "0 0 20px 0",color: "#116065", fontWeight:600,font:"poppins", fontSize:"24px" }}>Case Studies</p> */}
                <ul  className="text-white mt-4 lh-sm justify-content-md-start justify-content-center"
              style={{ fontFamily: "Dosis" , listStyleType: "none", display:"flex", gap:"4rem"}}>
                <div style={{display:"flex", flexDirection:"column", gap:"3rem"}}>
                   <Link to="/" style={{  }}>
                   <li style={{color:"white",fontSize:"16px"}}>Home</li>
                  </Link>
                  <div
          className="cursor-pointer"
          style={{ cursor: "pointer",  }}
          onClick={() => handleScroll("ourProducts")}
        >
            <li style={{ fontFamily: "Dosis",fontSize:"16px"}}>Products</li>
        </div>
                  {/* <li>About Us</li> */}
                  <Link to="/our-process" >
                  <li style={{color:"white",fontSize:"16px"}}>Our Process</li>
                  </Link>
                  </div>
                  <div  style={{display:"flex", flexDirection:"column", gap:"3rem"}}>
                  <Link to="/team" >
                  <li style={{ color:"white",fontSize:"16px" }}>Team</li>
                  </Link>
                  <Link to="/portfolio" >
                  <li style={{ color:"white",fontSize:"16px"}}>Portfolio</li>
                  </Link>
                  <Link to="/careers" >
                  <li style={{ color:"white",fontSize:"16px"}}>Careers</li>
                  </Link>
                  </div>
                </ul>
                </div>
          </div>
          <div className="col-12 col-lg-3 col-md-4 footer-contactus">
            <p
              className="fs-6 mt-4 "
              style={{ fontFamily: "Dosis",color:"#dde73c" }}
            >
              CONTACT US
            </p>
            <p  
              className="text-white mt-4 lh-sm"
              style={{ fontFamily: "Dosis" ,fontSize:"16px"}}
            >
              IDEA London Ground Floor 69 Wilson Street, London EC2A 2BB
              <p className="mt-4 mb-4 text-white lh-sm"   style={{ fontFamily: "Dosis",fontSize:"16px" }}>For hiring inquiries please contact: <span style={{fontFamily: "Dosis",fontSize:"16px"}}>hr@infini8ai.com</span></p> 
              
            </p>
            <p
              className="text-white mt-4 lh-sm"
              style={{ fontFamily: "Dosis" }}
            >
          
              <p className="mt-4 mb-4 text-white lh-sm"   style={{ fontFamily: "Dosis",fontSize:"16px" }}>For all other queries please contact: <span style={{fontFamily: "Dosis",fontSize:"16px"}}>info@infini8ai.com </span> </p> 
              
            </p>
            <div className=" mt-2">
          <p style={{ color: "white",fontSize:"16px" }}>2024 Copyright: Infini8AI</p>
        </div>

          </div>
          <div className="col-12 col-lg-3 col-md-4 footer-casestudies" >
            <p
              className="fs-6  mt-4  "
              style={{ fontFamily: "Dosis",color:"#dde73c" }}
            >
              CASE   STUDIES
            </p>
            {/* <p
              className="text-white mt-4 lh-sm"
              style={{ fontFamily: "Bitter" }}
            >
              To receive updates about our new product offerings and latest
              developments in the world of AI, join our bi-weekly newsletter:{" "}
              <a href="https://veracious.ai/news-letter" target="_blank">
                Veracious AI
              </a>
            </p> */}
              <div>
                {/* <p style={{ margin: "0 0 20px 0",color: "#116065", fontWeight:600,font:"poppins", fontSize:"24px" }}>Case Studies</p> */}
                <ul  className="text-white mt-4 lh-sm"
              style={{ fontFamily: "Dosis" , listStyleType: "none", display:"flex",  gap:"4rem"}}>
                    <div  style={{display:"flex", flexDirection:"column", gap:"3rem"}}>

                    <Link to="/optimus-case-study">
                    <li style={{color:"white",fontSize:"16px"}}>Healthcare</li>
                    </Link>
                    <Link to="/naseem-case-study">
                    <li style={{color:"white",fontSize:"16px"}}>Education</li>
                    </Link>
                    <Link to="/qurbani-case-study">
                    <li style={{color:"white",fontSize:"16px"}}>Livestock</li>
                    </Link>
                    </div>
                    <div  style={{display:"flex", flexDirection:"column", gap:"3rem"}}>

                    <Link to="/stock-chain-case-study">
                    <li style={{color:"white",fontSize:"16px"}}>Logistics</li>
                    </Link>
                    <Link to="/businesspal-case-study">
                    <li style={{color:"white",fontSize:"16px"}}>Business</li>
                    </Link>
                    </div>
                </ul>
                </div>

          </div>
        </div>
        
      </div>
    </footer>
    </div>
  );
};

export default OptimusSection4;
