import axios from "axios";

export const getTeamsByMember = async (userData) => {
  let res = await axios.post(`https://infini8ai.herokuapp.com/teams`, {
    request: {
      method: "getTeamsByMember",
      data: userData,
    },
  });
  let json = res.data.response.data;
  return json;
};
export const getTeamsByCompany = async (userData) => {
  let res = await axios.post(`https://infini8ai.herokuapp.com/teams`, {
    request: {
      method: "getTeamsByCompany",
      data: userData,
    },
  });
  let json = res.data.response.data;
  return json;
};
export const saveTeam = async (userData) => {
  let res = await axios.post(`https://infini8ai.herokuapp.com/teams`, {
    request: {
      method: "saveTeam",
      data: userData,
    },
  });
  let json = res.data.response.data;
  return json;
};
export const deleteTeam = async (userData) => {
  let res = await axios.post(`https://infini8ai.herokuapp.com/teams`, {
    request: {
      method: "deleteTeam",
      data: userData,
    },
  });
  let json = res.data.response.data;
  return json;
};

export const updateTeam = async (userData) => {
  let res = await axios.post(`https://infini8ai.herokuapp.com/teams`, {
    request: {
      method: "updateTeam",
      data: userData,
    },
  });
  let json = res.data.response.data;
  return json;
};
