import React from "react";
import stockmobile from "../../../assets/images/stock/stockimg.svg";
import illustration from "../../../assets/images/stock/illustration.png";
import probillustration from "../../../assets/images/stock/Problem illustration.svg";
import probstatement from "../../../assets/images/stock/probstatement.svg";
import solution from "../../../assets/images/stock/solution.svg";
import stockmarket from "../../../assets/images/stock/stock-market 1.svg";
import business from "../../../assets/images/stock/business.svg";
import interviewheading from "../../../assets/images/stock/interviewquestn.svg";
import learnheading from "../../../assets/images/stock/learnheading.svg";
import learn1 from "../../../assets/images/stock/learn1.svg";
import learn2 from "../../../assets/images/stock/learn2.svg";
import learn3 from "../../../assets/images/stock/learn3.svg";
import learn4 from "../../../assets/images/stock/learn4.svg";
import userperson from "../../../assets/images/stock/userperson.svg";
import empathymap from "../../../assets/images/stock/empathymap.svg";
import empathydo from "../../../assets/images/stock/empathydo.svg";
import empathysee from "../../../assets/images/stock/empathysee.svg";
import empathyhear from "../../../assets/images/stock/empathyhear.svg";
import empathypain from "../../../assets/images/stock/empathypain.svg";
import empathygain from "../../../assets/images/stock/empathygain.svg";
import empathygroup from "../../../assets/images/stock/empathygroup.svg";
import mobiledesign from "../../../assets/images/stock/mobiledesign.svg";
import stockchainlogo from "../../../assets/images/stock/stockchainlogo.svg";
import Group334 from "../../../assets/images//stock/Group334.png";
import Group336 from "../../../assets/images/stock/Group336.png";
import Union from "../../../assets/images/optimus/Union.svg";
import Group332 from "../../../assets/images/stock/Group332.png";
import StockChainIllustration from "../../../assets/images/stock/StockChainIllustration.png";
import footerbottem from "../../../assets/images/footer/footerbottem.png";
import businesspalbottom from "../../../assets/images/businesspal/businesspalbottom.png";
import empathyimage from "../../../assets/images/businesspal/empathymap.png";
import seeimage from "../../../assets/images/businesspal/seeimage.png";
import painimage from "../../../assets/images/businesspal/painimage.png";
import gainimage from "../../../assets/images/businesspal/gainimage.png";
import hearimage from "../../../assets/images/businesspal/Hearimage.png";
import doimage from "../../../assets/images/businesspal/doimage.png";
import saraimg from "../../../assets/images/businesspal/saraimg.png";
import rajimg from "../../../assets/images/businesspal/rajimg.png";
import Group277 from "../../../assets/images/businesspal/Group277.png";
import Group278 from "../../../assets/images/businesspal/Group278.png";
import Group48098340 from "../../../assets/images/businesspal/Group48098340.png";
import Group48098341 from "../../../assets/images/businesspal/Group48098341.png";
import Group48098342 from "../../../assets/images/businesspal/Group48098342.png";
import Group48098343 from "../../../assets/images/businesspal/Group48098343.png";
import Group48098310 from "../../../assets/images/businesspal/Group48098310.png";
import questionimg from "../../../assets/images/businesspal/questionimg.png";
import monitorimg from "../../../assets/images/businesspal/monitorimg.png";
import stockMarket1 from "../../../assets/images/stock/stockMarket1.png";
import sixtyfiveseventyimg from "../../../assets/images/businesspal/sixtyfiveseventyimg.png";
import Rectangle327 from "../../../assets/images/businesspal/Rectangle327.png";
import seventytwoeightyfivepercent from "../../../assets/images/businesspal/seventytwoeightyfivepercent.png";
import businesspaltopimg from "../../../assets/images/businesspal/businesspaltopimage.png";
import uiuixcasestudy from "../../../assets/images/businesspal/uiuixcasestudy.png";
import monitorscreen from "../../../assets/images/businesspal/monitorscreen.png";
import mobilescreen from "../../../assets/images/businesspal/mobikescreen.png";
import seventytwoeightyfiveimgmobile from "../../../assets/images/businesspal/seventytwoeightyfiveimgmobile.png";
import sixtyfiveseventyimgmobile from "../../../assets/images/businesspal/sixtyfiveseventyimgmobile.png";
import interviewquestionsasked from "../../../assets/images/businesspal/interviewquestionsasked.png";
import interviewqustionsmobile from "../../../assets/images/businesspal/interviewqustionsmobile.png";
import { Link ,useNavigate , } from "react-router-dom";
import { Link as Linkscroll,scroller } from "react-scroll";
const BusinessPalmobile = () => {
  let url = "https://infini8ai-images.s3.amazonaws.com/stock";
  const navigate = useNavigate();

  const handleScroll = (section) => {


    navigate('/');
    setTimeout(() => {
      scroller.scrollTo(section, {
        duration: 500,
        smooth: true,
      });
    }, 100);
  };
  return (
    <div>
      <div style={{ height: "100%" }}>
        <div
          className="stockmobile_background1 businesspal-first-container"
          style={{ background: "#E1FCFE" }}
        >
          <div className="stockChain_Text text-white d-flex justify-content-center  pt-5">
            <img src={businesspaltopimg} className="img-fluid" width="80%" />
          </div>
          <div className="stockChain_Text text-white d-flex justify-content-center  pt-5">
            <img src={uiuixcasestudy} className="img-fluid" width="80%"  />
          </div>
          <div style={{display:"flex",flexDirection:"column", alignItems:"center", gap:"1rem"}}>
          <div className="stockChain_Text p-3 text-center text-white mt-3">
            <p
              style={{
                fontSize: "40px",
                font: "poppins",
                fontWeight: 700,
                color: "#16CAD5",
              }}
            >
              BusinessPal
            </p>
          </div>
          <div className="text-center" style={{display:"flex", flexDirection:"column", alignItems:"center", gap:"1rem"}}>
            <p style={{maxWidth:"80%"}}>
              Manage your business professionally with BusinessPal through the
              best Web and Mobile Apps for your billing, inventory, accounting
              etc.
            </p>
            <p style={{maxWidth:"80%"}}>Join the digital revolution and bring your business online.</p>
          </div>
          </div>
          <div
            className="stockChain_Text text-center "
            style={{ paddingTop: "50px" }}
          >
            <img src={monitorimg} width="90%" />
          </div>
        </div>
      </div>
      <div id="StockProblemStatement" style={{paddingTop:"2rem", paddingBottom:"2rem",backgroundColor:"white"}}>
        <div className="container">
          <div
            className="row "
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div className="col-md-9">
              <div className="ProblemStatementCard">
                <div style={{ display: "flex", justifyContent: "center", }}>
                  <div
                    class="card text-center"
                    style={{ backgroundColor: "#ECFEFE",width:"90%" }}
                  >
                    <h5
                      style={{
                        fontWeight: 600,
                        fontSize: "24px",
                        color: "#16CAD5",
                      }}
                    >
                      <span style={{ color: "#000000" }}>Problem </span>
                      Statement
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="ProblemStatementImg">
                <div>
                  <div className="container py-4 ">
                    <p className="text-center" style={{fontWeight:400,font:"poppins", fontSize:"16px"}}>
                      Small and medium-sized businesses often face challenges in
                      efficiently managing their operations, including sales,
                      inventory, billing, invoicing, and financial
                      record-keeping. Traditional methods are time-consuming,
                      prone to errors, and lack integration, leading to
                      inefficiencies and increased operational costs.
                    </p>
                    <p className=" my-3  text-center "  style={{fontWeight:400,font:"poppins", fontSize:"16px"}}>
                      Businesses need a comprehensive and integrated solution
                      that streamlines these operations, enhances accuracy, and
                      provides real-time insights. The lack of such a solution
                      can lead to missed sales opportunities, stockouts or
                      overstock, delayed payments, and financial discrepancies.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="row "
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div className="col-md-9">
              <div className="ProblemStatementCard">
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div
                    class="card text-center"
                    style={{ backgroundColor: "#ECFEFE" ,width:"90%"}}
                  >
                    <h5
                      style={{
                        fontWeight: 600,
                        fontSize: "24px",
                        color: "#16CAD5",
                      }}
                    >
                      <span style={{ color: "#000000" }}>Possible </span>
                      Solution
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="ProblemStatementImg">
                <div>
                  <div className="container py-4 ">
                    <p className=" my-3 text-center " style={{fontWeight:400,font:"poppins", fontSize:"16px"}}>
                      To address the challenges faced by small and medium-sized
                      businesses in managing their operations, BusinessPal
                      offers an integrated platform that consolidates essential
                      business functions into one seamless solution. By
                      providing real-time sales and inventory tracking,
                      automated billing and invoicing, and efficient tools for
                      creating quotations, BusinessPal simplifies and
                      streamlines business processes. Additionally, it offers
                      daily bookkeeping capabilities, customizable bill
                      printing, and detailed ledger management. This
                      comprehensive approach not only reduces errors and
                      enhances operational efficiency but also provides valuable
                      insights into business performance, empowering businesses
                      to focus on growth and customer satisfaction.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="row "
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div className="col-md-9">
              <div className="ProblemStatementCard">
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div
                    class="card text-center"
                    style={{ backgroundColor: "#ECFEFE" ,width:"90%"}}
                  >
                    <h5
                      style={{
                        fontWeight: 600,
                        fontSize: "24px",
                        color: "#000000",
                      }}
                    >
                      <span style={{}}>Business </span>Opportunity
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="ProblemStatementImg">
                <div>
                  <div className="container py-4 ">
                    <p className=" my-3 text-center " style={{fontWeight:400,font:"poppins", fontSize:"16px"}}>
                      As businesses increasingly seek efficient and integrated
                      solutions to manage their operations, BusinessPal is
                      uniquely positioned to capitalize on this growing market
                      demand. By offering a comprehensive platform that
                      streamlines sales, inventory, billing, invoicing, and
                      financial record-keeping, BusinessPal addresses critical
                      pain points for small and medium-sized enterprises. This
                      creates a significant market opportunity to attract
                      businesses looking to improve operational efficiency,
                      reduce errors, and gain valuable insights into their
                      operations. Leveraging these features, BusinessPal can
                      drive substantial adoption, positioning itself as a vital
                      tool for business growth and customer satisfaction.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="StockSurveyAnalysis" style={{ background: "#116065" }}>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div
                className="StockLearningContentMain"
                style={{
                  background: "#116065",
                  borderColor: "#116065",
                  boxShadow: "-5px -5px 10px #37757B",
                  textAlign: "center",
                }}
              >
                <p
                  style={{
                    color: "#62FFAA",
                    fontSize: "30px",
                    paddingTop: "0.5rem",
                    paddingBottom: "0.5rem",
                     fontWeight:600,fontSize:"30px", font:"poppins"
                  }}
                >
                  Survey Analysis
                </p>
              </div>
            </div>
            <div className="col-md-12">
              <div className="d-flex justify-content-center">
                <p
                  className="fs-3 my-5 lh-base text-center "
                  style={{ color: "white", maxWidth: "80%", }}
                >
                  A survey was conducted among small and medium-sized
                  enterprises to understand the challenges in managing business
                  operations and evaluate the potential impact of an integrated
                  solution like BusinessPal.
                </p>
              </div>
            </div>
          </div>
          <div className="row" style={{}}>
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-6">
                  <div className="StockAnalysisContentCardImg">
                    <img
                      src={sixtyfiveseventyimgmobile}
                      className="GroupImg273"
                      alt="Group273"
                    />
                  </div>
                </div>
                <div
                  className="col-md-6"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      // width: '300px',
                      height: "200px",
                      backgroundColor: "#136E73",
                      borderRadius: "20px",
                      boxShadow: "-20px -20px 20px #37757B",
                    }}
                  >
                    <p style={{ color: "white", textAlign: "center", paddingLeft:"0.3rem", paddingRight:"0.3rem" }}>
                      <span style={{color:"#62FFAA"}}>65%</span> of respondents reported difficulties in tracking sales
                      and managing customer data, leading to missed sales
                      opportunities.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row" style={{marginTop:"2.6rem"}}>
                <div className="col-md-12">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      // width: '300px',
                      height: "200px",
                      backgroundColor: "#136E73",
                      borderRadius: "20px",
                      boxShadow: "-20px -20px 20px #37757B",
                    }}
                  >
                    <p style={{ color: "white", textAlign: "center", paddingLeft:"0.3rem", paddingRight:"0.3rem" }}>
                    <span style={{color:"#62FFAA"}}>70%</span> experienced issues with stock control, including
                      frequent stockouts and overstock situations, which affect
                      cash flow and customer satisfaction.
                    </p>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="StockAnalysisContentCardImg">
                    <img
                      src={seventytwoeightyfiveimgmobile}
                      className="GroupImg276"
                      alt="Group276"
                    />
                  </div>
                </div>

                <div
                  className="col-md-6"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      // width: '300px',
                      height: "200px",
                      backgroundColor: "#136E73",
                      borderRadius: "20px",
                      boxShadow: "-20px -20px 20px #37757B",
                    }}
                  >
                    <p style={{ color: "white", textAlign: "center" , paddingLeft:"0.3rem", paddingRight:"0.3rem"}}>
                    <span style={{color:"#62FFAA"}}> 72%</span>  found traditional methods of billing and invoicing to
                      be time-consuming and error-prone, resulting in financial
                      discrepancies.
                    </p>
                  </div>
                </div>
              </div>

              <div className="row" style={{marginTop:"2.6rem"}}>
                <div className="col-md-12">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      // width: '300px',
                      height: "200px",
                      backgroundColor: "#136E73",
                      borderRadius: "20px",
                      boxShadow: "-20px -20px 20px #37757B",
                    }}
                  >
                    <p style={{ color: "white", textAlign: "center", paddingLeft:"0.3rem", paddingRight:"0.3rem" }}>
                    <span style={{color:"#62FFAA"}}> 85% </span>  indicated that maintaining accurate financial records
                      was a significant challenge, often leading to errors and
                      non-compliance with regulatory requirements.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="StockInterviewQuestions" style={{ backgroundColor: "#BBE0F5" ,paddingTop:"4rem", paddingBottom:"1rem"}}>
        <div className="container">
          <div className="row">
            <div className="col-md-8 offset-md-2 text-center">
              <div className="StockInterviewImage1">
                <img src={ interviewqustionsmobile} className="Groupimg277" alt="Group277" />
              </div>
            </div>
          </div>
          <div className="row ">
            <div className="col-md-12 text-center">
              <div className="StockInterviewImage2">
                <img src={interviewquestionsasked} className="Groupimg278" alt="Group278" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="StockMainLearning" style={{ backgroundColor: "#116065",paddingTop:"4rem", paddingBottom:"4rem" }}>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div
                className="StockLearningContentMain"
                style={{
                  background: "#116065",
                  borderColor: "#116065",
                  boxShadow: "-5px -5px 10px #37757B",
                }}
              >
                <p style={{ color: "#62FFAA" }}>Main Learnings</p>
              </div>
            </div>
            <div className="col-md-12 ">
              <div className="row pt-5">
                <div className="col-md-6">
                  <div className="StockAnalysisContentCardImg">
                    <img
                      src={Group48098340}
                      className="GroupImg280"
                      alt="Group280"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="StockAnalysisContentCardImg">
                    <img
                      src={Group48098341}
                      className="GroupImg281"
                      alt="Group281"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="StockAnalysisContentCardImg">
                    <img
                      src={Group48098342}
                      className="GroupImg282"
                      alt="Group282"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="StockAnalysisContentCardImg">
                    <img
                      src={Group48098343}
                      className="GroupImg283"
                      alt="Group283"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="StockAbouts" style={{paddingTop:"4rem", paddingBottom:"4rem",backgroundColor:"white"}}>
        <div
          className="container"
          style={{ display: "flex", flexDirection: "column", gap: "80px" }}
        >
          <div className="row">
            <div className="col-md-12">
              <div className="StockLearningContentMain">
                <p>User Personas</p>
              </div>
            </div>
          </div>
          <div
            style={{ display: "flex", flexDirection: "column", gap: "25px" }}
          >
            <div className="row">
              <div className="col-md-12">
                <div className="StockAnalysisContentCardImg">
                  <img
                    src={saraimg}
                    className="GroupImg276"
                    alt="Group276"
                    style={{ maxWidth: "100%" }}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-5">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    // width: '300px',
                    height: "308px",
                    backgroundColor: "#E1FCFE",
                    borderRadius: "20px",
                    // boxShadow: "-20px -20px 20px #37757B",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "15px",
                    }}
                  >
                    <p style={{ color: "#116065", textAlign: "center" ,fontWeight:600,font:"poppins", fontSize:"24px"}}>About</p>
                    <p style={{ color: "black", textAlign: "center",fontWeight:400,font:"poppins", fontSize:"16px" , marginLeft:"2rem",marginRight:"2rem"}}>
                      Sarah runs a small retail store and struggles with
                      managing inventory and finances using traditional methods.
                    </p>
                    <p style={{ color: "#116065", textAlign: "center" ,fontWeight:400,font:"poppins", fontSize:"16px" , marginLeft:"2rem",marginRight:"2rem"}}>
                      “keeping track of everything manually is exhausting and
                      prone to mistake”
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    // width: '300px',
                    height: "400px",
                    backgroundColor: "#E1FCFE",
                    borderRadius: "20px",
                    // boxShadow: "-20px -20px 20px #37757B",
                    paddingLeft:"0.5rem",
                    paddingRight:"0.5rem",
                    gap:"1rem"
                  }}
                >
                  <div>
                    <p style={{ margin: "0 0 20px 0",color: "#116065", fontWeight:600,font:"poppins", fontSize:"24px" }}>Goals</p>
                    <ul style={{ margin: "0", padding: "0 0 0 20px" , fontWeight:400,font:"poppins", fontSize:"16px"}}>
                      <li>Streamline inventory management</li>
                      <li>Reduce manual errors</li>
                      <li>Improve financial accuracy</li>
                    </ul>
                  </div>

                  <div>
                    <p style={{ margin: "0 0 20px 0" ,color: "#116065", fontWeight:600,font:"poppins", fontSize:"24px"}}>Pain Points</p>
                    <ul style={{ margin: "0", padding: "0 0 0 20px" , fontWeight:400,font:"poppins", fontSize:"16px"}}>
                      <li>Time-consuming manual processes</li>
                      <li>Frequent stockouts and overstock</li>
                      <li>Financial discrepancies</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="StockAnalysisContentCardImg">
                  <img
                    src={rajimg}
                    className="GroupImg276"
                    alt="Group276"
                    style={{ maxWidth: "100%" }}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    // width: '300px',
                    height: "308px",
                    backgroundColor: "#E1FCFE",
                    borderRadius: "20px",
                    // boxShadow: "-20px -20px 20px #37757B",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "15px",
                    }}
                  >
                    <p style={{ color: "#116065", textAlign: "center" ,fontWeight:600,font:"poppins", fontSize:"24px" }}>About</p>
                    <p style={{color: "black", textAlign: "center",fontWeight:400,font:"poppins", fontSize:"16px" , marginLeft:"2rem",marginRight:"2rem" }}>
                      Raj manages a medium-sized manufacturing business and
                      needs an efficient solution to handle complex operations.{" "}
                    </p>
                    <p style={{  color: "#116065", textAlign: "center" ,fontWeight:400,font:"poppins", fontSize:"16px" , marginLeft:"2rem",marginRight:"2rem"}}>
                      "An integrated system could save us so much time and
                      money."
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    // width: '300px',
                    height: "400px",
                    backgroundColor: "#E1FCFE",
                    borderRadius: "20px",
                    // boxShadow: "-20px -20px 20px #37757B",
                    paddingLeft:"0.5rem",
                    paddingRight:"0.5rem",
                    gap:"1rem"
                  }}
                >
                  <div>
                    <p style={{ margin: "0 0 20px 0",color: "#116065", fontWeight:600,font:"poppins", fontSize:"24px" }}>Goals</p>
                    <ul style={{ margin: "0", padding: "0 0 0 20px" , fontWeight:400,font:"poppins", fontSize:"16px"}}>
                      <li>Integrate various business tasks</li>
                      <li>Gain real-time insights to sales</li>
                      <li>Improve cash flow management</li>
                    </ul>
                  </div>

                  <div>
                    <p style={{ margin: "0 0 20px 0" ,color: "#116065", fontWeight:600,font:"poppins", fontSize:"24px"}}>Pain Points</p>
                    <ul style={{ margin: "0", padding: "0 0 0 20px" , fontWeight:400,font:"poppins", fontSize:"16px"}}>
                      <li>Inefficiencies in current operations</li>
                      <li>Payment issues due to invoices</li>
                      <li>Lack of real-time business insights</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="Empathypart">
        <div style={{ background: "#E1FCFE" }}>
          <div className="text-center pt-2">
            <img src={empathyimage} className="img-fluid   " />
          </div>
          <div className="text-center pt-2">
            <img src={doimage} className="img-fluid   " />
          </div>
          <div className="text-center pt-2">
            <img src={seeimage} className="img-fluid   " />
          </div>
          <div className="text-center pt-2">
            <img src={hearimage} className="img-fluid   " />
          </div>
          <div className="text-center pt-2">
            <img src={painimage} className="img-fluid   " />
          </div>
          <div className="text-center pt-2">
            <img src={gainimage} className="img-fluid   " />
          </div>{" "}
          {/* <div className="text-center pt-2">
            <img src={empathygroup} className="img-fluid   " />
          </div> */}
        </div>
      </div>
      <div id="Mobiledesign" style={{backgroundColor:"white"}}>
        <div className="text-center pt-5 pb-5">
          <img src={mobiledesign} className="img-fluid   " />
        </div>
        <div className="text-center pt-2">
          <img src={monitorscreen} className="img-fluid   " />
        </div>
        <div className="text-center pt-2">
          <img src={mobilescreen} className="img-fluid   " />
        </div>
      </div>
      <div
        id=""
        style={{
          background: "#16CAD5",
          paddingTop: "3rem",
          
        }}
      >
        <div className="row" style={{}}>
          <div className="col-md-12">
            <div className="d-flex justify-content-center">
              <img
                src={businesspalbottom}
                className="StockChain_FC_HLockup"
                alt="StockChain_FC_HLockup"
                style={{ maxWidth: "80%" }}
              />
            </div>
          </div>
          <div className="col-md-12">
            <div className="ProblemStatementImg">
              <div>
                <div className="container py-4 ">
                  <p
                    className="fs-3 my-5 lh-base text-center "
                    style={{ color: "white" }}
                  >
                    BusinessPal is well-equipped to tackle operational
                    challenges with its comprehensive features, improving
                    efficiency, accuracy, and providing real-time insights. The
                    survey suggests a strong market potential for BusinessPal,
                    highlighting its role as a crucial tool for business growth
                    and customer satisfaction.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="footerpart">
      <footer
      style={{
        backgroundColor: "#037d7a",
        backgroundImage: "none",
      }}
      className="page-footer font-small   pb-3 "
    >
      <div className="container">
        <div className="row footer-row" >
          <div className="col-12 col-lg-3 col-md-4 footer-sitemap">
            <p
              className="fs-6 mt-4"
              style={{ fontFamily: "Dosis",color:"#dde73c", }}
            >
              SITE MAP
            </p>
           
            <div>
                {/* <p style={{ margin: "0 0 20px 0",color: "#116065", fontWeight:600,font:"poppins", fontSize:"24px" }}>Case Studies</p> */}
                <ul  className="text-white mt-4 lh-sm justify-content-md-start justify-content-center"
              style={{ fontFamily: "Dosis" , listStyleType: "none", display:"flex", gap:"4rem"}}>
                <div style={{display:"flex", flexDirection:"column", gap:"3rem"}}>
                   <Link to="/" style={{  }}>
                   <li style={{color:"white",fontSize:"16px"}}>Home</li>
                  </Link>
                  <div
          className="cursor-pointer"
          style={{ cursor: "pointer",  }}
          onClick={() => handleScroll("ourProducts")}
        >
            <li style={{ fontFamily: "Dosis",fontSize:"16px"}}>Products</li>
        </div>
                  {/* <li>About Us</li> */}
                  <Link to="/our-process" >
                  <li style={{color:"white",fontSize:"16px"}}>Our Process</li>
                  </Link>
                  </div>
                  <div  style={{display:"flex", flexDirection:"column", gap:"3rem"}}>
                  <Link to="/team" >
                  <li style={{ color:"white",fontSize:"16px" }}>Team</li>
                  </Link>
                  <Link to="/portfolio" >
                  <li style={{ color:"white",fontSize:"16px"}}>Portfolio</li>
                  </Link>
                  <Link to="/careers" >
                  <li style={{ color:"white",fontSize:"16px"}}>Careers</li>
                  </Link>
                  </div>
                </ul>
                </div>
          </div>
          <div className="col-12 col-lg-3 col-md-4 footer-contactus">
            <p
              className="fs-6 mt-4 "
              style={{ fontFamily: "Dosis",color:"#dde73c" }}
            >
              CONTACT US
            </p>
            <p  
              className="text-white mt-4 lh-sm"
              style={{ fontFamily: "Dosis" ,fontSize:"16px"}}
            >
              IDEA London Ground Floor 69 Wilson Street, London EC2A 2BB
              <p className="mt-4 mb-4 text-white lh-sm"   style={{ fontFamily: "Dosis",fontSize:"16px" }}>For hiring inquiries please contact: <span style={{fontFamily: "Dosis",fontSize:"16px"}}>hr@infini8ai.com</span></p> 
              
            </p>
            <p
              className="text-white mt-4 lh-sm"
              style={{ fontFamily: "Dosis" }}
            >
          
              <p className="mt-4 mb-4 text-white lh-sm"   style={{ fontFamily: "Dosis",fontSize:"16px" }}>For all other queries please contact: <span style={{fontFamily: "Dosis",fontSize:"16px"}}>info@infini8ai.com </span> </p> 
              
            </p>
            <div className=" mt-2">
          <p style={{ color: "white",fontSize:"16px" }}>2024 Copyright: Infini8AI</p>
        </div>

          </div>
          <div className="col-12 col-lg-3 col-md-4 footer-casestudies" >
            <p
              className="fs-6  mt-4  "
              style={{ fontFamily: "Dosis",color:"#dde73c" }}
            >
              CASE   STUDIES
            </p>
            {/* <p
              className="text-white mt-4 lh-sm"
              style={{ fontFamily: "Bitter" }}
            >
              To receive updates about our new product offerings and latest
              developments in the world of AI, join our bi-weekly newsletter:{" "}
              <a href="https://veracious.ai/news-letter" target="_blank">
                Veracious AI
              </a>
            </p> */}
              <div>
                {/* <p style={{ margin: "0 0 20px 0",color: "#116065", fontWeight:600,font:"poppins", fontSize:"24px" }}>Case Studies</p> */}
                <ul  className="text-white mt-4 lh-sm"
              style={{ fontFamily: "Dosis" , listStyleType: "none", display:"flex",  gap:"4rem"}}>
                    <div  style={{display:"flex", flexDirection:"column", gap:"3rem"}}>

                    <Link to="/optimus-case-study">
                    <li style={{color:"white",fontSize:"16px"}}>Healthcare</li>
                    </Link>
                    <Link to="/naseem-case-study">
                    <li style={{color:"white",fontSize:"16px"}}>Education</li>
                    </Link>
                    <Link to="/qurbani-case-study">
                    <li style={{color:"white",fontSize:"16px"}}>Livestock</li>
                    </Link>
                    </div>
                    <div  style={{display:"flex", flexDirection:"column", gap:"3rem"}}>

                    <Link to="/stock-chain-case-study">
                    <li style={{color:"white",fontSize:"16px"}}>Logistics</li>
                    </Link>
                    <Link to="/businesspal-case-study">
                    <li style={{color:"white",fontSize:"16px"}}>Business</li>
                    </Link>
                    </div>
                </ul>
                </div>

          </div>
        </div>
        
      </div>
    </footer>
      </div>
    </div>
  );
};

export default BusinessPalmobile;
