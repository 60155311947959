import React, { useLayoutEffect } from "react";
import { useState, useEffect } from "react";
import "./task.css";
import axios from "axios";
import { toast } from "react-toastify";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import S3FileUpload from "react-s3/lib/ReactS3";
import { Box, styled } from "@mui/system";
import { Button, Grid, Stack, Typography } from "@mui/material";
import AddCompany from "../../assets/images/project/AddCompany.svg";
import CardImg from "../../assets/images/project/cardImg.png";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import TaskImg from "../../assets/images/project/task.png";
import TaskDetailPopup from "./TaskDetailPopup";
import DashboardLayout from "../../layouts/Dashboard";
import DashboardNavbar from "../../layouts/Navbars/DashboardNavbar";
import { getTasksByCompany, saveTask } from "../../apiservices/task";
import { getUsersByCompany } from "../../apiservices/general";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import Select from "react-select";
import { components } from "react-select";
import Loader from "./Loader";
import { BackButton } from "../team/Team";
import { Dialog, DialogContent } from "@mui/material";
import "@fullcalendar/react/dist/vdom";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import Slide from "@mui/material/Slide";
import uploadimage from "../../assets/images/project/add_a_photo.svg";
import emp from "../../assets/images/project/emp.svg";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
window.Buffer = window.Buffer || require("buffer").Buffer;
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const Body = styled(Stack)({
  background: "#FFFFFF",
  padding: "10px",
  // margin: "20px",
  position: "absolute",
  borderRadius: "10px",
  width: "95%",
});
const Task = ({ closeTask, viewMembers, viewMembersModel }) => {
  const [isEdit, setisEdit] = useState("");
  const [editShow, seteditShow] = useState(false);
  const userName = localStorage.getItem("userName");
  const [assignTask, setassignTask] = useState("");
  const [popup, setPopup] = useState(false);
  const [selectedRow, setselectedRow] = useState("");
  const [selectedCol, setSelectedCol] = useState("");
  const [selectedColName, setSelectedColName] = useState("");
  const [teamMembers, setTeamMembers] = useState([]);
  const [taskImg, setTaskImg] = useState([]);
  const [displayImg, setdisplayImg] = useState("");
  const [allUsers, setAllUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const [columns, setColumns] = useState({});
  const [taskCompleted, setTaskCompleted] = useState(false);
  const [calender, setCalender] = useState(false);
  const [events, setEvents] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [popoverContent, setPopoverContent] = useState(null);
  const [taskData, setTaskData] = useState({});

  const taskModal = {
    assignTaskName: "",
    assignTaskDate: "",
    assignTaskMember: "",
    assignTaskDetail: "",
    progressTaskName: "",
    progressTaskDate: "",
    progressTaskMember: "",
    progressTaskDetail: "",
    completeTaskName: "",
    completeTaskDate: "",
    completeTaskMember: "",
    completeTaskDetail: "",
    testingTaskName: "",
    testingTaskDate: "",
    testingTaskMember: "",
    testingTaskDetail: "",
    value: "",
  };
  const [state, setState] = useState(taskModal);
  const TaskHeader = styled(Box)({
    height: "40px",
    // width: "336px",
    borderRadius: "6px",
    background: "#04B2AE",
    boxShadow: " 0px 4px 20px rgba(0, 0, 0, 0.16)",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    color: "#FFFFFF",
  });
  const CancelBtn = styled(Button)({
    height: "30px",
    width: "130px",
    borderRadius: "4px",
    padding: "8px 50px 8px 50px",
    color: "#A7A7A7",
    background: "#FFFFFF",
    "&:hover": {
      boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.16)",
      color: "#A7A7A7",
    },
    "&:active": {
      background: " #A7A7A7",
      color: "#FFFFFF",
    },
  });
  const SaveBtn = styled(Button)({
    height: "30px",
    width: "120px",
    borderRadius: "4px",
    padding: "8px 50px 8px 50px",
    color: "#F5F5F5",
    background: "#21A49E",
    "&:hover": {
      boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.16)",
      background: "#1B8C87",
      color: "#F5F5F5",
    },
    "&:active": {
      background: " #146E6A",
      color: "#F5F5F5",
    },
  });
  const Cards = styled(Box)({
    // height: "139px",
    // width: "312px",
    borderRadius: "6px",
    // background: "#FFFFFF",
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.08)",
  });
  const DragIcon = styled(Box)({
    margin: "5px",
    borderRadius: "2px",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    color: "grey",
    justifyContent: "center",
    "&:hover": {
      background: "#67748E",
      color: "#ffffff",
    },
  });
  const config = {
    bucketName: "infini8ai-images",
    dirName: "photos",
    error: "",
    region: "us-east-1",
    accessKeyId: "AKIA44SH2DQ6PSTL43O7",
    secretAccessKey: "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",
  };
  useEffect(() => {
    getallTask();
    getAllUser();
  }, []);
  useLayoutEffect(() => {
    if (selectedCol) {
      updateTask();
    }
  }, [selectedCol]);
  const getAllUser = async () => {
    let data = {
      company_code: localStorage.getItem("companyCode"),
    };
    let res = await getUsersByCompany(data);
    let updated =
      res.usersArr &&
      res.usersArr.map((item) => ({
        ...item,
        label: item.name,
        value: item.name,
      }));
    setAllUsers(updated);
  };
  const updateTask = async () => {
    let copyCols = JSON.parse(JSON.stringify(columns));
    let found = copyCols[selectedCol].items.find(
      (item) => item.journey !== selectedCol
    );
    if (found) {
      found.journey = selectedCol;
      await axios.post(`https://infini8ai.herokuapp.com/tasks`, {
        request: {
          method: "updateTask",
          data: {
            ...found,
          },
        },
      });
      console.log("Task Updated:", found);
      // setLoader(false);
      setSelectedCol("");
      getallTask();
    } else {
      setSelectedCol("");
      // setLoader(false);
    }
    setTaskCompleted(false);
  };
  const complete = () => {
    setTaskCompleted(!taskCompleted);
  };
  const onDragEnd = async (result, columns, setColumns) => {
    if (result.destination.droppableId === "completed") {
      complete();
    }
    if (!result.destination) return;
    const { source, destination } = result;
    setSelectedCol(destination.droppableId);

    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];
      const sourceItems = [...sourceColumn.items];
      const destItems = [...destColumn.items];
      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems,
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems,
        },
      });
    } else {
      setSelectedCol("");
      const column = columns[source.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems,
        },
      });
    }
  };
  const handleChange = (e) => {
    let { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  function randomColor() {
    let hex = Math.floor(Math.random() * 0xffffff);
    let color = "#" + hex.toString(16);
    return color;
  }
  const handleCalender = () => {
    setCalender((prev) => !prev);
  };
  const getallTask = async () => {
    let columnsFromBackend = {
      assigned: {
        name: "Assigned",
        items: [],
        icons: AddCompany,
      },
      inprogress: {
        name: "In Progress",
        items: [],
      },
      testing: {
        name: "Testing",
        items: [],
      },
      completed: {
        name: "Done",
        items: [],
      },
    };
    let data = {
      company_code: localStorage.getItem("companyCode"),
    };
    let dataArr = [];

    let res = await getTasksByCompany(data);
    let inprogress = [];
    let completed = [];
    let assigned = [];
    let testing = [];
    let response = res.tasksArr;
    response.forEach((el) => {
      if (el.journey === "assigned") {
        assigned.push(el);
      } else if (el.journey === "inprogress") {
        inprogress.push(el);
      } else if (el.journey === "testing") {
        testing.push(el);
      } else if (el.journey === "completed") {
        completed.push(el);
      }
    });
    columnsFromBackend.inprogress.items = inprogress;
    columnsFromBackend.completed.items = completed;
    columnsFromBackend.assigned.items = assigned;
    columnsFromBackend.testing.items = testing;

    setColumns(columnsFromBackend);
    res?.tasksArr.map((item) => {
      return dataArr.push({
        title: item.task,
        projects: item.projects,
        members: item.members,
        start: item.startDate,
        end: item.endDate,
        backgroundColor: randomColor(),
      });
    });

    setEvents(dataArr);
  };
  const uploadImage = async (item) => {
    try {
      let { location } = await S3FileUpload.uploadFile(item, config);
      return location;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
  const saveAllTask = async (taskName) => {
    // Set isLoading to true to show the loader
    setIsLoading(true);

    try {
      const {
        assignTaskName,
        assignTaskDate,
        assignTaskDetail,
        progressTaskName,
        progressTaskDate,
        progressTaskDetail,
        completeTaskName,
        completeTaskDate,
        completeTaskDetail,
        testingTaskName,
        testingTaskDate,
        testingTaskDetail,
      } = state;

      if (
        taskName === "assigned" &&
        [assignTaskName, assignTaskDate, selectedUser].includes("")
      ) {
        toast.error("Please Enter All Fields", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        const apiPromises = taskImg.map(uploadImage);
        const results = await Promise.all(apiPromises);

        let data = {
          task:
            taskName === "assigned"
              ? assignTaskName
              : taskName === "inprogress"
              ? progressTaskName
              : taskName === "testing"
              ? testingTaskName
              : completeTaskName,
          members: state.value,
          journey: taskName,
          date:
            taskName === "assigned"
              ? assignTaskDate
              : taskName === "inprogress"
              ? progressTaskDate
              : taskName === "testing"
              ? testingTaskDate
              : completeTaskDate,
          details:
            taskName === "assigned"
              ? assignTaskDetail
              : taskName === "inprogress"
              ? progressTaskDetail
              : taskName === "testing"
              ? testingTaskDetail
              : completeTaskDetail,
          deadline:
            taskName === "assigned"
              ? assignTaskDate
              : taskName === "inprogress"
              ? progressTaskDate
              : taskName === "testing"
              ? testingTaskDate
              : completeTaskDate,
          company_code: localStorage.getItem("companyCode"),
          imageUrl: results,
          assignedBy: userName,
        };

        await saveTask(data);
        toast.success(
          `${editShow ? "Task Edit Successfully" : "Task Added Successfully"}`,
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );

        // Clear form fields and reset states
        setState((prevState) => ({
          ...prevState,
          assignTaskName: "",
          assignTaskDate: "",
          assignTaskDetail: "",
          value: "",
        }));
        setSelectedUser("");
        getallTask();
        setisEdit("");
        setassignTask("");
        setdisplayImg("");
        setTaskImg([]);
        seteditShow(false);
      }
    } catch (error) {
      // Handle any errors here
    } finally {
      // Set isLoading to false after the save operation (whether success or error)
      setIsLoading(false);
    }
  };
  const handleCancel = () => {
    setisEdit(!assignTask);
    setState((prevState) => ({
      ...prevState,
      assignTaskName: "",
      assignTaskDate: "",
      assignTaskMember: "",
      assignTaskDetail: "",
    }));
    setSelectedUser("");
  };
  const assignTaskDetails = (items, columname) => {
    setselectedRow(items);
    setPopup(true);
    setSelectedColName(columname);
  };
  const closeTaskPopup = () => {
    setPopup(false);
  };
  const handleImg = (e) => {
    const files = Array.from(e.target.files);

    let imgs = [];
    if (files.length > 0) {
      files.forEach((item) => {
        imgs.push(item);
      });
    }
    setTaskImg(imgs);
  };
  const onChange = (selectedOptions) => {
    setSelectedUser(selectedOptions);
    setState((prev) => ({ ...prev, value: selectedOptions }));
  };
  const OptionWithImageAndName = (props) => (
    <components.Option {...props}>
      <span className="px-2">
        {props.data.imageUrl && (
          <img
            src={props.data.imageUrl}
            alt="img"
            className=""
            style={{ width: "20px" }}
          />
        )}
      </span>
      {props.label}
    </components.Option>
  );
  const handleDateClick = (arg) => {
    setPopoverContent(`${arg.event?._def}`);
    setTaskData(arg.event?._def);
  };
  const handleEventMouseLeave = () => {
    setPopoverContent(null);
    setTaskData(null);
  };
  return (
    <>
      {isLoading && (
        <div
          style={{
            fontSize: "50px",
            position: "absolute",
            top: "50%",
            bottom: "50%",
            left: "50%",
            right: "50%",
            zIndex: "9999",
          }}>
          <Loader />
        </div>
      )}
      <DashboardLayout>
        <DashboardNavbar>
          <Grid container spacing={2}>
            <Grid item xs={1}>
              <img src={TaskImg} alt="img" />
            </Grid>
            <Grid
              item
              xs={9}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}>
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                My Tasks
              </Typography>
            </Grid>
            <Grid
              item
              xs={2}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}>
              <BackButton
                size="small"
                onClick={handleCalender}
                sx={{ textTransform: "capitalize" }}>
                View Calender
              </BackButton>
            </Grid>
          </Grid>
        </DashboardNavbar>
        <Grid
          container
          sx={{
            px: 3,
            mt: 5,
          }}>
          <DragDropContext
            onDragEnd={(result) => onDragEnd(result, columns, setColumns)}>
            {Object.entries(columns).map(([columnId, column], index) => {
              return (
                <Grid item sm={6} md={3} key={columnId}>
                  <TaskHeader sx={{ mx: 1 }}>
                    <Typography>{column.name}</Typography>
                    {/* {column?.icons && (
                      <Button
                        onClick={() => handleTask(columnId)}
                        sx={{ marginLeft: 8, marginRight: "80px" }}>
                        <Box
                          sx={{
                            ...commonStyles,
                            borderRadius: "16px",

                            "&:hover": {
                              bgcolor: "#EF9F57",
                              borderColor: "#EF9F57",

                              color: "#FFFFFF",
                            },
                          }}>
                          <LoupeIcon
                            sx={{
                              "&:hover": {
                                // background: "#FFFFFF",
                              },
                            }}
                          />
                        </Box>
                      </Button>
                    )} */}
                  </TaskHeader>
                  <Droppable droppableId={columnId} key={columnId}>
                    {(provided, snapshot) => {
                      return (
                        <div
                          className="task_card_body taskbody_scroll mx-2"
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          style={{
                            background: snapshot.isDraggingOver
                              ? "lightblue"
                              : "#E7F3F3",
                          }}>
                          {provided.placeholder}

                          {isEdit === columnId ? (
                            <div className="subTask_body mb-3">
                              <div className="text-end m-2  pt-4 px-2 d-flex justify-content-between align-items-center">
                                <img
                                  src={column.icons ? column.icons : CardImg}
                                  alt="img"
                                />
                                <label
                                  style={{
                                    fontSize: "11px",
                                  }}>
                                  Due Date:
                                </label>
                                <input
                                  type="date"
                                  style={{
                                    border: 0,
                                    background: "#F7F7F7",
                                    fontSize: "14px",
                                  }}
                                  className=" mx-2 px-2"
                                  name={
                                    isEdit === "assigned"
                                      ? "assignTaskDate"
                                      : isEdit === "inprogress"
                                      ? "progressTaskDate"
                                      : "completeTaskDate"
                                  }
                                  value={
                                    isEdit === "assigned"
                                      ? state.assignTaskDate
                                      : isEdit === "inprogress"
                                      ? state.progressTaskDate
                                      : state.completeTaskDate
                                  }
                                  onChange={handleChange}
                                />
                              </div>
                              <div className="text-center d-flex flex-column pt-3">
                                <input
                                  type="text"
                                  style={{
                                    border: 0,
                                    background: "#F7F7F7",
                                    fontSize: "14px",
                                  }}
                                  placeholder="Task"
                                  className="card_text_input mx-auto p-2"
                                  name={
                                    isEdit === "assigned"
                                      ? "assignTaskName"
                                      : isEdit === "inprogress"
                                      ? "progressTaskName"
                                      : isEdit === "testing"
                                      ? "testingTaskName"
                                      : "completeTaskName"
                                  }
                                  value={
                                    isEdit === "assigned"
                                      ? state.assignTaskName
                                      : isEdit === "inprogress"
                                      ? state.progressTaskName
                                      : isEdit === "testing"
                                      ? state.testingTaskName
                                      : state.completeTaskName
                                  }
                                  onChange={handleChange}
                                />

                                <textarea
                                  style={{
                                    border: 0,
                                    background: "#F7F7F7",
                                    height: "60px",
                                    fontSize: "14px",
                                  }}
                                  type="text"
                                  placeholder="Task Detail"
                                  className="card_text_input p-2 mt-2 mx-auto"
                                  name={
                                    isEdit === "assigned"
                                      ? "assignTaskDetail"
                                      : isEdit === "inprogress"
                                      ? "progressTaskDetail"
                                      : isEdit === "testing"
                                      ? "testingTaskDetail"
                                      : "completeTaskDetail"
                                  }
                                  value={
                                    isEdit === "assigned"
                                      ? state.assignTaskDetail
                                      : isEdit === "inprogress"
                                      ? state.progressTaskDetail
                                      : isEdit === "testing"
                                      ? state.testingTaskDetail
                                      : state.completeTaskDetail
                                  }
                                  onChange={handleChange}
                                />
                                {/* <Select
                                  value={selectedUser}
                                  isMulti
                                  name="members"
                                  onChange={onChange}
                                  options={allUsers}
                                  className="basic-multi-select"
                                  classNamePrefix="select Members"
                                  styles={{
                                    control: (baseStyles, state) => ({
                                      ...baseStyles,
                                      border: 0,
                                      margin: "12px",
                                      background: "#F7F7F7",
                                      fontSize: "14px",
                                    }),
                                  }}
                                /> */}

                                <Select
                                  value={selectedUser}
                                  isMulti
                                  name="members"
                                  onChange={onChange}
                                  options={allUsers}
                                  className="basic-multi-select"
                                  classNamePrefix="select Members"
                                  components={{
                                    Option: OptionWithImageAndName, // Use the custom option component
                                  }}
                                  styles={{
                                    control: (baseStyles, state) => ({
                                      ...baseStyles,
                                      border: 0,
                                      margin: "12px",
                                      background: "#F7F7F7",
                                      fontSize: "14px",
                                    }),
                                  }}
                                />

                                {/* <div
                                className="mt-2 mx-auto "
                                style={{
                                  width: "90%",
                                  display: "grid",
                                  fontSize: "14px",
                                }}>
                                <ReactAutocomplete
                                  style={{
                                    overflow: "scroll",
                                  }}
                                  inputProps={{
                                    placeholder: "Add Members",
                                    style: {
                                      boxSizing: "borderBox",
                                      color: "#000",
                                      width: "100%",
                                      height: "45px",
                                      outline: "none",
                                      // border: "0.5px solid #c0bebe",
                                      borderRadius: "4px",
                                      alignItems: "center",
                                      padding: "8px",
                                      border: 0,
                                      background: "#F7F7F7",
                                    },
                                  }}
                                  items={allUsers}
                                  shouldItemRender={(item, value) =>
                                    item?.name
                                      .toLowerCase()
                                      .indexOf(value.toLowerCase()) > -1
                                  }
                                  getItemValue={(item) => item.name}
                                  renderItem={(item, highlighted) => (
                                    <div
                                      key={item.id}
                                      style={{
                                        backgroundColor: highlighted
                                          ? "#eee"
                                          : "transparent",
                                        width: "100%",
                                        height: "40px",
                                        padding: "4px",
                                      }}>
                                      <div className="d-flex align-items-center">
                                        <img
                                          alt="Avatar"
                                          src={item.imageUrl}
                                          className="img-fluid "
                                          style={{
                                            borderRadius: "50%",
                                            alignItems: "center",
                                            resize: "contain",
                                            verticalAlign: "middle",
                                            width: "35px",
                                            height: "35px",
                                            padding: "5px",
                                          }}
                                        />
                                        {item.name}
                                      </div>
                                    </div>
                                  )}
                                  value={selectedUser}
                                  onChange={selectUser}
                                  onSelect={handleSelect}
                                />
                              </div> */}
                                <input
                                  type="file"
                                  className="mx-auto card_text_input mt-2"
                                  multiple
                                  style={{
                                    border: 0,
                                    background: "#F7F7F7",
                                    fontSize: "14px",
                                  }}
                                  onChange={handleImg}
                                />
                                <Stack direction="row" sx={{ mt: 3, mx: 1 }}>
                                  <CancelBtn
                                    onClick={handleCancel}
                                    sx={{ mx: 1 }}>
                                    Cancel
                                  </CancelBtn>
                                  <SaveBtn
                                    onClick={() => saveAllTask(columnId)}
                                    style={{ marginLeft: "-20px" }}>
                                    Done
                                  </SaveBtn>
                                </Stack>
                              </div>
                            </div>
                          ) : null}

                          {column.items &&
                            column.items?.map((item, index) => {
                              return (
                                <>
                                  <Draggable
                                    key={item._id}
                                    draggableId={item._id}
                                    index={index}>
                                    {(provided) => {
                                      return (
                                        <>
                                          <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={{
                                              paddingInline: "5px",
                                              userSelect: "none",
                                              margin: "0 0 8px 0",
                                              minHeight: "50px",

                                              color: "white",
                                              ...provided.draggableProps.style,
                                            }}>
                                            <Cards
                                              style={{ position: "relative" }}
                                              className={`${
                                                columnId === "completed"
                                                  ? "task_comp_tab"
                                                  : "task_show_tab  mb-3"
                                              } fw-bold fs-6`}
                                              onClick={(e) =>
                                                assignTaskDetails(
                                                  item,
                                                  columnId
                                                )
                                              }>
                                              <div className="row   justify-content-between">
                                                <div className="col-8">
                                                  <span className="px-2 ">
                                                    {item.imageUrl && (
                                                      <img
                                                        src={
                                                          item.imageUrl[0]
                                                            ? item.imageUrl[0]
                                                            : uploadimage
                                                        }
                                                        alt="img"
                                                        className="img-fluid pt-2 "
                                                        style={{
                                                          height: "32px",
                                                        }}
                                                      />
                                                    )}
                                                  </span>
                                                </div>
                                                <div className="col-4 d-none">
                                                  <Stack alignItems="center">
                                                    <DragIcon>
                                                      <DragIndicatorIcon />
                                                    </DragIcon>
                                                  </Stack>
                                                </div>
                                              </div>
                                              <div
                                                style={{
                                                  color: "#67748E",
                                                  marginLeft: 10,
                                                  marginRight: 10,
                                                }}
                                                className="">
                                                <span>
                                                  <CheckCircleOutlineIcon
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                  />
                                                </span>

                                                <span
                                                  className="my-1  w-100 fw-bold p-1"
                                                  style={{
                                                    overflowWrap: "break-word",
                                                    overflow: "hidden",
                                                    fontSize: "12px",
                                                  }}>
                                                  {item.task}
                                                </span>
                                              </div>

                                              <div className="row  d-flex flex-end ">
                                                <div
                                                  className=" align-items-center "
                                                  style={{ color: "#67748E" }}>
                                                  <p
                                                    className="   task_asign_name  "
                                                    style={{
                                                      marginLeft: "16px",
                                                      width: "66px",
                                                      marginTop: "6px",
                                                    }}>
                                                    {" "}
                                                    Assigned to:
                                                  </p>
                                                  <div className="d-flex align-items-center">
                                                    <p
                                                      style={{
                                                        marginTop: "",
                                                      }}>
                                                      <img
                                                        // src={
                                                        //   item.members[0]
                                                        //     .imageUrl
                                                        //     ? item.members[0]
                                                        //         .imageUrl
                                                        //     : emp
                                                        // }
                                                        src={
                                                          item.members?.[0]
                                                            ?.imageUrl ?? emp
                                                        }
                                                        alt=""
                                                        className="img-fluid "
                                                        style={{
                                                          borderRadius: "50%",
                                                          alignItems: "center",
                                                          resizeMode: "contain",
                                                          verticalAlign:
                                                            "middle",
                                                          width: "40px",
                                                          height: "40px",
                                                          padding: "5px",
                                                          marginRight: "6px",
                                                          marginLeft: "10px",
                                                        }}
                                                      />
                                                    </p>

                                                    <p
                                                      className=""
                                                      style={{
                                                        fontSize: "12px",
                                                        fontWeight: "600",
                                                      }}>
                                                      {item.members[0]?.name}
                                                    </p>
                                                  </div>
                                                  <Stack
                                                    sx={{
                                                      position: "relative",
                                                    }}>
                                                    <p
                                                      className=""
                                                      style={{
                                                        fontSize: "12px",
                                                        fontWeight: "600",
                                                        display: "flex",
                                                        marginRight: "10px",
                                                        flexDirection:
                                                          "row-reverse",
                                                      }}>
                                                      {item.projects &&
                                                        item.projects[0]}
                                                    </p>
                                                  </Stack>
                                                  {item.members?.length > 1 && (
                                                    <div className="d-flex">
                                                      <p
                                                        className="   task_asign_name  "
                                                        style={{
                                                          display: "none",
                                                        }}>
                                                        Collaborators:
                                                      </p>
                                                      <p
                                                        className="d-flex flex-row"
                                                        style={
                                                          {
                                                            // marginTop: "-18px",
                                                          }
                                                        }>
                                                        {Array.isArray(
                                                          item.members
                                                        ) &&
                                                          item.members.length >
                                                            1 &&
                                                          item.members
                                                            .slice(1)
                                                            ?.map((row) => (
                                                              <img
                                                                src={
                                                                  row.imageUrl
                                                                }
                                                                alt=""
                                                                className="img-fluid "
                                                                style={{
                                                                  borderRadius:
                                                                    "50%",
                                                                  alignItems:
                                                                    "center",
                                                                  resizeMode:
                                                                    "contain",
                                                                  verticalAlign:
                                                                    "middle",
                                                                  width: "20px",
                                                                  height:
                                                                    "20px",
                                                                  padding:
                                                                    "2px",
                                                                }}
                                                              />
                                                            ))}
                                                      </p>
                                                    </div>
                                                  )}
                                                </div>
                                                <div
                                                  className=""
                                                  style={{ color: "#67748E" }}>
                                                  <span
                                                    className="  p-2 task_asign_name "
                                                    style={{
                                                      float: "right",
                                                      position: "absolute",
                                                      top: "0",
                                                      right: "0",
                                                    }}>
                                                    Due:{" "}
                                                    {item?.endDate
                                                      ? item?.endDate
                                                      : item.deadline}
                                                  </span>
                                                </div>
                                              </div>
                                            </Cards>
                                          </div>
                                        </>
                                      );
                                    }}
                                  </Draggable>
                                </>
                              );
                            })}
                        </div>
                      );
                    }}
                  </Droppable>
                </Grid>
              );
            })}
          </DragDropContext>
          {taskCompleted ? (
            <div className="bird-container bird-container--one">
              <div className="bird bird--one"></div>
            </div>
          ) : null}
        </Grid>
        <></>
        {popup && (
          <TaskDetailPopup
            selectedColName={selectedColName}
            popup={popup}
            closeTaskPopup={closeTaskPopup}
            selectedRow={selectedRow}
            getallTask={getallTask}
            teamMembers={teamMembers}
            displayImg={displayImg}
            allUsers={allUsers}
          />
        )}
        <Dialog
          open={calender}
          onClose={handleCalender}
          fullWidth
          fullScreen
          TransitionComponent={Transition}>
          <DialogContent>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCalender}
              aria-label="close">
              <CloseIcon />
            </IconButton>
            <Stack>
              {popoverContent && (
                <Stack
                  sx={{
                    position: "relative",
                    backgroundColor: "#fff",
                    border: "1px solid #fff",
                    borderRadius: "4px",
                    padding: "10px",
                    top: "80%",
                    left: "35%",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                    zIndex: "1000",
                    width: "fit-content",
                  }}
                  spacing={1}
                  direction={"row"}>
                  <Stack direction={"row"}>
                    <Typography sx={{ fontWeight: "bold" }} variant="p">
                      Project :
                    </Typography>
                    <Typography variant="p">
                      {taskData.extendedProps.projects[0]}
                    </Typography>
                  </Stack>
                  <Typography sx={{ fontWeight: "bold" }} variant="p">
                    Members :
                  </Typography>
                  {taskData.extendedProps.members.map((item) => {
                    return (
                      <Stack direction={"row"}>
                        <img
                          src={item.imageUrl}
                          alt=""
                          height={20}
                          width={20}
                          style={{ borderRadius: "8px" }}></img>
                        <Typography variant="p">{item.label}</Typography>
                      </Stack>
                    );
                  })}
                </Stack>
              )}
              <Body>
                <FullCalendar
                  firstDay={1}
                  locale="en"
                  height={700}
                  plugins={[dayGridPlugin]}
                  events={events}
                  eventMouseEnter={(e) => handleDateClick(e)}
                  eventMouseLeave={(e) => handleEventMouseLeave(e)}
                />
              </Body>
            </Stack>
          </DialogContent>
        </Dialog>
      </DashboardLayout>
    </>
  );
};

export default Task;
