import React from "react";
import { Grid, Typography } from "@mui/material";
import { Box, Stack, styled } from "@mui/system";
import Profile from "../../assets/images/dashboard/rectangle.png";
import emp from "../../assets/images/project/emp.svg";
import { TextStyle } from "../dashboard/style";

window.Buffer = window.Buffer || require("buffer").Buffer;

const ProfileFields = ({ userData }) => {
  const ProfileCard = styled(Box)({
    height: "150px",
    marginTop: "5px",
    borderRadius: "8px",
    background: "#FFFFFF",
    display: "flex",
    alignItems: "center",
    padding: "20px",
    color: "#67748E",
  });
  const TextFieldCard = styled(Box)({
    height: "60px",
    borderRadius: "4px",
    background: "#FFFFFF",
    display: "flex",
    alignItems: "center",
  });
  const Grids = styled(Grid)({
    paddingRight: "35px",
  });
  const Title = styled(Typography)({
    marginLeft: "20px",
    fontSize: "14px",
    color: "#1B8C87",
    backgroundColor: "#EDF4F4",
    maxWidth: "190px",
    padding: "1px",
  });

  return (
    <Grid container spacing={1} sx={{ mt: 3, px: 10 }}>
      <Grids item xs={12} md={6}>
        <ProfileCard>
          <img
            src={userData?.imageUrl ? userData?.imageUrl : emp}
            alt="profile"
            height="120px"
            width="120px"
            style={{ borderRadius: 4 }}></img>
          <Stack sx={{ display: "flex", alignItems: "center" }}>
            <TextStyle sx={{ m: 2, fontWeight: "bold" }}>
              Profile Image
            </TextStyle>
            <TextStyle sx={{ mx: 2 }}>
              {/* Filename:
              {userData.imageUrl ? userData.imageUrl : "profile012.jpg"} */}
            </TextStyle>
          </Stack>
        </ProfileCard>
      </Grids>
      <Grid item xs={12} md={5} sx={{ marginLeft: 5 }}>
        <Title>Employee name</Title>
        <TextFieldCard sx={{ pl: 2, mt: -1.5 }}>
          <TextStyle sx={{ color: "#A7A7A7", fontSize: "15px" }}>
            {userData?.name}
          </TextStyle>
        </TextFieldCard>

        <Title>Email</Title>
        <TextFieldCard sx={{ pl: 2, mt: -1.5 }}>
          <TextStyle sx={{ color: "#A7A7A7", fontSize: "15px" }}>
            {userData?.email}
          </TextStyle>
        </TextFieldCard>
      </Grid>
      <Grids item xs={12} md={6}>
        <Title>Date of joining</Title>
        <TextFieldCard sx={{ pl: 2, mt: -1.5 }}>
          <TextStyle sx={{ color: "#A7A7A7", fontSize: "15px" }}>
            {userData?.date}
          </TextStyle>
        </TextFieldCard>
      </Grids>
      <Grid item xs={12} md={5} sx={{ marginLeft: 5 }}>
        <Title>Designation</Title>
        <TextFieldCard sx={{ pl: 2, mt: -1.5 }}>
          <TextStyle sx={{ color: "#A7A7A7", fontSize: "15px" }}>
            {userData?.designation}
          </TextStyle>
        </TextFieldCard>
      </Grid>
      <Grids item xs={12} md={6}>
        <Title>Permanent address</Title>
        <TextFieldCard sx={{ pl: 2, mt: -1.5 }}>
          <TextStyle sx={{ color: "#A7A7A7", fontSize: "15px" }}>
            {userData?.permanentAddress}
          </TextStyle>
        </TextFieldCard>
      </Grids>
      <Grid item xs={12} md={5} sx={{ marginLeft: 5 }}>
        <Title>Temporary address</Title>
        <TextFieldCard sx={{ pl: 2, mt: -1.5 }}>
          <TextStyle sx={{ color: "#A7A7A7", fontSize: "15px" }}>
            {userData?.temporaryAddress}
          </TextStyle>
        </TextFieldCard>
      </Grid>
      <Grids item xs={12} md={6}>
        <Title>Contact</Title>
        <TextFieldCard sx={{ pl: 2, mt: -1.5 }}>
          <TextStyle sx={{ color: "#A7A7A7", fontSize: "15px" }}>
            {" "}
            {userData?.phoneNo}
          </TextStyle>
        </TextFieldCard>
      </Grids>
      <Grid item xs={12} md={5} sx={{ marginLeft: 5 }}>
        <Title> Emergency Contact</Title>
        <TextFieldCard sx={{ pl: 2, mt: -1.5 }}>
          <TextStyle sx={{ color: "#A7A7A7", fontSize: "15px" }}>
            {userData?.emergencyNo}
          </TextStyle>
        </TextFieldCard>
      </Grid>
      <Grids item xs={12} md={6}>
        <Title>Gender</Title>
        <TextFieldCard sx={{ pl: 2, mt: -1.5 }}>
          <TextStyle sx={{ color: "#A7A7A7", fontSize: "15px" }}>
            {userData?.gender}
          </TextStyle>
        </TextFieldCard>
      </Grids>
      <Grid item xs={12} md={5} sx={{ marginLeft: 5 }}>
        <Title>Company Code</Title>
        <TextFieldCard sx={{ pl: 2, mt: -1.5 }}>
          <TextStyle sx={{ color: "#A7A7A7", fontSize: "15px" }}>
            {userData?.company_code}
          </TextStyle>
        </TextFieldCard>
      </Grid>
    </Grid>
  );
};

export default ProfileFields;
