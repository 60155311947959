import { useState } from "react";
import "./team.css";
import { useEffect } from "react";
import { Box, styled } from "@mui/system";
import { Button, CircularProgress, Grid, Stack } from "@mui/material";
import TeamLogo from "../../assets/images/team/teams.png";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import ReplyAllOutlinedIcon from "@mui/icons-material/ReplyAllOutlined";
import TeamMember from "./TeamMember";
import DashboardLayout from "../../layouts/Dashboard";
import DashboardNavbar from "../../layouts/Navbars/DashboardNavbar";
import { getTeamsByMember } from "../../apiservices/team";
import NoTeam from "../../assets/images/noteam.svg";
import { TextStyle, Title } from "../dashboard/style";
export const BackButton = styled(Button)({
  borderRadius: "4px",
  padding: "4px 8px 4px 8px",
  fontStyle: "normal",
  color: "#67748E",
  border: "1px solid #67748E",
  fontFamily: "Dosis",
  "&:hover": {
    background: "#67748E",
    color: "#FFFFFF",
  },
  "&:active": {
    background: "#586278",
    color: "#FFFFFF",
  },
});
const Team = () => {
  const [state, setState] = useState({
    teamData: [],
    selected: false,
    members: [],
  });
  const selectedTeam = (data) => {
    setState((prev) => ({
      ...prev,
      selected: !prev.selected,
      members: data.members,
      loader: false,
    }));
  };
  const TeamCard = styled(Box)({
    height: "100px",
    maxWidth: "450px",
    borderRadius: "8px",
    borderLeft: "2px solid transparent",
    background: "#F5F5F5",
    margin: "20px",
    transition: "0.5s",
    "&:hover": {
      background: "#FFFFFF",
      borderLeft: "3px solid #04B2AE",
      boxShadow: "0px 4px 10px 0px #EBEBEB",
    },
    "&:active": {
      background: "#F5F5F5",
      borderLeft: "0px solid #04B2AE",
      boxShadow: "0px 0px 0px 0px #EBEBEB ",
    },
  });
  const DragIcon = styled(Box)({
    margin: "5px",
    borderRadius: "2px",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    color: "grey",
    justifyContent: "center",
    "&:hover": {
      background: "#67748E",
      color: "#ffffff",
    },
  });

  const getAllTeam = async () => {
    setState((prev) => ({ ...prev, loader: !prev.loader }));

    let data = {
      member: localStorage.getItem("userEmail"),
      company_code: localStorage.getItem("companyCode"),
    };
    let res = await getTeamsByMember(data);
    if (res) {
      let response = res.teamsArr;
      setState({ teamData: response });
      setState((prev) => ({ ...prev, loader: !prev.loader }));
    }
    setState((prev) => ({ ...prev, loader: !prev.loader }));
  };
  useEffect(() => {
    getAllTeam();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar>
        <Stack
          sx={{ height: "100px", mx: 1 }}
          alignItems={"center"}
          direction={"row"}
          justifyContent={"space-between"}>
          <Stack>
            <img src={TeamLogo} alt="img" />
          </Stack>
          <Stack>
            <Title variant="h6" sx={{ fontWeight: "bold" }}>
              Teams
            </Title>
          </Stack>
          <Stack>
            <BackButton
              size="small"
              hidden={!state.selected}
              onClick={selectedTeam}>
              <ReplyAllOutlinedIcon />
            </BackButton>
          </Stack>
        </Stack>
      </DashboardNavbar>
      {!state.selected ? (
        <Grid container sx={{ m: 3 }}>
          {state.teamData && state.teamData.length !== 0 ? (
            state.teamData.map((item) => (
              <Grid item xs={12} md={6} onClick={() => selectedTeam(item)}>
                <TeamCard>
                  <Stack direction="row" justifyContent="space-between">
                    <Stack direction="row" alignItems="center">
                      <Stack justifyContent="center" sx={{ m: 1 }}>
                        <img
                          src={item.imageUrl ? item.imageUrl : TeamLogo}
                          alt="one"
                          // width="80px"
                          // height="80px"
                          style={{ borderRadius: 2 }}
                        />
                      </Stack>
                      <Stack sx={{ mx: 3 }}>
                        <TextStyle sx={{ color: "#67748E" }}>
                          {item.teamName}
                        </TextStyle>
                      </Stack>
                    </Stack>

                    <Stack direction="column" sx={{ alignItems: "end" }}>
                      <DragIcon>
                        <DragIndicatorIcon />
                      </DragIcon>

                      {/* <img src={Img} alt="" /> */}
                    </Stack>
                  </Stack>
                </TeamCard>
              </Grid>
            ))
          ) : (
            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "center" }}>
              {state.loader ? (
                <Box sx={{ display: "flex" }}>
                  <CircularProgress color="success" />
                </Box>
              ) : (
                <img src={NoTeam} alt="" height="350px" width="400px" />
              )}
            </Grid>
          )}
        </Grid>
      ) : (
        <TeamMember selectedRow={state.members} />
      )}
    </DashboardLayout>
  );
};

export default Team;
