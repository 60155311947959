import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import { navbarMobileMenu } from "./styles";
import MDBox from "../../../components/MDBox";

import { useMaterialUIController, setMiniSidenav } from "../../../context";
import { Box, Grid, styled } from "@mui/material";

export const CardHeader = styled(Box)({
  borderRadius: "8px",
  background: " #FFFFFF",
  height: "75px",
  margin: "0 auto",
  boxShadow: "0px 4px 30px 0px #0000001F",
  width: "97%",
  display: "flex",
  alignItems: "center",
});

const Navbar = styled(AppBar)({
  background: "#FF9637 !important",
  borderRadius: "10px",
  height: "100px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

function DashboardNavbar({ light, children }) {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav } = controller;

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);

  return (
    <Navbar position="static" color="inherit">
      <CardHeader>
        <Grid container spacing={1}>
          <Grid item xs={window.innerWidth > 992 ? 12 : 11}>
            {children}
          </Grid>
          {window.innerWidth > 992 ? null : (
            <Grid
              item
              xs={1}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <MDBox color={light ? "white" : "inherit"}>
                <IconButton
                  size="small"
                  disableRipple
                  color="inherit"
                  sx={navbarMobileMenu}
                  onClick={handleMiniSidenav}
                >
                  <Icon sx={{ color: "black" }} fontSize="medium">
                    {miniSidenav ? "menu_open" : "menu"}
                  </Icon>
                </IconButton>
              </MDBox>
            </Grid>
          )}
        </Grid>
      </CardHeader>
    </Navbar>
  );
}

export default DashboardNavbar;
