import React from "react";
import qurbanimain from "../../../assets/images/qurbani/qurbanimain.svg";
import qsurvey from "../../../assets/images/qurbani/qsurvey.png";
import intheading from "../../../assets/images/qurbani/intheading.png";
import intdetail from "../../../assets/images/qurbani/intdetail.png";
import userperson from "../../../assets/images/qurbani/userperson.svg";
import users from "../../../assets/images/qurbani/users.png";
import user1 from "../../../assets/images/qurbani/user1.png";
import user2 from "../../../assets/images/qurbani/user2.png";
import user4 from "../../../assets/images/qurbani/user4.png";
import Qurbanimobile1 from "./Qurbanimobile1";

const Qurbanimobile = () => {
  let url = "https://infini8ai-images.s3.amazonaws.com/qurbani";
  return (
    <div>
      <div className="position-relative ">
        <div className="  w-100">
          <div className=" position-relative">
            <img
              src={qurbanimain}
              className="position-absolute  img-fluid w-100 "
            />
            <div
              className="position-absolute text-center "
              style={{
                padding: "10px",
                width: "100%",
                height: "100%",
                textAlign: "center",
              }}
            >
              <img
                src={`${url}/qurbanilogo.png`}
                className=" mt-5  img-fluid "
              />
              <div className="qurbani_text">
                <p className="fs-1 fw-bold text-white">Qurbani App</p>
                <p className="fs-3 text-white mt-3 lh-sm">
                  A Online Management System Powered By Qurbani App
                </p>
              </div>
              <div className=" d-flex justify-content-center ">
                <img src={`${url}/qcard2.png`} className="img-fluid " />
              </div>
              <div className="mt-3 mb-5">
                <img src={`${url}/probstatement.svg`} className="img-fluid " />
              </div>
              <div id="survey">
                <div
                  style={{ background: "#474343" }}
                  className=" row mt-4 pb-5 pt-3"
                >
                  <div className="text-center ">
                    <div className="qurbani_text">
                      <p className="fs-3 text-white mt-3 lh-sm">
                        The survey was conducted over 50 Farms with 10 Farms
                        owners and 80,000 buyers.
                      </p>
                    </div>
                    <img src={qsurvey} className=" p-2 img-fluid   " />
                  </div>
                </div>
              </div>
              <div id="interview">
                <div style={{ background: "#5F5E5E" }} className=" row  pt-3">
                  <div className="text-center ">
                    <div className="qurbani_text">
                      <p className="fs-3 text-white mt-3 lh-sm">
                        Interviews conducted with a group of people including:
                      </p>

                      <p className="fs-5 text-white  lh-sm">
                        {" "}
                        Farms owners,sellers & buyers.
                      </p>
                    </div>
                    <div className="mt-5">
                      <img src={intheading} className=" img-fluid  w-75 " />
                      <img src={intdetail} className=" img-fluid  mt-5 " />
                      <img
                        src={`${url}/intobj.png`}
                        className=" img-fluid mt-5 w-75 "
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div id="userdetail">
                <div
                  style={{ background: "#DEDEDE" }}
                  className=" row pb-5 pt-3"
                >
                  <div>
                    <img src={userperson} className="img-fluid " />
                    <img src={users} className="img-fluid mt-5 w-75" />
                  </div>
                </div>
              </div>
              <div id="Userdetail1">
                <div
                  style={{
                    background: "#E8E6E6",
                    padding: "10px",
                    height: "100%",
                    textAlign: "start",
                  }}
                  className="pt-5 pb-5 row"
                >
                  <div className=" ">
                    <img src={user1} className="  img-fluid   " />
                  </div>

                  <div className="row"  style={{marginLeft:"0px"}}>
                    <div className="col-md-5">
                      <div>
                        <div className="qurbani_texts">
                          <p className="fw-bold fs-3 text-success ">About</p>
                        </div>
                        <div className="qurbani_text  mt-4">
                          <p>
                            Mr. Ijaaz Ahmed is a thirty two year. He has four
                            farms in Rawalpindi. He manages and oversees his two
                            farms in Rawalpindi.
                          </p>
                          <p className="mt-4">
                            He wishes to do his work without moving around and
                            he wants easier way to accomplish his tasks without
                            visiting each farm.
                          </p>
                          <p className="mt-4">
                            He appreciates technology as it is more centralized
                            management system where, he could do most of his
                            work from a single platform without moving around.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row"  style={{marginLeft:"0px"}}>
                    <div className="col-md-12">
                      <div id="questionAnswering">
                        <div className="qurbani_text">
                          <p className="fs-3 fw-bold text-success mt-4">
                            Goals
                          </p>
                        </div>
                        <div className="qurbani_text mt-4">
                          <div>
                            <ul className="d-flex ml-3mt-4 ">
                              <li
                                className="list-unstyled ml-1"
                                style={{ fontFamily: "Poppins" }}
                              >
                                <p>
                                  Manage animal farms under my supervision from
                                  a single platform.
                                </p>
                              </li>
                            </ul>
                            <ul className="d-flex ml-3 mt-4">
                              <li
                                className="list-unstyled ml-1"
                                style={{ fontFamily: "Poppins" }}
                              >
                                <p>
                                  Don’t have to travel for day to day routine
                                  work.
                                </p>
                              </li>
                            </ul>
                            <ul className="d-flex  mt-4">
                              <li
                                className="list-unstyled ml-1"
                                style={{ fontFamily: "Poppins" }}
                              >
                                <p>
                                  Make life more relaxing by reducing work
                                  stress.
                                </p>
                              </li>
                            </ul>
                          </div>
                        </div>

                        <div className="qurbani_text">
                          <p className="fs-3 fw-bold text-success mt-4">
                            {" "}
                            Pain Points
                          </p>
                        </div>
                        <div className="qurbani_text">
                          <div>
                            <ul className="d-flex ml-3 mt-4">
                              <li className="list-unstyled ml-1">
                                <p>
                                  Visiting every farm physically even for
                                  smaller routine work.
                                </p>
                              </li>
                            </ul>
                            <ul className="d-flex ml-3 mt-4">
                              <li className="list-unstyled ml-1">
                                <p>
                                  {" "}
                                  More expensive to visit every farm per day.
                                </p>
                              </li>
                            </ul>
                            <ul className="d-flex ml-3 mt-4 ">
                              <li className="list-unstyled ml-1">
                                <p>
                                  Mentally keeping track of everything happening
                                  around.
                                </p>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="Userdetail2">
                <div
                  style={{
                    background: "#F2EFEF",
                    padding: "10px",
                    height: "100%",
                    textAlign: "start",
                  }}
                  className="pt-5 pb-5 row"
                >
                  <div className=" ">
                    <img src={user2} className="  img-fluid   " />
                  </div>

                  <div className="row"  style={{marginLeft:"0px"}}>
                    <div className="col-md-5">
                      <div>
                        <div className="qurbani_texts">
                          <p className="fw-bold fs-3 text-success ">About</p>
                        </div>
                        <div className="qurbani_text  mt-4">
                          <p>
                            Oversee day to day farms progress from a single
                            platform.
                          </p>
                          <p className="mt-4">
                            He considers his farms reputataion and progress a
                            very important task and in order to do so he has to
                            physically engage himself with employee, in order to
                            review their progress and failures all the time.
                          </p>
                          <p className="mt-4">
                            Mr. Kamran is looking for a solution which could
                            help him oversee farms day to day progress and act
                            accordingly based on the stats and engage the staff.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row"  style={{marginLeft:"0px"}}>
                    <div className="col-md-12">
                      <div id="questionAnswering">
                        <div className="qurbani_text">
                          <p className="fs-3 fw-bold text-success mt-4">
                            Goals
                          </p>
                        </div>
                        <div className="qurbani_text mt-4">
                          <div>
                            <ul className="d-flex ml-3mt-4 ">
                              <li
                                className="list-unstyled ml-1"
                                style={{ fontFamily: "Poppins" }}
                              >
                                <p>
                                  Oversee day to day farms progress from a
                                  single platform.
                                </p>
                              </li>
                            </ul>
                            <ul className="d-flex ml-3 mt-4">
                              <li
                                className="list-unstyled ml-1"
                                style={{ fontFamily: "Poppins" }}
                              >
                                <p>
                                  Minimize the chances of daily physical
                                  interaction.
                                </p>
                              </li>
                            </ul>
                            <ul className="d-flex  mt-4">
                              <li
                                className="list-unstyled ml-1"
                                style={{ fontFamily: "Poppins" }}
                              >
                                <p>
                                  Get to review to the point stats and act
                                  accordingly based on the data
                                </p>
                              </li>
                            </ul>
                          </div>
                        </div>

                        <div className="qurbani_text">
                          <p className="fs-3 fw-bold text-success mt-4">
                            {" "}
                            Pain Points
                          </p>
                        </div>
                        <div className="qurbani_text">
                          <div>
                            <ul className="d-flex ml-3 mt-4">
                              <li className="list-unstyled ml-1">
                                <p>
                                  Time it takes to interact with the staff in
                                  order to get progress reports.
                                </p>
                              </li>
                            </ul>
                            <ul className="d-flex ml-3 mt-4">
                              <li className="list-unstyled ml-1">
                                <p>
                                  {" "}
                                  Not being sure about the stats and reports
                                  being 100 percent accurate.
                                </p>
                              </li>
                            </ul>
                            <ul className="d-flex ml-3 mt-4 ">
                              <li className="list-unstyled ml-1">
                                <p>
                                  Not being able to get the data right on the
                                  spot when needed.
                                </p>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="Userdetail3">
                <div
                  style={{
                    background: "#F2EFEF",
                    padding: "10px",
                    height: "100%",
                    textAlign: "start",
                  }}
                  className="pt-5 pb-5 row"
                >
                  <div className=" ">
                    <img src={`${url}/user3.png`} className="  img-fluid   " />
                  </div>

                  <div className="row"  style={{marginLeft:"0px"}}>
                    <div className="col-md-5">
                      <div>
                        <div className="qurbani_texts">
                          <p className="fw-bold fs-3 text-success ">About</p>
                        </div>
                        <div className="qurbani_text  mt-4">
                          <p>
                            Mr. ihtisham ul haq is a twenty seven year old farm
                            owner.
                          </p>
                          <p className="mt-4">
                            He has five animal farms. He is doing farming
                            business for five years and get tired of daily busy
                            routine. It’s very dificult for Mr. ihtisham ul haq
                            to visit farms daily.
                          </p>
                          <p className="mt-4">
                            Samin and other looking for a solution which could
                            help them manage their farms from a single platform
                            in a simple yet efficient manner.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row"  style={{marginLeft:"0px"}}>
                    <div className="col-md-12">
                      <div id="questionAnswering">
                        <div className="qurbani_text">
                          <p className="fs-3 fw-bold text-success mt-4">
                            Goals
                          </p>
                        </div>
                        <div className="qurbani_text mt-4">
                          <div>
                            <ul className="d-flex ml-3mt-4 ">
                              <li
                                className="list-unstyled ml-1"
                                style={{ fontFamily: "Poppins" }}
                              >
                                <p>
                                  Managing all of the farms in a better way
                                  rather than using traditional method.
                                </p>
                              </li>
                            </ul>
                            <ul className="d-flex ml-3 mt-4">
                              <li
                                className="list-unstyled ml-1"
                                style={{ fontFamily: "Poppins" }}
                              >
                                <p>
                                  Being able to comunicate with sellers
                                  community and buyers digitally.
                                </p>
                              </li>
                            </ul>
                            <ul className="d-flex  mt-4">
                              <li
                                className="list-unstyled ml-1"
                                style={{ fontFamily: "Poppins" }}
                              >
                                <p>
                                  Be more efficient and way to run their
                                  bussiness.
                                </p>
                              </li>
                            </ul>
                          </div>
                        </div>

                        <div className="qurbani_text">
                          <p className="fs-3 fw-bold text-success mt-4">
                            {" "}
                            Pain Points
                          </p>
                        </div>
                        <div className="qurbani_text">
                          <div>
                            <ul className="d-flex ml-3 mt-4">
                              <li className="list-unstyled ml-1">
                                <p>
                                  Farms progress is getting stop due to daily
                                  busy routine.
                                </p>
                              </li>
                            </ul>
                            <ul className="d-flex ml-3 mt-4">
                              <li className="list-unstyled ml-1">
                                <p>
                                  {" "}
                                  Being overwhelmed with a bunch of tasks and
                                  keeping records manually.
                                </p>
                              </li>
                            </ul>
                            <ul className="d-flex ml-3 mt-4 ">
                              <li className="list-unstyled ml-1">
                                <p>
                                  It’s difficult for buyers to travel physically
                                  and buy animals.Buyers want ease through
                                  digita l buying.
                                </p>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="Userdetail4">
                <div
                  style={{
                    background: "#FFFFFF",
                    padding: "10px",
                    height: "100%",
                    textAlign: "start",
                  }}
                  className="pt-5 pb-5 row"
                >
                  <div className=" ">
                    <img src={user4} className="  img-fluid   " />
                  </div>

                  <div className="row"  style={{marginLeft:"0px"}}>
                    <div className="col-md-5">
                      <div>
                        <div className="qurbani_texts">
                          <p className="fw-bold fs-3 text-success ">About</p>
                        </div>
                        <div className="qurbani_text  mt-4">
                          <p>
                            Iqbal is a fourty nine year old. He want to grow his
                            business.
                          </p>
                          <p className="mt-4">
                            He mostly busy in his daily routine beinga business
                            man, taking care of the entirefamily which makes it
                            difficult for him to visit his farms every other day
                            in order to oversee and to get familiar with farm
                            growth.
                          </p>
                          <p className="mt-4">
                            He is curious and very interested in the idea of
                            having an application, which could help tracking his
                            farm daily progress and being aware of any ups and
                            down in market.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row"  style={{marginLeft:"0px"}}>
                    <div className="col-md-12">
                      <div id="questionAnswering">
                        <div className="qurbani_text">
                          <p className="fs-3 fw-bold text-success mt-4">
                            Goals
                          </p>
                        </div>
                        <div className="qurbani_text mt-4">
                          <div>
                            <ul className="d-flex ml-3mt-4 ">
                              <li
                                className="list-unstyled ml-1"
                                style={{ fontFamily: "Poppins" }}
                              >
                                <p>
                                  Making sure to get better result and get
                                  sucess in life.
                                </p>
                              </li>
                            </ul>
                            <ul className="d-flex ml-3 mt-4">
                              <li
                                className="list-unstyled ml-1"
                                style={{ fontFamily: "Poppins" }}
                              >
                                <p>
                                  Being able to track his farms progress without
                                  worrying about having to visit himself.
                                </p>
                              </li>
                            </ul>
                            <ul className="d-flex  mt-4">
                              <li
                                className="list-unstyled ml-1"
                                style={{ fontFamily: "Poppins" }}
                              >
                                <p>
                                  Getting feedback and stats directly from the
                                  source.
                                </p>
                              </li>
                            </ul>
                          </div>
                        </div>

                        <div className="qurbani_text">
                          <p className="fs-3 fw-bold text-success mt-4">
                            {" "}
                            Pain Points
                          </p>
                        </div>
                        <div className="qurbani_text">
                          <div>
                            <ul className="d-flex ml-3 mt-4">
                              <li className="list-unstyled ml-1">
                                <p>
                                  It’s nearly impossible to visit farms on daily
                                  basis and moniter progress.
                                </p>
                              </li>
                            </ul>
                            <ul className="d-flex ml-3 mt-4">
                              <li className="list-unstyled ml-1">
                                <p>
                                  {" "}
                                  Being overwhelmed with a bunch of tasks and
                                  keeping records manually.
                                </p>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Qurbanimobile1 />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Qurbanimobile;
