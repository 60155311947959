import React, { useEffect, useLayoutEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { set, useForm } from "react-hook-form";
import { getProjectsByCompany } from "../../apiservices/project";
import Repo from "../../Repository/Repo";
import Toaster from "../../components/Toaster";
import Loader from "../../admin portal/task/Loader";

const schema = yup
  .object({
    title: yup.string().required("*title is required"),
    project: yup.string().required("*Select project"),
  })
  .required();
const CancelBtn = styled(Button)({
  height: "34px",
  width: "136px",
  borderRadius: "4px",
  padding: "8px 50px 8px 50px",
  color: "#A7A7A7",
  background: "#FFFFFF",
  "&:hover": {
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.16)",
    color: "#A7A7A7",
  },
  "&:active": {
    background: " #A7A7A7",
    color: "#FFFFFF",
  },
});
const SaveBtn = styled(Button)({
  height: "34px",
  width: "136px",
  borderRadius: "4px",
  padding: "8px 50px 8px 50px",
  color: "#F5F5F5",
  background: "#21A49E",
  "&:hover": {
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.16)",
    background: "#1B8C87",
    color: "#F5F5F5",
  },
  "&:active": {
    background: " #146E6A",
    color: "#F5F5F5",
  },
});

const AddMeeting = ({ closePop, onSuccess }) => {
  const {
    register,
    handleSubmit,

    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [projectData, setProjectData] = useState([]);

  const [formdata, setformData] = useState([]);
  console.log("format", formdata);
  const [isLoading, setLoading] = useState(false);
  console.log(formdata);

  useEffect(() => {
    getProjectsData();
  }, []);

  const getProjectsData = async () => {
    let data = {
      company_code: localStorage.getItem("companyCode"),
    };
    let res = await getProjectsByCompany(data);
    setProjectData(res.projectsArr);
  };

  const onSubmit = async (arg) => {
    setLoading(true);
    try {
      let { data } = await Repo.roadMap({
        request: {
          method: "saveRoadMap",
          data: {
            title: arg.title,
            project: arg.project,
            company_code: localStorage.getItem("companyCode"),
            email: localStorage.getItem("userEmail"),
          },
        },
      });
      console.log(data);
      // setLoading(false);
      if (data.response.data.roadmapId) {
        Toaster("success", "Added Successfully");
        onSuccess();
      } else {
        Toaster("error", "Error");
      }
    } catch (err) {
      console.log(err);
      Toaster("error", "Error");
      // setLoading(false);
    } finally {
      setLoading(false); // Step 5
    }
  };

  return (
    <>
      <Typography
        variant="h5"
        sx={{ textAlign: "center", color: "#21A49E", mb: 4 }}
      >
        Create new roadmap for your project
      </Typography>
      <Box>
        <TextField
          fullWidth
          label="Roadmap Title"
          type="text"
          {...register("title")}
        />
        <Typography
          sx={{
            justifyContent: "center",
            color: "red",
            fontSize: "13px",
            mb: 3,
          }}
        >
          {errors.title?.message}
        </Typography>
      </Box>

      <Box>
        <TextField fullWidth type="date" {...register("date")} />
        <Typography
          sx={{
            justifyContent: "center",
            color: "red",
            fontSize: "13px",
            mb: 3,
          }}
        >
          {errors.date?.message}
        </Typography>
      </Box>

      <Box>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Project</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            sx={{ height: "40px" }}
            {...register("project")}
            label="Age"
          >
            {projectData.map((item) => (
              <MenuItem value={item.name}>{item.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <Typography
          sx={{
            justifyContent: "center",
            color: "red",
            fontSize: "13px",
            mb: 3,
          }}
        >
          {errors.project?.message}
        </Typography>
      </Box>

      <Stack direction="row" sx={{ mt: 3 }} justifyContent="space-around">
        <CancelBtn onClick={() => closePop()}>Cancel</CancelBtn>
        <SaveBtn onClick={handleSubmit(onSubmit)}>
          {isLoading ? <Loader /> : "save"}
        </SaveBtn>
      </Stack>
    </>
  );
};

export default AddMeeting;
