import React, { useEffect } from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Stack, styled } from "@mui/system";
import { getEmployeeAttendanceSummary } from "../../../apiservices/attendance";
import moment from "moment";

window.Buffer = window.Buffer || require("buffer").Buffer;
const AttendanceSumary = ({ openPopup, closeSumaryPopup, email }) => {
  const style = {
    position: "absolute",
    // width: 900,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: "#FFFFFF",
    boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.2)",
    borderRadius: "12px",
    p: 4,
  };
  const RowStyle = styled(Stack)({
    height: "80px",
    width: "800px",
    alignItems: "center",
    padding: "40px",
    borderRadius: "6px",
    margin: "25px",
    background: "#F8F8F8",
    color: "#67748E",
  });
  const currentMonthYear = moment().format("MM-YYYY");
  const updatedDate = moment().format("YYYY-MM");
  const [attendSumaryData, setAttendSumaryData] = useState({});
  const [filterMonth, setfilterMonth] = useState(updatedDate);

  useEffect(() => {
    getAttendanceSumary(currentMonthYear);
  }, []);
  const getAttendanceSumary = async (date) => {
    let data = {
      employeeId: email,
      month: date,
    };
    let res = await getEmployeeAttendanceSummary(data);
    setAttendSumaryData(res);
  };
  const handleChange = async (e) => {
    setfilterMonth(e.target.value);
    const keyword = e.target.value;
    var updatedDate = keyword;
    updatedDate = updatedDate.split("-").reverse().join("-");
    getAttendanceSumary(updatedDate);
  };

  return (
    <div>
      <Modal
        open={openPopup}
        onClose={() => closeSumaryPopup("applyLeaves")}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Stack justifyContent="center" alignItems="center">
            <Typography
              variant="h5"
              sx={{ fontWeight: "bold", color: "#04B2AE", m: 3 }}>
              Your Attendance Summary
            </Typography>
            <input
              type="month"
              id="att_date_input"
              value={filterMonth}
              onChange={(e) => handleChange(e)}
              style={{ margin: 3 }}
            />
            <RowStyle
              direction="row"
              // alignItems="center"
              justifyContent="space-evenly">
              <Stack alignItems="center">
                <Typography>Total Presents</Typography>
                <Typography sx={{ color: "#00A455" }}>
                  {attendSumaryData.presents}
                </Typography>
              </Stack>
              <Typography
                type="title"
                color="inherit"
                style={{
                  borderRight: "2px solid #E3E3E3",
                  height: "4em",
                  color: "#E3E3E3",
                }}></Typography>
              <Stack alignItems="center">
                <Typography>Total Absents</Typography>
                <Typography sx={{ color: "#A40000" }}>
                  {attendSumaryData.absents}
                </Typography>
              </Stack>
              <Typography
                type="title"
                color="inherit"
                style={{
                  borderRight: "2px solid #E3E3E3",
                  height: "4em",
                  color: "#E3E3E3",
                }}></Typography>
              <Stack alignItems="center">
                <Typography>Total Leaves</Typography>
                <Typography sx={{ color: "#A0A400" }}>
                  {attendSumaryData.fullLeaves}
                </Typography>
              </Stack>
              <Typography
                type="title"
                color="inherit"
                style={{
                  borderRight: "2px solid #E3E3E3",
                  height: "4em",
                  color: "#E3E3E3",
                }}></Typography>
              <Stack alignItems="center">
                <Typography>Total Half Leaves</Typography>
                <Typography sx={{ color: "#D2AC23" }}>
                  {attendSumaryData.halfLeaves}
                </Typography>
              </Stack>
              <Typography
                type="title"
                color="inherit"
                style={{
                  borderRight: "2px solid #E3E3E3",
                  height: "4em",
                  color: "#E3E3E3",
                }}></Typography>
              <Box>
                <Typography>Total WFH</Typography>
                <Typography sx={{ color: "#0038A4" }}>
                  {" "}
                  {attendSumaryData.wfh}
                </Typography>
              </Box>
            </RowStyle>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
};

export default AttendanceSumary;
