import React from "react";
import { Grid, Typography } from "@mui/material";
import { Box, Stack, styled } from "@mui/system";
import Profile from "../../../assets/images/dashboard/profile.png";
window.Buffer = window.Buffer || require("buffer").Buffer;

const ProfileFields = ({ userData }) => {
  const ProfileCard = styled(Box)({
    height: "150px",
    marginTop: "5px",
    borderRadius: "8px",
    background: "#FFFFFF",
    display: "flex",
    alignItems: "center",
    padding: "20px",
    color: "#67748E",
  });
  const Grids = styled(Grid)({
    paddingRight: "35px",
  });
  const Title = styled(Typography)({
    marginLeft: "20px",
    fontSize: "14px",
    color: "#1B8C87",
    backgroundColor: "#EDF4F4",
    maxWidth: "190px",
    padding: "1px",
  });
  const TextFieldCard = styled(Box)({
    height: "60px",
    borderRadius: "4px",
    background: "#FFFFFF",
    display: "flex",
    alignItems: "center",
  });
  return (
    <Grid container spacing={1} sx={{ mt: 3 }}>
      <Grids item xs={12} md={6}>
        <ProfileCard>
          <img
            src={userData.imageUrl ? userData.imageUrl : Profile}
            alt="profile"
            height="120px"
            width="120px"></img>
          <Stack sx={{ display: "flex", alignItems: "center" }}>
            <Typography sx={{ m: 2, fontWeight: "bold" }}>
              Profile Image
            </Typography>
            <Typography sx={{ mx: 2 }}>
              {/* Filename:
              {userData.imageUrl ? userData.imageUrl : "profile012.jpg"} */}
            </Typography>
          </Stack>
        </ProfileCard>
      </Grids>
      <Grid item xs={12} md={5} sx={{ marginLeft: 5 }}>
        <Title sx={{ ml: 2, color: "#1B8C87" }}>Employee name</Title>
        <TextFieldCard sx={{ pl: 2, mt: -2 }}>
          <Typography sx={{ color: "#A7A7A7", fontSize: "15px" }}>
            {userData?.name}
          </Typography>
        </TextFieldCard>

        <Title sx={{ ml: 2, color: "#1B8C87" }}>Email</Title>
        <TextFieldCard sx={{ pl: 2, mt: -2 }}>
          <Typography sx={{ color: "#A7A7A7", fontSize: "15px" }}>
            {userData?.email}
          </Typography>
        </TextFieldCard>
      </Grid>
      <Grids item xs={12} md={6}>
        <Title sx={{ ml: 2, color: "#1B8C87" }}>Date of joining</Title>
        <TextFieldCard sx={{ pl: 2, mt: -2 }}>
          <Typography sx={{ color: "#A7A7A7", fontSize: "15px" }}>
            {userData?.date}
          </Typography>
        </TextFieldCard>
      </Grids>
      <Grid item xs={12} md={5} sx={{ marginLeft: 5 }}>
        <Title sx={{ ml: 2, color: "#1B8C87" }}>Designation</Title>
        <TextFieldCard sx={{ pl: 2, mt: -2 }}>
          <Typography sx={{ color: "#A7A7A7", fontSize: "15px" }}>
            {userData?.designation}
          </Typography>
        </TextFieldCard>
      </Grid>
      <Grids item xs={12} md={6}>
        <Title sx={{ ml: 2, color: "#1B8C87" }}>Permanent address</Title>
        <TextFieldCard sx={{ pl: 2, mt: -2 }}>
          <Typography sx={{ color: "#A7A7A7", fontSize: "15px" }}>
            {userData?.permanentAddress}
          </Typography>
        </TextFieldCard>
      </Grids>
      <Grid item xs={12} md={5} sx={{ marginLeft: 5 }}>
        <Title sx={{ ml: 2, color: "#1B8C87" }}>Temporary address</Title>
        <TextFieldCard sx={{ pl: 2, mt: -2 }}>
          <Typography sx={{ color: "#A7A7A7", fontSize: "15px" }}>
            {userData?.temporaryAddress}
          </Typography>
        </TextFieldCard>
      </Grid>
      <Grids item xs={12} md={6}>
        <Title sx={{ ml: 2, color: "#1B8C87" }}>Contact</Title>
        <TextFieldCard sx={{ pl: 2, mt: -2 }}>
          <Typography sx={{ color: "#A7A7A7", fontSize: "15px" }}>
            {" "}
            {userData?.phoneNo}
          </Typography>
        </TextFieldCard>
      </Grids>
      <Grid item xs={12} md={5} sx={{ marginLeft: 5 }}>
        <Title sx={{ ml: 2, color: "#1B8C87" }}> Emergency Contact</Title>
        <TextFieldCard sx={{ pl: 2, mt: -2 }}>
          <Typography sx={{ color: "#A7A7A7", fontSize: "15px" }}>
            {userData?.emergencyNo}
          </Typography>
        </TextFieldCard>
      </Grid>
      <Grids item xs={12} md={6}>
        <Title sx={{ ml: 2, color: "#1B8C87" }}>Gender</Title>
        <TextFieldCard sx={{ pl: 2, mt: -2 }}>
          <Typography sx={{ color: "#A7A7A7", fontSize: "15px" }}>
            {userData?.gender}
          </Typography>
        </TextFieldCard>
      </Grids>
      <Grid item xs={12} md={5} sx={{ marginLeft: 5 }}>
        <Title sx={{ ml: 2, color: "#1B8C87" }}>Company Code</Title>
        <TextFieldCard sx={{ pl: 2, mt: -2 }}>
          <Typography sx={{ color: "#A7A7A7", fontSize: "15px" }}>
            {userData?.company_code}
          </Typography>
        </TextFieldCard>
      </Grid>
    </Grid>
  );
};

export default ProfileFields;
