import React from "react";
const NaseemSection1 = () => {
  let url = "https://infini8ai-images.s3.amazonaws.com/naseem-casestudy";
  return (
    <div id="naseem-hero-part">
      <div className="padding-100 bg-case-2">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-4 m-0 p-0">
                  <div className="hero-images">
                    <img
                      src={`${url}/splashlogo.png`}
                      // src={splashlogo}
                      class="rounded float-left"
                      alt="logo"
                      style={{
                        position: "relative",
                        top: "0",
                        width: "100%",
                        maxWidth: "96px",
                        backgroundPosition: "100% center",
                        backgroundSize: "cover",
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="hero-Content text-center">
                    <h3>UX/UI Case Study</h3>
                  </div>
                </div>
                <div className="col-md-4 m-0 p-0">
                  <div className="hero-images">
                    <img
                      src={`${url}/Group3.png`}
                      class="rounded Group3"
                      alt="logo"
                    />
                  </div>
                </div>
              </div>
              <div className="UiCaseStudy">
                <div className="row " style={{ alignItems: "center" }}>
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-md-12">
                        <img
                          src={`${url}/kids-3420032_1920 1.svg`}
                          class="rounded kids19201 float-left"
                          alt="logo"
                        />
                      </div>
                      <div className="col-md-12">
                        <div className="naseemEducation pt-0">
                          <h3>Naseem Education</h3>
                          <p>
                            A learning management system powered by AI,
                            Neuroscience and Learning Sciences
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="naseemEducationImg">
                      <img
                        src={`${url}/Group4826.png`}
                        class="rounded float-left"
                        alt="logo"
                        style={{
                          position: "relative",
                          width: "100%",
                          maxWidth: "100%",
                          backgroundPosition: "100% center",
                          backgroundSize: "cover",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="problemStatement" className="padding-100 ">
        <div className="container">
          <div className="firstSection">
            <div className="row">
              <div className="col-md-2">
                <div className="solutionImages">
                  <img
                    src={`${url}/emojione_keycap-1.png`}
                    class="rounded emojionekeycap1"
                    alt="logo"
                  />
                </div>
              </div>
              <div className="col-md-8 ">
                <div id="problemStatementContent">
                  <h4 className="mb-2">Problem Statement</h4>
                  <p className="pt-2">
                    At Naseem education we feel that learning and management
                    works side by side, for any organisation. Many educational
                    institutes find it hard, to integrate their operations with
                    quality learning based on latest technological developments.
                  </p>
                </div>
              </div>
              <div className="col-md-2">
                <div className="row">
                  <div className="col-md-12">
                    <div className="solutionImages float-end">
                      <img
                        src={`${url}/solution1.png`}
                        class="rounded solution1"
                        alt="logo"
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="solutionImages float-end">
                      <img
                        src={`${url}/brain-5983810_19201.png`}
                        class="rounded brainImg"
                        alt="logo"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="solutionImages">
                  <img
                    src={`${url}/border1.svg`}
                    class="rounded border1"
                    alt="logo"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="secondSection">
            <div className="row">
              <div
                className="col-md-2"
                style={{
                  zIndex: "1",
                }}
              >
                <div className="row">
                  <div className="col-md-12">
                    <div className="solutionImages float-start ">
                      <img
                        src={`${url}/artificial-intelligence2.png`}
                        class="rounded artificialIntelligence2"
                        alt="logo"
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="solutionImages float-start">
                      <img
                        src={`${url}/light.png`}
                        class="rounded light"
                        alt="logo"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-8 ">
                <div id="problemStatementContent">
                  <h4 className="mb-2">Solution </h4>
                  <p className="pt-2">
                    Naseem Education is a powerful Management Information
                    System, that is supported by state of the art learning
                    management system. Powered by Artificial Intelligence,
                    Neuroscience and Learning Sciences.
                  </p>
                </div>
              </div>
              <div className="col-md-2">
                <div className="solutionImages float-end">
                  <img
                    src={`${url}/emojione_keycap-2.png`}
                    class="rounded emojionekeycap2"
                    alt="logo"
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="solutionImages">
                  <img
                    src={`${url}/border2.svg`}
                    class="rounded border2"
                    alt="logo"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="thirdSection">
            <div className="row">
              <div className="col-md-2">
                <div className="solutionImages">
                  <img
                    src={`${url}/emojione_keycap-3.png`}
                    class="rounded emojionekeycap3"
                    alt="logo"
                  />
                </div>
              </div>
              <div className="col-md-8 ">
                <div id="problemStatementContent">
                  <h4 className="mb-2">Business Opportunities</h4>
                  <p className="pt-2">
                    The target market for this product is huge, with around five
                    hundred thousand schools in Pakistan. The company has the
                    potential to capture a significant proportion of this
                    market, with its expertise in learning sciences, software
                    development and growth.
                  </p>
                </div>
              </div>
              <div
                className="col-md-2"
                style={{
                  zIndex: "2",
                }}
              >
                <div className="row">
                  <div className="col-md-12">
                    <div className="solutionImages float-end">
                      <img
                        src={`${url}/handshake.png`}
                        class="rounded handshakes"
                        alt="logo"
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="solutionImages float-end">
                      <img
                        src={`${url}/Graph.png`}
                        class="rounded GraphImg"
                        alt="logo"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default NaseemSection1;
