import React from "react";
import EDUCATION from "../../assets/images/EDUCATION.svg";
import HEALTHCARE from "../../assets/images/HEALTHCARE.svg";
import RETAIL from "../../assets/images/RETAIL.svg";

import AGRICULTURE from "../../assets/images/AGRICULTURE.svg";
import BUSINESS from "../../assets/images/BUSINESS.svg";

import optimus_health from "../../assets/images/home page/optimus health (white) 1.png";
import Optimus_PHONE from "../../assets/images/home page/Optimus PHONE.png";

import Q_APP_Logo from "../../assets/images/home page/qurbani_app_logo.svg";
import Q_APP_PHONE from "../../assets/images/home page/qurbani_app.png";

import Naseem_PHONE from "../../assets/images/home page/Naseem PHONE.png";

import Stock_Logo from "../../assets/images/home page/StockChain_logo.svg";
import Stock_PHONE from "../../assets/images/home page/Stockchain PHONE.png";
import Businessipad from "../../assets/images/businesspal/OptimusIPAD.png";
import businessproductmobile from "../../assets/images/businesspal/businessproducts mobile.png";
import businessproducts from "../../assets/images/businesspal/businessproducts.png";
import splashlogonaseem from "../../assets/images/businesspal/splashlogo.png";
import OptimusIPADNaseem from "../../assets/images/home page/OptimusIPADNaseem.png";
import naseemlogoproducts from "../../assets/images/home page/naseemlogoproducts.png";
import OptimusIPADQurbani from "../../assets/images/home page/OptimusIPADQurbani.png";
import qurbanilogoproducts from "../../assets/images/home page/qurbanilogoproducts.png";
import OptimusIPADHealtProducts from "../../assets/images/home page/OptimusIPADHealtProducts.png";
import optimushealthlogoproducts from "../../assets/images/home page/optimushealthlogoproducts.png";
import StockchainIPADproducts from "../../assets/images/home page/StockchainIPADproducts.png";
import stockchainlogoproducts from "../../assets/images/home page/stockchainlogoproducts.png";
import eduationslider from "../../assets/images/home page/eduationslider.png";
import legalslider from "../../assets/images/home page/legalslider.png";
import automotiveslider from "../../assets/images/home page/automotiveslider.png";
import smartcitiesslider from "../../assets/images/home page/smartcitiesslider.png";
import hesalthcareslider from "../../assets/images/home page/hesalthcareslider.png";
import businessslider from "../../assets/images/home page/businessslider.png";
import agricultureslider from "../../assets/images/home page/agricultureslider.png";
import retailslider from "../../assets/images/home page/retailslider.png";
import studylanding from "../../assets/images/home page/studylanding.png";
import openaislide from "../../assets/images/home page/openaislide.png";
import robotslide from "../../assets/images/home page/robotslide.png";
import companiesslide from "../../assets/images/home page/companiesslide.png";
import ukslide from "../../assets/images/home page/ukslide.png";
import labslide from "../../assets/images/home page/labslide.png";
import aislide from "../../assets/images/home page/aislide.png";
import bigmanslider from "../../assets/images/home page/bigmanslider.png";
import amazonslide from "../../assets/images/home page/amazonslide.png";
import alienslide from "../../assets/images/home page/alienslide.png";
import goatslide from "../../assets/images/home page/goatslide.png";
import mobalienslider from "../../assets/images/home page/mobalienslider.png";
import headslide from "../../assets/images/home page/headslide.png";

import Slider from "react-slick";
import { useNavigate } from "react-router-dom";
const OurProducts = () => {
  let url = "https://infini8ai-images.s3.amazonaws.com/home page";
  const navigate = useNavigate();
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 4,
    // slidesToScroll: 1,
    autoplay: true,
    speed: 5500,
    autoplaySpeed: 0,
    cssEase: "linear",
    // nextArrow: <img src={right_blue_arrow} />,
    // prevArrow: <img src={left_blue_arow} />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  const settingsOne = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    // slidesToScroll: 1,
    autoplaySpeed: 0,
    autoplay: true,
    vertical: true,
    speed: 5500,
    autoplaySpeed: 0,
    cssEase: "linear",
    useTransform: false,
    // nextArrow: <img src={right_blue_arrow} />,
    // prevArrow: <img src={left_blue_arow} />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  const settingsTwo = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    // slidesToScroll: 1,
    autoplay: true,
    vertical: true,
    rtl: true,
    speed: 5500,
    autoplaySpeed: 0,
    cssEase: "linear",
    // nextArrow: <img src={right_blue_arrow} />,
    // prevArrow: <img src={left_blue_arow} />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };
  const viewDetails = (mode) => {
    if (mode === "optimus") {
      return navigate(`/optimus-case-study`);
    } else if (mode === "qurbani") {
      return navigate(`/qurbani-case-study`);
    } else if (mode === "naseem") {
      return navigate(`/naseem-case-study`);
    } else if (mode === "stock") {
      return navigate(`/stock-chain-case-study`);
    }
    else if (mode === "business") {
      return navigate(`/businesspal-case-study`);
    }
  };
  return (
    <div>
      <section id="ourProducts" style={{ backgroundColor: "#F0FDFF" }}>
        <div className="" style={{}}>
          <div className="container py-1">
            <p className="text_dark_blue fs-2  fw-bolder position-relative mt-5 text-center">
              Products & Industries we serve
              <span className="position-absolute mt-3 blue_icon"></span>
            </p>
            <br />
            <div style={{ width: "93%" }} className="mt-5 mx-auto">
               <div className="slider-products">
    <div className="slide-track-products">
      <div className="slide-products img">
        <img src={eduationslider} alt="" />
      </div>
      <div className="slide-products img">
        <img src={automotiveslider} alt="" />
      </div>
      <div className="slide-products img">
        <img src={businessslider} alt="" />
      </div>
      <div className="slide-products img">
        <img src={agricultureslider} alt="" />
      </div>
      <div className="slide-products img">
        <img src={retailslider} alt="" />
      </div>
      <div className="slide-products img">
        <img src={hesalthcareslider} alt="" />
      </div>
      <div className="slide-products img">
        <img src={legalslider} alt="" />
      </div>
      <div className="slide-products img">
        <img src={smartcitiesslider} alt="" />
      </div>
    </div>
  </div>
            </div>
          </div>
        </div>
        {/* card-section */}
        <div className="container cards-container">
          <div className="row card-top">
            <div
              className="col-md-5 single-card "
              style={{
                backgroundColor: "#BAF6F9",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                borderRadius: "11px",
              }}
              onClick={(e) => viewDetails("business")}
            >
              <div>
                <img src={Businessipad} className="card-img-tablet" />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                  paddingBottom: "2rem",
                }}
              >
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img src={businessproducts} className="card-logo" />
                </div>
                <div>
                  <p className="text-center card-text">
                    Manage your business professionally with BusinessPal through
                    the best Web and Mobile Apps for your billing, inventory,
                    accounting etc. Join the digital revolution and bring your
                    business online.
                  </p>
                </div>
                <div className="card-button-business" >
                  <button
                    className="qurbani_btn mt-sm-5  "
                    onClick={(e) => viewDetails("business")}
                    style={{
                      color: "rgb(22, 202, 213)",
                      borderColor: "white",
                      width: "90%",
                      backgroundColor: "#FFFFFF",
                      border: "none",
                    }}
                  >
                    Learn more
                  </button>
                </div>
              </div>
            </div>

            <div
              className="col-md-5 single-card "
              style={{
                backgroundColor: "#B6F3E1",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                borderRadius: "11px",
              }}
              onClick={(e) => viewDetails("naseem")}
            >
              <div>
                <img src={OptimusIPADNaseem} className="card-img-tablet" />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                  paddingBottom: "2rem",
                }}
              >
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img
                    src={naseemlogoproducts}
                    className="card-logo"
                    style={{ maxWidth: "80%" }}
                  />
                </div>
                <div>
                  <p className="text-center card-text">
                    A learning management system powered by artificial
                    intelligence, neuroscience and learning sciences.
                  </p>
                </div>
                <div  className="card-button-naseem" >
                  <button
                    className="qurbani_btn "
                    onClick={(e) => viewDetails("naseem")}
                    style={{
                      color: "#45B56B",
                      borderColor: "white",
                      width: "90%",
                      backgroundColor: "#FFFFFF",
                      border: "none",
                    }}
                  >
                    Learn more
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="row card-top ">
            <div
              className="col-md-5 single-card "
              style={{
                backgroundColor: "#CBF9BA",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                borderRadius: "11px",
              }}
              onClick={(e) => viewDetails("qurbani")}
            >
              <div>
                <img src={OptimusIPADQurbani} className="card-img-tablet" />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                  paddingBottom: "2rem",
                }}
              >
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img src={qurbanilogoproducts} className="card-logo" />
                </div>
                <div>
                  <p className="text-center card-text">
                    A Network Marketplace to buy, sell and share livestock for
                    free anywhere world wide.
                  </p>
                </div>
                <div className="card-button-qurbani ">
                  <button
                    className="qurbani_btn "
                    onClick={(e) => viewDetails("qurbani")}
                    style={{
                      color: "#7AAC67",
                      borderColor: "white",
                      width: "90%",
                      backgroundColor: "#FFFFFF",
                      border: "none",
                    }}
                  >
                    Learn more
                  </button>
                </div>
              </div>
            </div>

            <div
              className="col-md-5 single-card "
              style={{
                backgroundColor: "#F9E0BA",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                borderRadius: "11px",
              }}
              onClick={(e) => viewDetails("optimus")}
            >
              <div>
                <img
                  src={OptimusIPADHealtProducts}
                  className="card-img-tablet"
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                  paddingBottom: "2rem",
                }}
              >
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img
                    src={optimushealthlogoproducts}
                    className="card-logo"
                    style={{ maxWidth: "60%" }}
                  />
                </div>
                <div>
                  <p className="text-center card-text">
                    Easily aggregate & streamline all your well-being data, apps
                    & expert coaching on a single “Smartphone App”, towards your
                    own personalized optimal unique lifestyle
                  </p>
                </div>
                <div className="card-button-optimus ">
                  <button
                    className="qurbani_btn mt-sm-5 "
                    onClick={(e) => viewDetails("optimus")}
                    style={{
                      color: "#B79B71",
                      borderColor: "white",
                      width: "90%",
                      backgroundColor: "#FFFFFF",
                      border: "none",
                    }}
                  >
                    Learn more
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="row card-top">
            <div className="col-md-11 stock-card-col single-card "   onClick={(e) => viewDetails("stock")}>
              <div>
                <img src={StockchainIPADproducts} className="card-img-tablet" />
              </div>
              <div className="stock-chain-logo-text">
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img src={stockchainlogoproducts} className="card-logo" />
                </div>
                <div>
                  <p className="text-center card-text">
                    “StockChain” is an advance logistic solution that gives you
                    complete transparency in supply chain and cargo mobility.
                    From booking to delivery, you can track every segment of the
                    whole process.
                  </p>
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <button
                    className="qurbani_btn mt-sm-5 "
                    onClick={(e) => viewDetails("stock")}
                    style={{
                      color: "#1569FF",
                      borderColor: "white",
                      width: "90%",
                      backgroundColor: "#FFFFFF",
                      border: "none",
                    }}
                  >
                    Learn more
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* footer-slider */}

        <div className="container bottom-slider-container ">
          <div
            className="row row-newsletter-top"
          >
            <div className="col-md-5 col-lg-6 ">
              <div className="slider-top ">
                <div  className="mt-5 slider-top-div mx-auto">
                <div className="slider-one">
    <div className="slide-track-one">
      <div className="slide-one img">
        <img src={openaislide} alt="" />
      </div>
      <div className="slide-one img">
        <img src={robotslide} alt="" />
      </div>
      <div className="slide-one img">
        <img src={companiesslide} alt="" />
      </div>
      <div className="slide-one img">
        <img src={ukslide} alt="" />
      </div>
      <div className="slide-one img">
        <img src={labslide} alt="" />
      </div>
      <div className="slide-one img">
        <img src={aislide} alt="" />
      </div>
    </div>
  </div>
                </div>

                <div  className="mt-5  mx-auto slider-top-div">
                <div className="slider-two">
    <div className="slide-track-two">
      <div className="slide-two img">
        <img src={bigmanslider} alt="" />
      </div>
      <div className="slide-two img">
        <img src={amazonslide} alt="" />
      </div>
      <div className="slide-two img">
        <img src={alienslide} alt="" />
      </div>
      <div className="slide-two img">
        <img src={goatslide} alt="" />
      </div>
      <div className="slide-two img">
        <img src={mobalienslider} alt="" />
      </div>
      <div className="slide-two img">
        <img src={headslide} alt="" />
      </div>
    </div>
  </div>
                </div>
              </div>
            </div>
            <div
              className="col-md-5"
              style={{ marginTop: "3rem", marginBottom: "1.5rem" }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                }}
              >
                <div
                  className="bi-weekly-text-top"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <p
                    style={{
                      color: "#000000",
                      font: "Dosis",
                      fontWeight: 700,
                      fontSize: "16px",
                    }}
                  >
                    GET OUR BI-WEEKLY
                  </p>
                  <p
                    style={{
                      background: "linear-gradient(to right, #FF354D, #FF7A01)",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                      fontWeight: "bold",
                      fontSize: "50px", // Adjust as needed
                    }}
                  >
                    NEWSLETTER
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                  }}
                >
                  <p className="newsletter-read">
                    Read by over 100,000 Researchers, Students, Enthusiasts and
                    Industry professionals around the world.
                  </p>
                  <div className="subscribe-subscribe-top">
                    <div className="subscribe-now-top">
                      <p
                        style={{
                          color: "#000000",
                          font: "Dosis",
                          fontWeight: 700,
                          fontSize: "24px",
                        }}
                      >
                        Subscribe now!
                      </p>
                      <img src={studylanding} style={{ width: "30%" }} />
                    </div>
                    <a
                      href="https://veracious.ai/news-letter"
                      target="_blank"
                      style={{ color: "white" ,width:"90%"}}
                    >
                      <button
                        className="qurbani_btn mt-sm-5 mt-md-0 "
                        // onClick={(e) => viewDetails("qurbani")}
                        style={{
                          color: "#1569FF",
                          borderColor: "white",
                          width: "100%",
                          height: "4rem",
                          backgroundColor: "#E7472F",
                          color: "white",
                          border: "none",
                          borderRadius: "10px",
                          
                        }}
                      >
                        SUBSCRIBE
                      </button>
                    </a>
                  </div>

                  {/* <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "2rem",
                  }}
                > */}

                  <p
                    className="text-center"
                    style={{
                      color: "#B7B7B7",
                      font: "Dosis",
                      fontWeight: 400,
                      fontSize: "16px",
                    }}
                  >
                    Your email is safe with us, we dont spam.
                  </p>
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* ========================================= Card 1 Business========================================= */}
        {/* 
        <div className="cards card2">
          <div className="container">
            <div className="row ">
              <div className="col-12 col-md-5 col-lg-3 mt-5 business-product-top">
                <img
                  src={businessproducts}
                  className="img-fluid business w-70"
                />
              </div>
              <div className="col-8 col-md-7 col-lg-9 mt-4 mt-md-0">
                <img
                  src={Businessipad}
                  className="float-md-end img-fluid    ipad"
                />
              </div>

              <div className=" col-4 col-md-6  mt-4 mt-md-0">
                <img
                  src={businessproductmobile}
                  className="img-fluid  mobile"
                />
              </div>
              <div className="col-12 col-md-6 fs-3 text-center text-md-start mt-3 mx-auto mb-5 mb-md-0  lh-sm ">
                Manage your business professionally with BusinessPal through the
                best Web and Mobile Apps for your billing, inventory, accounting
                etc. Join the digital revolution and bring your business online.
              </div>
            </div>
            <div
              className="d-flex justify-content-center justify-content-md-center p-5 product-buttons product-qurbani-button business-button"
              // style={{
              //   marginTop: "-280px",
              //   marginBottom: "150px",
              //   marginLeft: "130px",
              // }}
            >
              <button
                className="qurbani_btn mt-sm-5 mt-md-0 "
                onClick={(e) => viewDetails("qurbani")}
                style={{ color: "#5687CD", borderColor: "#5687CD" }}
              >
                Learn more
              </button>
            </div>
          </div>
        </div> */}
        {/* ========================================= Card 1========================================= */}
        {/* <div className="cards card1  ">
          <div className="container">
            <div className="row">
              <div className="col-8 col-md-6 col-lg-8 order-2 order-md-1">
                <img
                  src={`${url}/optimusIpad.png`}
                  className="img-fluid ipad"
                />
              </div>
              <div className="col-12  col-md-6 col-lg-4 order-first order-md-2  ">
                <img
                  src={optimus_health}
                  className="float-md-end img-fluid w-70 health"
                />
              </div>
              <div className="col-12 col-md-6 order-4 order-md-3 fs-3 text-center text-md-start card_des lh-sm">
                Easily aggregate & streamline all your well-being data, apps &
                expert coaching on a single “Smartphone App”, towards your own
                personalized optimal unique lifestyle.
              </div>
              <div className=" col-4 col-md-6 order-3 order-md-4">
                <img
                  src={Optimus_PHONE}
                  className="float-end img-fluid  mobile"
                />
              </div>
            </div>
            <div className="d-flex justify-content-center justify-content-md-start mt-xs-neg-100 mt-sm-neg-150 mt-md-neg-210 mb-150">
              <button
                className="optimus_btn btn_outline_gray  mt-sm-5 mt-md-0 "
                onClick={(e) => viewDetails("optimus")}
              >
                Learn more
              </button>
            </div>
          </div>
        </div> */}

        {/* ========================================= Card 2 ========================================= */}
        {/* <div className="cards card2">
          <div className="container">
            <div className="row ">
              <div className="col-12 col-md-5 col-lg-3 mt-5">
                <img src={Q_APP_Logo} className="img-fluid health w-70" />
              </div>
              <div className="col-8 col-md-7 col-lg-9 mt-4 mt-md-0">
                <img
                  src={`${url}/qurbani_app_ipad.png`}
                  className="float-md-end img-fluid    ipad"
                />
              </div>

              <div className=" col-4 col-md-6  mt-4 mt-md-0">
                <img src={Q_APP_PHONE} className="img-fluid  mobile" />
              </div>
              <div className="col-12 col-md-6 fs-3 text-center text-md-start mt-3 mx-auto mb-5 mb-md-0  lh-sm ">
                A Network Marketplace to buy, sell and share livestock for free
                anywhere world wide.
              </div>
            </div>
            <div
              className="d-flex justify-content-center justify-content-md-center p-5 product-buttons product-qurbani-button"
              style={{
                marginTop: "-280px",
                marginBottom: "150px",
                marginLeft: "130px",
              }}
            >
              <button
                className="qurbani_btn btn_outline_green mt-sm-5 mt-md-0 "
                onClick={(e) => viewDetails("qurbani")}
              >
                Learn more
              </button>
            </div>
          </div>
        </div> */}

        {/* ========================================= Card 3 ========================================= */}
        {/* <div className="cards card3">
          <div className="container">
            <div className="row">
              <div className="col-8 col-md-6 col-lg-8 order-2 order-md-1 ">
                <img
                  src={`${url}/Naseem IPAD.png`}
                  className="img-fluid ipad"
                />
              </div>
              <div className="col-12 col-md-6 col-lg-4 order-first order-md-2  mt-3 naseem-logo-top">
                <img
                  src={splashlogonaseem}
                  className="float-md-center naseem_logo img-fluid  w-70  health "
                  style={{ maxWidth: "50%" }}
                />
              </div>
              <div className="col-12 col-md-6 order-4 order-md-3 mb-5 mb-md-0 fs-3 text-center text-md-start lh-sm ">
                A learning management system powered by artificial intelligence,
                neuroscience and learning sciences
              </div>
              <div className=" col-4 col-md-6 order-3 order-md-4">
                <img
                  src={Naseem_PHONE}
                  className="float-end img-fluid  mobile"
                />
              </div>
            </div>
            <div
              className="d-flex justify-content-center justify-content-md-start"
              style={{ marginTop: "-210px", marginBottom: "150px" }}
            >
              <button
                className="naseem_btn btn_outline_purple  mt-md-0 mt-sm-5"
                onClick={() => viewDetails("naseem")}
              >
                Learn more
              </button>
            </div>
          </div>
        </div> */}

        {/* ========================================= Card 4 ========================================= */}
        {/* <div className="cards card4">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-6 col-lg-4 mt-5 ">
                <img
                  src={Stock_Logo}
                  className="img-fluid health  "
                  style={{ width: "55%", marginBottom: "50px" }}
                />
              </div>
              <div className="col-8 col-md-6 col-lg-8 mt-4 mt-md-0">
                <img
                  src={`${url}/Stockchain IPAD.png`}
                  className="float-md-end img-fluid   ipad"
                />
              </div>

              <div className=" col-4 col-md-6  mt-4 mt-md-0">
                <img src={Stock_PHONE} className="img-fluid  mobile " />
              </div>
              <div className="col-12 col-md-6 fs-3 text-center text-md-start lh-sm ">
                “StockChain” is an advance logistic solution that gives you
                complete transparency in supply chain and cargo mobility. From
                booking to delivery, you can track every segment of the whole
                process.
              </div>
            </div>
            <div
              className="d-flex justify-content-center justify-content-md-center p-4 product-buttons product-stock-button"
              style={{ marginTop: "-200px", marginBottom: "150px" }}
            >
              <button
                className=" stock_btn btn_outline_blue mt-sm-5 mt-md-0  "
                onClick={() => viewDetails("stock")}
              >
                Learn more
              </button>
            </div>
          </div>
        </div> */}
      </section>
    </div>
  );
};

export default OurProducts;
