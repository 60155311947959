import React from "react";
import ijaaz from "../../../assets/images/qurbani/ijaaz.svg";
import Group5035 from "../../../assets/images/qurbani/Group5035.svg";
import Group4937 from "../../../assets/images/qurbani/Group4937.svg";
import Group4938 from "../../../assets/images/qurbani/Group4938.svg";
import Group5053 from "../../../assets/images/qurbani/Group5053.png";

const QurbaniSection3 = () => {
  let url = "https://infini8ai-images.s3.amazonaws.com/qurbani";
  return (
    <div>
      <div>
        <div id="Qurbanibg-case-about1" className="padding-10 0 ">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="QurbaniAbout">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="QurbaniAboutContent">
                        <h3>About</h3>
                        <p>
                          Mr. Ijaaz Ahmed is a thirty two year. He has four
                          farms in Rawalpindi. He manages and oversees his two
                          farms in Rawalpindi.
                        </p>
                        <p>
                          He wishes to do his work without moving around and he
                          wants easier way to accomplish his tasks without
                          visiting each farm.
                        </p>
                        <p>
                          He appreciates technology as it is more centralized
                          management system where, he could do most of his work
                          from a single platform without moving around.
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="row" style={{ alignItems: "center" }}>
                        <div className="col-md-5">
                          <div className="AboutVector">
                            <img className="" src={ijaaz} />
                          </div>
                        </div>
                        <div className="col-md-7">
                          <div className="AboutVectorText">
                            <div className="card">
                              <h5 className="card-title">Mr. Ijaaz Ahmed</h5>
                              <div className="card-body">
                                <p className="card-text">
                                  <strong>Age:</strong> 32
                                </p>
                                <p className="card-text">
                                  <strong>Work:</strong> Farming
                                </p>
                                <p className="card-text">
                                  <strong>Farms:</strong> 4
                                </p>
                                <p className="card-text">
                                  <strong>Location:</strong> Rawalpindi, PK
                                </p>
                              </div>
                            </div>
                            <p className="card-text">
                              “ Personal Life satisfaction is when your job’s
                              much more relaxing. ”
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row pt-5">
                    <div className="col-md-12">
                      <div className="QurbaniAboutContent">
                        <h3>Goals</h3>
                        <p>
                          Manage animal farms under my supervision from a single
                          platform.
                        </p>
                        <p>Don’t have to travel for day to day routine work.</p>
                        <p>Make life more relaxing by reducing work stress.</p>
                      </div>
                    </div>
                    <div className="col-md-12 pt-5">
                      <div className="QurbaniAboutContent">
                        <h3>Pain Points</h3>
                        <p>
                          Visiting every farm physically even for smaller
                          routine work.
                        </p>
                        <p>More expensive to visit every farm per day. </p>
                        <p>
                          Mentally keeping track of everything happening around.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="Qurbanibgabout2linearBg">
        <div id="Qurbanibg-case-about2" className="padding-10 0 ">
          <div className="container">
            <div className="row" style={{ }}>
              <div className="col-md-12">
                <div className="QurbaniAbout">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="QurbaniAboutContent">
                        <h3>About</h3>
                        <p>
                          Mr. Kamran Rauf is a fourty year old. He is owner of
                          six farm.
                        </p>
                        <p>
                          He considers his farms reputation and progress a very
                          important task and in order to do so he has to
                          physically engage himself with employee, in order to
                          review their progress and failures all the time.
                        </p>
                        <p>
                          Mr. Kamran is looking for a solution which could help
                          him oversee farms day to day progress and act
                          accordingly based on the stats and engage the staff.
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="row" style={{ alignItems: "center" }}>
                        <div className="col-md-5">
                          <div className="AboutVector">
                            <img className="" src={Group5035} alt="Group5035" />
                          </div>
                        </div>
                        <div className="col-md-7">
                          <div className="AboutVectorText">
                            <div className="card">
                              <h5 className="card-title">Mr. Kamran Rauf</h5>
                              <div className="card-body">
                                <p className="card-text">
                                  <strong>Age:</strong> 40
                                </p>
                                <p className="card-text">
                                  <strong>Work:</strong> farming
                                </p>
                                <p className="card-text">
                                  <strong>Farm :</strong> 4
                                </p>
                                <p className="card-text">
                                  <strong>Location:</strong> Islamabad, PK
                                </p>
                              </div>
                            </div>
                            <p className="card-text">
                              “ Sometimes, we all need a little inspiration to
                              remind us why we do what we do.”
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row pt-5">
                    <div className="col-md-12">
                      <div className="QurbaniAboutContent">
                        <h3>Goals</h3>
                        <p>
                          Oversee day to day farms progress from a single
                          platform.
                        </p>
                        <p>
                          Minimize the chances of daily physical interaction.
                        </p>
                        <p>
                          Get to review to the point stats and act accordingly
                          based on the data.
                        </p>
                      </div>
                    </div>
                    <div className="col-md-12 pt-5">
                      <div id="painPoints" className="QurbaniAboutContent">
                        <h3>Pain Points</h3>
                        <p>
                          Time it takes to interact with the staff in order to
                          get progress reports.
                        </p>
                        <p>
                          Not being sure about the stats and reports being 100
                          percent accurate.
                        </p>
                        <p>
                          Not being able to get the data right on the spot when
                          needed.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div id="Qurbanibg-case-about3-top"></div>
        <div id="Qurbanibg-case-about3" className="padding-10 0 ">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="QurbaniAbout">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="QurbaniAboutContent">
                        <h3>About</h3>
                        <p>
                          Mr. Ihtisham ul Haq is a twenty seven year old farm
                          owner.{" "}
                        </p>
                        <p>
                          He has five animal farms. He is doing farming business
                          for five years and get tired of daily busy routine.
                          It’s very dificult for Ihtisham ul Haq to visit farms
                          daily.
                        </p>
                        <p>
                          Ihtisham ul Haq and other looking for a solution which
                          could help them manage their farms from a single
                          platform in a simple yet efficient manner.
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="row" style={{ alignItems: "center" }}>
                        <div className="col-md-5">
                          <div className="AboutVector">
                            <img className="" src={Group4937} alt="" />
                          </div>
                        </div>
                        <div className="col-md-7">
                          <div className="AboutVectorText">
                            <div className="card">
                              <h5 className="card-title">
                                Mr. Ihtisham ul Haq
                              </h5>
                              <div className="card-body">
                                <p className="card-text">
                                  <strong>Age:</strong> 27
                                </p>
                                <p className="card-text">
                                  <strong>Work:</strong> Farming
                                </p>
                                <p className="card-text">
                                  <strong>Farm:</strong> 5
                                </p>
                                <p className="card-text">
                                  <strong>Location:</strong> Lahore, PK
                                </p>
                              </div>
                            </div>
                            <p className="card-text">
                              “ The duties of a teacher are neither few or
                              small, but they elevate the mind and enhance
                              character.”
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row pt-5">
                    <div className="col-md-12">
                      <div className="QurbaniAboutContent">
                        <h3>Goals</h3>
                        <p>
                          Managing all of the farms in a better way rather than
                          using traditional method.
                        </p>
                        <p>
                          Being able to communicate with sellers community and
                          buyers digitally.
                        </p>
                        <p>Be more efficient and way to run their business.</p>
                      </div>
                    </div>
                    <div className="col-md-12 pt-5">
                      <div className="QurbaniAboutContent">
                        <h3>Pain Points</h3>
                        <p>
                          Farms progress is getting stop due to daily busy
                          routine.
                        </p>
                        <p>
                          Being overwhelmed with a bunch of tasks and keeping
                          records manually.
                        </p>
                        <p>
                          It’s difficult for buyers to travel physically and buy
                          animals. Buyers want ease through digital buying.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="Qurbanibgabout4linearBg">
        <div id="Qurbanibg-case-about4" className="padding-10 0 ">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="naseemAbout">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="naseemAboutContent">
                        <h3>About</h3>
                        <p>
                          Iqbal is a fourty nine year old. He want to grow his
                          business.
                        </p>
                        <p>
                          He mostly busy in his daily routine being a business
                          man, taking care of the entire family which makes it
                          difficult for him to visit his farms every other day
                          in order to oversee and to get familiar with farm
                          growth.
                        </p>
                        <p>
                          he is curious and very interested in the idea of
                          having an application, which could help tracking his
                          farm daily progress and being aware of any ups and
                          down in market.
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="row" style={{ alignItems: "center" }}>
                        <div className="col-md-5">
                          <div className="AboutVector">
                            <img className="" src={Group4938} />
                          </div>
                        </div>
                        <div className="col-md-7">
                          <div className="AboutVectorText">
                            <div className="card">
                              <h5 className="card-title">Mr. Muhammad Iqbal</h5>
                              <div className="card-body">
                                <p className="card-text">
                                  <strong>Age:</strong> 49
                                </p>
                                <p className="card-text">
                                  <strong>Work:</strong> farming
                                </p>
                                <p className="card-text">
                                  <strong>Farm:</strong> 7
                                </p>
                                <p className="card-text">
                                  <strong>Location:</strong> Jhelum, PK
                                </p>
                              </div>
                            </div>
                            <p className="card-text">
                              “ The memories we make with our family is
                              everything.”
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row pt-5">
                    <div className="col-md-12">
                      <div className="naseemAboutContent">
                        <h3>Goals</h3>
                        <p>
                          Making sure to get better result and get sucess in
                          life.{" "}
                        </p>
                        <p>
                          Being able to track his farms progress without
                          worrying about having to visit himself.
                        </p>
                        <p>
                          Getting feedback and stats directly from the source.{" "}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-12 pt-5">
                      <div className="naseemAboutContent">
                        <h3>Pain Points</h3>
                        <p>
                          It’s nearly impossible to visit farms on daily basis
                          and moniter progress.{" "}
                        </p>
                        <p>
                          Being overwhelmed with a bunch of tasks and keeping
                          records manually.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="QurbanibgempathyMaplinearBg">
        <div id="bg-case-empathyMap" className="padding-10 0 ">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="empathyMapContent">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="empathyMapLogo">
                        <img
                          className="Group5016"
                          src={Group5053}
                          alt="Group5016"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QurbaniSection3;
