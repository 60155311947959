import React, { useLayoutEffect } from "react";
import { useState } from "react";
import "./profile.css";
import Star from "../../assets/images/dashboard/star.png";
import Setting from "../../assets/images/team/settingicon.png";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import { Box, Button, CircularProgress, Grid } from "@mui/material";
import { Stack, styled } from "@mui/system";
import ProfileFields from "./ProfileFields";
import ProfileEdit from "./ProfileEdit";
import DashboardLayout from "../../layouts/Dashboard";
import DashboardNavbar from "../../layouts/Navbars/DashboardNavbar";
import { Title } from "../dashboard/style";
import { getUser } from "../../apiservices/profile";

const Profile = () => {
  const userEmail = localStorage.getItem("userEmail");
  const EditButton = styled(Button)({
    textTransform: "capitalize",
    fontFamily: "Dosis",
    width: "70px",
    fontWeight: "normal",
    fontSize: "14px",
    borderRadius: "4px",
    border: "1px solid #67748E",
    color: "#67748E",
    "&:hover": {
      background: "#67748E",
      color: "#FFFFFF",
    },
    "&:active": {
      background: "#586278",
      color: "#FFFFFF",
    },
  });
  const [state, setState] = useState({
    userData: [],
    editFields: false,
    loader: false,
  });
  const edit = (name) => {
    setState((prev) => ({ ...prev, [name]: !prev.editFields }));
  };
  useLayoutEffect(() => {
    getUsers();
  }, []);

  const getUsers = async () => {
    setState((prev) => ({ ...prev, loader: !prev.loader }));

    let data = {
      _id: userEmail,
    };
    let res = await getUser(data);
    let response = res.userObj;
    setState((prev) => ({
      ...prev,
      userData: response,
    }));
    setState((prev) => ({ ...prev, loader: !prev.loader }));
  };
  return (
    <DashboardLayout>
      <DashboardNavbar>
        <Stack
          sx={{ height: "100px", mx: 1 }}
          alignItems={"center"}
          direction={"row"}
          justifyContent={"space-between"}>
          <Stack>
            <img src={state.editFields ? Setting : Star} alt="" />
          </Stack>
          <Stack>
            <Title>Profile Settings</Title>
          </Stack>
          <Stack>
            {!state.editFields && (
              <EditButton size="small" onClick={() => edit("editFields")}>
                <DriveFileRenameOutlineIcon fontSize="large" sx={{ mx: 0.4 }} />
                Edit
              </EditButton>
            )}
          </Stack>
        </Stack>
      </DashboardNavbar>

      <Stack>
        {!state.loader ? (
          state.editFields ? (
            <ProfileEdit
              handleCancel={(edits) => edit(edits)}
              getUsers={getUsers}
              userData={state.userData}
            />
          ) : (
            <ProfileFields userData={state.userData} />
          )
        ) : (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress color="success" />
          </Box>
        )}
      </Stack>
    </DashboardLayout>
  );
};

export default Profile;
