import React from "react";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import { Stack, styled } from "@mui/system";
import Boxs from "../../assets/images/calender/box.svg";
import Delete from "../../assets/images/calender/delete.svg";
import Notes from "../../assets/images/calender/notes.svg";
import Calendars from "../../assets/images/calender/calendar.svg";
import Account from "../../assets/images/calender/account.svg";
import moment from "moment";
import { TextStyle } from "../dashboard/style";

window.Buffer = window.Buffer || require("buffer").Buffer;
const LeaveModel = ({ popup, closeTaskPopup, events }) => {
  let start = moment(events.extendedProps.leaveDate, "YYYY-MM-DD").format(
    "DD-MM-YYYY"
  );
  let end = moment(events.extendedProps?.leaveEndDate, "YYYY-MM-DD").format(
    "DD-MM-YYYY"
  );
  console.log(events.extendedProps.created);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: "#FFFFFF",
    boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.2)",
    borderRadius: "4px",
    p: 1,
  };
  const LeaveDetail = styled(Stack)({
    // height: "15px",
    width: "450px",
  });

  return (
    <div>
      <Modal
        open={popup}
        onClose={() => closeTaskPopup("leaveModal")}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>
          <LeaveDetail>
            <Stack
              direction="row"
              justifyContent="space-between"
              sx={{ mb: 3, mx: 1 }}>
              <Stack>
                <TextStyle variant="h6">
                  <img src={Boxs} alt="" style={{ marginRight: "10px" }} />
                  {events.title}
                </TextStyle>
                <TextStyle variant="p" sx={{ mx: 3, color: "#67748E" }}>
                  {start}

                  {!events.extendedProps.startT && " To"}
                  <br />

                  {events.extendedProps.startT
                    ? events.extendedProps.startT + "-"
                    : end}
                  {events.extendedProps?.endT}
                </TextStyle>
              </Stack>
              <TextStyle>
                <img src={Delete} alt="" style={{ marginRight: "10px" }} />
                Added:{events.extendedProps.created}
              </TextStyle>
            </Stack>
            <Stack direction="row" sx={{ mb: 5, mx: 1 }}>
              <img src={Notes} alt="" style={{ marginRight: "10px" }} />
              <TextStyle
                sx={{
                  fontSize: "14px",
                  lineBreak: "anywhere",
                }}>
                {events.extendedProps.description}
              </TextStyle>
            </Stack>

            <Stack alignItems="center" sx={{ mb: 1 }}>
              <a
                href={events.extendedProps.attachment}
                target="_blank"
                rel="noreferrer">
                {events.extendedProps.attachment && "view attachment"}
              </a>
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              sx={{ mx: 1 }}>
              <TextStyle sx={{ color: "#67748E" }}>
                {" "}
                <img src={Calendars} alt="" style={{ marginRight: "10px" }} />
                {events.extendedProps.applicantName}
              </TextStyle>
              <TextStyle
                sx={{
                  color:
                    events.extendedProps.status === "pending"
                      ? "#CAA629"
                      : events.extendedProps.status === "approved"
                      ? "#2CCA29"
                      : "#CA2929",
                }}
                variant="h6">
                {" "}
                <img
                  src={Account}
                  alt=""
                  style={{ marginRight: "10px" }}
                  height="15px"
                />
                <span
                  style={{
                    color: "#04B2AE",
                  }}>
                  Admin:
                </span>
                {events.extendedProps.status}
              </TextStyle>
            </Stack>
          </LeaveDetail>
        </Box>
      </Modal>
    </div>
  );
};

export default LeaveModel;
