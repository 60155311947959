import React from "react";
import mbleheading from "../../../assets/images/qurbani/mbleheading.svg";
import sketchheading from "../../../assets/images/qurbani/sketchheading.svg";
import mbleappheading from "../../../assets/images/qurbani/mobileappheading.svg";
import webheading from "../../../assets/images/qurbani/webheading.svg";
import wireframe from "../../../assets/images/qurbani/wireframe.png";
import footerbottem from "../../../assets/images/footer/footerbottem.png";
import { Link ,useNavigate , } from "react-router-dom";
import { Link as Linkscroll,scroller } from "react-scroll";

const Qurbanimobile1 = () => {
  let url = "https://infini8ai-images.s3.amazonaws.com/qurbani";
  const navigate = useNavigate();

  const handleScroll = (section) => {


    navigate('/');
    setTimeout(() => {
      scroller.scrollTo(section, {
        duration: 500,
        smooth: true,
      });
    }, 100);
  };
  return (
    <div>
      <div id="sketchdesign">
        <div style={{ background: "#2DB24C" }} className="row mt-2">
          <img src={`${url}/empathy.png`} className="img-fluid  " />
          <img
            src={sketchheading}
            className="img-fluid  "
            style={{ marginTop: "-100px" }}
          />
          <img src={`${url}/sketch.png`} className="img-fluid  " />
          <img
            src={`${url}/sketch1.png`}
            className="img-fluid  "
            style={{ marginTop: "-40px" }}
          />
          <img src={`${url}/sketch2.png`} className="img-fluid  " />
          <img
            src={`${url}/skech3.png`}
            className="img-fluid  "
            style={{ marginTop: "-40px" }}
          />

          <img src={mbleheading} className="img-fluid  " />
          <img src={`${url}/mblemain.png`} className="img-fluid  " />
        </div>
      </div>
      <div id="mobiledesign">
        <div style={{ background: "#CDCDCD" }} className="row ">
          <img src={wireframe} className="img-fluid mt-5 " />
          <img src={`${url}/wire1.png`} className="img-fluid  " />
          <img src={`${url}/wire2.png`} className="img-fluid  " />
          <img src={`${url}/wire3.png`} className="img-fluid  " />
          <img src={`${url}/wire4.png`} className="img-fluid  " />
          <img src={`${url}/wire5.png`} className="img-fluid  " />
          <img src={`${url}/wire6.png`} className="img-fluid  " />
          <img src={`${url}/wire7.png`} className="img-fluid  " />
          <img src={`${url}/wire8.png`} className="img-fluid  " />
          <img src={`${url}/wire9.png`} className="img-fluid  " />
          <img src={`${url}/wire10.png`} className="img-fluid  " />
          <img src={`${url}/wire11.png`} className="img-fluid  " />
          <img src={`${url}/wire12.png`} className="img-fluid  " />
          <img src={`${url}/wire13.png`} className="img-fluid  " />
        </div>
      </div>
      <div id="mobiledesign1">
        <div style={{ background: "#21C546" }} className="row ">
          <img src={mbleappheading} className="img-fluid  " />
          <img src={`${url}/mbledesign1.png`} className="img-fluid  " />
          <img src={`${url}/mbledesign2.png`} className="img-fluid  " />
        </div>
      </div>
      <div id="webdesign1">
        <div style={{ background: "#21C546" }} className="row ">
          <img src={webheading} className="img-fluid  " />
          <img src={`${url}/web1.png`} className="img-fluid  " />
          <img src={`${url}/web2.png`} className="img-fluid  " />
        </div>
      </div>
      <div className="row">
      <footer
      style={{
        backgroundColor: "rgb(56 127 53)",
        backgroundImage: "none",
      }}
      className="page-footer font-small   pb-3 "
    >
      <div className="container">
        <div className="row footer-row" >
          <div className="col-12 col-lg-3 col-md-4 footer-sitemap">
            <p
              className="fs-6 mt-4"
              style={{ fontFamily: "Dosis",color:"#dde73c", }}
            >
              SITE MAP
            </p>
           
            <div>
                {/* <p style={{ margin: "0 0 20px 0",color: "#116065", fontWeight:600,font:"poppins", fontSize:"24px" }}>Case Studies</p> */}
                <ul  className="text-white mt-4 lh-sm justify-content-md-start justify-content-center"
              style={{ fontFamily: "Dosis" , listStyleType: "none", display:"flex", gap:"4rem"}}>
                <div style={{display:"flex", flexDirection:"column", gap:"3rem"}}>
                   <Link to="/" style={{  }}>
                   <li style={{color:"white",fontSize:"16px"}}>Home</li>
                  </Link>
                  <div
          className="cursor-pointer"
          style={{ cursor: "pointer",  }}
          onClick={() => handleScroll("ourProducts")}
        >
            <li style={{ fontFamily: "Dosis",fontSize:"16px"}}>Products</li>
        </div>
                  {/* <li>About Us</li> */}
                  <Link to="/our-process" >
                  <li style={{color:"white",fontSize:"16px"}}>Our Process</li>
                  </Link>
                  </div>
                  <div  style={{display:"flex", flexDirection:"column", gap:"3rem"}}>
                  <Link to="/team" >
                  <li style={{ color:"white",fontSize:"16px" }}>Team</li>
                  </Link>
                  <Link to="/portfolio" >
                  <li style={{ color:"white",fontSize:"16px"}}>Portfolio</li>
                  </Link>
                  <Link to="/careers" >
                  <li style={{ color:"white",fontSize:"16px"}}>Careers</li>
                  </Link>
                  </div>
                </ul>
                </div>
          </div>
          <div className="col-12 col-lg-3 col-md-4 footer-contactus">
            <p
              className="fs-6 mt-4 "
              style={{ fontFamily: "Dosis",color:"#dde73c" }}
            >
              CONTACT US
            </p>
            <p  
              className="text-white mt-4 lh-sm"
              style={{ fontFamily: "Dosis" ,fontSize:"16px"}}
            >
              IDEA London Ground Floor 69 Wilson Street, London EC2A 2BB
              <p className="mt-4 mb-4 text-white lh-sm"   style={{ fontFamily: "Dosis",fontSize:"16px" }}>For hiring inquiries please contact: <span style={{fontFamily: "Dosis",fontSize:"16px"}}>hr@infini8ai.com</span></p> 
              
            </p>
            <p
              className="text-white mt-4 lh-sm"
              style={{ fontFamily: "Dosis" }}
            >
          
              <p className="mt-4 mb-4 text-white lh-sm"   style={{ fontFamily: "Dosis",fontSize:"16px" }}>For all other queries please contact: <span style={{fontFamily: "Dosis",fontSize:"16px"}}>info@infini8ai.com </span> </p> 
              
            </p>
            <div className=" mt-2">
          <p style={{ color: "white",fontSize:"16px" }}>2024 Copyright: Infini8AI</p>
        </div>

          </div>
          <div className="col-12 col-lg-3 col-md-4 footer-casestudies" >
            <p
              className="fs-6  mt-4  "
              style={{ fontFamily: "Dosis",color:"#dde73c" }}
            >
              CASE   STUDIES
            </p>
            {/* <p
              className="text-white mt-4 lh-sm"
              style={{ fontFamily: "Bitter" }}
            >
              To receive updates about our new product offerings and latest
              developments in the world of AI, join our bi-weekly newsletter:{" "}
              <a href="https://veracious.ai/news-letter" target="_blank">
                Veracious AI
              </a>
            </p> */}
              <div>
                {/* <p style={{ margin: "0 0 20px 0",color: "#116065", fontWeight:600,font:"poppins", fontSize:"24px" }}>Case Studies</p> */}
                <ul  className="text-white mt-4 lh-sm"
              style={{ fontFamily: "Dosis" , listStyleType: "none", display:"flex",  gap:"4rem"}}>
                    <div  style={{display:"flex", flexDirection:"column", gap:"3rem"}}>

                    <Link to="/optimus-case-study">
                    <li style={{color:"white",fontSize:"16px"}}>Healthcare</li>
                    </Link>
                    <Link to="/naseem-case-study">
                    <li style={{color:"white",fontSize:"16px"}}>Education</li>
                    </Link>
                    <Link to="/qurbani-case-study">
                    <li style={{color:"white",fontSize:"16px"}}>Livestock</li>
                    </Link>
                    </div>
                    <div  style={{display:"flex", flexDirection:"column", gap:"3rem"}}>

                    <Link to="/stock-chain-case-study">
                    <li style={{color:"white",fontSize:"16px"}}>Logistics</li>
                    </Link>
                    <Link to="/businesspal-case-study">
                    <li style={{color:"white",fontSize:"16px"}}>Business</li>
                    </Link>
                    </div>
                </ul>
                </div>

          </div>
        </div>
        
      </div>
    </footer>
      </div>
    </div>
  );
};

export default Qurbanimobile1;
