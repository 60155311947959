import { Box, Stack, styled } from "@mui/system";
import React, { useLayoutEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Grid, Typography } from "@mui/material";
import ReplyAllOutlinedIcon from "@mui/icons-material/ReplyAllOutlined";
import { AddProject } from "../../../assets/Svg/svg";
import Logo from "../../../assets/images/Employees/task.png";
import CardImg from "../../../assets/images/Employees/cardimg.svg";
import AssignedImg from "../../../assets/images/Employees/assigned.svg";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import TaskDetailModal from "./TaskDetailModel";
import DashboardLayout from "../../../layouts/Dashboard";
import DashboardNavbar from "../../../layouts/Navbars/DashboardNavbar";
import { getTasksByMember } from "../../../apiservices/task";
import NoTask from "../../../assets/images/notask.svg";
import ViewProject from "./TaskModel";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import uploadimage from "../../../assets/images/project/add_a_photo.svg";

const TaskReport = () => {
  let navigate = useNavigate();
  let location = useLocation();

  const userEmail = location.state.empObj.email;
  const [state, setState] = useState({
    viewProject: false,
    viewTaskDetail: false,
    cardsData: [],
    taskObj: {},
  });
  console.log("helo salmana", state.cardsData);

  const ViewMemberButton = styled(Button)({
    height: "32px",
    // width: "140px",
    borderRadius: "4px",
    padding: " 4px 8px 4px 8px",
    border: "1px solid #67748E",
    color: "#67748E",
    fontSize: "15px",
    textTransform: "capitalize",
    "&:hover": {
      background: "#67748E",
      color: "#FFFFFF",
    },
    "&:active": {
      // background: "#586278",
      color: "#FFFFFF",
    },
  });
  const TaskHeader = styled(Box)({
    height: "40px",
    width: "240px",
    borderRadius: "6px",
    background: "#04B2AE",
    boxShadow: " 0px 4px 20px rgba(0, 0, 0, 0.16)",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    color: "#FFFFFF",
  });
  const BackButton = styled(Button)({
    borderRadius: "4px",
    padding: "4px 8px 4px 8px",
    color: "#67748E",
    border: "1px solid #67748E",
    "&:hover": {
      background: "#67748E",
      color: "#FFFFFF",
    },
    "&:active": {
      background: "#586278",
      color: "#FFFFFF",
    },
  });
  const Cards = styled(Stack)({
    // minHeight: "130px",
    width: "320px",
    borderRadius: "6px",
    border: "1px solid",
    background: "#FFFFFF",
    boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.08)",
  });
  const DragIcon = styled(Box)({
    margin: "5px",
    borderRadius: "2px",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    color: "grey",
    justifyContent: "center",
    "&:hover": {
      background: "#67748E",
      color: "#ffffff",
    },
  });
  useLayoutEffect(() => {
    getallTask();
    console.log(state.cardsData);
  }, []);
  const back = () => {
    navigate("/employees", {
      state: {
        dialogue: true,
        empName: location.state.empName,
      },
    });
  };
  const getallTask = async () => {
    let data = {
      member: userEmail,
    };
    let res = await getTasksByMember(data);
    setState((prev) => ({ ...prev, cardsData: res.tasksArr }));
  };
  const viewTask = (view, item = "") => {
    setState((prev) => ({
      ...prev,
      [view]: !prev.viewTaskDetail,
      taskObj: item,
    }));
  };
  const view = (view, item = "") => {
    setState((prev) => ({
      ...prev,
      [view]: !prev.viewProject,
      taskObj: item,
    }));
  };

  return (
    <DashboardLayout>
      <DashboardNavbar>
        <Grid container spacing={2}>
          <Grid item xs={1}>
            <img src={Logo} alt="img" height="50px" width="50px" />
          </Grid>
          <Grid
            item
            xs={7}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              // mx: 4,
            }}>
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              Employee Tasks
            </Typography>
          </Grid>
          <Grid
            item
            xs={3.8}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              // mx: 4,
            }}>
            <Stack direction="row">
              {/* <Stack direction="row" alignItems="center">
                <Typography variant="p">From:</Typography>
                <input
                  type="month"
                  id="att_date_input"
                  defaultValue="2022-01"
                  placeholder="From"
                  onChange={(e) => handleChange(e)}
                  style={{
                    border: "1px solid #67748E",
                    borderRadius: "4px",
                    marginRight: "10px",
                    height: "32px",
                  }}
                />
              </Stack>
              <Stack direction="row" alignItems="center">
                <Typography variant="p">To:</Typography>

                <input
                  type="month"
                  id="att_date_input"
                  defaultValue="2022-01"
                  placeholder="To"
                  onChange={(e) => handleChange(e)}
                  style={{
                    border: "1px solid #67748E",
                    borderRadius: "4px",
                    marginRight: "10px",
                    height: "32px",
                  }}
                />
              </Stack> */}
              <ViewMemberButton
                size="small"
                onClick={() => view("viewProject")}
                sx={{ mx: 0.5 }}>
                <AddProject />
                &nbsp; View Projects
              </ViewMemberButton>
              <BackButton size="small" onClick={back}>
                <ReplyAllOutlinedIcon />
              </BackButton>
            </Stack>
          </Grid>
        </Grid>
      </DashboardNavbar>
      <Stack direction="row" justifyContent="center" sx={{ mt: 2, ml: "20px" }}>
        <Stack sx={{ p: 1 }}>
          <TaskHeader>
            <Typography>Assigned</Typography>
          </TaskHeader>
          <Stack sx={{ background: "#F6F4F1" }} alignItems="center">
            {state.cardsData && state.cardsData.length !== 0 ? (
              state.cardsData.map(
                (item) =>
                  item.journey === "assigned" && (
                    <Cards
                      sx={{
                        m: 0.5,
                        mt: 2,
                        p: 1,
                        color: "#67748E",
                        width: "240px",
                      }}
                      onClick={() => viewTask("viewTaskDetail", item)}>
                      <Stack direction="row" justifyContent="space-between">
                        <Stack>
                          <img
                            src={uploadimage}
                            alt="img"
                            width="60px"
                            height="30px"
                          />
                        </Stack>
                        <Stack alignItems="center">
                          <DragIcon>
                            <DragIndicatorIcon />
                          </DragIcon>
                        </Stack>
                      </Stack>
                      <Stack direction="row" alignItems="center" sx={{ m: 1 }}>
                        <CheckCircleOutlineIcon />
                        <Typography sx={{ fontSize: "15px" }}>
                          {item.task}
                        </Typography>
                      </Stack>
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center">
                        <Stack direction="row" alignItems="center">
                          {location.state.empObj.imageUrl && (
                            <img
                              src={
                                location.state.empObj.imageUrl &&
                                location.state.empObj.imageUrl
                              }
                              alt="assigned"
                              width="30px"
                              height="30px"
                            />
                          )}
                          <Stack ml={2}>
                            <Typography sx={{ fontSize: "12px" }}>
                              Assigned To:
                            </Typography>
                            {item.members.map((data) => (
                              <Typography sx={{ fontSize: "12px" }}>
                                {data.name}
                              </Typography>
                            ))}
                          </Stack>
                        </Stack>
                        <Stack>
                          <Typography
                            sx={{ color: "#21A49E", fontSize: "12px" }}>
                            {item?.projects[0]}
                          </Typography>
                          <Typography sx={{ fontSize: "12px" }}>
                            Due:{item.deadline}
                          </Typography>
                        </Stack>
                      </Stack>
                    </Cards>
                  )
              )
            ) : (
              <img src={NoTask} alt="No task assigned" height="160px"></img>
            )}
          </Stack>
        </Stack>
        <Stack sx={{ p: 1 }}>
          <TaskHeader>
            <Typography>Ongoing</Typography>
          </TaskHeader>
          <Stack sx={{ background: "#F6F4F1" }} alignItems="center">
            {state.cardsData.map(
              (item) =>
                item.journey === "inprogress" && (
                  <Cards
                    sx={{
                      m: 0.5,
                      mt: 2,
                      p: 1,
                      color: "#67748E",
                      width: "240px",
                    }}
                    onClick={() => viewTask("viewTaskDetail", item)}>
                    <Stack direction="row" justifyContent="space-between">
                      <Stack>
                        <img
                          src={item.imageUrl ? item.imageUrl : CardImg}
                          alt="img"
                          width="60px"
                          height="30px"
                        />
                      </Stack>
                      <Stack alignItems="center">
                        <DragIcon>
                          <DragIndicatorIcon />
                        </DragIcon>
                      </Stack>
                    </Stack>
                    <Stack direction="row" alignItems="center" sx={{ m: 1 }}>
                      <CheckCircleOutlineIcon />
                      <Typography sx={{ fontSize: "15px" }}>
                        {item.task}
                      </Typography>
                    </Stack>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center">
                      <Stack direction="row" alignItems="center">
                        <img
                          src={AssignedImg}
                          alt="assigned"
                          width="50px"
                          height="50px"
                        />
                        <Stack>
                          <Typography sx={{ fontSize: "12px" }}>
                            Assigned To:
                          </Typography>
                          {item.members.map((data) => (
                            <Typography sx={{ fontSize: "12px" }}>
                              {data.name}
                            </Typography>
                          ))}
                        </Stack>
                      </Stack>
                      <Stack>
                        <Typography sx={{ color: "#21A49E", fontSize: "12px" }}>
                          {item?.projects[0]}
                        </Typography>
                        <Typography sx={{ fontSize: "12px" }}>
                          Due:{item.deadline}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Cards>
                )
            )}
          </Stack>
        </Stack>
        <Stack sx={{ p: 1 }}>
          <TaskHeader>
            <Typography>Test</Typography>
          </TaskHeader>
          <Stack sx={{ background: "#F6F4F1" }} alignItems="center">
            {state.cardsData.map(
              (item) =>
                item.journey === "test" && (
                  <Cards
                    sx={{
                      m: 0.5,
                      mt: 2,
                      p: 1,
                      color: "#67748E",
                      width: "240px",
                    }}
                    onClick={() => viewTask("viewTaskDetail", item)}>
                    <Stack direction="row" justifyContent="space-between">
                      <Stack>
                        <img
                          src={item.imageUrl ? item.imageUrl : CardImg}
                          alt="img"
                          width="60px"
                          height="30px"
                        />
                      </Stack>
                      <Stack alignItems="center">
                        <DragIcon>
                          <DragIndicatorIcon />
                        </DragIcon>
                      </Stack>
                    </Stack>
                    <Stack direction="row" alignItems="center" sx={{ m: 1 }}>
                      <CheckCircleOutlineIcon />
                      <Typography sx={{ fontSize: "15px" }}>
                        {item.task}
                      </Typography>
                    </Stack>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center">
                      <Stack direction="row" alignItems="center">
                        <img
                          src={AssignedImg}
                          alt="assigned"
                          width="50px"
                          height="50px"
                        />
                        <Stack>
                          <Typography sx={{ fontSize: "12px" }}>
                            Assigned To:
                          </Typography>
                          {item.members.map((data) => (
                            <Typography sx={{ fontSize: "12px" }}>
                              {data.name}
                            </Typography>
                          ))}
                        </Stack>
                      </Stack>
                      <Stack>
                        <Typography sx={{ color: "#21A49E", fontSize: "12px" }}>
                          {item?.projects[0]}
                        </Typography>
                        <Typography sx={{ fontSize: "12px" }}>
                          Due:{item.deadline}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Cards>
                )
            )}
          </Stack>
        </Stack>
        <Stack sx={{ p: 1 }}>
          <TaskHeader>
            <Typography>Done</Typography>
          </TaskHeader>
          <Stack sx={{ background: "#F6F4F1" }} alignItems="center">
            {state.cardsData.map(
              (item) =>
                item.journey === "completed" && (
                  <Cards
                    sx={{
                      m: 0.5,
                      mt: 2,
                      p: 1,
                      color: "#67748E",
                      background: "#9CCC6580",
                      width: "240px",
                    }}
                    onClick={() => viewTask("viewTaskDetail", item)}>
                    <Stack direction="row" justifyContent="space-between">
                      <Stack>
                        <img
                          src={item.imageUrl ? item.imageUrl : CardImg}
                          alt="img"
                          width="60px"
                          height="30px"
                        />
                      </Stack>
                      <Stack alignItems="center">
                        <DragIcon>
                          <DragIndicatorIcon />
                        </DragIcon>
                      </Stack>
                    </Stack>
                    <Stack direction="row" alignItems="center" sx={{ m: 1 }}>
                      <CheckCircleOutlineIcon />
                      <Typography sx={{ fontSize: "15px" }}>
                        {item.task}
                      </Typography>
                    </Stack>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center">
                      <Stack direction="row" alignItems="center">
                        <img
                          src={AssignedImg}
                          alt="assigned"
                          width="50px"
                          height="50px"
                        />
                        <Stack>
                          <Typography sx={{ fontSize: "12px" }}>
                            Assigned To:
                          </Typography>
                          {item.members.map((data) => (
                            <Typography sx={{ fontSize: "12px" }}>
                              {data.name}
                            </Typography>
                          ))}
                        </Stack>
                      </Stack>
                      <Stack>
                        <Typography sx={{ color: "#21A49E", fontSize: "12px" }}>
                          {item?.projects[0]}
                        </Typography>
                        <Typography sx={{ fontSize: "12px" }}>
                          Due:{item.deadline}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Cards>
                )
            )}
          </Stack>
        </Stack>
      </Stack>

      <TaskDetailModal
        popup={state.viewTaskDetail}
        closeTaskPopup={(name) => viewTask(name)}
        selectedRow={state.taskObj}
      />
      <ViewProject
        popup={state.viewProject}
        closeTaskPopup={(name) => view(name)}
        selectedRow={state.taskObj}
        userEmail={userEmail}
      />
    </DashboardLayout>
  );
};

export default TaskReport;
