import React from "react";
import { Grid, InputAdornment, InputLabel, OutlinedInput } from "@mui/material";
import { Stack, Typography, Button, FormControl } from "@mui/material";
import ClockImg from "../../assets/images/clock img.png";
import { Title } from "../../user portal/dashboard/style";
import DashboardLayout from "../../layouts/Dashboard";
import DashboardNavbar from "../../layouts/Navbars/DashboardNavbar";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import Repo from "../../Repository/Repo";
import Toaster from "../../components/Toaster";

const schema = yup
  .object({
    monthlyHours: yup.number().positive().required(),
    weeklyHours: yup.number().positive().required(),
    dailyHours: yup.number().positive().required(),
    lunchHours: yup.number().positive().required(),
    lunchTime: yup.string().required(),
    clockIn: yup.string().required(),
    clockOut: yup.string().required(),
  })
  .required();

function AddTracking() {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleClear = () => {
    reset();
  };

  const submit = async (formData) => {
    try {
      let { data } = await Repo.time_track({
        request: {
          method: "saveCompanyTimeTrack",
          data: {
            company_code: localStorage.getItem("companyCode"),
            monthly_hours: formData.monthlyHours,
            daily_hours: formData.weeklyHours,
            lunch_time: formData.lunchTime,
            clockIn: formData.clockIn,
            clockOut: formData.clockOut,
            weekly_hours: formData.weeklyHours,
            lunch_hours: formData.lunchHours,
          },
        },
      });
      console.log("data:", data);
      if (data) {
        reset();
        Toaster("success", "Time Tracking Added Successfully");
      } else {
        Toaster("error", "Error");
      }
    } catch (err) {
      console.log("err:", err);
      // Toast("error", "Error");
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar>
        <Grid container>
          <Grid item xs={1} sx={{ display: "flex", alignItems: "center" }}>
            <img
              style={{ marginLeft: "10px" }}
              height="50px"
              src={ClockImg}
              alt=""
            />
          </Grid>
          <Grid
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            item
            xs={11}
          >
            <Title>Employee Time Tracking</Title>
          </Grid>
        </Grid>
      </DashboardNavbar>
      <Grid
        container
        columnSpacing={5}
        rowSpacing={3}
        sx={{ p: { xs: 1.6, md: "31px 80px" } }}
      >
        <Grid item md={6} xs={12}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel sx={{ color: "#21A49E" }}>Monthly Hours</InputLabel>
            <OutlinedInput
              type="number"
              label="Monthly Hours"
              {...register("monthlyHours")}
              sx={{ background: "#ffffff" }}
              endAdornment={
                <InputAdornment position="end">
                  <AccessTimeIcon />
                </InputAdornment>
              }
            />
            {errors?.monthlyHours && (
              <Typography sx={{ fontSize: "14px", color: "red" }}>
                Monthly Hours is required
              </Typography>
            )}
          </FormControl>
        </Grid>
        <Grid item md={6} xs={12}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel sx={{ color: "#21A49E" }}>Weekly Hours</InputLabel>
            <OutlinedInput
              type="number"
              label="Weekly Hours"
              {...register("weeklyHours")}
              sx={{ background: "#ffffff" }}
              endAdornment={
                <InputAdornment position="end">
                  <AccessTimeIcon />
                </InputAdornment>
              }
            />
            {errors?.weeklyHours && (
              <Typography sx={{ fontSize: "14px", color: "red" }}>
                Weekly Hours is required
              </Typography>
            )}
          </FormControl>
        </Grid>
        <Grid item md={6} xs={12}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel sx={{ color: "#21A49E" }}>Daily Hours</InputLabel>
            <OutlinedInput
              type="number"
              label="Daily Hours"
              placeholder="08"
              {...register("dailyHours")}
              sx={{ background: "#ffffff" }}
              endAdornment={
                <InputAdornment position="end">
                  <AccessTimeIcon />
                </InputAdornment>
              }
            />
            {errors?.dailyHours && (
              <Typography sx={{ fontSize: "14px", color: "red" }}>
                Daily Hours is required
              </Typography>
            )}
          </FormControl>
        </Grid>
        <Grid item md={6} xs={12}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel sx={{ color: "#21A49E" }}>Lunch Hours</InputLabel>
            <OutlinedInput
              name="lunchHours"
              label="Lunch Hours"
              placeholder="01"
              {...register("lunchHours")}
              sx={{ background: "#ffffff" }}
              endAdornment={
                <InputAdornment position="end">
                  <AccessTimeIcon />
                </InputAdornment>
              }
            />
            {errors?.lunchHours && (
              <Typography sx={{ fontSize: "14px", color: "red" }}>
                Lunch Hour is required
              </Typography>
            )}
          </FormControl>
        </Grid>
        <Grid item md={6} xs={12}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel sx={{ color: "#21A49E" }}>Lunch Time</InputLabel>
            <OutlinedInput
              label="Lunch Time"
              placeholder="09:00 AM"
              {...register("lunchTime")}
              sx={{ background: "#ffffff" }}
              endAdornment={
                <InputAdornment position="end">
                  <AccessTimeIcon />
                </InputAdornment>
              }
            />
            {errors?.lunchTime && (
              <Typography sx={{ fontSize: "14px", color: "red" }}>
                Lunch Time is required
              </Typography>
            )}
          </FormControl>
        </Grid>
        <Grid item md={6} xs={12}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel sx={{ color: "#21A49E" }}>Clock-In</InputLabel>
            <OutlinedInput
              label="Clock-In"
              placeholder="09:00 AM"
              {...register("clockIn")}
              sx={{ background: "#ffffff" }}
              endAdornment={
                <InputAdornment position="end">
                  <AccessTimeIcon />
                </InputAdornment>
              }
            />
            {errors?.clockIn && (
              <Typography sx={{ fontSize: "14px", color: "red" }}>
                Clock-In is required
              </Typography>
            )}
          </FormControl>
        </Grid>
        <Grid item md={6} xs={12}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel sx={{ color: "#21A49E" }}>Clock-Out</InputLabel>
            <OutlinedInput
              label="Clock-Out"
              placeholder="06:00 PM"
              {...register("clockOut")}
              sx={{ background: "#ffffff" }}
              endAdornment={
                <InputAdornment position="end">
                  <AccessTimeIcon />
                </InputAdornment>
              }
            />
            {errors?.clockOut && (
              <Typography sx={{ fontSize: "14px", color: "red" }}>
                Clock-Out is required
              </Typography>
            )}
          </FormControl>
        </Grid>
        <Grid item md={6} xs={12}>
          <Stack direction="row" width="100%" justifyContent="space-between">
            <Button
              variant="contained"
              sx={{
                background: "#FFFFFF !important",
                textTransform: "none",
                color: "#A7A7A7",
                boxShadow: "none",
                px: 6.4,
              }}
              onClick={handleClear}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={{
                background: "#21A49E !important",
                textTransform: "none",
                color: "#ffffff",
                px: 6.4,
              }}
              onClick={handleSubmit(submit)}
            >
              Save
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
}

export default AddTracking;
