import { useState } from "react";
import { useEffect } from "react";
import { Box, styled } from "@mui/system";
import {
  Button,
  CircularProgress,
  Grid,
  Popover,
  Stack,
  Typography,
} from "@mui/material";
import ProjectLogo from "../../assets/images/team/projecticon.png";
import Projects from "../../assets/images/dashboard/folder.png";
import ReplyAllOutlinedIcon from "@mui/icons-material/ReplyAllOutlined";
import Task from "./AddProject";
import { AddProject, Members } from "../../assets/Svg/svg";
import DashboardLayout from "../../layouts/Dashboard";
import DashboardNavbar from "../../layouts/Navbars/DashboardNavbar";
import { deleteProject, getProjectsByCompany } from "../../apiservices/project";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import NoProject from "../../assets/images/noproject.svg";
import DialogComponent from "../../user portal/reuseable components/Dialog";
import AddNewProject from "./AddNew";
import { toast } from "react-toastify";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";

const Project = () => {
  const Btn = styled(Button)({
    color: "#67748E",
    textTrasform: "capitalize",
    "&:hover": {
      color: "#67748E",
    },
  });
  const StylePop = styled(Stack)({
    background: "#FFFFFF",
    display: "flex",
    flexDirection: "column",
  });
  const [state, setState] = useState({
    projectData: [],
    selected: false,
    viewMembers: false,
    project: {},
    loader: false,
    isEdit: false,
    anchorEl: "",
    editProject: {},
  });
  const open = state.anchorEl;
  const id = open ? "simple-popover" : undefined;
  const selectedTeam = (data) => {
    setState((prev) => ({ ...prev, selected: !prev.selected, project: data }));
  };
  const ViewMemberButton = styled(Button)({
    // height: "26px",
    width: "150px",
    borderRadius: "4px",
    padding: " 4px 8px 4px 8px",
    border: "1px solid #67748E",
    color: "#67748E",
    fontSize: "15px",
    textTransform: "capitalize",
    "&:hover": {
      background: "#67748E",
      color: "#FFFFFF",
    },
    "&:active": {
      background: "#586278",
      color: "#FFFFFF",
    },
  });
  const TeamCard = styled(Box)({
    height: "100px",
    maxWidth: "450px",
    borderRadius: "8px",
    borderLeft: "2px solid transparent",
    background: "#F5F5F5",
    margin: "20px",
    transition: "0.5s",
    "&:hover": {
      background: "#FFFFFF",
      borderLeft: "3px solid #04B2AE",
      boxShadow: "0px 4px 10px 0px #EBEBEB",
    },
    "&:active": {
      background: "#F5F5F5",
      borderLeft: "0px solid #04B2AE",
      boxShadow: "0px 0px 0px 0px #EBEBEB ",
    },
  });
  const BackButton = styled(Button)({
    borderRadius: "4px",
    padding: "4px 8px 4px 8px",
    color: "#67748E",
    border: "1px solid #67748E",
    "&:hover": {
      background: "#67748E",
      color: "#FFFFFF",
    },
    "&:active": {
      background: "#586278",
      color: "#FFFFFF",
    },
  });
  const DragIcon = styled(Box)({
    margin: "5px",
    borderRadius: "2px",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    color: "grey",
    justifyContent: "center",
    "&:hover": {
      background: "#67748E",
      color: "#ffffff",
    },
    "&:active": {
      background: "#67748E",
      color: "#ffffff",
    },
  });

  const getAllProjects = async () => {
    setState((prev) => ({ ...prev, loader: !prev.loader }));
    let data = {
      company_code: localStorage.getItem("companyCode"),
    };
    let res = await getProjectsByCompany(data);
    setState((prev) => ({ ...prev, projectData: res.projectsArr }));
    setState((prev) => ({ ...prev, loader: !prev.loader }));
  };

  useEffect(() => {
    getAllProjects();
  }, []);
  const viewMembersModel = () => {
    setState((prev) => ({ ...prev, viewMembers: !prev.viewMembers }));
  };
  const addProject = (item = "") => {
    setState((prev) => ({
      ...prev,
      addModal: !prev.addModal,
      editProject: item,
      isEdit: false,
    }));
  };
  const editProject = (item = "") => {
    setState((prev) => ({
      ...prev,
      isEdit: !prev.isEdit,
      addModal: !prev.addModal,
      editProject: item,
    }));
  };
  const removeProject = async (item) => {
    let data = {
      _id: item._id,
    };
    await deleteProject(data);
    toast.error("Project deleted successfully");

    getAllProjects();
  };
  const handleClick = (event) => {
    setState((prev) => ({ ...prev, anchorEl: event.currentTarget }));
  };
  return (
    <>
      <DialogComponent
        title="Item History"
        open={state.addModal}
        onClose={addProject}
        onClickCancel={addProject}
        // dialogTitle={"Summary"}
        dialogContent={
          <>
            <AddNewProject
              editRow={state.editProject}
              isEdit={state.isEdit}
              getAllProjects={getAllProjects}
              closeTeamPopup={addProject}
            />
          </>
        }
      />
      <DashboardLayout>
        <DashboardNavbar>
          <Grid container spacing={2}>
            <Grid item xs={1} sx={{ mx: 1 }}>
              <img src={ProjectLogo} alt="img" />
            </Grid>
            <Grid
              item
              xs={state.selected ? 7 : 9.2}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}>
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                Projects
              </Typography>
            </Grid>

            {state.selected ? (
              <>
                <Grid xs={0.8}></Grid>
                <Grid
                  item
                  xs={2.8}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}>
                  <Stack direction="row">
                    <ViewMemberButton
                      size="small"
                      onClick={viewMembersModel}
                      sx={{ mx: 1 }}>
                      <Members />
                      View Members
                    </ViewMemberButton>
                    <BackButton size="small" onClick={selectedTeam}>
                      <ReplyAllOutlinedIcon />
                    </BackButton>
                  </Stack>
                </Grid>
              </>
            ) : (
              <Grid
                item
                xs={1.5}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}>
                <BackButton
                  onClick={addProject}
                  size="small"
                  sx={{ textTransform: "capitalize" }}>
                  <AddProject />
                  &nbsp; Add Project
                </BackButton>
              </Grid>
            )}
          </Grid>
        </DashboardNavbar>
        {!state.selected ? (
          <Grid container sx={{ m: 3 }}>
            {state.projectData && state.projectData.length !== 0 ? (
              state.projectData.map((item) => (
                <Grid item xs={12} md={6}>
                  <TeamCard>
                    <Stack direction="row" justifyContent="space-between">
                      <Stack
                        direction="row"
                        alignItems="center"
                        onClick={() => selectedTeam(item)}>
                        <Stack justifyContent="center" sx={{ m: 1 }}>
                          <img
                            src={item.imageUrl ? item.imageUrl : Projects}
                            alt="one"
                            width="80px"
                            height="80px"
                            style={{ borderRadius: 2 }}
                          />
                        </Stack>
                        <Stack sx={{ mx: 3 }}>
                          <Typography sx={{ color: "#67748E" }}>
                            {item.name}
                          </Typography>
                        </Stack>
                      </Stack>
                      <Stack direction="column" sx={{ alignItems: "end" }}>
                        <PopupState variant="popover" popupId="demo-popup-menu">
                          {(popupState) => (
                            <>
                              <Button
                                onClick={handleClick}
                                aria-describedby={id}
                                {...bindTrigger(popupState)}>
                                <DragIcon>
                                  <DragIndicatorIcon />
                                </DragIcon>
                              </Button>
                              <Popover {...bindMenu(popupState)}>
                                <StylePop>
                                  <Btn
                                    sx={{ p: 2 }}
                                    onClick={() => editProject(item)}>
                                    Edit project
                                  </Btn>
                                  <Btn
                                    sx={{ p: 2 }}
                                    onClick={() => removeProject(item)}>
                                    Remove
                                  </Btn>
                                </StylePop>
                              </Popover>
                            </>
                          )}
                        </PopupState>
                      </Stack>
                    </Stack>
                  </TeamCard>
                </Grid>
              ))
            ) : (
              <Grid
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "center" }}>
                {state.loader ? (
                  <Box sx={{ display: "flex" }}>
                    <CircularProgress color="success" />
                  </Box>
                ) : (
                  <img src={NoProject} alt="" height="350px" width="400px" />
                )}
              </Grid>
            )}
          </Grid>
        ) : (
          <Task
            viewMembers={state.viewMembers}
            projectData={state.project}
            viewMembersModel={viewMembersModel}
          />
        )}
      </DashboardLayout>
    </>
  );
};

export default Project;
