import axios from "axios";
import moment from "moment";

export const getEmployeeAttendance = async (userData) => {
  let res = await axios.post(`https://infini8ai.herokuapp.com/attendance`, {
    request: {
      method: "getEmployeeAttendance",
      data: userData,
    },
  });
  let json = res.data.response.data;
  return json;
};

export const getEmployeeAttendanceSummary = async (userData) => {
  let res = await axios.post(`https://infini8ai.herokuapp.com/attendance`, {
    request: {
      method: "getEmployeeAttendanceSummary",
      data: userData,
    },
  });
  let json = res.data.response.data;
  return json;
};

export const getAllEmployeesAttendance = async (date) => {
  let res = await axios.post(`https://infini8ai.herokuapp.com/attendance`, {
    request: {
      method: "getAllEmployeesAttendance",
      data: {
        company_code: localStorage.getItem("companyCode"),
        date: date
          ? moment(date).format("DD-MM-YYYY")
          : moment().format("DD-MM-YYYY"),
      },
    },
  });
  let json = res.data.response.data;
  return json;
};
export const saveAttendance = async (userData) => {
  let res = await axios.post(`https://infini8ai.herokuapp.com/attendance`, {
    request: {
      method: "saveAttendance",
      data: userData,
    },
  });
  let json = res.data.response.data;
  return json;
};

export const updateAttendance = async (userData) => {
  let res = await axios.post(`https://infini8ai.herokuapp.com/attendance`, {
    request: {
      method: "updateAttendance",
      data: userData,
    },
  });
  let json = res.data.response.data;
  return json;
};

export const getAllEmployeesAttendanceSummary = async (date) => {
  let res = await axios.post(`https://infini8ai.herokuapp.com/attendance`, {
    request: {
      method: "getAllEmployeesAttendanceSummary",
      data: {
        company_code: localStorage.getItem("companyCode"),
        month: date
          ? moment(date).format("MM-YYYY")
          : moment().format("MM-YYYY"),
      },
    },
  });
  let json = res.data.response.data;
  return json;
};
