import React, { useState } from "react";
import { Gantt } from "gantt-task-react";
import "gantt-task-react/dist/index.css";
import {
  Button,
  Dialog,
  DialogContent,
  Typography,
  Stack,
} from "@mui/material";
import { Box } from "@mui/system";
import GanttChart from "./GanttChart/GanttChart";
function GanttView({ allGroups }) {
  const [open, setOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const [startDate, setStartDate] = useState(null); // State for start date
  const [endDate, setEndDate] = useState(null); // State for end date
  let tasks = [];

  allGroups?.forEach((grp) => {
    grp?.tasks?.forEach((tsk) => {
      // Parse the estimatedTimeline property into Date objects
      const estimatedTimelineParts = tsk.estimatedTimeline.split(" - ");
      const taskStartDate = new Date(estimatedTimelineParts[0]);
      const taskEndDate = new Date(estimatedTimelineParts[1]);

      // Check if the dates are valid before pushing the task
      if (!isNaN(taskStartDate.getTime()) && !isNaN(taskEndDate.getTime())) {
        const taskNameWithGroup = `${grp.groupName} - ${tsk.taskName}`;
        const taskId = `Task-${grp._id}-${tsk._id}`;

        // Construct the task object and push it to tasks array
        tasks.push({
          start: taskStartDate,
          end: taskEndDate,
          name: taskNameWithGroup,
          id: taskId,
          type: "task",
          progress:
            tsk.status === "Pending"
              ? 0
              : tsk.status === "InProgress"
              ? 40
              : tsk.status === "Testing"
              ? 80
              : tsk.status === "DOne"
              ? 100
              : "",
          isDisabled: true,
          styles: {
            progressColor: "red",
            progressSelectedColor: "blue",
          },
          groupId: grp._id,
          taskDetails: tsk,
        });
      } else {
        // Log an error or handle invalid date format
        console.error(`Invalid date format for task: ${tsk.taskName}`);
      }
    });
  });

  const handleTaskClick = (task) => {
    setSelectedTask(task.taskDetails); // Set the selected task details
    setStartDate(task.start); // Set the start date
    setEndDate(task.end); // Set the end date
    setOpen(true); // Open the modal
  };

  const handleModalClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog open={open} onClose={handleModalClose}>
        <DialogContent>
          {selectedTask && (
            <Box
              sx={{
                padding: "20px",
                width: "400px",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}>
                <Typography
                  variant="subtitle1"
                  fontWeight="600"
                  fontSize="14px">
                  TaskName:
                </Typography>
                <Typography variant="body1" fontWeight="400" fontSize="14px">
                  {selectedTask.taskName}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}>
                <Typography
                  variant="subtitle1"
                  fontWeight="600"
                  fontSize="14px">
                  Assignee:
                </Typography>
                <Typography variant="body1" fontSize="14px" fontWeight="400">
                  {selectedTask.assignee}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}>
                <Typography
                  variant="subtitle1"
                  fontSize="14px"
                  fontWeight="600">
                  Status:
                </Typography>
                <Typography variant="body1" fontSize="14px" fontWeight="400">
                  {selectedTask.status}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}>
                <Typography
                  variant="subtitle1"
                  fontWeight="600"
                  fontSize="14px">
                  Start Date:
                </Typography>
                <Typography variant="body1" fontSize="14px" fontWeight="400">
                  {startDate && startDate.toLocaleDateString()}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}>
                <Typography
                  variant="subtitle1"
                  fontWeight="600"
                  fontSize="14px">
                  End Date:
                </Typography>
                <Typography variant="body1" fontSize="14px" fontWeight="400">
                  {endDate && endDate.toLocaleDateString()}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}>
                <Typography
                  variant="subtitle1"
                  fontWeight="600"
                  fontSize="14px">
                  Description:
                </Typography>
                <Typography
                  variant="body1"
                  fontSize="14px"
                  fontWeight="400"
                  sx={{
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    width: "80px",
                  }}>
                  {selectedTask.anyText}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}>
                <Typography
                  variant="subtitle1"
                  fontWeight="600"
                  fontSize="14px">
                  Priority:
                </Typography>
                <Typography variant="body1" fontSize="14px" fontWeight="400">
                  {selectedTask.priority}
                </Typography>
              </Box>
            </Box>
          )}
        </DialogContent>
      </Dialog>
      {console.log(tasks)}
      {tasks.length !== 0 ? (
        <GanttChart tasks={tasks} onClick={handleTaskClick} />
      ) : (
        <Stack
          sx={{ height: "200px", background: "#fff" }}
          justifyContent={"center"}
          alignItems={"center"}>
          <Typography>There are no records to display</Typography>
        </Stack>
      )}
    </>
  );
}

export default GanttView;
