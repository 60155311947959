import React from "react";
import Alii from "../../assets/images/team/ceo.png";
import sibghat from "../../assets/images/team/sibghat.png";
import shahrukh from "../../assets/images/team/sharukh.png";
import musharraf from "../../assets/images/team/musharaf.png";
import Iqra from "../../assets/images/team/iqra.png";
import Nasir from "../../assets/images/team/nasir.png";
import Mushtaq from "../../assets/images/team/mushtaq.png";
import Waseem from "../../assets/images/team/waseem.png";
import Zilly from "../../assets/images/team/zilly.png";
import Sami from "../../assets/images/team/sami.png";
import TopImage from "../../assets/images/team/topimg.svg";
import Line from "../../assets/images/team/line.svg";
import sharukkh from "../../assets/images/team/sharukkh.png";
import sharukkh2x from "../../assets/images/team/sharukkh2x.png";
import mujtaba2x from "../../assets/images/team/mujtaba2x.png";
import abdullah2x from "../../assets/images/team/andullah2x.png";
import amelia2x from "../../assets/images/team/amelia2x.png";
import james2x from "../../assets/images/team/james2x.png";
import sophia2x from "../../assets/images/team/sophia2x.png";
import adeel2x from "../../assets/images/team/adeel2x.png";
import naomi2x from "../../assets/images/team/naomi2x.png";
import yulia2x from "../../assets/images/team/yulia2x.png";
import amarah2x from "../../assets/images/team/amarah2x.png";
import haris2x from "../../assets/images/team/haris2x.png";
import suleman2x from "../../assets/images/team/suleman2x.png";
import hannah2x from "../../assets/images/team/hannah2x.png";
import ali2x from "../../assets/images/team/ali2x.png";
import charlotte2x from "../../assets/images/team/charlotte2x.png";
import sibghat2x from "../../assets/images/team/sibghat2x.png";
import "./team.css";
import NavbarMenu from "../common/NavbarMenu";
import Footer from "../common/Footer";
import PageLayout from "../../layouts/Page";
const Team = () => {
  return (
    <PageLayout>
      <NavbarMenu />
      <div className="team_card1 ">
        <div className="d-flex justify-content-center ">
          <img src={TopImage} className="img-fluid mt-5" />
        </div>
        <div className="d-flex justify-content-center">
          <h6 className="team_heading">Meet Our Team</h6>
        </div>
        <div className="d-flex justify-content-center mt-3 mb-3">
          <img src={Line} className="img-fluid" />
        </div>
        <div className="text-center mb-5 m-0">
          <span className="fs-3">
            These are the people who make the magic HAPPEN!
          </span>
        </div>
        {/* <div className="row m-0 p-3 w-100 ">
          <div className="col-12 col-md-7 col-lg-7 col-sm-12 d-flex justify-content-center ">
            <div className="ceo_card tex-center">
              <div className="mb-3 d-flex justify-content-center">
                <img
                  src={Alii}
                  className="img-fluid"
                  style={{ width: "100%" }}
                />
              </div>

              <div className="team_name mb-3 mx-5">
                <h3 style={{ marginTop: "-20px" }}>Muhammad Ali Chaudhry</h3>
              </div>
              <h6 className="text-white text-white d-flex justify-content-center justify-content-md-start py-1 mx-5 mb-3">
                Founder & CEO
              </h6>
            </div>
          </div>

          <div className="col-12 col-md-5 col-lg-5 col-sm-12   d-flex justify-content-center ">
            <div className="ceo_card tex-center ">
              <div className=" d-flex justify-content-sm-center  ">
                <img
                  src={sibghat}
                  className="img-fluid "
                  style={{ width: "100%" }}
                />
              </div>

              <div className="team_name mb-3 mx-5">
                <h3>Sibghatullah Baig </h3>
              </div>
              <h6 className="text-white mb-3 d-flex justify-content-center justify-content-md-start px-5">
                Senior project Manager
              </h6>
            </div>
          </div>
        </div> */}

<div className="row  m-0 gy-5 mb-5 w-100 d-flex team-ali justify-content-center">
          <div className="col-lg-3 col-md-3 col-sm-6 d-flex justify-content-center">
            <div className="team_membar_card mt-0">
              <div className=" d-flex justify-content-center">
                <img src={ali2x} className="img-fluid" />
              </div>

              {/* <div className=" mb-3 d-flex justify-content-center">
                <span className="team_member_title">
                  User Experience Specialist
                </span>
              </div>
              <div>
                <span className="team_mem_name">Shahrukh Hussnain</span>
              </div> */}
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-6 d-flex justify-content-center ">
            <div
              className="team_membar_card mt-0 "
              // style={{ borderBottom: "10px solid #FF2976" }}
            >
              <div className=" d-flex justify-content-center">
                <img src={charlotte2x} className="img-fluid" />
              </div>

              {/* <div className=" mb-3 d-flex justify-content-center">
                <span className="team_member_title">
                  Front-End React Developer
                </span>
              </div>
              <div>
                <span className="team_mem_name">Mushtaq Ahmed</span>
              </div> */}
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-6 d-flex justify-content-center">
            <div className="team_membar_card mt-0">
              <div className=" d-flex justify-content-center">
                <img src={sibghat2x} className="img-fluid" />
              </div>

              {/* <div className=" mb-3 d-flex justify-content-center">
                <span className="team_member_title">
                  Front-End React Developer
                </span>
              </div>
              <div>
                <span className="team_mem_name">Zillay Abbas</span>
              </div> */}
            </div>
          </div>
        </div>




      </div>
      <div className="team_card2" style={{paddingBottom:"70px"}}>
    






        <div className="row  m-0 gy-5 w-100 d-flex justify-content-center team-ali ">
          <div className="col-lg-3 col-md-3 col-sm-6 d-flex justify-content-center">
            <div className="team_membar_card mt-4">
              <div className=" d-flex justify-content-center">
                <img src={sharukkh} className="img-fluid team-employee-images" />
              </div>

              {/* <div className=" mb-3 d-flex justify-content-center">
                <span className="team_member_title">
                  User Experience Specialist
                </span>
              </div>
              <div>
                <span className="team_mem_name">Shahrukh Hussnain</span>
              </div> */}
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-6 d-flex justify-content-center ">
            <div
              className="team_membar_card mt-4 "
              // style={{ borderBottom: "10px solid #FF2976" }}
            >
              <div className=" d-flex justify-content-center">
                <img src={mujtaba2x} className="img-fluid team-employee-images" />
              </div>

              {/* <div className=" mb-3 d-flex justify-content-center">
                <span className="team_member_title">
                  Front-End React Developer
                </span>
              </div>
              <div>
                <span className="team_mem_name">Mushtaq Ahmed</span>
              </div> */}
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-6 d-flex justify-content-center">
            <div className="team_membar_card mt-4">
              <div className=" d-flex justify-content-center">
                <img src={abdullah2x} className="img-fluid team-employee-images" />
              </div>

              {/* <div className=" mb-3 d-flex justify-content-center">
                <span className="team_member_title">
                  Front-End React Developer
                </span>
              </div>
              <div>
                <span className="team_mem_name">Zillay Abbas</span>
              </div> */}
            </div>
          </div>
        </div>
        <div className="row  m-0 gy-5  w-100 d-flex justify-content-center team-ali">
          <div className="col-lg-3 col-md-3 col-sm-6 d-flex justify-content-center">
            <div className="team_membar_card">
              <div className=" d-flex justify-content-center">
                <img src={amelia2x} className="img-fluid team-employee-images" />
              </div>

              {/* <div className=" mb-3 d-flex justify-content-center">
                <span className="team_member_title">IOS Developer</span>
              </div>
              <div>
                <span className="team_mem_name">Raja Musharraf</span>
              </div> */}
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-6 d-flex justify-content-center ">
            <div
              className="team_membar_card "
              // style={{ borderBottom: "10px solid #FF2976" }}
            >
              <div className=" d-flex justify-content-center">
                <img src={james2x} className="img-fluid team-employee-images" />
              </div>

              {/* <div className=" mb-3 d-flex justify-content-center">
                <span className="team_member_title">
                  Senior Android Developer
                </span>
              </div>
              <div>
                <span className="team_mem_name">Nasir Hussain</span>
              </div> */}
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-6 d-flex justify-content-center">
            <div className="team_membar_card ">
              <div className=" d-flex justify-content-center">
                <img src={sophia2x} className="img-fluid team-employee-images" />
              </div>

              {/* <div className=" mb-3 d-flex justify-content-center">
                <span className="team_member_title">Back-End Developer</span>
              </div>
              <div>
                <span className="team_mem_name">Iqra Shahid</span>
              </div> */}
            </div>
          </div>
        </div>
        <div className="row  m-0 gy-5 w-100 d-flex justify-content-center team-ali">
          <div className="col-lg-3 col-md-3 col-sm-6 d-flex justify-content-center ">
            <div className="team_membar_card mb-5">
              <div className=" d-flex justify-content-center">
                <img src={adeel2x} className="img-fluid team-employee-images" />
              </div>

              {/* <div className=" mb-3 d-flex justify-content-center">
                <span className="team_member_title">
                  Front-End React Developer
                </span>
              </div>
              <div>
                <span className="team_mem_name">Sami ul Hassan</span>
              </div> */}
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-6 d-flex justify-content-center ">
            <div
              className="team_membar_card "
              // style={{ borderBottom: "10px solid #FF2976" }}
            >
              <div className=" d-flex justify-content-center">
                <img src={naomi2x} className="img-fluid team-employee-images" />
              </div>

              {/* <div className=" mb-3 d-flex justify-content-center">
                <span className="team_member_title">Graphic Designer</span>
              </div>
              <div>
                <span className="team_mem_name">Afran Hussain</span>
              </div> */}
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-6 d-flex justify-content-center  ">
            <div className="team_membar_card  ">
              <div className=" d-flex justify-content-center">
                <img src={yulia2x} className="img-fluid team-employee-images" />
              </div>

              {/* <div className="  d-flex justify-content-center">
                <span className="team_member_title">Android Developer</span>
              </div>
              <div>
                <span className="team_mem_name">Waseem Shakoor</span>
              </div> */}
            </div>
          </div>
          <div className="row  m-0 gy-5 w-100 d-flex justify-content-center team-ali">
          <div className="col-lg-3 col-md-3 col-sm-6 d-flex justify-content-center" style={{marginTop:"0px"}}>
            <div className="team_membar_card ">
              <div className=" d-flex justify-content-center">
                <img src={amarah2x} className="img-fluid team-employee-images" />
              </div>

              {/* <div className=" mb-3 d-flex justify-content-center">
                <span className="team_member_title">
                  User Experience Specialist
                </span>
              </div>
              <div>
                <span className="team_mem_name">Shahrukh Hussnain</span>
              </div> */}
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-6 d-flex justify-content-center " style={{marginTop:"0px"}}>
            <div
              className="team_membar_card "
              // style={{ borderBottom: "10px solid #FF2976" }}
            >
              <div className=" d-flex justify-content-center">
                <img src={haris2x} className="img-fluid team-employee-images" />
              </div>

              {/* <div className=" mb-3 d-flex justify-content-center">
                <span className="team_member_title">
                  Front-End React Developer
                </span>
              </div>
              <div>
                <span className="team_mem_name">Mushtaq Ahmed</span>
              </div> */}
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-6 d-flex justify-content-center" style={{marginTop:"0px"}}>
            <div className="team_membar_card ">
              <div className=" d-flex justify-content-center">
                <img src={suleman2x} className="img-fluid team-employee-images" />
              </div>

              {/* <div className=" mb-3 d-flex justify-content-center">
                <span className="team_member_title">
                  Front-End React Developer
                </span>
              </div>
              <div>
                <span className="team_mem_name">Zillay Abbas</span>
              </div> */}
            </div>
          </div>
        </div>

        <div className="row  m-0 gy-5 w-100 d-flex justify-content-around">
    
          <div className="col-lg-3 col-md-3 col-sm-6 d-flex justify-content-center " >
            <div className="team_membar_card ">
              <div className=" d-flex justify-content-center">
                <img src={hannah2x} className="img-fluid team-employee-images" />
              </div>

              {/* <div className=" mb-3 d-flex justify-content-center">
                <span className="team_member_title">
                  Front-End React Developer
                </span>
              </div>
              <div>
                <span className="team_mem_name">Zillay Abbas</span>
              </div> */}
            </div>
          </div>
        </div>

        </div>
      </div>
      <Footer />
    </PageLayout>
  );
};

export default Team;
