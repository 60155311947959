import React, { useState } from "react";
import infini8logo from "../../assets/images/get quote/getqoutlogo.png";
import NavbarMenu from "../common/NavbarMenu";
import "./portfolio.css";
import { Card, CardBody, CardTitle, CardText, CardImg, Col } from "reactstrap";
import p1 from "../../assets/images/portfolio/p1.png";
import p2 from "../../assets/images/portfolio/p2.png";
import p3 from "../../assets/images/portfolio/p3.png";
import p4 from "../../assets/images/portfolio/p4.png";
import p5 from "../../assets/images/portfolio/p5.png";
import p6 from "../../assets/images/portfolio/p6.png";
import p7 from "../../assets/images/portfolio/p7.png";
import p8 from "../../assets/images/portfolio/p8.png";
import p9 from "../../assets/images/portfolio/p9.png";
import Footer from "../common/Footer";
import PageLayout from "../../layouts/Page";

const Portfolio = () => {
  let mode = "all";
  const [state, setState] = useState({ mode: "all" });
  return (
    <PageLayout>
      <NavbarMenu />
      <div className="container-fluid">
        <div className="text-center mt-5 ">
          <p className="fs-1 fw-bold text_blue">PORTFOLIO</p>
          <img src={infini8logo} className="img-fluid mt-5" />
        </div>
        <div id="team" class="mt-5 mx-5 padding-btm-140">
          <div className="text-center tabs">
            <div className="tabs-border">
              <button
                onClick={(e) => setState({ mode: "all" })}
                className={
                  state.mode == "all"
                    ? "tab-button-filled fs-3 fw-bold text-white"
                    : "tab-button-unfilled fs-3 fw-bold "
                }
              >
                All
              </button>
              <button
                onClick={(e) => setState({ mode: "web" })}
                className={
                  state.mode == "web"
                    ? "tab-button-filled fs-3 fw-bold text-white"
                    : "tab-button-unfilled fs-3 fw-bold "
                }
              >
                WEB
              </button>
              <button
                onClick={(e) => setState({ mode: "app" })}
                className={
                  state.mode == "app"
                    ? "tab-button-filled fs-3 fw-bold text-white "
                    : "tab-button-unfilled fs-3 fw-bold "
                }
              >
                APP
              </button>
            </div>
          </div>

          {state.mode == "all" && (
            <div>
              <div className="row mt-5 ">
                <Col
                  className="padding-50"
                  md={4}
                  lg={4}
                  data-aos="zoom-in-up"
                  data-aos-duration="3000"
                >
                  <Card
                    style={{ backgroundColor: "transparent", border: "none" }}
                  >
                    <CardImg top width="100%" src={p1} alt="Card image cap" />
                    <CardBody className="font text-center caps font">
                      <CardTitle tag="h5">QURBANI APP</CardTitle>
                      <CardText>APP</CardText>
                    </CardBody>
                  </Card>
                  {/*  */}
                </Col>

                <Col
                  className="padding-50"
                  md={4}
                  lg={4}
                  data-aos="zoom-in-up"
                  data-aos-duration="3000"
                >
                  <Card style={{ border: "none" }}>
                    <CardImg top width="100%" src={p2} alt="Card image cap" />
                    <CardBody className="text-center caps font">
                      <CardTitle tag="h5">HARDWARE</CardTitle>
                      <CardText>WEB</CardText>
                    </CardBody>
                  </Card>
                  {/*  */}
                </Col>

                <Col
                  className="padding-50"
                  md={4}
                  lg={4}
                  data-aos="zoom-in-up"
                  data-aos-duration="3000"
                >
                  <Card style={{ backgroundColor: "white", border: "none" }}>
                    <CardImg
                      top
                      src={p3}
                      alt="Card image cap"
                      style={{ width: "100%" }}
                    />
                    <CardBody className="text-center caps font">
                      <CardTitle className="text-center " tag="h5">
                        Cable and Devices{" "}
                      </CardTitle>
                      <CardText>WEB</CardText>
                    </CardBody>
                  </Card>
                  {/*  */}
                </Col>
              </div>

              <div className="row">
                <Col
                  className="padding-50"
                  md={4}
                  lg={4}
                  data-aos="zoom-in-up"
                  data-aos-duration="3000"
                >
                  <Card
                    style={{ backgroundColor: "transparent", border: "none" }}
                  >
                    <CardImg top width="100%" src={p4} alt="Card image cap" />
                    <CardBody className="font text-center caps font">
                      <CardTitle tag="h5">Ledger World</CardTitle>
                      <CardText>APP</CardText>
                    </CardBody>
                  </Card>
                  {/*  */}
                </Col>

                <Col
                  className="padding-50"
                  md={4}
                  lg={4}
                  data-aos="zoom-in-up"
                  data-aos-duration="3000"
                >
                  <Card style={{ border: "none" }}>
                    <CardImg top width="100%" src={p5} alt="Card image cap" />
                    <CardBody className="text-center caps font">
                      <CardTitle tag="h5">Qurbani App Web</CardTitle>
                      <CardText>WEB</CardText>
                    </CardBody>
                  </Card>
                  {/*  */}
                </Col>

                <Col
                  className="padding-50"
                  md={4}
                  lg={4}
                  data-aos="zoom-in-up"
                  data-aos-duration="3000"
                >
                  <Card style={{ backgroundColor: "white", border: "none" }}>
                    <CardImg
                      top
                      src={p6}
                      alt="Card image cap"
                      style={{ width: "100%" }}
                    />
                    <CardBody className="text-center caps font">
                      <CardTitle className="text-center " tag="h5">
                        Home Schooling{" "}
                      </CardTitle>
                      <CardText>WEB</CardText>
                    </CardBody>
                  </Card>
                  {/*  */}
                </Col>
              </div>

              <div className="row">
                <Col
                  className="padding-50"
                  md={4}
                  lg={4}
                  data-aos="zoom-in-up"
                  data-aos-duration="3000"
                >
                  <Card
                    style={{ backgroundColor: "transparent", border: "none" }}
                  >
                    <CardImg top width="100%" src={p7} alt="Card image cap" />
                    <CardBody className="font text-center caps font">
                      <CardTitle tag="h5">Tribe Md</CardTitle>
                      <CardText>WEB</CardText>
                    </CardBody>
                  </Card>
                  {/*  */}
                </Col>

                <Col
                  className="padding-50"
                  md={4}
                  lg={4}
                  data-aos="zoom-in-up"
                  data-aos-duration="3000"
                >
                  <Card style={{ border: "none" }}>
                    <CardImg top width="100%" src={p8} alt="Card image cap" />
                    <CardBody className="text-center caps font">
                      <CardTitle tag="h5">Plantiza</CardTitle>
                      <CardText>WEB</CardText>
                    </CardBody>
                  </Card>
                  {/*  */}
                </Col>

                <Col
                  className="padding-50"
                  md={4}
                  lg={4}
                  data-aos="zoom-in-up"
                  data-aos-duration="3000"
                >
                  <Card style={{ backgroundColor: "white", border: "none" }}>
                    <CardImg
                      top
                      src={p9}
                      alt="Card image cap"
                      style={{ width: "100%" }}
                    />
                    <CardBody className="text-center caps font">
                      <CardTitle className="text-center " tag="h5">
                        Deaf Tawk{" "}
                      </CardTitle>
                      <CardText>WEB</CardText>
                    </CardBody>
                  </Card>
                  {/*  */}
                </Col>
              </div>
            </div>
          )}

          {state.mode == "web" && (
            <div className="row mt-5 ">
              <Col
                className="padding-50"
                md={4}
                lg={4}
                data-aos="zoom-in-up"
                data-aos-duration="3000"
              >
                <Card style={{ border: "none" }}>
                  <CardImg top width="100%" src={p2} alt="Card image cap" />
                  <CardBody className="text-center caps font">
                    <CardTitle tag="h5">HARDWARE</CardTitle>
                    <CardText>WEB</CardText>
                  </CardBody>
                </Card>
                {/*  */}
              </Col>

              <Col
                className="padding-50"
                md={4}
                lg={4}
                data-aos="zoom-in-up"
                data-aos-duration="3000"
              >
                <Card style={{ backgroundColor: "white", border: "none" }}>
                  <CardImg
                    top
                    src={p3}
                    alt="Card image cap"
                    style={{ width: "100%" }}
                  />
                  <CardBody className="text-center caps font">
                    <CardTitle className="text-center " tag="h5">
                      Cable and Devices{" "}
                    </CardTitle>
                    <CardText>WEB</CardText>
                  </CardBody>
                </Card>
                {/*  */}
              </Col>

              <Col
                className="padding-50"
                md={4}
                lg={4}
                data-aos="zoom-in-up"
                data-aos-duration="3000"
              >
                <Card style={{ border: "none" }}>
                  <CardImg top width="100%" src={p5} alt="Card image cap" />
                  <CardBody className="text-center caps font">
                    <CardTitle tag="h5">Qurbani App Web</CardTitle>
                    <CardText>WEB</CardText>
                  </CardBody>
                </Card>
                {/*  */}
              </Col>
            </div>
          )}

          {state.mode == "app" && (
            <div className="row mt-5  d-flex justify-content-around">
              <Col
                className="padding-50 "
                md={4}
                lg={4}
                data-aos="zoom-in-up"
                data-aos-duration="3000"
              >
                <Card
                  style={{ backgroundColor: "transparent", border: "none" }}
                >
                  <CardImg top width="100%" src={p1} alt="Card image cap" />
                  <CardBody className="font text-center caps font">
                    <CardTitle tag="h5">QURBANI APP</CardTitle>
                    <CardText>APP</CardText>
                  </CardBody>
                </Card>
                {/*  */}
              </Col>

              <Col
                className="padding-50"
                md={4}
                lg={4}
                data-aos="zoom-in-up"
                data-aos-duration="3000"
              >
                <Card
                  style={{ backgroundColor: "transparent", border: "none" }}
                >
                  <CardImg top width="100%" src={p4} alt="Card image cap" />
                  <CardBody className="font text-center caps font">
                    <CardTitle tag="h5">Ledger World</CardTitle>
                    <CardText>APP</CardText>
                  </CardBody>
                </Card>
                {/*  */}
              </Col>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </PageLayout>
  );
};

export default Portfolio;
