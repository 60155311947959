import React, { useEffect, useState } from "react";
import "@fullcalendar/react/dist/vdom";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import { Stack, styled } from "@mui/system";
import { Button, Grid, Typography } from "@mui/material";
import Logo from "../../../assets/images/Employees/file.png";
import DashboardNavbar from "../../../layouts/Navbars/DashboardNavbar";
import DashboardLayout from "../../../layouts/Dashboard";
import { Info } from "../../../assets/Svg/svg";
import ReplyAllOutlinedIcon from "@mui/icons-material/ReplyAllOutlined";
import { useLocation, useNavigate } from "react-router-dom";
import { getEmployeeAttendance } from "../../../apiservices/attendance";
import AttendanceSumaryPopup from "../calender/AttendancePopup";
import moment from "moment";

export default function AttendanceReport() {
  let navigate = useNavigate();
  let location = useLocation();
  const [state, setState] = useState({
    applyLeaves: false,
    leaveModal: false,
    attendanceArr: [],
    date: moment().format("MM-YYYY"),
  });
  let firstDaty = 1;
  const ViewMemberButton = styled(Button)({
    height: "32px",
    // width: "140px",
    borderRadius: "4px",
    padding: " 4px 8px 4px 8px",
    border: "1px solid #67748E",
    color: "#67748E",
    fontSize: "15px",
    textTransform: "capitalize",
    "&:hover": {
      background: "#67748E",
      color: "#FFFFFF",
    },
    "&:active": {
      // background: "#586278",
      color: "#FFFFFF",
    },
  });

  const Body = styled(Stack)({
    background: "#FFFFFF",
    padding: "10px",
    margin: "20px",
    borderRadius: "10px",
  });
  const BackButton = styled(Button)({
    borderRadius: "4px",
    padding: "4px 8px 4px 8px",
    color: "#67748E",
    border: "1px solid #67748E",
    "&:hover": {
      background: "#67748E",
      color: "#FFFFFF",
    },
    "&:active": {
      background: "#586278",
      color: "#FFFFFF",
    },
  });
  const applyModel = (name) => {
    setState((prev) => ({ ...prev, [name]: !prev[name] }));
  };
  useEffect(() => {
    getEmployeesAttendance();
  }, []);

  const getEmployeesAttendance = async () => {
    let data = {
      employeeId: location.state.empObj.email,
      month: state.date,
    };
    let res = await getEmployeeAttendance(data);
    let dataArr = [];
    res.attendanceArr &&
      res.attendanceArr.map((item) => {
        let date = moment(item.date, "DD-MM-YYYY").format("YYYY-MM-DD");
        return dataArr.push({
          title:
            item.status === 1
              ? "Present"
              : item.status === 2
              ? "Absent"
              : item.status === 3
              ? "Leave"
              : item.status === 4
              ? "Half Leave"
              : "WFH",
          start: date,
          backgroundColor: "rgba(202, 41, 41, 0.12)",
          textColor: item.status
            ? "#2CCA29"
            : item.status === 3
            ? "#CAA729"
            : "#CA2929",
          borderColor: "#FFFFFF",
        });
      });
    setState((prev) => ({ ...prev, events: dataArr }));
  };
  const back = () => {
    navigate("/employees", {
      state: {
        dialogue: true,
        empName: location.state.empName,
      },
    });
  };
  return (
    <DashboardLayout>
      <DashboardNavbar>
        <Grid container spacing={2}>
          <Grid
            item
            xs={6.3}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              // mx: 4,
            }}>
            <img src={Logo} alt="img" height="50px" width="50px" />
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              Employee Attendance
            </Typography>
          </Grid>
          <Grid
            item
            xs={5.5}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              // mx: 4,
            }}>
            <Stack direction="row">
              <ViewMemberButton
                size="small"
                onClick={() => applyModel("applyLeaves")}
                sx={{ mx: 1 }}>
                <Info />
                &nbsp; Summary
              </ViewMemberButton>

              <BackButton size="small" onClick={back}>
                <ReplyAllOutlinedIcon />
              </BackButton>
            </Stack>
          </Grid>
        </Grid>
      </DashboardNavbar>
      <Body>
        <FullCalendar
          firstDay={firstDaty}
          locale="en"
          plugins={[dayGridPlugin]}
          events={state.events}
        />
      </Body>

      <AttendanceSumaryPopup
        openPopup={state.applyLeaves}
        empName={state.empName}
        closeSumaryPopup={(apply) => applyModel(apply)}
        email={location.state.empObj.email}
      />
    </DashboardLayout>
  );
}
