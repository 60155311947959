import React, { useLayoutEffect } from "react";
import { useState, useEffect } from "react";
import "./task.css";
import axios from "axios";
import { toast } from "react-toastify";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import S3FileUpload from "react-s3/lib/ReactS3";
import { Box, styled } from "@mui/system";
import { Button, Grid, Stack, Select } from "@mui/material";
import AddCompany from "../../assets/images/project/AddCompany.svg";
import CardImg from "../../assets/images/project/cardImg.png";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import TaskDetailPopup from "./TaskPopup";
import ViewMembers from "./ViewMembers";
import LoupeIcon from "@mui/icons-material/Loupe";
import { getTeamMembers } from "../../apiservices/project";
import { getTasksByProject, saveTask } from "../../apiservices/task";
import { getUsersByCompany } from "../../apiservices/general";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
// import Select from "react-select";
import { components } from "react-select";
// import Loader from "../task/Loader";
import uploadimage from "../../assets/images/project/add_a_photo.svg";
import emp from "../../assets/images/project/emp.svg";
import { TextField } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import AutorenewIcon from "@mui/icons-material/Autorenew";

import {
  FormControl,
  MenuItem,
  Dialog,
  DialogContent,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import moment from "moment";
import { TextStyle } from "../dashboard/style";
import { ViewMemberButton } from "./Project";
window.Buffer = window.Buffer || require("buffer").Buffer;
const TaskHeader = styled(Box)({
  height: "40px",
  // width: "336px",
  borderRadius: "6px",
  background: "#04B2AE",
  boxShadow: " 0px 4px 20px rgba(0, 0, 0, 0.16)",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  color: "#FFFFFF",
});
const CancelBtn = styled(Button)({
  height: "30px",
  width: "120px",
  borderRadius: "4px",
  padding: "8px 50px 8px 50px",
  color: "#A7A7A7",
  background: "#FFFFFF",
  "&:hover": {
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.16)",
    color: "#A7A7A7",
  },
  "&:active": {
    background: " #A7A7A7",
    color: "#FFFFFF",
  },
});
const SaveBtn = styled(Button)({
  height: "30px",
  width: "120px",
  borderRadius: "4px",
  padding: "8px 50px 8px 50px",
  color: "#F5F5F5",
  background: "#21A49E",
  "&:hover": {
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.16)",
    background: "#1B8C87",
    color: "#F5F5F5",
  },
  "&:active": {
    background: " #146E6A",
    color: "#F5F5F5",
  },
});
const Cards = styled(Box)({
  borderRadius: "6px",
  boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.08)",
});
const commonStyles = {
  borderColor: "#FFFFFF",
  m: 1,
  border: 1,
  width: "1.3rem",
  height: "1.3rem",
  color: "#FFFFFF",
};
const DragIcon = styled(Box)({
  margin: "5px",
  borderRadius: "2px",
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  color: "grey",
  justifyContent: "center",
  "&:hover": {
    background: "#67748E",
    color: "#ffffff",
  },
});
function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.Typography?.fontWeightRegular
        : theme.Typography?.fontWeightMedium,
  };
}
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      height: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const Task = ({ projectData, viewMembers, viewMembersModel }) => {
  const theme = useTheme();
  const config = {
    bucketName: "infini8ai-images",
    dirName: "photos",
    error: "",
    region: "us-east-1",
    accessKeyId: "AKIA44SH2DQ6PSTL43O7",
    secretAccessKey: "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",
  };
  const [isEdit, setisEdit] = useState("");
  const [editShow, seteditShow] = useState(false);
  const userName = localStorage.getItem("userName");

  const [state, setState] = useState({
    assignTaskName: "",
    assignTaskDate: "",
    assignTaskMember: "",
    assignTaskDetail: "",
    progressTaskName: "",
    progressTaskDate: "",
    progressTaskMember: "",
    progressTaskDetail: "",
    completeTaskName: "",
    completeTaskDate: "",
    completeTaskMember: "",
    completeTaskDetail: "",
    testingTaskName: "",
    testingTaskDate: "",
    testingTaskMember: "",
    testingTaskDetail: "",
    value: "",
    openForm: false,
  });
  const [assignTask, setassignTask] = useState("");
  const [popup, setPopup] = useState(false);
  const [selectedRow, setselectedRow] = useState("");
  const [selectedCol, setSelectedCol] = useState("");
  const [selectedColName, setSelectedColName] = useState("");
  const [teamMembers, setTeamMembers] = useState([]);
  const [taskImg, setTaskImg] = useState([]);
  const [displayImg, setdisplayImg] = useState("");
  const [allUsers, setAllUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const [columns, setColumns] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [task, setTask] = useState({
    taskName: "",
    assignee: [],
    status: "",
    priority: "",
    startDate: moment().format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
    dependentOn: [],
    anyText: "",
    files: [],
  });
  useEffect(() => {
    getTeamMember();
    getallTask();
    getAllUser();
  }, []);
  useLayoutEffect(() => {
    if (selectedCol) {
      updateTask();
    }
  }, [selectedCol]);
  const getAllUser = async () => {
    let data = {
      company_code: localStorage.getItem("companyCode"),
    };
    let res = await getUsersByCompany(data);
    let updated =
      res.usersArr &&
      res.usersArr.map((item) => ({
        ...item,
        label: item.name,
        value: item.name,
      }));
    setAllUsers(updated);
  };
  const updateTask = async () => {
    // setLoader(true);
    let copyCols = JSON.parse(JSON.stringify(columns));
    let found = copyCols[selectedCol].items.find(
      (item) => item.journey !== selectedCol
    );
    if (found) {
      found.journey = selectedCol;
      await axios.post(`https://infini8ai.herokuapp.com/tasks`, {
        request: {
          method: "updateTask",
          data: {
            ...found,
          },
        },
      });

      // setLoader(false);
      setSelectedCol("");
      getallTask();
    } else {
      setSelectedCol("");
      // setLoader(false);
    }
  };

  const onDragEnd = (result, columns, setColumns) => {
    if (!result.destination) return;
    const { source, destination } = result;
    setSelectedCol(destination.droppableId);

    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];
      const sourceItems = [...sourceColumn.items];
      const destItems = [...destColumn.items];
      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems,
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems,
        },
      });
    } else {
      setSelectedCol("");
      const column = columns[source.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems,
        },
      });
    }
  };

  const handleTask = (id) => {
    getallTask();
    seteditShow(false);
    setisEdit(id);
    setassignTask(id);
    setState((prev) => ({ ...prev, openForm: true }));
  };
  const handleChange = (e) => {
    let { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const uploadImage = async (item) => {
    try {
      let { location } = await S3FileUpload.uploadFile(item, config);
      return location;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
  const saveForm = async () => {
    setIsLoading(true); // Start loading indicator

    const {
      taskName,
      assignee,
      status,
      priority,
      startDate,
      endDate,
      dependentOn,
      anyText,
      files,
    } = task;

    try {
      if (
        [
          taskName,
          assignee,
          // status,
          // priority,
          // state.assignTaskDetail,
        ].includes("")
      ) {
        toast.error("Please Enter All Fields", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        const apiPromises = taskImg.map(uploadImage);
        const results = await Promise.all(apiPromises);
        let data = {
          task: taskName,
          members: assignee,
          journey: "assigned",
          date: moment().format("DD-MM-YYYY"),
          details: anyText,
          deadline: endDate,
          company_code: localStorage.getItem("companyCode"),
          imageUrl: results,
          assignedBy: userName,
          projects: [projectData.name],
          startDate: startDate,
          endDate: endDate,
          collaborators: dependentOn,
          status: status,
          priority: priority,
        };
        await saveTask(data);
        toast.success(
          `${editShow ? "Task Edit Successfully" : "Task Added Successfully"}`,
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
        handleCancel();

        setTask((prevState) => ({
          ...prevState,
          taskName: "",
          assignee: [],
          status: "",
          priority: "",
          startDate: moment().format("YYYY-MM-DD"),
          endDate: moment().format("YYYY-MM-DD"),
          dependentOn: [],
          anyText: "",
          value: "",
        }));
        setSelectedUser("");
        getallTask();
        setisEdit("");
        setassignTask("");
        setdisplayImg("");
        setTaskImg([]);
        seteditShow(false);
      }
    } catch (error) {
      // Handle any errors here
      console.error("Error:", error);
    } finally {
      setIsLoading(false); // Stop loading indicator
    }
  };
  const handleCancel = () => {
    setisEdit(!assignTask);
    setState((prevState) => ({
      ...prevState,
      assignTaskName: "",
      assignTaskDate: "",
      assignTaskMember: "",
      assignTaskDetail: "",
      openForm: false,
    }));
    setSelectedUser("");
  };
  const assignTaskDetails = (items, columname) => {
    setselectedRow(items);
    setPopup(true);
    setSelectedColName(columname);
  };
  const closeTaskPopup = () => {
    setPopup(false);
  };

  const handleImg = (e) => {
    const files = Array.from(e.target.files);

    let imgs = [];
    if (files.length > 0) {
      files.forEach((item) => {
        imgs.push(item);
      });
    }
    setTaskImg(imgs);
  };
  const getTeamMember = async () => {
    let data = {
      teamName: projectData.team,
      company_code: localStorage.getItem("companyCode"),
    };
    let res = await getTeamMembers(data);
    setTeamMembers(res.membersArr);
  };
  const getallTask = async () => {
    let columnsFromBackend = {
      assigned: {
        name: "Assigned",
        items: [],
        icons: AddCompany,
      },
      inprogress: {
        name: "In Progress",
        items: [],
      },
      testing: {
        name: "Testing",
        items: [],
      },
      completed: {
        name: "Done",
        items: [],
      },
    };
    let data = {
      projectName: projectData?.name,
    };
    let res = await getTasksByProject(data);
    let inprogress = [];
    let completed = [];
    let assigned = [];
    let testing = [];
    let response = res.tasksArr;
    response.forEach((el) => {
      if (el.journey === "assigned") {
        assigned.push(el);
      } else if (el.journey === "inprogress") {
        inprogress.push(el);
      } else if (el.journey === "testing") {
        testing.push(el);
      } else if (el.journey === "completed") {
        completed.push(el);
      }
    });
    columnsFromBackend.inprogress.items = inprogress;
    columnsFromBackend.completed.items = completed;
    columnsFromBackend.assigned.items = assigned;
    columnsFromBackend.testing.items = testing;

    setColumns(columnsFromBackend);
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    if (name === "startDate" || name === "endDate") {
      const formattedDate = value
        ? new Date(value).toISOString().split("T")[0]
        : "";

      if (name === "startDate") {
        setTask((prev) => ({ ...prev, startDate: formattedDate }));
      } else {
        setTask((prev) => ({ ...prev, endDate: formattedDate }));
      }
    } else if (name === "files") {
      const { files } = e.target;
      if (files[0]) {
        setTask((prev) => ({ ...prev, files: [...prev.files, files[0]] }));
      }
    } else {
      setTask((prev) => ({ ...prev, [name]: value }));
    }
  };
  const handleSelectChange = (event) => {
    const {
      target: { value, name },
    } = event;
    setTask((prev) => ({
      ...prev,
      [name]:
        // On autofill we get a stringified value.
        typeof value === "string" ? value.split(",") : value,
    }));
  };

  return (
    <>
      {isLoading && (
        <div
          style={{
            fontSize: "50px",
            position: "absolute",
            top: "50%",
            bottom: "50%",
            left: "50%",
            right: "50%",
            zIndex: "9999",
          }}>
          {/* <Loader /> */}
        </div>
      )}
      <Grid
        container
        sx={{
          px: 3,
          mt: 5,
        }}>
        <DragDropContext
          onDragEnd={(result) => onDragEnd(result, columns, setColumns)}>
          {Object.entries(columns).map(([columnId, column], index) => {
            return (
              <Grid item sm={6} md={3} key={columnId}>
                <TaskHeader sx={{ mx: 1 }}>
                  <TextStyle
                    sx={
                      column?.icons && {
                        marginLeft: 5,
                      }
                    }>
                    {column.name}
                  </TextStyle>
                  {column?.icons && (
                    <Button
                      onClick={() => handleTask(columnId)}
                      sx={{ marginLeft: 8 }}>
                      <Box
                        sx={{
                          ...commonStyles,
                          borderRadius: "16px",

                          "&:hover": {
                            bgcolor: "#EF9F57",
                            borderColor: "#EF9F57",

                            color: "#FFFFFF",
                          },
                        }}>
                        <LoupeIcon
                          sx={{
                            "&:hover": {
                              // background: "#FFFFFF",
                            },
                          }}
                        />
                      </Box>
                    </Button>
                  )}
                </TaskHeader>
                <Droppable droppableId={columnId} key={columnId}>
                  {(provided, snapshot) => {
                    return (
                      <div
                        className="task_card_body taskbody_scroll mx-2"
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={{
                          background: snapshot.isDraggingOver
                            ? "lightblue"
                            : "#E7F3F3",
                        }}>
                        {provided.placeholder}

                        {column.items &&
                          column.items?.map((item, index) => {
                            return (
                              <>
                                <Draggable
                                  key={item._id}
                                  draggableId={item._id}
                                  index={index}>
                                  {(provided) => {
                                    return (
                                      <>
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          style={{
                                            paddingInline: "5px",
                                            userSelect: "none",
                                            margin: "0 0 8px 0",
                                            minHeight: "50px",

                                            color: "white",
                                            ...provided.draggableProps.style,
                                          }}>
                                          <Cards
                                            style={{ position: "relative" }}
                                            className={`${
                                              columnId === "completed"
                                                ? "task_comp_tab"
                                                : "task_show_tab  mb-3"
                                            } fw-bold fs-6`}
                                            onClick={(e) =>
                                              assignTaskDetails(item, columnId)
                                            }>
                                            <div className="row   justify-content-between align-items-center">
                                              <div className="col-8 ">
                                                <span className="px-2 ">
                                                  {item.imageUrl && (
                                                    <img
                                                      src={
                                                        item.imageUrl[0]
                                                          ? item.imageUrl[0]
                                                          : uploadimage
                                                      }
                                                      alt="img"
                                                      className="img-fluid  pt-2 "
                                                      style={{
                                                        height: "32px",
                                                      }}
                                                    />
                                                  )}
                                                </span>
                                              </div>
                                              <div className="col-4 d-none">
                                                <Stack alignItems="center">
                                                  <DragIcon>
                                                    <DragIndicatorIcon />
                                                  </DragIcon>
                                                </Stack>
                                              </div>
                                            </div>
                                            <div
                                              style={{
                                                color: "#67748E",
                                                // marginLeft: 35,
                                                // marginTop: -28,
                                                marginLeft: 10,
                                                marginRight: 10,
                                              }}
                                              className="">
                                              <span>
                                                <CheckCircleOutlineIcon
                                                  style={{
                                                    cursor: "pointer",
                                                  }}
                                                />
                                              </span>

                                              <span
                                                className="my-1  w-100 fw-bold p-1"
                                                style={{
                                                  overflowWrap: "break-word",
                                                  overflow: "hidden",
                                                  fontSize: "12px",
                                                }}>
                                                {item?.task}
                                              </span>
                                            </div>

                                            <div className="row  d-flex flex-end ">
                                              <div
                                                className=""
                                                style={{
                                                  color: "#67748E",
                                                  alignItems: "center",
                                                }}>
                                                <p
                                                  className="   task_asign_name  "
                                                  style={{
                                                    marginLeft: "14px",
                                                    marginTop: "6px",
                                                    fontWeight: "500",
                                                  }}>
                                                  {" "}
                                                  Assigned To:
                                                </p>
                                                <div
                                                  className="d-flex align-items-center   gap-1"
                                                  style={{
                                                    marginLeft: "10px",
                                                  }}>
                                                  <p>
                                                    <img
                                                      // src={
                                                      //   item?.members[0]
                                                      //     ?.imageUrl
                                                      //     ? item.members[0]
                                                      //         ?.imageUrl
                                                      //     : emp
                                                      // }
                                                      alt=""
                                                      className=" "
                                                      style={{
                                                        borderRadius: "50%",
                                                        alignItems: "center",
                                                        resizeMode: "contain",
                                                        verticalAlign: "middle",
                                                        width: "40px",
                                                        height: "40px",
                                                        padding: "5px",
                                                      }}
                                                    />
                                                  </p>

                                                  <p
                                                    className="task_asigned_name pt-1"
                                                    style={{
                                                      fontSize: "12px",
                                                      fontWeight: "600",
                                                    }}>
                                                    {item.members &&
                                                      item.members[0]?.name}
                                                  </p>
                                                </div>
                                                <p
                                                  className=""
                                                  style={{
                                                    fontSize: "12px",
                                                    fontWeight: "600",
                                                    display: "flex",
                                                    marginRight: "10px",
                                                    flexDirection:
                                                      "row-reverse",
                                                  }}>
                                                  {item.projects &&
                                                    item?.projects[0]}
                                                </p>

                                                {item?.members &&
                                                  item.members.length > 1 && (
                                                    <div className="d-flex">
                                                      <p className="   task_asign_name  ">
                                                        Collaborators:
                                                      </p>
                                                      <p
                                                        className="d-flex flex-row"
                                                        style={
                                                          {
                                                            // marginTop: "-18px",
                                                          }
                                                        }>
                                                        {item?.members &&
                                                          item?.members
                                                            .slice(1)
                                                            ?.map((row) => (
                                                              <img
                                                                src={
                                                                  row?.imageUrl
                                                                }
                                                                alt=""
                                                                className="img-fluid "
                                                                style={{
                                                                  borderRadius:
                                                                    "50%",
                                                                  alignItems:
                                                                    "center",
                                                                  resizeMode:
                                                                    "contain",
                                                                  verticalAlign:
                                                                    "middle",
                                                                  width: "20px",
                                                                  height:
                                                                    "20px",
                                                                  padding:
                                                                    "2px",
                                                                }}
                                                              />
                                                            ))}
                                                      </p>
                                                    </div>
                                                  )}
                                              </div>
                                              <div className="">
                                                <span
                                                  className="  p-2 task_asign_name "
                                                  style={{
                                                    float: "right",
                                                    position: "absolute",
                                                    top: "0",
                                                    right: "0",
                                                    color: "rgb(103, 116, 142)",
                                                  }}>
                                                  Due:{" "}
                                                  {item?.endDate
                                                    ? item?.endDate
                                                    : item.deadline}
                                                </span>
                                              </div>
                                            </div>
                                          </Cards>
                                        </div>
                                      </>
                                    );
                                  }}
                                </Draggable>
                              </>
                            );
                          })}
                      </div>
                    );
                  }}
                </Droppable>
              </Grid>
            );
          })}
        </DragDropContext>
      </Grid>
      {popup && (
        <TaskDetailPopup
          selectedColName={selectedColName}
          popup={popup}
          closeTaskPopup={closeTaskPopup}
          selectedRow={selectedRow}
          projName={projectData.name}
          getallTask={getallTask}
          teamMembers={teamMembers}
          displayImg={displayImg}
          allUsers={allUsers}
        />
      )}
      {viewMembers && (
        <ViewMembers
          openViewMembers={viewMembers}
          closeViewMember={viewMembersModel}
          teamMembers={teamMembers}
        />
      )}
      <Dialog
        open={state.openForm}
        onClose={handleCancel}
        fullWidth
        maxWidth="sm">
        <DialogContent>
          <TextStyle>Task Details</TextStyle>
          <Grid container spacing={3}>
            <Grid item md={12} xs={12}>
              <textarea
                style={{
                  width: "100%",
                  borderRadius: "8px",
                  borderColor: "#BDBDBD",
                  padding: 5,
                }}
                variant="outlined"
                placeholder="Task Name"
                rows={1}
                name="taskName"
                value={task.taskName}
                onChange={handleInput}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="demo-multiple-name-label">Assignee</InputLabel>
                <Select
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  multiple
                  name="assignee"
                  value={task.assignee}
                  onChange={handleSelectChange}
                  sx={{ height: "2.7rem", width: "100%" }}
                  input={<OutlinedInput label="Person Name" />}
                  MenuProps={MenuProps}>
                  {allUsers.map((name) => (
                    <MenuItem
                      key={name.name}
                      value={name}
                      style={getStyles(name, task.assignee, theme)}>
                      {name.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item md={6} xs={12}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Status</InputLabel>
                <Select
                  name="status"
                  value={task.status}
                  onChange={handleInput}
                  input={<OutlinedInput label="Status" />}
                  sx={{ height: "2.8rem" }}>
                  <MenuItem value="Pending">Pending</MenuItem>
                  <MenuItem value="InProgress">InProgress</MenuItem>
                  <MenuItem value="Testing">Testing</MenuItem>
                  <MenuItem value="DOne">Done</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Priority</InputLabel>
                <Select
                  name="priority"
                  value={task.priority}
                  onChange={handleInput}
                  input={<OutlinedInput label="Priority" />}
                  sx={{ height: "2.8rem" }}>
                  <MenuItem value="First">High</MenuItem>
                  <MenuItem value="Second">Medium</MenuItem>
                  <MenuItem value="third">Low</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                id="outlined-required"
                variant="outlined"
                name="startDate"
                value={task.startDate}
                label="Start Date"
                type="date"
                onChange={handleInput}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                id="outlined-required"
                variant="outlined"
                name="endDate"
                value={task.endDate}
                label="End Date"
                type="date"
                onChange={handleInput}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="demo-multiple-name-label">
                  Collaborators
                </InputLabel>
                <Select
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  multiple
                  name="dependentOn"
                  value={task.dependentOn}
                  onChange={handleSelectChange}
                  sx={{ height: "2.7rem", width: "100%" }}
                  input={<OutlinedInput label="Person Name" />}
                  MenuProps={MenuProps}>
                  {teamMembers.map((name) => (
                    <MenuItem
                      key={name.name}
                      value={name}
                      style={getStyles(name, task.dependentOn, theme)}>
                      {name.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item md={12} xs={12}>
              <textarea
                style={{
                  width: "100%",
                  borderRadius: "8px",
                  borderColor: "#BDBDBD",
                  padding: 5,
                }}
                fullWidth
                variant="outlined"
                name="anyText"
                value={task.anyText}
                placeholder="Description"
                onChange={handleInput}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <input
                type="file"
                multiple
                className="mx-auto card_text_input mt-2"
                style={{
                  border: 0,
                  background: "#F7F7F7",
                  fontSize: "14px",
                }}
                onChange={handleImg}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <ViewMemberButton size="small" onClick={handleCancel}>
                  Cancel
                </ViewMemberButton>
                <ViewMemberButton
                  size="small"
                  sx={{
                    borderColor: "#04B2AE",
                    "&:hover": {
                      background: "#04B2AE",
                    },
                  }}
                  disabled={isLoading}
                  onClick={saveForm}>
                  {isLoading && <AutorenewIcon />} Save
                </ViewMemberButton>
              </Stack>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Task;
