import React from "react";
import About329 from "../../../assets/images/optimus/About329.svg";
import Goals330 from "../../../assets/images/optimus/Goals330.svg";
import painPoints331 from "../../../assets/images/optimus/painPoints331.svg";
import Union from "../../../assets/images/optimus/Union.svg";
import Group337 from "../../../assets/images/optimus/Group337.svg";
import Group338 from "../../../assets/images/optimus/Group338.png";
const OptimusSection3 = () => {
  let url = "https://infini8ai-images.s3.amazonaws.com/optimus";
  return (
    <div>
      <div id="OptimusAbouts">
        <div className="container">
          <div className="firstAboutContent">
            <div className="row" style={{ alignItems: "center" }}>
              <div className="col-md-6">
                <div>
                  <div className="aboutImg">
                    <img src={About329} className="About329" alt="About329" />
                  </div>
                  <div className="aboutText">
                    <p>
                      Mr. John Mckenny is a twenty seven-year-old man. He works
                      as an accountant in a major firm and has earned a Master's
                      degree in Business Administration.
                    </p>
                    <p>
                      He loves his job but also acknowledges the stress that
                      comes with it and for that, he tries to maintain his
                      mental and physical health through yoga and good
                      nutrition.
                    </p>
                    {/* <br />
                    <br /> */}
                    <span>
                      “Without hard work and discipline it is difficult to be a
                      top professional.”
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="aboutgroupImg">
                  <img
                    src={`${url}/Group326.svg`}
                    className="GroupImg326"
                    alt="Group326"
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div id="questionAnswering">
                  <div className="aboutImg">
                    <img src={Goals330} className="Goals330" alt="Goals330" />
                  </div>
                  <div className="aboutText">
                    <div className="generallyAboutContent">
                      <ul className="d-flex ml-3 ">
                        <li className="list-unstyled mr-1">
                          <img src={Union} className="Union" alt="union" />
                        </li>
                        <li className="list-unstyled ml-1">
                          <p>
                            More success in professional career and becoming a
                            lead Accountant.
                          </p>
                        </li>
                      </ul>
                      <ul className="d-flex ml-3 ">
                        <li className="list-unstyled mr-1">
                          <img src={Union} className="Union" alt="union" />
                        </li>
                        <li className="list-unstyled ml-1">
                          <p>
                            Maintaining mental and physical health through yoga
                            and good nutrition.
                          </p>
                        </li>
                      </ul>
                      <ul className="d-flex ml-3 ">
                        <li className="list-unstyled mr-1">
                          <img src={Union} className="Union" alt="union" />
                        </li>
                        <li className="list-unstyled ml-1">
                          <p>
                            Overcoming stress and pressure that comes along with
                            job responsilities.
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="aboutImg">
                    <img
                      src={painPoints331}
                      className="painPoints331"
                      alt="painPoints331"
                    />
                  </div>
                  <div className="aboutText">
                    <div className="generallyAboutContent">
                      <ul className="d-flex ml-3 ">
                        <li className="list-unstyled mr-1">
                          <img src={Union} className="Union" alt="union" />
                        </li>
                        <li className="list-unstyled ml-1">
                          <p>
                            Difficulty in doing remote work due to Covid
                            restrictions.
                          </p>
                        </li>
                      </ul>
                      <ul className="d-flex ml-3 ">
                        <li className="list-unstyled mr-1">
                          <img src={Union} className="Union" alt="union" />
                        </li>
                        <li className="list-unstyled ml-1">
                          <p>
                            Stress leads to loss of focus and bad sleeping
                            habits.
                          </p>
                        </li>
                      </ul>
                      <ul className="d-flex ml-3 ">
                        <li className="list-unstyled mr-1">
                          <img src={Union} className="Union" alt="union" />
                        </li>
                        <li className="list-unstyled ml-1">
                          <p>
                            Difficulty finding the right information on
                            nutrition and health.
                          </p>
                        </li>
                      </ul>
                      <ul className="d-flex ml-3 ">
                        <li className="list-unstyled mr-1">
                          <img src={Union} className="Union" alt="union" />
                        </li>
                        <li className="list-unstyled ml-1">
                          <p>
                            Unable to meet nutritionist or doctors due to
                            lockdown.{" "}
                          </p>
                        </li>
                      </ul>
                      <ul className="d-flex ml-3 ">
                        <li className="list-unstyled mr-1">
                          <img src={Union} className="Union" alt="union" />
                        </li>
                        <li className="list-unstyled ml-1">
                          <p>
                            No alternate medium is available that combines all
                            aspects related to health.
                          </p>
                        </li>
                      </ul>
                      <ul className="d-flex ml-3 ">
                        <li className="list-unstyled mr-1">
                          <img src={Union} className="Union" alt="union" />
                        </li>
                        <li className="list-unstyled ml-1">
                          <p>
                            Unable to track daily routine changes and how
                            they’re affecting health.
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="secondAboutContent">
            <div className="row" style={{ alignItems: "center" }}>
              <div className="col-md-6">
                <div>
                  <div className="aboutImg">
                    <img src={About329} className="About329" alt="About329" />
                  </div>
                  <div className="aboutText">
                    <p>
                      Mrs. Kim Denmon is a twenty five-year-old Married girl.
                      She works as a freelance Front-end Web developer online
                      and spends most of her time at home.
                    </p>
                    <p>
                      Her job requires her to sit in a single place for a decent
                      amount of time, so to maintain her health, she goes to a
                      nearby gym for exercise to stay fit.
                    </p>
                    {/* <br />
                    <br /> */}
                    <span>
                      “Programmer: A machine that turns coffee into code.”
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="aboutgroupImg">
                  <img
                    src={`${url}/Group333.svg`}
                    className="GroupImg326"
                    alt="Group333"
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div id="questionAnswering">
                  <div className="aboutImg">
                    <img src={Goals330} className="Goals330" alt="Goals330" />
                  </div>
                  <div className="aboutText">
                    <div className="generallyAboutContent">
                      <ul className="d-flex ml-3 ">
                        <li className="list-unstyled mr-1 ">
                          <img src={Union} className="Union" alt="union"  />
                        </li>
                        <li className="list-unstyled ml-1">
                          <p>Be the best Front-end Web developer.</p>
                        </li>
                      </ul>
                      <ul className="d-flex ml-3 ">
                        <li className="list-unstyled mr-1">
                          <img src={Union} className="Union" alt="union" />
                        </li>
                        <li className="list-unstyled ml-1">
                          <p>
                            Mainatain a healthy lifestyle and staying physically
                            fit.
                          </p>
                        </li>
                      </ul>
                      <ul className="d-flex ml-3 ">
                        <li className="list-unstyled mr-1">
                          <img src={Union} className="Union" alt="union" />
                        </li>
                        <li className="list-unstyled ml-1">
                          <p>
                            Overcoming stress and pressure that comes along with
                            job responsilities.
                          </p>
                        </li>
                      </ul>
                      <ul className="d-flex ml-3 ">
                        <li className="list-unstyled mr-1">
                          <img src={Union} className="Union" alt="union" />
                        </li>
                        <li className="list-unstyled ml-1">
                          <p>
                            Spend time outside of the home as much as possible
                            for mindfulness and peace.
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="aboutImg">
                    <img
                      src={painPoints331}
                      className="painPoints331"
                      alt="painPoints331"
                    />
                  </div>
                  <div className="aboutText">
                    <div className="generallyAboutContent">
                      <ul className="d-flex ml-3 ">
                        <li className="list-unstyled mr-1">
                          <img src={Union} className="Union" alt="union" />
                        </li>
                        <li className="list-unstyled ml-1">
                          <p>
                            Not being able to exercise at the gym because of
                            Covid restrictions.
                          </p>
                        </li>
                      </ul>
                      <ul className="d-flex ml-3 ">
                        <li className="list-unstyled mr-1">
                          <img src={Union} className="Union" alt="union" />
                        </li>
                        <li className="list-unstyled ml-1">
                          <p>
                            Not enough knowledge about exercising without any
                            equipment.
                          </p>
                        </li>
                      </ul>
                      <ul className="d-flex ml-3 ">
                        <li className="list-unstyled mr-1">
                          <img src={Union} className="Union" alt="union" />
                        </li>
                        <li className="list-unstyled ml-1">
                          <p>
                            Changes in daily routine because of lockdown and not
                            being able to track them.
                          </p>
                        </li>
                      </ul>
                      <ul className="d-flex ml-3 ">
                        <li className="list-unstyled mr-1">
                          <img src={Union} className="Union" alt="union" />
                        </li>
                        <li className="list-unstyled ml-1">
                          <p>
                            Not being able to meet a doctor or nutritionist in
                            person to discuss health.{" "}
                          </p>
                        </li>
                      </ul>
                      <ul className="d-flex ml-3 ">
                        <li className="list-unstyled mr-1">
                          <img src={Union} className="Union" alt="union" />
                        </li>
                        <li className="list-unstyled ml-1">
                          <p>
                            Lack of information on how to stay healthy in
                            pandemic like situations.
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="OptimusUserPersonas">
        <div className="container">
          {/* <div className="row">
            <div className="col-md-12">
              <div className="OptimusPersonasTitle">
                <img src={Group337} className="GroupImg315" alt="Group315" />
              </div>
            </div>
          </div> */}
          <div className="UserPersonasMain">
            <div className="row text-center" style={{ alignItems: "center" }}>
              <div className="col-md-4 col-sm-12">
                <div className="row">
                  <div className="analysisCountingImg">
                    <img
                      src={`${url}/Group339.png`}
                      className="GroupImg339"
                      alt="Group339"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="analysisCountingImg">
                    <img
                      src={`${url}/Group341.png`}
                      className="GroupImg339"
                      alt="Group339"
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-12">
                <div className="row">
                  <div className="analysisCountingImg">
                    <img
                      src={Group338}
                      className="GroupImg338"
                      alt="Group338"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="analysisCountingImg">
                    <img
                      src={`${url}/Group344.png`}
                      className="GroupImg344"
                      alt="Group344"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="analysisCountingImg">
                    <img
                      src={`${url}/Group343.png`}
                      className="GroupImg343"
                      alt="Group343"
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-12">
                <div className="row">
                  <div className="analysisCountingImg">
                    <img
                      src={`${url}/Group340.png`}
                      className="GroupImg340"
                      alt="Group340"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="analysisCountingImg">
                    <img
                      src={`${url}/Group342.png`}
                      className="GroupImg342"
                      alt="Group342"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OptimusSection3;
