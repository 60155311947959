import React, { useState } from "react";
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { Box, Stack, styled } from "@mui/system";
import Profile from "../../assets/images/dashboard/rectangle.png";

import AttachFileIcon from "@mui/icons-material/AttachFile";
import { CancelButton, ImgButton, SaveButton } from "../../styles/style";
import { toast } from "react-toastify";
import { updateUser } from "../../apiservices/profile";
import S3FileUpload from "react-s3/lib/ReactS3";
import { TextStyle } from "../dashboard/style";

const ProfileEdit = ({ getUsers, handleCancel, userData }) => {
  const [state, setState] = useState({
    empName: userData ? userData.name : "",
    email: userData ? userData.email : "",
    desigination: userData ? userData.designation : "",
    joiningDate: userData ? userData.joiningDate : "",
    permAddress: userData ? userData.permanentAddress : "",
    tempAddress: userData ? userData.temporaryAddress : "",
    phoneNo: userData ? userData.phoneNo : "",
    emergencyContact: userData ? userData.emergencyNo : "",
    empGender: userData ? userData.gender : "",
    companyCode: userData?.company_code,
    displayImg: userData ? userData.imageUrl : "",
    feedbackImg: "",
  });
  const ProfileCard = styled(Box)({
    height: "150px",
    marginTop: "5px",
    borderRadius: "8px",
    background: "#FFFFFF",
    display: "flex",
    alignItems: "center",
    padding: "20px",
    color: "#67748E",
  });
  const Grids = styled(Grid)({
    paddingRight: "38px",
  });
  const userId = localStorage.getItem("userId");
  const userRev = localStorage.getItem("userRev");

  const config = {
    bucketName: "infini8ai-images",
    dirName: "photos",
    error: "",
    region: "us-east-1",
    accessKeyId: "AKIA44SH2DQ6PSTL43O7",
    secretAccessKey: "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",
  };

  const handleChange = (e) => {
    let { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const updateProfile = async () => {
    let url = userData.imageUrl;
    if (state.feedbackImg) {
      let { location } = await S3FileUpload.uploadFile(
        state.feedbackImg,
        config
      );
      url = location;
    }

    let data = {
      _id: userId,
      _rev: userRev,
      email: state.email,
      gender: state.empGender,
      name: state.empName,
      imageUrl: url,
      joiningDate: state.joiningDate,
      temporaryAddress: state.tempAddress,
      permanentAddress: state.permAddress,
      designation: state.desigination,
      phoneNo: state.phoneNo,
      emergencyNo: state.emergencyContact,
    };
    let res = await updateUser(data);
    if (res) {
      getUsers();
      handleCancel("editFields");
      localStorage.setItem("imageUrl", state.displayImg);
      toast.success("Edit Successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const handleImg = (e) => {
    setState((prev) => ({ ...prev, feedbackImg: e.target.files[0] }));
    let displayImage = URL.createObjectURL(e.target.files[0]);
    setState((prev) => ({ ...prev, displayImg: displayImage }));
    var x = document.getElementById("upload-input");
    x.style.visibility = "collapse";
    document.getElementById("fileName").innerHTML = x.value.split("\\").pop();
  };

  return (
    <Grid container sx={{ mt: 4, px: 10 }}>
      <Grids item xs={12} md={6}>
        <ProfileCard>
          <img
            src={state.displayImg ? state.displayImg : Profile}
            alt="profile"
            height="120px"
            width="120px"
            style={{ borderRadius: 4 }}></img>
          <Stack
            sx={{
              display: "flex",
              alignItems: "center",
            }}>
            <TextStyle sx={{ m: 2, fontWeight: "bold" }}>
              Update Profile
            </TextStyle>

            <label htmlFor="upload-photo">
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  m: 2,
                }}>
                <input
                  style={{ display: "none" }}
                  id="upload-photo"
                  name="upload-photo"
                  type="file"
                  onChange={handleImg}
                />
                <ImgButton component="span">
                  <AttachFileIcon fontSize="medium" /> Choose File
                </ImgButton>
              </Box>
            </label>
          </Stack>
        </ProfileCard>
      </Grids>
      <Grid item xs={12} md={5} sx={{ marginLeft: 5, mt: 2 }}>
        <TextField
          fullWidth
          sx={{
            mb: 2,
            "& .MuiInputBase-root": {
              height: 60,
            },
            background: "#FFFFFF",
          }}
          id="outlined-required"
          label="Employee name"
          name="empName"
          defaultValue={state.empName}
          onChange={() => handleChange()}
        />
        <TextField
          fullWidth
          sx={{
            mb: 1,
            "& .MuiInputBase-root": {
              height: 60,
            },
            background: "#FFFFFF",
          }}
          id="outlined-required"
          label="Email"
          name="email"
          defaultValue={state.email}
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={12} md={6} sx={{ paddingRight: 5 }}>
        <TextField
          fullWidth
          sx={{
            mb: 4,
            mt: 3,
            "& .MuiInputBase-root": {
              height: 60,
            },
            background: "#FFFFFF",
          }}
          id="outlined-required"
          label="Permanent address"
          name="permAddress"
          defaultValue={state.permAddress}
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={12} md={5} sx={{ marginLeft: 5 }}>
        <TextField
          fullWidth
          sx={{
            mb: 4,
            mt: 3,
            "& .MuiInputBase-root": {
              height: 60,
            },
            background: "#FFFFFF",
          }}
          id="outlined-required"
          label="Designation"
          name="desigination"
          value={state.desigination}
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={12} md={6} sx={{ paddingRight: 5 }}>
        <TextField
          fullWidth
          sx={{
            mb: 4,
            "& .MuiInputBase-root": {
              height: 60,
            },
            background: "#FFFFFF",
          }}
          id="outlined-required"
          label="Contact"
          name="phoneNo"
          defaultValue={state.phoneNo}
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={12} md={5} sx={{ marginLeft: 5 }}>
        <TextField
          fullWidth
          sx={{
            mb: 4,
            "& .MuiInputBase-root": {
              height: 60,
            },
            background: "#FFFFFF",
          }}
          id="outlined-required"
          label="Temporary address"
          name="tempAddress"
          defaultValue={state.tempAddress}
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={12} md={6} sx={{ paddingRight: 5 }}>
        <FormControl
          fullWidth
          sx={{
            mb: 4,
            "& .MuiInputBase-root": {
              height: 60,
            },
            background: "#FFFFFF",
          }}>
          <InputLabel id="demo-simple-select-label">Gender</InputLabel>
          <Select
            sx={{ height: "40px" }}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            name="empGender"
            value={state.empGender}
            label="Gender"
            onChange={handleChange}>
            <MenuItem value="male">Male</MenuItem>
            <MenuItem value="female">Female</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={5} sx={{ marginLeft: 5 }}>
        <TextField
          fullWidth
          sx={{
            // mb: 1,
            "& .MuiInputBase-root": {
              height: 60,
            },
            background: "#FFFFFF",
          }}
          id="outlined-required"
          label="Emergency Contact"
          name="emergencyContact"
          defaultValue={state.emergencyContact}
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={12} md={6}></Grid>
      <Grid item xs={12} md={5} sx={{ marginLeft: 5 }}>
        <CancelButton
          size="small"
          onClick={() => handleCancel("editFields")}
          sx={{ marginRight: 3 }}>
          Cancel
        </CancelButton>
        <SaveButton size="small" onClick={updateProfile}>
          Save
        </SaveButton>
      </Grid>
    </Grid>
  );
};

export default ProfileEdit;
