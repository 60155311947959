import React, { useEffect, useState } from "react";
import { Box, Input, Stack, Typography } from "@mui/material";
import searchIcon from "../../assets/images/roadmap/search.svg";
import closeIcon from "../../assets/images/roadmap/cancel.svg";
import groupIcon from "../../assets/images/roadmap/Rectangle 18844.svg";
import userIcon from "../../assets/images/roadmap/account_circle.svg";
import projectImg from "../../assets/images/roadmap/project-img.svg";
import menuIcon from "../../assets/images/roadmap/menu.svg";
import checkIcon from "../../assets/images/roadmap/check_circle.svg";
import TrakingDetail from "./TrakingDetail";
import moveImage from "./move_up.svg";
import uploadimage from "../../assets/images/project/add_a_photo.svg";
import { saveTask, getTasksByCompany } from "../../apiservices/task";
import Loader from "../../admin portal/task/Loader";
import Toaster from "../../components/Toaster";
import Repo from "../../Repository/Repo";
import axios from "axios";
import Pagination from "@mui/material/Pagination";
import moment from "moment";
const Traking = ({ success, allGroups, task, roadMap, del }) => {
  const [addModal, setAddModal] = useState(false);
  const [selected, setSelected] = useState("");
  const [selectedGroup, setSelectedGroup] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [searchQuery2, setSearchQuery2] = useState("");
  const [loading, setLoading] = useState(false);
  const project = roadMap.project;
  const [assignedTasks, setAssignedTasks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [myGroup, setMyGroup] = useState([]);
  const [myTask, setMyTask] = useState([]);
  const [AllUsers, setAllUsers] = useState([]);
  const itemsPerPage = 10;
  const [page, setPage] = React.useState(1);
  const [paper, setPaper] = React.useState(1);
  const noOfPages = Math.ceil(allGroups[0]?.tasks.length / itemsPerPage);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const handleModal = (single = "", group) => {
    setAddModal((prev) => !prev);
    setSelected(single);
    setSelectedGroup(single);
    console.log("single", single);
  };

  const onSuccess = () => {
    setAddModal((prev) => !prev);
    success();
  };
  const handleRemoveSearch = () => {
    setSearchQuery("");
  };
  const handleRemoveSearch2 = () => {
    setSearchQuery2("");
  };

  const fetchAssignedTasks = async () => {
    setIsLoading(true);

    try {
      const response = await getTasksByCompany({
        company_code: localStorage.getItem("companyCode"),
        startDate: moment().format("DD-MM-YYYY"),
        endDate: moment().format("DD-MM-YYYY"),
      });
      setAssignedTasks(response.tasksArr); // Assuming your API response has a tasks property
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching assigned tasks:", error);
      setIsLoading(false);
    }
  };

  const updateGrp = async (tsk, gp) => {
    let { data } = await Repo.roadMap({
      request: {
        method: "updateRoadMapGroup",
        data: {
          _id: gp._id,
          _rev: gp._rev,
          srNum: tsk.srNum,
          task: {
            ...tsk,
            isActive: true,
          },
        },
      },
    });
    console.log("🚀 ~ file: Traking.js:88 ~ updateGrp ~ data:", data);
  };

  const moveToTask = async (single, grp) => {
    let user = AllUsers.find((u) => u.name === single.assignee);
    setMyTask(single);
    setMyGroup(grp);
    setLoading(true); // Start loading

    let data = {
      task: single.taskName,
      members: [
        {
          _id: user && user._id,
          name: single.assignee,
          email: user && user.email,
        },
      ],
      journey: "assigned",
      date: single.created_at,
      details: single.anyText,
      projects: project,
      deadline: single.estimatedTimeline,
      company_code: localStorage.getItem("companyCode"),
      imageUrl: single.files,
      assignedBy: "Admin",
    };

    try {
      updateGrp(single, grp);
      await saveTask(data);
      setLoading(false); // Stop loading

      Toaster("success", "Task Moved Successfully");
      fetchAssignedTasks();
      success();
    } catch (error) {
      console.error("Error moving task:", error);
      setLoading(false); // Stop loading
    }
  };

  useEffect(() => {
    fetchAssignedTasks();

    const apiUrl = "https://infini8ai.herokuapp.com/users"; // Replace with your API URL

    // Define the request data
    const requestData = {
      request: {
        method: "getAllUsers",
      },
    };

    axios
      .post(apiUrl, requestData)
      .then((response) => {
        const userData = response.data.response.data.usersArr;
        setAllUsers(userData);
      })
      .catch((error) => {
        console.error("API Request Error: ", error);
      });
  }, []);
  const handleChange = (event, value) => {
    setPage(value);
  };
  const handlePaperChange = (event, value) => {
    setPaper(value);
  };
  return (
    <>
      {loading && (
        <div
          style={{
            fontSize: "50px",
            position: "absolute",
            top: "50%",
            bottom: "50%",
            left: "50%",
            right: "50%",
            zIndex: "9999",
          }}>
          <Loader />
        </div>
      )}

      <Box display={"flex"} gap={"3px"}>
        <Stack flex={1}>
          <Typography
            textAlign={"center"}
            bgcolor={"#04B2AE"}
            py={"10px"}
            color={"#ffff"}
            borderRadius={"6px"}
            mb={"16px"}>
            Timeline Assigned
          </Typography>
          <Box
            py={"9px"}
            mx={"19px"}
            borderRadius={"4px"}
            display={"flex"}
            justifyContent={"space-between"}
            bgcolor={"#ffff"}
            px={"8px"}
            alignItems={"center"}>
            <Box display={"flex"} alignItems={"center"} gap={"5px"} flex={"1"}>
              <img src={searchIcon} alt="" />
              <Input
                placeholder="Search by user"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value.toLowerCase())}
                sx={{ width: "80%" }}
              />
            </Box>
            <img
              src={closeIcon}
              onClick={handleRemoveSearch}
              style={{ cursor: "pointer" }}
              alt=""
            />
          </Box>
          <div
            style={{
              maxHeight: "calc(100vh - 300px)",
              overflowY: "scroll",
            }}>
            {allGroups.map((group) => {
              return group?.tasks
                .slice((page - 1) * itemsPerPage, page * itemsPerPage)
                .filter((single) =>
                  single?.assignee?.some((assigneeName) =>
                    assigneeName
                      .toLowerCase()
                      .includes(searchQuery.toLowerCase())
                  )
                )
                .map((single) => {
                  return (
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Box
                        sx={{ m: 1.9 }}
                        bgcolor={"#E6E6FA"}
                        borderRadius={"6px"}
                        py="16px">
                        <Box
                          bgcolor={"#ffff"}
                          my={"24px"}
                          mx={"19px"}
                          px={"16px"}
                          py="19px">
                          <Box
                            display={"flex"}
                            alignItems={"center"}
                            justifyContent={"space-between"}>
                            <Typography fontSize={"14px"}>
                              <img
                                src={groupIcon}
                                style={{ marginRight: "5px" }}
                                alt=""
                              />
                              {group.groupName}
                            </Typography>
                            <Typography fontSize={"14px"} sx={{ width: "50%" }}>
                              <img src={userIcon} alt="" />
                              {single.assignee}
                            </Typography>
                          </Box>
                          <Box
                            display={"flex"}
                            alignItems={"center"}
                            justifyContent={"space-between"}
                            my={"16px"}>
                            <Typography fontSize={"14px"} color={"#04B2AE"}>
                              {single.taskName}
                            </Typography>
                            <Typography fontSize={"14px"} color={"#04B2AE"}>
                              {single.estimatedTimeline}
                            </Typography>
                          </Box>
                          <Typography fontSize={"14px"}>
                            {single.anyText}
                          </Typography>
                          <Box
                            display={"flex"}
                            alignItems={"center"}
                            justifyContent={"space-between"}
                            my={"16px"}>
                            <button
                              style={{
                                fontSize: "12px",
                                fontWeight: "400",
                                color: "#ffff",
                                background: "#E9760D",
                                px: "18px",
                                width: "103px",
                                padding: "3px 18px",
                                borderRadius: "3px",
                                border: "0",
                              }}>
                              {single.priority}
                            </button>
                            <button
                              style={{
                                fontSize: "12px",
                                fontWeight: "400",
                                // color: "#ffff",
                                background: "#EBEBEB",
                                px: "18px",
                                width: "103px",
                                padding: "3px 18px",
                                borderRadius: "3px",
                                border: "0",
                              }}>
                              Not started
                            </button>

                            <button
                              onClick={() => moveToTask(single, group)}
                              style={{
                                fontSize: "12px",
                                fontWeight: "400",
                                color: "#ffff",
                                background: "#21A49E",
                                px: "18px",
                                width: "103px",
                                padding: "3px 0px",
                                borderRadius: "3px",
                                border: "0",
                              }}
                              disabled={single.isActive ? true : false}>
                              <img
                                src={moveImage}
                                style={{ marginRight: "2px" }}
                                alt=""
                              />
                              {single.isActive ? "Moved" : "Move to task"}
                            </button>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  );
                });
            })}
          </div>
          <Pagination
            count={noOfPages}
            page={page}
            onChange={handleChange}
            defaultPage={1}
            color="primary"
            size="large"
            showFirstButton
            showLastButton
          />
        </Stack>
        <Box flex={"1"}>
          <Typography
            textAlign={"center"}
            bgcolor={"#04B2AE"}
            py={"10px"}
            color={"#ffff"}
            borderRadius={"6px"}
            mb={"16px"}>
            Tasks Assigned
          </Typography>

          <Box
            py={"9px"}
            mx={"19px"}
            borderRadius={"4px"}
            display={"flex"}
            justifyContent={"space-between"}
            bgcolor={"#ffff"}
            px={"8px"}
            alignItems={"center"}>
            <Box display={"flex"} alignItems={"center"} gap={"5px"} flex={"1"}>
              <img src={searchIcon} alt="" />
              <Input
                placeholder="Search by user"
                sx={{ width: "80%" }}
                value={searchQuery2}
                onChange={(e) => setSearchQuery2(e.target.value.toLowerCase())}
              />
            </Box>
            <img
              src={closeIcon}
              onClick={handleRemoveSearch2}
              style={{ cursor: "pointer" }}
              alt=""
            />
          </Box>
          <Stack
            alignItems={"center"}
            direction={"row"}
            justifyContent={"space-evenly"}
            sx={{ m: 1 }}>
            {/* <input
              type="date"
              style={{ borderRadius: "6px", padding: 1 }}
              value={startDate}
              name="startDate"
              // onChange={handleChange}
            />

            <label>To</label>
            <input
              style={{ borderRadius: "6px", padding: 1 }}
              type="date"
              value={endDate}
              name="endDate"
              // onChange={handleChange}
            /> */}
            {/* <button onClick={handleApplyFilter}>Apply Filter</button> */}
          </Stack>
          {isLoading && (
            <div
              style={{
                fontSize: "50px",
                position: "absolute",
                top: "50%",
                bottom: "50%",
                left: "50%",
                right: "50%",
                zIndex: "9999",
              }}>
              <Loader />
            </div>
          )}
          <div
            style={{
              maxHeight: "calc(100vh - 300px)",
              overflowY: "scroll",
            }}>
            {assignedTasks
              .slice((paper - 1) * itemsPerPage, paper * itemsPerPage)
              .filter((single) =>
                // Step 3: Filter based on search query

                // (single.task &&
                //   single.task.toLowerCase().includes(searchQuery2)) ||
                single?.members?.some((assigneeName) =>
                  Array.isArray(assigneeName.name)
                    ? assigneeName.name.some((ass) =>
                        ass.toLowerCase().includes(searchQuery2.toLowerCase())
                      )
                    : assigneeName.name
                        .toLowerCase()
                        .includes(searchQuery2.toLowerCase())
                )
              )

              .map((single) => {
                return (
                  <Box
                    sx={{ m: 1.9 }}
                    bgcolor={"#E6E6FA"}
                    borderRadius={"6px"}
                    py="16px"
                    onClick={() => handleModal(single)}>
                    <Box
                      bgcolor={"#ffff"}
                      my={"24px"}
                      mx={"19px"}
                      px={"16px"}
                      py="19px">
                      <Box
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"space-between"}>
                        <img
                          src={
                            single.imageUrl[0]
                              ? single.imageUrl[0]
                              : uploadimage
                          }
                          alt=""
                          width="24px"
                        />

                        <Typography fontSize={"12px"} color={"#67748E"}>
                          Status : {single.journey}
                          <img src={menuIcon} alt="" />
                        </Typography>
                      </Box>
                      <Box
                        display={"flex"}
                        alignItems={"center"}
                        gap={"13px"}
                        my={"16px"}>
                        <img src={checkIcon} alt="" />
                        <Typography fontSize={"14px"} color={"#67748E"}>
                          {single.details}
                        </Typography>
                      </Box>

                      <Box
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                        my={"16px"}>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <img src={userIcon} width="40px" alt="" />
                          <p
                            style={{
                              fontSize: "12px",
                              color: "#67748E",
                              lineHeight: "224%",
                            }}>
                            {single.members && single.members[0].name}:<br />
                            Employee Name
                          </p>
                        </Box>
                        <Box>
                          <Typography
                            sx={{ color: "#21A49E", fontSize: "12px" }}>
                            {single.task}
                          </Typography>
                          <Typography
                            sx={{ color: "#67748E", fontSize: "12px" }}>
                            {single.date}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                );
              })}
          </div>
          <Pagination
            count={noOfPages}
            page={paper}
            onChange={handlePaperChange}
            defaultPage={1}
            color="primary"
            size="large"
            showFirstButton
            showLastButton
          />
        </Box>
      </Box>

      {addModal && (
        <TrakingDetail
          onClose={handleModal}
          task={selected}
          projectImg={projectImg}
          group={selectedGroup}
          onSuccess={onSuccess}
          single={selected}
          all={allGroups}
          delTask={del}
          myGroup={myGroup}
          myTask={myTask}
        />
      )}
    </>
  );
};

export default Traking;
