import axios from "axios";
import React, { useCallback, useLayoutEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import S3FileUpload from "react-s3/lib/ReactS3";
import { Stack, styled } from "@mui/system";
import CardImg from "../../assets/images/project/cardImg.png";
import { Grid, InputLabel } from "@mui/material";
import { BreakWord, CancelButton, SaveButton } from "../../styles/style";
import { formatDateYYYYMMDD } from "../../user portal/reuseable components";
import moment from "moment/moment";
import ReactSelect from "react-select";
import ImageViewer from "react-simple-image-viewer";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Loader from "./Loader";
import emp from "../../assets/images/project/emp.svg";
import uploadimg from "../../assets/images/project/add_a_photo.svg";
import {
  FormControl,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  InputAdornment,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
const config = {
  bucketName: "infini8ai-images",
  dirName: "photos",
  error: "",
  region: "us-east-1",
  accessKeyId: "AKIA44SH2DQ6PSTL43O7",
  secretAccessKey: "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",
};
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  height: "600px",
  overflow: "scroll",
  transform: "translate(-50%, -50%)",
  background: "#FFFFFF",
  boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.2)",
  borderRadius: "12px",
  // height: "500px",
  p: 4,
};
const RemoveBtn = styled(Button)({
  // height: "36px",
  width: "98px",
  borderRadius: "4px",
  border: "1px solid #C02727",
  color: "#A42121",
  textTransform: "capitalize",
  "&:hover": {
    color: "#FFFFFF",
    background: "#C02727",
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.16)",
    border: "1px solid #C02727",
  },
  "&:active": {
    background: "#931D1D",
    border: "1px solid #A22020",
  },
});
function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      height: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
window.Buffer = window.Buffer || require("buffer").Buffer;
const TaskDetailPopup = ({
  popup,
  closeTaskPopup,
  selectedRow,
  selectedColName,
  projName,
  getallTask,
  teamMembers,
  allUsers,
}) => {
  const theme = useTheme();

  const username = localStorage.getItem("userName");
  const userImage = localStorage.getItem("imageUrl");
  const [state, setState] = useState(selectedRow);
  const [displayImg, setDisplayImg] = useState("");
  const [commentSec, setCommentSec] = useState(
    selectedRow.commentSection ?? []
  );
  const [comments, setComments] = useState("");

  const [newImages, setNewImages] = useState([]);
  const [displayImages, setDisplayImages] = useState([]);
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  useLayoutEffect(() => {
    let date = formatDateYYYYMMDD(state.deadline);
    let assignedDate = moment.unix(selectedRow.created_at).format("YYYY-MM-DD");

    setState((prevState) => ({
      ...prevState,
      deadline: date,
      assigned: assignedDate,
    }));
    let updatedState =
      state.members &&
      state.members.map((item) => ({
        ...item,
        label: item.name,
        value: item.name,
      }));

    setState((prevState) => ({
      ...prevState,
      members: updatedState,
    }));
    const keyDownHandler = (event) => {
      if (event.keyCode === 13) {
        event.preventDefault();
        document.getElementById("btn").click();
      }
    };
    document.addEventListener("keydown", keyDownHandler);
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, []);
  const handleChange = (e) => {
    let { name, value } = e.target;
    if (name === "members") {
      let arr = JSON.parse(value);
      setState((prev) => ({ ...prev, members: [arr] }));
    } else if (name === "comments") {
      setComments(value);
    } else {
      setState((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  // const updateTasks = async () => {
  //   let url = state.imageUrl;
  //   if (displayImg) {
  //     let { location } = await S3FileUpload.uploadFile(state.imageUrl, config);
  //     url = location;
  //   }
  //   await axios.post(`https://infini8ai.herokuapp.com/tasks`, {
  //     request: {
  //       method: "updateTask",
  //       data: { ...state, imageUrl: url, commentSection: commentSec },
  //     },
  //   });
  //   toast.success("Task Edit Successfully");
  //   closeTaskPopup();
  //   getallTask();
  // };

  const updateTasks = async () => {
    // Set isLoading to true to show the loader
    setIsLoading(true);

    try {
      let url = state.imageUrl;
      if (displayImg) {
        let { location } = await S3FileUpload.uploadFile(
          state.imageUrl,
          config
        );
        url = location;
      }

      await axios.post(`https://infini8ai.herokuapp.com/tasks`, {
        request: {
          method: "updateTask",
          data: { ...state, imageUrl: url, commentSection: commentSec },
        },
      });

      toast.success("Task Edit Successfully");
      closeTaskPopup();
      getallTask();
    } catch (error) {
      // Handle any errors here
      // You can add error handling logic and display error messages
      toast.error("Error updating task. Please try again.");
    } finally {
      // Set isLoading to false after the update operation (whether success or error)
      setIsLoading(false);
    }
  };

  const deleteTask = async () => {
    await axios.post(`https://infini8ai.herokuapp.com/tasks`, {
      request: {
        method: "deleteTask",
        data: {
          _id: state._id,
        },
      },
    });
    toast.warning("Task Deleted Successfully");
    getallTask();
    closeTaskPopup();
  };
  // const handleImg = (e) => {
  //   let displayImage = URL.createObjectURL(e.target.files[0]);
  //   setDisplayImg(displayImage);
  //   setState((prev) => ({ ...prev, imageUrl: e.target.files[0] }));
  // };

  const allImages = () => {
    let arr = Array.isArray(state.imageUrl);

    if (arr) {
      return [...displayImages, ...state.imageUrl];
    } else if (typeof state.imageUrl === "string") {
      return [...displayImages, state.imageUrl];
    } else {
      return [...displayImages];
    }
  };

  const handleImg = (e) => {
    const files = Array.from(e.target.files);
    let img = [];
    files.forEach((item) => {
      img.push(URL.createObjectURL(item));
    });
    setDisplayImages((prev) => [...img, ...prev]);
    setNewImages((prev) => [...files, ...prev]);
  };
  const onChange = (selectedOptions) => {
    setState((prev) => ({ ...prev, members: selectedOptions }));
  };
  const saveComments = (e) => {
    let date = moment().format("MMM. D, YYYY");
    if (e.code === "Enter" && comments !== "") {
      setCommentSec([
        { username, comment: comments, imageUrl: userImage, date: date },
        ...commentSec,
      ]);
      setComments("");
    }
  };

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const handleRemoveImg = (arg, idx) => {
    if (arg.toString().includes("blob")) {
      let filtered = displayImages.filter((item) => item != arg);
      setDisplayImages(filtered);
      let copy = [...newImages];
      copy.splice(idx, 1);
      setNewImages(copy);
    } else {
      let arr = Array.isArray(state.imageUrl);
      if (arr) {
        let filtered = state.imageUrl.filter((item) => item != arg);
        setState((prev) => ({ ...prev, imageUrl: filtered }));
      } else {
        setState((prev) => ({ ...prev, imageUrl: [] }));
      }
    }
  };

  const handleInputKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent the default Enter key behavior (form submission)

      // Insert a newline character at the cursor's current position
      const { selectionStart, selectionEnd, value } = event.target;
      const newValue =
        value.substring(0, selectionStart) +
        "\n" +
        value.substring(selectionEnd);

      setState((prevState) => ({
        ...prevState,
        details: newValue, // Set the updated text to the state's 'details' property
      }));

      // Move the cursor to the position after the inserted newline character
      const newCursorPosition = selectionStart + 1;
      event.target.setSelectionRange(newCursorPosition, newCursorPosition);
    }
  };
  const handleSelectChange = (event) => {
    const {
      target: { value, name },
    } = event;
    setState((prev) => ({
      ...prev,
      [name]:
        // On autofill we get a stringified value.
        typeof value === "string" ? value.split(",") : value,
    }));
  };
  const onColabChange = (selectedOptions) => {
    setState((prev) => ({ ...prev, collaborators: selectedOptions }));
  };
  return (
    <>
      {isLoading && (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: "9999",
          }}>
          <Loader />
        </div>
      )}

      <Modal
        open={popup}
        onClose={closeTaskPopup}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style} justifyContent="center">
          <Stack direction="row" justifyContent="space-between">
            <Box></Box>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={{ marginLeft: 8 }}>
              Task Details
            </Typography>
            <RemoveBtn size="small" onClick={deleteTask}>
              Remove
            </RemoveBtn>
          </Stack>
          {/* <Stack justifyContent="center" alignItems="center" sx={{ p: 1 }}>
          <img
            src={displayImg ? displayImg : state.imageUrl}
            alt=""
            width="200px"
            height="80px"
          />
          <input
            type="file"
            multiple
            accept="/image/*"
            onChange={handleImg}
            style={{ border: 0, width: "200px", marginTop: 3 }}
          />
        </Stack> */}
          <Stack justifyContent="center" alignItems="center" sx={{ p: 1 }}>
            <Stack
              alignItems="center"
              justifyContent="center"
              direction="row"
              spacing={1}>
              {allImages().map((src, index) => (
                <Box className="profile-pic">
                  <img
                    src={src ? src : uploadimg}
                    onClick={() => openImageViewer(index)}
                    width="150"
                    height="150"
                    key={index}
                    style={{ margin: "2px" }}
                    alt=""
                  />
                  <Box className="edit">
                    <DeleteForeverIcon
                      sx={{ color: "red", cursor: "pointer" }}
                      onClick={() => handleRemoveImg(src, index)}
                    />
                  </Box>
                </Box>
              ))}

              {allImages().length == 0 && (
                <img
                  src={uploadimg}
                  width="150"
                  height="150"
                  style={{ margin: "2px" }}
                  onClick={() => openImageViewer()}
                  alt=""
                />
              )}

              {isViewerOpen && (
                <ImageViewer
                  src={allImages()}
                  currentIndex={currentImage}
                  onClose={closeImageViewer}
                  disableScroll={false}
                  backgroundStyle={{
                    backgroundColor: "rgba(0,0,0,0.9)",
                  }}
                  closeOnClickOutside={true}
                />
              )}
            </Stack>
            <label htmlFor="fileInput" className="custom-file-upload">
              Upload Images
            </label>
            <input
              type="file"
              id="fileInput"
              multiple
              accept="image/*"
              onChange={handleImg}
              style={{ display: "none" }}
            />
          </Stack>
          <Grid container spacing={1}>
            <Grid item md={12} xs={12}>
              <InputLabel>Title</InputLabel>
              <textarea
                style={{
                  width: "100%",
                  borderRadius: "8px",
                  borderColor: "#BDBDBD",
                  padding: 5,
                }}
                variant="outlined"
                placeholder="Task Name"
                rows={1}
                name="task"
                value={state.task}
                onChange={handleChange}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Status</InputLabel>
                <Select
                  name="status"
                  value={state.status}
                  onChange={handleChange}
                  input={<OutlinedInput label="Status" />}
                  sx={{ height: "2.8rem" }}>
                  <MenuItem value="Pending">Pending</MenuItem>
                  <MenuItem value="InProgress">InProgress</MenuItem>
                  <MenuItem value="Testing">Testing</MenuItem>
                  <MenuItem value="DOne">Done</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Priority</InputLabel>
                <Select
                  name="priority"
                  value={state.priority}
                  onChange={handleChange}
                  input={<OutlinedInput label="Priority" />}
                  sx={{ height: "2.8rem" }}>
                  <MenuItem value="First">High</MenuItem>
                  <MenuItem value="Second">Medium</MenuItem>
                  <MenuItem value="third">Low</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                id="outlined-required"
                variant="outlined"
                name="startDate"
                value={state.startDate}
                label="Start Date"
                type="date"
                onChange={handleChange}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                id="outlined-required"
                variant="outlined"
                name="endDate"
                value={state.endDate}
                label="End Date"
                type="date"
                onChange={handleChange}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <InputLabel>Description</InputLabel>
              <textarea
                style={{
                  width: "100%",
                  borderRadius: "8px",
                  borderColor: "#BDBDBD",
                  padding: 5,
                }}
                fullWidth
                variant="outlined"
                name="details"
                value={state.details}
                placeholder="Description"
                onChange={handleChange}
              />
            </Grid>
            <FormControl fullWidth sx={{ m: 1 }}>
              <InputLabel htmlFor="outlined-adornment-amount">
                Post an update
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-amount"
                value={comments}
                name="comments"
                onChange={handleChange}
                startAdornment={
                  <InputAdornment position="start">
                    {" "}
                    <img
                      src={localStorage.getItem("imageUrl")}
                      alt=""
                      width="30px"
                      height="30px"
                      style={{
                        borderRadius: "100px",
                        // marginRight: 20,
                        marginTop: 3,
                      }}></img>
                  </InputAdornment>
                }
                label="Post an update"
              />
            </FormControl>
            {commentSec &&
              commentSec.map((item) => (
                <Stack
                  direction="row"
                  sx={{ background: "#F7F7F7", m: 0.5, borderRadius: 2, p: 1 }}>
                  <img
                    src={item.imageUrl && item.imageUrl}
                    alt=""
                    width="30px"
                    height="30px"
                    style={{
                      borderRadius: "100px",
                      marginRight: 20,
                    }}></img>
                  <Stack>
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between">
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "bold",
                          marginRight: 5,
                          color: "#21A49E",
                        }}>
                        {item.username}
                      </Typography>
                      <InputLabel sx={{ fontSize: "12px", px: 2 }}>
                        {item.date}
                      </InputLabel>
                    </Stack>
                    <BreakWord>{item.comment}</BreakWord>
                  </Stack>
                </Stack>
              ))}

            <Stack
              direction="row"
              justifyContent="space-between"
              sx={{ mt: 1 }}>
              <Typography variant="p" sx={{ fontSize: "16px" }}>
                {state.assignedBy} Created This Task
              </Typography>
            </Stack>
            <Grid container sx={{ mt: 2 }}>
              <Grid item xs={1}>
                <img
                  src={
                    state.members?.length !== 0 && state.members[0]?.imageUrl
                      ? state.members[0]?.imageUrl
                      : emp
                  }
                  alt="card"
                  width="50px"
                  height="50px"
                  style={{ borderRadius: "100px" }}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel id="demo-multiple-name-label">
                    Assignee
                  </InputLabel>
                  <ReactSelect
                    value={state.members}
                    isMulti
                    onChange={onChange}
                    options={allUsers}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        border: 0,
                        // margin: "12px",
                        background: "#F7F7F7",
                        fontSize: "14px",
                        minHeight: "20px",
                        width: "250px",
                      }),
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item md={4} xs={12}>
                <InputLabel>Collaborators</InputLabel>
                <ReactSelect
                  value={state.collaborators}
                  isMulti
                  onChange={onColabChange}
                  options={allUsers}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      border: 0,
                      // margin: "12px",
                      background: "#F7F7F7",
                      fontSize: "14px",
                      minHeight: "20px",
                      width: "250px",
                    }),
                  }}
                />
              </Grid>
            </Grid>
            <Grid item md={4} xs={12}>
              <Stack direction="row" alignItems="center">
                <Button onClick={() => closeTaskPopup()}>Cancel</Button>
                <Button onClick={updateTasks}>Save</Button>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

export default TaskDetailPopup;
