import React, { useState } from "react";
import useWindowSize from "../utils/useWindowSize";
import Slider from "react-slick";

import Star_2 from "../../assets/images/Star 2.svg";
import normal_line from "../../assets/images/normal_line.svg";
import active_line from "../../assets/images/active_line.svg";
import html from "../../assets/images/html.svg";
import html2 from "../../assets/images/html_2.svg";
import react_icon from "../../assets/images/react.svg";
import vue from "../../assets/images/vue.svg";
import java from "../../assets/images/java.png";
import lang1 from "../../assets/images/lang1.png";
import lang2 from "../../assets/images/lang2.png";
import lang3 from "../../assets/images/lang3.png";
import lang4 from "../../assets/images/lang4.png";
import lang5 from "../../assets/images/lang5.png";
import lang6 from "../../assets/images/lang6.png";
import lang7 from "../../assets/images/lang7.png";
import lang8 from "../../assets/images/lang8.png";
import sass from "../../assets/images/sass.png";
import qt from "../../assets/images/qt.png";
import vscode from "../../assets/images/vscode.png";
import android from "../../assets/images/android.png";
import apple from "../../assets/images/apple.png";
import nextjs from "../../assets/images/nextjs.png";
import nodejs from "../../assets/images/nodejs.png";
import aws from "../../assets/images/aws.png";
import bootstrap_icon from "../../assets/images/bootstrap.svg";
// import left_arow from "../../assets/images/left_arrow.svg";
// import right_arrow from "../../assets/images/right_arrow.svg";
import web_development_img from "../../assets/images/home page/web_development.svg";
import app_development_img from "../../assets/images/home page/app-development.svg";
import desktop_development_img from "../../assets/images/home page/desktop-computer.svg";
import database_development_img from "../../assets/images/home page/database-management.svg";
import Rectangle_158 from "../../assets/images/Rectangle 158.svg";

export default function ServiceWeOffer() {
  const size = useWindowSize();

  const [activeItemIndex, setActiveItemIndex] = useState(0);

  const [activeTab, setActiveTab] = useState(1);
  const webDevelopment =
    "We offer a variety of front end, back end, and progressive web development services, from creating mobile web development solutions and responsive web designs, to building custom web experiences using cutting-edge web technologies.";
  const mobileDevelopment =
    "Infini8AI provides full-stack mobile application development services. Our professionals can develop and deliver business-centric, highly customized native, cross-platform, and hybrid mobile applications.";
  const destopDevelopment =
    "The combination of our experience, technical expertise, and deep understanding of the desktop operating system architecture, ensures meeting our clients’ needs and expectations. ";
  const cludeDevelopment =
    "Cloud native applications help companies speed up time-consuming and demanding tasks performed on optimizing existing apps and building new ones while, at the same time, enhancing user experience. ";
  let activeImg = web_development_img;
  if (activeTab === "2") {
    activeImg = app_development_img;
  } else if (activeTab === "3") {
    activeImg = desktop_development_img;
  } else if (activeTab === "4") {
    activeImg = database_development_img;
  } else {
    activeImg = web_development_img;
  }

  const corouselItems = size.width >= 768 ? 6 : 4;
  return (
    <div style={{backgroundColor:"#F5FFFF", border:"0px"}}>
      <div className="container service_we_offer  d-flex align-items-end justify-content-center py-1">
        <p className=" fs-2 mt-5 fw-bolder position-relative text-center" style={{color:"#04B2AE"}}>
          Sevices we offer{" "}
          <span className="position-absolute mt-2" style={{ zIndex: "-2" }}>
            <img
              src={Rectangle_158}
              style={{ width: "20px", marginLeft: "-10px" }}
              alt=""
            />
          </span>
        </p>
      </div>
      {size.width > 768 ? (
        <FullScreenView
          activeImg={activeImg}
          activeItemIndex={activeItemIndex}
          setActiveItemIndex={setActiveItemIndex}
          webDevelopment={webDevelopment}
          mobileDevelopment={mobileDevelopment}
          destopDevelopment={destopDevelopment}
          cludeDevelopment={cludeDevelopment}
          setActiveTab={setActiveTab}
          activeTab={activeTab}
          corouselItems={corouselItems}
        />
      ) : (
        <MobileView
          activeImg={activeImg}
          activeItemIndex={activeItemIndex}
          setActiveItemIndex={setActiveItemIndex}
          webDevelopment={webDevelopment}
          mobileDevelopment={mobileDevelopment}
          destopDevelopment={destopDevelopment}
          cludeDevelopment={cludeDevelopment}
          setActiveTab={setActiveTab}
          activeTab={activeTab}
          corouselItems={corouselItems}
        />
      )}
    </div>
  );
}

function MobileView(props) {
  return (
    <div className="service_we_offer_mobile">
      <div className="row">
        <div className="col-4">
          <img
            src={props.activeImg}
            className="img-fluid  my-auto serv_offer_img"
            alt=""
          />
        </div>
        <div className="col-8">
          <select
            className="rounded"
            style={{
              padding: "15px",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              borderBottom: "3px solid black",
              width: "100%",
              maxWidth: "400px",
            }}
            value={props.activeTab}
            onChange={(e) => props.setActiveTab(e.target.value)}
          >
            <option value={"1"}>Web Developement</option>
            <option value={"2"}>Mobile Developement</option>
            <option value={"3"}>Desktop Applications</option>
            <option value={"4"}>Cloud Native</option>
          </select>
        </div>
      </div>

      <p className="fs-6">
        {props.activeTab == "1" && props.webDevelopment}
        {props.activeTab == "2" && props.mobileDevelopment}
        {props.activeTab == "3" && props.destopDevelopment}
        {props.activeTab == "4" && props.cludeDevelopment}
      </p>
      <div style={{ width: "90%" }} className="mt-1 mx-auto">
        <CustomSlider />
      </div>
    </div>
  );
}

function FullScreenView(props) {
  return (
    // <div>
      <div className="development" style={{paddingTop:"30px", paddingBottom:"50px"}}>
        <div className="rectangle"> </div>
        <div className="container" style={{maxWidth:"100%"}}>
          <div className="row gx-5">
            <div className="col-5 px-5 mt-5">
              <div className="d-flex my-3">
                <span>
                  <img src={Star_2} alt="" />
                </span>
                <p className="fs-6 fw-semibold text_blue  ms-2">
                  {props.activeTab == "1" && "Web Developement"}
                  {props.activeTab == "2" && "Mobile Developement"}
                  {props.activeTab == "3" && "Desktop Applications"}
                  {props.activeTab == "4" && "Cloud Native"}
                </p>
              </div>
              <p className="service_offer_text lh-sm">
                {props.activeTab == "1" && props.webDevelopment}
                {props.activeTab == "2" && props.mobileDevelopment}
                {props.activeTab == "3" && props.destopDevelopment}
                {props.activeTab == "4" && props.cludeDevelopment}
              </p>
              <CustomSlider />
            </div>
            <div className="col-7 px-2 float-end">
              <div className="row" style={{backgroundColor:"#E3F9F7",  borderRadius: "175px 0px 0px 175px"
}}>
                <div className="col-sm-5 d-flex flex-column justify-content-between ">
                  <img
                    src={props.activeImg}
                    className="img-fluid  my-auto sevices-img"
                    alt=""
                  />
                </div>
                <div className="col-sm-7 d-flex flex-column justify-content-between h-100 py-5">
                  <div className="d-flex align-items-center">
                    <input
                      type="radio"
                      className="h-100"
                      name="a"
                      value={1}
                      checked={props.activeTab == "1"}
                      onChange={() => props.setActiveTab("1")}
                    />
                    <img
                      src={props.activeTab == "1" ? active_line : normal_line}
                      alt=""
                    />
                    <p className="ms-3 fw-semibold"  onClick={() => props.setActiveTab("1")}>Web Developement</p>
                  </div>
                  <div className="d-flex align-items-center">
                    <input
                      type="radio"
                      className="h-100"
                      name="a"
                      value={2}
                      checked={props.activeTab == "2"}
                      onChange={() => props.setActiveTab("2")}
                    />
                    <img
                      src={props.activeTab == "2" ? active_line : normal_line}
                      alt=""
                    />

                    <p className="ms-3 fw-semibold"  onClick={() => props.setActiveTab("2")}>Mobile Developement</p>
                  </div>
                  <div className="d-flex align-items-center">
                    <input
                      type="radio"
                      className="h-100"
                      name="a"
                      value={3}
                      checked={props.activeTab == "3"}
                      onChange={() => props.setActiveTab("3")}
                    />
                    <img
                      src={props.activeTab == "3" ? active_line : normal_line}
                      alt=""
                    />

                    <p className="ms-3 fw-semibold" onClick={() => props.setActiveTab("3")}>Desktop Applications</p>
                  </div>
                  <div className="d-flex align-items-center">
                    <input
                      type="radio"
                      className="h-100"
                      name="a"
                      value={4}
                      checked={props.activeTab == "4"}
                      onChange={() => props.setActiveTab("4")}
                    />
                    <img
                      src={props.activeTab == "4" ? active_line : normal_line}
                      alt=""
                    />

                    <p className="ms-3 fw-semibold" onClick={() => props.setActiveTab("4")}>Cloud Native</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    // </div>
  );
}

function CustomSlider() {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    speed: 4000,
    autoplaySpeed: 0,
    cssEase: "linear",
    // nextArrow: <img src={right_arrow} />,
    // prevArrow: <img src={left_arow} />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };
  return (
    <div style={{ height: "93%" }} className="mt-5 px-3">
      {/* <Slider {...settings}>
        <img src={html} className="img-fluid" alt="" />
        <img src={html2} className="img-fluid" alt="" />
        <img src={react_icon} className="img-fluid" alt="" />
        <img src={vue} className="img-fluid" alt="" />
        <img src={bootstrap_icon} className="img-fluid" alt="" />
        <img src={java} className="img-fluid" alt="" />
        <img src={lang1} className="img-fluid" alt="" />
        <img src={lang2} className="img-fluid" alt="" />
        <img src={lang3} className="img-fluid" alt="" />
        <img src={lang4} className="img-fluid" alt="" />
        <img src={lang5} className="img-fluid" alt="" />
        <img src={lang6} className="img-fluid" alt="" />
        <img src={lang7} className="img-fluid" alt="" />
        <img src={lang8} className="img-fluid" alt="" />
        <img src={sass} className="img-fluid" alt="" />
        <img src={qt} className="img-fluid" alt="" />
        <img src={android} className="img-fluid" alt="" />
        <img src={vscode} className="img-fluid" alt="" />
        <img src={aws} className="img-fluid" alt="" />
        <img src={nextjs} className="img-fluid" alt="" />
        <img src={nodejs} className="img-fluid" alt="" />
        <img src={apple} className="img-fluid" alt="apple" />
      </Slider> */}

      <div className="slider-services">
    <div className="slide-track-services">
      <div className="slide-services img">
        <img src={html} alt="" />
      </div>
      <div className="slide-services img">
        <img src={html2} alt="" />
      </div>
      <div className="slide-services img">
        <img src={react_icon} alt="" />
      </div>
      <div className="slide-services img">
        <img src={vue} alt="" />
      </div>
      <div className="slide-services img">
        <img src={bootstrap_icon} alt="" />
      </div>
      <div className="slide-services img">
        <img src={java} alt="" />
      </div>
      <div className="slide-services img">
        <img src={lang1} alt="" />
      </div>
      <div className="slide-services img">
        <img src={lang2} alt="" />
      </div>
      <div className="slide-services img">
        <img src={lang3} alt="" />
      </div>
      <div className="slide-services img">
        <img src={lang4} alt="" />
      </div>
      <div className="slide-services img">
        <img src={lang5} alt="" />
      </div>
      <div className="slide-services img">
        <img src={lang6} alt="" />
      </div>
      <div className="slide-services img">
        <img src={lang7} alt="" />
      </div>
      <div className="slide-services img">
        <img src={lang8} alt="" />
      </div>
      <div className="slide-services img">
        <img src={sass} alt="" />
      </div>
      <div className="slide-services img">
        <img src={qt} alt="" />
      </div>
      <div className="slide-services img">
        <img src={android} alt="" />
      </div>
      <div className="slide-services img">
        <img src={vscode} alt="" />
      </div>
      <div className="slide-services img">
        <img src={aws} alt="" />
      </div>
      <div className="slide-services img">
        <img src={nextjs} alt="" />
      </div>
      <div className="slide-services img">
        <img src={nodejs} alt="" />
      </div>
      <div className="slide-services img">
        <img src={apple} alt="" />
      </div>
    </div>
  </div>
    </div>
  );
}
