import React, { useLayoutEffect } from "react";
import { useState } from "react";
import Star from "../../../assets/images/dashboard/star.png";
import { Button, CircularProgress, Grid, Typography } from "@mui/material";
import { Box, Stack, styled } from "@mui/system";
import ProfileFields from "./ProfileFields";
import ReplyAllOutlinedIcon from "@mui/icons-material/ReplyAllOutlined";
import { useLocation, useNavigate } from "react-router-dom";
import DashboardLayout from "../../../layouts/Dashboard";
import DashboardNavbar from "../../../layouts/Navbars/DashboardNavbar";
import { getUser } from "../../../apiservices/profile";

const Profile = () => {
  let navigate = useNavigate();
  let location = useLocation();
  const userEmail = location.state.empObj.email;
  const BackButton = styled(Button)({
    borderRadius: "4px",
    padding: "4px 8px 4px 8px",
    color: "#67748E",
    border: "1px solid #67748E",
    "&:hover": {
      background: "#67748E",
      color: "#FFFFFF",
    },
    "&:active": {
      background: "#586278",
      color: "#FFFFFF",
    },
  });

  const [state, setState] = useState({
    userData: [],
    editFields: false,
    loader: false,
  });

  useLayoutEffect(() => {
    getUsers();
  }, []);

  const getUsers = async () => {
    setState((prev) => ({ ...prev, loader: !prev.loader }));

    let data = {
      _id: userEmail,
    };
    let res = await getUser(data);
    let response = res.userObj;
    setState((prev) => ({
      ...prev,
      userData: response,
    }));
    setState((prev) => ({ ...prev, loader: !prev.loader }));
  };
  const back = () => {
    navigate("/employees", {
      state: {
        dialogue: true,
        empName: location.state.empName,
      },
    });
  };
  return (
    <DashboardLayout>
      <DashboardNavbar>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <img src={Star} alt="img" />
          </Grid>
          <Grid
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            item
            xs={window.innerWidth > 992 ? 9 : 8}>
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              Employee Profile
            </Typography>
          </Grid>
          <Grid
            item
            xs={1}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}>
            <BackButton size="small" onClick={back}>
              <ReplyAllOutlinedIcon />
            </BackButton>
          </Grid>
        </Grid>
      </DashboardNavbar>
      {state.loader ? (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress color="success" />
        </Box>
      ) : (
        <Stack>
          <ProfileFields userData={state.userData} />
        </Stack>
      )}
    </DashboardLayout>
  );
};

export default Profile;
