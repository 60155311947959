import React from "react";
const NaseemSection2 = () => {
  let url = "https://infini8ai-images.s3.amazonaws.com/naseem-casestudy";
  return (
    <div>
      <div id="surveyAnalysis" className="relative">
        <div id="features-divAnal" class="features-div ">
          <div className="container">
            <div className="row pt-5">
              <div className="col-sm-12 col-md-5 col-lg-5">
                <div className="row">
                  <div className="col-md-12">
                    <img
                      src={`${url}/surveyIllustration.svg`}
                      class="rounded img-fluid float-end surveyillustration"
                      alt="wrapkit"
                    />
                    <div className="surveyContent">
                      <p>Survey Analysis</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-7 col-lg-7">
                <div id="surveyContent">
                  <p>
                    The survey was conducted over <span>50</span>{" "}
                    <strong>schools</strong> with
                    <span> 10</span> <strong>school owners</strong> and{" "}
                    <span>80,000</span> <strong>students</strong>.
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12">
                <img
                  src={`${url}/Group4836.svg`}
                  class="rounded img-fluid Group4836"
                  alt="wrapkit"
                />
              </div>
            </div>
            <div id="AnalysisCardTop">
              <div className="row">
                <div className="col-md-11 m-auto ">
                  <div
                    className="surveyAnalysisCard1 "
                    style={{ marginTop: "0rem" }}
                  >
                    <div className="row " style={{ alignItems: "center" }}>
                      <div className="col-md-8">
                        <div className="row" style={{ alignItems: "flex-end" }}>
                          <div className="col-md-2">
                            <div className="smartPhonesIcon">
                              <img
                                style={{
                                  width: "100%",
                                  maxWidth: "50px",
                                  position: "relative",
                                  zIndex: "0",
                                }}
                                src={`${url}/Group1.png`}
                                class="rounded img-fluid float-end"
                                alt="wrapkit"
                              />
                            </div>
                          </div>
                          <div className="col-md-10">
                            <div className="smartPhonesTxt">
                              <p>96% of the school staff uses smart phones</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="smartPhonesCircle">
                          <img
                            style={{
                              width: "100%",
                              maxWidth: "194px",
                              position: "relative",
                              zIndex: "2",
                            }}
                            src={`${url}/Grou1443.png`}
                            class="rounded img-fluid float-end"
                            alt="wrapkit"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                      <img
                        src={`${url}/Group4837.svg`}
                        class="rounded img-fluid Group4837"
                        alt="wrapkit"
                      />
                    </div>
                  </div>
                  <div className="surveyAnalysisCard2">
                    <div className="row " style={{ alignItems: "center" }}>
                      <div className="col-md-4">
                        <div className="smartPhonesCircle  float-left">
                          <img
                            style={{
                              width: "100%",
                              maxWidth: "194px",
                              position: "relative",
                              zIndex: "2",
                            }}
                            src={`${url}/Group1444.png`}
                            class="rounded img-fluid float-end"
                            alt="wrapkit"
                          />
                        </div>
                      </div>
                      <div className="col-md-8">
                        <div className="row " style={{ alignItems: "center" }}>
                          <div className="col-md-9">
                            <div className="smartPhonesTxt">
                              <p>
                                89% wanted to integrate technology in their
                                institutions
                              </p>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="smartPhonesIcon">
                              <img
                                style={{
                                  width: "100%",
                                  maxWidth: "97.35px",
                                  position: "relative",
                                  zIndex: "2",
                                }}
                                src={`${url}/Group.png`}
                                class="rounded img-fluid float-end"
                                alt="wrapkit"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                      <img
                        src={`${url}/Group4837.svg`}
                        class="rounded img-fluid GroupSecond4837"
                        alt="wrapkit"
                      />
                    </div>
                  </div>
                  <div className="surveyAnalysisCard1">
                    <div className="row " style={{ alignItems: "center" }}>
                      <div className="col-md-8">
                        <div className="row" style={{ alignItems: "center" }}>
                          <div className="col-md-2">
                            <div className="smartPhonesIcon">
                              <img
                                style={{
                                  width: "100%",
                                  maxWidth: "113.61px",
                                  position: "relative",
                                  zIndex: "2",
                                }}
                                src={`${url}/Group33.png`}
                                class="rounded img-fluid float-end"
                                alt="wrapkit"
                              />
                            </div>
                          </div>
                          <div className="col-md-10">
                            <div className="smartPhonesTxt">
                              <p>
                                88% believed they needed thorough training
                                before deployment
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="smartPhonesCircle">
                          <img
                            style={{
                              width: "100%",
                              maxWidth: "194px",
                              position: "relative",
                              zIndex: "2",
                            }}
                            src={`${url}/Grou1446.png`}
                            class="rounded img-fluid float-end"
                            alt="wrapkit"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                      <img
                        src={`${url}/Group4837.svg`}
                        class="rounded img-fluid GroupSecond4837"
                        alt="wrapkit"
                      />
                    </div>
                  </div>
                  <div className="surveyAnalysisCard2">
                    <div className="row " style={{ alignItems: "center" }}>
                      <div className="col-md-4">
                        <div className="smartPhonesCircle  float-left">
                          <img
                            style={{
                              width: "100%",
                              maxWidth: "194px",
                              position: "relative",
                              zIndex: "2",
                            }}
                            src={`${url}/Group1448.png`}
                            class="rounded img-fluid float-end"
                            alt="wrapkit"
                          />
                        </div>
                      </div>
                      <div className="col-md-8">
                        <div className="row " style={{ alignItems: "center" }}>
                          <div className="col-md-9">
                            <div className="smartPhonesTxt">
                              <p>
                                75% wanted to communicate with students and
                                their parents through an LMS
                              </p>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="smartPhonesIcon">
                              <img
                                style={{
                                  width: "100%",
                                  maxWidth: "97.35px",
                                  position: "relative",
                                  zIndex: "2",
                                }}
                                src={`${url}/Group32.png`}
                                class="rounded img-fluid float-end"
                                alt="wrapkit"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="Interviews"
        className="relative"
        style={{ backgroundColor: "#F0A512" }}
      >
        <div className="features-div ">
          <div className="container">
            <div className="row ">
              <div className="col-md-5">
                <div className="row" style={{ paddingTop: "15rem" }}>
                  <div className="col-md-12">
                    <img
                      src={`${url}/Group1469.png`}
                      class="rounded img-fluid float-end Group1469"
                      alt="wrapkit"
                    />
                    <div className="surveyContent">
                      <p>Interviews</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-7">
                <div id="surveyContent">
                  <p>Interviews conducted with a group of people including:</p>
                  <span>
                    <strong>
                      School Executives, <br />
                      Principles, <br />
                      Teachers & Parents.
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p id="questionsAsked">
                      The questions asked were generally about:
                    </p>
                  </div>
                </div>
                <br />
                <div className="interviewsCard">
                  <div className="row">
                    <div className="col-md-10 offset-md-1">
                      <div className="col-md-10 offset-md-1 ">
                        <img
                          src={`${url}/Group4850.png`}
                          className="img-fluid"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="mainLearnings"
        className="inherit"
        style={{ backgroundColor: "#E1F3FF",paddingBottom:"20rem" }}
      >
        <div className="features-div ">
          <div className="container">
            <div className="row ">
              <div className="col-md-10 offset-md-1">
                <div className="LearningsTop text-center">
                  <div className="ExecutivesCard">
                    <img src={`${url}/Group4849.png`} className="" />
                  </div>
                </div>
              </div>
            </div>
            <div className="row ">
              <div className="col-md-12">
                <div className="LearningscontentCard text-center">
                  <div className="row" style={{ alignItems: "center" }}>
                    <div className="col">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="ExecutivesCard">
                            <img src={`${url}/Executives.png`} className="" />
                          </div>
                        </div>
                      </div>
                      <br />
                      <div className="row">
                        <div className="col-md-12">
                          <div className="ExecutivesCard">
                            <img src={`${url}/Teachers.png`} className="" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="StarCard">
                            <img src={`${url}/Vector2.png`} className="" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="ExecutivesCard">
                            <img src={`${url}/Principals.png`} className="" />
                          </div>
                        </div>
                      </div>
                      <br />
                      <div className="row">
                        <div className="col-md-12">
                          <div className="ExecutivesCard">
                            <img src={`${url}/Parents.png`} className="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="userPersonas" className="inherit">
        <div className="userPersonasFeatures ">
          <div className="ExecutivesCard1">
            <img src={`${url}/Group4831.png`} className="img-fluid" />
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-10 offset-md-1">
                <div className="ExecutivesCard2">
                  <img src={`${url}/charProfiles.png`} className="img-fluid" />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-10 offset-md-1">
                <div className="ExecutivesCard3">
                  <img src={`${url}/Group4835.png`} className="img-fluid" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NaseemSection2;
