import React from "react";
// import marker from "../../assets/images/footer/pindrop.png";
// import email from "../../assets/images/footer/mail.png";
// import phone from "../../assets/images/footer/call.png";
import footerbottem from "../../assets/images/footer/footerbottem.png";
import { Link ,useNavigate , } from "react-router-dom";
import { Link as Linkscroll,scroller } from "react-scroll";

export default function Footer() {
  const navigate = useNavigate();

  const handleScroll = (section) => {


    navigate('/');
    setTimeout(() => {
      scroller.scrollTo(section, {
        duration: 500,
        smooth: true,
      });
    }, 100);
  };
  return (
    <footer
      style={{
        backgroundColor: "#037d7a",
        backgroundImage: "none",
      }}
      className="page-footer font-small   pb-3 "
    >
      <div className="container">
        <div className="row footer-row" >
          <div className="col-12 col-lg-3 col-md-4 footer-sitemap">
            <p
              className="fs-6 mt-4"
              style={{ fontFamily: "Dosis",color:"#dde73c", }}
            >
              SITE MAP
            </p>
           
            <div>
                {/* <p style={{ margin: "0 0 20px 0",color: "#116065", fontWeight:600,font:"poppins", fontSize:"24px" }}>Case Studies</p> */}
                <ul  className="text-white mt-4 lh-sm justify-content-md-start justify-content-center"
              style={{ fontFamily: "Dosis" , listStyleType: "none", display:"flex", gap:"4rem"}}>
                <div style={{display:"flex", flexDirection:"column", gap:"3rem"}}>
                   <Link to="/" style={{  }}>
                   <li style={{color:"white",fontSize:"16px"}}>Home</li>
                  </Link>
                  <div
          className="cursor-pointer"
          style={{ cursor: "pointer",  }}
          onClick={() => handleScroll("ourProducts")}
        >
            <li style={{ fontFamily: "Dosis",fontSize:"16px"}}>Products</li>
        </div>
                  {/* <li>About Us</li> */}
                  <Link to="/our-process" >
                  <li style={{color:"white",fontSize:"16px"}}>Our Process</li>
                  </Link>
                  </div>
                  <div  style={{display:"flex", flexDirection:"column", gap:"3rem"}}>
                  <Link to="/team" >
                  <li style={{ color:"white",fontSize:"16px" }}>Team</li>
                  </Link>
                  <Link to="/portfolio" >
                  <li style={{ color:"white",fontSize:"16px"}}>Portfolio</li>
                  </Link>
                  <Link to="/careers" >
                  <li style={{ color:"white",fontSize:"16px"}}>Careers</li>
                  </Link>
                  </div>
                </ul>
                </div>
          </div>
          <div className="col-12 col-lg-3 col-md-4 footer-contactus">
            <p
              className="fs-6 mt-4 "
              style={{ fontFamily: "Dosis",color:"#dde73c" }}
            >
              CONTACT US
            </p>
            <p  
              className="text-white mt-4 lh-sm"
              style={{ fontFamily: "Dosis" ,fontSize:"16px"}}
            >
              IDEA London Ground Floor 69 Wilson Street, London EC2A 2BB
              <p className="mt-4 mb-4 text-white lh-sm"   style={{ fontFamily: "Dosis",fontSize:"16px" }}>For hiring inquiries please contact: <span style={{fontFamily: "Dosis",fontSize:"16px"}}>hr@infini8ai.com</span></p> 
              
            </p>
            <p
              className="text-white mt-4 lh-sm"
              style={{ fontFamily: "Dosis" }}
            >
          
              <p className="mt-4 mb-4 text-white lh-sm"   style={{ fontFamily: "Dosis",fontSize:"16px" }}>For all other queries please contact: <span style={{fontFamily: "Dosis",fontSize:"16px"}}>info@infini8ai.com </span> </p> 
              
            </p>
            <div className=" mt-2">
          <p style={{ color: "white",fontSize:"16px" }}>2024 Copyright: Infini8AI</p>
        </div>

          </div>
          <div className="col-12 col-lg-3 col-md-4 footer-casestudies" >
            <p
              className="fs-6  mt-4  "
              style={{ fontFamily: "Dosis",color:"#dde73c" }}
            >
              CASE   STUDIES
            </p>
            {/* <p
              className="text-white mt-4 lh-sm"
              style={{ fontFamily: "Bitter" }}
            >
              To receive updates about our new product offerings and latest
              developments in the world of AI, join our bi-weekly newsletter:{" "}
              <a href="https://veracious.ai/news-letter" target="_blank">
                Veracious AI
              </a>
            </p> */}
              <div>
                {/* <p style={{ margin: "0 0 20px 0",color: "#116065", fontWeight:600,font:"poppins", fontSize:"24px" }}>Case Studies</p> */}
                <ul  className="text-white mt-4 lh-sm"
              style={{ fontFamily: "Dosis" , listStyleType: "none", display:"flex",  gap:"4rem"}}>
                    <div  style={{display:"flex", flexDirection:"column", gap:"3rem"}}>

                    <Link to="/optimus-case-study">
                    <li style={{color:"white",fontSize:"16px"}}>Healthcare</li>
                    </Link>
                    <Link to="/naseem-case-study">
                    <li style={{color:"white",fontSize:"16px"}}>Education</li>
                    </Link>
                    <Link to="/qurbani-case-study">
                    <li style={{color:"white",fontSize:"16px"}}>Livestock</li>
                    </Link>
                    </div>
                    <div  style={{display:"flex", flexDirection:"column", gap:"3rem"}}>

                    <Link to="/stock-chain-case-study">
                    <li style={{color:"white",fontSize:"16px"}}>Logistics</li>
                    </Link>
                    <Link to="/businesspal-case-study">
                    <li style={{color:"white",fontSize:"16px"}}>Business</li>
                    </Link>
                    </div>
                </ul>
                </div>

          </div>
        </div>
        
      </div>
    </footer>
    // <footer>
    //   <div className="container text-white p-4">
    //     <div className="row">
    //       <div className="col-12 col-md-6">
    //         <h2 className="fw-semibold mb-2">ABOUT US</h2>
    //         <p className="lh-sm">
    //           Infinit8AI provides cutting edge solutions to all of your
    //           problems. We have highly skilled, professional and enthusiast
    //           individual that put all their efforts to make a product better
    //           than the expectation of our clients.
    //         </p>
    //       </div>
    //       <div className="col-12 col-md-3 mt-3 mt-md-0 ">
    //         <h2 className="fw-semibold mb-2">CONTACT US</h2>
    //         <ul className="list-unstyled">
    //           <li style={{ display: "flex" }}>
    //             {/* <img
    //                 style={{
    //                   width: "20px",
    //                   height: "20px",
    //                   marginRight: "5px",
    //                 }}
    //                 src={marker}
    //               ></img> */}
    //             <p className="base-text text-white">
    //               <img src={pindrop} className="img-fluid mx-2" /> 7 Dickinson
    //               Court, West Yorkshire, WF1 3PU
    //             </p>
    //           </li>
    //           <li style={{ display: "flex" }}>
    //             {/* <img
    //                 style={{
    //                   width: "20px",
    //                   height: "20px",
    //                   marginRight: "5px",
    //                 }}
    //                 src={marker}
    //               ></img> */}
    //             <p className="base-text text-white lh-sm">
    //               <img src={pindrop} className="img-fluid mx-2" /> Office 1206,
    //               National Software Technology Park H-12, NUST Islamabad
    //             </p>
    //           </li>
    //           <li style={{ display: "flex" }}>
    //             {/* <img
    //                 style={{
    //                   width: "20px",
    //                   height: "20px",
    //                   marginRight: "5px",
    //                 }}
    //                 src={phone}
    //               ></img> */}
    //             <p className="base-text text-white lh-sm">
    //               {" "}
    //               <img src={call} className="img-fluid mx-2" />
    //               +44 7403 228448
    //             </p>
    //           </li>
    //           <li style={{ display: "flex" }}>
    //             {/* <img
    //                 style={{
    //                   width: "20px",
    //                   height: "20px",
    //                   marginRight: "5px",
    //                 }}
    //                 src={email}
    //               ></img> */}
    //             <img src={mail} className="img-fluid mx-2" />
    //             <p className="base-text text-white "> ali@naseem.education</p>
    //           </li>
    //         </ul>
    //       </div>
    //       <div className="col-12 col-md-3 mt-3 mt-md-0 ">
    //         <h2 className="fw-semibold mb-2">NEWS LETTER</h2>
    //         <p className="lh-sm">
    //           To get updates about our latest products and growing market
    //           trends, send us a mail and get yourself updated.
    //         </p>
    //       </div>
    //     </div>

    //     <p className="mt-5 text-center"> © 2021 Copyright: Infini8AI</p>
    //   </div>
    // </footer>
  );
}
