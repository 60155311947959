import { Button, Grid, Typography } from "@mui/material";
import { Stack, styled } from "@mui/system";
import React, { useEffect, useState } from "react";
import {
  deleteUser,
  getUsersByCompany,
  updateUser,
} from "../../apiservices/user verification";
import Sheild from "../../assets/images/user verification/sheild.png";
import DashboardLayout from "../../layouts/Dashboard";
import DashboardNavbar from "../../layouts/Navbars/DashboardNavbar";
import { formatDateDDMMYYYY } from "../../user portal/reuseable components";
function UserVerification() {
  const [state, setState] = useState({
    loading: false,
    usersArr: [],
  });
  const TableHeader = styled(Stack)({
    height: "60px",
    alignItems: "center",
    padding: "20px",
    borderRadius: "8px",
    margin: "25px",
  });
  const Decline = styled(Button)({
    height: "30px",
    width: "95px",
    borderRadius: "4px",
    padding: "8px 30px 8px 30px",
    border: "1px solid #C02727",
    color: "#C02727",
    textTransform: "capitalize",
    "&:hover": {
      boxShadow: "0px 2px 8px 0px #000000",
      background: "#C02727",
      color: "#FFFFFF",
    },
    "&:active": {
      background: "#931D1D",
    },
  });
  const Accept = styled(Button)({
    height: "30px",
    width: "95px",
    borderRadius: "4px",
    padding: "8px 30px 8px 30px",
    border: "1px solid #1B8C87",
    color: "#1B8C87",
    textTransform: "capitalize",
    "&:hover": {
      boxShadow: "0px 2px 8px 0px #000000",
      background: "#1B8C87",
      color: "#FFFFFF",
    },
    "&:active": {
      background: "#146E6A",
    },
  });
  const Typo = styled(Typography)({
    fontSize: "14px",
  });
  useEffect(() => {
    getUsers();
  }, []);
  const getUsers = async () => {
    let data = {
      company_code: localStorage.getItem("companyCode"),
    };
    let res = await getUsersByCompany(data);
    setState((prev) => ({ ...prev, usersArr: res.usersArr }));
  };
  const acceptUser = async (item) => {
    let data = {
      _id: item._id,
      _rev: item._rev,
      approved: true,
    };
    await updateUser(data);
    getUsers();
  };
  const declineUser = async (item) => {
    let data = {
      _id: item._id,
    };
    await deleteUser(data);
    getUsers();
  };
  return (
    <DashboardLayout>
      <DashboardNavbar>
        <Grid container spacing={2}>
          <Grid item xs={1} sx={{ mx: 1 }}>
            <img src={Sheild} alt="img" height="50px" widt="50px" />
          </Grid>
          <Grid
            item
            xs={9}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}>
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              User Verification
            </Typography>
          </Grid>
        </Grid>
      </DashboardNavbar>
      {state.usersArr &&
        state.usersArr.map(
          (item) =>
            item.approved === false && (
              <TableHeader
                direction="row"
                justifyContent="space-between"
                sx={{
                  background: "#FFFFFF",
                  color: "#67748E",
                  borderLeft: 2,
                  borderRight: 2,

                  borderColor: "#04B2AE",
                }}>
                <Typo sx={{ fontWeight: "bold", minWidth: "50px" }}>
                  {item.name}
                </Typo>

                <Typo sx={{ width: "200px" }}>Email:{item.email}</Typo>
                <Typo>Joining:{item.date}</Typo>
                <Stack direction="row">
                  <Decline
                    size="small"
                    onClick={() => declineUser(item)}
                    sx={{ mx: 1 }}>
                    Decline
                  </Decline>
                  <Accept size="small" onClick={() => acceptUser(item)}>
                    Accept
                  </Accept>
                </Stack>
              </TableHeader>
            )
        )}
    </DashboardLayout>
  );
}

export default UserVerification;
