import { useEffect } from "react";
import { useLocation, NavLink } from "react-router-dom";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";
import { useNavigate } from "react-router-dom";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import MDButton from "../../components/MDButton";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import SidenavCollapse from "../Sidenav/SidenavCollapse";
import logo from "../../assets/images/logo.svg";
import logout from "../../assets/images/sidebar/direction.png";
import "./sidenav.css";
import SidenavRoot from "../Sidenav/SidenavRoot";
import {
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
} from "../../context";
import { Stack, styled } from "@mui/system";
import { Typography } from "@mui/material";
import { formatDateDDMMYYYY } from "../../user portal/reuseable components";

function Sidenav({ color, brand, brandName, routes, ...rest }) {
  let date = new Date();
  let todayDate = formatDateDDMMYYYY(date);
  let navigate = useNavigate();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav } = controller;
  const location = useLocation();
  const collapseName = location.pathname.replace("/", "");

  let textColor = "#f0f";

  if (transparentSidenav || whiteSidenav) {
    textColor = "#f0f";
  } else if (whiteSidenav) {
    textColor = "#f0f";
  }
  const LogoutCard = styled(Stack)({
    height: "150px",
    minWidth: "208px",
    borderRadius: "6px",
    background: "#F1FAF9",
    color: "#67748E",
    justifyContent: "center",
    alignItems: "center",
    margin: "10px",
  });
  const StyledTypography = styled(Stack)({
    FontSize: "13px",
  });
  const MDStyled = styled(MDBox)({
    background: "#FFFFFF",
    height: "40px",
    width: "134px",
    borderRadius: "6px",
    ".MuiTypography-root .MuiTypography-button .css-ijepq7-MuiTypography-root":
      {
        background: "#67748E",
      },
    "&:hover ": {
      background: "#67748E",
      color: "#FFFFFF !important",
    },
  });
  const MDTypo = styled(MDTypography)({
    background: "#67748E",
    height: "35px",
    padding: "10px ",
    borderRadius: "4px",
    boxShadow: "0px 2px rgba(0, 0, 0, 0.16)",
    ":hover": {
      background: "#8493B1",
    },
    ":active": {
      background: "#67748E",
    },
  });
  const MDBtn = styled(MDButton)({
    color: "#67748E !important",
    textTransform: "capitalize",
    "&:hover": {
      color: "#FFFFFF !important",
    },
  });
  const closeSidenav = () => setMiniSidenav(dispatch, true);

  useEffect(() => {
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 992);
      setTransparentSidenav(
        dispatch,
        window.innerWidth < 992 ? false : transparentSidenav
      );
      setWhiteSidenav(dispatch, window.innerWidth < 992 ? false : whiteSidenav);
    }

    /** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location]);

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = routes.map(
    ({ type, name, icon, title, noCollapse, key, href, route }) => {
      let returnValue;

      if (type === "collapse") {
        returnValue = href ? (
          <Link
            href={href}
            key={key}
            target="_blank"
            rel="noreferrer"
            sx={{ textDecoration: "none" }}>
            <SidenavCollapse
              name={name}
              icon={icon}
              active={key === collapseName}
              noCollapse={noCollapse}
            />
          </Link>
        ) : (
          <NavLink key={key} to={route}>
            <SidenavCollapse
              name={name}
              icon={icon}
              active={key === collapseName}
            />
          </NavLink>
        );
      } else if (type === "title") {
        returnValue = (
          <MDTypography
            key={key}
            // color={textColor}
            sx={{ color: "#67748E" }}
            display="block"
            variant="caption"
            fontWeight="bold"
            textTransform="uppercase"
            pl={3}
            mt={2}
            mb={1}
            ml={1}>
            {/* {title} */}
            Title
          </MDTypography>
        );
      } else if (type === "divider") {
        returnValue = (
          <Divider
            key={key}
            // light={
            //   (!whiteSidenav && !transparentSidenav) ||
            //   (!transparentSidenav && whiteSidenav)
            // }
          />
        );
      }

      return returnValue;
    }
  );

  return (
    <SidenavRoot
      {...rest}
      variant="permanent"
      ownerState={{ transparentSidenav, whiteSidenav, miniSidenav }}>
      <MDBox pt={3} pb={1} px={4} textAlign="center">
        <MDBox
          display={{ xs: "block", xl: "none" }}
          position="absolute"
          top={0}
          right={0}
          // p={1.625}
          onClick={closeSidenav}
          sx={{ cursor: "pointer" }}>
          <MDTypography variant="h6" color="secondary">
            <Icon sx={{ fontWeight: "bold" }}>close</Icon>
          </MDTypography>
        </MDBox>
        <MDBox
          component={NavLink}
          to="/dashboard"
          display="flex"
          justifyContent="center">
          <MDTypography
            component="h6"
            variant="button"
            fontWeight="medium"
            // color={textColor}
            sx={{ color: "#67748E" }}>
            <img src={logo} alt="" />
          </MDTypography>
        </MDBox>
      </MDBox>
      <Divider
        sx={{
          color: "#67748E !important",
          opacity: "1.2",
          border: "none !important",
        }}
        // light={
        //   (!whiteSidenav && !transparentSidenav) ||
        //   (!transparentSidenav && whiteSidenav)
        // }
      />
      <List>{renderRoutes}</List>
      <LogoutCard>
        <Stack direction="row" sx={{ p: 1 }}>
          <Stack>
            <StyledTypography sx={{ fontSize: "13px" }}>
              {localStorage.getItem("loginType") === "admin" ? "Admin" : "User"}{" "}
              Account:
            </StyledTypography>
            <StyledTypography
              sx={{ fontSize: "13px", width: "120px", lineBreak: "anywhere" }}>
              {localStorage.getItem("userEmail")}
            </StyledTypography>
          </Stack>
          <Typography sx={{ fontSize: "13px" }}>
            <CalendarMonthIcon />
            {todayDate}
          </Typography>
        </Stack>
        <MDStyled
          // p={2}
          mt={1}
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
          <MDBtn
            fullWidth
            onClick={() => {
              localStorage.clear();
              navigate("./login");
            }}>
            <MDTypo
              component="h6"
              variant="button"
              fontWeight="medium"
              color={textColor}
              sx={{
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
              }}>
              <img src={logout} alt="" />
            </MDTypo>
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Logout
          </MDBtn>
        </MDStyled>
      </LogoutCard>
    </SidenavRoot>
  );
}

export default Sidenav;
