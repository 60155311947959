import React from "react";
import About329 from "../../../assets/images/optimus/About329.svg";
import Goals330 from "../../../assets/images/optimus/Goals330.svg";
import Union from "../../../assets/images/optimus/Union.svg";
import painPoints331 from "../../../assets/images/optimus/painPoints331.svg";

import contactus from "../../../assets/images/optimus/contactus.png";

import footerbottem from "../../../assets/images/footer/footerbottem.png";
import opthealthfoot from "../../../assets/images/optimus/optimus health-foot.png";
import opthealth from "../../../assets/images/footer/opthealth.png";
import empathymap from "../../../assets/images/optimus/empathy-map.png";
import mobileappsketches from "../../../assets/images/optimus/mobile-app-sketches.png";
import { Link ,useNavigate , } from "react-router-dom";
import { Link as Linkscroll,scroller } from "react-scroll";

const OptimusMobile2 = () => {
  let url = "https://infini8ai-images.s3.amazonaws.com/optimus";
  const navigate = useNavigate();

  const handleScroll = (section) => {


    navigate('/');
    setTimeout(() => {
      scroller.scrollTo(section, {
        duration: 500,
        smooth: true,
      });
    }, 100);
  };
  return (
    <div>
      <div id="OptimusAbouts">
        <div className="container">
          <div className="firstAboutContent">
            <div className="row" style={{ alignItems: "center" ,marginLeft:"0px"}}>
              <div className="col-md-6">
                <div>
                  <div className="aboutImg">
                    <img src={About329} className="About329" alt="About329" />
                  </div>
                  <div>
                    <p className="aboutText_mobile">
                    Mr. John Mckenny is a twenty seven-year-old man. He works
                      as an accountant in a major firm and has earned a Master's
                      degree in Business Administration.
                    </p>
                    <p className="aboutText_mobile">
                    He loves his job but also acknowledges the stress that
                      comes with it and for that, he tries to maintain his
                      mental and physical health through yoga and good
                      nutrition.
                    </p>
                    <br />
                    <br />
                    <span  style={{fontWeight:600}} className="aboutText_mobile text-center pl-3">
                    “Without hard work and discipline it is difficult to be a
                    top professional.”                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="row" style={{ marginLeft:"0px"}}>
              <div className="col-md-12">
                <div id="questionAnswering">
                  <div className="aboutImg">
                    <img src={Goals330} className="Goals330" alt="Goals330" />
                  </div>
                  <div className="">
                    <ul className="d-flex ml-3 mt-4">
                      <li className="list-unstyled mr-1">
                        <img src={Union} className="Union" alt="union" />
                      </li>
                      <li className="list-unstyled ml-1">
                        <span className="iterview_mobile mt-4">
                        More success in professional career and becoming a
                        lead Accountant.                        </span>
                      </li>
                    </ul>

                    <ul className="d-flex ml-3 ">
                      <li className="list-unstyled mr-1">
                        <img src={Union} className="Union" alt="union" />
                      </li>
                      <li className="list-unstyled ml-1">
                        <span className="iterview_mobile">
                        Maintaining mental and physical health through yoga
                        and good nutrition.                        </span>
                      </li>
                    </ul>
                    <ul className="d-flex ml-3 ">
                      <li className="list-unstyled mr-1">
                        <img src={Union} className="Union" alt="union" />
                      </li>
                      <li className="list-unstyled ml-1">
                        <span className="iterview_mobile">
                          Overcoming stress and pressure that comes along with
                          job responsilities.
                        </span>
                      </li>
                    </ul>
                    {/* <ul className="d-flex ml-3 ">
                      <li className="list-unstyled mr-1">
                        <img src={Union} className="Union" alt="union" />
                      </li>
                      <li className="list-unstyled ml-1">
                        <span className="iterview_mobile">
                          Spend time outside of the home as much as possible for
                          mindfulness and peace.
                        </span>
                      </li>
                    </ul> */}
                  </div>

                  <div className="aboutImg">
                    <img
                      src={painPoints331}
                      className="painPoints331"
                      alt="painPoints331"
                    />
                  </div>
                  <div className="">
                    <div className="">
                      <ul className="d-flex ml-3 ">
                        <li className="list-unstyled mr-1">
                          <img src={Union} className="Union" alt="union" />
                        </li>
                        <li className="list-unstyled ml-1">
                          <span className="iterview_mobile">
                          Difficulty in doing remote work due to Covid
                          restrictions.
                          </span>
                        </li>
                      </ul>
                      <ul className="d-flex ml-3 ">
                        <li className="list-unstyled mr-1">
                          <img src={Union} className="Union" alt="union" />
                        </li>
                        <li className="list-unstyled ml-1">
                          <span className="iterview_mobile">
                          Stress leads to loss of focus and bad sleeping
                          habits.
                          </span>
                        </li>
                      </ul>
                      <ul className="d-flex ml-3 ">
                        <li className="list-unstyled mr-1">
                          <img src={Union} className="Union" alt="union" />
                        </li>
                        <li className="list-unstyled ml-1">
                          <span className="iterview_mobile">
                          Difficulty finding the right information on
                            nutrition and health.
                          </span>
                        </li>
                      </ul>
                      <ul className="d-flex ml-3 ">
                        <li className="list-unstyled mr-1">
                          <img src={Union} className="Union" alt="union" />
                        </li>
                        <li className="list-unstyled ml-1">
                          <span className="iterview_mobile">
                          Unable to meet nutritionist or doctors due to
                          lockdown.{" "}
                          </span>
                        </li>
                      </ul>
                      <ul className="d-flex ml-3 ">
                        <li className="list-unstyled mr-1">
                          <img src={Union} className="Union" alt="union" />
                        </li>
                        <li className="list-unstyled ml-1">
                          <span className="iterview_mobile">
                          No alternate medium is available that combines all
                          aspects related to health.
                          </span>
                        </li>
                      </ul>
                      <ul className="d-flex ml-3 ">
                        <li className="list-unstyled mr-1">
                          <img src={Union} className="Union" alt="union" />
                        </li>
                        <li className="list-unstyled ml-1">
                          <span className="iterview_mobile">
                          Unable to track daily routine changes
and how they’re affecting health.
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div className="col-md-12">
                <div className="d-flex justify-content-center mt-5">
                  <img
                    src={`${url}/Group333.svg`}
                    // className="GroupImg326"
                    className="img-fluid"
                    alt="Group333"
                  />
                </div>
              </div>

            <div className="row" style={{ alignItems: "center" ,marginLeft:"0px"}}>
              <div className="col-md-6">
                <div>
                  <div className="aboutImg">
                  <img src={About329}  className="About329" alt="About329" />
                  </div>
                  <div>
                    <p className="aboutText_mobile">
                      Mrs. Kim Denmon is a twenty five-year-old Married girl.
                      She works as a freelance Front- end Web developer online
                      and spends most of her time at home.
                    </p>
                    <p className="aboutText_mobile">
                      Her job requires her to sit in a single place for a decent
                      amount of time, so to maintain her health, she goes to a
                      nearby gym for exercise to
                    </p>
                    <br />
                    <br />
                    <span style={{fontWeight:600,}} className="aboutText_mobile text-center pl-3">
                      “Programmer:A machine that turns coffee into code.”
                    </span>
                  </div>
                </div>
              </div>
            </div>
            
            <div className="row" style={{ marginLeft:"0px"}}>
              <div className="col-md-12">
                <div id="questionAnswering">
                  <div className="aboutImg">
                    <img src={Goals330} className="Goals330" alt="Goals330" />
                  </div>
                  <div className="">
                    <ul className="d-flex ml-3 mt-4">
                      <li className="list-unstyled mr-1">
                        <img src={Union} className="Union" alt="union" />
                      </li>
                      <li className="list-unstyled ml-1">
                        <span className="iterview_mobile mt-4">
                          Be the best Front-end Web developer.
                        </span>
                      </li>
                    </ul>

                    <ul className="d-flex ml-3 ">
                      <li className="list-unstyled mr-1">
                        <img src={Union} className="Union" alt="union" />
                      </li>
                      <li className="list-unstyled ml-1">
                        <span className="iterview_mobile">
                          Mainatain a healthy lifestyle and staying physically
                          fit.
                        </span>
                      </li>
                    </ul>
                    <ul className="d-flex ml-3 ">
                      <li className="list-unstyled mr-1">
                        <img src={Union} className="Union" alt="union" />
                      </li>
                      <li className="list-unstyled ml-1">
                        <span className="iterview_mobile">
                          Overcoming stress and pressure that comes along with
                          job responsilities.
                        </span>
                      </li>
                    </ul>
                    <ul className="d-flex ml-3 ">
                      <li className="list-unstyled mr-1">
                        <img src={Union} className="Union" alt="union" />
                      </li>
                      <li className="list-unstyled ml-1">
                        <span className="iterview_mobile">
                          Spend time outside of the home as much as possible for
                          mindfulness and peace.
                        </span>
                      </li>
                    </ul>
                  </div>

                  <div className="aboutImg">
                    <img
                      src={painPoints331}
                      className="painPoints331"
                      alt="painPoints331"
                    />
                  </div>
                  <div className="">
                    <div className="">
                      <ul className="d-flex ml-3 ">
                        <li className="list-unstyled mr-1">
                          <img src={Union} className="Union" alt="union" />
                        </li>
                        <li className="list-unstyled ml-1">
                          <span className="iterview_mobile">
                            Not being able to exercise at the gym because of
                            Covid restrictions.
                          </span>
                        </li>
                      </ul>
                      <ul className="d-flex ml-3 ">
                        <li className="list-unstyled mr-1">
                          <img src={Union} className="Union" alt="union" />
                        </li>
                        <li className="list-unstyled ml-1">
                          <span className="iterview_mobile">
                            Not enough knowledge about exercising without any
                            equipment.
                          </span>
                        </li>
                      </ul>
                      <ul className="d-flex ml-3 ">
                        <li className="list-unstyled mr-1">
                          <img src={Union} className="Union" alt="union" />
                        </li>
                        <li className="list-unstyled ml-1">
                          <span className="iterview_mobile">
                            Changes in daily routine because of lockdown and not
                            being able to track them.
                          </span>
                        </li>
                      </ul>
                      <ul className="d-flex ml-3 ">
                        <li className="list-unstyled mr-1">
                          <img src={Union} className="Union" alt="union" />
                        </li>
                        <li className="list-unstyled ml-1">
                          <span className="iterview_mobile">
                            Not being able to meet a doctor or nutritionist in
                            person to discuss health.
                          </span>
                        </li>
                      </ul>
                      <ul className="d-flex ml-3 ">
                        <li className="list-unstyled mr-1">
                          <img src={Union} className="Union" alt="union" />
                        </li>
                        <li className="list-unstyled ml-1">
                          <span className="iterview_mobile">
                            Lack of information on how to stay healthy in
                            pandemic like situations.
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            

              
            <div className="row">
              <div className="empathy_rectangle ">
                <div className=" ">
                  <img
                    src={empathymap}
                    className="img-fluid text-center"
                  />
                </div>
                <div>
                  <img
                    src={`${url}/empathydo.png`}
                    className="img-fluid "
                    style={{ marginTop: "-80px" }}
                  />
                  <img
                    src={`${url}/empathysee.png`}
                    className="img-fluid "
                    style={{ marginTop: "-150px" }}
                  />
                  <img
                    src={`${url}/empathyhear.png`}
                    className="img-fluid "
                    style={{ marginTop: "-200px" }}
                  />
                  <img
                    src={`${url}/empathypain.png`}
                    className="img-fluid "
                    style={{ marginTop: "-200px" }}
                  />
                  <img
                    src={`${url}/empathygain.png`}
                    className="img-fluid "
                    style={{ marginTop: "-200px" }}
                  />
                  <img
                    src={`${url}/empathygroup.png`}
                    className="img-fluid "
                    style={{ marginTop: "-100px" }}
                  />
                </div>
              </div>
            </div>
            <div>
              <div id="mobileSketches">
                <div className="container">
                  <div className="row" style={{ marginTop: "-50px" }}>
                    <div className="col-md-12">
                      <div className="mobileSketchesHead">
                        <img
                          src={mobileappsketches}
                          className="img-fluid  Group381"
                          alt="Group381"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row" style={{ marginTop: "-50px" }}>
                    <div className="col-md-12">
                      <div className="mobileSketchesImg">
                        <img
                          src={`${url}/mbleskechmain.png`}
                          className="img-fluid Group4839"
                          alt="Group4839"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row" style={{ marginTop: "-100px" }}>
                    <div className="col-md-12">
                      <div className="mobileSketchesImg">
                        <img
                          src={`${url}/mblewireframe.png`}
                          className="img-fluid Group4839"
                          alt="Group4839"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row" style={{ marginTop: "-150px" }}>
                    <div className="col-md-12">
                      <div className="mobileSketchesImg">
                        <img
                          src={`${url}/mbleframemain.png`}
                          className="img-fluid Group4839"
                          alt="Group4839"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="empathy_rectangle">
                    <div className="">
                      <img
                        src={`${url}/mbleapp.png`}
                        className="img-fluid"
                        alt="Group4839"
                      />
                    </div>
                  </div>
                </div>
                <div className="row" style={{ marginTop: "-70px" }}>
                  <div className="col-md-12">
                    <div className="mobileSketchesImg">
                      <img
                        src={contactus}
                        className="img-fluid Group4839"
                        alt="Group4839"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
              <footer
      style={{
        backgroundColor: "#1A1A1A",
        backgroundImage: "none",
      }}
      className="page-footer font-small   pb-3 "
    >
      <div className="container">
        <div className="row footer-row" >
          <div className="col-12 col-lg-3 col-md-4 footer-sitemap">
            <p
              className="fs-6 mt-4"
              style={{ fontFamily: "Dosis",color:"#dde73c", }}
            >
              SITE MAP
            </p>
           
            <div>
                {/* <p style={{ margin: "0 0 20px 0",color: "#116065", fontWeight:600,font:"poppins", fontSize:"24px" }}>Case Studies</p> */}
                <ul  className="text-white mt-4 lh-sm justify-content-md-start justify-content-center"
              style={{ fontFamily: "Dosis" , listStyleType: "none", display:"flex", gap:"4rem"}}>
                <div style={{display:"flex", flexDirection:"column", gap:"3rem"}}>
                   <Link to="/" style={{  }}>
                   <li style={{color:"white",fontSize:"16px"}}>Home</li>
                  </Link>
                  <div
          className="cursor-pointer"
          style={{ cursor: "pointer",  }}
          onClick={() => handleScroll("ourProducts")}
        >
            <li style={{ fontFamily: "Dosis",fontSize:"16px"}}>Products</li>
        </div>
                  {/* <li>About Us</li> */}
                  <Link to="/our-process" >
                  <li style={{color:"white",fontSize:"16px"}}>Our Process</li>
                  </Link>
                  </div>
                  <div  style={{display:"flex", flexDirection:"column", gap:"3rem"}}>
                  <Link to="/team" >
                  <li style={{ color:"white",fontSize:"16px" }}>Team</li>
                  </Link>
                  <Link to="/portfolio" >
                  <li style={{ color:"white",fontSize:"16px"}}>Portfolio</li>
                  </Link>
                  <Link to="/careers" >
                  <li style={{ color:"white",fontSize:"16px"}}>Careers</li>
                  </Link>
                  </div>
                </ul>
                </div>
          </div>
          <div className="col-12 col-lg-3 col-md-4 footer-contactus">
            <p
              className="fs-6 mt-4 "
              style={{ fontFamily: "Dosis",color:"#dde73c" }}
            >
              CONTACT US
            </p>
            <p  
              className="text-white mt-4 lh-sm"
              style={{ fontFamily: "Dosis" ,fontSize:"16px"}}
            >
              IDEA London Ground Floor 69 Wilson Street, London EC2A 2BB
              <p className="mt-4 mb-4 text-white lh-sm"   style={{ fontFamily: "Dosis",fontSize:"16px" }}>For hiring inquiries please contact: <span style={{fontFamily: "Dosis",fontSize:"16px"}}>hr@infini8ai.com</span></p> 
              
            </p>
            <p
              className="text-white mt-4 lh-sm"
              style={{ fontFamily: "Dosis" }}
            >
          
              <p className="mt-4 mb-4 text-white lh-sm"   style={{ fontFamily: "Dosis",fontSize:"16px" }}>For all other queries please contact: <span style={{fontFamily: "Dosis",fontSize:"16px"}}>info@infini8ai.com </span> </p> 
              
            </p>
            <div className=" mt-2">
          <p style={{ color: "white",fontSize:"16px" }}>2024 Copyright: Infini8AI</p>
        </div>

          </div>
          <div className="col-12 col-lg-3 col-md-4 footer-casestudies" >
            <p
              className="fs-6  mt-4  "
              style={{ fontFamily: "Dosis",color:"#dde73c" }}
            >
              CASE   STUDIES
            </p>
            {/* <p
              className="text-white mt-4 lh-sm"
              style={{ fontFamily: "Bitter" }}
            >
              To receive updates about our new product offerings and latest
              developments in the world of AI, join our bi-weekly newsletter:{" "}
              <a href="https://veracious.ai/news-letter" target="_blank">
                Veracious AI
              </a>
            </p> */}
              <div>
                {/* <p style={{ margin: "0 0 20px 0",color: "#116065", fontWeight:600,font:"poppins", fontSize:"24px" }}>Case Studies</p> */}
                <ul  className="text-white mt-4 lh-sm"
              style={{ fontFamily: "Dosis" , listStyleType: "none", display:"flex",  gap:"4rem"}}>
                    <div  style={{display:"flex", flexDirection:"column", gap:"3rem"}}>

                    <Link to="/optimus-case-study">
                    <li style={{color:"white",fontSize:"16px"}}>Healthcare</li>
                    </Link>
                    <Link to="/naseem-case-study">
                    <li style={{color:"white",fontSize:"16px"}}>Education</li>
                    </Link>
                    <Link to="/qurbani-case-study">
                    <li style={{color:"white",fontSize:"16px"}}>Livestock</li>
                    </Link>
                    </div>
                    <div  style={{display:"flex", flexDirection:"column", gap:"3rem"}}>

                    <Link to="/stock-chain-case-study">
                    <li style={{color:"white",fontSize:"16px"}}>Logistics</li>
                    </Link>
                    <Link to="/businesspal-case-study">
                    <li style={{color:"white",fontSize:"16px"}}>Business</li>
                    </Link>
                    </div>
                </ul>
                </div>

          </div>
        </div>
        
      </div>
    </footer>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OptimusMobile2;
