import { styled, Typography } from "@mui/material";

export const Title = styled(Typography)({
  fontFamily: "Dosis",
  fontStyle: "normal",
  fontWeight: "600",
  fontSize: "20px",
  lineHeight: "25px",
  textAlign: "center",
  color: "#67748E",
});
export const SubTitle = styled(Typography)({
  fontFamily: "Dosis",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "18px",
  lineHeight: "23px",
  color: "#67748E",
  textAlign: "center",
});
export const TextStyle = styled(Typography)({
  fontFamily: "Dosis",
  fontStyle: "normal",
});
export const CardTitle = styled(Typography)({
  fontFamily: "Dosis",
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: "20px",
  lineHeight: "25px",
  color: "#67748E",
});
export const Cards = styled("div")({
  height: "280px",
  width: "240px",
  borderRadius: "8px",
  background: "#F5F5F5",
  display: "flex",
  fontWeight: "500",
  color: "#67748E",
  justifyContent: "center",
  transition: "0.5s",
  "&:hover": {
    background: "#FFFFFF",
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.12)",
    fontWeight: "900",
  },
  "&:active": {
    background: "#F5F5F5",
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.12)",
  },
});

export const RootStyle = styled("div")({
  margin: "0px 30px",
  marginTop: "100px",
  display: "grid",
  columnGap: 22,
  rowGap: 22,
  gridTemplateColumns: "repeat(auto-fit, minmax(195px, 1fr))",
});
