import axios from "axios";

export const getAllMeetings = async () => {
  let res = await axios.post(`https://infini8ai.herokuapp.com/meetings`, {
    request: {
      method: "getAllMeetings",
    },
  });
  let json = res.data.response.data;
  return json;
};

export const saveMeeting = async (data) => {
  let res = await axios.post(`https://infini8ai.herokuapp.com/meetings`, {
    request: {
      method: "saveMeeting",
      data: data,
    },
  });
  let json = res.data.response.data;
  return json;
};
export const updateMeeting = async (data) => {
  let res = await axios.post(`https://infini8ai.herokuapp.com/meetings`, {
    request: {
      method: "updateMeeting",
      data: data,
    },
  });
  let json = res.data.response.data;
  return json;
};

export const getMeetingsByCompany = async (data) => {
  let res = await axios.post(`https://infini8ai.herokuapp.com/meetings`, {
    request: {
      method: "getMeetingsByCompany",
      data: data,
    },
  });
  let json = res.data.response.data;
  return json;
};
