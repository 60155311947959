import React from "react";
import { Link ,useNavigate , } from "react-router-dom";
import { Link as Linkscroll,scroller } from "react-scroll";
const NaseemMobile1 = () => {
  let url = "https://infini8ai-images.s3.amazonaws.com/naseem-casestudy";
  const navigate = useNavigate();

  const handleScroll = (section) => {


    navigate('/');
    setTimeout(() => {
      scroller.scrollTo(section, {
        duration: 500,
        smooth: true,
      });
    }, 100);
  };
  return (
    <div>
      <div>
        <div id="empathy">
          <div style={{ background: "#60AB62" }} className="pt-3">
            <div className="text-center ">
              <img src={`${url}/empathy.svg`} className="  img-fluid   " />
            </div>
          </div>
        </div>
        <div id="mobilesketch">
          <div style={{ background: "#12BBF0" }} className="pt-3">
            <div className="text-center ">
              <img src={`${url}/mbleheading.svg`} className="  img-fluid   " />
            </div>{" "}
            <div className="text-center ">
              <img src={`${url}/mobile1.svg`} className="  img-fluid   " />
            </div>{" "}
            <div className="text-center ">
              <img src={`${url}/mobile2.svg`} className="  img-fluid   " />
            </div>{" "}
            <div className="text-center ">
              <img src={`${url}/mobile3.svg`} className="  img-fluid   " />
            </div>{" "}
            <div className="text-center ">
              <img src={`${url}/mobile4.svg`} className="  img-fluid   " />
            </div>{" "}
            <div className="text-center ">
              <img src={`${url}/mobile5.svg`} className="  img-fluid   " />
            </div>{" "}
          </div>
        </div>
        <div id="wireframe">
          <div style={{ background: "#F1F1F1" }} className="pt-3">
            <div className="text-center ">
              <img
                src={`${url}/wireframeheading.svg`}
                className="  img-fluid   "
              />
            </div>
            <div className="text-center mt-5">
              <img src={`${url}/wireframe1.svg`} className="  img-fluid   " />
            </div>{" "}
            <div className="text-center mt-3">
              <img src={`${url}/wireframe2.svg`} className="  img-fluid   " />
            </div>{" "}
            <div className="text-center mt-3">
              <img src={`${url}/wireframe3.svg`} className="  img-fluid   " />
            </div>{" "}
            <div className="text-center mt-3">
              <img src={`${url}/wireframe4.svg`} className="  img-fluid   " />
            </div>{" "}
            <div className="text-center mt-3">
              <img src={`${url}/wireframe5.svg`} className="  img-fluid   " />
            </div>{" "}
            <div className="text-center ">
              <img src={`${url}/wireframe6.svg`} className="  img-fluid   " />
            </div>{" "}
            <div className="text-center ">
              <img src={`${url}/wireframe7.svg`} className="  img-fluid   " />
            </div>{" "}
            <div className="text-center ">
              <img src={`${url}/wireframe8.svg`} className="  img-fluid   " />
            </div>{" "}
            <div className="text-center ">
              <img src={`${url}/wireframe9.svg`} className="  img-fluid   " />
            </div>
          </div>
        </div>
        <div id="mobiledesign">
          <div style={{ background: "#E1F2FF" }} className="pt-3">
            <div className="text-center ">
              <img
                src={`${url}/designheading.svg`}
                className="  img-fluid   "
              />
            </div>
            <div className="text-center ">
              <img
                src={`${url}/alldesign.svg`}
                className=" w-75 img-fluid   "
              />
            </div>
          </div>
        </div>
        <div id="webdesign ">
          <div style={{ background: "#E1F2FF" }} className="pt-3 pb-5">
            <div className="text-center ">
              <img src={`${url}/webdesign.svg`} className="  img-fluid   " />
            </div>
            <div className="text-center ">
              <img src={`${url}/mainweb.png`} className="  img-fluid   " />
            </div>
          </div>
        </div>
        <div id="footerpart">
        <footer
      style={{
        backgroundColor: "#5912F0",
        backgroundImage: "none",
      }}
      className="page-footer font-small   pb-3 "
    >
      <div className="container">
        <div className="row footer-row" >
          <div className="col-12 col-lg-3 col-md-4 footer-sitemap">
            <p
              className="fs-6 mt-4"
              style={{ fontFamily: "Dosis",color:"#dde73c", }}
            >
              SITE MAP
            </p>
           
            <div>
                {/* <p style={{ margin: "0 0 20px 0",color: "#116065", fontWeight:600,font:"poppins", fontSize:"24px" }}>Case Studies</p> */}
                <ul  className="text-white mt-4 lh-sm justify-content-md-start justify-content-center"
              style={{ fontFamily: "Dosis" , listStyleType: "none", display:"flex", gap:"4rem"}}>
                <div style={{display:"flex", flexDirection:"column", gap:"3rem"}}>
                   <Link to="/" style={{  }}>
                   <li style={{color:"white",fontSize:"16px"}}>Home</li>
                  </Link>
                  <div
          className="cursor-pointer"
          style={{ cursor: "pointer",  }}
          onClick={() => handleScroll("ourProducts")}
        >
            <li style={{ fontFamily: "Dosis",fontSize:"16px"}}>Products</li>
        </div>
                  {/* <li>About Us</li> */}
                  <Link to="/our-process" >
                  <li style={{color:"white",fontSize:"16px"}}>Our Process</li>
                  </Link>
                  </div>
                  <div  style={{display:"flex", flexDirection:"column", gap:"3rem"}}>
                  <Link to="/team" >
                  <li style={{ color:"white",fontSize:"16px" }}>Team</li>
                  </Link>
                  <Link to="/portfolio" >
                  <li style={{ color:"white",fontSize:"16px"}}>Portfolio</li>
                  </Link>
                  <Link to="/careers" >
                  <li style={{ color:"white",fontSize:"16px"}}>Careers</li>
                  </Link>
                  </div>
                </ul>
                </div>
          </div>
          <div className="col-12 col-lg-3 col-md-4 footer-contactus">
            <p
              className="fs-6 mt-4 "
              style={{ fontFamily: "Dosis",color:"#dde73c" }}
            >
              CONTACT US
            </p>
            <p  
              className="text-white mt-4 lh-sm"
              style={{ fontFamily: "Dosis" ,fontSize:"16px"}}
            >
              IDEA London Ground Floor 69 Wilson Street, London EC2A 2BB
              <p className="mt-4 mb-4 text-white lh-sm"   style={{ fontFamily: "Dosis",fontSize:"16px" }}>For hiring inquiries please contact: <span style={{fontFamily: "Dosis",fontSize:"16px"}}>hr@infini8ai.com</span></p> 
              
            </p>
            <p
              className="text-white mt-4 lh-sm"
              style={{ fontFamily: "Dosis" }}
            >
          
              <p className="mt-4 mb-4 text-white lh-sm"   style={{ fontFamily: "Dosis",fontSize:"16px" }}>For all other queries please contact: <span style={{fontFamily: "Dosis",fontSize:"16px"}}>info@infini8ai.com </span> </p> 
              
            </p>
            <div className=" mt-2">
          <p style={{ color: "white",fontSize:"16px" }}>2024 Copyright: Infini8AI</p>
        </div>

          </div>
          <div className="col-12 col-lg-3 col-md-4 footer-casestudies" >
            <p
              className="fs-6  mt-4  "
              style={{ fontFamily: "Dosis",color:"#dde73c" }}
            >
              CASE   STUDIES
            </p>
            {/* <p
              className="text-white mt-4 lh-sm"
              style={{ fontFamily: "Bitter" }}
            >
              To receive updates about our new product offerings and latest
              developments in the world of AI, join our bi-weekly newsletter:{" "}
              <a href="https://veracious.ai/news-letter" target="_blank">
                Veracious AI
              </a>
            </p> */}
              <div>
                {/* <p style={{ margin: "0 0 20px 0",color: "#116065", fontWeight:600,font:"poppins", fontSize:"24px" }}>Case Studies</p> */}
                <ul  className="text-white mt-4 lh-sm"
              style={{ fontFamily: "Dosis" , listStyleType: "none", display:"flex",  gap:"4rem"}}>
                    <div  style={{display:"flex", flexDirection:"column", gap:"3rem"}}>

                    <Link to="/optimus-case-study">
                    <li style={{color:"white",fontSize:"16px"}}>Healthcare</li>
                    </Link>
                    <Link to="/naseem-case-study">
                    <li style={{color:"white",fontSize:"16px"}}>Education</li>
                    </Link>
                    <Link to="/qurbani-case-study">
                    <li style={{color:"white",fontSize:"16px"}}>Livestock</li>
                    </Link>
                    </div>
                    <div  style={{display:"flex", flexDirection:"column", gap:"3rem"}}>

                    <Link to="/stock-chain-case-study">
                    <li style={{color:"white",fontSize:"16px"}}>Logistics</li>
                    </Link>
                    <Link to="/businesspal-case-study">
                    <li style={{color:"white",fontSize:"16px"}}>Business</li>
                    </Link>
                    </div>
                </ul>
                </div>

          </div>
        </div>
        
      </div>
    </footer>
        </div>
      </div>
    </div>
  );
};

export default NaseemMobile1;
