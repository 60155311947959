import React from "react";
import NavbarMenu from "../common/NavbarMenu";
import ServiceWeOffer from "./Services";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import heroImg from "../../assets/images/heroImg.svg";
import hero_bottom from "../../assets/images/home page/Rectangle 151.png";
import Tech_Eye_Illus from "../../assets/images/Tech Eye Illus.svg";
import rocket from "../../assets/images/home page/rocket.png";
import calendar from "../../assets/images/home page/orange calendar.png";
import headphones from "../../assets/images/home page/pink headphones.png";
import support_female from "../../assets/images/home page/support female.png";
import Footer from "../common/Footer";
import { useNavigate } from "react-router-dom";
import OurProducts from "./OurProducts";
import PageLayout from "../../layouts/Page";
import Slider from "react-slick";
import microsoft from "../../assets/images/home page/microsoft.png";
import ukresearch from "../../assets/images/home page/uk-reasearch.png";
import openai from "../../assets/images/home page/open-ai.png";
import amazonweb from "../../assets/images/home page/amazon-web.png";
import uclimage from "../../assets/images/home page/uclimage.png";
import arbisoftimage from "../../assets/images/businesspal/arbisoftimg.png";
import councilimage from "../../assets/images/businesspal/councilimg.png";
import designrushimage from "../../assets/images/businesspal/designrush.png";
import systemimage from "../../assets/images/businesspal/systemsimg.png";
import educatenewimage from "../../assets/images/businesspal/educatenew.png";
import uclnewimage from "../../assets/images/businesspal/uclnew.png";
import frameimage from "../../assets/images/businesspal/Frame1000003876.png";
import maskimg from "../../assets/images/businesspal/maskimg.png";

const LandingPage = () => {
  const navigate = useNavigate();

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    // slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0,
    speed: 10000,
    autoplaySpeed: 0,
    cssEase: "linear",
    // nextArrow: <img src={right_blue_arrow} />,
    // prevArrow: <img src={left_blue_arow} />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  const viewDetails = () => {
    return navigate(`/our-process`);
  };
  return (
    <PageLayout>
      <NavbarMenu />
      <div
        className="hero-top"
        style={{
          backgroundColor: "#51A7A3", // or 'auto' to retain original size        
        }}
      >
        <div className="container container-text">
          <div
            className=" heros-text"
            style={{
              marginLeft: "20px",
              maxWidth: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <p
              className=" mt-3 hero_text1 lh-sm"
              style={{
                fontWeight: 500,
                fontSize: "24px",
                font: "Dosis",
                color: "#F9FF83",
              }}
            >
              Transform thoughts into progressive ventures
              <br />& services with
            </p>
            <h2
              className="fw-bold  mt-sm-3 mt-md-5 mt-lg-5"
              style={{ fontSize: "35px", color: "white", textAlign: "start" }}
            >
              Interaction Design, Technology,
              <br />
              Innovation & Support
            </h2>
            <p
              className="mt-sm-3 mt-md-5 mt-lg-5 lh-sm"
              style={{
                fontWeight: 500,
                fontSize: "24px",
                font: "Dosis",
                color: "#F9FF83",
              }}
            >
              Let us help you see the world with Tech Eyes
            </p>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <img
              src={maskimg}
              className="alien-img-home"
            />
          </div>
        </div>
        <div className="hero_img">
          <img src={heroImg}  />
        </div>
      </div>

      <div
        className="products"
        style={{
          backgroundColor: "white",
          paddingTop: "50px",
          paddingBottom: "200px",
        }}
      >
        <div className="container d-flex flex-column  ">
          <p
            className=" fs-2  fw-bolder position-relative mt-2 text-center"
            style={{ color: "#06A6A2" }}
          >
            Our Partners
            <span className="position-absolute mt-3 blue_icon"></span>
          </p>
          {/* <br /> */}
          <div style={{ width: "93%" ,}} className="mt-5 mx-auto">
          <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
  <div className="slider">
    <div className="slide-track">
      <div className="slide img">
        <img src={microsoft} alt="" />
      </div>
      <div className="slide img">
        <img src={ukresearch} alt=""  style={{height:"45px"}}/>
      </div>
      <div className="slide img">
        <img src={openai} alt="" style={{height:"45px"}}/>
      </div>
      <div className="slide img">
        <img src={amazonweb} alt="" />
      </div>
      {/* <div className="slide img">
        <img src={uclimage} alt="" />
      </div> */}
      <div className="slide img">
        <img src={arbisoftimage} alt="" />
      </div>
      <div className="slide img">
        <img src={systemimage} alt=""  style={{height:"40px"}}/>
      </div>
      <div className="slide img">
        <img src={educatenewimage} alt="" />
      </div>
      <div className="slide img">
        <img src={uclnewimage} alt=""  style={{height:"45px"}}/>
      </div>
      <div className="slide img">
        <img src={designrushimage} alt="" />
      </div>
      <div className="slide img">
        <img src={councilimage} alt="" />
      </div>
    </div>
  </div>
</div>

          </div>
        </div>
      </div>

      <div
        className="our_process"
        style={{ backgroundColor: "#FFFAF5", paddingTop: "20px" }}
      >
        <div className="container py-4 ">
          <p className="fs-1 text_orange text-center mt-3 fw-semibold">
            Our process at Infini8AI
          </p>
          <p className="fs-5 my-5 lh-base text-center mx-2 mx-md-0" >
            At the core of our methodology lies a deeply iterative process that
            significantly expedites development. From gaining a comprehensive
            understanding of our clients’ unique business challenges to
            efficiently developing and launching their software products, we
            tailor our workflow to align seamlessly with the specific needs of
            each project. This customized approach ensures not only a rapid
            go-to-market strategy but also a perfect fit solution for our
            clients.
          </p>
          <div className="d-flex justify-content-center ">
            <button className="btn_blue mt-4" onClick={viewDetails}>
              Learn more
            </button>
          </div>
        </div>
      </div>

      <div
        className="cards_detail d-flex align-items-center "
        // style={{ paddingTop: "70px", paddingBottom: "110px" }}
      >
        <div className="container ">
          <div className="row gx-3 h-100 cards_row">
            <div className="col-12 col-sm-6 col-lg-3  ">
              <div className=" rounded p-3 px-4 h-100 d-lg-flex flex-lg-column justify-content-around process_cards">
                <p className="text_gray fs-4" style={{ fontWeight: 600 }}>
                  Quick Launch
                </p>
                <p className="text_gray mt-3  mb-2 lh-sm">
                  We understand that launching a software product quickly and
                  impactfully involves several key steps that balance speed with
                  quality and market readiness.
                </p>
                <div>
                  <img src={rocket} className="img-fluid w-100" alt="" />
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-3 quick-lauch-cards ">
              <div className="rounded p-3 px-4 h-100 d-lg-flex flex-lg-column justify-content-lg-between process_cards">
                <p className="text_gray fs-4" style={{ fontWeight: 600 }}>
                  MVP within a month
                </p>
                <p className="text_gray mt-3  mb-2 lh-sm">
                  We identify and develop only the essential features that solve
                  the primary problem for your users in the MVP.
                </p>
                <div>
                  <img src={calendar} className="img-fluid w-100" alt="" />
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-3  ">
              <div className=" rounded p-3 px-4 h-100 d-lg-flex flex-lg-column justify-content-lg-between process_cards">
                <p className="text_gray fs-4" style={{ fontWeight: 600 }}>
                  Prototypes
                </p>
                <p className="text_gray mt-3  mb-2 lh-sm">
                  We design, develop, test and iteratively improve the prototype
                  with a tight feedback loop that directly connects the
                  development process with users.
                </p>
                <div>
                  <img src={headphones} className="img-fluid w-100" alt="" />
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-3 ">
              <div className=" rounded p-3 px-4 h-100 d-lg-flex flex-lg-column justify-content-lg-between process_cards">
                <p className="text_gray fs-4" style={{ fontWeight: 600 }}>
                  Support
                </p>
                <p className="text_gray mt-3 mb-2 lh-sm">
                  We understand the importance of efficient and timely support
                  for a project's success and our response times are
                  unprecedented in the industry.
                </p>
                <div>
                  <img
                    src={support_female}
                    className="img-fluid w-100"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ServiceWeOffer />
      <OurProducts />

      <Footer />
    </PageLayout>
  );
};

export default LandingPage;
