import React, { useState, useEffect } from "react";
import { Box, Button, Stack, Typography, styled } from "@mui/material";
import moment from "moment";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import Clock from "./Clock";

const TableHeader = styled(Box)({
  minHeight: "80px",
  display: "flex",
  alignItems: "center",
  padding: "0 10px",
  borderRadius: "8px",
  background: "#04B2AE",
  color: "#FFFFFF",
  margin: "28px 0",
});
const Text = styled(Typography)({
  fontFamily: "Dosis",
  fontWeight: 400,
  fontSize: "16px",
  color: "#ffffff",
});
const TimeText = styled(Typography)({
  fontFamily: "Dosis",
  fontWeight: 700,
  fontSize: "20px",
  color: "#04B2AE",
});
const TimeBox = styled(Box)({
  height: "40px",
  width: "60px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "4px",
  color: "#04B2AE",
  background: "#FFFFFF",
});

const BtnClock = styled(Button)({
  padding: "8px",
  textTransform: "none",
  background: "#ffffff !important",
  color: "#67748E",
  fontFamily: "Dosis",
  fontWeight: 400,
  fontSize: "16px",
  "&:hover": {
    background: "#67748E !important",
    color: "#ffffff",
  },
});

function Timer({ user, clockIn, clockOut, time, load }) {
  console.log("user:", user);
  let today = moment().format("DD-MM-YYYY");
  const [timeDifference, setTimeDifference] = useState(time);

  useEffect(() => {
    const interval = setInterval(() => {
      if (time || time == 0) {
        let current = Math.floor(Date.now() / 1000);
        setTimeDifference(current - time);
      } else {
        clearInterval(interval);
        setTimeDifference(0);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [time]);

  const getStatus = () => {
    let checkIn = true;
    let checkOut = true;
    if (!load) {
      if (user && user?.length > 0) {
        if (user[0].date == today && user[0].clockedIn) {
          checkIn = true;
          checkOut = false;
        } else if (user[0].date == today && user[0].clockedOut) {
          checkIn = false;
          checkOut = true;
        } else {
          checkIn = false;
          checkOut = true;
        }
      } else {
        checkIn = false;
        checkOut = true;
      }
    }
    return { checkIn, checkOut };
  };

  const handleClockOut = () => {
    if (user[0].date == today) {
      clockOut();
    } else {
      clockOut(user[0]._id);
    }
  };

  const getHours = () => {
    let hours = Math.floor(timeDifference / 3600);
    if (user.length > 0) {
      if (user[0].date == today && user[0].consumed_time) {
        let hrs = user[0].consumed_time.slice(0, 2);
        hours = parseInt(hours) + parseInt(hrs);
      }
    }
    if (hours.toString().length == 1) {
      hours = `0${hours}`;
    }
    return hours;
  };
  const getMins = () => {
    let mins = Math.floor((timeDifference % 3600) / 60);
    if (user.length > 0) {
      if (user[0].date == today && user[0].consumed_time) {
        let min = user[0].consumed_time.slice(3, 5);
        mins = parseInt(mins) + parseInt(min);
      }
    }
    if (mins.toString().length == 1) {
      mins = `0${mins}`;
    }
    return mins;
  };
  const getSecs = () => {
    let secs = timeDifference % 60;
    if (secs.toString().length == 1) {
      secs = `0${secs}`;
    }
    return secs;
  };
  return (
    <TableHeader>
      <Stack
        direction="row"
        width="100%"
        alignItems="center"
        justifyContent="space-between"
        flexWrap="wrap"
      >
        <Stack direction="row" spacing={1}>
          <Stack alignItems="center" justifyContent="center">
            <TimeBox>
              <TimeText>{load ? "00" : getHours()}</TimeText>
            </TimeBox>
            <Text>Hours</Text>
          </Stack>
          <Stack alignItems="center" justifyContent="center">
            <TimeBox>
              <TimeText>{load ? "00" : getMins()}</TimeText>
            </TimeBox>
            <Text>Minutes</Text>
          </Stack>
          <Stack alignItems="center" justifyContent="center">
            <TimeBox>
              <TimeText>{load ? "00" : getSecs()}</TimeText>
            </TimeBox>
            <Text>Seconds</Text>
          </Stack>
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          spacing={0.5}
        >
          <BtnClock
            disabled={user && user[0]?.clockedIn ? true : false}
            variant="contained"
            onClick={clockIn}
          >
            <AccessAlarmIcon sx={{ mr: 1, fontSize: "35px" }} />
            Clock-In
          </BtnClock>
          <Box sx={{ background: "#ffffff", height: "50px", width: "2px" }} />
          <BtnClock
            disabled={user && user[0]?.clockedOut ? true : false}
            variant="contained"
            onClick={handleClockOut}
          >
            <AccessAlarmIcon sx={{ mr: 1, fontSize: "35px" }} />
            Clock-Out
          </BtnClock>
        </Stack>
        <Stack alignItems="start">
          <Stack direction="row" spacing={0.5}>
            <Text sx={{ fontWeight: 600, mr: 0.5 }}>Today : </Text>
            <Text>{moment().format("DD-MM-YYYY")}</Text>
          </Stack>
          <Stack direction="row" spacing={0.5}>
            <Text sx={{ fontWeight: 600, mr: 0.5 }}>Time : </Text>

            <Text>
              <Clock />
            </Text>
          </Stack>
        </Stack>
      </Stack>
    </TableHeader>
  );
}

export default Timer;
