import Repository from "./Repository";

export default {
  users(payload) {
    return Repository.post("users", payload);
  },
  time_track(payload) {
    return Repository.post("time_track", payload);
  },
  roadMap(payload) {
    return Repository.post("roadmap", payload);
  },
  leave(payload) {
    return Repository.post("leave", payload);
  },
};
