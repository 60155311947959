import React, { useEffect, useState } from "react";
import DashboardLayout from "../../layouts/Dashboard";
import DashboardNavbar from "../../layouts/Navbars/DashboardNavbar";
import { Box, Button, CircularProgress, Grid, Stack } from "@mui/material";
import { Typography, styled, TextField } from "@mui/material";
import ClockImg from "../../assets/images/clock img.png";
import moment from "moment";
import { Title } from "../../user portal/dashboard/style";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ReplyAllOutlinedIcon from "@mui/icons-material/ReplyAllOutlined";
import ArticleIcon from "@mui/icons-material/Article";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import EditDetails from "./EditDetails";
import Report from "./Report";
import Repo from "../../Repository/Repo";
import DailyDetails from "./DailyDetails";
import Toaster from "../../components/Toaster";
import Clock from "../../user portal/time tracking/Clock";
import TimerDetails from "./TimerDetails";

const TableHeader = styled(Box)({
  minHeight: "80px",
  display: "flex",
  alignItems: "center",
  padding: "0 10px",
  borderRadius: "8px",
  background: "#04B2AE",
  color: "#FFFFFF",
  margin: "28px 0",
});
const ItemBox = styled(Box)(({ theme }) => ({
  minHeight: "60px",
  display: "flex",
  alignItems: "center",
  padding: "0 43px",
  borderRadius: "8px",
  background: "#FFFFFF",
  margin: "28px 0",
  borderLeft: "2px solid #04B2AE",
  borderRight: "2px solid #04B2AE",
  [theme.breakpoints.down("md")]: {
    padding: "10px",
  },
}));
const Text = styled(Typography)({
  fontFamily: "Dosis",
  fontWeight: 400,
  fontSize: "16px",
  color: "#ffffff",
});
const TimeText = styled(Typography)({
  fontFamily: "Dosis",
  fontWeight: 700,
  fontSize: "20px",
  color: "#04B2AE",
});

const TimeBox = styled(Box)({
  height: "40px",
  width: "60px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "4px",
  color: "#04B2AE",
  background: "#FFFFFF",
});

const BtnReport = styled(Button)({
  padding: "8px",
  textTransform: "none",
  background: "#ffffff !important",
  color: "#67748E",
  fontFamily: "Dosis",
  fontWeight: 400,
  fontSize: "16px",
  "&:hover": {
    background: "#67748E !important",
    color: "#ffffff",
  },
});
const BackButton = styled(Box)({
  borderRadius: "4px",
  padding: "4px 6px",
  height: "24px",
  cursor: "pointer",
  fontSize: "14px",
  color: "#67748E",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  border: "1px solid #67748E",
  "&:hover": {
    background: "#67748E",
    color: "#FFFFFF",
  },
});

function Tracking() {
  const navigate = useNavigate();
  const location = useLocation();
  let today = moment().format("DD-MM-YYYY");
  const [state, setState] = useState({
    startDate: "",
    endDate: "",
  });
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState("");
  const [selected, setSelected] = useState("");
  const [selectedIdx, setSelectedIdx] = useState(null);
  const [modals, setModals] = useState({
    report: false,
    edit: false,
    details: false,
  });
  const [selectedTime, setSelectedTime] = useState(null);

  useEffect(() => {
    if (location?.state.empObj) {
      const { startDate, endDate } = state;
      if ((startDate && endDate) || (!startDate && !endDate)) {
        getTimeTrack();
      }
    } else {
      navigate("/employees");
    }
  }, [state.startDate, state.endDate]);

  const toggle = (name, item = "", idx = null) => {
    if (name == "edit") {
      setSelected(item);
    }
    if (name == "details") {
      setSelectedIdx(idx);
    }
    setModals((prev) => ({ ...prev, [name]: !prev[name] }));
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };

  const getTotal = () => {
    let hours = "00";
    let mins = "00";
    if (user.totalTimeSpent) {
      hours = user.totalTimeSpent.slice(0, 2);
      mins = user.totalTimeSpent.slice(3, 5);
      if (hours.toString().length == 1) {
        hours = `0${hours}`;
      }
      if (mins.toString().length == 1) {
        mins = `0${mins}`;
      }
    }
    return { hours, mins };
  };

  const getTimeTrack = async () => {
    try {
      setLoading(true);
      const { startDate, endDate } = state;
      let start =
        startDate && endDate ? moment(startDate).format("DD-MM-YYYY") : "";
      let end =
        startDate && endDate ? moment(endDate).format("DD-MM-YYYY") : "";
      let { data } = await Repo.time_track({
        request: {
          method: "getEmployeeTimeTracks",
          data: {
            employeeId: location.state.empObj.email,
            startDate: start,
            endDate: end,
          },
        },
      });
      setLoading(false);
      if (data.response.data) {
        setUser(data.response.data);
        if (data.response.data.timeTracksArr) {
          let today = moment().format("DD-MM-YYYY");
          let arr = data.response.data.timeTracksArr;
          if (arr[0].date == today) {
            if (arr[0]?.clockedIn) {
              let length = arr[0]?.clockHitsory.length - 1;
              let t = arr[0].clockHitsory[length].time;
              setSelectedTime(t);
            }
          }
        }
      } else {
        Toaster("error", "Error");
      }
    } catch (err) {
      setLoading(false);
      console.log("err:", err);
      // Toast("error", "Error");
    }
  };

  const onSuccess = () => {
    toggle("edit");
    getTimeTrack();
  };

  const handleClockIn = async () => {
    try {
      setLoading(true);
      let time = Math.floor(Date.now() / 1000);
      setSelectedTime(time);
      let { data } = await Repo.time_track({
        request: {
          method: "saveEmployeeTimeTrack",
          data: {
            company_code: location.state.empObj.company_code,
            employeeId: location.state.empObj.email,
            employeeName: location.state.empObj.name,
            clockIn: time,
          },
        },
      });
      if (data.response.data.timeTractId) {
        Toaster("success", "Clocked-In Successfully");
        setLoading(false);
        getTimeTrack();
      } else {
        Toaster("error", "Error");
        setLoading(false);
      }
    } catch (err) {
      console.log("err:", err);
      Toaster("error", "Error");
      setLoading(false);
    }
  };

  const handleClockOut = async (arg = "") => {
    try {
      setLoading(true);
      let time = Math.floor(Date.now() / 1000);
      let info = {
        company_code: location.state.empObj.company_code,
        employeeId: location.state.empObj.email,
        employeeName: location.state.empObj.name,
        clockOut: time,
      };
      if (arg) {
        info._id = arg;
      }
      let { data } = await Repo.time_track({
        request: {
          method: "saveEmployeeTimeTrack",
          data: info,
        },
      });
      if (data.response.status.statusCode == 200) {
        Toaster("success", "Clocked-Out Successfully");
        setLoading(false);
        getTimeTrack();
      } else {
        Toaster("error", "Error");
        setLoading(false);
      }
    } catch (err) {
      console.log("err:", err);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar>
        <Grid container>
          <Grid item xs={1} sx={{ display: "flex", alignItems: "center" }}>
            <img
              style={{ marginLeft: "10px" }}
              height="50px"
              src={ClockImg}
              alt=""
            />
          </Grid>
          <Grid
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            item
            xs={6.8}
          >
            <Title>Employee Time Tracking</Title>
          </Grid>
          <Grid item xs={3} sx={{ display: "flex", alignItems: "center" }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              spacing={1}
            >
              <Stack>
                <Typography sx={{ fontSize: "12px" }}>From</Typography>
                <TextField
                  size="small"
                  type="date"
                  variant="outlined"
                  name="startDate"
                  onChange={handleInput}
                />
              </Stack>
              <Stack>
                <Typography sx={{ fontSize: "12px" }}>To</Typography>
                <TextField
                  size="small"
                  type="date"
                  variant="outlined"
                  name="endDate"
                  onChange={handleInput}
                />
              </Stack>
            </Stack>
          </Grid>
          <Grid
            item
            xs={1}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <Link to="/employees">
              <BackButton>
                <ReplyAllOutlinedIcon />
              </BackButton>
            </Link>
          </Grid>
        </Grid>
      </DashboardNavbar>
      <TableHeader>
        <Stack
          direction="row"
          width="100%"
          alignItems="center"
          justifyContent="space-between"
          flexWrap="wrap"
        >
          <Stack alignItems="start">
            <Stack direction="row" spacing={0.5}>
              <Text sx={{ fontWeight: 600, mr: 0.5 }}>Today : </Text>
              <Text>{moment().format("DD-MM-YYYY")}</Text>
            </Stack>
            <Stack direction="row" spacing={0.5}>
              <Text sx={{ fontWeight: 600, mr: 0.5 }}>Time : </Text>
              <Text>
                <Clock />
              </Text>
            </Stack>
          </Stack>
          <Stack direction="row" spacing={2} alignItems="center">
            <TimeText sx={{ fontWeight: 600, color: "#ffffff" }}>
              Total Hours Spent:
            </TimeText>
            <Stack alignItems="center" justifyContent="center">
              <TimeBox>
                <TimeText>{getTotal().hours}</TimeText>
              </TimeBox>
              <Text>Hours</Text>
            </Stack>
            <Stack alignItems="center" justifyContent="center">
              <TimeBox>
                <TimeText>{getTotal().mins}</TimeText>
              </TimeBox>
              <Text>Minutes</Text>
            </Stack>
          </Stack>
          <Stack direction="row" alignItems="center">
            <BtnReport variant="contained" onClick={() => toggle("report")}>
              <ArticleIcon sx={{ mr: 1, fontSize: "20px" }} />
              Generate Report
            </BtnReport>
          </Stack>
        </Stack>
      </TableHeader>
      <TimerDetails
        user={user?.timeTracksArr}
        clockIn={handleClockIn}
        clockOut={(e) => handleClockOut(e)}
        time={selectedTime}
        load={loading}
      />
      {loading && (
        <Stack alignItems="center" justifyContent="center">
          <CircularProgress size={50} />
        </Stack>
      )}
      {!loading &&
        user?.timeTracksArr?.map((item, i) => {
          return (
            <ItemBox key={i}>
              <Grid container spacing={2}>
                <Grid item md={2.5} sm={6} xs={12}>
                  <Stack direction="row" spacing={0.5}>
                    <Text sx={{ color: "#67748E", fontWeight: 600, mr: 0.5 }}>
                      Date :
                    </Text>
                    <Text sx={{ color: "#67748E" }}>{item?.date}</Text>
                  </Stack>
                </Grid>

                <Grid item md={2.5} sm={6} xs={12}>
                  <Stack direction="row" spacing={0.5}>
                    <Text sx={{ color: "#67748E", fontWeight: 600, mr: 0.5 }}>
                      Time Consumed :
                    </Text>
                    <Text sx={{ color: "#67748E" }}>{item?.consumed_time}</Text>
                  </Stack>
                </Grid>
                <Grid item md={2.5} sm={6} xs={12}>
                  <Stack direction="row" spacing={0.5}>
                    <Text sx={{ color: "#67748E", fontWeight: 600, mr: 0.5 }}>
                      Pending Time :
                    </Text>
                    <Text sx={{ color: "#67748E" }}>{item?.pending_time}</Text>
                  </Stack>
                </Grid>
                <Grid item md={2.5} sm={6} xs={12}>
                  <Stack direction="row" spacing={0.5}>
                    <Text sx={{ color: "#67748E", fontWeight: 600, mr: 0.5 }}>
                      Total Hours :
                    </Text>
                    <Text sx={{ color: "#67748E" }}>{item?.total_time}</Text>
                  </Stack>
                </Grid>
                <Grid item md={2} sm={6} xs={12}>
                  {item.date == today && item.clockedIn == true ? (
                    <BackButton onClick={handleClockOut}>Clock-Out</BackButton>
                  ) : (
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-around"
                    >
                      <BackButton onClick={() => toggle("edit", item)}>
                        <BorderColorIcon sx={{ mr: 1 }} /> Edit
                      </BackButton>
                      <BackButton onClick={() => toggle("details", "", i)}>
                        Details
                      </BackButton>
                    </Stack>
                  )}
                </Grid>
              </Grid>
            </ItemBox>
          );
        })}
      {modals.edit && (
        <EditDetails
          onClose={() => toggle("edit")}
          record={selected}
          onSuccess={onSuccess}
        />
      )}
      {modals.report && <Report user={user} onClose={() => toggle("report")} />}
      {modals.details && (
        <DailyDetails
          user={user}
          selected={selectedIdx}
          onClose={() => toggle("details", "", null)}
        />
      )}
    </DashboardLayout>
  );
}

export default Tracking;
