import React from "react";
import NavbarMenu from "../../common/NavbarMenu";
import useWindowSize from "../../utils/useWindowSize";
import PageLayout from "../../../layouts/Page";
import BusinessPalmobile from "./BusinessPalMobile";
import BusinessSection1 from "./BusinessSection1";
import BusinessSection2 from "./BusinessSection2";
import BusinessSection3 from "./BusinessSection3";
import BusinessSection4 from "./BusinessSection4";


const BusinessPal = () => {
  const size = useWindowSize();

  return (
    <PageLayout >
      <NavbarMenu />
      {size.width < 768 ? (
        <BusinessPalmobile />
      ) : (
        <>
          <BusinessSection1 />
          <BusinessSection2 />
          <BusinessSection3 />
          <BusinessSection4 />
        </>
      )}
    </PageLayout>
  );
};

export default BusinessPal;
