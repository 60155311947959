import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import MDBox from "../../components/MDBox";
import { setLayout, useMaterialUIController } from "../../context";

function PageLayout({ background, children }) {
  const [, dispatch] = useMaterialUIController();
  const { pathname } = useLocation();

  useEffect(() => {
    setLayout(dispatch, "page");
  }, [pathname]);

  return (
    <MDBox
      // width="100vw"
      height="100%"
      minHeight="100vh"
      bgColor={background}
      sx={{ overflowX: "hidden" }}
    >
      {children}
    </MDBox>
  );
}

export default PageLayout;
