import axios from "axios";

export const getUser = async (userData) => {
  let res = await axios.post(`https://infini8ai.herokuapp.com/users`, {
    request: {
      method: "getUser",
      data: userData,
    },
  });
  let json = res.data.response.data;
  return json;
};

export const updateUser = async (userData) => {
  let res = await axios.post(`https://infini8ai.herokuapp.com/users`, {
    request: {
      method: "updateUser",
      data: userData,
    },
  });
  let json = res.data.response.data;
  return json;
};
