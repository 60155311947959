import React, { useEffect, useState } from "react";
import DashboardLayout from "../../layouts/Dashboard";
import DashboardNavbar from "../../layouts/Navbars/DashboardNavbar";
import { Button, Grid, Stack, Box } from "@mui/material";
import homeFlage from "./flag-dynamic-color.png";
import searchIcon from "./searchIcon.svg";
import doNot from "./do_not_disturb_on.svg";
import inprogress from "./progress.svg";
import testing from "./delay.svg";
import completed from "./completed.svg";
import folderIcon from "./fav-folder-dynamic-color.svg";
import axios from "axios";
import { getTasksByCompany } from "../../apiservices/task";
import Task from "../project/AddProject";
import { TextStyle } from "../dashboard/style";

const Home = () => {
  const [currentDate, setCurrentDate] = useState("");
  const [projects, setProjects] = useState([]);
  const [columns, setColumns] = useState({});
  const [selectedStatus, setSelectedStatus] = useState("pending");
  const [query, setQuery] = useState("");
  const [userTasks, setUserTasks] = useState([]);
  const [state, setState] = useState({
    selected: false,
    projects: {},
  });
  const pendingCount = userTasks.filter(
    (task) => task.journey === "assigned"
  ).length;
  const inProgressCount = userTasks.filter(
    (task) => task.journey === "inprogress"
  ).length;
  const testingCount = userTasks.filter(
    (task) => task.journey === "testing"
  ).length;
  const completedCount = userTasks.filter(
    (task) => task.journey === "completed"
  ).length;

  useEffect(() => {
    // Function to format the date
    const formatDate = (date) => {
      const options = {
        weekday: "long",
        month: "long",
        day: "numeric",
        year: "numeric",
      };
      return new Date(date).toLocaleDateString(undefined, options);
    };

    // Get the current date and set it to the state
    const today = new Date();
    setCurrentDate(formatDate(today));
    axios
      .post("https://infini8ai.herokuapp.com/projects", {
        request: {
          method: "getAllProjects",
        },
      })
      .then((response) => {
        // Check if the response status code is OK (200)
        if (response.status === 200) {
          // Update the projects state with the retrieved data
          setProjects(response.data.response.data.projectsArr);
        } else {
          console.error("Failed to fetch projects:", response.statusText);
        }
      })
      .catch((error) => {
        console.error("Error fetching projects:", error);
      });
    getallTask();

    const apiUrl = "https://infini8ai.herokuapp.com/tasks";

    const requestData = {
      request: {
        method: "getTasksByMember",
        data: {
          member: localStorage.getItem("userEmail"),
        },
      },
    };
    axios
      .post(apiUrl, requestData)
      .then((response) => {
        // Access the response data
        const responseData = response.data;

        // Access the "tasksArr" array within the data
        const tasksArr = responseData.response.data.tasksArr;

        // Update the component state with the tasks
        setUserTasks(tasksArr);
      })
      .catch((error) => {
        // Handle API request errors here
        console.error("API request error:", error);
      });
  }, []);

  const getallTask = async () => {
    let columnsFromBackend = {
      assigned: {
        name: "Assigned",
        items: [],
      },
      inprogress: {
        name: "In Progress",
        items: [],
      },
      testing: {
        name: "Testing",
        items: [],
      },
      completed: {
        name: "Done",
        items: [],
      },
    };
    let data = {
      company_code: localStorage.getItem("companyCode"),
    };
    let res = await getTasksByCompany(data);
    let inprogress = [];
    let completed = [];
    let assigned = [];
    let testing = [];
    let response = res.tasksArr;
    response.forEach((el) => {
      if (el.journey === "assigned") {
        assigned.push(el);
      } else if (el.journey === "inprogress") {
        inprogress.push(el);
      } else if (el.journey === "testing") {
        testing.push(el);
      } else if (el.journey === "completed") {
        completed.push(el);
      }
    });
    columnsFromBackend.inprogress.items = inprogress;
    columnsFromBackend.completed.items = completed;
    columnsFromBackend.assigned.items = assigned;
    columnsFromBackend.testing.items = testing;

    setColumns(columnsFromBackend);
  };

  const handleStatusClick = (status) => {
    setSelectedStatus(status);
  };
  const filteredProjects = projects.filter(
    (project) =>
      project.name.toLowerCase().includes(query.toLowerCase()) ||
      project.team.toLowerCase().includes(query.toLowerCase())
  );
  const selectedProject = (data = "") => {
    setState((prev) => ({ ...prev, selected: !prev.selected, projects: data }));
  };

  return (
    <DashboardLayout>
      <DashboardNavbar>
        <Stack
          sx={{ height: "100px", mx: 1 }}
          alignItems={"center"}
          direction={"row"}
          justifyContent={"space-between"}>
          <Stack>
            <img src={homeFlage} alt="" />
          </Stack>
          <Stack>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                bgcolor: "#F3F3F3",
                borderRadius: "20px",
                width: "400px",
                mx: "12px",
                mt: "10px",
              }}>
              <input
                placeholder="Search projects or teams......"
                value={query}
                id="inputID"
                onChange={(e) => setQuery(e.target.value)}
                style={{
                  border: "none",
                  marginLeft: "12px",
                  color: "#000",
                  background: "#F3F3F3",
                  width: "100%",
                  fontSize: "14px",
                  fontWeight: "400",
                  padding: "0 10px",
                  "::-webkit-input-placeholder": {
                    color: "red !important",
                  },
                }}
              />
              <style>
                {` 
                    ::placeholder { 
                        color: crimson; 
                    }`}
              </style>
              <img src={searchIcon} alt="" />
            </Box>
          </Stack>
          <Stack
            item
            xs={2}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}>
            <TextStyle
              size="small"
              sx={{ fontSize: "13px", fontWeight: "400" }}>
              {currentDate}
            </TextStyle>
          </Stack>
        </Stack>
      </DashboardNavbar>
      {!state.selected && (
        <Stack direction="row" gap="26px" my="16px">
          <Box
            flex="1"
            bgcolor={"#FFF"}
            sx={{ borderRadius: "5px", p: "16px" }}>
            <Stack direction={"row"} gap="24px">
              <img
                src={homeFlage}
                style={{ width: "60px", height: "60px", borderRadius: "50%" }}
                alt=""
              />
              <Box>
                <TextStyle>My Tasks</TextStyle>
                <Stack direction={"row"}>
                  <Button
                    sx={{
                      fontFamily: "Dosis",
                      fontStyle: "normal",
                      textTransform: "capitalize",
                      color: "#67748E !important",
                    }}
                    onClick={() => handleStatusClick("pending")}
                    className={
                      selectedStatus === "pending" ? "activebtns" : ""
                    }>
                    Pending ({pendingCount})
                  </Button>
                  <Button
                    size="small"
                    sx={{
                      fontFamily: "Dosis",
                      fontStyle: "normal",
                      textTransform: "capitalize",
                      color: "#67748E !important",
                    }}
                    className={
                      selectedStatus === "Inprogress" ? "activebtns" : ""
                    }
                    onClick={() => handleStatusClick("Inprogress")}>
                    Inprogress ({inProgressCount})
                  </Button>
                  <Button
                    sx={{
                      fontFamily: "Dosis",
                      fontStyle: "normal",
                      textTransform: "capitalize",
                      color: "#67748E !important",
                    }}
                    size="small"
                    onClick={() => handleStatusClick("testing")}
                    className={
                      selectedStatus === "testing" ? "activebtns" : ""
                    }>
                    testing ({testingCount})
                  </Button>

                  <Button
                    sx={{
                      fontFamily: "Dosis",
                      fontStyle: "normal",
                      textTransform: "capitalize",
                      color: "#67748E !important",
                    }}
                    size="small"
                    onClick={() => handleStatusClick("completed")}
                    className={
                      selectedStatus === "completed" ? "activebtns" : ""
                    }>
                    Completed ({completedCount})
                  </Button>
                </Stack>
              </Box>
            </Stack>
            <hr style={{ background: "#CCCCCC", marginBottom: "24px" }} />

            {selectedStatus === "pending" && (
              <div
                style={{
                  maxHeight: "52vh", // Adjust the max height as needed
                  overflowY: "auto",
                }}>
                {userTasks.map((states) => {
                  if (states.journey === "assigned") {
                    return (
                      <div key={states.name}>
                        <Stack
                          bgcolor="#F3F3F3"
                          direction={"row"}
                          borderRadius={"4px"}
                          justifyContent="space-between"
                          p="8px"
                          mb="16px">
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: "8px",
                            }}>
                            <img src={doNot} alt="" />
                            <TextStyle
                              sx={{
                                fontSize: "14px",
                              }}>
                              {states.task}
                            </TextStyle>
                          </Box>
                          <TextStyle
                            sx={{
                              fontSize: "14px",
                              backgroundColor: "#E1E1E1",
                              borderRadius: "2px",
                              py: "2px",
                              px: "8px",
                            }}>
                            {states.projects}
                          </TextStyle>
                        </Stack>
                      </div>
                    );
                  }
                  return null; // Optionally, you can handle other cases here
                })}
              </div>
            )}

            {selectedStatus === "testing" && (
              <div
                style={{
                  maxHeight: "52vh", // Adjust the max height as needed
                  overflowY: "auto",
                }}>
                {userTasks.map((states) => {
                  if (states.journey === "testing") {
                    return (
                      <div key={states.name}>
                        <Stack
                          bgcolor="#F3F3F3"
                          direction={"row"}
                          borderRadius={"4px"}
                          justifyContent="space-between"
                          p="8px"
                          mb="16px">
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: "8px",
                            }}>
                            <img src={testing} alt="" />
                            <TextStyle
                              sx={{
                                fontSize: "14px",
                              }}>
                              {states.task}
                            </TextStyle>
                          </Box>
                          <TextStyle
                            sx={{
                              fontSize: "14px",
                              backgroundColor: "#E1E1E1",
                              borderRadius: "2px",
                              py: "2px",
                              px: "8px",
                            }}>
                            {states.projects}
                          </TextStyle>
                        </Stack>
                      </div>
                    );
                  }
                  return null; // Optionally, you can handle other cases here
                })}
              </div>
            )}

            {selectedStatus === "Inprogress" && (
              <div
                style={{
                  maxHeight: "52vh", // Adjust the max height as needed
                  overflowY: "auto",
                }}>
                {userTasks.map((states) => {
                  if (states.journey === "inprogress") {
                    return (
                      <div key={states.name}>
                        <Stack
                          bgcolor="#F3F3F3"
                          direction={"row"}
                          borderRadius={"4px"}
                          justifyContent="space-between"
                          p="8px"
                          mb="16px">
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: "8px",
                            }}>
                            <img src={inprogress} alt="" />
                            <TextStyle
                              sx={{
                                fontSize: "14px",
                              }}>
                              {states.task}
                            </TextStyle>
                          </Box>
                          <TextStyle
                            sx={{
                              fontSize: "14px",
                              backgroundColor: "#E1E1E1",
                              borderRadius: "2px",
                              py: "2px",
                              px: "8px",
                            }}>
                            {states.projects}
                          </TextStyle>
                        </Stack>
                      </div>
                    );
                  }
                  return null; // Optionally, you can handle other cases here
                })}
              </div>
            )}
            {selectedStatus === "completed" && (
              <div
                style={{
                  maxHeight: "52vh", // Adjust the max height as needed
                  overflowY: "auto",
                }}>
                {userTasks.map((states) => {
                  if (states.journey === "completed") {
                    return (
                      <div key={states.name}>
                        <Stack
                          bgcolor="#F3F3F3"
                          direction={"row"}
                          borderRadius={"4px"}
                          justifyContent="space-between"
                          p="8px"
                          mb="16px">
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: "8px",
                            }}>
                            <img src={completed} alt="" />
                            <TextStyle
                              sx={{
                                fontSize: "14px",
                              }}>
                              {states.task}
                            </TextStyle>
                          </Box>
                          <TextStyle
                            sx={{
                              fontSize: "14px",
                              backgroundColor: "#E1E1E1",
                              borderRadius: "2px",
                              py: "2px",
                              px: "8px",
                            }}>
                            {states.projects}
                          </TextStyle>
                        </Stack>
                      </div>
                    );
                  }
                  return null; // Optionally, you can handle other cases here
                })}
              </div>
            )}
          </Box>

          <Box
            flex="1"
            bgcolor={"#FFF"}
            sx={{ p: "16px", borderRadius: "5px" }}>
            <Box textAlign={"center"} mb="44px">
              <TextStyle fontSize="20px">Projects</TextStyle>
            </Box>

            <Grid spacing={2} container>
              {filteredProjects.length > 0 ? (
                filteredProjects.map((project) => (
                  <Box
                    onClick={() => selectedProject(project)}
                    key={project._id} // Assuming each project has a unique ID
                    sx={{
                      background: "#F3F3F3",
                      borderRadius: "4px",
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                      p: "5px",
                      pr: "4px",
                      m: "10px",
                      width: "220px",
                      cursor: "pointer",
                    }}>
                    <Box
                      sx={{
                        p: "2px",
                        borderRadius: "4px",
                        background: "#fff",
                      }}>
                      <img src={folderIcon} alt="Folder Icon" />
                    </Box>
                    <Box>
                      <TextStyle fontSize={"14px"}>{project.name}</TextStyle>
                      <TextStyle fontSize={"14px"} mt="8px">
                        {project.team}
                      </TextStyle>
                    </Box>
                  </Box>
                ))
              ) : (
                // Display a message if there are no projects
                <TextStyle>No projects available</TextStyle>
              )}
            </Grid>
          </Box>
        </Stack>
      )}
      {state.selected && (
        <Task
          // viewMembers={state.selected}
          projectData={state.projects}
          viewMembersModel={selectedProject}
        />
      )}
    </DashboardLayout>
  );
};

export default Home;
