import axios from "axios";

export const registerUser = async (userData) => {
  let res = await axios.post(`https://infini8ai.herokuapp.com/users`, {
    request: {
      method: "signupUser",
      data: userData,
    },
  });
  let json = res.data.response.data;
  return json;
};

export const loginUser = async (userData) => {
  let res = await axios.post(`https://infini8ai.herokuapp.com/users`, {
    request: {
      method: "loginUser",
      data: userData,
    },
  });
  let json = res.data.response.data;
  return json;
};

export const loginAdmin = async (userData) => {
  let res = await axios.post(`https://infini8ai.herokuapp.com/users`, {
    request: {
      method: "loginAdmin",
      data: userData,
    },
  });
  let json = res.data.response.data;
  return json;
};
