import { useState, useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.js";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Sidenav from "./layouts/Sidenav";
import theme from "./assets/theme";
import routes from "./routes";
import { adminRoutes } from "./routes";
import { useMaterialUIController, setMiniSidenav } from "./context";
import brandWhite from "./assets/images/logo-ct.png";
import brandDark from "./assets/images/logo-ct-dark.png";
import "./App.css";

import { toast } from "react-toastify";
export default function App() {
  const isLoggedIn = localStorage.getItem("loggedIn");
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    layout,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();
  useEffect(() => {
    if (isLoggedIn && isLoggedIn === "true") {
      <Navigate to="/dashboard" />;
    }
  }, []);

  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getDashboardRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }
      if (route.route) {
        return (
          <Route
            exact
            path={route.route}
            element={route.component}
            key={route.key}
          />
        );
      }
      return null;
    });
  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (!route.type) {
        return (
          <Route
            exact
            path={route.route}
            element={route.component}
            key={route.key}
          />
        );
      }

      return null;
    });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {layout === "dashboard" && (
        <Sidenav
          color={sidenavColor}
          brand={
            (transparentSidenav && !darkMode) || whiteSidenav
              ? brandDark
              : brandWhite
          }
          brandName="Material Dashboard 2"
          routes={
            localStorage.getItem("loginType") === "admin" ? adminRoutes : routes
          }
          onMouseEnter={handleOnMouseEnter}
          onMouseLeave={handleOnMouseLeave}
        />
      )}

      {isLoggedIn === "true" ? (
        <Routes>
          {getDashboardRoutes(
            localStorage.getItem("loginType") === "admin" ? adminRoutes : routes
          )}
          <Route path="*" element={<Navigate to="/dashboard" />} />
        </Routes>
      ) : (
        <Routes>
          {getRoutes(
            localStorage.getItem("loginType") === "admin" ? adminRoutes : routes
          )}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      )}
    </ThemeProvider>
  );
}
