import React from "react";
import Group1464 from "../../../assets/images/qurbani/Group1464.svg";
import Group5029 from "../../../assets/images/qurbani/Group5029.png";
import Group5028 from "../../../assets/images/qurbani/Group5028.png";
import Group5027 from "../../../assets/images/qurbani/Group5027.png";
import Group5026 from "../../../assets/images/qurbani/Group5026.png";
import Group5033 from "../../../assets/images/qurbani/Group5033.svg";
import Group4866 from "../../../assets/images/qurbani/Group4866.png";

import Group4948 from "../../../assets/images/qurbani/Group4948.png";
import Group4949 from "../../../assets/images/qurbani/Group4949.png";
import Group4950 from "../../../assets/images/qurbani/Group4950.png";
import Group4951 from "../../../assets/images/qurbani/Group4951.png";
import Group4952 from "../../../assets/images/qurbani/Group4952.png";
import Vector from "../../../assets/images/qurbani/Vector.png";
import Group4868 from "../../../assets/images/qurbani/Group4868.png";

const QurbaniSection2 = () => {
  let url = "https://infini8ai-images.s3.amazonaws.com/qurbani";
  return (
    <div>
      <div className="QurbaniSurveyAnalysisMain">
        <div id="QurbaniSurveyAnalysis">
          <div className="container">
            <div className="row" style={{ alignItems: "center" }}>
              <div className="col-md-6">
                <div className="QurbaniSurveyImg">
                  <img src={Group1464} className="Group1464" alt="Group1464" />
                </div>
              </div>
              <div className="col-md-6">
                <div className="QurbaniSurveyTitles">
                  <p>
                    The survey was conducted over <span>50 Farms </span>with{" "}
                    <span>10 </span>Farms owners and <span>80,000 </span>buyers.
                  </p>
                </div>
              </div>
            </div>
            <div className="QurbaniSurveyCardImgMain">
              <div className="row">
                <div className="col-md-12 py-0 my-0 px-0 mx-0 p-0 m-0 ">
                  <div className="QurbaniSurveyCardImg">
                    <img
                      src={Group5029}
                      className="Group5029"
                      alt="Group5029"
                    />
                  </div>
                </div>
                <div className="col-md-12 py-0 my-0 px-0 mx-0 p-0 m-0 ">
                  <div className="QurbaniSurveyCardImg">
                    <img
                      src={Group5028}
                      className="Group5028"
                      alt="Group5028"
                    />
                  </div>
                </div>
                <div className="col-md-12 py-0 my-0 px-0 mx-0 p-0 m-0 ">
                  <div className="QurbaniSurveyCardImg">
                    <img
                      src={Group5027}
                      className="Group5027"
                      alt="Group5027"
                    />
                  </div>
                </div>
                <div className="col-md-12 py-0 my-0 px-0 mx-0 p-0 m-0 ">
                  <div className="QurbaniSurveyCardImg">
                    <img
                      src={Group5026}
                      x
                      className="Group5026"
                      alt="Group5026"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="QurbaniInterviewsMain">
        <div id="QurbaniInterviews">
          <div className="container">
            <div className="QurbaniInterviewCardImgMain">
              <div className="row" style={{ alignItems: "center" }}>
                <div className="col-md-6">
                  <div className="QurbaniInterviewsImg">
                    <img
                      src={Group5033}
                      className="Group5033"
                      alt="Group5033"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="QurbaniSurveyTitles">
                    <p>
                      Interviews conducted with a group of people including:
                    </p>
                    <h5>Farms owners,</h5>
                    <h5>sellers</h5>
                    <h5>& buyers.</h5>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="QurbaniInterviewsContent">
                    <p>The questions asked were generally about:</p>
                    <img
                      src={Group4866}
                      className="Group4866"
                      alt="Group4866"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      
      <div id="QurbanimainLearnings" className="inherit">
        <div className="features-div ">
          <div className="container">
            <div className="row ">
              <div className="col-md-10 offset-md-1">
                <div className="QurbaniLearningsTop text-center">
                  <div className="QurbaniExecutivesCard">
                    <img
                      src={Group4948}
                      className="Group4948"
                      alt="Group4948"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row ">
              <div className="col-md-12">
                <div className="QurbaniLearningscontentCard text-center">
                  <div className="row" style={{ alignItems: "center" }}>
                    <div className="col">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="QurbaniExecutivesCard">
                            <img
                              src={Group4949}
                              className="Group4949"
                              alt="Group4949"
                            />
                          </div>
                        </div>
                      </div>
                      <br />
                      <div className="row">
                        <div className="col-md-12">
                          <div className="QurbaniExecutivesCard">
                            <img
                              src={Group4951}
                              className="Group4951"
                              alt="Group4951"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="QurbaniStarCard">
                            <img
                              src={Vector}
                              className="QurbaniVector"
                              alt="QurbaniVector"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="QurbaniExecutivesCard">
                            <img
                              src={Group4950}
                              className="Group4950"
                              alt="Group4950"
                            />
                          </div>
                        </div>
                      </div>
                      <br />
                      <div className="row">
                        <div className="col-md-12">
                          <div className="QurbaniExecutivesCard">
                            <img
                              src={Group4952}
                              className="Group4952"
                              alt="Group4952"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>

      <div id="QurbaniUserPersonas">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="QurbaniUserPersonasContent1">
                <img src={Group4868} className="Group4868" alt="Group4868" />
              </div>
              <div className="QurbaniUserPersonasContent2">
                <img
                  src={`${url}/Group5034.png`}
                  className="Group5034"
                  alt="Group5034"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QurbaniSection2;
