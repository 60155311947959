import React, { useEffect, useState } from "react";
import loginImg from "../../../assets/images/login/loginImg.png";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../style.css";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CancelIcon from "@mui/icons-material/Cancel";
import NavbarMenu from "../../../components/common/NavbarMenu";
import { Box, Button, Grid } from "@mui/material";
import { OutlinedInput, Stack, styled, Typography } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import Signup from "../sign-up";
import { useNavigate } from "react-router-dom";
import PageLayout from "../../../layouts/Page";
import { loginAdmin, loginUser } from "../../../apiservices/authentication";

const schema = yup
  .object({
    email: yup.string().required("*Email is required"),
    password: yup.string().required("*Password is required"),
  })
  .required();
const LoginForm = styled("div")({
  background: "white",
  borderRadius: "12px",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  minHeight: "510px",
});
const RootStyle = styled("div")({
  overflowY: "hidden",
  background: "#04B2AB",
  minHeight: "88.5vh",
  display: "flex",
  alignItems: "center",
  padding: "0px 5%",
});

const TypoTitle = styled(Typography)({
  marginTop: 15,
  color: "#A7A7A7",
});
const TypoMain = styled(Typography)({
  marginTop: 15,
  fontWeight: "bold",
  color: "#21A49E",
});
const StyledButton = styled(Button)({
  height: " 40px",
  width: "228px",
  borderRadius: "4px",
  background: "#21A49E",
  color: "#ffffff",
  textDecorationLine: "none",
  "&:hover": {
    backgroundColor: "#1B8C87",
    color: "#ffffff",
  },
  "&:active": {
    backgroundColor: "#146F6A",
    color: "#ffffff",
  },
});
export const LoginButton = styled(Button)({
  textTransform: "capitalize",
  color: "#21A49E !important",
  fontWeight: "500",
  fontSize: "15px",
  "&:hover": {
    color: "#21A49E",
  },
  "&:active": {
    color: "#21A49E",
  },
});
const Login = () => {
  let navigate = useNavigate();

  let loggedIn = localStorage.getItem("loggedIn");
  if (loggedIn && loggedIn === "true") {
    navigate("/dashboard");
  }

  const [state, setState] = useState({
    showPassword: false,
    email: "",
    password: "",
    loginCheck: false,
    register: false,
    loader: false,
  });
  useEffect(() => {
    localStorage.setItem("loginType", "user");

    const keyDownHandler = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        document.getElementById("btn").click();
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, []);

  const handleClickShowPassword = (state) =>
    setState((prev) => ({ ...prev, showPassword: !prev.showPassword }));
  const {
    register,
    handleSubmit,
    resetField,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmit = async (arg) => {
    setState((prev) => ({ ...prev, loading: !prev.loading }));
    let data = {
      email: arg.email,
      password: arg.password,
    };
    let res =
      state.loginCheck === true
        ? await loginAdmin(data)
        : await loginUser(data);
    if (res.userObj) {
      let user = res.userObj;
      localStorage.setItem("loggedIn", true);
      localStorage.setItem("userName", user.name);
      localStorage.setItem("userEmail", user.email);
      localStorage.setItem("userGender", user.gender);
      localStorage.setItem("userId", user._id);
      localStorage.setItem("userRev", user._rev);
      localStorage.setItem("userToken", user.password_reset_token);
      localStorage.setItem("imageUrl", user.imageUrl);
      localStorage.setItem("companyCode", user.company_code);
      localStorage.setItem(
        "designation",
        user.designation ? user.designation : "Designation"
      );

      toast.success("Login Successfully");
      navigate("/dashboard");
    } else {
      toast.error(state.loginCheck === true ? res.error : res.error);
    }
    setState((prev) => ({ ...prev, loading: !prev.loading }));
  };
  const changeLogin = (name) => {
    setState((prev) => ({ [name]: !prev[name] }));
    if (!state.loginCheck) {
      localStorage.setItem("loginType", "admin");
    } else {
      localStorage.setItem("loginType", "user");
    }
  };
  const handleClick = () => resetField("email");

  return (
    <PageLayout>
      <NavbarMenu />
      <RootStyle>
        <Grid container spacing={2} alignItems="center">
          <Grid item md={4} lg={6} display={{ xs: "none", md: "block" }}>
            <Stack alignItems="center" justifyContent="center">
              <img src={loginImg} alt="" />
            </Stack>
          </Grid>

          {state.register === true ? (
            <Signup loginCheck={(register) => changeLogin(register)} />
          ) : (
            <Grid item xs={6}>
              <LoginForm sx={{ width: { xs: "350px", md: "500px" } }}>
                <TypoTitle>Welcome to infini8ai Web App</TypoTitle>
                <TypoMain variant="h5">
                  {" "}
                  {state.loginCheck ? "Admin" : "User"} Login
                </TypoMain>
                <Stack
                  sx={{
                    paddingTop: 5,
                    marginLeft: 35,
                  }}
                >
                  <LoginButton onClick={() => changeLogin("loginCheck")}>
                    {state.loginCheck ? "Login as user?" : "Login as admin?"}
                  </LoginButton>
                </Stack>
                <Box>
                  <Box sx={{ mb: 3 }}>
                    <FormControl
                      fullWidth
                      sx={{ m: 1, width: { sm: 300, md: 400 } }}
                      variant="outlined"
                    >
                      <InputLabel htmlFor="outlined-adornment-Email">
                        Email
                      </InputLabel>
                      <OutlinedInput
                        id="outlined-adornment-Email"
                        type="text"
                        {...register("email")}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton onClick={handleClick} edge="end">
                              <CancelIcon color="disabled" fontSize="small" />
                            </IconButton>
                          </InputAdornment>
                        }
                        label="Password"
                      />
                    </FormControl>
                    <Typography sx={{ justifyContent: "center", color: "red" }}>
                      {errors.email?.message}
                    </Typography>
                  </Box>
                  <Box sx={{ mb: 3 }}>
                    <FormControl
                      sx={{ m: 1, width: { sm: 300, md: 400 } }}
                      variant="outlined"
                    >
                      <InputLabel htmlFor="outlined-adornment-password">
                        Password
                      </InputLabel>
                      <OutlinedInput
                        id="outlined-adornment-password"
                        type={state.showPassword ? "text" : "password"}
                        {...register("password")}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              edge="end"
                            >
                              {state.showPassword ? (
                                <Visibility color="disabled" fontSize="small" />
                              ) : (
                                <VisibilityOff
                                  color="disabled"
                                  fontSize="small"
                                />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        label="Password"
                      />
                    </FormControl>
                    <Typography sx={{ justifyContent: "center", color: "red" }}>
                      {errors.password?.message}
                    </Typography>
                  </Box>
                </Box>

                <StyledButton
                  id="btn"
                  onClick={handleSubmit(onSubmit)}
                  type="submit"
                  disabled={state.loading}
                >
                  Login
                </StyledButton>
                {!state.loginCheck && (
                  <Box
                    sx={{
                      borderTop: 1,
                      width: "300px",
                      marginTop: "30px",
                      color: "#D2D2D2",
                    }}
                  />
                )}
                {!state.loginCheck && (
                  <>
                    <TypoTitle>Don't have an account?</TypoTitle>
                    <LoginButton onClick={() => changeLogin("register")}>
                      Register
                    </LoginButton>
                  </>
                )}
              </LoginForm>
            </Grid>
          )}
        </Grid>
      </RootStyle>
    </PageLayout>
  );
};

export default Login;
