import React from "react";
import { useState, useEffect } from "react";
import "./meeting.css";
import { Button, CircularProgress, Grid, Typography } from "@mui/material";
import { Box, Stack, styled } from "@mui/system";
import VideoLogo from "../../assets/images/meeting/video.png";
import Alarm from "../../assets/images/meeting/alarm.png";
import NoMeeting from "../../assets/images/nomeetings.svg";
import DashboardLayout from "../../layouts/Dashboard";
import DashboardNavbar from "../../layouts/Navbars/DashboardNavbar";
import {
  getAllMeetings,
  getMeetingsByCompany,
} from "../../apiservices/meeting";
import DialogComponent from "../../user portal/reuseable components/Dialog";
import AddMeeting from "./AddMeeting";
import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined";
import moment from "moment/moment";
import { VideoCall } from "../../assets/Svg/svg";

const Meeting = () => {
  const [state, setState] = useState({
    meetingData: [],
    loader: false,
    selectedRow: {},
    isEdit: false,
    addModal: false,
  });
  const TableHeader = styled(Stack)({
    minHeight: "60px",
    alignItems: "center",
    paddingLeft: "10px",
    borderRadius: "8px",
    marginBottom: "15px",
  });
  const BackButton = styled(Button)({
    borderRadius: "4px",
    padding: "4px 8px 4px 8px",
    color: "#67748E",
    border: "1px solid #67748E",
    "&:hover": {
      background: "#67748E",
      color: "#FFFFFF",
    },
    "&:active": {
      background: "#586278",
      color: "#FFFFFF",
    },
  });
  const Typo = styled(Grid)({
    fontSize: "17px",
  });

  const TypoHead = styled(Grid)({
    fontSize: "20px",
  });
  const getMeetings = async () => {
    setState((prev) => ({ ...prev, loader: !prev.loader }));
    let data = {
      company_code: localStorage.getItem("companyCode"),
    };
    let res = await getMeetingsByCompany(data);
    setState((prev) => ({ ...prev, meetingData: res.meetingsArr }));
    setState((prev) => ({ ...prev, loader: !prev.loader }));
  };
  useEffect(() => {
    getMeetings();
  }, []);

  const getRemainingHours = (endTime) => {
    let currentTime = moment().hours();
    let time = moment(endTime, "hh:mm A").format("HH:mm");

    let endHours = moment(time, "HH:mm").hours();
    let diff = endHours - currentTime;
    return diff.toString().includes("-") ? 24 + diff : diff;
  };
  const addMeeting = () => {
    setState((prev) => ({
      ...prev,
      addModal: !prev.addModal,
      isEdit: false,
    }));
  };
  const editMeeting = (item = "") => {
    setState((prev) => ({
      ...prev,
      addModal: !prev.addModal,
      isEdit: !prev.isEdit,
      selectedRow: item,
    }));
  };
  return (
    <DashboardLayout>
      <DialogComponent
        title="Item History"
        open={state.addModal}
        onClose={addMeeting}
        onClickCancel={addMeeting}
        // dialogTitle={"Summary"}
        dialogContent={
          <>
            <AddMeeting
              getMeetings={getMeetings}
              closePop={addMeeting}
              editRow={state.selectedRow}
              isEdit={state.isEdit}
            />
          </>
        }
      />
      <DashboardNavbar>
        <Grid container spacing={2}>
          <Grid item xs={1} sx={{ mx: 1 }}>
            <img src={VideoLogo} alt="img" />
          </Grid>
          <Grid
            item
            xs={8.8}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}>
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              Meetings
            </Typography>
          </Grid>
          <Grid
            item
            xs={2}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}>
            <BackButton
              size="small"
              onClick={addMeeting}
              sx={{ textTransform: "capitalize" }}>
              <VideoCall />
              &nbsp; Create new
            </BackButton>
          </Grid>
        </Grid>
      </DashboardNavbar>

      <TableHeader sx={{ background: "#04B2AE", color: "#FFFFFF", mt: 5 }}>
        <Grid container>
          <Grid item xs={1}>
            <TypoHead>#</TypoHead>
          </Grid>
          <Grid item xs={3}>
            <TypoHead>Meeting Name</TypoHead>
          </Grid>
          <Grid item xs={2}>
            <TypoHead>Link/ID</TypoHead>
          </Grid>{" "}
          <Grid item xs={2}>
            <TypoHead>Date</TypoHead>
          </Grid>{" "}
          <Grid item xs={1}>
            <TypoHead>Passcode</TypoHead>
          </Grid>
          <Grid item xs={2}>
            <TypoHead>Starts in</TypoHead>
          </Grid>
          <Grid item xs={1}>
            <TypoHead>Action</TypoHead>
          </Grid>
        </Grid>
      </TableHeader>
      {state.meetingData && state.meetingData.length !== 0 ? (
        state.meetingData &&
        state.meetingData.map((item, index) => (
          <TableHeader
            sx={{
              background: "#FFFFFF",
              color: "#67748E",
              borderLeft: 2,
              borderRight: 2,

              borderColor: "#04B2AE",
            }}>
            <Grid container alignItems="center">
              <Grid item xs={1}>
                <Typo>{index + 1}</Typo>
              </Grid>
              <Grid item xs={3}>
                <Typo sx={{ width: "150px" }}>{item.name}</Typo>
              </Grid>
              <Grid item xs={2}>
                <a href={item.meetingUrl} target="_blank" rel="noreferrer">
                  <Typo sx={{ width: "10px" }}>{Date.now() * (index + 1)}</Typo>
                </a>
              </Grid>
              <Grid item xs={2}>
                <Typo>{item.date}</Typo>
              </Grid>
              <Grid item xs={1}>
                <Typo>{item.password}</Typo>
              </Grid>
              <Grid item xs={2}>
                <Box>
                  <Typo>
                    <img
                      src={Alarm}
                      alt="alarm"
                      style={{ marginRight: "5px" }}
                    />
                    {item.time}
                  </Typo>
                  <Typo>
                    <img
                      src={Alarm}
                      alt="alarm"
                      style={{ marginRight: "5px" }}
                    />
                    {item.time && getRemainingHours(item.time)} hours left,
                  </Typo>
                </Box>
              </Grid>
              <Grid item xs={1}>
                <Button onClick={() => editMeeting(item)}>
                  <DriveFileRenameOutlineOutlinedIcon />
                </Button>
              </Grid>
            </Grid>
          </TableHeader>
        ))
      ) : (
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
          {state.loader ? (
            <Box sx={{ display: "flex" }}>
              <CircularProgress color="success" />
            </Box>
          ) : (
            <img src={NoMeeting} alt="" height="350px" width="400px" />
          )}
        </Grid>
      )}
    </DashboardLayout>
  );
};

export default Meeting;
