import { Grid, Stack } from "@mui/material";
import { styled, Box } from "@mui/system";
import React from "react";
import Member from "../../assets/images/team/4.png";
import { TextStyle } from "../dashboard/style";

const TeamMember = ({ selectedRow }) => {
  const MemberCard = styled(Grid)({
    borderRadius: "10px",
    height: "220px",
    width: "148px !important",
    borderBottom: "2px solid #67748E",
    background: "#FFFFFF",
    color: "#737373",
    "&:hover": {
      borderWidth: "1px 1px 4px 1px",
      borderStyle: "solid",
      borderColor: "#04B2AE !important",
      borderRadius: "10px",
      color: "#04B2AE",
    },
  });
  const Border = styled(Stack)({
    border: " 1px solid ",
    // boxShadow: "0px 2.57px rgba(0, 0, 0, 0.3)",
    height: "115px",
    width: "102px",
    borderRadius: "10px",
    justifyContent: "center",
  });
  return (
    <Grid container sx={{ m: 2, px: 3 }}>
      {selectedRow.map((item) => (
        <MemberCard
          item
          xs={1.8}
          sx={{
            mb: 3,
            mx: 2,
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}>
          <Stack alignItems="center">
            <Border>
              <img
                src={item.imageUrl ? item.imageUrl : Member}
                alt="img"
                height="115px"
                width="100px"
                style={{ borderRadius: "10px" }}></img>
            </Border>
          </Stack>
          <Box
            sx={{
              borderTop: 2,
              width: "40px",
              marginTop: "10px",
              marginLeft: "28px",
              marginBottom: "15px",
            }}
          />
          <Stack direction="column" alignItems="center">
            <TextStyle
              sx={{
                fontSize: "10px",
                px: 1,
                lineBreak: "anywhere",
              }}>
              {item.email}
            </TextStyle>
            <TextStyle
              sx={{ fontWeight: "bold", color: "#67748E", fontSize: "12px" }}>
              {item.name}
            </TextStyle>
          </Stack>
        </MemberCard>
      ))}
    </Grid>
  );
};

export default TeamMember;
