import React from "react";
import OptimusSection1 from "./OptimusSection1";
import "../caseStudy.css";
import NavbarMenu from "../../common/NavbarMenu";
import OptimusSection2 from "./OptimusSection2";
import OptimusSection3 from "./OptimusSection3";
import OptimusSection4 from "./OptimusSection4";
import OptimusMobile from "./OptimusMobile";
import useWindowSize from "../../utils/useWindowSize";
import PageLayout from "../../../layouts/Page";

const Optimus = () => {
  const size = useWindowSize();

  return (
    <PageLayout>
      <NavbarMenu />
      {size.width < 768 ? (
        <OptimusMobile />
      ) : (
        <>
          <OptimusSection1 />
          <OptimusSection2 />
          <OptimusSection3 />
          <OptimusSection4 />{" "}
        </>
      )}
    </PageLayout>
  );
};

export default Optimus;
