import React from "react";
import { Box, Stack, styled, Typography } from "@mui/material";
import { RowStyle } from "./styles";
import Img from "../../assets/images/attendance/img1.png";

const Typo = styled(Typography)({
  fontSize: "17px",
});
function Summary({ summary }) {
  return (
    <div>
      {summary.map((item, index) => (
        <RowStyle key={index} direction="row" justifyContent="space-between">
          <img
            src={item.imageUrl ? item.imageUrl : Img}
            alt=""
            width="80px"
            height="80px"
          />
          <Typo sx={{ width: "80px" }}>{item.employeeName}</Typo>
          <Stack alignItems="center">
            <Typo>Total Presents</Typo>
            <Typo sx={{ color: "#00A455" }}>{item.presents}</Typo>
          </Stack>
          <Typo
            type="title"
            style={{
              borderRight: "2px solid #E3E3E3",
              height: "4em",
              color: "#E3E3E3",
            }}></Typo>
          <Stack alignItems="center">
            <Typo>Total Absents</Typo>
            <Typo sx={{ color: "#A40000" }}>{item.absents}</Typo>
          </Stack>
          <Typo
            type="title"
            color="inherit"
            style={{
              borderRight: "2px solid #E3E3E3",
              height: "4em",
              color: "#E3E3E3",
            }}></Typo>
          <Stack alignItems="center">
            <Typo>Total Leaves</Typo>
            <Typo sx={{ color: "#A0A400" }}>{item.fullLeaves}</Typo>
          </Stack>
          <Typo
            type="title"
            color="inherit"
            style={{
              borderRight: "2px solid #E3E3E3",
              height: "4em",
              color: "#E3E3E3",
            }}></Typo>
          <Stack alignItems="center">
            <Typo>Total Half Leaves</Typo>
            <Typo sx={{ color: "#D2AC23" }}>{item.halfLeaves}</Typo>
          </Stack>
          <Typo
            type="title"
            color="inherit"
            style={{
              borderRight: "2px solid #E3E3E3",
              height: "4em",
              color: "#E3E3E3",
            }}></Typo>
          <Box>
            <Typo>Total WFH</Typo>
            <Typo sx={{ color: "#0038A4" }}> {item.wfh}</Typo>
          </Box>
        </RowStyle>
      ))}
    </div>
  );
}

export default Summary;
