import React from "react";
import Group4937 from "../../../assets/images/Group4937.png"
import { Link ,useNavigate , } from "react-router-dom";
import { Link as Linkscroll,scroller } from "react-scroll";
const NaseemSection4 = () => {
  let url = "https://infini8ai-images.s3.amazonaws.com/naseem-casestudy";
  const navigate = useNavigate();

  const handleScroll = (section) => {


    navigate('/');
    setTimeout(() => {
      scroller.scrollTo(section, {
        duration: 500,
        smooth: true,
      });
    }, 100);
  };
  return (
    <div>
      <div id="Sketches">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="SketchesContent">
                <img
                  src={Group4937}
                  width="100%"
                  className="SketchesImg"
                  alt="Sketches"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="Wireframes">
        <div className="container" style={{position:"relative", bottom:"74px",}}>
          <div className="row">
            <div className="col-md-6   text-center" style={{marginLeft:"360px"}}>
              <div className="WireframesContent">
                <img
                  src={`${url}/Frame81.svg`}
                  width="100%"
                  className="Frame81Img"
                  alt="Frame81"
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="WireframesContent">
                <img
                  src={`${url}/loginwire.svg`}
                  width="100%"
                  className="loginwireImg"
                  alt="loginwire"
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="WireframesContent">
                <img
                  src={`${url}/execwire.svg`}
                  width="100%"
                  className="execwireImg"
                  alt="execwire"
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="WireframesContent">
                <img
                  src={`${url}/princwire.svg`}
                  width="100%"
                  className="princwireImg"
                  alt="princwire"
                />
              </div>
            </div>

            <div className="col-md-12">
              <div className="WireframesContent">
                <img
                  src={`${url}/teachwire.svg`}
                  width="100%"
                  className="teachwireImg"
                  alt="teachwire"
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="WireframesContent">
                <img
                  src={`${url}/studwire.svg`}
                  width="100%"
                  className="studwireImg"
                  alt="studwire"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="mobileDesignBottom">
                <div className="row">
                  <div className="col-md-12">
                    <div className="mobileCommomImg">
                      <img
                        src={`${url}/mobwire1.svg`}
                        width="100%"
                        className="mobwire1"
                        alt="mobwire1"
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="mobileCommomImg">
                      <img
                        src={`${url}/mobwire3.svg`}
                        width="100%"
                        className="mobwire3"
                        alt="mobwire3"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="mobileDesignTop">
                <div className="row">
                  <div className="col-md-12">
                    <div className="mobileCommomImg">
                      <img
                        src={`${url}/mobwire2.svg`}
                        width="100%"
                        className="mobwire2"
                        alt="mobwire2"
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="mobileCommomImg">
                      <img
                        src={`${url}/mobwire4.svg`}
                        width="100%"
                        className="mobwire4"
                        alt="mobwire4"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="MobileAppDesign">
        <div className="mobileDesign">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="mobileTopImg">
                  <img
                    src={`${url}/mobDesignBanner.svg`}
                    width="100%"
                    className="mobDesignBanner"
                    alt="mobDesignBanner"
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="mobileDesignBottom">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mobileCommomImg">
                        <img
                          src={`${url}/Login.png`}
                          width="100%"
                          className="Login"
                          alt="Login"
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="mobileCommomImg">
                        <img
                          src={`${url}/verificationCode.png`}
                          width="100%"
                          className="verificationCode"
                          alt="verificationCode"
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="mobileCommomImg">
                        <img
                          src={`${url}/Inventory.png`}
                          width="100%"
                          className="Inventory"
                          alt="Inventory"
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="mobileCommomImg">
                        <img
                          src={`${url}/ClassNoreen.png`}
                          width="100%"
                          className="ClassNoreen"
                          alt="ClassNoreen"
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="mobileCommomImg">
                        <img
                          src={`${url}/defaulterVertical.png`}
                          width="100%"
                          className="ClassNoreen"
                          alt="ClassNoreen"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="mobileDesignTop">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mobileCommomImg">
                        <img
                          src={`${url}/loginRegister.png`}
                          width="100%"
                          className="loginRegister"
                          alt="loginRegister"
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="mobileCommomImg">
                        <img
                          src={`${url}/principalDashboard.png`}
                          width="100%"
                          className="principalDashboard"
                          alt="principalDashboard"
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="mobileCommomImg">
                        <img
                          src={`${url}/classfeeManagement.png`}
                          width="100%"
                          className="classfeeManagement"
                          alt="classfeeManagement"
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="mobileCommomImg">
                        <img
                          src={`${url}/classReference.png`}
                          width="100%"
                          className="classReference"
                          alt="classReference"
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="mobileCommomImg">
                        <img
                          src={`${url}/naseemApp.png`}
                          width="100%"
                          className="naseemApp"
                          alt="naseemApp"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="mobileDesignBottom">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mobileCommomImg">
                        <img
                          src={`${url}/Register.png`}
                          width="100%"
                          className="Register"
                          alt="Register"
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="mobileCommomImg">
                        <img
                          src={`${url}/registrationSuccessful.png`}
                          width="100%"
                          className="registrationSuccessful"
                          alt="registrationSuccessful"
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="mobileCommomImg">
                        <img
                          src={`${url}/Fees.png`}
                          width="100%"
                          className="Fees"
                          alt="Fees"
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="mobileCommomImg">
                        <img
                          src={`${url}/addItem.png`}
                          width="100%"
                          className="addItem"
                          alt="addItem"
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="mobileCommomImg">
                        <img
                          src={`${url}/defaulterHorizontal.png`}
                          width="100%"
                          className="defaulterHorizontal"
                          alt="defaulterHorizontal"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="mobileBottomImg">
                  <img
                    src={`${url}/webDesignBanner.svg`}
                    width="100%"
                    className="webDesignBanner"
                    alt="webDesignBanner"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="desktopDesign">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="desktopDesignContents">
                  <img
                    src={`${url}/naseemExecutive.png`}
                    width="100%"
                    className="naseemExecutiveImg"
                    alt="naseemExecutive"
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="desktopDesignContent">
                  <img
                    src={`${url}/naseemPrincipal.png`}
                    width="100%"
                    className="naseemPrincipalImg"
                    alt="naseemPrincipal"
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="desktopDesignContent">
                  <img
                    src={`${url}/naseemTeacher.png`}
                    width="100%"
                    className="naseemTeacherImg"
                    alt="naseemTeacher"
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="desktopDesignContent">
                  <img
                    src={`${url}/naseemStudent.png`}
                    width="100%"
                    className="naseemStudentImg"
                    alt="naseemStudent"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
     <footer
      style={{
        backgroundColor: "#5912F0",
        backgroundImage: "none",
      }}
      className="page-footer font-small   pb-3 "
    >
      <div className="container">
        <div className="row footer-row" >
          <div className="col-12 col-lg-3 col-md-4 footer-sitemap">
            <p
              className="fs-6 mt-4"
              style={{ fontFamily: "Dosis",color:"#dde73c", }}
            >
              SITE MAP
            </p>
           
            <div>
                {/* <p style={{ margin: "0 0 20px 0",color: "#116065", fontWeight:600,font:"poppins", fontSize:"24px" }}>Case Studies</p> */}
                <ul  className="text-white mt-4 lh-sm justify-content-md-start justify-content-center"
              style={{ fontFamily: "Dosis" , listStyleType: "none", display:"flex", gap:"4rem"}}>
                <div style={{display:"flex", flexDirection:"column", gap:"3rem"}}>
                   <Link to="/" style={{  }}>
                   <li style={{color:"white",fontSize:"16px"}}>Home</li>
                  </Link>
                  <div
          className="cursor-pointer"
          style={{ cursor: "pointer",  }}
          onClick={() => handleScroll("ourProducts")}
        >
            <li style={{ fontFamily: "Dosis",fontSize:"16px"}}>Products</li>
        </div>
                  {/* <li>About Us</li> */}
                  <Link to="/our-process" >
                  <li style={{color:"white",fontSize:"16px"}}>Our Process</li>
                  </Link>
                  </div>
                  <div  style={{display:"flex", flexDirection:"column", gap:"3rem"}}>
                  <Link to="/team" >
                  <li style={{ color:"white",fontSize:"16px" }}>Team</li>
                  </Link>
                  <Link to="/portfolio" >
                  <li style={{ color:"white",fontSize:"16px"}}>Portfolio</li>
                  </Link>
                  <Link to="/careers" >
                  <li style={{ color:"white",fontSize:"16px"}}>Careers</li>
                  </Link>
                  </div>
                </ul>
                </div>
          </div>
          <div className="col-12 col-lg-3 col-md-4 footer-contactus">
            <p
              className="fs-6 mt-4 "
              style={{ fontFamily: "Dosis",color:"#dde73c" }}
            >
              CONTACT US
            </p>
            <p  
              className="text-white mt-4 lh-sm"
              style={{ fontFamily: "Dosis" ,fontSize:"16px"}}
            >
              IDEA London Ground Floor 69 Wilson Street, London EC2A 2BB
              <p className="mt-4 mb-4 text-white lh-sm"   style={{ fontFamily: "Dosis",fontSize:"16px" }}>For hiring inquiries please contact: <span style={{fontFamily: "Dosis",fontSize:"16px"}}>hr@infini8ai.com</span></p> 
              
            </p>
            <p
              className="text-white mt-4 lh-sm"
              style={{ fontFamily: "Dosis" }}
            >
          
              <p className="mt-4 mb-4 text-white lh-sm"   style={{ fontFamily: "Dosis",fontSize:"16px" }}>For all other queries please contact: <span style={{fontFamily: "Dosis",fontSize:"16px"}}>info@infini8ai.com </span> </p> 
              
            </p>
            <div className=" mt-2">
          <p style={{ color: "white",fontSize:"16px" }}>2024 Copyright: Infini8AI</p>
        </div>

          </div>
          <div className="col-12 col-lg-3 col-md-4 footer-casestudies" >
            <p
              className="fs-6  mt-4  "
              style={{ fontFamily: "Dosis",color:"#dde73c" }}
            >
              CASE   STUDIES
            </p>
            {/* <p
              className="text-white mt-4 lh-sm"
              style={{ fontFamily: "Bitter" }}
            >
              To receive updates about our new product offerings and latest
              developments in the world of AI, join our bi-weekly newsletter:{" "}
              <a href="https://veracious.ai/news-letter" target="_blank">
                Veracious AI
              </a>
            </p> */}
              <div>
                {/* <p style={{ margin: "0 0 20px 0",color: "#116065", fontWeight:600,font:"poppins", fontSize:"24px" }}>Case Studies</p> */}
                <ul  className="text-white mt-4 lh-sm"
              style={{ fontFamily: "Dosis" , listStyleType: "none", display:"flex",  gap:"4rem"}}>
                    <div  style={{display:"flex", flexDirection:"column", gap:"3rem"}}>

                    <Link to="/optimus-case-study">
                    <li style={{color:"white",fontSize:"16px"}}>Healthcare</li>
                    </Link>
                    <Link to="/naseem-case-study">
                    <li style={{color:"white",fontSize:"16px"}}>Education</li>
                    </Link>
                    <Link to="/qurbani-case-study">
                    <li style={{color:"white",fontSize:"16px"}}>Livestock</li>
                    </Link>
                    </div>
                    <div  style={{display:"flex", flexDirection:"column", gap:"3rem"}}>

                    <Link to="/stock-chain-case-study">
                    <li style={{color:"white",fontSize:"16px"}}>Logistics</li>
                    </Link>
                    <Link to="/businesspal-case-study">
                    <li style={{color:"white",fontSize:"16px"}}>Business</li>
                    </Link>
                    </div>
                </ul>
                </div>

          </div>
        </div>
        
      </div>
    </footer>
    </div>
  );
};

export default NaseemSection4;
