import React, { useEffect, useState } from "react";
import { Box, Button, styled, TextField, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import Select from "react-select";
import S3FileUpload from "react-s3/lib/ReactS3";
import { getUsersByCompany } from "../../apiservices/general";
import { saveTeam, updateTeam } from "../../apiservices/team";
import { getCurrentDate } from "../../user portal/reuseable components";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
const AddTeam = ({ closeTeamPopup, editRow, isEdit, getAllTeam }) => {
  const schema = yup
    .object({
      teamName: yup.string().required("*Team Name is required"),
    })
    .required();
  const [state, setState] = useState({
    checked: false,
    members: [],
    selected: editRow ? editRow.members : [],
    imageUrl: "",
  });
  const [displayImg, setdisplayImg] = useState("");
  const config = {
    bucketName: "infini8ai-images",
    dirName: "photos",
    error: "",
    region: "us-east-1",
    accessKeyId: "AKIA44SH2DQ6PSTL43O7",
    secretAccessKey: "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",
  };

  const CancelBtn = styled(Button)({
    height: "34px",
    width: "136px",
    borderRadius: "4px",
    padding: "8px 50px 8px 50px",
    color: "#A7A7A7",
    background: "#FFFFFF",
    "&:hover": {
      boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.16)",
      color: "#A7A7A7",
    },
    "&:active": {
      background: " #A7A7A7",
      color: "#FFFFFF",
    },
  });
  const SaveBtn = styled(Button)({
    height: "34px",
    width: "136px",
    borderRadius: "4px",
    padding: "8px 50px 8px 50px",
    color: "#F5F5F5",
    background: "#21A49E",
    "&:hover": {
      boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.16)",
      background: "#1B8C87",
      color: "#F5F5F5",
    },
    "&:active": {
      background: " #146E6A",
      color: "#F5F5F5",
    },
  });
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmit = async (data) => {
    let date = getCurrentDate();
    let url = editRow.imageUrl;
    if (displayImg) {
      let { location } = await S3FileUpload.uploadFile(state.imageUrl, config);
      url = location;
    }
    let teamData = {
      ...(isEdit ? { _id: editRow._id, _rev: editRow._rev } : {}),
      teamName: data.teamName,
      members: state.selected,
      imageUrl: url,
      company_code: localStorage.getItem("companyCode"),
      date: date,
    };
    isEdit ? await updateTeam(teamData) : await saveTeam(teamData);
    isEdit
      ? toast.success("Team edit successfully")
      : toast.success("Team aadded successfully");
    getAllTeam();
    closeTeamPopup();
  };
  const getUser = async () => {
    let data = {
      company_code: localStorage.getItem("companyCode"),
    };
    let arr = [];
    let selected = [];
    let res = await getUsersByCompany(data);

    res.usersArr.map((item) =>
      arr.push({
        ...item,
        value: item.name,
        label: item.name,
      })
    );
    setState((prev) => ({ ...prev, members: arr }));
    if (isEdit) {
      editRow.members &&
        editRow.members.map((item) =>
          selected.push({
            ...item,
            value: item.name,
            label: item.name,
          })
        );
      setState((prev) => ({ ...prev, selected: selected }));
    }
  };
  const handleImg = (e) => {
    let displayImage = URL.createObjectURL(e.target.files[0]);
    setdisplayImg(displayImage);
    setState((prev) => ({ ...prev, imageUrl: e.target.files[0] }));
  };

  useEffect(() => {
    getUser();
    setValue("teamName", editRow?.teamName);
  }, []);
  const onChange = (selectedOptions) => {
    setState((prev) => ({ ...prev, selected: selectedOptions }));
  };

  return (
    <>
      <Typography
        variant="h5"
        sx={{ textAlign: "center", color: "#21A49E", mb: 4 }}>
        Create new team
      </Typography>
      <Box>
        <TextField
          fullWidth
          label="Add Name"
          type="text"
          {...register("teamName")}
        />
        <Typography sx={{ mb: 3, color: "red", fontSize: "12px" }}>
          {errors.teamName?.message}
        </Typography>
      </Box>

      <Box>
        <TextField
          fullWidth
          type="file"
          sx={{ mb: 3 }}
          onChange={(e) => handleImg(e)}
        />
      </Box>
      <Box>
        <Select
          value={state.selected}
          isMulti
          name="selected"
          onChange={onChange}
          options={state.members}
          className="basic-multi-select"
          classNamePrefix="select"
        />
      </Box>
      <Stack direction="row" sx={{ mt: 3 }} justifyContent="end">
        <CancelBtn onClick={() => closeTeamPopup()} sx={{ mx: 3 }}>
          Cancel
        </CancelBtn>
        <SaveBtn onClick={handleSubmit(onSubmit)}>Done</SaveBtn>
      </Stack>
    </>
  );
};

export default AddTeam;
