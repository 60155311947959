import React from "react";
import Footer from "../common/Footer";
import NavbarMenu from "../common/NavbarMenu";
import HeroImg from "../../assets/images/our process/Main-Image.png";
import Ideation from "../../assets/images/our process/Ideation.png";
import Brainstorm from "../../assets/images/our process/Brainstorm.png";
import Development from "../../assets/images/our process/Development.png";
import Feedback from "../../assets/images/our process/Feedback.png";
import PeopleSitting from "../../assets/images/our process/People-sitting.png";
import SupportCustomize from "../../assets/images/our process/Target.png";
import Target from "../../assets/images/our process/Support-Customize.png";
import mobilepic1 from "../../assets/images/our process/mobilepic1.svg";
import mobilepic2 from "../../assets/images/our process/mobilepic2.jpg";
import mobilepic3 from "../../assets/images/our process/mobilepic3.svg";
import mobilepic4 from "../../assets/images/our process/mobilepic4.svg";
import mobilepic5 from "../../assets/images/our process/mobilepic5.svg";
import mobilepic6 from "../../assets/images/our process/mobilepic6.svg";
import useWindowSize from "../utils/useWindowSize";
import "./OurProcess.css";
import PageLayout from "../../layouts/Page";

export default function OurProcess() {
  const size = useWindowSize();

  return (
    <div className="w-100">
      <NavbarMenu />
      {size.width > 768 ? <FullScreen /> : <MobileScreen />}
      <Footer />
    </div>
  );
}

function FullScreen(props) {
  return (
    <PageLayout>
      <div id="approach_main">
        <div className="container ">
          <div id="mainow" className="row align-items-center">
            <div className="col-md-6 col-sm-6 col-sm-push-6">
              <div
                className="approach_text"
                data-aos="fade-up-right"
                data-aos-offset="300"
                data-aos-easing="ease-in-sine"
              >
                <h3>Our approach to product development</h3>
                <p>
                Every detail matters in building a product that users love
                <br />
                </p>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-sm-pull-6">
              <div
                className="approach_image"
                data-aos="zoom-in-down"
                data-aos-offset="300"
                data-aos-easing="ease-in-sine"
              >
                <img className="HeroImg" src={HeroImg}></img>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="brainstorm_main">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div
                className="brainstorm_image"
                data-aos="fade-down-right"
                data-aos-offset="300"
                data-aos-easing="ease-in-sine"
              >
                <img className="Brainstorm" src={Brainstorm}></img>
              </div>
            </div>
            <div className="col-md-6">
              <div
                className="brainstorm_text"
                data-aos="zoom-in-left"
                data-aos-offset="300"
                data-aos-easing="ease-in-sine"
              >
                <h3>Brainstorm & Define</h3>
                <p>
                This phase involves gathering key stakeholders, including developers, designers, product managers, and potential users, to generate ideas and clarify the vision for the product.

                </p>
                {/* <p>
                  
                </p> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="Ideation_main">
        <div className="container-fluid">
          <div id="mainRow" className="row align-items-center">
            <div className="col-md-6">
              <div
                className="Ideation_text"
                data-aos="zoom-in-right"
                data-aos-offset="300"
                data-aos-easing="ease-in-sine"
              >
                <h3> Ideation & Design</h3>
                <p>
                This step focuses on bringing the ideas to life, while maintaining a realistic and functional design approach. It involves:
                </p>
              </div>
              <br/>
              <div
                className="Ideation_textIcon"
                data-aos="zoom-in-right"
                data-aos-offset="300"
                data-aos-easing="ease-in-sine"
              >
                <p>
                {"->"}Storyboards: sketches to help explore user experience.

                </p>
                <p>
                  {" "}
                  {"->"}Wireframes: creating outlines of the content layout.
                </p>
                <p>
                  {"->"}Prototypes: to test the functionality and usability of the design..
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div
                className="Ideation_image"
                data-aos="fade-down-left"
                data-aos-offset="300"
                data-aos-easing="ease-in-sine"
              >
                <img className="Ideation" src={Ideation}></img>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="development_main">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div
                className="development_image"
                data-aos="fade-down-right"
                data-aos-offset="300"
                data-aos-easing="ease-in-sine"
              >
                <img className="development" src={Development}></img>
              </div>
            </div>
            <div className="col-md-6">
              <div
                className="development_text"
                data-aos="zoom-in-left"
                data-aos-offset="300"
                data-aos-easing="ease-in-sine"
              >
                <h3>Development & Testing</h3>
                <p>
                Collaborating with UX designers, our development team focuses on converting the designs into a functional and usable product across multiple platforms using the latest software development frameworks.

Our software engineers rigorously test the product at each stage of development, ensuring a seamless and efficient product development process. By integrating continuous testing throughout the development cycle, we maintain high standards of quality and reliability, anticipating and addressing potential issues before they become obstacles.
                </p>
                {/* <p>
                 
                </p> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="Feedback_main">
        <div className="container-fluid">
          <div id="mainRow" className="row align-items-center">
            <div className="col-md-6">
              <div
                className="Feedback_text"
                data-aos="zoom-in-right"
                data-aos-offset="300"
                data-aos-easing="ease-in-sine"
              >
                <h3> Feedback & Launch</h3>

                <p>
                From developers to designers, our team meticulously evaluates the product based on feedback from potential users and key stakeholders. This collaborative process allows us to continually refine and enhance both the design and functionality of our products. After receiving a stamp of approval from all necessary parties, we proceed to launch the product.
                </p>
                <p>
                  {/*  */}
                  <span style={{display:"flex", justifyContent:"flex-end"}}>  
                    <img className="Customize " src={SupportCustomize}></img>
                  </span>
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div
                className="Feedback_image"
                data-aos="fade-down-right"
                data-aos-offset="300"
                data-aos-easing="ease-in-sine"
              >
                <img className="Feedback" src={Feedback}></img>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="customization_main">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-8 offset-md-2">
              <div
                className="customization-text"
                data-aos="zoom-in"
                data-aos-offset="300"
                data-aos-easing="ease-in-sine"
              >
                <h3>Support & Customization</h3>

                <img className="Customize" src={Target} ></img>

                <p>
                Your journey with us does not end after a successful product launch. Our dedicated team continues to monitor performance, gather feedback, and implement improvements to enhance user experience. We offer post project customizations to meet your specific needs, ensuring our solution adapts to your unique requirements over time. We are committed to evolving with you, ensuring our product not only meets but consistently exceeds your expectations.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="PeopleSitting_main">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div
                className="imgTeam-content"
                data-aos="fade-down-left"
                data-aos-offset="300"
                data-aos-easing="ease-in-sine"
              >
                <img className="PeopleSitting" src={PeopleSitting}></img>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="footer_main">
        <div className="container-fluid" style={{width:"80%"}}>
          <div className="row" >
            <div className="col-md-12 " >
              <div
                className="footer-content"
                data-aos="fade-down"
                data-aos-offset="300"
                data-aos-easing="ease-in-sine"
              >
                <p>
                Our iterative approach marks our distinction from other tech teams. By deeply understanding your business challenges and client needs, we tailor our process to develop and deliver your product to the market in the shortest possible time.<br/> We customize our workflow to align seamlessly with your project requirements, ensuring that each solution is not just effective but also uniquely suited to your venture's needs.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
}

function MobileScreen(props) {
  let url = "https://infini8ai-images.s3.amazonaws.com/qurbani";
  return (
    <PageLayout>
      <div className="d-flex justify-content-center">
        <img src={`${url}/mblemain.png`} className="img-fluid w-75" />
      </div>
      <p className="text-center fs-1 fw-bold text_blue">
        Our approach to product development
      </p>
      <p className="text-center fs-4 mt-3  text_orange">
        We care about every step we take to bring an amazing product to life
      </p>
      <div className="d-flex justify-content-center mt-5">
        <img src={mobilepic1} className="img-fluid" style={{ width: "80%" }} />
      </div>
      <div className="d-flex justify-content-center mt-4">
        <img src={mobilepic4} className="img-fluid" style={{ width: "80%" }} />
      </div>
      <div className="d-flex justify-content-center mt-4">
        <img src={mobilepic2} className="img-fluid" style={{ width: "80%" }} />
      </div>
      <div className="d-flex justify-content-center mt-4">
        <img src={mobilepic3} className="img-fluid" style={{ width: "80%" }} />
      </div>
      <div className="d-flex justify-content-center mt-5">
        <p className="text-center fs-1 fw-bold text_orange">
          Support & Customization
        </p>
      </div>
      <div className="d-flex justify-content-center mt-4">
        <img src={mobilepic5} className="img-fluid" style={{ width: "80%" }} />
      </div>
      <p className="text-center fs-4 m-3 lh-sm ">
        Your journey with us just doesn’t end after a successful product launch.
        Our team observes,takes feedback, and constantly improves user
        experience. We also provide personalized customization §to meet your
        specific needs.
      </p>
      <div className="mobile_rectangle1 text-center p-2">
        <img src={mobilepic6} className="img-fluid" style={{ width: "80%" }} />
      </div>
      <div className="mobile_rectangle2 p-4">
        <p className="text-center fs-5  lh-sm " >
          Our 'iterative' approach helps expand development, from understanding
          your clients and business difficulties to building up your product and
          taking it to showcase in the briefest conceivable time. We customize
          how we work to accommodate your venture needs.
        </p>
      </div>
    </PageLayout>
  );
}
