import { useState } from "react";
import { useEffect } from "react";
import { Box, styled } from "@mui/system";
import { Button, CircularProgress, Grid, Stack } from "@mui/material";
import ProjectLogo from "../../assets/images/team/projecticon.png";
import folder from "../../assets/images/dashboard/folder.png";
import ReplyAllOutlinedIcon from "@mui/icons-material/ReplyAllOutlined";
import Task from "./AddProject";
import { Members } from "../../assets/Svg/svg";
import DashboardLayout from "../../layouts/Dashboard";
import DashboardNavbar from "../../layouts/Navbars/DashboardNavbar";
import { getProjectsByMember } from "../../apiservices/project";
import NoProject from "../../assets/images/noproject.svg";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { TextStyle, Title } from "../dashboard/style";

const DragIcon = styled(Box)({
  margin: "5px",
  borderRadius: "2px",
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  color: "grey",
  justifyContent: "center",
  "&:hover": {
    background: "#67748E",
    color: "#ffffff",
  },
});

export const ViewMemberButton = styled(Button)({
  // height: "26px",
  width: "150px",
  borderRadius: "4px",
  padding: " 4px 8px 4px 8px",
  border: "1px solid #67748E",
  color: "#67748E !important",
  fontSize: "15px",
  fontWeight: "normal",
  textTransform: "capitalize",
  "&:hover": {
    background: "#67748E",
    color: "#FFFFFF !important",
  },
  "&:active": {
    background: "#586278",
    color: "#FFFFFF !important",
  },
});
const TeamCard = styled(Box)({
  height: "100px",
  maxWidth: "450px",
  borderLeft: "2px solid transparent",
  borderRadius: "8px",
  background: "#F5F5F5",
  margin: "20px",
  transition: "0.5s",
  "&:hover": {
    background: "#FFFFFF",
    borderLeft: "3px solid #04B2AE",
    boxShadow: "0px 4px 10px 0px #EBEBEB",
  },
  "&:active": {
    background: "#F5F5F5",
    borderLeft: "px solid #04B2AE",
    boxShadow: "0px 4px 10px 0px #EBEBEB",
  },
});
const BackButton = styled(Button)({
  borderRadius: "4px",
  padding: "4px 8px 4px 8px",
  color: "#67748E",
  border: "1px solid #67748E",
  "&:hover": {
    background: "#67748E",
    color: "#FFFFFF",
  },
  "&:active": {
    background: "#586278",
    color: "#FFFFFF",
  },
});

const Project = () => {
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    projectData: [],
    selected: false,
    viewMembers: false,
    project: {},
  });

  useEffect(() => {
    getProjects();
  }, []);

  const selectedTeam = (data) => {
    setState((prev) => ({ ...prev, selected: !prev.selected, project: data }));
  };

  const getProjects = async () => {
    setLoading(true);

    let data = {
      member: localStorage.getItem("userEmail"),
    };
    let res = await getProjectsByMember(data);
    setState((prev) => ({ ...prev, projectData: res.projectsArr }));
    setLoading(false);
  };

  const viewMembersModel = () => {
    setState((prev) => ({ ...prev, viewMembers: !prev.viewMembers }));
  };

  return (
    <DashboardLayout>
      <DashboardNavbar>
        <Stack
          sx={{ height: "100px", mx: 1 }}
          alignItems={"center"}
          direction={"row"}
          justifyContent={"space-between"}>
          <Stack>
            <img src={ProjectLogo} alt="img" />
          </Stack>
          <Stack>
            <Title variant="h6" sx={{ fontWeight: "bold" }}>
              Projects
            </Title>
          </Stack>

          {state.selected ? (
            <Stack direction="row">
              <ViewMemberButton
                size="small"
                onClick={viewMembersModel}
                sx={{ mx: 1 }}>
                <Members />
                View Members
              </ViewMemberButton>
              <BackButton size="small" onClick={selectedTeam}>
                <ReplyAllOutlinedIcon />
              </BackButton>
            </Stack>
          ) : (
            <Stack>
              <Button hidden></Button>
            </Stack>
          )}
        </Stack>
      </DashboardNavbar>
      {!state.selected && (
        <Grid container sx={{ m: 2 }}>
          {state?.projectData?.length > 0 ? (
            state.projectData.map((item) => (
              <Grid item xs={6} md={6} onClick={() => selectedTeam(item)}>
                <TeamCard sx={{ m: 2 }}>
                  <Stack direction="row" justifyContent="space-between">
                    <Stack direction="row" alignItems="center">
                      <Stack justifyContent="center" sx={{ m: 1 }}>
                        <img
                          src={item.imageUrl ? item.imageUrl : folder}
                          alt="one"
                          width="80px"
                          height="80px"
                          style={{ borderRadius: 2 }}
                        />
                      </Stack>
                      <Stack sx={{ mx: 3 }}>
                        <TextStyle sx={{ color: "#67748E" }}>
                          {item.name}
                        </TextStyle>
                      </Stack>
                    </Stack>
                    <Stack>
                      <DragIcon>
                        <DragIndicatorIcon />
                      </DragIcon>
                    </Stack>
                  </Stack>
                </TeamCard>
              </Grid>
            ))
          ) : (
            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "center" }}>
              {loading ? (
                <Box sx={{ display: "flex" }}>
                  <CircularProgress color="success" />
                </Box>
              ) : (
                <img src={NoProject} alt="" height="350px" width="400px" />
              )}
            </Grid>
          )}
        </Grid>
      )}

      {state.selected && (
        <Task
          viewMembers={state.viewMembers}
          projectData={state.project}
          viewMembersModel={viewMembersModel}
        />
      )}
    </DashboardLayout>
  );
};

export default Project;
