import React, { useState } from "react";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CancelIcon from "@mui/icons-material/Cancel";
import { toast } from "react-toastify";
import "../style.css";
import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  Grid,
  OutlinedInput,
  Radio,
  RadioGroup,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { registerUser } from "../../../apiservices/authentication";
import { schema } from "../../schema";
import { getCurrentDate } from "../../reuseable components";
import { LoginButton } from "../sign-in";
import ReCAPTCHA from "react-google-recaptcha";
const LoginForm = styled("div")({
  background: "white",
  borderRadius: "12px",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  height: "510px",
});

const TypoTitle = styled(Typography)({
  marginTop: 15,
  color: "#A7A7A7",
});
const StyledButton = styled(Button)({
  height: " 40px",
  width: "228px",
  borderRadius: "4px",
  background: "#21A49E",
  color: "#ffffff",
  textDecorationLine: "none",
  "&:hover": {
    backgroundColor: "#1B8C87",
    color: "#ffffff",
  },
  "&:active": {
    backgroundColor: "#146F6A",
    color: "#ffffff",
  },
});
const Signup = ({ loginCheck }) => {
  const [state, setState] = useState({
    showPassword: false,
    showConfirmPassword: false,
  });

  const [recaptchaValue, setRecaptchaValue] = useState("");
  const [isCaptchaSelected, setIsCaptchaSelected] = useState(false);
  const handleRecaptchaChange = (value) => {
    setIsCaptchaSelected(!!value);
    setRecaptchaValue(value);
  };

  const handleClickShowPassword = (name) =>
    setState((prev) => ({ ...prev, [name]: !prev[name] }));
  const {
    register,
    handleSubmit,
    resetField,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const handleClick = (name) => resetField(name);

  const onSubmit = async (data) => {
    let userData = {
      name: data.name,
      email: data.email,
      password: data.password,
      gender: data.gender,
      date: getCurrentDate(),
      approved: false,
      company_code: data.companyCode,
      recaptchaValue: recaptchaValue,
    };
    let res = await registerUser(userData);
    localStorage.setItem("signUpUser", res?.userId);
    if (res?.error !== undefined) {
      toast.error(res.error, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.success("Register Successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
      loginCheck();
    }
  };
  return (
    <Grid item xs={6}>
      <LoginForm
        sx={{
          width: {
            xs: "350px",
            md: "500px",
            justifyContent: "center",
            alignItems: "center",
          },
        }}
      >
        <TypoTitle>New Account Details</TypoTitle>
        <Box>
          <Box
            sx={{
              mt: 1,
            }}
          >
            <FormControl
              sx={{ m: 1, width: { xs: 250, md: 400 } }}
              justifyContent="center"
              variant="outlined"
            >
              <InputLabel htmlFor="outlined-adornment-Name">Name</InputLabel>
              <OutlinedInput
                size="small"
                id="outlined-adornment-Name"
                type="text"
                {...register("name")}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton onClick={() => handleClick("name")} edge="end">
                      <CancelIcon color="disabled" fontSize="small" />
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
            </FormControl>
            <Typography sx={{ justifyContent: "center", color: "red" }}>
              {errors.name?.message}
            </Typography>
          </Box>
          <Box>
            <FormControl
              sx={{ m: 1, width: { xs: 250, md: 400 } }}
              variant="outlined"
            >
              <InputLabel htmlFor="outlined-adornment-Email">Email</InputLabel>
              <OutlinedInput
                size="small"
                id="outlined-adornment-Email"
                type="text"
                {...register("email")}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton onClick={() => handleClick("email")} edge="end">
                      <CancelIcon color="disabled" fontSize="small" />
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
            </FormControl>
            <Typography sx={{ justifyContent: "center", color: "red" }}>
              {errors.email?.message}
            </Typography>
          </Box>
          <Box>
            <FormControl
              sx={{ m: 1, width: { sm: 300, md: 400 } }}
              variant="outlined"
            >
              <InputLabel htmlFor="outlined-adornment-password">
                Password
              </InputLabel>
              <OutlinedInput
                size="small"
                id="outlined-adornment-password"
                type={state.showPassword ? "text" : "password"}
                {...register("password")}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => handleClickShowPassword("showPassword")}
                      edge="end"
                    >
                      {state.showPassword ? (
                        <Visibility color="disabled" fontSize="small" />
                      ) : (
                        <VisibilityOff color="disabled" fontSize="small" />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
            </FormControl>
            <Typography sx={{ justifyContent: "center", color: "red" }}>
              {errors.password?.message}
            </Typography>
          </Box>
          <Box>
            <FormControl
              sx={{ m: 1, width: { sm: 300, md: 400 } }}
              variant="outlined"
            >
              <InputLabel size="small" htmlFor="outlined-adornment-confirm">
                Confirm Password
              </InputLabel>
              <OutlinedInput
                size="small"
                id="outlined-adornment-confirm"
                type={state.showConfirmPassword ? "text" : "password"}
                {...register("confirmPassword")}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() =>
                        handleClickShowPassword("showConfirmPassword")
                      }
                      edge="end"
                    >
                      {state.showConfirmPassword ? (
                        <Visibility color="disabled" fontSize="small" />
                      ) : (
                        <VisibilityOff color="disabled" fontSize="small" />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
            </FormControl>
            <Typography sx={{ justifyContent: "center", color: "red" }}>
              {errors.confirmPassword?.message}
            </Typography>
          </Box>
          <Stack sx={{ mb: 1 }} direction="row">
            <Box>
              <FormControl sx={{ mt: 2 }}>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    value="female"
                    control={<Radio {...register("gender")} />}
                    label="Female"
                  />
                  <FormControlLabel
                    value="male"
                    control={<Radio {...register("gender")} />}
                    label="Male"
                  />
                </RadioGroup>
              </FormControl>
              <Typography sx={{ justifyContent: "center", color: "red" }}>
                {errors.gender?.message}
              </Typography>
            </Box>
            <Box sx={{ mx: 2 }}>
              <FormControl
                sx={{ m: 1, width: { sm: 150, md: 200 } }}
                variant="outlined"
              >
                <InputLabel htmlFor="outlined-adornment-code">Code</InputLabel>
                <OutlinedInput
                  size="small"
                  id="outlined-adornment-code"
                  type="text"
                  {...register("companyCode")}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => handleClick("companyCode")}
                        edge="end"
                      >
                        <CancelIcon color="disabled" fontSize="small" />
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                />
              </FormControl>
              <Typography sx={{ justifyContent: "center", color: "red" }}>
                {errors.companyCode?.message}
              </Typography>
            </Box>
          </Stack>
        </Box>
        <ReCAPTCHA
          sitekey="6LeN5lwoAAAAAMtzPUd4PUVk5Bo6_hC1NR3Eqq8G"
          onChange={handleRecaptchaChange}
        />

        <StyledButton
          onClick={handleSubmit(onSubmit)}
          disabled={!isCaptchaSelected || state.loading}
        >
          Register
        </StyledButton>

        <Divider />
        <Stack direction="row" alignItems="center" sx={{ mb: 2 }}>
          <TypoTitle>Already have an account?</TypoTitle>
          <LoginButton size="small" sx={{ mt: 2 }} onClick={loginCheck}>
            Login
          </LoginButton>
        </Stack>
      </LoginForm>
    </Grid>
  );
};

export default Signup;
