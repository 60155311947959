import React, { useState } from "react";
import logo from "../../assets/images/logo.svg";
import menu_icon from "../../assets/images/home page/menu_open.svg";
import { Link as Linkscroll,scroller } from "react-scroll";
import useWindowSize from "../utils/useWindowSize";
import { Link ,useNavigate , } from "react-router-dom";
import { UncontrolledDropdown } from "reactstrap";
import { DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
const NavbarMenu = () => {
  const size = useWindowSize();
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const navigate = useNavigate();
  const handleScroll = (section) => {


    navigate('/');
    setTimeout(() => {
      scroller.scrollTo(section, {
        duration: 500,
        smooth: true,
      });
    }, 100);
  };
  return (
    <div className="bg-white">
      <div className="container ">
        <div className="d-flex justify-content-between align-items-center nav_bar">
          <div>
            <Link to="/">
              {" "}
              <img src={logo} alt="" />
            </Link>
          </div>
          {/* {size.width < 768 && (
            <div className="get_a_quote_btn">
              <button className=" py-2 nav_btn">
                {" "}
                <Link to="/getquote">Get a quote</Link>
              </button>
            </div>
          )} */}

          {size.width < 768 ? (
            isOpenMenu ? (
              <span onClick={() => setIsOpenMenu(!isOpenMenu)}>&#10006;</span>
            ) : (
              <img
                src={menu_icon}
                onClick={() => setIsOpenMenu(!isOpenMenu)}
                alt=""
              />
            )
          ) : null}

          {size.width >= 768 && (
            <div className="d-flex nav_right">
              <ul className="d-md-flex list_style_none align-items-md-center" >
                <li className="mx-2">
                  {" "}
                  <Link to="/" style={{ fontSize: "90%" }}>
                    Home
                  </Link>
                </li>
                <li className="mx-2" style={{marginTop:"0.2rem"}}>
                  {" "}
                 
                  <a
          className="cursor-pointer"
          style={{ cursor: "pointer", fontSize: "90%" }}
          onClick={() => handleScroll("ourProducts")}
        >
          Products
        </a>
                </li>
                <li className="mx-2">
                  <Link to="/our-process" style={{ fontSize: "90%" }}>
                    Our Process
                  </Link>
                </li>
                <li className="mx-2">
                  <Link to="/team" style={{ fontSize: "90%" }}>
                    Team
                  </Link>
                </li>

                <li className="mx-2">
                  {" "}
                  <Link to="/portfolio" style={{ fontSize: "90%" }}>
                    Portfolio
                  </Link>
                </li>
                <li className="mx-2">
                  <Link to="/careers" style={{ fontSize: "90%" }}>
                    Careers
                  </Link>{" "}
                </li>
                <li className="mx-2">
                  {" "}
                  {/* <Link to="">Case Studies</Link> */}
                  <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle nav caret >
                      Case Studies
                    </DropdownToggle>
                    <div>
                      <DropdownMenu center>
                        <Link to="/optimus-case-study">
                          <DropdownItem>Healthcare</DropdownItem>
                        </Link>
                        <Link to="/naseem-case-study">
                          <DropdownItem>Education</DropdownItem>
                        </Link>

                        <Link to="/qurbani-case-study">
                          <DropdownItem>Livestock</DropdownItem>
                        </Link>
                        <Link to="/stock-chain-case-study">
                          <DropdownItem>Logistics</DropdownItem>
                        </Link>
                        <Link to="/businesspal-case-study">
                          <DropdownItem>Business</DropdownItem>
                        </Link>
                      </DropdownMenu>
                    </div>
                  </UncontrolledDropdown>
                </li>
                {/* <li className="mx-2">
                  {" "}
                  <Link to="/login">Login</Link>
                </li> */}
                 <div className="get_a_quote_btn">
                <button className="nav_btn">
                  <Link to="/login">Login</Link>
                </button>
              </div>
              </ul>
              {/* <div className="get_a_quote_btn">
                <button className=" p-1 nav_btn">
                  <Link to="/get-quote">Get a quote</Link>
                </button>
              </div> */}
            </div>
          )}
        </div>

        {/* Mobile navbar */}
        {size.width < 768 && (
          <div
            className={`${isOpenMenu ? "open_menu" : "close_menu"} mobile_nav`}
          >
            <ul className="list_style_none">
              <li className="mx-2">
                <Link to="/">Home</Link>
              </li>
              <li className="mx-2">
                  {" "}
                 
                  <div
          className="cursor-pointer"
          style={{ cursor: "pointer", fontSize: "90%" }}
          onClick={() => handleScroll("ourProducts")}
        >
          Products
        </div>
              </li>
              <li className="mx-2">
                <Link to="/team">Team</Link>
              </li>
              <li className="mx-2">
                {" "}
                <Link to="/portfolio">Portfolio</Link>
              </li>
              <li className="mx-2">
                <Link to="/career">Careers</Link>
              </li>
              <li className="mx-2">
                {" "}
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav caret>
                    Case Studies
                  </DropdownToggle>
                  <DropdownMenu center>
                    <Link to="/optimus-case-study">
                      <DropdownItem>Healthcare</DropdownItem>
                    </Link>
                    <Link to="/naseem-case-study">
                      <DropdownItem>Education</DropdownItem>
                    </Link>

                    <Link to="/qurbani-case-study">
                      <DropdownItem>Livestock</DropdownItem>
                    </Link>
                    <Link to="/stock-chain-case-study">
                      <DropdownItem>Logistics</DropdownItem>
                    </Link>
                    <Link to="/businesspal-case-study">
                      <DropdownItem>Business</DropdownItem>
                    </Link>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </li>
              <li className="mx-2">Login</li>
            </ul>
          </div>
        )}
      </div>
      <div className="shadow_box"></div>
    </div>
  );
};

export default NavbarMenu;
