import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import { Box, Grid, Typography } from "@mui/material";
import { getProjectsByMember } from "../../../apiservices/project";
import Folder from "../../../assets/images/Employees/folder.png";
import NoProject from "../../../assets/images/noproject.svg";

import { Stack, styled } from "@mui/system";
window.Buffer = window.Buffer || require("buffer").Buffer;
const ViewProject = ({ popup, closeTaskPopup, userEmail }) => {
  const [state, setState] = useState({ projectData: [] });
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    width: "900px",
    transform: "translate(-50%, -50%)",
    background: "#FFFFFF",
    boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.2)",
    borderRadius: "12px",
    p: 5,
  };
  const Card = styled(Stack)({
    borderRadius: "4px",
    padding: "6px 51px 6px 12px",
    background: "#F5F5F5",
  });
  useEffect(() => {
    getProjects();
  }, []);

  const getProjects = async () => {
    let data = {
      member: userEmail,
    };
    let res = await getProjectsByMember(data);
    setState((prev) => ({ ...prev, projectData: res.projectsArr }));
  };

  return (
    <Modal
      open={popup}
      onClose={() => closeTaskPopup("viewProject")}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box sx={style} justifyContent="center">
        <Stack alignItems="center">
          <Typography sx={{ fontWeight: "bold" }}>
            Employee is a part of the following projects
          </Typography>
        </Stack>
        {state.projectData && state.projectData.length !== 0 ? (
          <Grid container>
            {state.projectData.map((item) => (
              <Grid item xs={4} sx={{ px: 0.2 }}>
                <Card direction="row" alignItems="center">
                  <img
                    src={Folder}
                    alt=""
                    width="20px"
                    height="15px"
                    style={{ marginRight: 10 }}
                  />
                  <Typography>{item.name}</Typography>
                </Card>
              </Grid>
            ))}
          </Grid>
        ) : (
          <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
            <img src={NoProject} alt="" height="300px" width="300px" />
          </Grid>
        )}
      </Box>
    </Modal>
  );
};
export default ViewProject;
