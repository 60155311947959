import React from "react";
import Group5030 from "../../../assets/images/qurbani/Group5030.png";
import Group4842 from "../../../assets/images/qurbani/Group4842.svg";
import Group1 from "../../../assets/images/qurbani/Group1.svg";
const QurbaniSection1 = () => {
  let url = "https://infini8ai-images.s3.amazonaws.com/qurbani";
  return (
    <div style={{backgroundColor:"white"}}>
      <div className="bgCase5Div">
        <div className="padding-100 bg-case-5">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-8 col-sm-6">
                    <div className="hero-Content text-end">
                      <h3>UX/UI Case Study</h3>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6 m-0 p-0">
                    <div className="hero-images">
                      <img
                        src={Group4842}
                        class="rounded Group4842 "
                        alt="logo"
                      />
                    </div>
                  </div>
                </div>
                <div className="UiCaseStudy">
                  <div className="row " style={{ alignItems: "center" }}>
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-md-12">
                          <img
                            src={Group1}
                            class="rounded GoatGroup1 float-left"
                            alt="logo"
                          />
                        </div>
                        <div className="col-md-12">
                          <div className="naseemEducation pt-0">
                            <h3>Qurbani App</h3>
                            <p>
                              A Online Management System Powered By Qurbani App
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="naseemEducationImg">
                        <img
                          src={Group5030}
                          class="rounded Group5030 float-left"
                          alt="logo"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="QurbaniProblemStatement">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="problemStatementImg">
                <img
                  src={`${url}/Frame1.svg`}
                  className="Frame1"
                  alt="Frame1"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QurbaniSection1;
