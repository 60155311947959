import { Button, Stack, styled } from "@mui/material";

export const SummaryButton = styled(Button)({
  height: "30px",
  width: "120px",
  borderRadius: "4px",
  color: "#67748E",
  textTransform: "capitalize",
  border: "1px solid #67748E",
  padding: "10px",
  "&:hover": {
    background: "#67748E",
    color: "#FFFFFF",
  },
  "&:active": {
    background: "#586278",
    color: "#FFFFFF",
  },
});
export const RowStyle = styled(Stack)({
  marginTop: "10px",
  minHeight: "60px",
  padding: "10px",
  borderRadius: "8px",
  marginBottom: "15px",
  alignItems: "center",
  background: "#FFFFFF",
  color: "#67748E",
  borderLeft: "2px solid",
  borderRight: "2px solid",
  borderColor: "#04B2AE",
});
export const Mark = styled(Button)({
  borderRadius: "4px",
  // padding: " 4px 8px 4px 8px",
  width: "80px",
  background: "#ffffff",
  border: "1px solid #67748E",
  color: "#67748E !important",
  "&:hover": {
    color: "#FFFFFF !important",
    background: "#67748E",
  },
});
export const Marked = styled(Button)({
  borderRadius: "4px",
  // padding: " 4px 8px 4px 8px",
  width: "80px",
  color: "#FFFFFF !important",
  background: "#67748E",
  "&:hover": {
    background: "#ffffff",
    border: "1px solid #67748E",
    color: "#67748E !important",
  },
});
export const BackButton = styled(Button)({
  borderRadius: "4px",
  padding: "4px 8px 4px 8px",
  color: "#67748E",
  border: "1px solid #67748E",
  "&:hover": {
    background: "#67748E",
    color: "#FFFFFF",
  },
  "&:active": {
    background: "#586278",
    color: "#FFFFFF",
  },
});
