import React from "react";
import leftChevron from "../../assets/images/social media/left-chevron.png";
import PageLayout from "../../layouts/Page";

function ApplyNow() {
  // Example starter JavaScript for disabling form submissions if there are invalid fields
  (function () {
    "use strict";

    // Fetch all the forms we want to apply custom Bootstrap validation styles to
    var forms = document.querySelectorAll(".needs-validation");

    // Loop over them and prevent submission
    Array.prototype.slice.call(forms).forEach(function (form) {
      form.addEventListener(
        "submit",
        function (event) {
          if (!form.checkValidity()) {
            event.preventDefault();
            event.stopPropagation();
          }

          form.classList.add("was-validated");
        },
        false
      );
    });
  })();

  return (
    <PageLayout>
      <div id="ApplyNow">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col">
                  <div className="BackIcon">
                    <ul>
                      <li>
                        <a className="careerBtn" href="/career">
                          <img
                            src={leftChevron}
                            className="img-responsive"
                            alt="leftChevron"
                          />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div id="mainJobTitle">
                <div className="row" style={{ alignItems: "center" }}>
                  <div className="col-md-8">
                    <div className="jobTitle">
                      <h2>Apply for Sales Engineer</h2>
                      <span class="pull-right required-fields">
                        <b class="text-danger">*</b> Required fields
                      </span>

                      <form>
                        <ul className="d-block ">
                          <li>
                            <div class="btn-group ">
                              <a
                                href="https://www.linkedin.com/company/infini8ai"
                                target="_blank"
                                class="btn btn-primary "
                              >
                                Apply with LinkedIn
                              </a>
                            </div>
                          </li>

                          <li>
                            <div class="btn-group">
                              <a
                                href="https://pk.indeed.com/"
                                class="btn btn-secondary"
                                target="_blank"
                              >
                                Apply with Indeed
                              </a>
                            </div>
                          </li>
                        </ul>
                      </form>
                    </div>
                  </div>
                  <div className="col-md-4"></div>
                  <div className="col-md-6">
                    <form class="row needs-validation" novalidate>
                      <div class="col-md-12 mt-2">
                        <label for="validationCustom01" class="form-label">
                          First name{" "}
                          <span>
                            <b class="text-danger">*</b>
                          </span>
                        </label>
                        <input
                          type="text"
                          class="form-control equalWidth"
                          id="validationCustom01"
                          required
                        />
                        <div class="invalid-feedback">
                          This field is required
                        </div>
                      </div>
                      <div class="col-md-12 mt-3">
                        <label for="validationCustom02" class="form-label">
                          Last name{" "}
                          <span>
                            <b class="text-danger">*</b>
                          </span>
                        </label>
                        <input
                          type="text"
                          class="form-control equalWidth"
                          id="validationCustom02"
                          required
                        />
                        <div class="invalid-feedback">
                          This field is required
                        </div>
                      </div>
                      <div class="col-md-12 mt-3">
                        <label for="validationCustom03" class="form-label">
                          Email Address{" "}
                          <span>
                            <b class="text-danger">*</b>
                          </span>
                        </label>
                        <div class="input-group has-validation">
                          <input
                            type="email"
                            class="equalWidth form-control"
                            id="validationCustom03"
                            aria-describedby="inputGroupPrepend"
                            required
                          />
                          <div class="invalid-feedback">
                            This field is required
                          </div>
                        </div>
                        <p class="help-block">
                          Kindly make sure to enter a valid email id.
                        </p>
                      </div>
                      <div class="col-md-12 mt-3">
                        <label for="validationCustom04" class="form-label">
                          Mobile{" "}
                          <span>
                            <b class="text-danger">*</b>
                          </span>
                        </label>
                        <div class="input-group has-validation">
                          <input
                            type="text"
                            class="equalWidth form-control"
                            id="validationCustom04"
                            aria-describedby="inputGroupPrepend"
                            required
                          />
                          <div class="invalid-feedback">
                            This field is required
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12 mt-3">
                        <label for="validationCustom05" class="form-label">
                          LinkedIn URL{" "}
                          <span>
                            <b class="text-danger">*</b>
                          </span>
                        </label>
                        <div class="input-group has-validation">
                          <input
                            type="text"
                            class="equalWidth form-control"
                            id="validationCustom05"
                            aria-describedby="inputGroupPrepend"
                            required
                          />
                          <div class="invalid-feedback">
                            This field is required
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12 mt-3">
                        <label for="validationCustom06" class="form-label">
                          Cover Letter{" "}
                          <span>
                            <b class="text-danger">*</b>
                          </span>
                        </label>
                        <div class="input-group has-validation">
                          <input
                            type="file"
                            class="equalWidth form-control"
                            id="validationCustom06"
                            aria-describedby="inputGroupPrepend"
                            required
                          />

                          <div class="invalid-feedback">
                            Please choose PDF format.
                          </div>
                        </div>
                        <p class="help-block">PDF format is recommended.</p>
                      </div>
                      <div class="col-md-12 mt-3">
                        <label for="validationCustom07" class="form-label">
                          Resume{" "}
                          <span>
                            <b class="text-danger">*</b>
                          </span>
                        </label>
                        <div class="input-group has-validation">
                          <input
                            type="file"
                            class="equalWidth form-control"
                            id="validationCustom07"
                            aria-describedby="inputGroupPrepend"
                            required
                          />

                          <div class="invalid-feedback">
                            Please choose PDF format.
                          </div>
                        </div>
                        <p class="help-block">PDF format is recommended.</p>
                      </div>
                      <legend class="heading heading-sm">Questions</legend>
                      <div class="col-md-12 mt-3">
                        <label for="validationCustom08" class="form-label">
                          Relevant Experience{" "}
                          <span>
                            <b class="text-danger">*</b>
                          </span>
                        </label>
                        <div class="input-group has-validation">
                          <input
                            type="text"
                            class="equalWidth form-control"
                            id="validationCustom08"
                            aria-describedby="inputGroupPrepend"
                            required
                          />
                          <div class="invalid-feedback">
                            This field is required
                          </div>
                        </div>
                      </div>

                      <div class="col-md-12 mt-3">
                        <label for="validationCustom10" class="form-label">
                          Current Salary{" "}
                          <span>
                            <b class="text-danger">*</b>
                          </span>
                        </label>
                        <div class="input-group has-validation">
                          <input
                            type="text"
                            class="equalWidth form-control"
                            id="validationCustom10"
                            aria-describedby="inputGroupPrepend"
                            required
                          />
                          <div class="invalid-feedback">
                            This field is required
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12 mt-3">
                        <label for="validationCustom11" class="form-label">
                          Expected salary{" "}
                          <span>
                            <b class="text-danger">*</b>
                          </span>
                        </label>
                        <div class="input-group has-validation">
                          <input
                            type="text"
                            class="equalWidth form-control"
                            id="validationCustom11"
                            aria-describedby="inputGroupPrepend"
                            required
                          />
                          <div class="invalid-feedback">
                            This field is required
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12 mt-3">
                        <button
                          id="applySubmit"
                          class="btn btn-primary mr-2"
                          type="submit"
                        >
                          Submit
                        </button>
                        <a
                          id="applyCancel"
                          class="btn btn-secondary ml-2"
                          type="button"
                          href="/career"
                        >
                          Cancel
                        </a>
                      </div>
                    </form>
                  </div>
                  <div className="col-md-6"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
}

export default ApplyNow;
