import { Grid, Stack, Typography } from "@mui/material";
import { styled, Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import Member from "../../assets/images/team/profile.png";
import TeamLogo from "../../assets/images/team/teams.png";
import CardModel from "./CardModel";
import DashboardLayout from "../../layouts/Dashboard";
import DashboardNavbar from "../../layouts/Navbars/DashboardNavbar";
import { getUsersByCompany } from "../../apiservices/employees";

const Employees = () => {
  const [state, setState] = useState({
    open: false,
    empName: "",
    empObj: {},
    empArr: [],
  });
  const cardClicked = (item) => {
    setState((prev) => ({
      ...prev,
      open: !prev.open,
      empName: item.name,
      empObj: item,
    }));
  };
  const MemberCard = styled(Grid)({
    borderRadius: "10px",
    height: "220px",
    borderBottom: "2px solid #67748E",
    background: "#FFFFFF",
    color: "#737373",
    "&:hover": {
      borderWidth: "1px 1px 4px 1px",
      borderStyle: "solid",
      borderColor: "#04B2AE",
      borderRadius: "10px",
      color: "#04B2AE",
    },
  });
  const Border = styled(Stack)({
    border: " 1px solid ",
    // color: "#67748E",
    boxShadow: "0px 2.57px rgba(0, 0, 0, 0.3)",
    height: "100px",
    width: "100px",
    borderRadius: "10px",
    justifyContent: "center",
  });
  useEffect(() => {
    getEmployees();
  }, []);
  const getEmployees = async () => {
    let data = {
      company_code: localStorage.getItem("companyCode"),
    };
    let res = await getUsersByCompany(data);
    setState((prev) => ({
      ...prev,
      empArr: res.usersArr,
    }));
  };
  return (
    <DashboardLayout>
      <DashboardNavbar>
        <Grid container spacing={2}>
          <Grid item xs={1} sx={{ mx: 4 }}>
            <img src={TeamLogo} alt="img" />
          </Grid>
          <Grid
            item
            xs={9}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              Team Members
            </Typography>
          </Grid>
        </Grid>
      </DashboardNavbar>
      <Grid container sx={{ m: 1, px: 1, mt: 3 }}>
        {state.empArr &&
          state.empArr.map((item) => (
            <MemberCard
              item
              xs={1.8}
              sx={{
                mx: 2,
                mb: 3,
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
              onClick={() => cardClicked(item)}
            >
              <Stack alignItems="center">
                <Border>
                  <img
                    src={item.imageUrl ? item.imageUrl : Member}
                    alt="img"
                    height="115px"
                    width="100px"
                    style={{ borderRadius: "10px" }}
                  ></img>
                </Border>
              </Stack>
              <Box
                sx={{
                  borderTop: 2,
                  width: "40px",
                  marginTop: "10px",
                  marginLeft: "28px",
                  marginBottom: "15px",
                }}
              />

              <Stack direction="column" alignItems="center">
                <Typography
                  sx={{
                    fontSize: "10px",
                    px: 1,
                    lineBreak: "anywhere",
                  }}
                >
                  {item.email}
                </Typography>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    color: "#67748E",
                    fontSize: "12px",
                  }}
                >
                  {item.name}
                </Typography>
              </Stack>
            </MemberCard>
          ))}
      </Grid>
      {state.open && <CardModel onClose={cardClicked} user={state.empObj} />}
    </DashboardLayout>
  );
};

export default Employees;
