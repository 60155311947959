import { Box, Button, CircularProgress, Grid, Typography } from "@mui/material";
import { Stack, styled } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ReplyAllOutlinedIcon from "@mui/icons-material/ReplyAllOutlined";
import Logo from "../../../assets/images/Employees/file.png";
import DashboardNavbar from "../../../layouts/Navbars/DashboardNavbar";
import DashboardLayout from "../../../layouts/Dashboard";
import {
  getLeavesByMonth,
  getLeavesCountById,
} from "../../../apiservices/calendar";
import moment from "moment";
import axios from "axios";
import Repo from "../../../Repository/Repo";

function AnnualLeaves() {
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    approve: false,
    leavesArr: [],
    loader: false,
    leavesCount: 0,
    filterMonth: moment().format("YYYY-MM"),
  });

  let navigate = useNavigate();
  let location = useLocation();
  console.log(location);
  const [leaveCount, setLeaveCount] = useState(null);
  console.log(leaveCount);
  let userEmail = location.state.empObj.email;
  const BackButton = styled(Button)({
    borderRadius: "4px",
    padding: "4px 8px 4px 8px",
    color: "#67748E",
    border: "1px solid #67748E",
    "&:hover": {
      background: "#67748E",
      color: "#FFFFFF",
    },
    "&:active": {
      background: "#586278",
      color: "#FFFFFF",
    },
  });
  const TableHeader = styled(Stack)({
    height: "60px",
    alignItems: "center",
    padding: "20px",
    borderRadius: "8px",
    margin: "25px",
  });
  const back = () => {
    navigate("/employees", {
      state: {
        dialogue: true,
        empName: location.state.empName,
      },
    });
  };
  const LeavesCard = styled(Stack)({
    height: "100px",
    width: "250px",
    borderRadius: "4px",
    background: "#FFFFFF",
    "&:hover": {
      boxShadow: "0px 4px 12px 0px #000000",
    },
    "&:active": {
      background: "#EBEBEB",
    },
  });
  const approveLeaves = () => {
    setState((prev) => ({ ...prev, approve: !prev.approve }));
  };
  useEffect(() => {
    getData();

    getLeaves(state.filterMonth);
  }, []);

  const getData = async () => {
    setLoading(true);
    let { data } = await Repo.leave({
      request: {
        method: "getLeavesCountById",
        data: {
          // applicantId: "mushtaq.qurbaniapp@gmail.com",
          applicantId: location.state.empObj._id,
        },
      },
    });
    setLoading(false);
    console.log(data);
    let counts = data.response.data.leaveCounts;
    if (counts || counts == 0) {
      setLeaveCount(data.response.data.leaveCounts);
    }
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
    getLeaves(value);
  };
  const getLeaves = async (monthDate) => {
    setState((prev) => ({ ...prev, loader: !prev.loader }));
    let data = {
      applicantId: userEmail,
      month: moment(monthDate).format("MM-YYYY"),
    };
    let res = await getLeavesByMonth(data);
    let count = await getLeavesCountById(data);
    setState((prev) => ({
      ...prev,
      leavesArr: res.leavesArr,
      leavesCount: count.leaveCounts,
      loader: !prev.loader,
    }));
  };

  return (
    <DashboardLayout>
      <DashboardNavbar>
        <Grid container spacing={2}>
          <Grid
            item
            xs={6.3}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              // mx: 4,
            }}
          >
            <img src={Logo} alt="img" height="50px" width="50px" />
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              Employee Attendance
            </Typography>
          </Grid>
          <Grid
            item
            xs={5.5}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              // mx: 4,
            }}
          >
            <Stack direction="row">
              <input
                type="month"
                id="att_date_input"
                name="filterMonth"
                style={{
                  marginRight: "20px",
                  background: "#FFFFFF",
                  border: "1px solid",
                }}
                value={state.filterMonth}
                onChange={handleInput}
              />
              {!state.approve ? (
                <BackButton size="small" onClick={back}>
                  <ReplyAllOutlinedIcon />
                </BackButton>
              ) : (
                <BackButton size="small" onClick={approveLeaves}>
                  <ReplyAllOutlinedIcon />
                </BackButton>
              )}
            </Stack>
          </Grid>
        </Grid>
      </DashboardNavbar>
      <Stack justifyContent="center" alignItems="center" sx={{ mt: 5 }}>
        {!state.approve && (
          <LeavesCard alignItems="center" onClick={approveLeaves}>
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              Annual Leaves
            </Typography>
            <Stack direction="row">
              <Stack alignItems="center" sx={{ fontWeight: "bold" }}>
                <Typography variant="h6" sx={{ color: "#B28C04" }}>
                  Availed
                </Typography>
                <Typography
                  variant="h6"
                  sx={{ fontWeight: "bold", color: "#B28C04" }}
                >
                  <p>Leave Count: {leaveCount}</p>
                </Typography>
              </Stack>
              <Typography
                type="title"
                color="inherit"
                sx={{
                  borderRight: "3px solid #04B2AE",
                  height: "2em",
                  color: "#04B2AE",
                  margin: "15px",
                }}
              ></Typography>
              <Stack
                alignItems="center"
                sx={{ color: "#04B2AE", fontWeight: "bold" }}
              >
                <Typography sx={{ color: "#04B2AE" }} variant="h6">
                  Total
                </Typography>
                <Typography
                  variant="h6"
                  sx={{ fontWeight: "bold", color: "#04B2AE" }}
                >
                  20
                </Typography>
              </Stack>
            </Stack>
          </LeavesCard>
        )}
      </Stack>
      {state.leavesArr && state.leavesArr.length !== 0 ? (
        state.leavesArr.map((item, index) =>
          !state.approve ? (
            <TableHeader
              direction="row"
              justifyContent="space-between"
              sx={{
                background: "#FFFFFF",
                color: "#67748E",
                borderLeft: 2,
                borderRight: 2,
                fontSize: "15px",
                borderColor: "#04B2AE",
              }}
            >
              <Typography sx={{ fontSize: "15px" }}>#{index + 1}</Typography>
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: "15px",
                  minWidth: "200px",
                }}
              >
                {item.title}
              </Typography>
              <Typography sx={{ fontSize: "15px" }}>
                Date:{item.date}
              </Typography>
              <Typography
                sx={{
                  color: `${
                    item.status === "Approved"
                      ? "#2CCA29"
                      : item.status === "Denied"
                      ? "#CA2929"
                      : "#CA8A29"
                  }`,
                  fontSize: "15px",
                }}
              >
                {item.status}
              </Typography>
              <Stack direction="row">
                <Typography sx={{ fontSize: "15px" }}>
                  From:{item.date}
                </Typography>
                <Typography sx={{ fontSize: "15px", mx: 1 }}>|</Typography>
                <Typography sx={{ fontSize: "15px" }}>
                  To:{item.endDate ? item.endDate : item.date}
                </Typography>
              </Stack>
            </TableHeader>
          ) : (
            item.status === "Approved" && (
              <TableHeader
                direction="row"
                justifyContent="space-between"
                sx={{
                  background: "#FFFFFF",
                  color: "#67748E",
                  borderLeft: 2,
                  borderRight: 2,
                  fontSize: "15px",
                  borderColor: "#04B2AE",
                }}
              >
                <Typography sx={{ fontSize: "15px" }}>#{item.key}</Typography>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    minWidth: "200px",
                  }}
                >
                  {item.title}
                </Typography>
                <Typography sx={{ fontSize: "15px" }}>
                  Date:{item.date}
                </Typography>
                <Typography
                  sx={{
                    color: `${
                      item.status === "Approved"
                        ? "#2CCA29"
                        : item.status === "Denied"
                        ? "#CA2929"
                        : "#CA8A29"
                    }`,
                    fontSize: "15px",
                  }}
                >
                  {item.status}
                </Typography>
                <Stack direction="row">
                  <Typography sx={{ fontSize: "15px" }}>
                    From:{item.endDate}
                  </Typography>
                  <Typography sx={{ fontSize: "15px", mx: 1 }}>|</Typography>
                  <Typography sx={{ fontSize: "15px" }}>
                    To:{item.startDate}
                  </Typography>
                </Stack>
              </TableHeader>
            )
          )
        )
      ) : (
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
          {state.loader ? (
            <Box sx={{ display: "flex" }}>
              <CircularProgress color="success" />
            </Box>
          ) : (
            <Typography variant="p" sx={{ mt: 5 }}>
              No Leaves availed
            </Typography>
          )}
        </Grid>
      )}
    </DashboardLayout>
  );
}

export default AnnualLeaves;
