import React from "react";
import Interviewone from "../../../assets/images/stock/Group277.png";
import Group277 from "../../../assets/images/businesspal/Group277.png";
import Group278 from "../../../assets/images/businesspal/Group278.png";
import Group48098340 from "../../../assets/images/businesspal/Group48098340.png";
import Group48098341 from "../../../assets/images/businesspal/Group48098341.png";
import Group48098342 from "../../../assets/images/businesspal/Group48098342.png";
import Group48098343 from "../../../assets/images/businesspal/Group48098343.png";
import sixtyfiveseventyimg from "../../../assets/images/businesspal/sixtyfiveseventyimg.png";
import Rectangle327 from "../../../assets/images/businesspal/Rectangle327.png";
import seventytwoeightyfivepercent from "../../../assets/images/businesspal/seventytwoeightyfivepercent.png";

const BusinessSection2 = () => {
  let url = "https://infini8ai-images.s3.amazonaws.com/stock";
  return (
    <div>
      <div id="StockSurveyAnalysis" style={{ background: "#116065" }}>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div
                className="StockLearningContentMain"
                style={{
                  background: "#116065",
                  borderColor: "#116065",
                  boxShadow: "-5px -5px 10px #37757B",
                  textAlign: "center",
                }}
              >
                <p
                  style={{
                    color: "#62FFAA",
                    fontSize: "30px",
                    paddingTop: "0.5rem",
                    paddingBottom: "0.5rem",
                  }}
                >
                  Survey Analysis
                </p>
              </div>
            </div>
            <div className="col-md-12">
              <div className="d-flex justify-content-center">
                <p
                  className="fs-3 my-5 lh-base text-center "
                  style={{ color: "white", maxWidth: "80%" }}
                >
                  A survey was conducted among small and medium-sized
                  enterprises to understand the challenges in managing business
                  operations and evaluate the potential impact of an integrated
                  solution like BusinessPal.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-6">
                  <div className="StockAnalysisContentCardImg">
                    <img
                      src={sixtyfiveseventyimg}
                      className="GroupImg273"
                      alt="Group273"
                    />
                  </div>
                </div>
                <div className="col-md-6" style={{display:"flex", alignItems:"center"}}>
                  <div
                    style={{
                      display: "flex",
                      alignItems:"center",
                      // width: '300px',
                      height: "200px",
                      backgroundColor: "#136E73",
                      borderRadius: "20px",
                      boxShadow: "-20px -20px 20px #37757B",
                    }}
                  >
                    <p style={{ color: "white",textAlign:"center" }}>
                      65% of respondents reported difficulties in tracking sales
                      and managing customer data, leading to missed sales
                      opportunities.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                    <div className="col-md-12" >
                  <div
                    style={{
                      display: "flex",
                      alignItems:"center",
                      justifyContent:"center",
                      // width: '300px',
                      height: "200px",
                      backgroundColor: "#136E73",
                      borderRadius: "20px",
                      boxShadow: "-20px -20px 20px #37757B",
                    }}
                  >
                    <p style={{ color: "white",textAlign:"center" }}>
                      65% of respondents reported difficulties in tracking sales
                      and managing customer data, leading to missed sales
                      opportunities.
                    </p>
                  </div>
                </div>
              </div>

              <div className="row">
              <div className="col-md-6" style={{display:"flex", alignItems:"center"}}>
                  <div
                    style={{
                      display: "flex",
                      alignItems:"center",
                      // width: '300px',
                      height: "200px",
                      backgroundColor: "#136E73",
                      borderRadius: "20px",
                      boxShadow: "-20px -20px 20px #37757B",
                    }}
                  >
                    <p style={{ color: "white",textAlign:"center" }}>
                      65% of respondents reported difficulties in tracking sales
                      and managing customer data, leading to missed sales
                      opportunities.
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="StockAnalysisContentCardImg">
                    <img
                      src={seventytwoeightyfivepercent}
                      className="GroupImg276"
                      alt="Group276"
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                    <div className="col-md-12" >
                  <div
                    style={{
                      display: "flex",
                      alignItems:"center",
                      justifyContent:"center",
                      // width: '300px',
                      height: "200px",
                      backgroundColor: "#136E73",
                      borderRadius: "20px",
                      boxShadow: "-20px -20px 20px #37757B",
                    }}
                  >
                    <p style={{ color: "white",textAlign:"center" }}>
                      65% of respondents reported difficulties in tracking sales
                      and managing customer data, leading to missed sales
                      opportunities.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="StockInterviewQuestions" style={{ backgroundColor: "#BBE0F5", paddingBottom:"3rem" }}>
        <div className="container">
          <div className="row">
            <div className="col-md-8 offset-md-2 text-center">
              <div className="StockInterviewImage1">
                <img src={Group277} className="Groupimg277" alt="Group277" />
              </div>
            </div>
          </div>
          <div className="row ">
            <div className="col-md-12 text-center">
              <div className="StockInterviewImage2">
                <img src={Group278} className="Groupimg278" alt="Group278" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="StockMainLearning" style={{ backgroundColor: "#116065" }}>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div
                className="StockLearningContentMain"
                style={{
                  background: "#116065",
                  borderColor: "#116065",
                  boxShadow: "-5px -5px 10px #37757B",
                }}
              >
                <p style={{ color: "#62FFAA" }}>Main Learnings</p>
              </div>
            </div>
            <div className="col-md-12 ">
              <div className="row pt-5">
                <div className="col-md-6">
                  <div className="StockAnalysisContentCardImg">
                    <img
                      src={Group48098340}
                      className="GroupImg280"
                      alt="Group280"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="StockAnalysisContentCardImg">
                    <img
                      src={Group48098341}
                      className="GroupImg281"
                      alt="Group281"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="StockAnalysisContentCardImg">
                    <img
                      src={Group48098342}
                      className="GroupImg282"
                      alt="Group282"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="StockAnalysisContentCardImg">
                    <img
                      src={Group48098343}
                      className="GroupImg283"
                      alt="Group283"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessSection2;
