import { useEffect, useState } from "react";
import MDBox from "../../components/MDBox";
import { useLocation } from "react-router-dom";
import Profile from "../../assets/images/dashboard/rectangle.png";
import Project from "../../assets/images/dashboard/folder.svg";
import Meeting from "../../assets/images/dashboard/video.svg";
import Teams from "../../assets/images/dashboard/team.svg";
import Chat from "../../assets/images/dashboard/chat.svg";
import { styled } from "@mui/system";
import { Grid, Stack, Box } from "@mui/material";
import { Link } from "react-router-dom";
import { useMaterialUIController, setMiniSidenav } from "../../context";
import { setLayout } from "../../context";
import DashboardLayout from "../../layouts/Dashboard";
import { navbarMobileMenu } from "../../layouts/Navbars/DashboardNavbar/styles";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import { Cards, CardTitle, SubTitle, Title } from "./style";
import { getCompany } from "../../apiservices/general";

let contentArray = [
  {
    img: Project,
    url: "/project",
    name: "Projects",
  },
  {
    img: Teams,
    url: "/teams",
    name: "Teams",
  },
  {
    img: Meeting,
    name: "Meetings",
    url: "/meeting",
  },
  {
    img: Chat,
    name: "Chat",
    url: "/chat",
  },
];

export const CardHeader = styled(Box)({
  borderRadius: "8px",
  background: " #FFFFFF",
  height: "100px",
  margin: "0 auto",
  boxShadow: "0px 4px 30px 0px #0000001F",
  width: "97%",
  marginTop: "75px",
  padding: "0px 10px",
});
const Navbar = styled(AppBar)({
  background: "#FF9637 !important",
  borderRadius: "10px",
  height: "100px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

function Dashboard({ light }) {
  const { pathname } = useLocation();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav } = controller;
  const [companyName, setCompanyName] = useState("");
  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  useEffect(() => {
    setLayout(dispatch, "dashboard");
    getCompanyData();
  }, [pathname]);
  const getCompanyData = async () => {
    let data = {
      _id: localStorage.getItem("companyCode"),
    };
    let res = await getCompany(data);
    setCompanyName(res?.companyId?.company_name);
  };
  return (
    <DashboardLayout>
      <Navbar position="static" color="inherit">
        <CardHeader>
          <Stack
            sx={{ height: "100px" }}
            alignItems={"center"}
            direction={"row"}
            justifyContent={"space-between"}>
            <Stack>
              <img
                src={localStorage.getItem("imageUrl") && Profile}
                alt=""
                height="65px"
                width="65px"
                style={{ borderRadius: "4px", marginLeft: 4 }}
              />
            </Stack>
            <Stack>
              <Title sx={{ mb: 1 }}>
                {companyName ? companyName : "Company Name"}
              </Title>
              <SubTitle>
                {localStorage.getItem("userName")
                  ? localStorage.getItem("userName")
                  : "user Name"}
                &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {localStorage.getItem("designation")
                  ? localStorage.getItem("designation")
                  : "Designation"}
                &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {localStorage.getItem("companyCode")
                  ? localStorage.getItem("companyCode")
                  : "Company Code"}
              </SubTitle>
            </Stack>
            <Stack>
              {window.innerWidth > 992 ? null : (
                <MDBox>
                  <MDBox color={light ? "white" : "inherit"}>
                    <IconButton
                      size="small"
                      disableRipple
                      color="inherit"
                      sx={navbarMobileMenu}
                      onClick={handleMiniSidenav}>
                      <Icon sx={{ color: "black" }} fontSize="medium">
                        {miniSidenav ? "menu_open" : "menu"}
                      </Icon>
                    </IconButton>
                  </MDBox>
                </MDBox>
              )}
            </Stack>
          </Stack>
        </CardHeader>
      </Navbar>
      <Grid container sx={{ mt: 15, px: 1 }}>
        {contentArray.map((item) => (
          <Grid item xs={12} md={6} lg={3} sx={{ px: 2 }}>
            <Link to={item.url}>
              <Cards>
                <Stack alignItems="center" justifyContent="center" gap={3}>
                  <img src={item.img} alt="" />
                  <CardTitle>{item.name}</CardTitle>
                </Stack>
              </Cards>
            </Link>
          </Grid>
        ))}
      </Grid>
    </DashboardLayout>
  );
}

export default Dashboard;
