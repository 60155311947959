import {
  Button,
  FormControl,
  Grid,
  InputAdornment,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { BackButton } from "../../admin portal/attendance/styles";
import DashboardLayout from "../../layouts/Dashboard";
import DashboardNavbar from "../../layouts/Navbars/DashboardNavbar";
import { Title } from "../dashboard/style";
import Bookmark from "../../assets/images/roadmap/bookmark.svg";
import { TableHeader } from "../meeting/Meeting";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import SearchIcon from "@mui/icons-material/Search";
import AddRoadIcon from "@mui/icons-material/AddRoad";
import DialogComponent from "../reuseable components/Dialog";
import AddMap from "./AddMap";
import { ReplyAllOutlined } from "@mui/icons-material";
import Repo from "../../Repository/Repo";
import Toaster from "../../components/Toaster";
import DataTable from "./Table";

const BtnStyle = styled(Button)({
  textTransform: "capitalize",
  height: "28px",
  //   width: "92px",
  borderRadius: "4px",
  background: "#EBEBEB",
  color: "#67748E !important",
  padding: "6px 10px 6px 10px",
  "&:hover": {
    background: "#E0E1E4",
    color: "#67748E",
  },
  "&:active": {
    background: "#C9CBD0",
    color: "#67748E",
  },
});
export const RoadBtn = styled(Button)({
  textTransform: "capitalize",
  height: "28px",
  //   width: "92px",
  borderRadius: "4px",
  background: "#21A49E",
  color: "#FFFFFF !important",
  padding: "6px 10px 6px 10px",
  "&:hover": {
    background: "#1B8C87",
    color: "#FFFFFF",
  },
  "&:active": {
    background: "#146E6A",
    color: "#FFFFFF",
  },
});
const Typo = styled(Typography)({
  color: "#67748E",
  fontSize: "16px",
});
const Index = () => {
  const [state, setState] = useState({
    addNew: false,
    viewMap: false,
  });
  const [records, setRecords] = useState([]);
  console.log("records", records);
  const [searchQuery, setSearchQuery] = useState("");
  const [selected, setSelected] = useState("");

  useEffect(() => {
    getAllRoadMaps();
  }, []);

  const toggle = (name, row = "") => {
    if (name == "viewMap") {
      setSelected(row);
      setState((prev) => ({
        ...prev,
        [name]: !prev[name],
      }));
    } else {
      setState((prev) => ({
        ...prev,
        [name]: !prev[name],
      }));
    }
  };

  const getAllRoadMaps = async () => {
    try {
      // setLoading(true);
      let { data } = await Repo.roadMap({
        request: {
          method: "getRoadMapsByEmail",
          data: {
            email: localStorage.getItem("userEmail"),
          },
        },
      });

      // Set the roadmapsArr as the new state for records

      console.log(data);
      // setLoading(false);
      if (data.response.data.roadmapsArr) {
        setRecords(data.response.data.roadmapsArr);
      } else {
        Toaster("error", "Error");
      }
    } catch (err) {
      console.log(err);
      Toaster("error", "Error");
      // setLoading(false);
    }
  };

  const onSuccess = () => {
    setState((prev) => ({ ...prev, addNew: !prev.addNew }));
    // setRecords((prev) => [data, ...prev]);
    getAllRoadMaps();
  };

  const filteredRecords = records.filter((record) =>
    record.title.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const toggleView = () => {
    console.log("hello ");
    setState((prev) => ({ ...prev, viewMap: false }));
  };
  return (
    <DashboardLayout>
      <DialogComponent
        title="Create new roadmap for your project"
        open={state.addNew}
        onClose={() => toggle("addNew")}
        onClickCancel={() => toggle("addNew")}
        // dialogTitle={"Summary"}
        dialogContent={
          <>
            <AddMap closePop={() => toggle("addNew")} onSuccess={onSuccess} />
          </>
        }
      />
      <DashboardNavbar>
        <Grid container spacing={2}>
          <Grid item xs={2} sx={{ mx: 1 }}>
            <img src={Bookmark} alt="" />
          </Grid>
          <Grid
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            item
            xs={window.innerWidth > 992 ? 8 : 8}
          >
            <Title>Product Roadmap</Title>
          </Grid>
          <Grid item xs={0.5}></Grid>
          <Grid
            item
            xs={1}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {state.viewMap && (
              <BackButton size="small" onClick={() => toggle("viewMap")}>
                <ReplyAllOutlined />
              </BackButton>
            )}
          </Grid>
        </Grid>
      </DashboardNavbar>
      {state.viewMap ? (
        <DataTable
          roadMap={selected}
          records={records}
          getAllRoadMaps={getAllRoadMaps}
          closeView={toggleView}
        />
      ) : (
        <>
          <TableHeader
            container
            sx={{
              background: "#FFFFFF",
              mt: 2,
            }}
          >
            <RoadBtn sx={{ mx: 3 }} onClick={() => toggle("addNew")}>
              <AddRoadIcon sx={{ mx: 1 }} />
              New Roadmap
            </RoadBtn>
            <FormControl>
              <TextField
                size="small"
                onChange={(e) => setSearchQuery(e.target.value)}
                value={searchQuery}
                placeholder="Search"
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
          </TableHeader>
          {filteredRecords.map((record, i) => {
            return (
              <TableHeader
                container
                justifyContent="center"
                alignItems="center"
                sx={{
                  background: "#FFFFFF",
                }}
              >
                <Grid item xs={2}>
                  <Typo ml="60px">{i + 1}</Typo>
                </Grid>
                <Grid item xs={4}>
                  <Typo>{record.title}</Typo>
                </Grid>
                <Grid item xs={4}>
                  <Typo>{record.project}</Typo>
                </Grid>

                <Grid item xs={2}>
                  <BtnStyle onClick={() => toggle("viewMap", record)}>
                    <RemoveRedEyeOutlinedIcon />
                    View map
                  </BtnStyle>
                </Grid>
              </TableHeader>
            );
          })}
        </>
      )}
    </DashboardLayout>
  );
};

export default Index;
